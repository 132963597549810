import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { ConversationsChannelListModule } from '@panel/app/pages/conversations/conversations-channel-list/conversations-channel-list.module';
import { ConversationsListModule } from '@panel/app/pages/conversations/conversations-list/conversations-list.module';
import { MessageInputModule } from '@panel/app/pages/conversations/message-input/message-input.module';
import { SavedRepliesPopoverModule } from '@panel/app/pages/conversations/saved-replies-popover/saved-replies-popover.module';
import { ConversationsUserCardModule } from '@panel/app/pages/conversations/user-card/user-card.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConversationsChannelListModule,
    ConversationsListModule,
    ConversationsUserCardModule,
    MessageInputModule,
    SavedRepliesPopoverModule,
  ],
  providers: [ConversationsStoreService],
})
export class ConversationsModule {}
