import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { TRIGGER_CHAIN_ACTION_TYPE } from '@http/trigger-chain/trigger-chain.constants';

@Component({
  selector: 'cq-action-header',
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHeaderComponent {
  @Input({ required: true })
  iconClass!: string;

  @Input({ required: true })
  actionType!: TRIGGER_CHAIN_ACTION_TYPE;

  @Output()
  duplicate = new Subject<void>();

  @Output()
  delete = new Subject<void>();
}
