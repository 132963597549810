import { forEach } from 'angular';
import { create, enforce, group, test } from 'vest';

import { TRIGGER_CHAIN_ACTION_TYPE } from '@http/trigger-chain/trigger-chain.constants';
import { TriggerChainStepAction } from '@panel/app/http/trigger-chain/internal-types';

export const triggerChainActionStepValidator = create((step: TriggerChainStepAction) => {
  group(step.uuid, () => {
    test('name', () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotEmpty();
    });

    test('meta.actions', () => {
      enforce(step.meta.actions).isArray().longerThan(0);
    });

    forEach(step.meta.actions, (action) => {
      switch (action.type) {
        case TRIGGER_CHAIN_ACTION_TYPE.EVENT_TYPE:
          test(`meta.actions.action.${action.type}.keyName`, () => {
            enforce(action.keyName).isNotEmpty();
          });
          test(`meta.actions.action.${action.type}.body`, () => {
            enforce(action.body).isNull();
          });
          break;
        case TRIGGER_CHAIN_ACTION_TYPE.PROPERTY:
          test(`meta.actions.action.${action.type}.keyName`, () => {
            enforce(action.keyName).isNotEmpty();
          });
          test(`meta.actions.action.${action.type}.body`, () => {
            enforce(action.body).isNotEmpty();
          });
          break;
        case TRIGGER_CHAIN_ACTION_TYPE.USER_TAG:
          test(`meta.actions.action.${action.type}.keyName`, () => {
            enforce(action.keyName).isNull();
          });
          test(`meta.actions.action.${action.type}.body`, () => {
            enforce(action.body).isNotEmpty();
          });
          break;
      }
    });
  });
});
