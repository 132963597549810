import { Injectable, OnDestroy } from '@angular/core';
import Quill, { Range } from 'quill';
import Delta from 'quill-delta';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { QuillEditorFormat } from '@panel/app/partials/quill-text-editor/quill-text-editor.component';
import { DestroyService } from '@panel/app/services';

import { CustomUserProp } from './custom-user-prop';

type insertUserPropertyOptions = { defaultValue: string; property: string; prettyName: string };

Quill.register(CustomUserProp);

@Injectable({
  providedIn: 'root',
})
export class QuillTextEditorService implements OnDestroy {
  public changeFormatting$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public changeContent$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private editor: Quill | null = null;
  private copyDelta: Delta | null = null;

  constructor(private readonly destroy$: DestroyService) {}

  private handleChangeFormatting = (range: Range) => {
    this.changeFormatting$.next(range ? this.editor!.getFormat(range) : {});
  };

  private handleChangeContent = () => {
    this.changeContent$.next(this.editor!.root.innerHTML);
  };

  public insertUserProperty(
    quill: Quill,
    range: Range | null,
    { defaultValue = '', property, prettyName }: insertUserPropertyOptions,
  ): void {
    let index = range ? range.index : 0;
    let length = range ? range.length : 0;
    let delta = {};

    // Получаем сущность quill-редактора, над которой пользователь хочет совершить действие
    let [leaf] = quill.getLeaf(index);
    if (leaf && leaf.parent instanceof CustomUserProp) {
      let startPosition = quill.getIndex(leaf);
      let endPosition = leaf.length();

      delta = new Delta()
        .retain(startPosition)
        .delete(endPosition)
        .insert(prettyName, {
          userProp: {
            propName: property,
            defaultValue: defaultValue,
            prettyName: prettyName,
          },
        });
    } else {
      delta = new Delta()
        .retain(index)
        .delete(length)
        .insert(prettyName, {
          userProp: {
            propName: property,
            defaultValue: defaultValue,
            prettyName: prettyName,
          },
        });
    }

    //@ts-ignore
    quill.updateContents(delta, 'user');
  }

  public init(config: {
    selector: HTMLElement;
    placeholder: string;
    value: string;
    formats: QuillEditorFormat[];
  }): Quill {
    this.editor = new Quill(config.selector, {
      modules: {
        toolbar: false,
      },
      placeholder: config.placeholder,
      theme: 'snow',
      formats: config.formats,
    });

    //Делаем чтобы typescript подхватил тип правильно
    const safeEditor = this.editor;

    safeEditor.root.innerHTML = config.value;

    safeEditor.on('selection-change', this.handleChangeFormatting);

    safeEditor.on('text-change', this.handleChangeContent);

    /**
     * HACK код ниже нужен для избавления от редкого бага Car-35030
     *  если НЕ выделяя текст, добавить его форматирование и ни чего не писать, то quill
     *  добавит span.ql-cursor, и убирает он его только если что-нибудь отредактировать или изменить положение курсора
     *  НО если снять фокус с поля, ни чего не сделав, он оставит этот span
     */
    fromEvent<FocusEvent>(safeEditor.root, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const cursorElement = safeEditor.root.querySelector('span.ql-cursor');
        if (cursorElement) {
          cursorElement.parentNode?.removeChild(cursorElement);
        }
      });

    fromEvent<ClipboardEvent>(this.editor.root, 'copy')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        const selectionRange = safeEditor.getSelection(true);
        const leaf = safeEditor.getLeaf(selectionRange.index + 1)[0];
        if (leaf && leaf.parent instanceof CustomUserProp) {
          this.copyDelta = safeEditor.getContents(selectionRange.index, selectionRange.length);
          return;
        }
        this.copyDelta = null;
      });

    fromEvent<ClipboardEvent>(safeEditor.root, 'paste')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        //@ts-ignore
        if (this.copyDelta?.ops[0] && this.copyDelta?.ops[0].hasOwnProperty('insert')) {
          //@ts-ignore
          const { insert, attributes } = this.copyDelta.ops[0];
          const selectionRange = safeEditor.getSelection(true);
          const delta = new Delta()
            .retain(selectionRange.index)
            .delete(selectionRange.length)
            //@ts-ignore
            .insert(insert, attributes);
          //@ts-ignore
          this.editor.updateContents(delta, 'user');
        }
      });

    /**
     * При переносе строки форматирование сохраняется, а для CustomUserProp его сохранять не нужно,
     * поэтому мы удаляем пустой элемент с форматированием на новой строке
     */
    fromEvent<KeyboardEvent>(safeEditor.root, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        if (e.key === 'Enter') {
          const range = safeEditor.getSelection(true);
          const leaf = safeEditor.getLeaf(range.index)[0];
          if (leaf && leaf.parent instanceof CustomUserProp) {
            leaf.parent.domNode.remove();
          }
        }
      });

    return safeEditor;
  }

  ngOnDestroy() {
    this.editor!.off('selection-change', this.handleChangeFormatting);
    this.editor!.off('text-change', this.handleChangeContent);
  }
}
