import { ChatBotAction } from 'app/http/chat-bot/types/action-internal.types';

/**
 * Тип ветки бота
 */
export type ChatBotBranch = {
  id: string | null;
  linkId: string;
  name: string;
  /**
   * @deprecated
   * Единственное место, в котором это используется - при отрисовке первичной дерева без координат
   */
  parentBranchIds: string[];
  actions: ChatBotAction[];
  isInvalid: boolean;
  coordinates?: { x: number; y: number } | null;
};
