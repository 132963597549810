import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectComponent } from "@ng-select/ng-select";
import { TuiLet } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';

import { BotActionMeetingComponent } from '@panel/app/pages/chat-bot/content/branch-editor/actions/meeting/meeting.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [BotActionMeetingComponent],
  exports: [BotActionMeetingComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    UIRouterModule,
    TuiLet,
    ReactiveFormsModule,
    SharedModule,
    NgSelectComponent,
  ],
})
export class BranchActionMeetingModule {}
