import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  CLICKHOUSE_PROPERTY_TYPE,
  EDITABLE_USER_PROPERTIES,
  ELASTICSEARCH_OPERATION_TYPES,
  ELASTICSEARCH_PROPERTY_OPERATIONS,
  ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE,
  EVENT_SYSTEM_PROPERTIES,
  USER_SYSTEM_PROPERTIES,
  UserSystemProperty,
} from 'app/http/property/property.constants';
import { ApiGetPropertyResponse, ApiUserPropertyResponse } from 'app/http/property/property.types';
import { clone } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { EVENT_TYPE_GROUP } from '@http/event-type/event-type.constants';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';

/**
 * Тип свойства пользователя
 */
export type UserProperty = {
  cls: CLICKHOUSE_PROPERTY_TYPE;
  group: string;
  groupOrder: number;
  name: string;
  prettyName: string;
  readonly: boolean;
};

/**
 * Тип типы событий
 */
export type EventType = {
  active: boolean;
  group: EVENT_TYPE_GROUP;
  groupOrder: number;
  id: string;
  name: string;
  prettyName: string;
  score: number;
  visible: boolean;
};

/**
 * Тип свойства типов событий
 */
export type EventTypeProperty = {
  cls: string;
  eventType: EventType;
  group: string;
  groupOrder: number;
  name: string;
  prettyName: string;
};

/**
 * Тип свойства
 *
 * Попробую описать логический, что это за три списка (На истину в последней инстанции не претендую, но понять смысл поможет)
 * userProps - свойства лидов. Записываются ботами, триггерными сообщениями, операторами в лида и, можно сказать, являются описательными штуками
 * eventTypes - события, которые совершаются в моменты, описанные в мастере сбора данных (или тригерящиеся где-то вручную), которые сами являются триггерами для различных рассылок, ботов, сообщений и тд
 * eventTypeProps - свойства лидов, которые записываются на основе данных по событиям. Первое/последнее событие и счетчик (сколько всего было у лида таких событий)
 */
export type Properties = {
  eventTypeProps: EventTypeProperty[];
  eventTypes: EventType[];
  userProps: UserProperty[];
};

/**
 * Сервис для работы с событиями пользователя и типов событий
 */
@Injectable({ providedIn: 'root' })
export class PropertyModel {
  constructor(
    private readonly http: HttpClient,
    private readonly transloco: TranslocoService,
    private readonly eventTypeModel: EventTypeModel,
    private readonly featureModel: FeatureModel,
    private readonly appService: AppService,
  ) {}

  private DATE_REGEXP: RegExp = /^(\d\d\d\d)-(\d\d)-(\d\d)([Tt])(\d\d):(\d\d):(\d\d)$/;
  /**
   * Свойство пользователя по умолчанию
   *
   */
  private DEFAULT_USER_PROPERTY: UserProperty = {
    cls: CLICKHOUSE_PROPERTY_TYPE.STR,
    group: '',
    groupOrder: 0,
    name: '',
    prettyName: '',
    readonly: false,
  };

  /**
   * Создание свойства пользователя
   *
   * @param appId ID приложения
   * @param userProperties Название свойства или массив названий
   */
  public createUserProperty(appId: string, userProperties: string): Observable<UserProperty>;
  public createUserProperty(appId: string, userProperties: string[]): Observable<UserProperty[]>;
  public createUserProperty(
    appId: string,
    userProperties: string | string[],
  ): Observable<UserProperty | UserProperty[]> {
    let body = {
      names: Array.isArray(userProperties) ? userProperties : [userProperties],
    };

    return this.http.post<any>('/apps/' + appId + '/create_user_props_meta', body).pipe(
      map((data) => {
        let allProperties: any[] = [];
        let allowedProperties = data.allowedProps;
        let ignoredProperties = data.ignoredProps;

        for (let i = 0; i < allowedProperties.length; i++) {
          this.parseProperty(allowedProperties[i]);
        }

        for (let i = 0; i < ignoredProperties.length; i++) {
          ignoredProperties[i] = this.parseIgnoredProperty(ignoredProperties[i]);
        }

        Object.assign(allProperties, allowedProperties, ignoredProperties);

        // если создавалось одно свойство - возвращаем именно свойство, а не массив свойств
        if (allProperties.length == 1) {
          return allProperties[0];
        } else {
          return allProperties;
        }
      }),
    );
  }

  /**
   * Фильтрация свойств для мастера сбора данных
   *
   * NOTE используется ТОЛЬКО в мастере сбора данных
   *
   * @param properties свойства
   */
  public filterPropsForTrackMaster(properties: Properties): UserProperty[] {
    const list: UserProperty[] = [];
    for (let i = 0; i < properties.userProps.length; i++) {
      const userProp = properties.userProps[i];

      if (userProp.name.indexOf('$') !== 0 || ['$name', '$email', '$phone'].includes(userProp.name)) {
        list.push(userProp);
      }
    }
    return list;
  }

  /**
   * Получение свойства пользователя по умолчанию
   *
   * @param userPropertyName Название свойства
   */
  public getDefaultUserProperty(userPropertyName: UserProperty['name']): UserProperty {
    const defaultUserProperty = clone(this.DEFAULT_USER_PROPERTY);

    defaultUserProperty.group = this.transloco.translate('models.property.groups.customProps');
    defaultUserProperty.groupOrder = 5;
    defaultUserProperty.name = userPropertyName;
    defaultUserProperty.prettyName = this.parseUserPropertyName(userPropertyName);
    defaultUserProperty.readonly = false;

    return defaultUserProperty;
  }

  /**
   * Возвращает имя группы по свойству операции
   *
   * @param elasticsearchPropertyOperation
   */
  public getElasticsearchOperationTypeByOperation(
    elasticsearchPropertyOperation: ELASTICSEARCH_PROPERTY_OPERATIONS,
  ): ELASTICSEARCH_OPERATION_TYPES {
    let foundType: ELASTICSEARCH_OPERATION_TYPES;

    for (let type in ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE) {
      if (
        ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE.hasOwnProperty(type) &&
        !!~ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[type].indexOf(elasticsearchPropertyOperation)
      ) {
        foundType = type as ELASTICSEARCH_OPERATION_TYPES;
      }
    }

    return foundType!;
  }

  /**
   * Получение списка свойств
   *
   * NOTE: если нужно получить только типы событий, то лучше использовать метод this.eventTypeModel.getList()
   *
   * @param appId ID приложения
   */
  public getList(appId: string): Observable<Properties> {
    let params = {
      include_not_visible: true,
    };
    return this.http.get<any>('/apps/' + appId + '/userpropsmeta', { params }).pipe(
      map((data) => {
        return this.parseProperties(data);
      }),
    );
  }

  /**
   * Парсинг значений свойств события
   *
   * @param properties Свойства события NOTE можгут приходить какие угодно свойства т.к. набор свойств у каждого свой
   */
  public parseEventProperties(properties: any) {
    // NOTE: пока что из свойств события парсятся только даты
    for (let propertyName in properties) {
      if (properties.hasOwnProperty(propertyName)) {
        const propertyValue = properties[propertyName];

        if (this.DATE_REGEXP.test(propertyValue)) {
          properties[propertyName] = moment(propertyValue + 'Z', 'YYYY-MM-DDTHH:mm:ssZ');
        }

        // Так как значением тут может быть routing_bot а клиенты такого названия не знают - решили перевести все эти значения
        if (propertyName === '$event_source_type') {
          properties[propertyName] = this.transloco.translate(`models.property.eventSourceTypes.${propertyValue}`);
        }
      }
    }
  }

  /**
   * Парсинг названия свойства события
   *
   * NOTE: временный метод, который используется в фильтре. Лучше его нигде больше не использовать. Будет удалён, когда все свойства события будут парситься через модель
   *
   * @param eventPropertyName Свойство события
   *
   * @return Если свойство системное - оно переведётся, иначе - вернётся то же, что было на входе
   */
  public parseEventPropertyName(eventPropertyName: string): string {
    return ~EVENT_SYSTEM_PROPERTIES.indexOf(eventPropertyName)
      ? this.transloco.translate('models.property.eventSystemProperties.' + eventPropertyName)
      : eventPropertyName;
  }

  /**
   * Парсинг свойства пользователя
   *
   * @param property Свойство пользователя
   */
  public parseProperty(property: ApiUserPropertyResponse): void {
    property.prettyName = this.parseUserPropertyName(property.name);

    // Определение readonly свойств
    property.readonly = !!(!~EDITABLE_USER_PROPERTIES.indexOf(property.name) && ~property.name.indexOf('$'));

    // FIXME: сделать константы для групп свойств
    // определение группы свойства пользователя
    if (
      ~[
        '$initial_utm_campaign',
        '$initial_utm_content',
        '$initial_utm_medium',
        '$initial_utm_source',
        '$initial_utm_term',
        '$last_utm_campaign',
        '$last_utm_content',
        '$last_utm_medium',
        '$last_utm_source',
        '$last_utm_term',
      ].indexOf(property.name)
    ) {
      property.group = this.transloco.translate('models.property.groups.utm');
      property.groupOrder = 1;
    } else if (~['$city', '$country', '$latitude', '$longitude', '$region'].indexOf(property.name)) {
      property.group = this.transloco.translate('models.property.groups.geography');
      property.groupOrder = 2;
    } else if (
      ~[
        '$cart_amount',
        '$cart_items',
        '$discount',
        '$group',
        '$last_order_status',
        '$last_payment',
        '$ordered_categories',
        '$ordered_items',
        '$orders_count',
        '$profit',
        '$revenue',
        '$viewed_categories',
        '$viewed_products',
      ].indexOf(property.name)
    ) {
      property.group = this.transloco.translate('models.property.groups.eCommerce');
      property.groupOrder = 3;
    } else if (~['$bitrix24_id', '$bitrix24_responsible', '$retailcrm_id'].indexOf(property.name)) {
      property.group = this.transloco.translate('models.property.groups.crm');
      property.groupOrder = 4;
    } else if (property.name.indexOf('$') == 0) {
      property.group = this.transloco.translate('models.property.groups.basicProps');
      property.groupOrder = 0;
    } else {
      property.group = this.transloco.translate('models.property.groups.customProps');
      property.groupOrder = 5;
    }
  }

  /**
   * Парсинг названия свойства пользователя
   *
   * NOTE: временный метод, который используется в фильтре. Лучше его нигде больше не использовать. Будет удалён, когда все свойства пользователя будут парситься через модель
   *
   * @param userPropertyName Свойство пользователя
   *
   * @return Если свойство системное - оно переведётся, иначе - вернётся то же, что было на входе
   */
  public parseUserPropertyName(userPropertyName: string): string {
    if (~USER_SYSTEM_PROPERTIES.indexOf(userPropertyName as UserSystemProperty)) {
      const userActivatedInSdk =
        Boolean(this.appService.app.activation.installed_sdk_ios) ||
        Boolean(this.appService.app.activation.installed_sdk_android);
      if (userPropertyName === '$sdk_push_subscribed' && !userActivatedInSdk) {
        return userPropertyName;
      }

      return this.transloco.translate(`models.property.userSystemProperties.${userPropertyName}`);
    } else {
      return userPropertyName;
    }
  }

  /**
   * Парсинг значений свойств пользователя
   *
   * @param properties Свойства пользователя NOTE можгут приходить какие угодно свойства т.к. набор свойств у каждого свой
   * @param propsTypesConverted В каком формате приходят значения свойств пользователя: false - строкой, true - распарсеными
   */
  public parseUserProperties(properties: any, propsTypesConverted?: boolean) {
    const parse = (propertyName: string) => {
      let propertyValue = properties[propertyName];

      // NOTE Не уверен, что здесь нужно парсить это свойство
      if (propertyName === '$email_status') {
        properties[propertyName] = this.transloco.translate('models.emailStatus.statuses.' + propertyValue);
      } else if (propertyName === '$last_seen') {
        properties[propertyName] = moment(propertyValue + 'Z', 'YYYY-MM-DDTHH:mm:ssZ');
      }
    };

    // Если свойства уже распарсены, то пробуем распарсить только дату
    if (propsTypesConverted) {
      for (let propertyName in properties) {
        if (properties.hasOwnProperty(propertyName)) {
          parse(propertyName);
        }
      }
    } else {
      for (let propertyName in properties) {
        if (properties.hasOwnProperty(propertyName)) {
          // Пробуем распарсить из строки массив
          try {
            let propertyValue = JSON.parse(properties[propertyName]);

            // HACK: Если в результате парсинга получилось число слишком большое число то оно округлится.
            // Пришлось сделать ксотыль
            if (typeof propertyValue != 'number') {
              properties[propertyName] = propertyValue;
            }
          } catch (e) {
            parse(propertyName);
          }
        }
      }
    }
  }

  /**
   * Фильтрация событий
   *
   * @param eventType - Объект с информацией о событии
   */
  private filterEventTypes(eventType: EventType): boolean {
    if (!eventType.name.includes('$push')) {
      return true;
    }
    return this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.appService.app && this.appService.app.created);
  }

  /**
   * Фильтрация свойств событий
   */
  private filterEventTypeProps(eventTypeProp: EventTypeProperty): boolean {
    return this.filterEventTypes(eventTypeProp.eventType);
  }

  /**
   * Фильтрация свойств
   *
   * @param property - Объект с информацией о свойстве
   */
  private filterProperty(property: UserProperty): boolean {
    if (!property.name.includes('$')) {
      return true;
    }
    if (!property.name.includes('push')) {
      return true;
    }
    if (property.name === '$sdk_push_subscribed') {
      return true;
    }
    if (property.name.startsWith('$message_scheduler_')) {
      return false;
    }
    return this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.appService.app && this.appService.app.created);
  }

  /**
   * Парсинг свойств
   *
   * @param properties Свойства пользователя и типов событий
   */
  private parseProperties(properties: ApiGetPropertyResponse[]): Properties {
    const parsedProperties: Properties = {
      userProps: [],
      eventTypes: [],
      eventTypeProps: [],
    };

    let parsedUserPropsList: UserProperty[] = []; // свойства пользователя
    let parsedEventTypes: Record<string, EventType> = {}; // типы событий !!! это объект, сделано для оптимизации, смотри код ниже
    let parsedEventTypePropsList: EventTypeProperty[] = []; // свойства типов событий !!! это не так, это свойства именно событий, которые произошли, а не их типов

    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];

      // если свойство начинается с $event_ - это свойство типа события, иначе - свойство пользователя
      if (!property.name.includes('$event_')) {
        this.parseProperty(property);

        // HACK: свойства, которые после перевода начинаются с доллара не должны попадать в список и показываться пользователям, т.к. это внутренние системные свойства
        if (property.prettyName?.indexOf('$') == 0) {
          continue;
        }

        parsedUserPropsList.push(property as UserProperty);
      } else {
        let eventType = parsedEventTypes[property.eventType!.id];
        // у свойств типов событий сами типы событий повторяются, т.е. в массив с типами событий нужно добавлять их только 1 раз
        if (!eventType) {
          eventType = property.eventType as EventType;
          this.eventTypeModel.parse(eventType);
          parsedEventTypes[eventType.id] = eventType;
        } else {
          property.eventType = eventType;
        }

        // определение имени свойства типа события
        // FIXME: пока что переводы хранятся в этой модели, но это неправильно, т.к. это названия для свойств типов событий
        if (~property.name.indexOf('_first')) {
          property.prettyName =
            eventType.prettyName + ' ' + this.transloco.translate('models.property.eventTypePropertyNamePart.first');
        } else if (~property.name.indexOf('_last')) {
          property.prettyName =
            eventType.prettyName + ' ' + this.transloco.translate('models.property.eventTypePropertyNamePart.last');
        } else if (~property.name.indexOf('_count')) {
          property.prettyName =
            eventType.prettyName + ' ' + this.transloco.translate('models.property.eventTypePropertyNamePart.counter');
        }

        // определение группы свойства типа события
        property.group = eventType.group;
        property.groupOrder = eventType.groupOrder;

        parsedEventTypePropsList.push(property as EventTypeProperty);
      }
    }

    // чтобы соблюдался порядок группировок (например, все пользовательские свойства внизу списка, архивные события тоже) надо отсортировать все 3 массива по заданному порядку групп
    // NOTE: такая сортировка будет игнорироваться только ui-select, там надо отдельно сортировать группы внутри атрибута repeat
    // !!! Внутри групп списки не сортируются, потому что сортировки портачат некоторый фунционал (например, таблицу лидов). Так что сортировка внутри групп выполняется всегда индивидуально, в контроллерах/темплейтах. Это, наверное, не очень правильно, нужно будет переделать, чтобы сортировка выполнялась тут
    parsedProperties.userProps = parsedUserPropsList
      .filter(this.filterProperty.bind(this))
      .sort((a, b) => a.groupOrder - b.groupOrder);

    parsedProperties.eventTypes = Object.values(parsedEventTypes)
      .filter(this.filterEventTypes.bind(this))
      .sort((a, b) => a.groupOrder - b.groupOrder);

    parsedProperties.eventTypeProps = parsedEventTypePropsList
      .filter(this.filterEventTypeProps.bind(this))
      .sort((a, b) => a.groupOrder - b.groupOrder);

    return parsedProperties;
  }

  /**
   * Парсинг свойства, в которое нельзя записывать значение
   * Отличается от обычного тем, что изначально это не объект, а строка, и нет никакой информации о его классе (поле cls в обычном свойстве)
   *
   * @param property Свойство
   */
  private parseIgnoredProperty(property: string): UserProperty {
    let parsedProperty: ApiUserPropertyResponse = {
      name: property,
    };

    this.parseProperty(parsedProperty);

    return parsedProperty as UserProperty;
  }
}
