<ng-container *transloco="let t; read: 'acmPromtComponent'">
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <label for="acm-promt">{{ t('label') }}</label>
    <div class="position-relative">
      <textarea
        class="form-control resize-vertical custom-scroll"
        [formControl]="control"
        [placeholder]="t('placeholder')"
        id="acm-promt"
        rows="3"
      ></textarea>
      <cq-validation-messages [control]="control">
        <cq-validation-message errorName="required">
          {{ t('errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{
            t('errors.minLength', {
              minLength: MIN_LENGTH_MESSAGE_CONTEXT
            })
          }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>
</ng-container>
