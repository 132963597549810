import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EventTypeActionComponent } from './event-type-action.component';

@NgModule({
  declarations: [EventTypeActionComponent],
  exports: [EventTypeActionComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    EventTypeSelectModule,
    SharedModule,
  ],
})
export class EventTypeActionModule {}
