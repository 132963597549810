import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { StateService } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { AppTask } from '@http/app/app.types';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { QuestionsService } from '@panel/app/pages/questions/questions.service';
import { AnswersOnQuestion, Question } from '@panel/app/pages/questions/questions.type';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-questions[currentApp][djangoUser][djangoUserTempData]',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  @Input()
  djangoUserTempData!: DjangoUserTempData;

  /** Последний ответ на текущий вопрос выбранный пользователем */
  savedAnswerToCurrentQuestions: AnswersOnQuestion | undefined;

  /** Текущий вопрос */
  currentQuestion: Question = this.questionsService.questionsSchema[0];

  /** Список вопросов */
  questions: Question[] = this.questionsService.questionsSchema;

  /** Линейная ли структура вопросов */
  isLinearQuestionStructure = this.questionsService.isLinearQuestionsStructure(this.questions);

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly djangoUserModel: DjangoUserModel,
    public readonly l10nHelper: L10nHelperService,
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
    private readonly questionsService: QuestionsService,
    private readonly starterGuideModel: StarterGuideModel,
    private readonly $state: StateService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    //Если схема опросника линейная, то запоминать шаг на котором остановишься
    if (this.isLinearQuestionStructure) {
      this.initInterruptedQuestion();
    }

    this.trackOpenedQuestions();
  }

  /** Обновляем данные пользователя: записываем выбранную задачу в свойство */
  identifySelectedTask(task: AppTask) {
    const propertyName = this.l10nHelper.isUsCountry()
      ? 'What will you be using Dashly for?'
      : 'Для чего вы будете использовать Carrot quest?';
    const propertyValue = this.translocoService.translate(`models.appModel.appTasks.${task}.prop`);

    this.carrotquestHelper.identify([
      {
        op: 'update_or_create',
        key: propertyName,
        value: propertyValue,
      },
    ]);
  }

  /** Инициализация запомненного вопроса */
  initInterruptedQuestion() {
    if (!this.djangoUserTempData.interruptedQuestionId) {
      return;
    }

    this.currentQuestion = this.questionsService.getQuestionById(this.djangoUserTempData.interruptedQuestionId)!;
  }

  /** Сохраняем ответы на вопросы */
  savedAnswerToQuestion(question: Question, answerOnQuestion: AnswersOnQuestion) {
    const savedAnswers = this.djangoUserTempData.savedAnswers ? this.djangoUserTempData.savedAnswers : [];

    let savedAnswerIndex = savedAnswers.findIndex((savedAnswer) => savedAnswer.questionId === question.id);
    savedAnswerIndex = savedAnswerIndex >= 0 ? savedAnswerIndex : question.id - 1;

    const interruptedQuestionId = answerOnQuestion.isLast
      ? this.djangoUserTempData.interruptedQuestionId
      : this.questionsService.getNextQuestion(answerOnQuestion).id;

    savedAnswers[savedAnswerIndex] = {
      questionId: question.id,
      answerId: answerOnQuestion.id,
    };

    this.djangoUserTempData = {
      ...this.djangoUserTempData,
      savedAnswers: savedAnswers,
      interruptedQuestionId,
    };

    firstValueFrom(this.djangoUserModel.saveTempData(this.djangoUser.id, this.djangoUserTempData));
  }

  /** Обработчик выбора задачи */
  selectTask(task: AppTask) {
    this.identifySelectedTask(task);

    this.djangoUserTempData['task'] = task;

    firstValueFrom(this.starterGuideModel.setUsageGoal(this.currentApp.id, task));

    this.sendingTasksAnalyticsToOtherSources(task);

    this.localStorage.setItem('carrotquest_selected_task', task.toString());

    firstValueFrom(this.djangoUserModel.saveTempData(this.djangoUser.id, this.djangoUserTempData));

    this.redirectToPanel();
  }

  /** Редирект в панель */
  redirectToPanel() {
    this.$state.go('app', {
      appId: this.currentApp.id,
      fromQuestions: true,
    });
  }

  /** Отправка аналитических данных при выборе задачи сервиса */
  sendingTasksAnalyticsToOtherSources(task: AppTask) {
    const taskText = this.translocoService.translate(`models.appModel.appTasks.${task}.prop`);
    // Отправка данных в GA
    if (dataLayer) {
      dataLayer.push({
        event: 'UAevent',
        eventCategory: 'registration',
        eventAction: 'start goals',
        eventLabel: taskText,
      });
    }
  }

  /** Установка вопроса */
  setQuestion(answerOnQuestion: AnswersOnQuestion) {
    this.trackAnswerOfQuestion(answerOnQuestion);
    this.identifyAnswerQuestion(answerOnQuestion);

    if (answerOnQuestion.isLast) {
      this.selectTask(answerOnQuestion.task);
      return;
    }

    const nextQuestion = this.questionsService.getNextQuestion(answerOnQuestion);

    if (this.isLinearQuestionStructure) {
      this.savedAnswerToQuestion(this.currentQuestion, answerOnQuestion);
    }

    this.currentQuestion = nextQuestion;
  }

  /** Установка вопроса */
  setQuestionById(id: number) {
    this.currentQuestion = this.questionsService.getQuestionById(id)!;

    this.savedAnswerToCurrentQuestions = this.getSavedAnswerToQuestion(this.currentQuestion);
  }

  /** Находим последний ответ на вопрос */
  getSavedAnswerToQuestion(question: Question): AnswersOnQuestion | undefined {
    const answerOnQuestion = this.djangoUserTempData.savedAnswers?.find(
      (answerOfQuestion) => answerOfQuestion.questionId === question.id,
    );

    if (!answerOnQuestion) {
      return;
    }

    return question.answers.find((answer) => answer.id === answerOnQuestion.answerId);
  }

  /** Трекаем заход на опросник */
  trackOpenedQuestions() {
    this.carrotquestHelper.track('Onboarding v2 - открыл опросник');
  }

  /** Трекаем ответ на вопрос */
  trackAnswerOfQuestion(answerOnQuestion: AnswersOnQuestion) {
    if (answerOnQuestion.event) {
      this.carrotquestHelper.track(answerOnQuestion.event);
      return;
    }

    const currentQuestion = this.currentQuestion;
    if (currentQuestion.track?.event) {
      this.carrotquestHelper.track(currentQuestion.track.event);
      return;
    }
  }

  /** Трекаем ответ на вопрос для записи в св-во пользователя */
  identifyAnswerQuestion(answerOnQuestion: AnswersOnQuestion) {
    const currentQuestion = this.currentQuestion;
    if (currentQuestion.track?.prop) {
      this.carrotquestHelper.identify({
        [currentQuestion.track.prop]: this.translocoService.translate(answerOnQuestion.text),
      });
    }
  }
}
