import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import {
  MessageAttachment,
  MessageAttachmentTemporary,
  MessageTelegramContent,
} from '@http/message-part/message-part.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

@Component({
  selector: 'cq-media-blocks-editor',
  templateUrl: './media-blocks-editor.component.html',
  styleUrls: ['./media-blocks-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaBlocksEditorComponent extends AbsCVAFormGroupBasedComponent<
  ToFormGroupControls<MessageTelegramContent>
> {
  @Output()
  readonly delete = new EventEmitter<void>();

  readonly MAX_FILE_SIZE = 10 * 1024 * 1024;
  readonly MAX_IMAGE_SIZE = 5 * 1024 * 1024;
  readonly MAX_IMAGE_SUM_WIDTH_AND_HEIGHT = 10000; // Суммарное разрешение (width + height)
  readonly MAX_IMAGE_RATIO = 20;

  readonly control: FormGroup<ToFormGroupControls<MessageTelegramContent>> = this.fb.group<
    ToFormGroupControls<MessageTelegramContent>
  >({
    type: this.fb.control<'file' | 'video_note'>('file', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    value: this.fb.control<string | null>(null),
    // TODO Car-64627
    attachment: this.fb.control<MessageAttachment | MessageAttachmentTemporary | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    readonly fileHelperService: FileHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  // TODO Car-64627
  get file(): MessageAttachment | MessageAttachmentTemporary | null {
    return this.control.controls.attachment.value;
  }

  async onFileChange(event: NgxDropzoneChangeEvent) {
    // rejectedFiles сюда добавлены, чтоб до клиента все равно доходила ошибка
    const files = [...event.addedFiles, ...event.rejectedFiles];

    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];

    if (file.size > this.MAX_FILE_SIZE) {
      const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.maxFileSize', {
        maxFileSize: this.MAX_FILE_SIZE / 1024 / 1024,
      });
      this.toastService.danger(message);
      return;
    }

    if (file.size === 0) {
      const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.emptyFileSize');
      this.toastService.danger(message);
      return;
    }

    // Специфичная валидация для каждого из типов
    let isValid = true;
    if (this.control.controls.type.value === 'file') {
      isValid = await this.isFileValid(file);
    } else {
      isValid = this.isVideoNoteFileValid(file);
    }
    if (!isValid) {
      return;
    }

    // TODO Car-64627
    let fileObj: MessageAttachmentTemporary = {
      filename: file.name,
      size: file.size,
      mimeType: file.type,
      fileBlob: new Blob([file], { type: file.type }),
      url: '',
    };
    this.control.controls.attachment.setValue(fileObj);
    // this.control.controls.attachment.setValue(file);
    this.cdr.markForCheck();
    this.readFile(file).then(
      function (file: any, fileContents: any) {
        file.url = fileContents;
        fileObj.url = fileContents;
      }.bind(this, file),
    );
  }

  /**
   * Валидация video_note файлов
   * @param file
   * @private
   */
  private isVideoNoteFileValid(file: File) {
    if (!this.fileHelperService.isAcceptedVideoExtension(file.name)) {
      const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.videoNoteExtension');
      this.toastService.danger(message);
      return false;
    }
    return true;
  }

  /**
   * Валидация файлов
   * @param file
   * @private
   */
  private async isFileValid(file: File): Promise<boolean> {
    if (!this.fileHelperService.isAcceptedExtension(file.name)) {
      const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.fileExtension');
      this.toastService.danger(message);
      return false;
    }

    // У телеги разные ограничения на размер изображений и файлов
    if (file.size > this.MAX_IMAGE_SIZE && this.fileHelperService.isAcceptedImageExtension(file.name)) {
      const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.maxImageSize', {
        maxFileSize: this.MAX_IMAGE_SIZE / 1024 / 1024,
      });
      this.toastService.danger(message);
      return false;
    }

    if (this.fileHelperService.isAcceptedImageExtension(file.name)) {
      try {
        const { width, height } = await this.loadImage(file);

        if (width + height > this.MAX_IMAGE_SUM_WIDTH_AND_HEIGHT) {
          const message = this.translocoService.translate(
            'mediaBlocksEditorComponent.toasts.maxImageSumWidthAndHeight',
          );
          this.toastService.danger(message);
          return false;
        } else if (Math.max(width / height, height / width) > this.MAX_IMAGE_RATIO) {
          const message = this.translocoService.translate('mediaBlocksEditorComponent.toasts.maxImageRatio');
          this.toastService.danger(message);
          return false;
        }
      } catch (e) {
        console.error('Failed image load', e);
        return false;
      }
    }

    return true;
  }

  private readFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve(e.target!.result! as string);
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      reader.readAsDataURL(file);
    });
  }

  loadImage(file: File): Promise<{ width: number; height: number }> {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function (e) {
        reject(e);
      };
      img.src = URL.createObjectURL(file);
    });
  }

  removeFile() {
    this.control.controls.attachment.setValue(null);
  }

  get fileName(): string {
    if (this.file) {
      return this.file instanceof File ? this.file.name : this.file.filename;
    }

    return '';
  }
}
