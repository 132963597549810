<section id="content">
  <div
    class="container-fluid"
    *ngIf="hasAccessToPage; else noAccess"
  >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Информация о биллинге аппа
          <span class="badge badge-secondary">Beta</span>
        </li>
      </ol>
    </nav>

    <div class="card">
      <div class="card-body">
        <!--Текущий тариф-->
        <div class="mb-10">
          <h2>Текущий тариф</h2>
          <div>
            {{ billingInfoModel.planId }}
          </div>
          <div>
            Версия тарифа:
            <strong>
              <ng-container *ngTemplateOutlet="planVersionTpl"></ng-container>
            </strong>
          </div>
        </div>

        <!--Проверить досупность фичи-->
        <div class="mb-20">
          <h2>Проверить досупность фичи</h2>
          <div>
            <!--Badges-->
            <ng-container>
              <ng-container *ngIf="featureToCheck; else noSelectedFeature">
                <div
                  *ngIf="getAccess(featureToCheck); else hasNoAccessToFeature"
                  class="badge badge-success mb-10"
                >
                  Работает
                </div>
                <ng-template #hasNoAccessToFeature>
                  <div class="badge badge-danger mb-10">Не работает</div>
                </ng-template>
              </ng-container>
              <ng-template #noSelectedFeature>
                <div class="badge badge-light-secondary mb-10">Unknown</div>
              </ng-template>
            </ng-container>
            <!--Feature selector-->
            <ng-select
              [clearable]="false"
              [(ngModel)]="featureToCheck"
              [items]="features"
              class="mb-10"
              placeholder="Выбери фичу для проверки"
            >
              <ng-template
                ng-label-tmp
                ng-option-tmp
                let-item="item"
              >
                {{ item }}
                <!--NOTE При отсутствии переводов в коноль падает куча ошибок. Пока закоментил-->
                <!--                <span *ngIf="getProductFeatureName(item)">-->
                <!--                  - <i class="text-secondary">{{ getProductFeatureName(item) }}</i>-->
                <!--                </span>-->
              </ng-template>
            </ng-select>
            <!--Инструкция по подключению-->
            <ng-container *ngIf="featureToCheck && !getAccess(featureToCheck)">
              <ngb-alert
                type="secondary"
                [dismissible]="false"
              >
                <div>
                  Чтобы подключить {{ featureToCheck }} для
                  <ng-container *ngTemplateOutlet="planVersionTpl"></ng-container>
                  тарифа надо:
                </div>
                <ng-container *ngIf="planFeatureService.isSpecialFeature(featureToCheck); else planPlugInInstruction">
                  Пока не доступно
                </ng-container>
                <ng-template #planPlugInInstruction>
                  <div *ngIf="getCapsToPlugIn(featureToCheck).length">
                    Капы:
                    <span *ngIf="getCapsToPlugIn(featureToCheck).length > 1">(один из)</span>
                    <span
                      class="badge badge-light-warning mr-5 mb-5"
                      *ngFor="let cap of getCapsToPlugIn(featureToCheck)"
                    >
                      {{ cap }}
                    </span>
                  </div>
                  <div
                    *ngIf="getAddonsToPlugIn(featureToCheck).length && getCapsToPlugIn(featureToCheck).length"
                    class="mb-5"
                  >
                    или
                  </div>
                  <div *ngIf="getAddonsToPlugIn(featureToCheck).length">
                    Аддоны:
                    <span *ngIf="getAddonsToPlugIn(featureToCheck).length > 1">(один из)</span>
                    <span
                      class="badge badge-light-success mr-5 mb-5"
                      *ngFor="let addon of getAddonsToPlugIn(featureToCheck)"
                    >
                      {{ addon }}
                    </span>
                  </div>
                </ng-template>
              </ngb-alert>
            </ng-container>
            <!--Примечание-->
            <ng-container>
              <div
                class="cursor-pointer"
                (click)="isFeaturesSelectNoteCollapsed = !isFeaturesSelectNoteCollapsed"
              >
                Примечание
                <i
                  class="cqi-sm ml-5"
                  [ngClass]="{
                    'cqi-chevron-up': !isFeaturesSelectNoteCollapsed,
                    'cqi-chevron-down': isFeaturesSelectNoteCollapsed
                  }"
                ></i>
              </div>
              <div
                class="card mt-10"
                [(ngbCollapse)]="isFeaturesSelectNoteCollapsed"
              >
                <div class="card-body">
                  Фича !== капабилити или аддон. Это савокупность наличия одного из них или сразу обоих. В зависимости
                  от версии тарифа могут проверяться разные аддоны/капы.
                  <blockquote class="border-left pl-15 my-5">
                    <i>Напр.</i>
                    <br />
                    Доступ до фичи <code>lead_bots</code> в LTD тарифе будет проверяться по капе
                    <code>lead_bots_total</code>, а в тарифе V1 по аддону <code>chat_bots</code>
                  </blockquote>
                  Некоторые фичи могут врать о своей досупности т.к. для корректной проверки нужно количество (наприимер
                  активных ботов), чего тут не сделано.
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!--Подключенные аддоны-->
        <div class="mb-20">
          <h2>Подключенные аддоны</h2>
          <div *ngIf="billingInfo.addons.length; else hasNoAddons">
            <input
              placeholder="Фильтр аддонов"
              class="form-control mb-10"
              [(ngModel)]="filterAddonText"
              (ngModelChange)="filterAddons()"
              type="text"
            />
            <span
              class="badge badge-light-success mr-10 mb-10"
              *ngFor="let addon of filteredAddons"
            >
              {{ addon }}
            </span>
          </div>
          <ng-template #hasNoAddons> Нет подключенных аддонов </ng-template>
        </div>

        <!--Подключенные капы-->
        <div class="mb-20">
          <h2>Подключенные капы</h2>
          <div *ngIf="capabilities.length; else hasNoCaps">
            <input
              placeholder="Фильтр по капабилити"
              class="form-control mb-10"
              [(ngModel)]="filterCapText"
              (ngModelChange)="filterCaps()"
              type="text"
            />
            <span
              class="badge badge-light-warning mr-10 mb-10"
              *ngFor="let cap of filteredCaps"
            >
              {{ cap.name }}
            </span>
          </div>
          <ng-template #hasNoCaps> Нет подключенных капабилити </ng-template>
        </div>

        <!---->
        <div class="mb-20">
          <h2></h2>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noAccess>
    <div class="container-fluid h-100 d-flex align-items-center justify-center">
      <h1>You have no access</h1>
    </div>
  </ng-template>
  <ng-template #planVersionTpl>
    <span *ngIf="planVersionService.isTrial()"> Trial </span>
    <span *ngIf="!planVersionService.isTrial()"> {{ planVersion }} </span>
  </ng-template>
</section>
