/**
 * Типы агрегаций
 */
import { inject, InjectionToken } from '@angular/core';

import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

export enum CONVERSATION_STATISTICS_AGGREGATIONS {
  /** среднее значение (например, для времени ответа или размера диалога) */
  AVG = 'AVG',
  /** максимальное значение */
  MAX = 'MAX',
  /** минимальное значение */
  MIN = 'MIN',
}

/**
 * Форматы статистики
 */
export enum CONVERSATION_STATISTICS_FORMATS {
  /**
   * ответ в виде двух массивов,
   *
   * один массив - даты в виде строк,
   *
   * второй массив - данные для каждой даты ([['2017-01-01', '2017-01-02'], [2, 5]])
   */
  ARRAYS = 'arrays',
  /** в виде объекта объектов ({'2017-01-01': 2, '2017-01-02': 5}) */
  DICT = 'dict',
  TUPLE_ARRAY = 'tuple_array',
}

/**
 * Группировоки статистики
 */
export enum CONVERSATION_STATISTICS_RANGES {
  /** группировка по часам */
  HOUR = 'hour',
  /** по дням */
  DAY = 'day',
  /** по неделям */
  WEEK = 'week',
  /** за весь период */
  ALL_PERIOD = 'no',
}

/**
 * Доступные типы статистики
 */
export enum CONVERSATION_STATISTICS_TYPES {
  /** статистика по скорости ответа */
  ANSWER_SPEED = 'answer_speed',
  /** по кол-ву закрытых диалогов */
  CLOSED = 'closed',
  /** по кол-ву пользователей, которые закрыли диалог */
  CLOSED_BY_USER = 'closed_by_user',
  /** по кол-ву упущенных диалогов */
  LOST = 'lost',
  /** по кол-ву неотвеченных диалогов */
  NOT_ANSWERED = 'not_answered',
  /** по кол-ву открытых диалогов */
  OPENED = 'opened',
  /** по кол-ву пользователей, которые открыли диалог */
  OPENED_BY_USER = 'opened_by_user',
  /** по размеру диалога */
  SIZE = 'size',
}

/**
 * Типы источников
 */
export enum CONVERSATION_STATISTICS_SOURCES {
  ALL = 'all',
  CHAT = 'chat',
  TELEGRAM = 'telegram',
  WHATSAPP = 'whatsapp',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  VIBER = 'viber',
  EMAIL = 'email',
  SDK = 'sdk',
  VK = 'vk',
}

/**
 * Реализация константы выше для использования в A2+
 */
export const CONVERSATION_STATISTICS_SOURCES_LIST = new InjectionToken('List of sources', {
  factory: () => {
    const list = [
      CONVERSATION_STATISTICS_SOURCES.ALL,
      CONVERSATION_STATISTICS_SOURCES.CHAT,
      CONVERSATION_STATISTICS_SOURCES.TELEGRAM,
      CONVERSATION_STATISTICS_SOURCES.WHATSAPP,
      CONVERSATION_STATISTICS_SOURCES.INSTAGRAM,
      CONVERSATION_STATISTICS_SOURCES.FACEBOOK,
      CONVERSATION_STATISTICS_SOURCES.VIBER,
      CONVERSATION_STATISTICS_SOURCES.EMAIL,
      CONVERSATION_STATISTICS_SOURCES.SDK,
    ];

    if (inject(L10nHelperService).isRusCountry()) {
      list.push(CONVERSATION_STATISTICS_SOURCES.VK);
    }

    return list;
  },
});

/**
 * Все константы для получения статистики диалогов с сервера
 */
export const CONVERSATION_STATISTICS = {
  AGGREGATIONS: CONVERSATION_STATISTICS_AGGREGATIONS,
  FORMATS: CONVERSATION_STATISTICS_FORMATS,
  RANGES: CONVERSATION_STATISTICS_RANGES,
  TYPES: CONVERSATION_STATISTICS_TYPES,
  SOURCES: CONVERSATION_STATISTICS_SOURCES,
} as const;

/**
 * Возможные кодировки экспортированных файлов
 */
export enum CONVERSATION_STATISTICS_EXPORT_ENCODINGS {
  UTF_8 = 'utf-8',
  UTF_8_SIG = 'utf-8-sig',
  WINDOWS_1251 = 'windows-1251',
}

/**
 * Параметры, по которым можно получать статистику по диалогам операторов
 */
export enum CONVERSATION_OPERATOR_STATISTICS_TYPES {
  /** Скорость ответа оператора в нерабочее время */
  ANSWER_SPEED_NOT_WORKING_TIME = 'answer_speed_not_working_time',
  /** Скорость ответа оператора в рабочее время */
  ANSWER_SPEED_WORKING_TIME = 'answer_speed_working_time',
  /** Количество решенных диалогов */
  CLOSED = 'closed',
  /** Количество упушенных диалогов */
  LOST_CUSTOMERS = 'lost_customers',
  /** Количество неотвеченных диалогов */
  NOT_ANSWERED = 'not_answered',
  /** Количество диалогов, в которых участвует оператор */
  PARTICIPATED = 'participated',
  /** Средняя оценка оператора */
  VOTE = 'vote',
}
