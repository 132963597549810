import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgMathPipesModule } from 'ngx-pipes';

import { LinkEditorModalModule } from '@panel/app/pages/chat-bot/content/modals/link-editor/link-editor-modal.module';
import { MediaBlocksEditorComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/media-blocks-editor/media-blocks-editor.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [MediaBlocksEditorComponent],
  exports: [MediaBlocksEditorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbCollapseModule,
    SharedModule,
    LinkEditorModalModule,
    NgxDropzoneModule,
    FormsModule,
    NgMathPipesModule,
  ],
})
export class MediaBlocksEditorModule {}
