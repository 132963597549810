<ng-container *transloco="let t; read: 'eventTypeSelectComponent'">
  <div ngbDropdown>
    <button
      type="button"
      class="btn btn-outline-primary"
      ngbDropdownToggle
    >
      <i
        class="btn-icon cqi-sm"
        [ngClass]="getClassIconByEventType(control.value)"
      ></i>
    </button>
    <div ngbDropdownMenu>
      <button
        *ngFor="let type of typesList"
        ngbDropdownItem
        (click)="control.setValue(type)"
        type="button"
      >
        <i
          class="cqi-sm"
          [ngClass]="getClassIconByEventType(type)"
        ></i>
        <span class="ml-5">{{ t(type) }}</span>
      </button>
    </div>
  </div>
</ng-container>
