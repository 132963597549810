<ng-container *transloco="let t; read: 'acmPropsEditorComponent'">
  <div class="form-group">
    <label> {{ t('property.label') }}: </label>
    <div class="d-flex">
      <cq-property-selector
        class="w-100"
        [formControl]="control.controls.propertyName"
        [properties]="properties"
        [currentApp]="currentApp"
      ></cq-property-selector>
      <button
        class="btn btn-outline-primary ml-10"
        type="button"
        (click)="delete.emit(index)"
      >
        <i class="cqi-sm cqi-times"></i>
      </button>
    </div>
  </div>
  <div
    class="form-group"
    [cqShowError]="control.controls.default"
  >
    <label> {{ t('defaultValue.label') }}: </label>
    <input
      class="form-control"
      name="button"
      type="text"
      placeholder="{{ t('defaultValue.placeholder') }}"
      [formControl]="control.controls.default"
      required
    />
    <cq-validation-messages [control]="control.controls.default">
      <cq-validation-message errorName="required">
        {{ t('defaultValue.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
  <div
    [ngClass]="{
      'mb-20': isLast
    }"
  >
    <label for="variableDescription">{{ t('variableDescription.label') }}</label>
    <textarea
      class="form-control no-resize custom-scroll"
      [formControl]="control.controls.context"
      [placeholder]="t('variableDescription.placeholder')"
      id="variableDescription"
      rows="3"
    ></textarea>
    <cq-validation-messages [control]="control.controls.context">
      <cq-validation-message errorName="required">
        {{ t('variableDescription.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>

  <hr *ngIf="!isLast" />
</ng-container>
