import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { AiContentMakerIntegration } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration';
import { AiContentMakerIntegrationExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService, SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-ai-content-maker',
  templateUrl: './ai-content-maker.component.html',
  styleUrls: ['./ai-content-maker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AiContentMakerComponent implements OnInit {
  /** Текущая или новая интеграция во внешнем формате */
  @Input({ required: true })
  integrationExternal: AiContentMakerIntegrationExternal | undefined;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: AiContentMakerIntegration;
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  requestIsPending = false;

  /** ID текущего приложения */
  get currentAppId() {
    return this.appService.currentAppId;
  }

  /** Получение переведённого названия сервиса */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AI_CONTENT_MAKER);
  }

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  get settingControls() {
    return this.integration.form.controls.settings.controls;
  }

  get promtsControls() {
    return this.integration.form.controls.settings.controls.promptsJson.controls;
  }

  get isExistIntegration() {
    return !!this.integration.id;
  }

  constructor(
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly integrationFactory: IntegrationFactory,
    private readonly integrationService: IntegrationService,
    private readonly systemToastService: SystemToastService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.integration = this.integrationFactory.create(INTEGRATION_TYPES.AI_CONTENT_MAKER, this.integrationExternal);
  }

  /** Сохранение интеграции */
  save() {
    this.requestIsPending = true;
    this.integrationService
      .save(this.currentAppId, this.integration)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          this.systemToastService.requestError();
          throw err;
        }),
        finalize(() => {
          this.requestIsPending = false;
        }),
      )
      .subscribe(() => {
        this.showIntegrationSavedToast();
      });
  }

  create() {
    this.requestIsPending = true;
    this.integrationService
      .create(this.currentAppId, this.integration)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          this.systemToastService.requestError();
          throw err;
        }),
        finalize(() => {
          this.requestIsPending = false;
        }),
      )
      .subscribe((integration) => {
        this.integration = integration;
        this.showIntegrationCreatedToast();
        this.goToConfiguredIntegration(this.integration);
      });
  }

  /**
   * Перенаправление на настроенную интеграцию, после её создания
   *
   * @param integration - Созданная интеграция
   */
  goToConfiguredIntegration(integration: AiContentMakerIntegration) {
    const params = {
      integrationType: integration.type,
      integrationId: integration.id,
    };

    this.uiRouter.stateService.go(`app.content.integrations.details.configured.${integration.type}`, params, {
      location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
    });
  }

  submit() {
    this.integration.form.markAllAsTouched();
    if (this.integration.form.invalid) {
      return;
    }
    this.isExistIntegration ? this.save() : this.create();
  }

  /** Показ тоста успешного сохранения интеграции */
  showIntegrationSavedToast() {
    this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
  }

  /** Показ тоста успешного сохранения интеграции */
  showIntegrationCreatedToast() {
    this.toastService.success(this.translocoService.translate('integrations.integration.toasts.created'));
  }
}
