import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { ExtractIntegration, IntegrationInstance } from '@http/integration/integration.types';
import { AiContentMakerIntegration } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration';
import { AllokaIntegration } from '@http/integration/integrations/alloka/alloka-integration';
import { AmocrmIntegration } from '@http/integration/integrations/amo/amocrm-integration';
import { CalendlyIntegration } from '@http/integration/integrations/calendly/calendly-integration';
import { EmailNotificationIntegration } from '@http/integration/integrations/email-notification/email-notification-integration';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { GoogleAnalyticsIntegration } from '@http/integration/integrations/google-analytics/google-analytics-integration';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { VkIntegrationExternal } from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';
import { VkIntegration } from '@http/integration/integrations/vk/vk-integration';
import { VkFormsIntegrationExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { VkFormsIntegration } from '@http/integration/integrations/vk-forms/vk-forms-integration';
import { WhatsAppEdnaIntegration } from '@http/integration/integrations/whats-app-edna/whats-app-edna-integration';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';

/** Фабрика для работы с интеграциями */
@Injectable()
export class IntegrationFactory {
  constructor(private translocoService: TranslocoService) {}

  /**
   * Создание интеграции
   *
   * @param type - Тип интеграции
   * @param integrationExternal - Внешний формат интеграции
   */
  create<T extends ExtractIntegration>(
    type: T,
    integrationExternal?: EmailNotificationIntegrationExternal,
  ): IntegrationInstance<T>;
  create<T extends ExtractIntegration>(type: T, integrationExternal?: VkIntegrationExternal): IntegrationInstance<T>;
  create<T extends ExtractIntegration>(
    type: T,
    integrationExternal?: VkFormsIntegrationExternal,
  ): IntegrationInstance<T>;
  create(type: INTEGRATION_TYPES, integrationExternal?: IntegrationExternal): any;
  create(...args: any): any {
    switch (args[0]) {
      case INTEGRATION_TYPES.AI_CONTENT_MAKER:
        return new AiContentMakerIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.ALLOKA:
        return new AllokaIntegration();
      case INTEGRATION_TYPES.AMOCRM:
        return new AmocrmIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.CALENDLY:
        return new CalendlyIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        return new EmailNotificationIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.GOOGLE_ANALYTICS:
        return new GoogleAnalyticsIntegration();
      case INTEGRATION_TYPES.TELEGRAM:
        return new TelegramIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.VK:
        return new VkIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.VK_FORMS:
        return new VkFormsIntegration(this.translocoService, args[1]);
      case INTEGRATION_TYPES.WHATS_APP_EDNA:
        return new WhatsAppEdnaIntegration(this.translocoService, args[1]);
    }
  }
}
