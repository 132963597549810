import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { IPromise } from 'angular';
import { firstValueFrom } from 'rxjs';

import { AppModel } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { FeatureModel } from '@http/feature/feature.model';
import { OnboardingModel } from '@http/onboarding/onboarding.model';
import { AccountComponent } from '@panel/app/pages/account/pages/account/account.component';
import { UtilsService } from '@panel/app/services/utils/utils.service';

export const ACCOUNT_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'account',
    url: '/account?{django_user_id}&{hash}&{tab}&{path}',
    redirectTo: 'account.content',
    onEnter: onEnterAccount,
    params: {
      createAppLoading: {
        value: false,
        dynamic: true,
      },
      django_user_id: {
        dynamic: true,
      },
      hash: {
        dynamic: true,
      },
      tab: {
        dynamic: true,
      },
      path: {
        dynamic: true,
      },
    },
  },
  {
    name: 'account.content',
    component: AccountComponent,
    url: '',
    resolve: [
      {
        token: 'appList',
        deps: [AppModel, 'djangoUser'],
        resolveFn: getAppList,
      },
      {
        token: 'djangoUser',
        deps: ['$q', '$window', 'ipCookie', 'utilsService', DjangoUserModel],
        resolveFn: getDjangoUser,
      },
      {
        token: 'features',
        deps: [FeatureModel],
        resolveFn: getFeaturesList,
      },
    ],
  },
];

/**
 * Получение списка приложений
 *
 * @param appModel
 * @param djangoUser
 * @return {Promise}
 */
function getAppList(appModel: AppModel, djangoUser: any): Promise<any> {
  return firstValueFrom(appModel.getList());
}

/**
 * Получение информации по текущему пользователю
 *
 * @param $q
 * @param $window
 * @param ipCookie
 * @param utilsService
 * @param djangoUserModel
 * @returns {Promise}
 */
function getDjangoUser(
  $q: any,
  $window: any,
  ipCookie: any,
  utilsService: any,
  djangoUserModel: DjangoUserModel,
): IPromise<any> {
  return firstValueFrom(djangoUserModel.get()).catch((response: any) => {
    // ошибка в этом запросе обычно происходит при отсутствии доступа (но не всегда, ещё может сервер упасть), а когда доступ отсутствует - нужно затереть токен и перенаправить пользователя на страницу логина
    if (response && (response.status === 401 || response.status === 403)) {
      // Reset
      ipCookie.remove('carrotquest_auth_token_panel', { path: '/' });

      // удаление куки с django user id, которая записана для fullstory
      ipCookie.remove('carrotquest_django_user_id', { path: '/' });

      $window.location.href = utilsService.getExternalPagesAbsoluteUrl('login');
      return $q.reject();
    }
  });
}

function getFeaturesList(featureModel: FeatureModel) {
  return firstValueFrom(featureModel.getList());
}

function onEnterAccount($transition$: any, $state: any, utilsService: UtilsService, $window: any): any {
  Promise.all([$transition$.injector().getAsync('appList'), $transition$.injector().getAsync('djangoUser')]).then(
    ([appList, djangoUser]) => {
      // если пользователь должен был завершить регистрацию, но ещё этого не сделал - перенаправляем его туда
      if (appList.length === 1 && !OnboardingModel.isBaseDataChanged(appList[0], djangoUser)) {
        return $state.go('onboarding.appSettings', { appId: appList[0].id });
      }

      // Не перекидываем в апп, если пользователь приглашенный
      // Сделано так потому что приглашенного пользователя необходимо перевести в настройки на странице аккаунта
      if ($transition$.params().tab) {
        return;
      }

      // Не перекидываем в апп, если приходим из админки в список аппов
      if ($transition$.$from().includes['app']) {
        return;
      }

      // Если пользователь пытался посетить какую то страницу незалогиненным
      // редиректим его на эту страницу после входа в админку
      // Если пытаемся редиректить на текущую страницу (/account)
      // То ни чего не делаем
      if ($transition$.params()?.path && $window.location.pathname !== $transition$.params()?.path) {
        const path = $transition$.params()?.path.replace(/\/(panel)\//, '');
        $window.location = utilsService.getPanelAbsoluteUrl(path);
        return;
      }

      // Не перекидываем в апп, если он не один
      if (appList.length !== 1) {
        return;
      }

      // Перекидываем в апп
      return $state.go('app', angular.extend({}, $transition$.params(), { appId: appList[0].id }));
    },
  );
}
onEnterAccount.$inject = ['$transition$', '$state', 'utilsService', '$window'];
