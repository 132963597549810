<ng-container *transloco="let t; read: 'aiDataBotSettingsComponent'">
  <div class="container-fluid limited-container padding-bottom-60">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a uiSref="app.content.messagesAjs.chatBot.list">
            {{ t('breadcrumbs.aiData') }}
          </a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ t('breadcrumbs.aiBotSettings') }}
        </li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-body p-30">
        <div class="wrapper">
          <form (ngSubmit)="saveSettings()">
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="botTriggersCollapse.toggle()"
              [attr.aria-expanded]="!isBotTriggersCollapsed"
              aria-controls="collapse"
            >
              <i class="cqi-sm cqi-robot cqi-03x"></i>
              <h4 class="mb-0 ml-10">{{ t('botTriggersCollapse.heading') }}</h4>
              <i
                class="cqi-sm ml-auto"
                [ngClass]="{
                  'cqi-chevron-up': !isBotTriggersCollapsed,
                  'cqi-chevron-down': isBotTriggersCollapsed,
                }"
              ></i>
            </div>
            <div
              #botTriggersCollapse="ngbCollapse"
              [(ngbCollapse)]="isBotTriggersCollapsed"
            >
              <div class="pt-30">
                <cq-bots-list class="mb-30"></cq-bots-list>
                <cq-bot-triggers [formControl]="settingsForm.controls.assistantEnabled"></cq-bot-triggers>
                <cq-bot-channels
                  class="mt-30"
                  [formControl]="settingsForm.controls.channels"
                  [channels]="channels"
                ></cq-bot-channels>
              </div>
            </div>
            <hr class="my-30" />
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="contentOfDialogsCollapse.toggle()"
              [attr.aria-expanded]="!isContentOfDialogsCollapsed"
              aria-controls="collapse"
            >
              <i class="cqi-sm cqi-comments cqi-03x"></i>
              <h4 class="mb-0 ml-10">{{ t('contentOfDialogsCollapse.heading') }}</h4>
              <i
                class="cqi-sm ml-auto"
                [ngClass]="{
                  'cqi-chevron-up': !isContentOfDialogsCollapsed,
                  'cqi-chevron-down': isContentOfDialogsCollapsed,
                }"
              ></i>
            </div>
            <div
              #contentOfDialogsCollapse="ngbCollapse"
              [(ngbCollapse)]="isContentOfDialogsCollapsed"
            >
              <div class="pt-30">
                <h4 class="mb-5">{{ t('contentOfDialogsCollapse.companyInfo.heading') }}</h4>
                <div class="text-secondary">{{ t('contentOfDialogsCollapse.companyInfo.description') }}</div>
                <cq-company-name-control
                  class="mt-15"
                  [formControl]="settingsForm.controls.companyName"
                ></cq-company-name-control>
                <cq-company-info-control
                  class="mt-15"
                  [formControl]="settingsForm.controls.companyInfo"
                ></cq-company-info-control>
                <cq-communication-style-control
                  class="mt-30"
                  [formControl]="settingsForm.controls.communicationStyle"
                ></cq-communication-style-control>
                <ng-container *ngIf="isRuLanguage">
                  <cq-address-form-control
                    class="mt-30"
                    [formControl]="settingsForm.controls.addressForm"
                  ></cq-address-form-control>
                </ng-container>
                <cq-replies-length-control
                  class="mt-30"
                  [formControl]="settingsForm.controls.repliesLength"
                ></cq-replies-length-control>
                <cq-creativity-level-control
                  class="mt-30"
                  [formControl]="settingsForm.controls.creativityLevel"
                ></cq-creativity-level-control>
              </div>
            </div>
            <hr class="my-30" />
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="switchingDialogsCollapse.toggle()"
              [attr.aria-expanded]="!isSwitchingDialogsCollapsed"
              aria-controls="collapse"
            >
              <i class="cqi-sm cqi-headset cqi-03x"></i>
              <h4 class="mb-0 ml-10">{{ t('switchingDialogsCollapse.heading') }}</h4>
              <i
                class="cqi-sm ml-auto"
                [ngClass]="{
                  'cqi-chevron-up': !isSwitchingDialogsCollapsed,
                  'cqi-chevron-down': isSwitchingDialogsCollapsed,
                }"
              ></i>
            </div>
            <div
              #switchingDialogsCollapse="ngbCollapse"
              [(ngbCollapse)]="isSwitchingDialogsCollapsed"
            >
              <div class="pt-30">
                <cq-stop-phrases-control [formControl]="settingsForm.controls.stopPhrases"></cq-stop-phrases-control>
                <div class="mt-30">
                  <h4>{{ t('switchingDialogsCollapse.botReply.heading') }}</h4>
                  <div class="d-flex">
                    <cq-working-time-reply-control
                      class="flex-grow-1"
                      [formControl]="settingsForm.controls.workingTimeReply"
                    ></cq-working-time-reply-control>
                    <cq-non-working-time-reply-control
                      class="flex-grow-1 ml-20"
                      [formControl]="settingsForm.controls.nonWorkingTimeReply"
                    ></cq-non-working-time-reply-control>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-30" />
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="botRulesCollapse.toggle()"
              [attr.aria-expanded]="!isBotRulesCollapsed"
              aria-controls="collapse"
            >
              <i class="cqi-sm cqi-pencil cqi-03x"></i>
              <h4 class="mb-0 ml-10">{{ t('botRulesCollapse.heading') }}</h4>
              <i
                class="cqi-sm ml-auto"
                [ngClass]="{
                  'cqi-chevron-up': !isBotRulesCollapsed,
                  'cqi-chevron-down': isBotRulesCollapsed,
                }"
              ></i>
            </div>
            <div
              #botRulesCollapse="ngbCollapse"
              [(ngbCollapse)]="isBotRulesCollapsed"
            >
              <div class="pt-30">
                <div class="text-secondary mb-15">{{ t('botRulesCollapse.description') }}</div>
                <div class="margin-between-rows-10">
                  <div
                    class="d-flex"
                    *ngFor="let control of settingsForm.controls.botRules.controls; let i = index"
                  >
                    <cq-bot-rule-control
                      class="flex-grow-1"
                      [formControl]="control"
                    ></cq-bot-rule-control>
                    <button
                      *ngIf="settingsForm.controls.botRules.controls.length > 1"
                      class="btn btn-outline-primary ml-10"
                      type="button"
                      (click)="removeBotRule(i)"
                    >
                      <i class="btn-icon cqi-sm cqi-trash"></i>
                    </button>
                  </div>
                </div>
                <button
                  class="btn btn-secondary w-100 mt-10"
                  type="button"
                  (click)="addBotRule()"
                  [disabled]="settingsForm.controls.botRules.controls.length === 5"
                  [ngbTooltip]="t('botRulesCollapse.maxRulesTooltip')"
                  [disableTooltip]="settingsForm.controls.botRules.controls.length < 5"
                  placement="bottom"
                >
                  <i class="btn-icon-left cqi-sm cqi-plus"></i>
                  {{ t('botRulesCollapse.addMoreButton') }}
                </button>
              </div>
            </div>
            <hr class="my-30" />
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="otherSettingsCollapse.toggle()"
              [attr.aria-expanded]="!isOtherSettingsCollapsed"
              aria-controls="collapse"
            >
              <i class="cqi-sm cqi-sliders cqi-03x"></i>
              <h4 class="mb-0 ml-10">{{ t('otherSettingsCollapse.heading') }}</h4>
              <i
                class="cqi-sm ml-auto"
                [ngClass]="{
                  'cqi-chevron-up': !isOtherSettingsCollapsed,
                  'cqi-chevron-down': isOtherSettingsCollapsed,
                }"
              ></i>
            </div>
            <div
              #otherSettingsCollapse="ngbCollapse"
              [(ngbCollapse)]="isOtherSettingsCollapsed"
            >
              <div class="pt-30">
                <cq-close-timeout-control [formControl]="settingsForm.controls.closeTimeout"></cq-close-timeout-control>
                <div class="mt-30">
                  <h4 class="mb-5">{{ t('otherSettingsCollapse.usersEventAndTag.heading') }}</h4>
                  <div class="text-secondary">{{ t('otherSettingsCollapse.usersEventAndTag.description') }}</div>
                  <cq-user-tag-control
                    class="mt-15"
                    [formControl]="settingsForm.controls.userTag"
                    [tags]="tags"
                    (tagsChange)="updateTags($event)"
                  ></cq-user-tag-control>
                  <cq-user-event-control
                    class="mt-15"
                    [formControl]="settingsForm.controls.userEvent"
                    [userEvents]="properties.eventTypes"
                    (userEventsChange)="updateEventTypes($event)"
                  ></cq-user-event-control>
                </div>
              </div>
            </div>
            <input
              class="hidden"
              id="formSubmit"
              type="submit"
              [disabled]="isApiRequestPerformed$ | async"
            />
          </form>
        </div>
      </div>
      <div class="card-footer position-sticky bottom-0 border-0 pt-0">
        <div class="wrapper">
          <hr class="mt-0" />
          <label
            class="btn btn-primary"
            for="formSubmit"
            type="button"
            [ngClass]="{ disabled: isApiRequestPerformed$ | async }"
          >
            {{ t('saveSettingsButton') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-container>
