export enum EDITOR_FORMAT {
  ALIGN = 'align',
  BOLD = 'bold',
  BUTTON = 'button',
  COLOR = 'color',
  DIVIDER = 'divider',
  FILE = 'file',
  HEADER = 'header',
  HTML_CODE = 'html_code',
  IMAGE = 'image',
  ITALIC = 'italic',
  LINE_HEIGHT = 'line_height',
  LINK = 'link',
  SIZE = 'size',
  STRIKE = 'strike',
  UNDERLINE = 'underline',
  USER_PROPERTY = 'user_property',
  VIDEO = 'video',
}
export const EDITOR_FORMATS_DEFAULT: Array<EDITOR_FORMAT> = [
  EDITOR_FORMAT.BOLD,
  EDITOR_FORMAT.HEADER,
  EDITOR_FORMAT.SIZE,
  EDITOR_FORMAT.DIVIDER,
  EDITOR_FORMAT.FILE,
  EDITOR_FORMAT.LINK,
  EDITOR_FORMAT.USER_PROPERTY,
  EDITOR_FORMAT.BUTTON,
];

export const EDITOR_FORMAT_ALIGNS = ['left', 'center', 'right', 'justify'];

export const EDITOR_FORMAT_LINE_HEIGHTS = [1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, false];
