import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { AmocrmFieldsSelectModule } from '@panel/app/pages/integrations/content/amocrm/fields-select/amocrm-fields-select.module';
import { AmocrmPipesModule } from '@panel/app/pages/integrations/content/amocrm/shared/pipes/pipes.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmPropsToContactComponent } from './amocrm-props-to-contact.component';

@NgModule({
  declarations: [AmocrmPropsToContactComponent],
  exports: [AmocrmPropsToContactComponent],
  imports: [
    AmocrmFieldsSelectModule,
    AmocrmPipesModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    NgbCollapseModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
  ],
})
export class AmocrmPropsToContactModule {}
