import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { ClipboardService } from 'ngx-clipboard';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { UserAvatarModule } from '@panel/app/partials/user-card/user-avatar/user-avatar.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { UserCardHeadComponent } from './user-card-head.component';

@NgModule({
  declarations: [UserCardHeadComponent],
  exports: [UserCardHeadComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    SharedModule,
    TranslocoModule,
    UserAvatarModule,
    AccessDenialPopoverModule,
    TuiLet,
  ],
  providers: [ClipboardService, CarrotquestHelperProvider],
})
export class UserCardHeadModule {}
