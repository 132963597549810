import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { App } from '@http/app/app.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { VkFormsStandardQuestionForm } from '@http/integration/integrations/vk-forms/forms/vk-forms-integration.form';
import { Properties } from '@http/property/property.model';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';

@Component({
  selector: 'cq-vk-forms-standard-questions[currentApp][properties][standardQuestions]',
  templateUrl: './vk-forms-standard-questions.component.html',
  styleUrls: ['./vk-forms-standard-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsStandardQuestionsComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Список свойств и событий, которые есть в App'е */
  @Input() properties!: Properties;
  /** Список стандартных вопросов */
  @Input() standardQuestions!: GenericFormArray<VkFormsStandardQuestionForm>;
  /** Текущий язык */
  activeLang!: string;
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;
  /** Является ли страна США */
  isUsCountry!: boolean;

  // @formatter:off
  constructor(private readonly l10nHelper: L10nHelperService, private readonly translocoService: TranslocoService) {
    this.activeLang = translocoService.getActiveLang();
    this.isUsCountry = l10nHelper.isUsCountry();
  }
  // @formatter:on

  /** Получение контролов с стандартными вопросами */
  get standardQuestionsControls() {
    return this.standardQuestions.controls;
  }
}
