import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

type Form = {
  maxUsersCountEnabled: FormControl<boolean>;
  maxUsersCount: FormControl<number>;
};

@Component({
  selector: 'cq-message-schedule-max-users-count',
  templateUrl: './message-schedule-max-users-count.component.html',
  styleUrls: ['./message-schedule-max-users-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class MessageScheduleMaxUsersCountComponent {
  @Input({ required: true })
  set maxUsersCount(value: number | null) {
    if (value === null) {
      return;
    }
    this.form.setValue(
      {
        maxUsersCountEnabled: true,
        maxUsersCount: value,
      },
      { emitEvent: false },
    );
  }

  @Input()
  receiverCount: number | null = null;

  readonly MIN_VALUE = 2001;
  readonly MAX_VALUE = 500000;

  readonly form = this.fb.group<Form>({
    maxUsersCountEnabled: this.fb.control(false, { nonNullable: true }),
    maxUsersCount: this.fb.control(50000, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)],
    }),
  });

  @Output()
  maxUsersCountChange: Observable<number | null> = this.form.valueChanges.pipe(
    filter(() => this.form.valid),
    map(() => {
      const { maxUsersCountEnabled, maxUsersCount } = this.form.getRawValue();
      if (!maxUsersCountEnabled) {
        return null;
      }
      return maxUsersCount;
    }),
  );

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.form.markAllAsTouched();
    this.formSubmitSubject.next();
    return this.form.status !== 'PENDING'
      ? Promise.resolve(this.form.valid)
      : firstValueFrom(
          this.form.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.form.valid),
          ),
        );
  });

  constructor(
    private readonly fb: FormBuilder,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
  ) {}
}
