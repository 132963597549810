import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { EVENT_TYPE_GROUP, EVENT_TYPE_GROUPS_ICONS } from '@http/event-type/event-type.constants';
import { EventType, UserProperty } from '@http/property/property.model';
import { AUTO_EVENTS_GROUPS } from '@http/track-master/track-master.constants';
import { AutoEvent } from '@http/track-master/track-master.model';
import { ApiCreateResponse } from '@http/track-master/track-master.types';
import { CreateAutoEventModalComponent } from '@panel/app/partials/modals/create-auto-event-modal/create-auto-event-modal.component';
import { CREATE_AUTO_EVENT_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/create-auto-event-modal/create-auto-event-modal.token';
import { CreateEventTypeModalComponent } from '@panel/app/partials/modals/create-event-type-modal/create-event-type-modal.component';
import { LeaveSiteAttemptSettingComponent } from '@panel/app/partials/modals/leave-site-attempt-setting/leave-site-attempt-setting.component';
import { EVENT_TYPE_SELECTOR_OPTIONS } from '@panel/app/partials/track-master/event-type-selector/event-type-selector.component';
import { ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/**
 * Ивенты, переводы которых раньше начинались с "Коммуникации: ".
 * Сейчас это убрано, но поиск в селекторе должен продолжать выдавать такие (по крайней мере какое-то время)
 * Список составлен на основе коммита 5404a6a3
 */
const COMMUNICATION_EVENT_TYPES: string[] = [
  '$calendly_invitee_created',
  '$chat_bot_finished',
  '$chat_bot_read',
  '$chat_bot_replied',
  '$chat_bot_sent',
  '$chat_bot_interrupted',
  '$conversation_user_started',
  '$goal_completed',
  '$message_bounced',
  '$message_clicked',
  '$message_control_group',
  '$message_read',
  '$message_replied',
  '$message_sended',
  '$message_spam',
  '$message_unsubscribed',
  '$zoom_meeting_joined',
];

/**
 * Компонент выбора событий
 */
@Component({
  selector: 'cq-event-type-select[currentApp]',
  templateUrl: './event-type-select.component.html',
  styleUrls: ['./event-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypeSelectComponent extends AbstractCVAControl<string | string[] | null> implements OnInit {
  /** Список автособытий */
  @Input()
  autoEvents: AutoEvent[] = [];

  /** Название поля, к которому привяжется значение селекта */
  @Input()
  bindValue: keyof Pick<EventType, 'id' | 'prettyName' | 'name'> = 'id';

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Список событий приложения */
  @Input()
  eventTypes: EventType[] = [];

  /** Возможность выбора нескольких событий */
  @Input()
  multiple: boolean = false;

  /** Текст для бейджа создания события посещения страницы */
  @Input()
  openPageText: string = this.translocoService.translate('eventTypeSelectComponent.openSpecificPageEvent');

  /** Текст для плейсхолдера селекта */
  @Input()
  placeholderText: string = this.translocoService.translate('eventTypeSelectComponent.placeholderText');

  /** Создавать ли ивент по одному названию или создавать как в мастере сбора данных  */
  @Input()
  createTrackMasterEvent: boolean = true;

  @Input()
  closeOnSelect: boolean = true;

  @Input()
  userProps: UserProperty[] = [];
  /**
   * Флаг показа шапки с популярными событиями
   *
   * TODO Когда этот компонент перестанет использоваться в AJS, то нужно выпилить этот флаг,
   *  потому что шапку и футер селекта можно будет передавать снаружи
   * */
  @Input()
  showHeader: boolean = true;

  @Input()
  showFooter: boolean = true;
  /** Текст для иконки события, которое ещё ни разу не совершалось на сайте */
  @Input()
  tooltipText: string = this.translocoService.translate('eventTypeSelectComponent.unusedEventTooltip', {
    projectName: environment.projectName,
  });
  /** Эмиттер изменений autoEvents */
  @Output()
  autoEventsChange = new Subject<AutoEvent[]>();
  /** Эмиттер изменений eventTypes */
  @Output()
  eventTypesChange = new Subject<EventType[]>();
  /** Колбэк на открытие и закрытие селекта */
  @Output()
  openChange = new Subject<Boolean>();
  /** Колбэк на выбор события в селекте */
  @Output()
  selectedEventTypeChange = new Subject<string | string[] | null>();
  /** Колбэк на клик по бейджу создания события посещения страницы */
  @Output()
  sessionStartAdd = new Subject<void>();
  /** Колбэк на выбор события в селекте */
  @Output()
  urlEventTypeAdd = new Subject<void>();
  /** Инстанс ng-select */
  @ViewChild(NgSelectComponent)
  eventTypeSelect!: NgSelectComponent;
  /** Доступ до кастомных событий */
  accessToEventsEventTypesCustom: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };
  control = new GenericFormControl<string | string[] | null>(null);

  ngOnInit() {
    super.ngOnInit();

    this.accessToEventsEventTypesCustom = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
      this.currentApp,
    );
  }

  EVENT_TYPE_GROUPS_ICONS = EVENT_TYPE_GROUPS_ICONS;
  EVENT_TYPE_GROUP = EVENT_TYPE_GROUP;

  private _required = false;

  get required(): boolean {
    return this._required;
  }

  /** Обязательно ли поле к заполнению */
  @Input()
  set required(value: boolean) {
    if (value) {
      this.control.addValidators(Validators.required);
    } else {
      this.control.removeValidators(Validators.required);
    }
    this._required = value;
  }

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly modalHelperService: ModalHelperService,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  /** Показываем бейдж с выбором события «Зашёл на сайт», если оно ещё не выбрано в селекте */
  isSessionStartBadgeVisible() {
    const selectedValues = this.eventTypeSelect.selectedValues as EventType[];

    return !selectedValues.filter((eventType) => eventType.name === '$session_start').length;
  }

  /**
   * Клик на бейдж события "Зашел на сайт"
   */
  public onClickSessionStart(): void {
    this.sessionStartAdd.next();
    this.eventTypeSelect.close();
  }

  /**
   * Клик на бейдж создания события посещения страницы
   */
  public onClickUrlEventType(): void {
    this.urlEventTypeAdd.next();
    this.eventTypeSelect.close();
  }

  /**
   * Открытие или закрытие селекта
   *
   * @param isOpen Открывается или закрывается селект
   */
  public onOpenChange(isOpen: boolean): void {
    this.openChange.next(isOpen);
  }

  openCreateEventModal() {
    return this.createTrackMasterEvent ? this.openCreateAutoEventModal() : this.openCreateEventTypeModal();
  }

  /**
   * Создание ивента по имени
   */
  openCreateEventTypeModal() {
    this.modalHelperService
      .open(CreateEventTypeModalComponent)
      .result.then((eventType: EventType) => {
        this.handleNewEventFromModal(eventType);
      })
      .catch(() => {});
  }

  /**
   * Открывает модалку создания события
   */
  openCreateAutoEventModal() {
    this.modalHelperService
      .provide(CREATE_AUTO_EVENT_MODAL_DATA_TOKEN, {
        autoEvents: this.autoEvents,
        currentApp: this.currentApp,
        userProps: this.userProps,
        eventTypes: this.eventTypes,
      })
      .provide(EVENT_TYPE_SELECTOR_OPTIONS, [
        AUTO_EVENTS_GROUPS.URL,
        AUTO_EVENTS_GROUPS.URL_SCROLL,
        AUTO_EVENTS_GROUPS.CLICK,
      ])
      .open(CreateAutoEventModalComponent)
      .result.then(({ autoEvent, eventType }: ApiCreateResponse) => {
        this.handleNewEventFromModal(eventType, autoEvent);
      })
      .catch(() => {});
  }

  /**
   * Открывает модалку, предупреждающую о том, что сбор данных о "попытке ухода с сайта" не включен
   */
  openModalOnLeaveSiteSelect() {
    const modal = this.modalHelperService.open(LeaveSiteAttemptSettingComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'md modal-dialog-centered',
    });

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
    };

    modal.result
      .then(() => {})
      .catch(() => {
        this.control.setValue(null);
      });
  }

  /**
   * Выбор события
   *
   * @param eventType Тип события
   */
  public onSelectEventType(eventType: EventType): void {
    if (eventType.name === '$leave_site_attempt' && !this.currentApp.settings.track_leave_site_attempt) {
      this.openModalOnLeaveSiteSelect();
    }
  }

  /**
   * Функция для кастомного поиска по событиям
   *
   * @param term - Фраза для поиска
   * @param item - Событие
   */
  public searchEventTypeFn(term: string, item: EventType): boolean {
    //NOTE Это надо чтобы люди привыкли к новому названию. Через пол года можно удалить.
    // Если это не сделано к осени 2022, можно смело удалять тут и перевод
    const sessionStartOldName: string = this.translocoService.translate(
      'models.eventType.systemEventTypes.$session_start_old_name',
    );
    term = term.toLowerCase();

    const communicationPrefix: string = this.translocoService.translate('models.eventType.oldCommunicationsPrefix');

    return (
      item.name.toLowerCase().includes(term) ||
      item.prettyName.toLowerCase().includes(term) ||
      (item.name === '$session_start' && sessionStartOldName.toLowerCase().includes(term)) ||
      (communicationPrefix.toLowerCase().includes(term) && COMMUNICATION_EVENT_TYPES.includes(item.name))
    );
  }

  private handleNewEventFromModal(eventType: EventType, autoEvent?: AutoEvent) {
    if (autoEvent && !this.autoEvents.find((e) => e.id === autoEvent.id)) {
      this.autoEvents = [...this.autoEvents, autoEvent];
      this.autoEventsChange.next(this.autoEvents);
    }

    let oldEventType = this.eventTypes.find((e) => e.id === eventType.id);
    if ((oldEventType && !oldEventType.visible) || !oldEventType) {
      const newEventTypes = [...this.eventTypes];

      if (!oldEventType) {
        newEventTypes.push(eventType);
      } else {
        // Если создали автособытие, которое раньше уже было => оно было удалено и у него просто надо изменить видимость
        oldEventType.visible = eventType.visible;
        oldEventType.prettyName = eventType.prettyName;
        oldEventType.group = eventType.group;
      }

      this.eventTypes = newEventTypes;
      this.eventTypesChange.next(newEventTypes);
    }

    if (Array.isArray(this.control.value)) {
      this.control.setValue([...this.control.value, eventType[this.bindValue]]);
    } else {
      this.control.setValue(eventType[this.bindValue]);
    }
    this.onSelectEventType(eventType);
  }
}
