export const TRIGGER_CHAIN_BLOCK_CONTENT_INDENT = 10;
export const TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX = 272;
export const TRIGGER_CHAIN_BLOCK_WIDTH = TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX + TRIGGER_CHAIN_BLOCK_CONTENT_INDENT * 2;

export const TRIGGER_CHAIN_BLOCK_NAME_MAX_LENGTH = 25;

export enum TRIGGER_CHAIN_BLOCK_TYPE {
  CHAT = 'chat',
  DELAY = 'delay',
  EMAIL = 'email',
  FILTER = 'filter',
  POPUP = 'popup',
  TELEGRAM_MESSAGE = 'telegram_message',
  SDK_PUSH = 'sdk_push',
  WEBHOOK = 'webhook',
  REACTION = 'reaction',
  SENDING_CONDITION = 'sending_condition',
  /** Достижение успеха */
  EXIT_SUCCESS = 'exit_success',
  /** Выход из цепочки */
  EXIT = 'exit',
  /** Действие */
  ACTION = 'action',
}

export const TRIGGER_CHAIN_CONTENT_BLOCK_TYPES = [
  TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
  TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
  TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
  TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
  TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
  TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
] as const;

export const TRIGGER_CHAIN_LOGIC_BLOCK_TYPES = [
  TRIGGER_CHAIN_BLOCK_TYPE.FILTER,
  TRIGGER_CHAIN_BLOCK_TYPE.DELAY,
  TRIGGER_CHAIN_BLOCK_TYPE.REACTION,
  TRIGGER_CHAIN_BLOCK_TYPE.ACTION,
] as const;

export const TRIGGER_CHAIN_EXIT_BLOCK_TYPES = [
  TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS,
  TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
] as const;
