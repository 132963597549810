import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Partial } from 'split.js';

import { EventType } from '@http/property/property.model';
import { TriggerChainStepFactory } from '@panel/app/http/trigger-chain/factory/trigger-chain-step.factory';
import { TriggerChain } from '@panel/app/http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';

export type TriggerChainFactoryExtraData = {
  eventTypes: EventType[];
};

@Injectable({ providedIn: 'root' })
export class TriggerChainFactory {
  constructor(
    private readonly triggerChainStepFactory: TriggerChainStepFactory,
    private readonly transloco: TranslocoService,
  ) {}

  generate(overwrite: Partial<TriggerChain> = {}, extraData: TriggerChainFactoryExtraData): TriggerChain {
    const conditionStep = this.triggerChainStepFactory.generateSendingConditions(extraData);
    const triggerStep = this.triggerChainStepFactory.generate(TRIGGER_CHAIN_BLOCK_TYPE.CHAT);
    triggerStep.coordinates = {
      x: 500,
      y: 150,
    };
    conditionStep.meta.nextStep = triggerStep.uuid;

    return {
      active: false,
      name: this.transloco.translate('triggerChainModel.defaultChainName'),
      steps: [conditionStep, triggerStep],
      ...overwrite,
    };
  }

  generateStartedTemplate(extraData: TriggerChainFactoryExtraData) {
    const conditionStep = this.triggerChainStepFactory.generateSendingConditions(extraData);
    const triggerStep = this.triggerChainStepFactory.generateFirstStep();
    triggerStep.coordinates = {
      x: 500,
      y: 150,
    };
    conditionStep.meta.nextStep = triggerStep.uuid;

    return {
      active: false,
      name: this.transloco.translate('triggerChainModel.defaultChainName'),
      steps: [conditionStep, triggerStep],
    };
  }
}
