import { firstValueFrom } from 'rxjs';

import {
  MESSAGE_PART_TYPE_ICON_CLASSES,
  MESSAGE_PART_TYPES,
  MESSAGE_PART_TYPES_ARRAY,
} from '../../../../app/http/message-part/message-part.constants';
import {
  MESSAGE_TEMPLATES_STATUSES,
  MESSAGE_TEMPLATES_STATUSES_LIST,
} from '../../../../app/http/message-template/message-template.constants';
import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '../../../../app/http/template-directory/template-directory.constants';

/**
 * Контроллер списка шаблонов
 */
(function () {
  'use strict';

  angular
    .module('myApp.messageTemplates')
    .controller('CqMessageTemplatesGridController', CqMessageTemplatesGridController);

  function CqMessageTemplatesGridController(
    $filter,
    $scope,
    $uibModal,
    caseStyleHelper,
    ACTIONS_ON_DIRECTORY,
    MESSAGE_TEMPLATES_TABS,
    messageTemplateModel,
    carrotquestHelper,
    templateDirectoryModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.currentDirectory = $filter('filter')(
        vm.directories,
        { id: PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY] },
        true,
      )[0]; // Выбранная директория
      vm.currentMessagePartType = MESSAGE_PART_TYPES.ALL; // Выбрыннй тип сообещния
      vm.currentTab = MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY; // Выбранный статус шаблона сообщения
      vm.currentTemplateStatus = MESSAGE_TEMPLATES_STATUSES.USED; // Выбранный статус шаблона сообщения
      vm.FILTERED_MESSAGE_PART_TYPES_ARRAY = filterMessagePartTypesArray(MESSAGE_PART_TYPES_ARRAY); // используется для фильтрации сообщений по типу
      vm.MESSAGE_PART_TYPE_ICON_CLASSES = MESSAGE_PART_TYPE_ICON_CLASSES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MESSAGE_TEMPLATES_STATUSES = MESSAGE_TEMPLATES_STATUSES;
      vm.MESSAGE_TEMPLATES_STATUSES_LIST = MESSAGE_TEMPLATES_STATUSES_LIST;
      vm.MESSAGE_TEMPLATES_TABS = MESSAGE_TEMPLATES_TABS;
      vm.onTemplateChange = onTemplateChange;
      vm.onTemplateRemove = onTemplateRemove;
      vm.openEditDirectoryModal = openEditDirectoryModal;
      vm.templates = []; // список шаблонов
      vm.templatesPaginator = []; // пагинация шаблонов
      vm.trackChooseDirectory = trackChooseDirectory;
      vm.trackChooseMessageType = trackChooseMessageType;
      vm.updateTemplates = updateTemplates;

      $scope.$watchGroup(
        ['vm.currentTemplateStatus', 'vm.currentMessagePartType', 'vm.currentDirectory'],
        filtersWatch,
      );

      $scope.$on('message', handleRts);

      function filtersWatch(newValues) {
        updateTemplates(newValues[0], newValues[1], newValues[2], true);
      }
    }

    /**
     * Фильтрация массива типов варианта сообщения
     *
     * @param {MESSAGE_PART_TYPES_ARRAY} messagePartTypesArray
     * @returns {Array}
     */
    function filterMessagePartTypesArray(messagePartTypesArray) {
      var filteredMessagePartTypesArray = angular.copy(messagePartTypesArray);

      for (var i = filteredMessagePartTypesArray.length - 1; i >= 0; i--) {
        var messagePartType = filteredMessagePartTypesArray[i];

        if (
          !!~[MESSAGE_PART_TYPES.WEBHOOK, MESSAGE_PART_TYPES.JS, MESSAGE_PART_TYPES.CONTROL_GROUP].indexOf(
            messagePartType,
          )
        ) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        const userActivatedInSdk =
          Boolean(vm.currentApp.activation.installed_sdk_ios) ||
          Boolean(vm.currentApp.activation.installed_sdk_android);
        // NOTE: Доступ до SDK-типов только по фичагалке
        if (
          !!~[
            MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
            MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
            MESSAGE_PART_TYPES.SDK_PUSH,
          ].indexOf(messagePartType) &&
          !userActivatedInSdk
        ) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        // Нет шаблонов для Telegram
        if (messagePartType === MESSAGE_PART_TYPES.TELEGRAM) {
          filteredMessagePartTypesArray.splice(i, 1);
        }
      }

      return filteredMessagePartTypesArray;
    }

    /**
     * Обработка RTS-сообщений
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      var channel = info.channel;
      var newTemplate = info.data;
      caseStyleHelper.keysToCamelCase(newTemplate);
      // РТС изменения превью шаблона
      if (channel.indexOf('message_template_updated.') === 0) {
        let callApply = false;

        for (var i = 0; i < vm.templates.length; i++) {
          if (vm.templates[i].id === newTemplate.id) {
            var directory = $filter('filter')(vm.directories, { id: newTemplate.directory }, true)[0];
            if (directory) {
              newTemplate.directory = $filter('filter')(vm.directories, { id: newTemplate.directory }, true)[0];
            } else {
              newTemplate.directory = templateDirectoryModel.getWithoutDirectory();
            }
            messageTemplateModel.parse(newTemplate, true);
            vm.templates[i] = newTemplate;

            callApply = true;

            break;
          }
        }

        callApply && $scope.$applyAsync();
      }
    }

    /**
     * Изменение шаблона
     */
    function onTemplateChange() {
      updateTemplates(vm.currentTemplateStatus, vm.currentMessagePartType, vm.currentDirectory, true);
    }

    /**
     * Удаление шаблона
     *
     * @param {Object} template - Шаблон
     */
    function onTemplateRemove(template) {
      vm.templates.splice(vm.templates.indexOf(template), 1);
    }

    /**
     * Обновление шаблонов
     *
     * @param {MESSAGE_PART_TYPES_ARRAY} status - Статус шаблонов
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип вариантов сообщения
     * @param {Object} directory - Директория
     * @param {Boolean} forceReload - Принудительная перезагрузка
     * @returns {*}
     */
    function updateTemplates(status, messagePartType, directory, forceReload) {
      vm.onLoad();

      if (forceReload) {
        vm.templates = [];
        vm.templatesPaginator = null;
      }

      return firstValueFrom(
        messageTemplateModel.getList(vm.currentApp.id, status, messagePartType, directory.id, vm.templatesPaginator),
      )
        .then(getTemplatesSuccess)
        .finally(getTemplatesFinally);

      function getTemplatesSuccess(data) {
        vm.templatesPaginator = data.paginatorParams;
        vm.templates = [...vm.templates, ...data.templates];
      }

      function getTemplatesFinally() {
        vm.onLoad();
      }
    }

    /**
     * Открытие модалки редактирования/создания папки
     *
     * @param {Object} directory - Папка
     */
    function openEditDirectoryModal(directory) {
      var createDirectoryModal = $uibModal.open({
        component: 'cqTemplatesDirectoryEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          directory: angular.bind(null, angular.identity, angular.copy(directory)),
        },
      });

      createDirectoryModal.result.then(editDirectorySuccess);

      function editDirectorySuccess(response) {
        var directory = $filter('filter')(vm.directories, { id: response.directory.id }, true)[0];

        switch (response.action) {
          case ACTIONS_ON_DIRECTORY.CREATE: {
            vm.directories.push(response.directory);
            break;
          }
          case ACTIONS_ON_DIRECTORY.DELETE: {
            vm.directories.splice(vm.directories.indexOf(directory), 1);
            // Если выбранная папка совпадает с удаленной, надо выбрать другую папку
            if (vm.currentDirectory.id === directory.id) {
              vm.currentDirectory = $filter('filter')(
                vm.directories,
                { id: PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY] },
                true,
              )[0];
            } else {
              // Удалить из шаблонов только что удаленную папку
              for (var i = 0; i < vm.templates.length; i++) {
                if (vm.templates[i].directory.id === directory.id) {
                  vm.templates[i].directory = templateDirectoryModel.getWithoutDirectory();
                }
              }
            }
            break;
          }
          case ACTIONS_ON_DIRECTORY.RENAME: {
            directory.prettyName = directory.name = response.directory.prettyName;
            break;
          }
        }
      }
    }

    /**
     * Трек захода на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Шаблоны - зашел', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек выбора фильтра папки
     *
     * @param {Object} directory - Папка
     */
    function trackChooseDirectory(directory) {
      carrotquestHelper.track('Шаблоны - применил фильтр "Папка"', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Название папки': directory.prettyName,
        Расположение: 'Список шаблонов',
      });
    }

    /**
     * Трек фильтра выбора фильтра типа сообщения
     *
     * @param {String} messageType - Тип сообщения
     */
    function trackChooseMessageType(messageType) {
      carrotquestHelper.track('Шаблоны - применил фильтр "Тип"', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Тип: messageType,
      });
    }
  }
})();
