import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { DATA_SOURCE_STATUS } from '@http/ai-data/ai-data.constants';
import { AiContentKind, AiData } from '@http/ai-data/internal-types/ai-data.internal.type';
import { AppService } from '@http/app/services/app.service';
import { ArticleModel } from '@http/article/article.model';
import { AiDataActionModalService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

enum AI_DATA_SOURCE_ITEM_ACTIONS {
  ACTIVATE,
  RESYNC,
  RENAME,
  DELETE,
}

@Component({
  //eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cq-ai-data-source-table-item]',
  templateUrl: './ai-data-source-table-item.component.html',
  styleUrls: ['./ai-data-source-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiDataSourceTableItemComponent implements OnInit {
  @Input()
  dataSource!: AiData;

  availableActions: AI_DATA_SOURCE_ITEM_ACTIONS[] = [];

  sourceIconCssClass: string = '';

  sourceStatusLabelCssClass: string = '';

  sourceSref!: string;

  sourceUrl: string = '';

  AI_DATA_SOURCE_ITEM_ACTIONS = AI_DATA_SOURCE_ITEM_ACTIONS;
  DATA_SOURCE_STATUS = DATA_SOURCE_STATUS;

  constructor(
    private readonly aiDataActionModalService: AiDataActionModalService,
    private readonly appService: AppService,
    private readonly articleModel: ArticleModel,
    public readonly state: AiDataSourcesListStateService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    this.availableActions = this.getAvailableDropdownActions();
    this.sourceIconCssClass = this.getIconCssClass();
    this.sourceSref = this.getSourceSref();
    this.sourceStatusLabelCssClass = this.getSourceStatusLabelCssClass();
    this.sourceUrl = this.getSourceUrl();
  }

  changeStatus() {
    if (this.dataSource.status === DATA_SOURCE_STATUS.ACTIVE) {
      this.aiDataActionModalService
        .openDisableSingleItemModal(this.dataSource)
        .result.then(() => {
          this.toastService.success(
            this.translocoService.translate('aiDataSourceTableItemComponent.toasts.paused', {
              contentName: this.dataSource.name,
            }),
          );
        })
        .catch(() => {});
    }

    if (this.dataSource.status === DATA_SOURCE_STATUS.STOPPED) {
      this.toastService.success(
        this.translocoService.translate('aiDataSourceTableItemComponent.toasts.activated', {
          contentName: this.dataSource.name,
        }),
      );
    }
  }

  rename() {
    this.aiDataActionModalService
      .openRenameItemModal(this.dataSource)
      .result.then((newName: string) => {
        this.toastService.success(this.translocoService.translate('aiDataSourceTableItemComponent.toasts.renamed'));
      })
      .catch(() => {});
  }

  delete() {
    this.aiDataActionModalService
      .openDeleteItemModal(this.dataSource)
      .result.then(() => {
        this.toastService.success(
          this.translocoService.translate('aiDataSourceTableItemComponent.toasts.deleted', {
            contentName: this.dataSource.name,
          }),
        );
      })
      .catch(() => {});
  }

  onCheckedChange(newValue: boolean) {
    this.state.toggleDataSourceSelection(this.dataSource.contentId, newValue);
  }

  onClickDataSourceName() {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        // Чтобы перейти на редактирование статьи, нужно знать ID категории, к которой она относится
        this.articleModel
          .get(this.appService.currentAppId, this.dataSource.contentId, true)
          .subscribe((article: any) => {
            const url = this.uiRouter.stateService.href(
              'app.content.knowledgeBase.articleEdit',
              {
                articleId: article.id,
                categoryId: article.category.id,
              },
              { absolute: true },
            );
            window.open(url, '_blank');
          });
    }
  }

  private getAvailableDropdownActions() {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
      case AiContentKind.URL:
        return [AI_DATA_SOURCE_ITEM_ACTIONS.ACTIVATE];
    }
  }

  private getIconCssClass() {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        return 'cqi-book';
      case AiContentKind.URL:
        return 'cqi-link';
    }
  }

  private getSourceSref() {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
      case AiContentKind.URL:
        return '';
    }
  }

  private getSourceStatusLabelCssClass() {
    switch (this.dataSource.status) {
      case DATA_SOURCE_STATUS.ALL:
        return '';
      case DATA_SOURCE_STATUS.ACTIVE:
        return 'label-light-success';
      case DATA_SOURCE_STATUS.STOPPED:
        return 'label-light-danger';
    }
  }

  private getSourceUrl(): string {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        return this.translocoService.translate('aiDataSourceTableItemComponent.source.knowledgeBase');
      case AiContentKind.URL:
        return '';
    }
  }
}
