<ng-container *transloco="let t; read: 'acmAddPropsComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="editorCollapse.toggle()"
    [attr.aria-expanded]="!isEditorCollapsed"
    aria-controls="collapse"
  >
    <i class="cqi-sm cqi-circle-user cqi-03x"></i>
    <h4 class="mb-0 ml-10">{{ t('variablesEditorCollapseHeading') }}</h4>
    <i
      class="cqi-sm ml-auto"
      [ngClass]="{
        'cqi-chevron-up': !isEditorCollapsed,
        'cqi-chevron-down': isEditorCollapsed
      }"
    ></i>
  </div>

  <div
    #editorCollapse="ngbCollapse"
    [(ngbCollapse)]="isEditorCollapsed"
  >
    <div class="pt-30">
      <ng-container *ngTemplateOutlet="variablesEditor"></ng-container>
    </div>
  </div>

  <ng-template #variablesEditor>
    <ng-container *ngFor="let variable of control.controls; index as i; last as isLast">
      <cq-acm-props-editor
        [formControl]="variable"
        [properties]="properties"
        [isLast]="isLast"
        [index]="i"
        [currentApp]="currentApp"
        (delete)="deleteVariable($event)"
      ></cq-acm-props-editor>
    </ng-container>

    <button
      class="btn btn-secondary btn-block"
      type="button"
      (click)="addVariable()"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ t('addVariable') }}</span>
    </button>
  </ng-template>
</ng-container>
