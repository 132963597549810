import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';

import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { StepComponent } from './step/step.component';
import { StepActionsDirective } from './step-actions/step-actions.directive';
import { StepLabelDirective } from './step-label/step-label.directive';
import { StepperComponent } from './stepper/stepper.component';

@NgModule({
  declarations: [StepComponent, StepperComponent, StepLabelDirective, StepActionsDirective],
  imports: [CommonModule, LoaderModule, PolymorpheusOutlet],
  exports: [StepperComponent, StepComponent, StepLabelDirective, StepActionsDirective],
})
export class StepperModule {}
