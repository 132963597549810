import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { InputDateForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-date/input-date.types';
import { FILTER_DATE_VALUE_UNIT } from '@panel/app/services/filter/filter.constants';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent extends AbsCVAFormGroupBasedComponent<InputDateForm> {
  readonly control: FormGroup<InputDateForm> = this.fb.group<InputDateForm>({
    value: this.fb.control(1),
    units: this.fb.control(FILTER_DATE_VALUE_UNIT.MINUTES),
  });

  numbers: number[] = Array.from({ length: 59 }, (_, i) => i + 1);

  units: FILTER_DATE_VALUE_UNIT[] = [
    FILTER_DATE_VALUE_UNIT.MINUTES,
    FILTER_DATE_VALUE_UNIT.HOURS,
    FILTER_DATE_VALUE_UNIT.DAYS,
    FILTER_DATE_VALUE_UNIT.WEEKS,
    FILTER_DATE_VALUE_UNIT.MONTHS,
    FILTER_DATE_VALUE_UNIT.YEARS,
  ];

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}
