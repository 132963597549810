import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { HIDE_TOAST_ERROR } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class KnowledgeBaseModel {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  /**
   * Активация БЗ
   *
   * @param {String} appId - ID аппа
   *
   * @returns {Promise}
   */
  create(appId: any) {
    return this.httpClient.post('/knowledgebase/' + appId, {}).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  /**
   * Получение настроек БЗ
   *
   * @param {String} appId - ID аппа
   *
   * @returns {Promise}
   */
  get(appId: any) {
    const params = {};

    return this.httpClient
      .get('/knowledgebase/' + appId, {
        params,
        context: new HttpContext().set(HIDE_TOAST_ERROR, true),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(() => {
          return of('KnowledgeBaseAppDoesNotExist');
        }),
      );
  }

  /**
   * Сохранение статьи
   *
   * @param {Object} app - Текущее приложение
   * @param {Object} settings - Настройки
   * @returns {*}
   */
  save(app: any, settings: any) {
    let body: any = {
      domain: settings.domain,
      is_personal_domain: settings.isPersonalDomain,
      logo: settings.logo,
    };

    if (this.planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE_PRO, app).hasAccess) {
      body.google_analytics = !!settings.googleAnalytics ? settings.googleAnalytics : '';
      body.no_powered_by = settings.noPoweredBy;
      body.yandex_metrika = !!settings.yandexMetrika ? settings.yandexMetrika : '';
    }

    return this.httpClient.patch(`/knowledgebase/${app.id}`, body);
  }
}
