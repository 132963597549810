<ng-container *transloco="let t; read: 'editorFormatBoldComponent'">
  <button
    type="button"
    class="btn btn-borderless-primary rounded-0"
    [ngClass]="{ active: isFormatActive }"
    container="body"
    [ngbTooltip]="t('tooltip')"
    (click)="onClickToggleBoldButton()"
    triggers="hover:blur"
  >
    <i class="cqi-sm cqi-bold"></i>
  </button>
</ng-container>
