import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { filter, map } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter as rxFilter, finalize, takeUntil } from 'rxjs/operators';

import { AppModel } from '@http/app/app.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import {
  PSEUDO_DIRECTORY_IDS,
  PSEUDO_DIRECTORY_TYPES,
  SYSTEM_DIRECTORIES,
} from '@http/message-directory/message-directory.constants';
import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { AutoMessageLoopModalComponent } from '@panel/app/pages/auto-messages/modals/list/loop/auto-message-loop-modal.component';
import { AutoMessageSetDirectoryModalComponent } from '@panel/app/pages/auto-messages/modals/list/set-directory/auto-message-set-directory-modal.component';
import { directoriesStore$ } from '@panel/app/pages/auto-messages/stores/directories.store';
import { ReadyAutoMessagesModalComponent } from '@panel/app/partials/modals/ready-auto-messages-modal/ready-auto-messages-modal.component';
import { CaseStyleHelper, DestroyService, ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { InstallScriptModalComponent } from '@panel/app/shared/modals/install-script-modal/install-script-modal.component';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { SystemErrorService } from '@panel/app-old/shared/services/system-error/system-error.service';

@Component({
  selector: 'cq-auto-message-list-page',
  templateUrl: './auto-message-list-page.component.html',
  styleUrls: ['./auto-message-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AutoMessageListPageComponent implements OnInit {
  /** Массив с количеством активных триггерных сообщений по типам */
  @Input() activeMessagesAmounts!: any;
  /** данные о блокировках приложения */
  @Input() appBlocks!: any;
  /** информация по биллингу */
  @Input() billingInfo!: any;
  /** текущее приложение */
  @Input() currentApp!: any;

  @Input()
  set directories(directories: any) {
    directoriesStore$.next(directories);
  }
  get directories(): any {
    return directoriesStore$.getValue();
  }

  /** имеет ли хоть одно сообщение */
  @Input() hasMessages!: boolean;
  /** текущий пользователь */
  @Input() djangoUser!: any;
  /** данные для онбординга */
  @Input() onboarding!: any;
  /** Список ограничений по тарифу */
  @Input() planCapabilities!: any;
  /** список свойств и типов событий */
  @Input() properties!: any;
  /** список тегов пользователей */
  @Input() tags!: any[];

  /**
   * Со сколькими сообщениями совершается действие
   *
   * @type {{ALL: string, ONE: string, SEVERAL: string}}
   */
  protected COUNT_ACTION_TYPE: { ALL: string; ONE: string; SEVERAL: string } = {
    ONE: 'one', // с одним
    SEVERAL: 'several', // с несколькими
    ALL: 'all', // со всеми
  };

  protected accessToAutoMessages!: ProductFeatureAccess;
  protected accessToSelectedAutoMessages!: ProductFeatureAccess;
  protected accessToSelectedTelegramAutoMessages!: ProductFeatureAccess;
  protected accessToTelegramAutoMessages!: ProductFeatureAccess;
  protected accessToAutoMessagesMassActivation!: ProductFeatureAccess;
  protected accessToMessagesTemplates!: ProductFeatureAccess;
  protected accessToReadyAutoMessage!: ProductFeatureAccess;
  protected currentStatus: MESSAGE_STATUSES = MESSAGE_STATUSES.ACTIVE;
  protected currentDirectory: any;
  protected currentType: MESSAGE_PART_TYPES = MESSAGE_PART_TYPES.ALL;
  protected eventTypes: any[] = [];
  protected isApiRequestPerformed: any = false;
  protected isMessagesRequestPerformed: any = false;
  protected messages: any = [];
  protected messagesPaginator: any = null;
  protected selectedMessages: any = [];
  protected selectedMessagesAll: any = false;
  protected selectedMessageTriggerType: 'event' | 'schedule' = 'event';
  protected hasAccessToScheduledMessages = this.featureModel.hasAccess(FEATURES.SCHEDULED_MESSAGES);
  protected isSearchMode = false;
  protected searchForm = this.fb.control(null, [Validators.minLength(3), Validators.maxLength(255)]);
  readonly SEARCH_PATTERN_MIN_LENGTH = 3;
  readonly SEARCH_PATTERN_MAX_LENGTH = 255;

  constructor(
    protected readonly appModel: AppModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly caseStyleHelper: CaseStyleHelper,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly featureModel: FeatureModel,
    protected readonly l10nHelper: L10nHelperService,
    protected readonly messageDirectoryModel: MessageDirectoryModel,
    protected readonly messageModel: MessageModel,
    protected readonly messagePartModel: MessagePartModel,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly systemError: SystemErrorService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
    protected readonly destroy$: DestroyService,
    protected readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.trackEnterOnPage();

    this.initFeatureAccesses();

    this.eventTypes = this.properties.eventTypes;
    this.currentDirectory = this.directories.find(
      (dir: any) => dir.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
    );

    if (this.uiRouter.stateService.params.tab === 'scheduled') {
      this.selectedMessageTriggerType = 'schedule';
    }
    this.openMessageTemplatesModalOnCondition();

    this.filterMessages();

    this.searchForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        takeUntil(this.destroy$),
        rxFilter(() => this.searchForm.valid),
      )
      .subscribe(() => {
        this.getMessages(true);
      });
  }

  private initFeatureAccesses(): void {
    this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.currentApp,
      this.activeMessagesAmounts,
    );
    this.accessToSelectedAutoMessages = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.currentApp,
      this.activeMessagesAmounts,
      '<=',
    );
    this.accessToTelegramAutoMessages = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TELEGRAM,
      this.currentApp,
    );
    this.accessToSelectedTelegramAutoMessages = {
      hasAccess: true,
      denialReason: null,
    };
    this.accessToAutoMessagesMassActivation = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_MASS_ACTIVATION,
      this.currentApp,
    );
    this.accessToMessagesTemplates = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.MESSAGES_TEMPLATES,
      this.currentApp,
    );
    this.accessToReadyAutoMessage = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.READY_AUTO_MESSAGES,
      this.currentApp,
    );
  }

  /**
   * Архивирование/восстановление сообщения
   *
   * @param {Boolean} reverse флаг укзывающий надо восстановить или архивировать автосообщение
   * @param {Array|Object} messages массив сообщений или сообщение
   */
  archiveOrReestablishMessage(reverse: any, messages: any): any {
    var heading: any;
    var body: any;
    var confirmButtonText: any;

    var type = this.getCountActionType(messages);

    if (type == this.COUNT_ACTION_TYPE.ONE) {
      if (reverse) {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.reestablish.forOne.heading',
        );
        body = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.reestablish.forOne.body',
        );
      } else {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.archive.forOne.heading',
        );
        body = this.translocoService.translate('autoMessages.table.archiveOrReestablishMessage.archive.forOne.body', {
          archiveDirectory: this.translocoService.translate('models.directory.systemMessageDirectoryTypes.$archive'),
        });
      }
    } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
      if (reverse) {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.reestablish.forSeveral.heading',
        );
        body = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.reestablish.forSeveral.body',
          { selectedMessages: messages.length },
        );
      } else {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.archive.forSeveral.heading',
        );
      }
      body = this.translocoService.translate('autoMessages.table.archiveOrReestablishMessage.archive.forSeveral.body', {
        selectedMessages: messages.length,
        archiveDirectory: this.translocoService.translate('models.directory.systemMessageDirectoryTypes.$archive'),
      });
    } else if (type == this.COUNT_ACTION_TYPE.ALL) {
      if (reverse) {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.reestablish.forAll.heading',
        );
        if (this.currentType === MESSAGE_PART_TYPES.ALL) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.reestablish.forAll.body1',
          );
        } else {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.reestablish.forAll.body2',
            {
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
            },
          );
        }
      } else {
        heading = this.translocoService.translate(
          'autoMessages.table.archiveOrReestablishMessage.archive.forAll.heading',
        );
        if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body1',
            {
              directoryName: this.currentDirectory.prettyName,
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body2',
            {
              directoryName: this.currentDirectory.prettyName,
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body3',
            {
              directoryName: this.currentDirectory.prettyName,
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body4',
            {
              directoryName: this.currentDirectory.prettyName,
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body5',
            {
              directoryName: this.currentDirectory.prettyName,
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body6',
            {
              directoryName: this.currentDirectory.prettyName,
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body7',
            {
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body8',
            {
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body9',
            {
              messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body10',
            {
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body11',
            {
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate(
            'autoMessages.table.archiveOrReestablishMessage.archive.forAll.body12',
            {
              archiveDirectory: this.translocoService.translate(
                'models.directory.systemMessageDirectoryTypes.$archive',
              ),
            },
          );
        }
      }
    }

    if (reverse) {
      confirmButtonText = this.translocoService.translate(
        'autoMessages.table.archiveOrReestablishMessage.reestablish.confirmButtonText',
      );
    } else {
      confirmButtonText = this.translocoService.translate(
        'autoMessages.table.archiveOrReestablishMessage.archive.confirmButtonText',
      );
    }

    let archiveOrReestablishModal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: heading,
        body: body,
        confirmButtonText: confirmButtonText,
      })
      .open(ConfirmModalComponent);

    archiveOrReestablishModal.result
      .then(() => {
        var executedFunction;

        if (type == this.COUNT_ACTION_TYPE.ONE) {
          if (reverse) {
            executedFunction = angular.bind(
              null,
              this.messageModel.reestablishAutoMessages,
              this.currentApp.id,
              angular.isArray(messages) ? messages[0].id : messages.id,
            );
          } else {
            executedFunction = angular.bind(
              null,
              this.messageModel.archiveAutoMessages,
              this.currentApp.id,
              angular.isArray(messages) ? messages[0].id : messages.id,
            );
          }
        } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
          if (reverse) {
            executedFunction = angular.bind(
              null,
              this.messageModel.reestablishAutoMessages,
              this.currentApp.id,
              map(messages, 'id'),
            );
          } else {
            executedFunction = angular.bind(
              null,
              this.messageModel.archiveAutoMessages,
              this.currentApp.id,
              map(messages, 'id'),
            );
          }
        } else if (type == this.COUNT_ACTION_TYPE.ALL) {
          if (reverse) {
            executedFunction = angular.bind(
              null,
              this.messageModel.reestablishAutoMessages,
              this.currentApp.id,
              null,
              this.currentStatus,
              this.currentType,
              this.currentDirectory.id,
            );
          } else {
            executedFunction = angular.bind(
              null,
              this.messageModel.archiveAutoMessages,
              this.currentApp.id,
              null,
              this.currentStatus,
              this.currentType,
              this.currentDirectory.id,
            );
          }
        }

        if (executedFunction) {
          firstValueFrom(executedFunction()).then((response: any) => {
            if (response.updated > 1) {
              if (reverse) {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.reestablishMessagesSuccess', {
                    messagesCount: response.updated,
                  }),
                );
                this.trackReestablishMessages();
              } else {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.archiveMessagesSuccess', {
                    messagesCount: response.updated,
                  }),
                );
                this.trackArchivedMessages();
              }
            } else {
              if (reverse) {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.reestablishMessageSuccess'),
                );
                this.trackReestablishMessage(angular.isArray(messages) ? messages[0].name : messages.name);
              } else {
                this.toastr.success(this.translocoService.translate('autoMessages.table.toasts.archiveMessageSuccess'));
                this.trackArchivedMessage(angular.isArray(messages) ? messages[0].name : messages.name);
              }
            }
            this.resetSelectedMessages();
            this.refreshMessages();
          });
        }
      })
      .catch(() => {});
  }

  /**
   * Установка статуса автосообщению
   *
   * @param {Boolean} status Статус на который будет изменено сообщение
   * @param {Array|Object} messages Массив сообщений или сообщение
   */
  changeMessageStatus(status: any, messages: any): any {
    var heading: any;
    var body: any;
    var confirmButtonText: any;

    var type = this.getCountActionType(messages);
    var isShowConfirm = true;
    var testAutoMessageGraph = status;

    if (type === this.COUNT_ACTION_TYPE.ONE) {
      if (status) {
        isShowConfirm = false;
      } else {
        heading = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forOne.heading');
        body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forOne.body');
      }
    } else if (type === this.COUNT_ACTION_TYPE.SEVERAL) {
      if (status) {
        heading = this.translocoService.translate(
          'autoMessages.table.changeMessageStatusModal.activate.forSeveral.heading',
        );
        body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forSeveral.body', {
          selectedMessages: messages.length,
        });
      } else {
        heading = this.translocoService.translate(
          'autoMessages.table.changeMessageStatusModal.pause.forSeveral.heading',
        );
        body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forSeveral.body', {
          selectedMessages: messages.length,
        });
      }
    } else if (type === this.COUNT_ACTION_TYPE.ALL) {
      if (status) {
        heading = this.translocoService.translate(
          'autoMessages.table.changeMessageStatusModal.activate.forAll.heading',
        );

        if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body1', {
            directoryName: this.currentDirectory.prettyName,
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body2', {
            directoryName: this.currentDirectory.prettyName,
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body3', {
            directoryName: this.currentDirectory.prettyName,
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body4', {
            directoryName: this.currentDirectory.prettyName,
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body5', {
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body6', {
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body7');
        } else if (
          this.currentStatus === MESSAGE_STATUSES.STOPPED &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.forAll.body8');
        }
      } else {
        heading = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.heading');
        if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body1', {
            directoryName: this.currentDirectory.prettyName,
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body2', {
            directoryName: this.currentDirectory.prettyName,
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body3', {
            directoryName: this.currentDirectory.prettyName,
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body4', {
            directoryName: this.currentDirectory.prettyName,
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body5', {
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType !== MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body6', {
            messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
          });
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ALL &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body7');
        } else if (
          this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
          this.currentType === MESSAGE_PART_TYPES.ALL &&
          this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
        ) {
          body = this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forAll.body8');
        }
      }
    }

    confirmButtonText = status
      ? this.translocoService.translate('autoMessages.table.changeMessageStatusModal.activate.confirmButtonText')
      : this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.confirmButtonText');

    if (isShowConfirm) {
      let changeActiveModal = this.modalHelperService
        .provide(CONFIRM_MODAL_DATA_TOKEN, {
          heading: heading,
          body: body,
          confirmButtonText: confirmButtonText,
        })
        .open(ConfirmModalComponent);

      changeActiveModal.result
        .then(() => {
          var executedFunction;

          if (type == this.COUNT_ACTION_TYPE.ONE) {
            if (status) {
              executedFunction = angular.bind(
                null,
                this.messageModel.setActive,
                this.currentApp.id,
                testAutoMessageGraph,
                angular.isArray(messages) ? messages[0].id : messages.id,
              );
            } else {
              executedFunction = angular.bind(
                null,
                this.messageModel.setPaused,
                this.currentApp.id,
                testAutoMessageGraph,
                angular.isArray(messages) ? messages[0].id : messages.id,
              );
            }
          } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
            if (status) {
              executedFunction = angular.bind(
                null,
                this.messageModel.setActive,
                this.currentApp.id,
                testAutoMessageGraph,
                map(messages, 'id'),
              );
            } else {
              executedFunction = angular.bind(
                null,
                this.messageModel.setPaused,
                this.currentApp.id,
                testAutoMessageGraph,
                map(messages, 'id'),
              );
            }
          } else if (type == this.COUNT_ACTION_TYPE.ALL) {
            if (status) {
              executedFunction = angular.bind(
                null,
                this.messageModel.setActive,
                this.currentApp.id,
                testAutoMessageGraph,
                null,
                this.currentStatus,
                this.currentType,
                this.currentDirectory.id,
              );
            } else {
              executedFunction = angular.bind(
                null,
                this.messageModel.setPaused,
                this.currentApp.id,
                testAutoMessageGraph,
                null,
                this.currentStatus,
                this.currentType,
                this.currentDirectory.id,
              );
            }
          }

          if (executedFunction) {
            firstValueFrom(executedFunction()).then(
              (data: any) => {
                // FIXME: Это должно быть, разумеется, не в success-колбэке, а в error, но сервер присылает success, и делает всё правильно.
                //  По идее, в модели при наличии поля errored должен возвращаться $q.reject(), но этого не делается из-за того, что придётся соблюдать формат объекта с ошибкой, которые обычно возвращает сервер.
                //  Но формирование объекта с ошибкой ещё большие костыли, чем просто обработать errored в success-колбэке
                if (data.errored.expiredMessages) {
                  var totalMessages = data.errored.expiredMessages + data.updated;

                  if (totalMessages === 1) {
                    this.toastr.danger(
                      this.translocoService.translate('autoMessages.table.toasts.activatedMessageError'),
                    );
                  } else if (totalMessages > 1) {
                    this.toastr.danger(
                      this.translocoService.translate('autoMessages.table.toasts.activatedMessagesError', {
                        erroredMessagesCount: data.errored.expiredMessages,
                        totalMessagesCount: totalMessages,
                      }),
                    );
                  }

                  this.trackActivatedMessages();
                } else if (data.errored.invalidCapabilities) {
                  var totalMessages = data.errored.invalidCapabilities + data.updated;

                  if (totalMessages === 1) {
                    let toast = this.toastr.danger(
                      this.translocoService.translate('autoMessages.table.toasts.messageCapabilityNotAvailable'),
                      undefined,
                      {
                        closable: true,
                        onTap: () => {
                          this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                          this.toastr.remove(toast);
                        },
                        delay: 8000,
                      },
                    );
                  } else if (totalMessages > 1) {
                    let toast = this.toastr.danger(
                      this.translocoService.translate('autoMessages.table.toasts.messagesCapabilityNotAvailable', {
                        erroredMessagesCount: data.errored.invalidCapabilities,
                        totalMessagesCount: totalMessages,
                      }),
                      undefined,
                      {
                        closable: true,
                        onTap: () => {
                          this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                          this.toastr.remove(toast);
                        },
                        delay: 8000,
                      },
                    );
                  }

                  this.trackActivatedMessages();
                } else if (data.updated > 1) {
                  if (status) {
                    this.toastr.success(
                      this.translocoService.translate('autoMessages.table.toasts.activatedMessagesSuccess', {
                        messagesCount: data.updated,
                      }),
                    );
                    if (this.appModel.isAppBlocked(this.currentApp)) {
                      this.openInstallScriptModal();
                    }
                    this.trackActivatedMessages();

                    this.activeMessagesAmounts = this.activeMessagesAmounts + data.updated;
                    this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                      this.currentApp,
                      this.activeMessagesAmounts,
                    );
                  } else {
                    this.toastr.success(
                      this.translocoService.translate('autoMessages.table.toasts.pausedMessagesSuccess', {
                        messagesCount: data.updated,
                      }),
                    );
                    this.trackPausedMessages();

                    this.activeMessagesAmounts = this.activeMessagesAmounts - data.updated;
                    this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                      this.currentApp,
                      this.activeMessagesAmounts,
                    );
                  }
                } else {
                  if (status) {
                    this.toastr.success(
                      this.translocoService.translate('autoMessages.table.toasts.activatedMessageSuccess'),
                    );
                    if (this.appModel.isAppBlocked(this.currentApp)) {
                      this.openInstallScriptModal();
                    }
                    this.trackActivatedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                    this.activeMessagesAmounts = this.activeMessagesAmounts + 1;
                    this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                      this.currentApp,
                      this.activeMessagesAmounts,
                    );
                  } else {
                    this.toastr.success(
                      this.translocoService.translate('autoMessages.table.toasts.pausedMessageSuccess'),
                    );
                    this.trackPausedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                    this.activeMessagesAmounts = this.activeMessagesAmounts - 1;
                    this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                      this.currentApp,
                      this.activeMessagesAmounts,
                    );
                  }
                }
                this.resetSelectedMessages();
                this.refreshMessages();
              },
              (response: any) => {
                var exception = (this.caseStyleHelper.keysToCamelCase(response) as any).meta;
                if (exception.error == 'EventTypeLoopError') {
                  var currentMessageName = '';
                  if (type === this.COUNT_ACTION_TYPE.ONE) {
                    currentMessageName = Array.isArray(messages) ? messages[0].name : messages.name;
                  } else {
                    currentMessageName = 'несколько автосообщений'; // переводить не нужно т.к. используется для трека событий
                  }
                  let detectedMessageLoop = this.modalHelperService.open(AutoMessageLoopModalComponent);

                  detectedMessageLoop.componentInstance.modalWindowParams = {
                    currentMessageIds: exception.currentMessageIds,
                    currentMessageName: currentMessageName,
                    saveActivateButton: this.translocoService.translate(
                      'autoMessages.table.failedSetStatusModal.turnOn',
                    ),
                    saveDeactivateButton: this.translocoService.translate(
                      'autoMessages.table.failedSetStatusModal.turnOff',
                    ),
                    loops: exception.loops[0],
                  };

                  detectedMessageLoop.result.then((result: any) => {
                    var executedFunction;
                    if (result) {
                      if (type === this.COUNT_ACTION_TYPE.ONE) {
                        const messageId = Array.isArray(messages) ? messages[0].id : messages.id;
                        executedFunction = angular.bind(
                          null,
                          this.messageModel.setActive,
                          this.currentApp.id,
                          false,
                          messageId,
                        );
                      } else if (type === this.COUNT_ACTION_TYPE.SEVERAL) {
                        executedFunction = angular.bind(
                          null,
                          this.messageModel.setActive,
                          this.currentApp.id,
                          false,
                          map(this.selectedMessages, 'id'),
                        );
                      } else if (type == this.COUNT_ACTION_TYPE.ALL) {
                        executedFunction = angular.bind(
                          null,
                          this.messageModel.setActive,
                          this.currentApp.id,
                          false,
                          null,
                          this.currentStatus,
                          this.currentType,
                          this.currentDirectory.id,
                        );
                      }
                      if (executedFunction) {
                        firstValueFrom(executedFunction()).then((data: any) => {
                          let toast; // Инстанс тоста для ошибки активации сообщений из-за недоступности фичи в тарифе
                          // FIXME: Это должно быть, разумеется, не в success-колбэке, а в error, но сервер присылает success, и делает всё правильно.
                          //  По идее, в модели при наличии поля errored должен возвращаться $q.reject(), но этого не делается из-за того, что придётся соблюдать формат объекта с ошибкой, которые обычно возвращает сервер.
                          //  Но формирование объекта с ошибкой ещё большие костыли, чем просто обработать errored в success-колбэке
                          if (data.errored.expiredMessages) {
                            var totalMessages = data.errored.expiredMessages + data.updated;

                            if (totalMessages === 1) {
                              this.toastr.danger(
                                this.translocoService.translate('autoMessages.table.toasts.activatedMessageError'),
                              );
                            } else if (totalMessages > 1) {
                              this.toastr.danger(
                                this.translocoService.translate('autoMessages.table.toasts.activatedMessagesError', {
                                  erroredMessagesCount: data.errored.expiredMessages,
                                  totalMessagesCount: totalMessages,
                                }),
                              );
                            }

                            this.trackActivatedMessages();
                          } else if (data.errored.invalidCapabilities) {
                            var totalMessages = data.errored.invalidCapabilities + data.updated;

                            if (totalMessages === 1) {
                              let toast = this.toastr.danger(
                                this.translocoService.translate(
                                  'autoMessages.table.toasts.messageCapabilityNotAvailable',
                                ),
                                undefined,
                                {
                                  closable: true,
                                  onTap: () => {
                                    this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                                    this.toastr.remove(toast);
                                  },
                                  delay: 8000,
                                },
                              );
                            } else if (totalMessages > 1) {
                              let toast = this.toastr.danger(
                                this.translocoService.translate(
                                  'autoMessages.table.toasts.messagesCapabilityNotAvailable',
                                  {
                                    erroredMessagesCount: data.errored.invalidCapabilities,
                                    totalMessagesCount: totalMessages,
                                  },
                                ),
                                undefined,
                                {
                                  closable: true,
                                  onTap: () => {
                                    this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                                    this.toastr.remove(toast);
                                  },
                                  delay: 8000,
                                },
                              );
                            }

                            this.trackActivatedMessages();
                          } else if (data.updated > 1) {
                            if (status) {
                              this.toastr.success(
                                this.translocoService.translate('autoMessages.table.toasts.activatedMessagesSuccess', {
                                  messagesCount: data.updated,
                                }),
                              );
                              if (this.appModel.isAppBlocked(this.currentApp)) {
                                this.openInstallScriptModal();
                              }
                              this.trackActivatedMessages();

                              this.activeMessagesAmounts = this.activeMessagesAmounts + data.updated;
                              this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                                PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                                this.currentApp,
                                this.activeMessagesAmounts,
                              );
                            } else {
                              this.toastr.success(
                                this.translocoService.translate('autoMessages.table.toasts.pausedMessagesSuccess', {
                                  messagesCount: data.updated,
                                }),
                              );
                              this.trackPausedMessages();

                              this.activeMessagesAmounts = this.activeMessagesAmounts - data.updated;
                              this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                                PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                                this.currentApp,
                                this.activeMessagesAmounts,
                              );
                            }
                          } else {
                            if (status) {
                              this.toastr.success(
                                this.translocoService.translate('autoMessages.table.toasts.activatedMessageSuccess'),
                              );
                              if (this.appModel.isAppBlocked(this.currentApp)) {
                                this.openInstallScriptModal();
                              }
                              this.trackActivatedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                              this.activeMessagesAmounts = this.activeMessagesAmounts + 1;
                              this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                                PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                                this.currentApp,
                                this.activeMessagesAmounts,
                              );
                            } else {
                              this.toastr.success(
                                this.translocoService.translate('autoMessages.table.toasts.pausedMessageSuccess'),
                              );
                              this.trackPausedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                              this.activeMessagesAmounts = this.activeMessagesAmounts - 1;
                              this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                                PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                                this.currentApp,
                                this.activeMessagesAmounts,
                              );
                            }
                          }
                          this.resetSelectedMessages();
                          this.refreshMessages();
                        });
                      }
                    }
                  });
                } else {
                  this.systemError.somethingWentWrongToast.show();
                }
              },
            );
          }
        })
        .catch(() => {});
    } else {
      var executedFunction;

      if (type == this.COUNT_ACTION_TYPE.ONE) {
        if (status) {
          executedFunction = angular.bind(
            null,
            this.messageModel.setActive,
            this.currentApp.id,
            testAutoMessageGraph,
            angular.isArray(messages) ? messages[0].id : messages.id,
          );
        } else {
          executedFunction = angular.bind(
            null,
            this.messageModel.setPaused,
            this.currentApp.id,
            testAutoMessageGraph,
            angular.isArray(messages) ? messages[0].id : messages.id,
          );
        }
      } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
        if (status) {
          executedFunction = angular.bind(
            null,
            this.messageModel.setActive,
            this.currentApp.id,
            testAutoMessageGraph,
            map(messages, 'id'),
          );
        } else {
          executedFunction = angular.bind(
            null,
            this.messageModel.setPaused,
            this.currentApp.id,
            testAutoMessageGraph,
            map(messages, 'id'),
          );
        }
      } else if (type == this.COUNT_ACTION_TYPE.ALL) {
        if (status) {
          executedFunction = angular.bind(
            null,
            this.messageModel.setActive,
            this.currentApp.id,
            testAutoMessageGraph,
            null,
            this.currentStatus,
            this.currentType,
            this.currentDirectory.id,
          );
        } else {
          executedFunction = angular.bind(
            null,
            this.messageModel.setPaused,
            this.currentApp.id,
            testAutoMessageGraph,
            null,
            this.currentStatus,
            this.currentType,
            this.currentDirectory.id,
          );
        }
      }

      if (executedFunction) {
        firstValueFrom(executedFunction()).then(
          (data: any) => {
            let toast; // Инстанс тоста для ошибки активации сообщений из-за недоступности фичи в тарифе
            // FIXME: Это должно быть, разумеется, не в success-колбэке, а в error, но сервер присылает success, и делает всё правильно.
            //  По идее, в модели при наличии поля errored должен возвращаться $q.reject(), но этого не делается из-за того, что придётся соблюдать формат объекта с ошибкой, которые обычно возвращает сервер.
            //  Но формирование объекта с ошибкой ещё большие костыли, чем просто обработать errored в success-колбэке
            if (data.errored.expiredMessages) {
              var totalMessages = data.errored.expiredMessages + data.updated;

              if (totalMessages === 1) {
                this.toastr.danger(this.translocoService.translate('autoMessages.table.toasts.activatedMessageError'));
              } else if (totalMessages > 1) {
                this.toastr.danger(
                  this.translocoService.translate('autoMessages.table.toasts.activatedMessagesError', {
                    erroredMessagesCount: data.errored.expiredMessages,
                    totalMessagesCount: totalMessages,
                  }),
                );
              }

              this.trackActivatedMessages();
            } else if (data.errored.invalidCapabilities) {
              var totalMessages = data.errored.invalidCapabilities + data.updated;

              if (totalMessages === 1) {
                let toast = this.toastr.danger(
                  this.translocoService.translate('autoMessages.table.toasts.messageCapabilityNotAvailable'),
                  undefined,
                  {
                    closable: true,
                    onTap: () => {
                      this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                      this.toastr.remove(toast);
                    },
                    delay: 8000,
                  },
                );
              } else if (totalMessages > 1) {
                let toast = this.toastr.danger(
                  this.translocoService.translate('autoMessages.table.toasts.messagesCapabilityNotAvailable', {
                    erroredMessagesCount: data.errored.invalidCapabilities,
                    totalMessagesCount: totalMessages,
                  }),
                  undefined,
                  {
                    closable: true,
                    onTap: () => {
                      this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                      this.toastr.remove(toast);
                    },
                    delay: 8000,
                  },
                );
              }

              this.trackActivatedMessages();
            } else if (data.updated > 1) {
              if (status) {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.activatedMessagesSuccess', {
                    messagesCount: data.updated,
                  }),
                );
                if (this.appModel.isAppBlocked(this.currentApp)) {
                  this.openInstallScriptModal();
                }
                this.trackActivatedMessages();

                this.activeMessagesAmounts = this.activeMessagesAmounts + data.updated;
                this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                  PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                  this.currentApp,
                  this.activeMessagesAmounts,
                );
              } else {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.pausedMessagesSuccess', {
                    messagesCount: data.updated,
                  }),
                );
                this.trackPausedMessages();

                this.activeMessagesAmounts = this.activeMessagesAmounts - data.updated;
                this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                  PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                  this.currentApp,
                  this.activeMessagesAmounts,
                );
              }
            } else {
              if (status) {
                this.toastr.success(
                  this.translocoService.translate('autoMessages.table.toasts.activatedMessageSuccess'),
                );
                if (this.appModel.isAppBlocked(this.currentApp)) {
                  this.openInstallScriptModal();
                }
                this.trackActivatedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                this.activeMessagesAmounts = this.activeMessagesAmounts + 1;
                this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                  PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                  this.currentApp,
                  this.activeMessagesAmounts,
                );
              } else {
                this.toastr.success(this.translocoService.translate('autoMessages.table.toasts.pausedMessageSuccess'));
                this.trackPausedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                this.activeMessagesAmounts = this.activeMessagesAmounts - 1;
                this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                  PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                  this.currentApp,
                  this.activeMessagesAmounts,
                );
              }
            }
            this.resetSelectedMessages();
            this.refreshMessages();
          },
          (response: any) => {
            // @ts-ignore
            var exception = this.caseStyleHelper.keysToCamelCase(response).meta;
            if (exception.error == 'EventTypeLoopError') {
              var currentMessageName = '';
              if (type === this.COUNT_ACTION_TYPE.ONE) {
                currentMessageName = Array.isArray(messages) ? messages[0].name : messages.name;
              } else {
                currentMessageName = 'несколько автосообщений';
              }
              let detectedMessageLoop = this.modalHelperService.open(AutoMessageLoopModalComponent);

              detectedMessageLoop.componentInstance.modalWindowParams = {
                currentMessageIds: exception.currentMessageIds,
                currentMessageName: currentMessageName,
                saveActivateButton: this.translocoService.translate('autoMessages.table.failedSetStatusModal.turnOn'),
                saveDeactivateButton: this.translocoService.translate(
                  'autoMessages.table.failedSetStatusModal.turnOff',
                ),
                loops: exception.loops[0],
              };

              detectedMessageLoop.result
                .then((result: any) => {
                  var executedFunction;
                  if (result) {
                    if (type == this.COUNT_ACTION_TYPE.ONE) {
                      const messageId = Array.isArray(messages) ? messages[0].id : messages.id;
                      executedFunction = angular.bind(
                        null,
                        this.messageModel.setActive,
                        this.currentApp.id,
                        false,
                        messageId,
                      );
                    } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
                      executedFunction = angular.bind(
                        null,
                        this.messageModel.setActive,
                        this.currentApp.id,
                        false,
                        map(this.selectedMessages, 'id'),
                      );
                    } else if (type == this.COUNT_ACTION_TYPE.ALL) {
                      executedFunction = angular.bind(
                        null,
                        this.messageModel.setActive,
                        this.currentApp.id,
                        false,
                        null,
                        this.currentStatus,
                        this.currentType,
                        this.currentDirectory.id,
                      );
                    }

                    if (executedFunction) {
                      firstValueFrom(executedFunction()).then((data: any) => {
                        let toast; // Инстанс тоста для ошибки активации сообщений из-за недоступности фичи в тарифе
                        // FIXME: Это должно быть, разумеется, не в success-колбэке, а в error, но сервер присылает success, и делает всё правильно.
                        //  По идее, в модели при наличии поля errored должен возвращаться $q.reject(), но этого не делается из-за того, что придётся соблюдать формат объекта с ошибкой, которые обычно возвращает сервер.
                        //  Но формирование объекта с ошибкой ещё большие костыли, чем просто обработать errored в success-колбэке
                        if (data.errored.expiredMessages) {
                          var totalMessages = data.errored.expiredMessages + data.updated;

                          if (totalMessages === 1) {
                            this.toastr.danger(
                              this.translocoService.translate('autoMessages.table.toasts.activatedMessageError'),
                            );
                          } else if (totalMessages > 1) {
                            this.toastr.danger(
                              this.translocoService.translate('autoMessages.table.toasts.activatedMessagesError', {
                                erroredMessagesCount: data.errored.expiredMessages,
                                totalMessagesCount: totalMessages,
                              }),
                            );
                          }

                          this.trackActivatedMessages();
                        } else if (data.errored.invalidCapabilities) {
                          var totalMessages = data.errored.invalidCapabilities + data.updated;

                          if (totalMessages === 1) {
                            let toast = this.toastr.danger(
                              this.translocoService.translate(
                                'autoMessages.table.toasts.messageCapabilityNotAvailable',
                              ),
                              undefined,
                              {
                                closable: true,
                                onTap: () => {
                                  this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                                  this.toastr.remove(toast);
                                },
                                delay: 8000,
                              },
                            );
                          } else if (totalMessages > 1) {
                            let toast = this.toastr.danger(
                              this.translocoService.translate(
                                'autoMessages.table.toasts.messagesCapabilityNotAvailable',
                                {
                                  erroredMessagesCount: data.errored.invalidCapabilities,
                                  totalMessagesCount: totalMessages,
                                },
                              ),
                              undefined,
                              {
                                closable: true,
                                onTap: () => {
                                  this.showPaywall(this.accessToAutoMessagesMassActivation.denialReason);
                                  this.toastr.remove(toast);
                                },
                                delay: 8000,
                              },
                            );
                          }

                          this.trackActivatedMessages();
                        } else if (data.updated > 1) {
                          if (status) {
                            this.toastr.success(
                              this.translocoService.translate('autoMessages.table.toasts.activatedMessagesSuccess', {
                                messagesCount: data.updated,
                              }),
                            );
                            if (this.appModel.isAppBlocked(this.currentApp)) {
                              this.openInstallScriptModal();
                            }
                            this.trackActivatedMessages();

                            this.activeMessagesAmounts = this.activeMessagesAmounts + data.updated;
                            this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                              PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                              this.currentApp,
                              this.activeMessagesAmounts,
                            );
                          } else {
                            this.toastr.success(
                              this.translocoService.translate('autoMessages.table.toasts.pausedMessagesSuccess', {
                                messagesCount: data.updated,
                              }),
                            );
                            this.trackPausedMessages();

                            this.activeMessagesAmounts = this.activeMessagesAmounts - data.updated;
                            this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                              PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                              this.currentApp,
                              this.activeMessagesAmounts,
                            );
                          }
                        } else {
                          if (status) {
                            this.toastr.success(
                              this.translocoService.translate('autoMessages.table.toasts.activatedMessageSuccess'),
                            );
                            if (this.appModel.isAppBlocked(this.currentApp)) {
                              this.openInstallScriptModal();
                            }
                            this.trackActivatedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                            this.activeMessagesAmounts = this.activeMessagesAmounts + 1;
                            this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                              PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                              this.currentApp,
                              this.activeMessagesAmounts,
                            );
                          } else {
                            this.toastr.success(
                              this.translocoService.translate('autoMessages.table.toasts.pausedMessageSuccess'),
                            );
                            this.trackPausedMessage(angular.isArray(messages) ? messages[0].name : messages.name);

                            this.activeMessagesAmounts = this.activeMessagesAmounts - 1;
                            this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
                              PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
                              this.currentApp,
                              this.activeMessagesAmounts,
                            );
                          }
                        }
                        this.resetSelectedMessages();
                        this.refreshMessages();
                      });
                    }
                  }
                })
                .catch(() => {});
            } else {
              this.systemError.somethingWentWrongToast.show();
            }
          },
        );
      }
    }
  }

  changeScheduledMessageStatus(message: any) {
    const newStatus = !message.active;
    if (newStatus) {
      this.messageModel.patchScheduledMessage(message.id, { active: newStatus }).subscribe(() => {
        this.toastr.success(
          this.translocoService.translate('autoMessages.table.toasts.activatedMessagesSuccess', {
            messagesCount: 1,
          }),
        );

        this.activeMessagesAmounts = this.activeMessagesAmounts + 1;
        this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
          PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
          this.currentApp,
          this.activeMessagesAmounts,
        );
        this.refreshMessages();
      });
    } else {
      this.modalHelperService
        .provide(CONFIRM_MODAL_DATA_TOKEN, {
          heading: this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forOne.heading'),
          body: this.translocoService.translate('autoMessages.table.changeMessageStatusModal.pause.forOne.body'),
          confirmButtonText: this.translocoService.translate(
            'autoMessages.table.changeMessageStatusModal.pause.confirmButtonText',
          ),
        })
        .open(ConfirmModalComponent)
        .result.then(() => {
          this.messageModel.patchScheduledMessage(message.id, { active: newStatus }).subscribe(() => {
            this.toastr.success(
              this.translocoService.translate('autoMessages.table.toasts.pausedMessagesSuccess', {
                messagesCount: 1,
              }),
            );

            this.activeMessagesAmounts = this.activeMessagesAmounts - 1;
            this.accessToAutoMessages = this.planFeatureAccessService.getAccess(
              PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
              this.currentApp,
              this.activeMessagesAmounts,
            );
            this.refreshMessages();
          });
        })
        .catch();
    }
  }
  /**
   * Открытие модалки для перемещения сообщений
   *
   * @param {Array|Object} messages Массив сообщений или сообщение
   */
  openSetDirectoryModal(messages: any): any {
    var modalParams: any = {
      currentApp: this.currentApp,
    };

    var type = this.getCountActionType(messages);

    if (type == this.COUNT_ACTION_TYPE.ONE) {
      modalParams.messageIds = angular.isArray(messages) ? messages[0].id : messages.id;
      modalParams.messageDirectory = angular.isArray(messages) ? messages[0].directory : messages.directory;
      modalParams.heading = this.translocoService.translate('autoMessages.table.setDirectoryModal.forOne.heading');
      modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forOne.body');
    } else if (type == this.COUNT_ACTION_TYPE.SEVERAL) {
      modalParams.messageIds = map(messages, 'id');
      modalParams.heading = this.translocoService.translate('autoMessages.table.setDirectoryModal.forSeveral.heading');
      modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forSeveral.body', {
        messagesCount: messages.length,
      });
    } else if (type == this.COUNT_ACTION_TYPE.ALL) {
      modalParams.currentStatus = this.currentStatus;
      modalParams.currentMessageType = this.currentType;
      modalParams.currentDirectoryId = this.currentDirectory.id;
      modalParams.heading = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.heading');

      if (
        this.currentStatus === MESSAGE_STATUSES.ALL &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body1', {
          directoryName: this.currentDirectory.prettyName,
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body2', {
          directoryName: this.currentDirectory.prettyName,
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.STOPPED &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body3', {
          directoryName: this.currentDirectory.prettyName,
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body4', {
          directoryName: this.currentDirectory.prettyName,
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.STOPPED &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body5', {
          directoryName: this.currentDirectory.prettyName,
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ALL &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body6', {
          directoryName: this.currentDirectory.prettyName,
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ALL &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body7', {
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id !== PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body8', {
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.STOPPED &&
        this.currentType !== MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body9', {
          messagePartType: this.translocoService.translate('models.message.messagePartTypes.' + this.currentType),
        });
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ACTIVE &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body10');
      } else if (
        this.currentStatus === MESSAGE_STATUSES.STOPPED &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body11');
      } else if (
        this.currentStatus === MESSAGE_STATUSES.ALL &&
        this.currentType === MESSAGE_PART_TYPES.ALL &&
        this.currentDirectory.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]
      ) {
        modalParams.body = this.translocoService.translate('autoMessages.table.setDirectoryModal.forAll.body12');
      }
    }

    let setDirectoryModal = this.modalHelperService.open(AutoMessageSetDirectoryModalComponent);

    setDirectoryModal.componentInstance.modalWindowParams = modalParams;

    setDirectoryModal.result
      .then((response: any) => {
        let isDirExist = this.directories.find((dir: any) => dir.id === response.newDirectory.id) !== undefined;
        if (!isDirExist) {
          this.directories = [...this.directories, response.newDirectory];
        }
        if (type == this.COUNT_ACTION_TYPE.ONE) {
          this.trackSetMessageDirectory(
            angular.isArray(messages) ? messages[0].name : messages.name,
            response.newDirectory.prettyName,
          );
        } else {
          this.trackSetMessagesDirectory(response.newDirectory.prettyName);
        }

        this.resetSelectedMessages();
        this.refreshMessages();
      })
      .catch(() => {});
  }

  /**
   * Переход на состояние создания копии
   */
  createCopy(message: any): void {
    let messageId: any;

    if (this.getCountActionType(message) === this.COUNT_ACTION_TYPE.ONE) {
      this.trackClickCreateCopy(message.name);
      if (angular.isDefined(message)) {
        messageId = message.id;
      }
      this.uiRouter.stateService.go('app.content.messagesAjs.edit.copy', { messageId: messageId });
    }
  }

  createScheduledMessageCopy(message: any) {
    this.uiRouter.stateService.go('app.content.scheduledMessages.edit.copy', { messageId: message.id });
  }

  /**
   * Определение для одного, нескольких или всех сообщений будет производиться действие
   *
   * @param messages Массив сообщений или сообщение
   * @returns {String}
   */
  getCountActionType(messages: any): any {
    const messagesArray = Array.isArray(messages) ? messages : [messages];
    if (messagesArray.length === 1) {
      return this.COUNT_ACTION_TYPE.ONE;
    } else if (
      messagesArray.length > 1 &&
      (!this.selectedMessagesAll || (this.selectedMessagesAll && !this.messagesPaginator.paginatePosition))
    ) {
      return this.COUNT_ACTION_TYPE.SEVERAL;
    } else if (this.selectedMessagesAll && this.messagesPaginator.paginatePosition) {
      return this.COUNT_ACTION_TYPE.ALL;
    }
  }

  /**
   * Переименовать сообщение
   *
   * @param message Автосообщение
   */
  openRenameModal(message: any): void {
    let messageName = message.name;

    let renameMessageModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('autoMessages.table.renameMessageModal.heading'),
        confirmButtonText: this.translocoService.translate('general.save'),
        inputMaxLength: 255,
        inputMaxLengthErrorText: this.translocoService.translate(
          'autoMessages.table.renameMessageModal.inputMaxLengthErrorText',
          { maxLength: 255 },
        ),
        inputPlaceholder: this.translocoService.translate('autoMessages.table.renameMessageModal.inputPlaceholder'),
        text: messageName,
      })
      .open(PromptModalComponent);

    renameMessageModal.result
      .then((newMessageName: any) => {
        if (newMessageName !== message.name) {
          const params = {
            name: newMessageName,
          };
          this.messageModel.edit(message.id, params).subscribe(() => {
            this.messages = this.messages.map((item: any) => {
              if (item.id === message.id) {
                item.name = newMessageName;
              }

              return item;
            });
            this.isApiRequestPerformed = true;

            this.toastr.success(this.translocoService.translate('autoMessages.table.toasts.renameMessageSuccess'));
            this.cdr.detectChanges();
          });
        }
      })
      .catch(() => {});
  }

  renameScheduledMessage({ id, name }: any) {
    const renameMessageModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('autoMessages.table.renameMessageModal.heading'),
        confirmButtonText: this.translocoService.translate('general.save'),
        inputMaxLength: 255,
        inputMaxLengthErrorText: this.translocoService.translate(
          'autoMessages.table.renameMessageModal.inputMaxLengthErrorText',
          { maxLength: 255 },
        ),
        inputPlaceholder: this.translocoService.translate('autoMessages.table.renameMessageModal.inputPlaceholder'),
        text: name,
      })
      .open(PromptModalComponent);

    renameMessageModal.result
      .then((newMessageName: any) => {
        if (newMessageName !== name) {
          this.messageModel.patchScheduledMessage(id, { name: newMessageName }).subscribe(() => {
            this.messages = this.messages.map((item: any) => {
              if (item.id === id) {
                item.name = newMessageName;
              }

              return item;
            });
            this.toastr.success(this.translocoService.translate('autoMessages.table.toasts.renameMessageSuccess'));
            this.cdr.detectChanges();
          });
        }
      })
      .catch(() => {});
  }

  openReadyAutoMessagesModal(): void {
    let modal = this.modalHelperService.open(ReadyAutoMessagesModalComponent, {
      size: 'lg modal-dialog-centered',
    });

    modal.componentInstance.modalWindowParams = {
      appActivation: this.currentApp.activation,
      selectedUseCase: this.onboarding.appUsageGoal,
    };
  }

  openMessageTemplatesModalOnCondition(): void {
    if (this.hasMessages) {
      return;
    }
    this.openReadyAutoMessagesModal();
  }

  refreshMessages(): void {
    this.getMessages(true);
  }

  /**
   * Обновление выделенных сообщений
   */
  refreshSelectedMessages(): void {
    this.selectedMessages = filter(this.messages, { checked: true });
    this.updateAccessToSelectedAutoMessages();
  }

  /**
   * Отметить или снять галочки с сообщений
   */
  setSelectForAllMessages(value: any): void {
    this.selectedMessagesAll = !!value;

    for (let i = 0; i < this.messages.length; i++) {
      this.messages[i].checked = this.selectedMessagesAll;
    }
    this.refreshSelectedMessages();
  }

  changeSearchMode(isSearch: boolean) {
    this.isSearchMode = isSearch;

    if (!isSearch) {
      this.searchForm.setValue(null);
    }
  }

  /**
   * Показ пейволла
   *
   * @param denialReason Причина показа
   */
  showPaywall(denialReason: any): void {
    this.paywallService.showPaywall(this.currentApp, denialReason);
  }

  /**
   * Показ модалки установки скрипта
   */
  openInstallScriptModal(): void {
    let modal = this.modalHelperService.open(InstallScriptModalComponent);

    modal.componentInstance.modalWindowParams = {
      body: this.translocoService.translate('autoMessages.table.installScriptModal.body'),
      currentApp: this.currentApp,
      djangoUser: this.djangoUser,
      heading: this.translocoService.translate('autoMessages.table.installScriptModal.heading'),
    };
  }

  /**
   * Обновление доступа до автосообщений, для проверки на возможность активации
   */
  updateAccessToSelectedAutoMessages(): void {
    const pausedSelectedMessagesAmount = filter(this.selectedMessages, { active: false }).length || 1;
    const messageToActivateAmount = pausedSelectedMessagesAmount + this.activeMessagesAmounts;

    this.accessToSelectedAutoMessages = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.currentApp,
      messageToActivateAmount,
      '<=',
    );

    if (this.selectedMessages.some((message: any) => message.type === MESSAGE_PART_TYPES.TELEGRAM)) {
      this.accessToSelectedTelegramAutoMessages = this.planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_TELEGRAM,
        this.currentApp,
      );
    } else {
      this.accessToSelectedTelegramAutoMessages = {
        hasAccess: true,
        denialReason: null,
      };
    }

    this.cdr.markForCheck();
  }

  /**
   * Трек активирования сообщения
   *
   * @param messageName Имя сообщения
   */
  trackActivatedMessage(messageName: any): void {
    this.carrotquestHelper.track('Автосообщения - активировал сообщение', { Название: messageName });
  }

  /**
   * Трек активирования сообщений
   */
  trackActivatedMessages(): void {
    this.carrotquestHelper.track('Автосообщения - массовая операция: активировал сообщения');
  }

  /**
   * Трек открытия модалки рекомендованные сценарии
   */
  trackOpenRecommendedTemplates(): void {
    this.carrotquestHelper.track('Триггерные сообщения - Рекомендованные сценарии - перешел из меню');
  }

  /**
   * Трек архивирования сообщения
   *
   * @param messageName Имя сообщения
   */
  trackArchivedMessage(messageName: any): void {
    this.carrotquestHelper.track('Автосообщения - архивировал сообщение', { Название: messageName });
  }

  /**
   * Трек архивирования сообщений
   */
  trackArchivedMessages(): void {
    this.carrotquestHelper.track('Автосообщения - массовая операция: архивировал сообщения');
  }

  /**
   * Трек клика на 'Создать копию'
   *
   * @param messageName Имя сообщения
   */
  trackClickCreateCopy(messageName: any): void {
    this.carrotquestHelper.track('Автосообщение - кликнул "Создать копию"', { Название: messageName });
  }

  /**
   * Трек клика по "Узнать больше о чат-ботах в базе знанинй"
   */
  trackClickOnKnowledgeBaseInDescription(): void {
    this.carrotquestHelper.track('Автосообщения - клик на узнайте больше в базе знаний');
  }

  /**
   * Трек захода на страницу с автосообщениями
   */
  trackEnterOnPage(): void {
    this.carrotquestHelper.track('Зашел на страницу автосообщений');
  }

  /**
   * Трек приостановки сообщения
   *
   * @param messageName Имя сообщения
   */
  trackPausedMessage(messageName: any): void {
    this.carrotquestHelper.track('Автосообщения - приостановил сообщение', { Название: messageName });
  }

  /**
   * Трек приостановки сообщений
   */
  trackPausedMessages(): void {
    this.carrotquestHelper.track('Автосообщения - массовая операция: приостановил сообщения');
  }

  /**
   * Трек восстановление сообщения
   */
  trackReestablishMessage(messageName: any): void {
    this.carrotquestHelper.track('Автосообщения - восстановил сообщение', { Название: messageName });
  }

  /**
   * Трек восстановление сообщений
   */
  trackReestablishMessages(): void {
    this.carrotquestHelper.track('Автосообщения - массовая операция: восстановил сообщения');
  }

  /**
   * Трек перемещения сообщения
   *
   * @param messageName Имя сообщения
   * @param directoryName Имя папки
   */
  trackSetMessageDirectory(messageName: any, directoryName: any): void {
    this.carrotquestHelper.track('Автосообщения - переместил в папку сообщение', {
      Название: messageName,
      'Название папки': directoryName,
    });
  }

  /**
   * Трек перемещения сообщений
   *
   * @param directoryName Имя папки
   */
  trackSetMessagesDirectory(directoryName: any): void {
    this.carrotquestHelper.track('Автосообщения - массовая операция: переместил в папку сообщения', {
      'Название папки': directoryName,
    });
  }

  onChangeStatus(status: any): void {
    this.currentStatus = status;
    this.filterMessages();
  }

  onChangeDirectory(directory: any): void {
    this.currentDirectory = directory;
    this.filterMessages();
  }

  onChangeType(type: any): void {
    this.currentType = type;
    this.filterMessages();
  }

  filterMessages(): void {
    this.resetSelectedMessages();
    this.getMessages(true);
  }

  protected getMessages(forceReload: boolean = false): void {
    switch (this.selectedMessageTriggerType) {
      case 'event':
        this.getEventMessages(forceReload);
        break;
      case 'schedule':
        this.getScheduleMessages(forceReload);
        break;
    }
  }

  protected onChangeMessageTriggerType(): void {
    this.resetSelectedMessages();
    this.resetFilters();
    this.resetSearch();
    this.getMessages(true);
  }

  protected getCreateMessageState(): string {
    switch (this.selectedMessageTriggerType) {
      case 'event':
        return 'app.content.messagesAjs.create';
      case 'schedule':
        return 'app.content.scheduledMessages.create';
    }
  }

  private getEventMessages(forceReload: boolean = false): void {
    this.isMessagesRequestPerformed = true;

    if (forceReload) {
      this.messages = [];
      this.messagesPaginator = null;
    }

    if (this.currentDirectory.name === SYSTEM_DIRECTORIES.ARCHIVE) {
      this.currentStatus = MESSAGE_STATUSES.ALL;
    }

    this.messageModel
      .getAutoMessages(
        this.currentType,
        this.currentStatus,
        this.messagesPaginator,
        this.currentDirectory.id,
        false,
        this.searchPattern,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isMessagesRequestPerformed = false;
          this.setSelectForAllMessages(false);
        }),
      )
      .subscribe(({ messages, paginatorParams }) => {
        this.messages = [...this.messages, ...messages];
        this.messagesPaginator = paginatorParams;

        this.messageModel.parseMessageFilters(forceReload ? this.messages : messages, this.properties, this.tags);
        this.messageModel.linkWithTriggers(forceReload ? this.messages : messages, this.eventTypes);
      });
  }

  private getScheduleMessages(forceReload: boolean = false): void {
    this.isMessagesRequestPerformed = true;

    if (forceReload) {
      this.messages = [];
      this.messagesPaginator = null;
    }

    if (this.currentDirectory.name === SYSTEM_DIRECTORIES.ARCHIVE) {
      this.currentStatus = MESSAGE_STATUSES.ALL;
    }

    this.messageModel
      .getScheduledMessages(
        this.currentType,
        this.currentStatus,
        this.messagesPaginator,
        this.properties,
        this.searchPattern,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isMessagesRequestPerformed = false;
          this.setSelectForAllMessages(false);
        }),
      )
      .subscribe(({ messages, paginatorParams }) => {
        this.messages = [...this.messages, ...messages];
        this.messagesPaginator = paginatorParams;

        this.messageModel.parseMessageFilters(forceReload ? this.messages : messages, this.properties, this.tags);
      });
  }

  private resetFilters(): void {
    this.currentDirectory = this.directories.find(
      (dir: any) => dir.id === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
    );
    this.currentStatus = MESSAGE_STATUSES.ACTIVE;
    this.currentType = MESSAGE_PART_TYPES.ALL;
  }

  resetForSearch(): void {
    this.isSearchMode = true;
    this.resetFilters();
    this.currentStatus = MESSAGE_STATUSES.ALL;
  }

  resetSearch(): void {
    this.isSearchMode = false;
    this.resetFilters();
    this.searchForm.setValue(null);
  }

  private resetSelectedMessages(): void {
    this.selectedMessagesAll = false;
    this.selectedMessages = [];
  }

  private get searchPattern(): string | null {
    return this.searchForm.value && this.isSearchMode ? this.searchForm.value : null;
  }
}
