import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AiContentMakerIntegrationSettingsExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-ai-content-maker-api-key',
  templateUrl: './ai-content-maker-api-key.component.html',
  styleUrls: ['./ai-content-maker-api-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiContentMakerApiKeyComponent {
  @Input({ required: true })
  control!: GenericFormControl<AiContentMakerIntegrationSettingsExternal['openAiApiKey']>;
}
