/**
 * Контроллер хидера административной панели
 */
import { firstValueFrom } from 'rxjs';
import { DJANGO_USER_PRESENCE_TYPES } from '../../../app/http/django-user/django-user.constants';
import { GetDemoModalComponent } from '../../../app/pages/account/shared/get-demo-modal/get-demo-modal.component';
import { SUBSCRIPTION_STATUSES, V3_PLAN_IDS } from '../../../app/services/billing-info/billing-info.constants';
import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular.module('myApp.header').controller('CqHeaderController', CqHeaderController);

  function CqHeaderController(
    $filter,
    $interval,
    $scope,
    $state,
    $timeout,
    $translate,
    $uibModal,
    moment,
    toastr,
    API_OBJECT_NAME,
    PROJECT_NAME,
    USER_FILES_URL,
    appModel,
    carrotquestHelper,
    featureModel,
    djangoUserModel,
    l10nHelper,
    billingPlanService,
    newsHelperService,
    planVersionService,
    systemLogModel,
    utilsService,
    modalHelperService,
    conversationsStoreService,
    planFeatureAccessService,
  ) {
    let vm = this;

    /**
     * Интервал обновления времени событий системного лога
     *
     * @type {Object|null}
     */
    let interval = null;

    vm.$onInit = init;

    function init() {
      vm.accessToConversationsReassignment = planFeatureAccessService.getAccess(
        PLAN_FEATURE.UNASSIGN_CONVERSATIONS,
        vm.currentApp,
      );

      vm.$translate = $translate;
      vm.API_OBJECT_NAME = API_OBJECT_NAME;
      vm.appModel = appModel;
      vm.appSearch = ''; // поиск по приложениям
      vm.DJANGO_USER_PRESENCE_TYPES = DJANGO_USER_PRESENCE_TYPES;
      vm.djangoUserAutoAssign = false;
      vm.djangoUserConversationsUnassignment = false;
      vm.djangoUserModel = djangoUserModel;
      vm.newestUndeadNewsId = null;
      vm.isAutoAssignPopoverOpened = false;
      vm.isConversationsAutoAssignmentEnabledInApp = () =>
        !!vm.currentApp.settings.messenger_conversations_auto_assign_type;
      vm.isShowAuditButton = isShowAuditButton;
      vm.isShowNewYearSaleLabel = isShowNewYearSaleLabel; // открыт ли системный лог
      vm.isSystemLogPopoverOpen = false; // открыт ли системный лог
      vm.isSubscriptionInTrial = vm.billingInfo.subscription.status === SUBSCRIPTION_STATUSES.IN_TRIAL;
      vm.isWebApp = utilsService.webApp;
      vm.l10nHelper = l10nHelper;
      vm.moment = moment;
      vm.onClickAutoAssignPopoverOkButton = onClickAutoAssignPopoverOkButton;
      vm.onSwitchAutoAssign = onSwitchAutoAssign;
      vm.onSwitchUnassignConversations = onSwitchUnassignConversations;
      vm.onSwitchStatus = onSwitchStatus;
      vm.openGetDemoModal = openGetDemoModal;
      vm.openHotkeysModal = openHotkeysModal;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.planVersionService = planVersionService;
      const V3PlanNamePrefix = V3_PLAN_IDS.find((id) => vm.billingInfo.subscription.plan_id.includes(id));
      vm.v3PlanName = $translate.instant('models.billingInfo.billingPlansNames.' + V3PlanNamePrefix);
      vm.stateParams = $state.params; // NOTE: указан $state.params вместо $stateParams т.к. после инжекта $stateParams не меняет своего значения
      vm.systemLogMessages = null; // массив системных сообщений
      vm.trackClickAcademy = trackClickAcademy;
      vm.trackClickAndroidApp = trackClickAndroidApp;
      vm.trackClickApiDocumentation = trackClickApiDocumentation;
      vm.trackClickCase = trackClickCase;
      vm.trackClickGetDemo = trackClickGetDemo;
      vm.trackClickGetAudit = trackClickGetAudit;
      vm.trackClickHotkeysModal = trackClickHotkeysModal;
      vm.trackClickIosApp = trackClickIosApp;
      vm.trackClickKnowledgeBase = trackClickKnowledgeBase;
      vm.trackClickLearnAboutUpgradePlanPopover = trackClickLearnAboutUpgradePlanPopover;
      vm.trackClickOnProfile = trackClickOnProfile;
      vm.trackClickOnSubscriptionStatus = trackClickOnSubscriptionStatus;
      vm.trackClickOnSystemLog = trackClickOnSystemLog;
      vm.trackClickWhatsNew = trackClickWhatsNew;
      vm.trackClickWindowsApp = trackClickWindowsApp;
      vm.unreadNewsCount = 0; // Количество непрочитанных новостей
      vm.unreadSysLogCount = 0; // Количество непрочитанных сообщений в системном логе
      vm.updateLastClickOnWhatsNewTime = updateLastClickOnWhatsNewTime;
      vm.USER_FILES_URL = USER_FILES_URL;
      vm.utilsService = utilsService;

      $scope.$on('$destroy', onDestroy);
      $scope.$on('message', handleRtsMessage);
      $scope.$watch('vm.isSystemLogPopoverOpen', onSystemLogPopoverToggle);

      getSystemLogMessages().then(updateUnreadSysLogCounter);
      newsHelperService.getUnreadNewsCounterByUser(vm.djangoUser).then((count) => {
        vm.unreadNewsCount = count;
      });

      /** Получение значения настройки автоназначения на оператора */
      if (vm.isConversationsAutoAssignmentEnabledInApp()) {
        firstValueFrom(djangoUserModel.getAutoAssignStatus(vm.djangoUser.id)).then((autoAssignStatus) => {
          vm.djangoUserAutoAssign = autoAssignStatus.readyForAutoAssign;

          // Если настройка автоназначения включена, но пользователь ещё не активировался в неё, то покажем поповер
          if (vm.djangoUserAutoAssign && !vm.djangoUserTempData.autoAssignActivated) {
            vm.isAutoAssignPopoverOpened = true;
          }
        });
      }

      if (vm.accessToConversationsReassignment.hasAccess) {
        firstValueFrom(djangoUserModel.getReassignConversationsValue()).then((value) => {
          vm.djangoUserConversationsUnassignment = value;
        });
      }

      /**
       * Обновление количества непрочитанных сообщений системного лога
       * @param {Array.<Object>} systemLogMessages Массив системных сообщений
       */
      function updateUnreadSysLogCounter(systemLogMessages) {
        vm.unreadSysLogCount = systemLogModel.unreadCount(vm.currentApp.id, systemLogMessages);
      }

      /**
       * Обработчик RTS сообщений
       *
       * @param event
       * @param info
       */
      function handleRtsMessage(event, info) {
        // если в системном логе повилось новое событие - нужно выставить флаг
        if (info.channel.indexOf('system_log_added.') == 0) {
          vm.unreadSysLogCount += 1;
          $scope.$applyAsync();
        }
      }

      /**
       * Показывать ли кнопку аудита
       *
       * @returns {boolean}
       */
      function isShowAuditButton() {
        //Получаем mrr в рублях
        const mrr = vm.billingInfo.subscription.mrr / 100;
        const isRus = vm.l10nHelper.isRusCountry();

        if (isRus && mrr < 15990) {
          return false;
        }

        return vm.isWebApp && !vm.isSubscriptionInTrial;
      }

      /**
       * Функция проверки показа лейбла с новогодней акцией
       *
       * @returns {boolean}
       */
      function isShowNewYearSaleLabel() {
        const isEndedTrial =
          vm.billingInfo.subscription.status === SUBSCRIPTION_STATUSES.CANCELLED && planVersionService.isTrial();
        const hasUnpaidInvoice =
          !planVersionService.isTrial() && !isEndedTrial && vm.billingInfo.subscription.due_invoices_count > 0;

        return planVersionService.isTrial() || isEndedTrial || hasUnpaidInvoice;
      }

      /**
       * Уничтожение всякого
       */
      function onDestroy() {
        // хоть таймер и уничтожается при закрытии поповера, пусть тут тоже будет его уничтожение. Кейсов я придумать не могу, но мало ли что
        stopSystemLogMessagesTimeRefreshing();
      }

      /**
       * Вызывается при открытии/закрытии поповера с системным логом
       *
       * @param {Boolean} isPopoverOpen Открыт ли поповер
       */
      function onSystemLogPopoverToggle(isPopoverOpen) {
        if (isPopoverOpen) {
          vm.unreadSysLogCount = 0;
          vm.systemLogMessages = null;
          getSystemLogMessages().then(setLastCheckedSystemLogMessageId).then(startSystemLogMessagesTimeRefreshing);
        } else {
          stopSystemLogMessagesTimeRefreshing();
        }

        /**
         * Сохранение даты последнего открытия системного лога
         */
        function setLastCheckedSystemLogMessageId(systemLogMessages) {
          if (systemLogMessages.length) {
            systemLogModel.setLastReadId(vm.currentApp.id, systemLogMessages[0].id);
          }
        }
      }
    }

    /**
     * Получение системного лога
     *
     * @returns {*}
     */
    function getSystemLogMessages() {
      return firstValueFrom(systemLogModel.getList(vm.currentApp.id)).then(getListSuccess);

      function getListSuccess(systemLogMessages) {
        vm.systemLogMessages = systemLogMessages;

        return systemLogMessages;
      }
    }

    /**
     * Открытие модалки с предложением записи на демо или аудита
     *
     * @param isAudit - является ли модалка аудитом
     */
    function openGetDemoModal(isAudit) {
      let modal = modalHelperService.open(GetDemoModalComponent, {
        size: vm.l10nHelper.isRusCountry() ? 'md' : 'xl',
        backdrop: 'static',
      });

      modal.componentInstance.modalWindowParams = {
        isAudit: isAudit,
        appList: vm.appList,
        currentApp: vm.currentApp,
        djangoUser: vm.djangoUser,
      };

      modal.result.then(getDemoModalSuccess).catch(() => {});

      function getDemoModalSuccess() {
        toastr.success($translate.instant('header.toasts.demoRequestWasSent'));
      }
    }

    /**
     * Открытие модалки с горячими клавишами
     */
    function openHotkeysModal() {
      $uibModal
        .open({
          component: 'cqHotkeysModalWrapper',
          resolve: {
            djangoUser: angular.bind(null, angular.identity, vm.djangoUser),
          },
          size: 'lg',
        })
        .result.catch(() => {});
    }

    /**
     * Активация обновления прошедшего времени с момента совершения события в системном логе
     */
    function startSystemLogMessagesTimeRefreshing() {
      interval = $interval(angular.bind($scope, $scope.$broadcast, 'refreshSystemLogMessagesInterval'), 30000);
    }

    /**
     * Деактивация обновления прошедшего времени с момента совершения события в системном логе
     */
    function stopSystemLogMessagesTimeRefreshing() {
      $interval.cancel(interval);
    }

    /** Колбэк на клик по кнопке «Хорошо» в поповере про автоназначение */
    function onClickAutoAssignPopoverOkButton() {
      vm.isAutoAssignPopoverOpened = false;
      vm.djangoUserTempData.autoAssignActivated = true;
      firstValueFrom(
        djangoUserModel.saveTempData(vm.djangoUser.id, { ...vm.djangoUserTempData, autoAssignActivated: true }),
      );
    }

    /** Переключение настройки автоназначения на оператора */
    function onSwitchAutoAssign() {
      if (vm.djangoUserAutoAssign) {
        firstValueFrom(djangoUserModel.enableAutoAssign(vm.djangoUser.id));
      } else {
        firstValueFrom(djangoUserModel.disableAutoAssign(vm.djangoUser.id));
      }

      const currentMembers = conversationsStoreService.teamMembers$.getValue();
      const updatedMembers = currentMembers.map((member) =>
        member.id === vm.djangoUser.id
          ? {
              ...member,
              conversationsAutoAssign: {
                ...member.conversationsAutoAssign,
                readyForAutoAssign: vm.djangoUserAutoAssign,
              },
            }
          : member,
      );
      conversationsStoreService.teamMembers$.next(updatedMembers);

      if (!vm.djangoUserTempData.autoAssignActivated) {
        vm.djangoUserTempData.autoAssignActivated = true;
        firstValueFrom(
          djangoUserModel.saveTempData(vm.djangoUser.id, { ...vm.djangoUserTempData, autoAssignActivated: true }),
        );
      }

      // Если настройка автоназначения включается, то нужно выключить настройку автоматического снятия назначения,
      //  т.к. это конкурирующие настройки
      if (
        vm.accessToConversationsReassignment.hasAccess &&
        vm.djangoUserAutoAssign &&
        vm.djangoUserConversationsUnassignment
      ) {
        vm.djangoUserConversationsUnassignment = false;
      }
    }

    /** Переключения настройки переназначения диалогов */
    function onSwitchUnassignConversations() {
      firstValueFrom(djangoUserModel.setUnassignConversationsValue(vm.djangoUserConversationsUnassignment));

      // Если настройка автоназначения включается, то нужно выключить настройку автоматического снятия назначения,
      //  т.к. это конкурирующие настройки
      if (
        vm.isConversationsAutoAssignmentEnabledInApp() &&
        vm.djangoUserConversationsUnassignment &&
        vm.djangoUserAutoAssign
      ) {
        vm.djangoUserAutoAssign = false;
      }
    }

    /**
     * Переключение статуса
     */
    function onSwitchStatus() {
      if (vm.djangoUser.prefs[vm.currentApp.id].presence === DJANGO_USER_PRESENCE_TYPES.ONLINE) {
        firstValueFrom(djangoUserModel.setOnline(vm.currentApp.id));
      } else if (vm.djangoUser.prefs[vm.currentApp.id].presence === DJANGO_USER_PRESENCE_TYPES.OFFLINE) {
        firstValueFrom(djangoUserModel.setOffline(vm.currentApp.id));
      }
    }

    /**
     * Трек клика на 'Академия'
     */
    function trackClickAcademy() {
      carrotquestHelper.track('Клик на "Академия"');
    }

    /**
     * Трек клика на 'Для Android'
     */
    function trackClickAndroidApp() {
      carrotquestHelper.track('Клик на "Для Android"');
    }

    /**
     * Трек клика на 'Документация по API'
     */
    function trackClickApiDocumentation() {
      carrotquestHelper.track('Клик на "Документация по API"');
    }

    /**
     * Трек клика на 'Кейсы'
     */
    function trackClickCase() {
      carrotquestHelper.track('Клик на "Кейсы"');
    }

    /**
     * Трек клика на кнопку открытия модалки с запросом аудита
     */
    function trackClickGetAudit() {
      carrotquestHelper.track('panel-header-demo-popup', { type: 'get_audit_from_panel' });
    }

    /**
     * Трек клика на кнопу открытия модалки с демо
     */
    function trackClickGetDemo() {
      carrotquestHelper.track('panel-header-demo-popup', { type: 'get_demo_from_panel' });
    }

    /**
     * Трек клика на 'Горячие клавиши'
     */
    function trackClickHotkeysModal() {
      carrotquestHelper.track('Клик на "Горячие клавиши"', {
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек клика на 'Для iOS'
     */
    function trackClickIosApp() {
      carrotquestHelper.track('Клик на "Для iOS"');
    }

    /**
     * Трек клика на 'База знаний'
     */
    function trackClickKnowledgeBase() {
      carrotquestHelper.track('Клик на "База знаний"');
    }

    function trackClickLearnAboutUpgradePlanPopover() {
      carrotquestHelper.track('Unlimited plan upgrade extension - button clicked');
    }

    /**
     * Трек клика на аватарку пользователя
     */
    function trackClickOnProfile() {
      carrotquestHelper.track('Навигация - кликнул по аватарке аккаунта');
    }

    /**
     * Трекинг клика на статус подписки
     */
    function trackClickOnSubscriptionStatus() {
      carrotquestHelper.track('Клик на "оплатить подписку" в шапке');
    }

    /**
     * Трек клика на системный лог
     */
    function trackClickOnSystemLog() {
      carrotquestHelper.track('Кликнул на "панель оповещений фоновых задач"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика на 'Для Windows'
     */
    function trackClickWindowsApp() {
      carrotquestHelper.track('Клик на "Для Windows"');
    }

    /**
     * Трек клик на кнопке что нового
     */
    function trackClickWhatsNew() {
      carrotquestHelper.track('Клик на "Что нового"');
    }

    /**
     * Обновление значения в LS времени нажатия на кнопку "Что нового"
     */
    function updateLastClickOnWhatsNewTime() {
      newsHelperService.markNewsAsReadForUser(vm.djangoUser);
      setTimeout(() => {
        $scope.$apply(() => {
          vm.unreadNewsCount = 0;
        });
      }, 2000);
    }
  }
})();
