import { AUDIENCE_FILTER_TYPE, MESSAGE_PAGE_TYPES } from '../../../../../app/http/message/message.constants';
import { firstValueFrom } from 'rxjs';
import { Message } from '../../../../../app/http/message/message.types';

(function () {
  'use strict';

  angular
    .module('myApp.scheduledMessages')
    .controller('cqScheduledMessageAudienceController', cqScheduledMessageAudienceController);

  function cqScheduledMessageAudienceController(
    $scope,
    FILTER_SEGMENTS_PAGE_TYPE,
    carrotquestHelper,
    filterAjsModel,
    messageModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.changeHasFiltersSwitch = changeHasFiltersSwitch;
      vm.collapseChange = collapseChange;
      vm.emptySettings = emptySettings;
      vm.FILTER_SEGMENTS_PAGE_TYPE = FILTER_SEGMENTS_PAGE_TYPE;
      vm.hasFilters = isMessageHaveFilters();
      vm.isCollapsed = true; //Состояние аккордеона
      vm.isMessageHaveFilters = isMessageHaveFilters;
      vm.message.isMessageHaveFilters = isMessageHaveFilters();
      vm.getRepeatDelayDays = getRepeatDelayDays;
      vm.messagePageType = MESSAGE_PAGE_TYPES.AUTO_MESSAGES; // Тип страницы, на которой размещен компонент, необходимо для корректного трекинга событий и переводов
      vm.messagePartTypeName = messageModel.getMessagePartTypeName(vm.messagePart);
      vm.receiverCount = null;

      vm.message.jinjaFilterTemplate = '';
      vm.shownAudienceFilterType = AUDIENCE_FILTER_TYPE.DEFAULT;

      $scope.$watch('vm.message.filters', (newValue) => {
        $scope.$applyAsync(() => {
          vm.receiverCount = null;
        });

        if (
          newValue.filters.props.length === 0 &&
          newValue.filters.events.length === 0 &&
          newValue.filters.tags.length === 0
        ) {
          return;
        }

        if (!vm.filtersForm) {
          return;
        }

        if (vm.filtersForm.$invalid) {
          return;
        }

        firstValueFrom(messageModel.getMessageReceiversCount(vm.currentApp.id, vm.messagePart.type, [], newValue)).then(
          ({ total }) => {
            $scope.$applyAsync(() => {
              vm.receiverCount = total;
            });
          },
        );
      });
    }

    /**
     * Изменение значения свича фильтров
     */
    function changeHasFiltersSwitch(value) {
      $scope.$apply(() => {
        vm.message.isMessageHaveFilters = value;
      });
    }

    function collapseChange() {
      //Нужно чтобы отработало сразу
      $scope.$apply(() => {
        vm.isCollapsed = !vm.isCollapsed;
      });
    }

    /**
     * Состояние когда все настройки выключены
     * @returns {boolean}
     */
    function emptySettings() {
      return !(
        vm.currentMessage.isMessageHaveFilters ||
        vm.isMessageHaveFilters() ||
        vm.currentMessage.isControlGroupEnabled ||
        vm.currentMessage.userStatuses
      );
    }

    /**
     * Проверка наличия фильтров в триггерных сообщениях
     *
     * @returns {boolean}
     */
    function isMessageHaveFilters() {
      return (
        vm.message.filters.filters.props.length > 0 ||
        vm.message.filters.filters.events.length > 0 ||
        vm.message.filters.filters.tags.length > 0
      );
    }

    function getRepeatDelayDays() {
      return vm.message.repeatDelay / 24 / 60 / 60;
    }
  }
})();
