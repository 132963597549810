import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { extend } from 'angular';
import { CHAT_BOT_ACTIONS_TYPES } from 'app/http/chat-bot/chat-bot.constants';
import { defaultIfEmpty, forkJoin, mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DuplicateLoggerHelper } from '@http/chat-bot/duplicate-logger.helper';
import { DefaultBotHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-bot.helper';
import { ChatBotMapper } from '@http/chat-bot/mappers/chat-bot.mapper';
import { ChatBotAction, ChatBotActionAttachment } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import {
  ApiBotWithActiveStatusResponse,
  ApiCreateResponse,
  ApiGetListResponse,
  ApiLeadBotResponse,
  ApiSaveResponse,
  CHAT_BOT_TYPE,
} from '@http/chat-bot/types/chat-bot-external.types';
import {
  ActiveChatWidget,
  BotListPagination,
  BotWithActiveStatus,
  FacebookBot,
  LeadBot,
  RoutingBot,
  TelegramBot,
  WidgetBot,
} from '@http/chat-bot/types/chat-bot-internal.types';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import { DeleteMessage, Message } from '@http/message/message.types';
import { PSEUDO_DIRECTORY_IDS, PSEUDO_DIRECTORY_TYPES } from '@http/message-directory/message-directory.constants';
import { MESSAGE_PART_TYPES, RECIPIENT_TYPES } from '@http/message-part/message-part.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { APIPaginatableResponse } from '@http/types';
import { UtilsModel } from '@http/utils/utils.model';
import { CaseStyleHelper } from '@panel/app/services';
import { ConditionsSendingModel } from '@panel/app/services/conditions-sending/conditions-sending.model';
import {
  EXTENDED_RESPONSE,
  IGNORE_ID_AS_STRING,
  IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS,
  IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS,
} from '@panel/app/shared/constants/http.constants';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { SystemErrorService } from '@panel/app-old/shared/services/system-error/system-error.service';

/**
 * Сервис для работы с чат-ботом
 */
@Injectable({ providedIn: 'root' })
export class ChatBotModel {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly http: HttpClient,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly defaultBotHelper: DefaultBotHelper,
    private readonly featureModel: FeatureModel,
    private readonly messagePartModel: MessagePartModel,
    private readonly messageModel: MessageModel,
    private readonly utilsModel: UtilsModel,
    private readonly systemError: SystemErrorService,
  ) {}

  /**
   * Создание бота
   *
   * @param appId - ID приложения
   * @param chatBot - Чат-бот
   */
  private createBot<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    appId: string,
    chatBot: BotWithActiveStatus<T>,
  ): Observable<ApiCreateResponse<BotWithActiveStatus<T>>> {
    return this.uploadAttachments(appId, chatBot.branches).pipe(
      mergeMap(() => {
        let bot = ChatBotMapper.parseBotWithActiveStatusToServerFormat<T>(chatBot);

        bot.name = chatBot.name!;
        bot.ignoreErrors = true;

        return this.http.post<any>('/chat_bots', bot);
      }),
      catchError((err) => {
        this.systemError.somethingWentWrongToast.show();
        return throwError(err);
      }),
      map((data) => {
        let responseBot: BotWithActiveStatus<T> = data.chatBot;

        return {
          messageId: data.id as string,
          chatBot: responseBot,
        };
      }),
    );
  }

  /**
   * Создание Facebook-бота
   *
   * @param appId - ID приложения
   * @param chatBot - Чат-бот
   */
  public createFacebookBot(appId: string, chatBot: FacebookBot): Observable<ApiCreateResponse<FacebookBot>> {
    return this.createBot<CHAT_BOT_TYPE.FACEBOOK>(appId, chatBot);
  }

  /**
   * Создание Telegram-бота
   *
   * @param appId - ID приложения
   * @param chatBot - Чат-бот
   */
  public createTelegramBot(appId: string, chatBot: TelegramBot): Observable<ApiCreateResponse<TelegramBot>> {
    return this.createBot<CHAT_BOT_TYPE.TELEGRAM>(appId, chatBot);
  }

  /**
   * Создание Лид-бота
   *
   * @param appId - ID приложения
   * @param chatBotMessage - Чат-бот
   * @param active - Статус активности
   * @param deleteMessage - Параметры удаления сообщений
   */
  public createLeadBot(
    appId: string,
    chatBotMessage: Message,
    active: boolean,
    deleteMessage: DeleteMessage,
  ): Observable<ApiCreateResponse<LeadBot>> {
    return this.uploadAttachments(appId, chatBotMessage.bot!.branches).pipe(
      mergeMap(() => {
        let bot = ChatBotMapper.parseToServerFormat(chatBotMessage.bot!, active);
        bot.ignoreErrors = true;
        bot.name = chatBotMessage.name!;

        return this.http.post('/chat_bots', bot);
      }),
      catchError((err) => {
        this.systemError.somethingWentWrongToast.show();
        return throwError(err);
      }),
      mergeMap((data: any) => {
        let responseBot: ApiLeadBotResponse = data.chatBot;

        let body = this.messageModel.parseMessageToServerFormat(
          appId,
          chatBotMessage,
          responseBot.id,
          active,
          deleteMessage,
        );
        body.ignoreErrors = true;

        return this.http.post('/messages', body).pipe(
          catchError((error) => {
            return throwError({
              chatBot: ChatBotMapper.parseToInternalFormat(responseBot),
              mapIds: data.newIds,
              error: this.caseStyleHelper.keysToCamelCase(error),
            });
          }),
          map((data: any) => {
            return {
              messageId: data.id as string,
              chatBot: responseBot as unknown as LeadBot,
            };
          }),
        );
      }),
    );
  }

  /**
   * Создание бота
   *
   * @param appId - ID приложения
   * @param chatBot - Чат-бот
   */
  public createRoutingBot(appId: string, chatBot: RoutingBot): Observable<ApiCreateResponse<RoutingBot>> {
    return this.createBot<CHAT_BOT_TYPE.ROUTING>(appId, chatBot);
  }

  /**
   * Создание Widget-бота
   *
   * @param appId - ID приложения
   * @param chatBot - Чат-бот
   */
  public createWidgetBot(appId: string, chatBot: WidgetBot): Observable<ApiCreateResponse<WidgetBot>> {
    return this.createBot<CHAT_BOT_TYPE.WIDGET>(appId, chatBot);
  }

  public getActiveChatBotsAmounts(appId: string) {
    return forkJoin([this.getList(appId, MESSAGE_STATUSES.ACTIVE), this.getRoutingBot(), this.getFacebookBot()]).pipe(
      map(([leadBots, routingBot, facebookBot]) => {
        /**
         Вообще не нравится, что запрос по итогу возвращается разные сущности, нужно переделать
         чтобы возвращал сразу нужный счетчик
         */
        const chatBots = [...leadBots.chatBots, routingBot, facebookBot].filter((item) => isDefined(item));

        let activeChatBotIds: string[] = [];
        let activeChatBotsAmount = 0;

        chatBots.forEach((item) => {
          if (item?.active && item.id) {
            activeChatBotsAmount += 1;
            activeChatBotIds.push(item.id);
          }
        });

        return {
          amount: activeChatBotsAmount,
          ids: activeChatBotIds,
        };
      }),
    );
  }

  /**
   * Получение списка активных embedded-ботов
   *
   * @param appId ID приложения
   */
  getActiveWidgetBotList(appId: string): Observable<ActiveChatWidget> {
    return this.http.get<ActiveChatWidget>(`/apps/${appId}/chat_widgets`);
  }

  /**
   * Получение бота
   *
   * из списка ботов т.к. некоторые боты существую в единственном экземпляре
   */
  private getBot<T extends Exclude<CHAT_BOT_TYPE, 'lead' | 'telegram' | 'widget'>>(
    type: T,
    additionalParams: object = {},
  ): Observable<BotWithActiveStatus<T> | null> {
    return this.getBotList(type, additionalParams).pipe(
      map((data) => {
        return data.bots.length !== 0 ? data.bots[0] : null;
      }),
    );
  }

  /**
   * Получение списка ботов со статусом активности
   */
  private getBotList<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    type: T,
    additionalParams: object = {},
  ): Observable<BotListPagination<T>> {
    const params = {
      ...{
        type,
        include_branches__actions__attachments: true,
        ignore_errors: true,
        paginate_direction: 'before',
        paginate_count: 20,
        paginate_including: false,
        paginate_page_order: 'desc',
        paginate_position: null!,
        id_as_string: true,
      },
      ...additionalParams,
    };

    return this.http
      .get<APIPaginatableResponse<ApiBotWithActiveStatusResponse<T>[]>>(`/chat_bots`, {
        params,
        context: new HttpContext()
          .set(EXTENDED_RESPONSE, true)
          .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
          .set(IGNORE_ID_AS_STRING, true),
      })
      .pipe(
        map((response) => {
          const bots = response.data.map((bot) => ChatBotMapper.parseBotWithActiveStatusToInternalFormat<T>(bot));

          return {
            bots,
            paginatePosition: response.meta.nextBeforePosition as any,
          };
        }),
      );
  }

  /**
   * Получение Facebook бота
   */
  public getFacebookBot(): Observable<FacebookBot | null> {
    if (!this.featureModel.hasAccess(FEATURES.FACEBOOK_BOT)) {
      return of(null);
    }

    return this.getBot<CHAT_BOT_TYPE.FACEBOOK>(CHAT_BOT_TYPE.FACEBOOK).pipe(
      catchError((err) => {
        if (err === 'FacebookBotIsNotCreated') {
          return of(null);
        }
        return throwError(err);
      }),
    );
  }

  /**
   * Получение Telegram бота
   */
  public getTelegramBot(id: string): Observable<TelegramBot | null> {
    let params = {
      include_branches__actions__attachments: true,
      include_stats: false,
      include_trigger_types: true,
      id_as_string: true,
    };

    return this.http
      .get<ApiBotWithActiveStatusResponse<CHAT_BOT_TYPE.TELEGRAM>>(`/chat_bots/${id}`, {
        params,
        context: new HttpContext()
          .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
          .set(IGNORE_ID_AS_STRING, true),
      })
      .pipe(
        map((data) => {
          return ChatBotMapper.parseBotWithActiveStatusToInternalFormat(data);
        }),
      );
  }

  /**
   * Получение списка Telegram ботов
   */
  public getTelegramBotList(
    includeStats: boolean = false,
    status: MESSAGE_STATUSES = MESSAGE_STATUSES.ALL,
    telegramIntegrationId?: string,
  ): Observable<TelegramBot[]> {
    const params = {
      include_trigger_types: true,
      include_stats: includeStats,
      active: this.getParsedActiveStatus(status),
      integration: telegramIntegrationId,
    };

    return this.getBotList<CHAT_BOT_TYPE.TELEGRAM>(CHAT_BOT_TYPE.TELEGRAM, params).pipe(
      map((resp) => resp.bots),
      catchError(() => {
        return of([]);
      }),
    );
  }

  /**
   * Получение Лид-бота
   *
   * @param appId - ID аппа
   * @param chatBotMessageId - ID чатбота
   */
  public getLeadBot(appId: string, chatBotMessageId: string): Observable<Message> {
    return this.messageModel.getChatBotMessage(chatBotMessageId, false, true, false, true).pipe(
      mergeMap((message: any) => {
        const params = {
          include_branches__actions__attachments: true,
          id_as_string: true,
        };

        return this.http
          .get<ApiLeadBotResponse>(`/chat_bots/${message.parts[0].body}`, {
            params,
            context: new HttpContext()
              .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
              .set(IGNORE_ID_AS_STRING, true),
          })
          .pipe(
            map((response: ApiLeadBotResponse) => {
              message.bot = ChatBotMapper.parseToInternalFormat(response);
              return message;
            }),
          );
      }),
    );
  }

  /**
   * Получение роутинг бота
   */
  public getRoutingBot(includeStats: boolean = false): Observable<RoutingBot | null> {
    let params = {
      include_stats: includeStats,
    };

    return this.getBot<CHAT_BOT_TYPE.ROUTING>(CHAT_BOT_TYPE.ROUTING, params).pipe(
      catchError((err) => {
        if (err === 'RoutingBotIsNotCreated') {
          return of(null);
        }
        return throwError(err);
      }),
    );
  }

  /**
   * Получение роутинг бота
   */
  public getWidgetBot(id: string, includeStats: boolean = false): Observable<WidgetBot | null> {
    let params = {
      include_branches__actions__attachments: true,
      include_stats: includeStats,
      id_as_string: true,
    };

    return this.http
      .get<ApiBotWithActiveStatusResponse<CHAT_BOT_TYPE.WIDGET>>(`/chat_bots/${id}`, {
        params,
        context: new HttpContext()
          .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
          .set(IGNORE_ID_AS_STRING, true),
      })
      .pipe(map((data) => ChatBotMapper.parseBotWithActiveStatusToInternalFormat(data)));
  }

  public getWidgetBotList(
    includeStats: boolean = false,
    status: MESSAGE_STATUSES = MESSAGE_STATUSES.ALL,
    paginatePosition: string[] | null = null,
  ): Observable<BotListPagination<CHAT_BOT_TYPE.WIDGET>> {
    const params = {
      include_stats: includeStats,
      active: this.getParsedActiveStatus(status),
      include_branches__actions__attachments: false,
      paginate_position: paginatePosition?.join() ?? null,
    };

    return this.getBotList<CHAT_BOT_TYPE.WIDGET>(CHAT_BOT_TYPE.WIDGET, params);
  }

  /**
   * Сохранение бота
   * @param appId
   * @param bot
   * @param deletedBranches
   */
  public saveBot<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    appId: string,
    bot: BotWithActiveStatus<T>,
    deletedBranches: string[] = [],
  ) {
    return this.uploadAttachments(appId, bot.branches).pipe(
      mergeMap(() => {
        const botParams = ChatBotMapper.parseBotWithActiveStatusToServerFormat(bot);

        botParams.deleted_branches = deletedBranches;
        botParams.name = bot.name!;
        !botParams.branches?.length && delete botParams.branches;
        botParams.include_branches__actions__attachments = true;
        botParams.ignoreErrors = true;

        if (DuplicateLoggerHelper.hasDuplicates(botParams)) {
          DuplicateLoggerHelper.sendLogs(this.utilsModel, botParams);
        }

        return this.http.patch<any>(`/chat_bots/${bot.id}`, botParams, {
          params: {
            id_as_string: true,
          },
          context: new HttpContext()
            .set(EXTENDED_RESPONSE, true)
            .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
            .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
            .set(IGNORE_ID_AS_STRING, true),
        });
      }),
      catchError((err) => {
        this.systemError.somethingWentWrongToast.show();
        return throwError(err);
      }),
      map((response) => {
        //NOTE надо сохранить new_ids до keysToCamelCase т.к. он может изменить ключи этого обьекта
        // а ключи(linkId) это сгерерированные ID библиотеки и он может их поменять
        let newIds = { ...response.data.new_ids };
        this.caseStyleHelper.keysToCamelCase(response);
        let responseBot: ApiBotWithActiveStatusResponse<T> = response.data.chatBot;

        return {
          chatBot: ChatBotMapper.parseBotWithActiveStatusToInternalFormat(responseBot),
          mapIds: newIds,
        };
      }),
    );
  }

  /**
   * Сохранение Facebook-бота
   * @param appId - ID приложения
   * @param botChangedBranches - Бот с изменнными ветками
   * @param deletedBranches - Список ID удаленных веток
   */
  public saveFacebookBot(appId: string, botChangedBranches: FacebookBot, deletedBranches: string[] = []) {
    return this.saveBot<CHAT_BOT_TYPE.FACEBOOK>(appId, botChangedBranches, deletedBranches);
  }

  /**
   * Сохранение Telegram-бота
   * @param appId - ID приложения
   * @param botChangedBranches - Бот с изменнными ветками
   * @param deletedBranches - Список ID удаленных веток
   */
  public saveTelegramBot(appId: string, botChangedBranches: TelegramBot, deletedBranches: string[] = []) {
    return this.saveBot<CHAT_BOT_TYPE.TELEGRAM>(appId, botChangedBranches, deletedBranches);
  }

  /**
   * Сохранение Widget-бота
   * @param appId - ID приложения
   * @param botChangedBranches - Бот с изменнными ветками
   * @param deletedBranches - Список ID удаленных веток
   */
  public saveWidgetBot(appId: string, botChangedBranches: WidgetBot, deletedBranches: string[] = []) {
    return this.saveBot<CHAT_BOT_TYPE.WIDGET>(appId, botChangedBranches, deletedBranches);
  }

  /**
   * Сохранение порядка чат-виджетов
   *
   * @param appId ID приложения
   * @param idsArray Массив ID чат-виджетов
   */
  public saveChatWidgetListOrder(appId: string, idsArray: string[]): Observable<ActiveChatWidget[]> {
    const body = {
      chat_widgets_order: idsArray,
    };
    return this.http.post<ActiveChatWidget[]>(`/apps/${appId}/sort_chat_widgets`, body);
  }

  /**
   * Получение бота при его создании
   */
  public getInitialBotMessage(recipientType: RECIPIENT_TYPES = RECIPIENT_TYPES.WEB): Message {
    const bot = this.defaultBotHelper.getInitialBot(CHAT_BOT_TYPE.LEAD);

    let defaultBotMessage: Message = extend(
      {
        name: this.transloco.translate(`models.chatBot.defaultBotName.${recipientType}`),
        bot: bot,
        parts: [],
      },
      ConditionsSendingModel.getDefault(),
    );

    defaultBotMessage.isMessageHaveFilters = false;

    let controlGroup = this.messagePartModel.getDefaultForBot();
    controlGroup.type = MESSAGE_PART_TYPES.CONTROL_GROUP;
    this.messagePartModel.generatePartNames([controlGroup]);
    //@ts-ignore
    defaultBotMessage.controlGroup = controlGroup;
    // первоначальный процент контрольной группы - 10%, если пользователь её не активирует - при отправке этот процент анулируется

    if (defaultBotMessage.controlGroup) {
      defaultBotMessage.controlGroup.proportion = 0.1;
    }

    let newMessagePart = this.messagePartModel.getDefaultForBot();
    newMessagePart.recipientType = recipientType;
    defaultBotMessage.parts?.push(newMessagePart);
    this.messagePartModel.generatePartNames(defaultBotMessage.parts!);

    return defaultBotMessage;
  }

  static isConnectionSourceAction(actionType: ChatBotAction['type']): boolean {
    return [
      CHAT_BOT_ACTIONS_TYPES.BUTTON,
      CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
      CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION,
      CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION,
      CHAT_BOT_ACTIONS_TYPES.NEXT,
      CHAT_BOT_ACTIONS_TYPES.MEET,
    ].includes(actionType);
  }

  static isActionAllowedForStartBranch(actionType: ChatBotAction['type']): boolean {
    return ![
      CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION,
      CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION,
      CHAT_BOT_ACTIONS_TYPES.USER_TAG,
      CHAT_BOT_ACTIONS_TYPES.EVENT,
      CHAT_BOT_ACTIONS_TYPES.PROPERTY,
    ].includes(actionType);
  }

  static isActionAllowedForInterruptScenario(actionType: ChatBotAction['type']): boolean {
    return ![
      CHAT_BOT_ACTIONS_TYPES.BUTTON,
      CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY,
      CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
      CHAT_BOT_ACTIONS_TYPES.CLOSE,
    ].includes(actionType);
  }

  /**
   * Получения списка чат ботов для приложения
   *
   * @param appId ID приложения
   * @param status Статус получаемых сообщений
   * @param paginatorParams Параметры пагинации (для серверной пагинации)
   */
  //TODO ANGULAR_TS добавить тип MESSAGE_STATUSES для свойства status
  // Для этого надо message.constants.js переписать на TS
  public getList(appId: string, status: MESSAGE_STATUSES, paginatorParams?: any): Observable<ApiGetListResponse> {
    return this.messageModel
      .getChatBotMessages(
        MESSAGE_PART_TYPES.LEAD_BOT,
        status,
        paginatorParams,
        PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
        false,
      )
      .pipe(
        map((data: any) => {
          return {
            chatBots: data.messages,
            paginatorParams: data.paginatorParams,
          };
        }),
      );
  }

  /**
   * Возвращает параметр необходимый для запроса исходя из статуса
   *
   * @param status Статус сообщения
   */
  private getParsedActiveStatus(status: MESSAGE_STATUSES): boolean | undefined {
    switch (status) {
      case MESSAGE_STATUSES.ALL:
        return undefined;
      case MESSAGE_STATUSES.ACTIVE:
        return true;
      case MESSAGE_STATUSES.STOPPED:
        return false;
    }
  }

  /**
   * Сохранение Роутинг-бота
   *
   * @param appId - ID приложения
   * @param botChangedBranches - Бот с изменнными ветками
   * @param deletedBranches - Список ID удаленных веток
   */
  public saveRoutingBot(appId: string, botChangedBranches: RoutingBot, deletedBranches: string[] = []) {
    return this.saveBot<CHAT_BOT_TYPE.ROUTING>(appId, botChangedBranches, deletedBranches);
  }

  /**
   * Сохранение чат бота
   *
   * @param appId - ID приложения
   * @param chatBotMessage - Чат-бот
   * @param botChangedBranches - Бот с изменнными ветками
   * @param active - Статус активности
   * @param deleteMessage - Параметры удаления сообщений
   * @param deletedBranches - Список ID удаленных веток
   */
  public saveLeadBot(
    appId: string,
    chatBotMessage: Message,
    botChangedBranches: LeadBot,
    active: boolean,
    deleteMessage: DeleteMessage,
    deletedBranches: string[] = [],
  ): Observable<ApiSaveResponse<CHAT_BOT_TYPE.LEAD>> {
    return this.uploadAttachments(appId, botChangedBranches.branches).pipe(
      mergeMap(() => {
        const botParams = ChatBotMapper.parseToServerFormat<CHAT_BOT_TYPE.LEAD>(botChangedBranches, active);

        botParams.deleted_branches = deletedBranches;
        botParams.name = chatBotMessage.name!;
        !botParams.branches?.length && delete botParams.branches;
        botParams.include_branches__actions__attachments = true;
        botParams.ignoreErrors = true;

        if (DuplicateLoggerHelper.hasDuplicates(botParams)) {
          DuplicateLoggerHelper.sendLogs(this.utilsModel, botParams);
        }

        return this.http.patch<any>(`/chat_bots/${botChangedBranches.id}`, botParams, {
          params: {
            id_as_string: true,
          },
          context: new HttpContext()
            .set(EXTENDED_RESPONSE, true)
            .set(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS, true)
            .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, ['new_ids'])
            .set(IGNORE_ID_AS_STRING, true),
        });
      }),
      mergeMap((response) => {
        //NOTE надо сохранить new_ids до keysToCamelCase т.к. он может изменить ключи этого обьекта
        // а ключи(linkId) это сгерерированные ID библиотеки и он может их поменять
        let responseBot: ApiLeadBotResponse = response.data.chatBot;
        let params = this.messageModel.parseMessageToServerFormat(
          appId,
          chatBotMessage,
          response.data.chatBot.id,
          active,
          deleteMessage,
        );
        params.ignoreErrors = true;

        return this.http.put('/messages/' + chatBotMessage.id, params).pipe(
          catchError((err) => {
            return throwError(() =>
              JSON.stringify({
                chatBot: ChatBotMapper.parseToInternalFormat(responseBot),
                mapIds: response.data.newIds,
                error: err,
              }),
            );
          }),
          map(() => {
            return {
              chatBot: ChatBotMapper.parseToInternalFormat(responseBot),
              mapIds: response.data.newIds,
            };
          }),
        );
      }),
    );
  }

  /**
   * Загрузка прикреплений
   *
   * @param appId - ID приложения
   * @param branches - Ветки бота
   */
  public uploadAttachments(appId: string, branches: ChatBotBranch[]): Observable<ChatBotActionAttachment[]> {
    let uploadAttachmentRequests: Observable<ChatBotActionAttachment>[] = [];

    for (let i = 0; i < branches.length; i++) {
      for (let g = 0; g < branches[i].actions.length; g++) {
        // Загружать прикрепления надо если это
        // действие типа FILE или VIDEO_NOTE
        // и существует прикрепление без ID, т.е. оно не загружалось на сервер
        if (
          [CHAT_BOT_ACTIONS_TYPES.FILE, CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE].includes(branches[i].actions[g].type) &&
          branches[i].actions[g].attachments[0] &&
          !branches[i].actions[g].attachments[0].id
        ) {
          uploadAttachmentRequests.push(
            this.uploadAttachment(appId, branches[i].actions[g].attachments[0]).pipe(
              tap((attachment) => {
                branches[i].actions[g].attachments = [attachment];
                branches[i].actions[g].attachments[0].name = attachment.filename;
              }),
            ),
          );
        }
      }
    }

    return forkJoin(uploadAttachmentRequests).pipe(defaultIfEmpty([]));
  }

  /**
   * Загрузка прикрепления
   *
   * @param appId - ID приложения
   * @param attachment - Прикрепление
   */
  private uploadAttachment(appId: string, attachment: ChatBotActionAttachment): Observable<ChatBotActionAttachment> {
    let params = {
      attachment: attachment,
      attachment_file_name: attachment.name,
    };

    return this.http.post<ChatBotActionAttachment>('/chat_bots/attachments/', params);
  }
}
