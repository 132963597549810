import { firstValueFrom } from 'rxjs';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular.module('myApp.users').config(config).run(run);

  /**
   * Конфигурация роутов лидов
   *
   * @param $stateProvider
   */
  function config($stateProvider) {
    $stateProvider
      .state('app.content.users', {
        url: '/users',
        redirectTo: 'app.content.users.table',
        template: '<div ui-view></div>',
      })

      .state('app.content.users.table', {
        url: '',
        resolve: {
          activeUsers: getActiveUsers,
          activeUsersCount: getActiveUsersCount,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          pageType: getPageType,
          allowDeletingTags: () => true,
          telegramIntegrations: getTelegramIntegrations,
          emailDomains: getEmailDomains,
        },
        // FIXME: поскольку сейчас всё делается на компонентах - смысла в таких сложных и непонятных роутах нету, поэтому их нужно выпилить после перевода всего на компоненты
        views: {
          '': {
            templateUrl: 'js/components/users/users.html',
            controller: 'UsersController',
            controllerAs: 'vm',
          },
          'filterSegments@.': {
            bindings: {
              currentApp: 'currentApp',
              props: 'properties',
              segments: 'segments',
              tags: 'tags',
              pageType: 'pageType',
              telegramIntegrations: 'telegramIntegrations',
            },
            component: 'cqFilterSegmentsVertical',
          },
        },
      })

      .state('app.content.users.detail', {
        url: '/{userId}?{by_user_id}',
        resolve: {
          onRedirectConversationClick: getOnRedirectConversationClick,
          telegramIntegrations: getTelegramIntegrations,
          emailDomains: getEmailDomains,
        },
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          onRedirectConversationClick: 'onRedirectConversationClick',
          telegramIntegrations: 'telegramIntegrations',
          emailDomains: 'emailDomains',
        },
        component: 'cqUserCardWrapper',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.OPERATOR],
        },
      })

      .state('app.content.users.import', {
        url: '/import',
        bindings: {
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          properties: 'properties',
          tags: 'tags',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqLeadsImport',
        resolve: {
          userProps: getUserProps,
          tags: getNotRemovedTags,
          telegramIntegrations: getTelegramIntegrations,
        },
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      });

    /**
     * Получение онлайн пользователей
     *
     * @param userModel
     * @param currentApp
     * @param planFeatureAccessService
     * @returns {Promise}
     */
    function getActiveUsers(userModel, currentApp, planFeatureAccessService) {
      if (planFeatureAccessService.getAccess(PLAN_FEATURE.LIVE, currentApp).hasAccess) {
        return firstValueFrom(userModel.getActiveUsers(currentApp.id)).then(getActiveUsersSuccess);
      } else {
        return new Promise((resolve) => resolve({}));
      }

      function getActiveUsersSuccess(response) {
        return response.users;
      }
    }

    /**
     * Получение списка ТГ интеграций
     * @param integrationModel
     * @param currentApp
     * @return {Promise<*[]>}
     */
    function getTelegramIntegrations(integrationModel, currentApp) {
      return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
        return [...response[0]].filter((integration) => {
          return integration.active === true;
        });
      });
    }

    /**
     * Получение списка емейл доменов
     * @param currentApp
     * @param emailDomainModel
     * @return {Promise<*[]>}
     */
    function getEmailDomains(currentApp, emailDomainModel) {
      return firstValueFrom(emailDomainModel.getList(currentApp.id));
    }

    /**
     * Получение количества онлайн пользователей
     *
     * @param $q
     * @param currentApp
     * @param planFeatureAccessService
     * @param userModel
     * @returns {Promise}
     */
    function getActiveUsersCount($q, currentApp, planFeatureAccessService, userModel) {
      if (planFeatureAccessService.getAccess(PLAN_FEATURE.LIVE, currentApp).hasAccess) {
        return firstValueFrom(userModel.getActiveUsersCount(currentApp.id));
      } else {
        return $q.resolve();
      }
    }

    /**
     * Получение всех (удаленных и не удаленных) тегов
     *
     * @param tagModel
     * @param currentApp
     * @returns {*}
     */
    function getAllUserTags(tagModel, currentApp) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение не удаленных тегов
     *
     * @param tagModel
     * @param currentApp
     * @returns {*}
     */
    function getNotRemovedTags(tagModel, currentApp) {
      return firstValueFrom(tagModel.getList(currentApp.id, false));
    }

    /**
     * Устанавливаем флаг о редиректе,
     * после нажатия на кнопку "Перейти в диалог" нужно перенаправить в раздел диалоги
     *
     * @returns {boolean}
     */
    function getOnRedirectConversationClick() {
      return true;
    }

    /**
     * Получение свойств
     *
     * @param propertyModel
     * @param currentApp
     * @returns {*}
     */
    function getProperties(propertyModel, currentApp) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение страницы на которой будет отображаться фильтр
     *
     * @param FILTER_SEGMENTS_PAGE_TYPE
     * @returns {string}
     */
    function getPageType(FILTER_SEGMENTS_PAGE_TYPE) {
      return FILTER_SEGMENTS_PAGE_TYPE.LEAD_DATA_BASE;
    }

    /**
     * Получение сегментов
     *
     * @param $transition$
     * @param currentApp
     * @param tags
     * @param properties
     * @returns {*}
     */
    function getSegments($transition$, currentApp, tags, properties) {
      let filterAjsModel = $transition$.injector().get('filterAjsModel');
      let segmentModel = $transition$.injector().get('segmentModel');

      return firstValueFrom(segmentModel.getList(currentApp.id, true)).then(getSegmentsSuccess);

      function getSegmentsSuccess(segments) {
        for (var i = 0; i < segments.length; i++) {
          filterAjsModel.linkWithPropsAndTags(segments[i].filters, properties, tags);
        }
        return segments;
      }
    }

    /**
     * Получение свойств пользователя
     *
     * @param propertyModel
     * @param currentApp
     * @returns {*}
     */
    function getUserProps(propertyModel, currentApp) {
      return firstValueFrom(propertyModel.getList(currentApp.id)).then(getUserPropsSuccess);

      function getUserPropsSuccess(properties) {
        return properties.userProps;
      }
    }
  }

  function run($state, $transitions) {
    $transitions.onEnter({ to: 'app.content.users.table' }, onEnterToUsers);
    $transitions.onEnter({ to: 'app.content.users.detail' }, onEnterToUserCard);

    /**
     * Выполняется при заходе в раздел База лидов
     *
     * @param transition
     * @param state
     * @returns {*}
     */
    function onEnterToUsers(transition, state) {
      const currentApp = transition.injector().get('currentApp');
      const djangoUser = transition.injector().get('djangoUser');
      const djangoUserModel = transition.injector().get('djangoUserModel');

      /**
       * У операторов нет доступа к базе лидов. Перенаправляем их в Live
       */
      if (djangoUserModel.isOperator(currentApp.id, djangoUser)) {
        return $state.target('app.content.live', transition.params());
      }
    }

    /**
     * Выполняется при заходе на странциу с карточкой пользователя
     *
     * @param transition
     * @returns {*}
     */
    function onEnterToUserCard(transition) {
      const byUserIdParam = transition.params().by_user_id === 'true';

      // Если в URL указан данный параметр, то пользователя нужно искать по полю User ID, а не по ID в нашей системе
      if (byUserIdParam) {
        const userModel = transition.injector().get('userModel');
        const userIdParam = transition.params().userId;

        return firstValueFrom(userModel.get(userIdParam, { by_user_id: true })).then((user) => {
          const newStateParams = angular.extend({}, transition.params(), { userId: user.id });
          delete newStateParams.by_user_id; // Удаляем параметр by_user_id

          // После получения пользователя перенаправляем на это же состояние, но уже с ID пользователя из нашей системы
          return $state.target('app.content.users.detail', newStateParams);
        });
      }
    }
  }
})();
