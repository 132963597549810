<ng-container *transloco="let t; read: 'aiContentMakerCompanyInfoComponent'">
  <label for="companyName">
    <h3>{{ t('label') }}</h3>
  </label>
  <textarea
    class="form-control no-resize custom-scroll"
    id="companyName"
    rows="4"
    [formControl]="control"
    [placeholder]="t('placeholder')"
  ></textarea>
</ng-container>
