import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, finalize, startWith, takeUntil } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { AiContentMakerIntegrationExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { MessagePartModel } from '@http/message-part/message-part.model';
import {
  AiContentMakerBodyJsonConstants,
  AiContentMakerBodyJsonVariables,
  MessageEmailAiContentMakerBodyJson,
} from '@http/message-part/message-part.types';
import { UserProperty } from '@http/property/property.model';
import { MIN_LENGTH_MESSAGE_CONTEXT } from '@panel/app/pages/trigger-messages/email-part-editor/ai-content-maker/ai-content-maker.constants';
import { AiContentMakerBodyJsonForm } from '@panel/app/pages/trigger-messages/email-part-editor/ai-content-maker/ai-content-maker.types';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-ai-content-maker',
  templateUrl: './ai-content-maker.component.html',
  styleUrls: ['./ai-content-maker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, formSubmitTokenProviders, MessageEditorTriggerState],
})
export class AiContentMakerComponent implements OnInit {
  @Input({ required: true })
  aiContentMakerIntegrations: AiContentMakerIntegrationExternal[] = [];

  @Input()
  set userProps(userProps: UserProperty[]) {
    this.messageEditorTriggerState.userProps$.next(userProps);
  }

  _bodyJson!: MessageEmailAiContentMakerBodyJson;
  @Input({ required: true })
  set bodyJson(bodyJson: MessageEmailAiContentMakerBodyJson) {
    // HACK this.bodyJsonChange.emit(value) триггерит этот инпут
    // этим условием я ограничил пересбор формы ТОЛЬКО на реальный инпут
    if (this._bodyJson === bodyJson) {
      return;
    }
    this._bodyJson = bodyJson;
    this.form.setValue(this._bodyJson, { emitEvent: false });
  }

  get bodyJson(): MessageEmailAiContentMakerBodyJson {
    return this._bodyJson;
  }

  @Input({ required: true })
  body!: string;

  readonly form = this.fb.group<AiContentMakerBodyJsonForm>({
    aiContentMakerIntegration: this.fb.control<string | null>(null, {
      validators: [Validators.required],
    }),
    testLead: this.fb.control<string>('', { nonNullable: true }),
    messageContext: this.fb.control<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(MIN_LENGTH_MESSAGE_CONTEXT)],
    }),
    variables: this.fb.control<AiContentMakerBodyJsonVariables[]>([], {
      nonNullable: true,
      validators: [this.validateArray],
    }),
    constants: this.fb.control<AiContentMakerBodyJsonConstants[]>([], {
      nonNullable: true,
      validators: [this.validateArray],
    }),
  });

  @Output()
  bodyJsonChange: EventEmitter<MessageEmailAiContentMakerBodyJson> = new EventEmitter<MessageEmailAiContentMakerBodyJson>();

  @Output()
  bodyChange = new EventEmitter();

  @Output()
  submitValidation: EventEmitter<() => void> = new EventEmitter<() => void>();

  /** Этот Output нужен чтобы засинхронить валидации Ajs с валидностью формы этого компонента */
  @Output()
  validationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isGenerateDisabled: boolean = false;

  get aiContentMakerIntegrationId(): string | null {
    return this.form.controls.aiContentMakerIntegration.getRawValue()
      ? String(this.form.controls.aiContentMakerIntegration.getRawValue())
      : null;
  }

  constructor(
    private readonly appService: AppService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    private readonly fb: FormBuilder,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
    private readonly messagePartModel: MessagePartModel,
    public readonly messageEditorTriggerState: MessageEditorTriggerState,
  ) {}

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formData) => {
      //@ts-ignore
      this._bodyJson = formData;
      //@ts-ignore
      this.bodyJsonChange.emit(formData);
    });

    this.form.statusChanges
      .pipe(startWith(this.form.status), takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => {
        this.validationChange.emit(this.form.valid);
      });

    this.submitValidation.emit(() => {
      this.formSubmitSubject.next();
      this.integrationValidationCallback();
    });
  }

  get currentApp() {
    return this.appService.app;
  }

  generateContent() {
    const isValid = this.form.valid && this.form.controls.testLead.getRawValue();
    if (!isValid) {
      this.formSubmitSubject.next();
      this.integrationValidationCallback();
      if (!this.form.controls.testLead.getRawValue()) {
        this.form.controls.testLead.markAsTouched();
        this.form.controls.testLead.setErrors({ required: true });
      }
      return;
    }

    const bodyJson = this.form.getRawValue();
    this.isGenerateDisabled = true;
    this.messagePartModel
      .generateContent(bodyJson)
      .pipe(
        finalize(() => {
          this.isGenerateDisabled = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe((generatedContent) => {
        this.bodyChange.emit(generatedContent);
      });
  }

  onIntegrationIdChange(id: string) {
    const integration = this.aiContentMakerIntegrations.find((integration) => integration.id === id);
    this.form.controls.aiContentMakerIntegration.setValue(integration?.id || null);
  }

  setValidationCallback(cb: ValidationCallback) {
    this.integrationValidationCallback = cb;
  }

  integrationValidationCallback() {}

  validateArray(): ValidatorFn {
    return (formControl): ValidationErrors | null => {
      if (formControl.value.length === 0) {
        return null;
      }

      return { invalidArray: true };
    };
  }
}
