import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '../../../app/http/team-member/team-member.constants';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.messagePreview').controller('CqMessagePreviewController', CqMessagePreviewController);

  function CqMessagePreviewController(
    $filter,
    $scope,
    $templateRequest,
    $timeout,
    $translate,
    moment,
    LANDING_URL,
    PROJECT_NAME,
    USER_FILES_URL,
    appModel,
    caseStyleHelper,
    l10nHelper,
  ) {
    var vm = this;
    var parametersUpdatedTimer; // таймер отсчитывающий период с момента последнего обновления данных
    var unbindWatchOnLocale;
    var unbindWatchOnContent;
    var unbindWatchOnParameters;
    var localeClass = l10nHelper.isUsCountry() ? 'us' : 'ru';

    /**
     * Перечень анимаций виджета чата
     */
    var WIDGET_ANIMATIONS = [
      'horizontal_funnel',
      'smile',
      'vertical_funnel',
      'winking_smile',
      'dashly_dash',
      'dashly_dance',
      'dashly_bounce',
    ];

    vm.$onInit = init;

    function init() {
      vm.caseStyleHelper = caseStyleHelper;
      vm.getContent = getContent;
      vm.getTemplateUrl = getTemplateUrl;
      vm.iframeContent = {};
      vm.l10nHelper = l10nHelper;
      vm.locale = l10nHelper.getFourLetterLang(vm.language); // для использования tmhDynamicLocale внутри dynamic-iframe нужно использовать четырёхсимвольный код языка
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.tgCachedPreview = {}; // кеш для загруженных превью, чтобы не генерить изображения после каждого изменения
    }

    /**
     * Приминение необходимых фильтров к контенту варианта сообщения в зависимости от его типа
     *
     * @param {MESSAGE_PART_TYPES} messagePartType Тип сообщения
     * @param {String} messageContent Контент сообщения
     * @returns {String} Отфильтрованный контент сообщения
     */
    function filterMessagePartContent(messagePartType, messageContent) {
      var filteredMessageContent;

      // в чате не может быть html-тегов
      if (~[MESSAGE_PART_TYPES.POPUP_CHAT, MESSAGE_PART_TYPES.SDK_POPUP_CHAT].indexOf(messagePartType)) {
        filteredMessageContent = messageContent;
        filteredMessageContent = $filter('removeJinja')(filteredMessageContent);
      }

      // к кастомным email'ам, которые создаёт пользователь принудительно добавляем doctype для корректной работы JS
      if (
        ~[MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML, MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE].indexOf(
          messagePartType,
        )
      ) {
        filteredMessageContent = messageContent || '<br><br><br><br>';
        filteredMessageContent = $filter('removeJinja')(filteredMessageContent);
        filteredMessageContent = $filter('addDoctype')(filteredMessageContent);
      }

      // везде, где можно вставить html, нужно принудительно добавить всем ссылкам target="_blank", чтобы их нельзя было открыть внутри iframe
      if (
        ~[
          MESSAGE_PART_TYPES.POPUP_SMALL,
          MESSAGE_PART_TYPES.POPUP_BIG,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.DEFAULT,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.AI,
        ].indexOf(messagePartType)
      ) {
        filteredMessageContent = messageContent || '<br><br><br><br>';
        filteredMessageContent = $filter('removeJinja')(filteredMessageContent);
        filteredMessageContent = $filter('addTargetBlank')(filteredMessageContent);
      }

      // веб-пуши только поддерживают jinja
      if (~[MESSAGE_PART_TYPES.PUSH, MESSAGE_PART_TYPES.SDK_PUSH].indexOf(messagePartType)) {
        filteredMessageContent = messageContent;
        filteredMessageContent = $filter('removeJinja')(filteredMessageContent);
      }

      return filteredMessageContent;
    }

    /**
     * Получение контента сообщения варианта по его типу
     *
     * @param {MESSAGE_PART_TYPES} messagePartType Тип сообщения
     */
    function getContent(messagePartType) {
      // принудительная чистка watch, чтобы они не плодились
      if (unbindWatchOnLocale) {
        unbindWatchOnLocale();
      }

      if (unbindWatchOnContent) {
        unbindWatchOnContent();
      }

      if (unbindWatchOnParameters) {
        unbindWatchOnParameters();
      }

      switch (messagePartType) {
        case MESSAGE_PART_TYPES.POPUP_CHAT: {
          $templateRequest('js/components/message-preview/message-templates/popup-chat.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            vm.iframeContent.isUsCountry = l10nHelper.isUsCountry();

            vm.iframeContent.animationClass = localeClass;
            angular.extend(vm.iframeContent, vm.parameters, { USER_FILES_URL: USER_FILES_URL });

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то при помощи angular-translate эту ситуацию обработать не просто. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  inputPlaceholder: $translate.instant(
                    'directives.messagePreview.popupChat.' + newValue + '.inputPlaceholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  defaultSenderName: $translate.instant(
                    'directives.messagePreview.popupChat.' + newValue + '.defaultSenderName',
                  ),
                });
                angular.extend(vm.iframeContent, { defaultSenderAvatar: TEAM_MEMBER_DEFAULT_AVATAR_URL });
              }
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue, oldValue) {
                angular.extend(vm.iframeContent, newValue);

                // Передаем анимацию, чтобы анимация отработала
                // необходимо удалить существующий класс и через таймаут подставить новый
                if (newValue.isAnimationActive) {
                  vm.iframeContent.animationClass = localeClass;

                  $timeout(function () {
                    for (var i = 0; i < WIDGET_ANIMATIONS.length; i++) {
                      if (
                        newValue.isAnimationActive[WIDGET_ANIMATIONS[i]] !=
                        oldValue.isAnimationActive[WIDGET_ANIMATIONS[i]]
                      ) {
                        vm.iframeContent.animationClass =
                          'icon-chat-animate-option-' + WIDGET_ANIMATIONS[i].replace(/_/g, '-'); //Т.к. классы должны быть в kebab изменяем underscore на kebab
                      }
                    }
                  });
                }

                // Выставляем флаг о том что параметры обновлены, через таймаут убераем его
                if (
                  newValue.messengerIndent &&
                  (newValue.messengerIndent.vertical != oldValue.messengerIndent.vertical ||
                    newValue.messengerIndent.horizontal != oldValue.messengerIndent.horizontal)
                ) {
                  vm.iframeContent.parametersUpdated = true;

                  $timeout.cancel(parametersUpdatedTimer);
                  parametersUpdatedTimer = $timeout(function () {
                    vm.iframeContent.parametersUpdated = false;
                  }, 2000);
                }
              },
              true,
            );
          });
          break;
        }

        case MESSAGE_PART_TYPES.POPUP_SMALL: {
          $templateRequest('js/components/message-preview/message-templates/popup-small.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            angular.extend(vm.iframeContent, vm.parameters);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то flow переводов идёт по пизде. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  textPlaceholder: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.textReply.input.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  emailPlaceholder: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.emailReply.input.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  phonePlaceholder: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.phoneReply.input.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  confirmSubscriptionWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.confirmSubscriptionWithCheckboxText',
                    {
                      confirmSubscriptionLink: vm.iframeContent.confirmSubscriptionLink,
                    },
                  ),
                  confirmSubscriptionWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.confirmSubscriptionWithoutCheckboxText',
                    {
                      confirmSubscriptionLink: vm.iframeContent.confirmSubscriptionLink,
                    },
                  ),
                  emailConsentWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.emailConsentWithCheckboxText',
                    {
                      confirmSubscriptionEmailUrl: vm.iframeContent.confirmSubscriptionEmailUrl,
                    },
                  ),
                  emailConsentWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.emailConsentWithoutCheckboxText',
                    {
                      confirmSubscriptionEmailUrl: vm.iframeContent.confirmSubscriptionEmailUrl,
                    },
                  ),
                  phoneConsentWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.phoneConsentWithCheckboxText',
                    {
                      confirmSubscriptionPhoneUrl: vm.iframeContent.confirmSubscriptionPhoneUrl,
                    },
                  ),
                  phoneConsentWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.phoneConsentWithoutCheckboxText',
                    {
                      confirmSubscriptionPhoneUrl: vm.iframeContent.confirmSubscriptionPhoneUrl,
                    },
                  ),
                  isRusCountry: l10nHelper.isRusCountry(),
                });
                angular.extend(vm.iframeContent, {
                  poweredBy: $translate.instant('directives.messagePreview.popupSmall.' + newValue + '.poweredBy'),
                });
                angular.extend(vm.iframeContent, {
                  poweredByImageSrc: l10nHelper.isUsCountry()
                    ? 'assets/img/default/message-preview/dashly-logo.svg'
                    : 'assets/img/default/message-preview/carrot-logo.svg',
                });
              }
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });
          break;
        }

        case MESSAGE_PART_TYPES.POPUP_BIG: {
          $templateRequest('js/components/message-preview/message-templates/popup-big.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            angular.extend(vm.iframeContent, vm.parameters);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то flow переводов идёт по пизде. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  textPlaceholder: $translate.instant(
                    'directives.messagePreview.popupBig.' + newValue + '.textReply.textarea.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  emailPlaceholder: $translate.instant(
                    'directives.messagePreview.popupBig.' + newValue + '.emailReply.input.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  phonePlaceholder: $translate.instant(
                    'directives.messagePreview.popupBig.' + newValue + '.phoneReply.input.placeholder',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  confirmSubscriptionWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.confirmSubscriptionWithCheckboxText',
                    {
                      confirmSubscriptionLink: vm.iframeContent.confirmSubscriptionLink,
                    },
                  ),
                  confirmSubscriptionWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.confirmSubscriptionWithoutCheckboxText',
                    {
                      confirmSubscriptionLink: vm.iframeContent.confirmSubscriptionLink,
                    },
                  ),
                  emailConsentWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.emailConsentWithCheckboxText',
                    {
                      confirmSubscriptionEmailUrl: vm.iframeContent.confirmSubscriptionEmailUrl,
                    },
                  ),
                  emailConsentWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.emailConsentWithoutCheckboxText',
                    {
                      confirmSubscriptionEmailUrl: vm.iframeContent.confirmSubscriptionEmailUrl,
                    },
                  ),
                  phoneConsentWithCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.phoneConsentWithCheckboxText',
                    {
                      confirmSubscriptionPhoneUrl: vm.iframeContent.confirmSubscriptionPhoneUrl,
                    },
                  ),
                  phoneConsentWithoutCheckboxText: $translate.instant(
                    'directives.messagePreview.popupSmall.' + newValue + '.phoneConsentWithoutCheckboxText',
                    {
                      confirmSubscriptionPhoneUrl: vm.iframeContent.confirmSubscriptionPhoneUrl,
                    },
                  ),
                  isRusCountry: l10nHelper.isRusCountry(),
                });
                angular.extend(vm.iframeContent, {
                  poweredBy: $translate.instant('directives.messagePreview.popupBig.' + newValue + '.poweredBy'),
                });
                angular.extend(vm.iframeContent, {
                  poweredByImageSrc: l10nHelper.isUsCountry()
                    ? 'assets/img/default/message-preview/dashly-logo.svg'
                    : 'assets/img/default/message-preview/carrot-logo.svg',
                });
                angular.extend(vm.iframeContent, {
                  closeButton: $translate.instant('directives.messagePreview.popupBig.' + newValue + '.closeButton'),
                });
              }
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });
          break;
        }

        case MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.AI: {
          $templateRequest('js/components/message-preview/message-templates/email-ai.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то flow переводов идёт по пизде. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  replyHelper: $translate.instant(
                    'directives.messagePreview.emailDefault.' + newValue + '.replyHelper',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  unsubscribe: $translate.instant(
                    'directives.messagePreview.emailDefault.' + newValue + '.unsubscribe',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  poweredBy: $translate.instant('directives.messagePreview.emailDefault.' + newValue + '.poweredBy', {
                    landingUrl: `${LANDING_URL}?utm_campaign=powered_by&utm_medium=outbound&utm_source=cqemail&app_name=${vm.parameters.appName}`,
                    projectName: PROJECT_NAME,
                  }),
                });
                angular.extend(vm.iframeContent, {
                  date: $filter('amDateFormat')(
                    moment().locale(newValue),
                    appModel.isAppLanguageRu(newValue) ? 'LT L' : 'L LT',
                  ),
                });
              }
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });

          break;
        }

        case MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.DEFAULT: {
          if (vm.currentApp.settings.messages_lightweight_template) {
            var emailTemplateUrl = 'js/components/message-preview/message-templates/email-lightweight.html';
          } else {
            var emailTemplateUrl = 'js/components/message-preview/message-templates/email-default.html';
          }
          $templateRequest(emailTemplateUrl).then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то flow переводов идёт по пизде. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  replyHelper: $translate.instant(
                    'directives.messagePreview.emailDefault.' + newValue + '.replyHelper',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  unsubscribe: $translate.instant(
                    'directives.messagePreview.emailDefault.' + newValue + '.unsubscribe',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  poweredBy: $translate.instant('directives.messagePreview.emailDefault.' + newValue + '.poweredBy', {
                    landingUrl: `${LANDING_URL}?utm_campaign=powered_by&utm_medium=outbound&utm_source=cqemail&app_name=${vm.parameters.appName}`,
                    projectName: PROJECT_NAME,
                  }),
                });
                angular.extend(vm.iframeContent, {
                  date: $filter('amDateFormat')(
                    moment().locale(newValue),
                    appModel.isAppLanguageRu(newValue) ? 'LT L' : 'L LT',
                  ),
                });
              }
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });

          break;
        }

        case MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML: {
          $templateRequest('js/components/message-preview/message-templates/email-html.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });
          });
          break;
        }

        case MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE: {
          $templateRequest('js/components/message-preview/message-templates/email-bee.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });
          });
          break;
        }

        // NOTE: пуш не обязательно было оборачивать в iframe, но лучше обернуть, чтобы в будущем никакие стили не оказывали на него влияние, а так же можно было загрузить в него шрифты, которые используются в пушах гугла
        case MESSAGE_PART_TYPES.PUSH: {
          $templateRequest('js/components/message-preview/message-templates/push.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            angular.extend(vm.iframeContent, vm.parameters);

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                // у пушей и теле поддерживается jinja, поэтому его надо отфильтровать
                newValue.body = filterMessagePartContent(messagePartType, newValue.body);

                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });
          break;
        }

        case MESSAGE_PART_TYPES.SDK_POPUP_CHAT: {
          $templateRequest('js/components/message-preview/message-templates/popup-chat.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            vm.iframeContent.isUsCountry = l10nHelper.isUsCountry();

            vm.iframeContent.animationClass = localeClass;
            angular.extend(vm.iframeContent, vm.parameters, { USER_FILES_URL: USER_FILES_URL });

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue, oldValue) {
                angular.extend(vm.iframeContent, newValue);

                // Передаем анимацию, чтобы анимация отработала
                // необходимо удалить существующий класс и через таймаут подставить новый
                if (newValue.isAnimationActive) {
                  vm.iframeContent.animationClass = localeClass;

                  $timeout(function () {
                    for (var i = 0; i < WIDGET_ANIMATIONS.length; i++) {
                      if (
                        newValue.isAnimationActive[WIDGET_ANIMATIONS[i]] !=
                        oldValue.isAnimationActive[WIDGET_ANIMATIONS[i]]
                      ) {
                        vm.iframeContent.animationClass =
                          'icon-chat-animate-option-' + WIDGET_ANIMATIONS[i].replace(/_/g, '-'); //Т.к. классы должны быть в kebab изменяем underscore на kebab
                      }
                    }
                  });
                }

                // Выставляем флаг о том что параметры обновлены, через таймаут убераем его
                if (
                  newValue.messengerIndent &&
                  (newValue.messengerIndent.vertical != oldValue.messengerIndent.vertical ||
                    newValue.messengerIndent.horizontal != oldValue.messengerIndent.horizontal)
                ) {
                  vm.iframeContent.parametersUpdated = true;

                  $timeout.cancel(parametersUpdatedTimer);
                  parametersUpdatedTimer = $timeout(function () {
                    vm.iframeContent.parametersUpdated = false;
                  }, 2000);
                }
              },
              true,
            );
          });
          break;
        }

        // NOTE: пуш не обязательно было оборачивать в iframe, но лучше обернуть, чтобы в будущем никакие стили не оказывали на него влияние, а так же можно было загрузить в него шрифты, которые используются в пушах гугла
        case MESSAGE_PART_TYPES.SDK_PUSH: {
          $templateRequest('js/components/message-preview/message-templates/sdk-push.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.content = filterMessagePartContent(messagePartType, vm.content);
            angular.extend(vm.iframeContent, vm.parameters);

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              if (angular.isDefined(newValue)) {
                // HACK: из-за того, что в превью может быть другая локаль, отличная от админской (по скольку тянется из currentApp), то flow переводов идёт по пизде. Из этого есть 2 выхода:
                // 1) Как сделано сейчас. То есть в русском и английском файле переводов дублировать переводы для русского и английского языков.
                // Минус - дублирование текста
                // 2) Переводить через $translate, используя промисы и параметр forcedLanguage, то есть ждать загрузки файлов языка, отличного от админки, и потом уже работать с полученными переводами.
                // Минусы: а) если язык админки русский, а forcedLanguage - английсий, то грузятся вообще все английские json-файлы, чтобы их набор соответствовал русским файлам. б) самый главный минус - судя по всему $translate вызывает success промиса не дождавшись загрузки всех английских файлов, и как результат ничерта не переводит
                angular.extend(vm.iframeContent, {
                  applicationLabel: $translate.instant(
                    'directives.messagePreview.sdkPush.' + newValue + '.applicationLabel',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  todayLabelIos: $translate.instant('directives.messagePreview.sdkPush.' + newValue + '.todayLabelIos'),
                });
                angular.extend(vm.iframeContent, {
                  todayLabelAndroid: $translate.instant(
                    'directives.messagePreview.sdkPush.' + newValue + '.todayLabelAndroid',
                  ),
                });
                angular.extend(vm.iframeContent, {
                  bodyPlaceholder: $translate.instant(
                    'directives.messagePreview.sdkPush.' + newValue + '.bodyPlaceholder',
                  ),
                });
              }
            });

            unbindWatchOnContent = $scope.$watch('vm.content', function (newValue) {
              vm.iframeContent.content = filterMessagePartContent(messagePartType, newValue);
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                // у пушей и теле поддерживается jinja, поэтому его надо отфильтровать
                newValue.body = filterMessagePartContent(messagePartType, newValue.body);

                angular.extend(vm.iframeContent, newValue);
              },
              true,
            );
          });
          break;
        }

        case MESSAGE_PART_TYPES.TELEGRAM:
          $templateRequest('js/components/message-preview/message-templates/telegram.html').then(function (data) {
            vm.iframeTemplate = data;
            vm.iframeContent.isUsCountry = l10nHelper.isUsCountry();

            vm.iframeContent.animationClass = localeClass;
            angular.extend(vm.iframeContent, vm.parameters, { USER_FILES_URL: USER_FILES_URL });

            unbindWatchOnLocale = $scope.$watch('vm.language', function (newValue) {
              vm.locale = l10nHelper.getFourLetterLang(newValue);

              angular.extend(vm.iframeContent, {
                zeroData: $translate.instant('directives.messagePreview.telegram.' + newValue + '.zeroData'),
              });
            });

            unbindWatchOnParameters = $scope.$watch(
              'vm.parameters',
              function (newValue) {
                parseTelegramContentForPreview(newValue).then((data) => {
                  angular.extend(vm.iframeContent, { contentData: data });
                });
              },
              true,
            );
          });
          break;
      }
    }

    async function parseTelegramContentForPreview(tgContent) {
      const res = {
        buttons: [],
        contents: [],
      };

      for (let button of tgContent.buttons) {
        // &shy; надо чтобы кнопки не скукоживались по высоте при отсутствии текста
        res.buttons.push(button.text || '&shy;');
      }

      let canvas;
      let context;
      if (tgContent.contents.length > 0) {
        canvas = document.createElement('canvas');
        canvas.class = 'hidden';
        context = canvas.getContext('2d');
        document.body.appendChild(canvas);
      }

      for (let content of tgContent.contents) {
        /*
        Вот что надо знать, чтобы понять почему все сделано так
          1. base64 url загруженных, но не сохраненных файлов, не будут загружаться в <video> в превью
            поэтому я формирую base64 картинки для превью
          2. canvas не выполнит toDataURL с обычным URL (сохраненные файлы) из-за CORS
            поэтому в превью они грузятся через <video>
          3. Видео грузятся дольше изображений, из-за этого высота превью определяется не верно
            поэтому я рассчитываю videoPreviewHeight, чтобы заранее заполнить ширину
        */
        let attach = null;
        if (content.attachment) {
          let isVideoPreviewCircle = false;
          let videoPreviewHeight = null;
          let videoPreviewBase64 = null;

          if (content.type === 'video_note') {
            let videoElement = null;

            if (vm.tgCachedPreview[content.attachment.url]) {
              videoPreviewBase64 = vm.tgCachedPreview[content.attachment.url].videoPreviewBase64;
              isVideoPreviewCircle = vm.tgCachedPreview[content.attachment.url].isVideoPreviewCircle;
              videoPreviewHeight = vm.tgCachedPreview[content.attachment.url].videoPreviewHeight;
            } else {
              videoElement = document.createElement('video');
              videoElement.class = 'hidden';
              videoElement.crossOrigin = 'anonymous';
              videoElement.src = content.attachment.url;
              document.body.appendChild(videoElement);
            }

            await new Promise((resolve) => {
              if (vm.tgCachedPreview[content.attachment.url]) {
                resolve();
              }

              const loadData = () => {
                isVideoPreviewCircle =
                  videoElement.videoHeight === videoElement.videoWidth && videoElement.videoWidth <= 600;
                if (isVideoPreviewCircle) {
                  // 220 - Максимальная ширина/высота круглого видео
                  videoPreviewHeight = 220;
                  // 320 - Ширина контента
                } else if (videoElement.videoWidth <= 320) {
                  videoPreviewHeight = videoElement.videoHeight;
                } else {
                  videoPreviewHeight = (320 / videoElement.videoWidth) * videoElement.videoHeight;
                }

                vm.tgCachedPreview[content.attachment.url] = {
                  isVideoPreviewCircle,
                  videoPreviewHeight,
                };

                videoElement.currentTime = 0.5; // Устанавливаем время для захвата кадра
              };

              const captureFrame = () => {
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                try {
                  videoPreviewBase64 = canvas.toDataURL('image/jpeg');
                  vm.tgCachedPreview[content.attachment.url].videoPreviewBase64 = videoPreviewBase64;
                } catch (error) {}
                videoElement.removeEventListener('loadeddata', loadData);
                videoElement.removeEventListener('timeupdate', captureFrame);
                videoElement.remove();
                resolve();
              };

              videoElement.addEventListener('loadeddata', loadData);
              videoElement.addEventListener('timeupdate', captureFrame);
            });
          }

          attach = {
            filename: content.attachment.filename || content.attachment.name,
            mimeType: content.attachment.mimeType || content.attachment.type,
            size: content.attachment.size,
            url: content.attachment.url,
            isImage: (content.attachment.mimeType || content.attachment.type).includes('image'),
            extension: getFileExtension(content.attachment.filename || content.attachment.name),
            isVideoPreviewCircle: isVideoPreviewCircle,
            videoPreviewUrl: videoPreviewBase64,
            videoPreviewHeight: videoPreviewHeight,
          };
        }
        res.contents.push({
          type: content.type,
          // &shy; надо чтобы блок не скукоживался по высоте при отсутствии текста
          value: $filter('removeHref')(content.value || '&shy;'),
          attachment: attach,
        });
      }

      if (canvas) {
        canvas.remove();
      }

      return res;

      function getFileExtension(filename) {
        // Убедимся, что имя файла содержит точку (.) и это не начало строки
        if (filename.includes('.') && !filename.startsWith('.')) {
          return filename.split('.').pop();
        }

        return '';
      }
    }

    /**
     * Получения темплейта автосообщения (на самом деле - враппера, который содержит в себе <cq-dynamic-iframe-ajs>)
     *
     * @param {MESSAGE_PART_TYPES} messagePartType Тип варианта сообщения
     * @returns {String}
     */
    function getTemplateUrl(messagePartType) {
      // Не у всех типов сообщений есть превьюшки для внешней страницы
      if (
        vm.isExternal &&
        ~[
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.AI,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.DEFAULT,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML,
          MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE,
          MESSAGE_PART_TYPES.POPUP_BIG,
          MESSAGE_PART_TYPES.POPUP_CHAT,
          MESSAGE_PART_TYPES.POPUP_SMALL,
          MESSAGE_PART_TYPES.PUSH,
          MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
          MESSAGE_PART_TYPES.SDK_PUSH,
          MESSAGE_PART_TYPES.TELEGRAM,
        ].indexOf(messagePartType)
      ) {
        return (
          'js/components/message-preview/message-templates/' +
          caseStyleHelper.toKebabCase(messagePartType) +
          '.wrapper-external.html'
        );
      } else {
        return (
          'js/components/message-preview/message-templates/' +
          caseStyleHelper.toKebabCase(messagePartType) +
          '.wrapper.html'
        );
      }
    }
  }
})();
