import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DATA_SOURCE, DATA_SOURCE_STATUS } from '@http/ai-data/ai-data.constants';
import { AiDataBotSettingsExternal, AiDataCrawlingResultExternal, AiDataExternal } from '@http/ai-data/external-types';
import { AiDataBotSettings } from '@http/ai-data/internal-types';
import { AiDataMapper } from '@http/ai-data/mappers/ai-data.mapper';
import { APIPaginatableResponse, APIResponse, PaginationParamsRequest } from '@http/types';
import { EXTENDED_ERROR, EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class AiDataModel {
  constructor(private readonly http: HttpClient, private readonly aiDataMapper: AiDataMapper) {}

  /**
   * Сохранение накрауленных веб-страниц в качестве источников контента
   *
   * @param allNew Добавить все новые найденные страницы. Если true, то sourceIds не указывается.
   * @param sourceIds ID накрауленных страниц. Если передаётся этот параметр, то allNew передавать запрещено.
   * @param includeExistingContent Нужно ли обновить страницы, которые заново были найдены, но которые уже добавлены в источники контента
   */
  addContentSources(allNew: boolean, sourceIds: string[] = [], includeExistingContent: boolean = false) {
    let params: Record<string, any> = {
      include_existing_content: includeExistingContent,
    };

    if (allNew) {
      params['all_new'] = allNew;
    } else if (sourceIds.length > 0) {
      params['crawled_urls'] = sourceIds;
    }

    return this.http.post('/web_crawler/save_urls_as_content', params);
  }

  /**
   * Получение списка источников контента
   *
   * @param source Тип источника
   * @param status Статус активности источника
   * @param paginatorParams Параметр пагинации
   */
  getList(source?: DATA_SOURCE, status?: DATA_SOURCE_STATUS, paginatorParams?: PaginationParamsRequest) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params: Record<string, any> = {
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || null,
      paginate_page_order: paginatePageOrder,
    };

    if (source) {
      params.kind = this.aiDataMapper.parseDataSourceToExternal(source);
    }

    if (status) {
      params.removed = this.aiDataMapper.parseDataStatusToExternal(status);
    }

    return this.http
      .get<APIPaginatableResponse<AiDataExternal[]>>('/integrations/chat_gpt/content', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          return {
            dataSourceList: response.data.map((data) => this.aiDataMapper.parseToInternal(data)),
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  /**
   * Получение настроек AI-бота
   */
  getSettings() {
    return this.http
      .get<AiDataBotSettingsExternal>(`/integrations/chat_gpt`, {})
      .pipe(map((response) => this.aiDataMapper.parseAiBotSettingsToInternal(response)));
  }

  /**
   * Сохранение настроек AI-бота
   *
   * @param settings Настройки AI-бота
   */
  saveSettings(settings: AiDataBotSettings) {
    const params = this.aiDataMapper.parseAiBotSettingsToExternal(settings);

    return this.http.patch(`/integrations/chat_gpt`, params);
  }

  /**
   * Получение результатов краулинга веб-страниц
   *
   * @param existingContent Вернуть в ответе страницы, которые уже включены в источники контента
   * @param paginatorParams Параметр пагинации
   */
  getCrawlingResult(existingContent: boolean = false, paginatorParams?: PaginationParamsRequest) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params: Record<string, any> = {
      existing_content: existingContent,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || null,
      paginate_page_order: paginatePageOrder,
    };

    return this.http
      .get<APIPaginatableResponse<AiDataCrawlingResultExternal>>('/web_crawler/crawl', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(EXTENDED_ERROR, true),
      })
      .pipe(
        map((response) => {
          return {
            result: this.aiDataMapper.parseCrawlingResultToInternal(response.data),
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            } as PaginationParamsRequest,
          };
        }),
      );
  }

  /**
   * Запуск процесса краулинга веб-страниц
   *
   * @param url Адрес, с которого начнётся процесс поиска страниц
   */
  startCrawling(url: string) {
    const params = {
      url,
    };

    return this.http.post<APIResponse<{}>>(`/web_crawler/crawl`, params);
  }

  /** Удаление результата последнего процесса краулинга */
  deleteCrawlingResults() {
    return this.http.delete<APIResponse<{}>>(`/web_crawler/crawl`);
  }
}
