import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { filter } from 'lodash-es';

import { environment } from '@environment';
import { MessageModel } from '@http/message/message.model';
import {
  GOAL_EVENT_MAX_TIMEOUT,
  GOAL_EVENT_MIN_TIMEOUT,
} from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { TIME_UNIT_MEASURES } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

@Component({
  selector: 'cq-auto-message-goal',
  templateUrl: './auto-message-goal.component.html',
  styleUrls: ['./auto-message-goal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageGoalComponent implements OnInit {
  @Input()
  currentMessage!: any;

  @Input()
  eventTypes!: any;

  protected isCollapsed: boolean = false;

  protected formGroup: any;
  protected goalEventSwitch: any;
  protected messagePartTypeName: any;
  protected sortedEventTypes: any;

  protected GOAL_EVENT_MAX_TIMEOUT = GOAL_EVENT_MAX_TIMEOUT;
  protected TIME_UNIT_MEASURES: any = TIME_UNIT_MEASURES;

  protected currencySymbol = environment.currency.symbol;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly fb: FormBuilder,
    protected readonly messageModel: MessageModel,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        goalEventCost: this.fb.control(this.currentMessage.goalEventCost, [Validators.required, Validators.min(1)]),
        goalEventCostProp: this.fb.control(this.currentMessage.goalEventCostProp, [Validators.required]),
        goalEventCostType: this.fb.control(this.currentMessage.goalEventCostType, []),
        goalEventTimeout: this.fb.control(
          this.currentMessage.goalEventTimeout / this.TIME_UNIT_MEASURES[this.currentMessage.goalEventTimeoutTimeUnit],
          [
            Validators.required,
            Validators.min(GOAL_EVENT_MIN_TIMEOUT),
            Validators.max(this.currentMessage.goalEventMaxTimeout),
          ],
        ),
        goalEventTimeoutTimeUnit: this.fb.control(this.currentMessage.goalEventTimeoutTimeUnit, [Validators.required]),
        goalEventType: this.fb.control(this.currentMessage.goalEventType),
      },
      { emitEvent: false },
    );
    this.formGroup.valueChanges.subscribe((values: any) => {
      this.currentMessage.goalEventCost = parseFloat(`${values.goalEventCost}`.replace(',', '.'));
      this.currentMessage.goalEventCostProp = values.goalEventCostProp;
      this.currentMessage.goalEventCostType = values.goalEventCostType;
      this.currentMessage.goalEventTimeout =
        values.goalEventTimeout * this.TIME_UNIT_MEASURES[this.currentMessage.goalEventTimeoutTimeUnit];
      this.currentMessage.goalEventTimeoutTimeUnit = values.goalEventTimeoutTimeUnit;
      this.currentMessage.goalEventType = values.goalEventType;
    });

    this.goalEventSwitch = !!this.currentMessage.goalEventType;
    this.messagePartTypeName = this.messageModel.getMessagePartTypeName(this.currentMessage.parts);
    this.sortedEventTypes = this.getSortedEventTypes();
  }

  getSortedEventTypes(): any {
    let visibleEvents = filter(this.eventTypes, { visible: true });

    return visibleEvents.sort((a, b) => {
      const nameA = a.prettyName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const nameB = b.prettyName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }

  /**
   * Изменение значения свича цели
   * @param value
   */
  changeGoalEventSwitch(value: any): void {
    if (!value) {
      this.removeGoal();
    }
  }

  /**
   * Указывает свойство события, выбранного в качестве цели
   *
   * @param name Название свойства
   */
  checkGoalEventCostProp(name: any): void {
    this.formGroup.controls.goalEventCostType.setValue('eventField');
    this.formGroup.controls.goalEventCostProp.setValue(name);
  }

  /**
   * Удаления цели сообщения
   */
  removeGoal(): any {
    this.formGroup.controls.goalEventType.setValue(null);
  }

  /**
   * Обработка выбора цели
   *
   * @param item объект Event
   */
  setGoalEventCostProp(item: any): void {
    if (this.formGroup.controls.goalEventType.getRawValue() == 'none') {
      switch (item.name) {
        case '$order_completed':
          this.checkGoalEventCostProp('$order_amount');
          break;
        case '$cart_added':
          this.checkGoalEventCostProp('$amount');
          break;
        case '$order_paid':
          this.checkGoalEventCostProp('$order_amount');
          break;
      }
    }

    this.carrotquestHelper.track('Автосообщения - выбрал событие для цели');
  }
}
