import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';

import { EditorFormatSizeComponent } from './editor-format-size.component';

@NgModule({
  declarations: [EditorFormatSizeComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    NgbDropdown,
    NgbTooltip,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
  ],
  exports: [EditorFormatSizeComponent],
})
export class EditorFormatSizeModule {}
