import Inline from 'quill/blots/inline';

export class EditorCustomBlotUserProperty extends Inline {
  static blotName = 'user_property';
  static className = 'badge';
  static tagName = 'SPAN';

  static create(value: any): any {
    let node = super.create(value);

    if (typeof value === 'object' && value.defaultValue && value.prettyName && value.propertyName) {
      if (value.defaultValue) {
        node.dataset.defaultValue = value.defaultValue;

        if (value.propertyName) {
          node.dataset.propName = value.propertyName;
        }
      }

      node.classList.add('badge-light-secondary', 'cursor-pointer');
      node.setAttribute('contenteditable', 'false');
    }

    return node;
  }

  static formats(domNode: HTMLElement) {
    return {
      defaultValue: domNode.dataset.defaultValue,
      prettyName: domNode.innerText,
      propertyName: domNode.dataset.propName,
    };
  }

  public formats(): { [index: string]: any } {
    let format = this.statics.formats(this.domNode, this.scroll);
    let formats = this.attributes.values();

    if (format !== null) {
      formats[this.statics.blotName] = format;
    }

    return formats;
  }

  format(name: string, value: any): any {
    if (name === EditorCustomBlotUserProperty.blotName && value) {
      if (value.propertyName) {
        this.domNode.dataset.propName = value.propertyName;
      }

      if (value.defaultValue) {
        this.domNode.dataset.defaultValue = value.defaultValue;
      }

      if (value.prettyName) {
        this.domNode.innerText = value.prettyName;
      }
    } else {
      super.format(name, value);
    }
  }
}
