import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.live').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.live', {
      url: '/live',
      bindings: {
        billingInfo: 'billingInfo',
        currentApp: 'currentApp',
        djangoUser: 'djangoUser',
        telegramIntegrations: 'telegramIntegrations',
        emailDomains: 'emailDomains',
      },
      resolve: {
        telegramIntegrations: getTelegramIntegrations,
        emailDomains: getEmailDomains,
      },
      component: 'cqLive',
      data: {
        roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.OPERATOR],
      },
    });
  }

  function getTelegramIntegrations(integrationModel, currentApp) {
    return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
      return [...response[0]].filter((integration) => {
        return integration.active === true;
      });
    });
  }

  /**
   * Получение списка емейл доменов
   */
  function getEmailDomains(currentApp, emailDomainModel) {
    return firstValueFrom(emailDomainModel.getList(currentApp.id));
  }
})();
