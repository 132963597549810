import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionChannelComponent } from './channel.component';

@NgModule({
  declarations: [BranchActionChannelComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    ChannelIconModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
  exports: [BranchActionChannelComponent],
})
export class BranchActionChannelModule {}
