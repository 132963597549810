import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { AutoMessageTypeFilterComponent } from './auto-message-type-filter.component';

@NgModule({
  declarations: [AutoMessageTypeFilterComponent],
  exports: [AutoMessageTypeFilterComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class AutoMessageTypeFilterModule {}
