import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PropertyModel } from '@http/property/property.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { AutoMessagesPageComponent } from '@panel/app/pages/auto-messages/auto-messages-page.component';
import { AutoMessageListPageComponent } from '@panel/app/pages/auto-messages/pages/list/auto-message-list-page.component';

export const AUTO_MESSAGES_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.messages',
    url: '/messages',
    redirectTo: 'app.content.messages.auto',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
    },
    component: AutoMessagesPageComponent,
  },
  {
    name: 'app.content.messages.auto',
    url: `/auto?{tab}`,
    onEnter: clearAutoMessagesFilters,
    onExit: clearAutoMessagesFilters,
    redirectTo: 'app.content.messages.auto.table',
    component: AutoMessagesPageComponent,
  },
  {
    name: 'app.content.messages.auto.table',
    component: AutoMessageListPageComponent,
    url: '',
    resolve: [
      {
        token: 'activeMessagesAmounts',
        deps: ['messageModel'],
        resolveFn: getActiveMessagesAmounts,
      },
      {
        token: 'appBlocks',
        deps: ['appBlocks'],
        resolveFn: (appBlocks: any) => appBlocks,
      },
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'directories',
        deps: ['currentApp', 'messageDirectoryModel'],
        resolveFn: getDirectories,
      },
      {
        token: 'hasMessages',
        deps: ['messageModel'],
        resolveFn: hasMessages,
      },
      {
        token: 'planCapabilities',
        deps: ['planCapabilities'],
        resolveFn: (planCapabilities: any) => planCapabilities,
      },
      {
        token: 'properties',
        deps: ['currentApp', PropertyModel],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', 'tagModel'],
        resolveFn: getAllUserTags,
      },
      {
        token: 'onboarding',
        deps: ['currentApp', 'starterGuideModel'],
        resolveFn: getOnboardingInfo,
      },
    ],
  },
  {
    name: 'app.content.messages.auto.create',
    url: '/create?{fromStarterGuideStep}',
    redirectTo: 'app.content.messagesAjs.create',
  },
  {
    name: 'app.content.messages.auto.createOnTemplate',
    url: '/create/on-template/{templateId:id}?{step:int}',
    redirectTo: 'app.content.messagesAjs.createOnTemplate',
  },
  {
    name: 'app.content.messages.auto.createOnReadyTemplate',
    url: '/create/on-ready-template/{readyTemplateId:id}?{step:int}',
    redirectTo: 'app.content.messagesAjs.createOnReadyTemplate',
  },
  {
    name: 'app.content.messages.auto.statistics',
    url: '/{messageId:id}',
    redirectTo: 'app.content.messagesAjs.statistics',
  },
  {
    name: 'app.content.messages.auto.edit',
    url: '/{messageId:id}/edit?{step:int}',
    redirectTo: 'app.content.messagesAjs.edit',
  },
  {
    name: 'app.content.messages.auto.edit.copy',
    url: '/copy',
    redirectTo: 'app.content.messagesAjs.edit.copy',
  },
];

/**
 * Получение количества активных автосообщений
 *
 * @param messageModel
 *
 * @return {*}
 */
function getActiveMessagesAmounts(messageModel: any): any {
  return firstValueFrom(messageModel.getActiveTriggerMessageCount());
}

/**
 * Получение списка директорий автосообщений
 *
 * @param currentApp
 * @param messageDirectoryModel
 */
function getDirectories(currentApp: any, messageDirectoryModel: MessageDirectoryModel): any {
  return firstValueFrom(messageDirectoryModel.getList(currentApp.id, true, true));
}

function hasMessages(messageModel: any): any {
  return firstValueFrom(messageModel.getAutoMessages(MESSAGE_PART_TYPES.ALL, MESSAGE_STATUSES.ALL)).then(
    (resp: any) => resp.messages.length > 0,
  );
}

/**
 * Получение списка свойств и типов событий
 *
 * @param currentApp
 * @param propertyModel
 */
function getProperties(currentApp: any, propertyModel: any): any {
  return firstValueFrom(propertyModel.getList(currentApp.id));
}

/**
 * Получение всех тегов пользователя
 *
 * @param currentApp
 * @param tagModel
 */
function getAllUserTags(currentApp: any, tagModel: any): any {
  return firstValueFrom(tagModel.getList(currentApp.id));
}

function getOnboardingInfo(currentApp: any, starterGuideModel: any): any {
  return firstValueFrom(starterGuideModel.get(currentApp));
}

/**
 * Затирает в sessionStorage фильтры автосообщений
 */
function clearAutoMessagesFilters() {
  sessionStorage.setItem('autoMessageFilters', '');
}
