import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { EditorFormatBoldComponent } from './editor-format-bold.component';

@NgModule({
  declarations: [EditorFormatBoldComponent],
  exports: [EditorFormatBoldComponent],
  imports: [CommonModule, TranslocoDirective, NgbTooltip],
})
export class EditorFormatBoldModule {}
