/** Сервис для работы с модальным окном "Вечеринка закончилась" */
import { Injectable } from '@angular/core';
import { ui } from 'angular';

import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Injectable({ providedIn: 'root' })
export class PartyIsOverModalService {
  /** Инстанс модального окна */
  partyIsOverModalServiceInstance: ui.bootstrap.IModalInstanceService | null = null;

  constructor(private readonly uibModalService: UibModalService) {}

  /** Скрытие модального окна */
  hideModal() {
    if (this.partyIsOverModalServiceInstance) {
      this.partyIsOverModalServiceInstance.close();
      this.partyIsOverModalServiceInstance = null;
    }
  }

  /**
   * Открытие модального окна
   *
   * @param {Object=} currentApp - Текущее приложение
   * @param {Object} djangoUser - Текущий django-пользователь
   * @param {Object} billingInfo - Информация о биллинге
   */
  showModal(currentApp: App, djangoUser: DjangoUser, billingInfo: BillingInfo) {
    if (!this.partyIsOverModalServiceInstance) {
      this.partyIsOverModalServiceInstance = this.uibModalService.open({
        component: 'cqPartyIsOverModal',
        keyboard: false,
        resolve: {
          billingInfo: angular.bind(null, angular.identity, billingInfo),
          currentApp: angular.bind(null, angular.identity, currentApp),
          djangoUser: angular.bind(null, angular.identity, djangoUser),
        },
        backdrop: 'static',
        size: 'sm modal-dialog-centered',
        windowClass: 'party-is-over',
      });

      this.partyIsOverModalServiceInstance.result.finally(() => {
        this.partyIsOverModalServiceInstance = null;
      });
    }
  }
}
