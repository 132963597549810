import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';

import { App } from '@http/app/app.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { Capability } from '@http/plan-capability/plan-capability.types';
import {
  PLAN_FEATURE,
  PLAN_FEATURES,
  SPECIAL_PLAN_FEATURES,
} from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureService } from '@panel/app/services/billing/plan-feature/plan-feature.service';
import { ProductFeatures, SpecialProductFeatures } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfo, BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';

@Component({
  selector: 'cq-service-billing-info',
  templateUrl: './service-billing-info.component.html',
  styleUrls: ['./service-billing-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceBillingInfoComponent implements OnInit {
  @Input()
  billingInfo!: BillingInfo;

  @Input()
  currentApp!: App;

  @Input()
  capabilities: Capability[] = [];

  @Input()
  activeTriggerMessageAmount: number = 0;

  @Input()
  activeFacebookBotsAmount: number = 0;

  @Input()
  activeLeadBotsAmount: number = 0;

  @Input()
  activeTgBotsAmount: number = 0;

  @Input()
  activeWelcomeBotsAmount: number = 0;

  @Input()
  activeChatBotsAmount: number = 0;

  @Input()
  manualChannelsAmount: number = 0;

  filterAddonText: string = '';
  filteredAddons: BILLING_ADD_ONS[] = [];

  filterCapText: string = '';
  filteredCaps: Capability[] = [];

  featureToCheck?: PLAN_FEATURE;
  features: PLAN_FEATURE[] = Object.values(PLAN_FEATURE);
  isFeaturesSelectNoteCollapsed = true;
  planVersion: PAID_PLAN_VERSION | null = null;

  hasAccessToPage = this.featureModel.hasAccess(FEATURES.SERVICE_BILLING_INFO);

  constructor(
    private readonly featureModel: FeatureModel,
    readonly billingInfoModel: BillingInfoModel,
    readonly planVersionService: PlanVersionService,
    readonly planFeatureAccessService: PlanFeatureAccessService,
    readonly planFeatureService: PlanFeatureService,
    private readonly transloco: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.filterAddons();
    this.filterCaps();
    this.setPlanVersion();
  }

  filterAddons() {
    this.filteredAddons = this.billingInfo.addons.filter((addon) =>
      addon.toLowerCase().includes(this.filterAddonText.toLowerCase()),
    );
  }

  filterCaps() {
    this.filteredCaps = this.capabilities.filter((cap) =>
      cap.name.toLowerCase().includes(this.filterCapText.toLowerCase()),
    );
  }

  getProductFeatureName(productFeature: PLAN_FEATURE): string | null {
    let name = this.transloco.translate(`productFeatureTextService.feature.${productFeature}`, {
      projectName: '',
    });

    return name.includes('productFeatureTextService.feature.') ? null : name;
  }

  private getAccessToSpecial(productFeature: SpecialProductFeatures): boolean {
    let amount = 0;
    switch (true) {
      case PLAN_FEATURE.AUTO_MESSAGES_TOTAL === productFeature:
        amount = this.activeTriggerMessageAmount;
        break;
      case PLAN_FEATURE.FACEBOOK_BOTS === productFeature:
        // NOTE логика подсчета взята из cqFacebookBotEditController getActiveBotsAmount
        if (this.planVersionService.isV1(this.currentApp) || this.planVersionService.isV2(this.currentApp)) {
          amount = this.activeFacebookBotsAmount + this.activeLeadBotsAmount + this.activeWelcomeBotsAmount;
        } else {
          amount = this.activeLeadBotsAmount;
        }
        break;
      case PLAN_FEATURE.LEAD_BOTS === productFeature:
        // NOTE логика подсчета взята из cqChatBotEditController getActiveBotsAmount
        if (this.planVersionService.isV1(this.currentApp) || this.planVersionService.isV2(this.currentApp)) {
          amount = this.activeFacebookBotsAmount + this.activeLeadBotsAmount + this.activeWelcomeBotsAmount;
        } else {
          amount = this.activeLeadBotsAmount + this.activeFacebookBotsAmount;
        }
        break;
      case PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND === productFeature:
        amount = 10;
        break;
      case PLAN_FEATURE.MESSAGES_CHANNELS === productFeature:
        amount = this.manualChannelsAmount;
        break;
      case PLAN_FEATURE.TELEGRAM_BOTS === productFeature:
        amount = this.activeChatBotsAmount;
        break;
      case PLAN_FEATURE.WELCOME_BOTS === productFeature:
        // NOTE логика подсчета взята из cqRoutingBotEditController getActiveBotsAmount
        if (this.planVersionService.isV1(this.currentApp) || this.planVersionService.isV2(this.currentApp)) {
          amount = this.activeFacebookBotsAmount + this.activeLeadBotsAmount + this.activeWelcomeBotsAmount;
        } else {
          amount = this.activeWelcomeBotsAmount;
        }
        break;
    }

    return this.planFeatureAccessService.getAccess(productFeature, this.currentApp, amount).hasAccess;
  }

  @tuiPure
  getAccess(productFeature: PLAN_FEATURE): boolean {
    if (this.planFeatureService.isSpecialFeature(productFeature)) {
      return this.getAccessToSpecial(productFeature as SpecialProductFeatures);
    }
    return this.planFeatureAccessService.getAccess(productFeature, this.currentApp).hasAccess;
  }

  setPlanVersion(): void {
    switch (true) {
      case this.planVersionService.isFreemium():
        this.planVersion = PAID_PLAN_VERSION.FREEMIUM;
        break;
      case this.planVersionService.isLTD():
        this.planVersion = PAID_PLAN_VERSION.LTD;
        break;
      case this.planVersionService.isV1(this.currentApp):
        this.planVersion = PAID_PLAN_VERSION.V1;
        break;
      case this.planVersionService.isV2(this.currentApp):
        this.planVersion = PAID_PLAN_VERSION.V2;
        break;
      case this.planVersionService.isV3(this.currentApp):
        this.planVersion = PAID_PLAN_VERSION.V3;
        break;
    }
  }

  @tuiPure
  getCapsToPlugIn(productFeature: PLAN_FEATURE): PLAN_CAPABILITIES[] {
    if (!this.planVersion || SPECIAL_PLAN_FEATURES.includes(productFeature as SpecialProductFeatures)) {
      return [];
    }
    return PLAN_FEATURES[productFeature as ProductFeatures][this.planVersion].capabilities;
  }

  @tuiPure
  getAddonsToPlugIn(productFeature: PLAN_FEATURE): BILLING_ADD_ONS[] {
    if (!this.planVersion || SPECIAL_PLAN_FEATURES.includes(productFeature as SpecialProductFeatures)) {
      return [];
    }
    return PLAN_FEATURES[productFeature as ProductFeatures][this.planVersion].addOns;
  }
}
