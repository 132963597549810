<ng-container *transloco="let t; read: 'editorPageComponent'">
  <div class="margin-between-rows-50">
    <h1>Editor</h1>

    <div>
      <h3>Basic editor</h3>
      <div class="card">
        <div class="card-body">
          <cq-editor
            class="bordered"
            format="html"
            minHeight="150px"
            [formats]="formats"
            [(ngModel)]="content"
            [userProperties]="properties.userProps"
          ></cq-editor>
          <hr />
          <div>Content:</div>
          <div>{{ content }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
