import { ChangeDetectionStrategy, Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EventType } from '@http/property/property.model';
import { TriggerChainStepReactionCheck } from '@panel/app/http/trigger-chain/internal-types';
import {
  TRIGGER_CHAIN_REACTION_TYPE,
  TRIGGER_CHAIN_REACTION_TYPES,
} from '@panel/app/http/trigger-chain/trigger-chain.constants';
import { ReactionForm } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-reaction-editor/trigger-chain-reaction-editor.types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

@Component({
  selector: 'cq-trigger-chain-reaction-editor',
  templateUrl: './trigger-chain-reaction-editor.component.html',
  styleUrls: ['./trigger-chain-reaction-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainReactionEditorComponent implements OnChanges {
  @Input({ required: true })
  isValidationStrict: boolean = false;

  readonly form: FormGroup<ReactionForm> = new FormGroup<ReactionForm>({
    name: new FormControl('', { nonNullable: true }),
    reactionTime: new FormControl({ time: 1, unit: TIME_UNITS.SECOND }, { nonNullable: true }),
    reactionType: new FormControl(TRIGGER_CHAIN_REACTION_TYPE.CLICKED),
    eventType: new FormControl(null),
  });

  private _step!: TriggerChainStepReactionCheck;

  get step(): TriggerChainStepReactionCheck {
    return this._step;
  }

  @Input({ required: true })
  set step(step: TriggerChainStepReactionCheck) {
    this._step = step;
    this.form.setValue(
      {
        name: step.name,
        reactionTime: step.meta.reactionTime,
        reactionType: step.meta.reactionType,
        eventType: step.meta.event,
      },
      { emitEvent: false },
    );
  }

  @Output()
  stepChange: Observable<TriggerChainStepReactionCheck> = this.form.valueChanges.pipe(
    tap(() => {
      if (!this.isValidationStrict) {
        return;
      }

      let { reactionType } = this.form.getRawValue();

      if (reactionType === null && !this.form.controls.reactionType.hasValidator(Validators.required)) {
        this.addOptionalValidators();
      }
    }),
    map((controlValues) => {
      if (controlValues.name) {
        this.step.name = controlValues.name;
      }

      if (controlValues.reactionTime) {
        this.step.meta.reactionTime = controlValues.reactionTime;
      }

      if (controlValues.reactionType) {
        this.step.meta.reactionType = controlValues.reactionType;
      }

      if (controlValues.eventType) {
        this.step.meta.event = controlValues.eventType;
      }

      return this.step;
    }),
  );

  readonly MAX_REACTION_TIME = 90 * 24 * 60 * 60;
  protected readonly TRIGGER_CHAIN_REACTION_TYPE = TRIGGER_CHAIN_REACTION_TYPE;
  protected readonly TRIGGER_CHAIN_REACTION_TYPES = TRIGGER_CHAIN_REACTION_TYPES;

  constructor(
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    public readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isValidationStrict.currentValue) {
      let { reactionType } = this.form.controls;

      if (reactionType.getRawValue() === null) {
        this.addOptionalValidators();
      }
    }
  }

  addOptionalValidators(): void {
    let { reactionType } = this.form.controls;

    reactionType.addValidators([Validators.required]);
    reactionType.updateValueAndValidity();
    reactionType.markAsTouched();
  }

  getBlockTypeIconClass(type: TRIGGER_CHAIN_REACTION_TYPE): string {
    switch (type) {
      case TRIGGER_CHAIN_REACTION_TYPE.READ:
        return 'cqi-check-read';
      case TRIGGER_CHAIN_REACTION_TYPE.CLICKED:
        return 'cqi-link';
      case TRIGGER_CHAIN_REACTION_TYPE.REPLIED:
        return 'cqi-arrow-from-top-to-right';
      case TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE:
        return 'cqi-code';
    }
  }

  deleteStepChain() {
    this.editorInteractions.deleteClick.next(this.step);
  }

  copyStepChain() {
    this.editorInteractions.copyClick.next(this.step);
  }

  removeOptionalValidator(): void {
    let { reactionType } = this.form.controls;

    reactionType.clearValidators();
    reactionType.updateValueAndValidity();
  }

  eventTypesChanged(eventTypes: EventType[]) {
    this.triggerChainEditorStore.properties$.next({
      ...this.triggerChainEditorStore.properties$.getValue(),
      eventTypes,
    });
  }
}
