import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Sortable, { Options } from 'sortablejs';

import { AppService } from '@http/app/services/app.service';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { SAVED_REPLY_ACCESS_TYPE, SavedReply } from '@http/saved-reply/saved-reply.types';
import { ConversationsSettingsPageStore } from '@panel/app/pages/conversations-settings/conversations-settings-page.store';

@Component({
  selector: 'cq-saved-replies-list',
  templateUrl: './saved-replies-list.component.html',
  styleUrls: ['./saved-replies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedRepliesListComponent implements OnInit {
  // Список сохранённых ответов
  @Input({ required: true }) savedReplies: SavedReply[] = [];

  @Input() isSearch: boolean = false;

  @Input({ required: true, transform: booleanAttribute }) sortable!: boolean;

  // Колбэк при смене приоритета сохранённого ответа
  @Output() savedReplyOrderChanged = new EventEmitter<{ savedReply: SavedReply; newOrder: SavedReply['order'] }>();

  // Колбэк при редактировании сохранённого ответа
  @Output() savedReplyEdited = new EventEmitter();

  // Колбэк при удалении сохранённого ответа
  @Output() savedReplyRemoved = new EventEmitter<SavedReply>();

  // Нижняя область для scrolling'а при перетаскивании через drag&drop
  @ViewChild('bottomScrollZone') bottomScrollZone!: ElementRef;

  // Вернхяя область для scrolling'а при перетаскивании через drag&drop
  @ViewChild('topScrollZone') topScrollZone!: ElementRef;

  // Таблица с сохраненными ответами
  @ViewChild('savedRepliesTable') savedRepliesTable!: ElementRef;

  /**
   * Id html-node контейнера, который скролится при перетаскивании сохраненных ответов
   */
  CONTENT_NODE_ID: string = 'content';

  /**
   * HTML-node контейнера, который скролится при перетаскивании сохраненных ответов
   */
  contentNode!: HTMLElement;

  /**
   * Настройки для библиотеки sortablejs
   */
  sortablejsOptions!: Options;

  /** Является ли текущий пользователь оператором */
  isOperator: boolean = this.djangoUserModel.isOperator(this.appService.currentAppId, this.store.djangoUser);

  protected readonly SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE;

  constructor(
    private readonly appService: AppService,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly store: ConversationsSettingsPageStore,
  ) {}

  ngOnInit(): void {
    this.contentNode = document.getElementById(this.CONTENT_NODE_ID)!;

    this.sortablejsOptions = {
      handle: '.handle-icon',
      onChoose: this.onChoose.bind(this),
      onUnchoose: this.onUnchoose.bind(this),
      onUpdate: this.onUpdate.bind(this),
      disabled: !this.sortable,
    };
  }

  /**
   * Редактирование сохранённого ответа
   *
   * @param {SavedReply} savedReply — Редактируемый сохранённый ответ
   */
  editSavedReply(savedReply: SavedReply): void {
    this.savedReplyEdited.emit(savedReply);
  }

  getHotkeyForReply(savedReply: SavedReply, index: number) {
    if (savedReply.accessType === SAVED_REPLY_ACCESS_TYPE.PERSONAL) {
      return `Alt + ${index + 1}`;
    }

    return `Ctrl + ${index + 1}`;
  }

  /**
   * Обработчик "захвата" перетаскиваемого сохраненного ответа
   */
  onChoose(): void {
    let lastSavedReply =
      this.savedRepliesTable.nativeElement.rows[this.savedRepliesTable.nativeElement.rows.length - 1];

    if (this.contentNode.scrollTop >= this.topScrollZone.nativeElement.parentElement.offsetTop) {
      this.topScrollZone.nativeElement.classList.add('show');
    }

    if (
      this.contentNode.scrollTop + this.contentNode.clientHeight <
      lastSavedReply.offsetTop + lastSavedReply.clientHeight + this.savedRepliesTable.nativeElement.offsetTop
    ) {
      this.bottomScrollZone.nativeElement.classList.add('show');
    }
  }

  /**
   * Обработчик "отпускания" перетаскиваемого сохраненного ответа
   */
  onUnchoose(): void {
    this.topScrollZone.nativeElement.classList.remove('show');
    this.bottomScrollZone.nativeElement.classList.remove('show');
  }

  /**
   * Колбэк, срабатывающий при смене позиции ответа в списке после drag-n-drop
   *
   * @param event
   */
  onUpdate(event: Sortable.SortableEvent): void {
    let newOrder: SavedReply['order'];
    if (event.oldIndex! > event.newIndex!) {
      newOrder = this.savedReplies[event.newIndex! + 1].order;
    } else {
      newOrder = this.savedReplies[event.newIndex! - 1].order;
    }
    this.savedReplyOrderChanged.emit({
      savedReply: this.savedReplies[event.newIndex!],
      newOrder: newOrder,
    });
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param {SavedReply} savedReply — Удаляемый сохранённый ответ
   */
  removeSavedReply(savedReply: SavedReply): void {
    this.savedReplyRemoved.emit(savedReply);
  }

  /**
   * Функция для trackBy
   *
   * @param index
   * @param item
   */
  trackById(index: number, item: SavedReply): string {
    return item.id;
  }
}
