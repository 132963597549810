export enum TRIGGER_CHAIN_REACTION_TYPE {
  READ = 'read',
  CLICKED = 'clicked',
  REPLIED = 'replied',
  EVENT_TYPE = 'event_type',
}

export const TRIGGER_CHAIN_REACTION_TYPES: TRIGGER_CHAIN_REACTION_TYPE[] = Object.values(TRIGGER_CHAIN_REACTION_TYPE);

export enum TRIGGER_CHAIN_ACTION_TYPE {
  EVENT_TYPE = 'event_type',
  PROPERTY = 'property',
  USER_TAG = 'user_tag',
}

export const TRIGGER_CHAIN_ACTION_TYPES: TRIGGER_CHAIN_ACTION_TYPE[] = Object.values(TRIGGER_CHAIN_ACTION_TYPE);
