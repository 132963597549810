<div
  class="d-flex align-items-center pt-15"
  [ngClass]="{ 'pb-15': goalEventSwitch }"
>
  <cq-switch-new
    [(ngModel)]="goalEventSwitch"
    (ngModelChange)="changeGoalEventSwitch($event)"
  ></cq-switch-new>
  <div class="white-space-nowrap ml-15">
    <h5 class="d-inline-block font-weight-bold mb-0">{{ 'autoMessages.editor.goal.heading' | transloco }}</h5>
    <i
      class="cqi-sm cqi-question-circle"
      [ngbTooltip]="'autoMessages.editor.goal.headingTooltip' | transloco: { messageTypeGroup: messagePartTypeName }"
    ></i>
  </div>
</div>
<div
  *ngIf="goalEventSwitch"
  class="ml-50"
>
  <div class="text-secondary mb-15">
    {{ 'autoMessages.editor.goal.description' | transloco: { messageTypeGroup: messagePartTypeName } }}
  </div>
  <div class="form-group flex">
    <ng-select
      class="flex-grow"
      [clearable]="false"
      [formControl]="formGroup.controls.goalEventType"
      [items]="sortedEventTypes"
      [placeholder]="'autoMessages.editor.goal.eventTypesSelect.placeholder' | transloco"
      [searchable]="true"
      (change)="setGoalEventCostProp($event)"
      bindLabel="prettyName"
      bindValue="id"
    >
      <ng-template
        ng-label-tmp
        ng-option-tmp
        let-eventType="item"
      >
        <div>
          <span
            [ngClass]="{ 'text-muted': !eventType.active }"
            [innerHTML]="eventType.prettyName"
          ></span>

          <i
            *ngIf="!eventType.active"
            class="cqi-sm cqi-triangle-exclamation-o margin-right-5 text-danger"
            [ngbTooltip]="'autoMessages.editor.goal.eventTypesSelect.unusedEventTooltip' | transloco"
            container="body"
            placement="top"
          ></i>
        </div>
      </ng-template>
    </ng-select>
    <button
      *ngIf="currentMessage.goalEventType"
      class="btn btn-outline-primary margin-left-10"
      type="button"
      (click)="removeGoal()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
  <div
    [ngbCollapse]="!this.currentMessage.goalEventType"
    (ngbCollapseChange)="this.currentMessage.goalEventType = $event"
  >
    <div class="mb-20">
      <div class="mb-15 font-weight-bold">
        {{ 'autoMessages.editor.goal.eventTypesSelect.goalEvent.heading' | transloco }}
      </div>
      <div class="mb-15">{{ 'autoMessages.editor.goal.eventTypesSelect.goalEvent.description' | transloco }}</div>
      <div class="mb-15">
        <div class="btn-group btn-group-toggle">
          <label
            class="btn btn-outline-primary"
            [class.active]="currentMessage.goalEventCostType === 'none'"
          >
            <input
              [formControl]="formGroup.controls.goalEventCostType"
              type="radio"
              value="none"
            />
            <span>{{
              'autoMessages.editor.goal.eventTypesSelect.goalEvent.goalEventCostTypeRadioGroup.noneRadio.label'
                | transloco
            }}</span>
          </label>
          <label
            class="btn btn-outline-primary"
            [class.active]="currentMessage.goalEventCostType === 'eventField'"
          >
            <input
              [formControl]="formGroup.controls.goalEventCostType"
              type="radio"
              value="eventField"
            />
            <span>{{
              'autoMessages.editor.goal.eventTypesSelect.goalEvent.goalEventCostTypeRadioGroup.eventFieldRadio.label'
                | transloco
            }}</span>
          </label>
          <label
            class="btn btn-outline-primary"
            [class.active]="currentMessage.goalEventCostType === 'manual'"
          >
            <input
              [formControl]="formGroup.controls.goalEventCostType"
              type="radio"
              value="manual"
            />
            <span>{{
              'autoMessages.editor.goal.eventTypesSelect.goalEvent.goalEventCostTypeRadioGroup.manualRadio.label'
                | transloco
            }}</span>
          </label>
        </div>
      </div>
      <div
        *ngIf="currentMessage.goalEventCostType === 'eventField'"
        class="row no-margin"
      >
        <div class="mb-15">
          {{ 'autoMessages.editor.goal.eventTypesSelect.goalEvent.eventField.description' | transloco }}
        </div>
        <div
          class="event-property form-group no-margin-bottom"
          [cqShowError]="formGroup.controls.goalEventCostProp"
        >
          <input
            [formControl]="formGroup.controls.goalEventCostProp"
            class="form-control"
            placeholder="{{
              'autoMessages.editor.goal.eventTypesSelect.goalEvent.eventField.costPropInput.placeholder' | transloco
            }}"
          />

          <cq-validation-messages [control]="formGroup.controls.goalEventCostProp">
            <cq-validation-message errorName="required">
              {{
                'autoMessages.editor.goal.eventTypesSelect.goalEvent.eventField.costPropInput.errors.required'
                  | transloco
              }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
      <div
        *ngIf="currentMessage.goalEventCostType === 'manual'"
        class="form-inline"
      >
        <div
          class="form-group margin-right-5"
          [cqShowError]="formGroup.controls.goalEventCost"
        >
          <div>
            <input
              cqFloatNew
              [formControl]="formGroup.controls.goalEventCost"
              class="form-control time"
            />
            <span class="align-middle">{{ currencySymbol }}</span>
          </div>

          <cq-validation-messages [control]="formGroup.controls.goalEventCost">
            <cq-validation-message errorName="required">
              {{ 'autoMessages.editor.goal.eventTypesSelect.goalEvent.manual.coastInput.errors.required' | transloco }}
            </cq-validation-message>
            <cq-validation-message errorName="min">
              {{ 'autoMessages.editor.goal.eventTypesSelect.goalEvent.manual.coastInput.errors.min' | transloco }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
    </div>
    <div>
      <div class="mb-15 font-weight-bold">
        {{ 'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.heading' | transloco }}
      </div>
      <div class="mb-15">
        {{ 'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.description' | transloco }}
      </div>
      <div class="flex align-items-center mb-20">
        <div
          class="mr-10"
          [cqShowError]="formGroup.controls.goalEventTimeout"
        >
          <input
            class="form-control time"
            cqIntegerNew
            [formControl]="formGroup.controls.goalEventTimeout"
          />
          <cq-validation-messages [control]="formGroup.controls.goalEventTimeout">
            <cq-validation-message errorName="required">
              {{
                'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.timeoutInput.errors.required' | transloco
              }}
            </cq-validation-message>
            <cq-validation-message errorName="min">
              {{ 'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.timeoutInput.errors.min' | transloco }}
            </cq-validation-message>
            <cq-validation-message errorName="max">
              {{
                'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.timeoutInput.errors.max'
                  | transloco: { maxValue: GOAL_EVENT_MAX_TIMEOUT }
              }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <div [cqShowError]="formGroup.controls.goalEventTimeoutTimeUnit">
          <ng-select
            class="time-units"
            [clearable]="false"
            [formControl]="formGroup.controls.goalEventTimeoutTimeUnit"
            [items]="currentMessage.goalEventTimeoutTimeUnits"
            [placeholder]="'autoMessages.editor.goal.eventTypesSelect.placeholder' | transloco"
            [searchable]="false"
          >
            <ng-template
              ng-label-tmp
              ng-option-tmp
              let-timeUnit="item"
            >
              <div>
                <span
                  [innerHTML]="
                    'services.timeUnit.units.' + timeUnit + '.full'
                      | transloco
                        : {
                            count:
                              currentMessage.goalEventTimeout /
                              TIME_UNIT_MEASURES[currentMessage.goalEventTimeoutTimeUnit],
                          }
                  "
                ></span>
              </div>
            </ng-template>
          </ng-select>
          <cq-validation-messages [control]="formGroup.controls.goalEventTimeoutTimeUnit">
            <cq-validation-message errorName="required">
              {{
                'autoMessages.editor.goal.eventTypesSelect.goalEventTimeout.timeUnitSelect.errors.required' | transloco
              }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
    </div>
  </div>
</div>
