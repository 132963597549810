import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { PropertyActionComponent } from './property-action.component';

@NgModule({
  declarations: [PropertyActionComponent],
  exports: [PropertyActionComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, PropertySelectorModule, SharedModule],
})
export class PropertyActionModule {}
