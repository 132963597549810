import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, forkJoin, Subject, tap } from 'rxjs';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';

import { AiDataModel } from '@http/ai-data/ai-data.model';
import { AiDataCrawlingPage } from '@http/ai-data/internal-types';
import { PaginationParamsRequest } from '@http/types';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

enum PAGES_ACTIONS {
  ADD_NEW,
  ADD_NEW_AND_UPDATE_EXISTING,
  DO_NOT_ADD,
  UPDATE_EXISTING,
}

@Component({
  selector: 'cq-add-crawled-pages-modal',
  templateUrl: './add-crawled-pages-modal.component.html',
  styleUrls: ['./add-crawled-pages-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AddCrawledPagesModalComponent implements OnInit {
  /** Флаг загрузки пачек страниц */
  isPagesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  /** Флаг выполнения запрос на сохранение страниц */
  isRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Поток подгрузки следующей пачки новых накрауленных страниц */
  loadMoreNewPages$: Subject<void> = new Subject();
  /** Поток подгрузки следующей пачки уже существующих страниц */
  loadMoreExistingPages$: Subject<void> = new Subject();

  paginatorParamsForNewPages!: PaginationParamsRequest;
  paginatorParamsForExistingPages!: PaginationParamsRequest;

  newPages: AiDataCrawlingPage[] = [];
  existingPages: AiDataCrawlingPage[] = [];
  crawledUrl: string = '';
  totalUrls: number = 0;

  selectAllPagesCheckbox: boolean = true;

  PAGES_ACTIONS = PAGES_ACTIONS;

  constructor(
    readonly activeModal: NgbActiveModal,
    private readonly aiDataModel: AiDataModel,
    private readonly destroy$: DestroyService,
    private readonly modalHelperService: ModalHelperService,
    private readonly state: AiDataSourcesListStateService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.loadInitialPages();
    this.setupLoadMoreHandlers();
  }

  /** Загрузка начальной пачки страниц */
  private loadInitialPages() {
    forkJoin([
      this.aiDataModel.getCrawlingResult(false, this.paginatorParamsForNewPages).pipe(
        tap(({ paginatorParams, result }) => {
          this.paginatorParamsForNewPages = paginatorParams ?? null;
          this.crawledUrl = result.startUrl;
          this.totalUrls = result.totalUrlsFound;
        }),
      ),
      this.aiDataModel.getCrawlingResult(true, this.paginatorParamsForExistingPages).pipe(
        tap(({ paginatorParams, result }) => {
          this.paginatorParamsForExistingPages = paginatorParams ?? null;
        }),
      ),
    ])
      .pipe(
        take(1),
        finalize(() => this.isPagesLoading$.next(false)),
      )
      .subscribe((response) => {
        this.newPages = response[0].result.result;
        this.existingPages = response[1].result.result;
      });
  }

  /** Навешивание обработчиков для подгрузки следующих пачек страниц */
  private setupLoadMoreHandlers() {
    this.loadMoreNewPages$
      .pipe(
        tap(() => this.isPagesLoading$.next(true)),
        switchMap(() =>
          this.aiDataModel.getCrawlingResult(false, this.paginatorParamsForNewPages).pipe(
            tap(({ paginatorParams, result }) => {
              this.paginatorParamsForNewPages = paginatorParams ?? null;
            }),
            finalize(() => this.isPagesLoading$.next(false)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.newPages = [...this.newPages, ...response.result.result];
      });

    this.loadMoreExistingPages$
      .pipe(
        tap(() => this.isPagesLoading$.next(true)),
        switchMap(() =>
          this.aiDataModel.getCrawlingResult(true, this.paginatorParamsForExistingPages).pipe(
            tap(({ paginatorParams, result }) => {
              this.paginatorParamsForExistingPages = paginatorParams ?? null;
            }),
            finalize(() => this.isPagesLoading$.next(false)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.existingPages = [...this.existingPages, ...response.result.result];
      });
  }

  /** Нужно ли показывать кнопку дозагрузки новых накрауленных страниц */
  get canLoadMoreNewPages(): boolean {
    return !!this.paginatorParamsForNewPages && this.paginatorParamsForNewPages.paginatePosition !== null;
  }

  /** Нужно ли показывать кнопку дозагрузки существующих страниц */
  get canLoadMoreExistingPages(): boolean {
    return !!this.paginatorParamsForExistingPages && this.paginatorParamsForExistingPages.paginatePosition !== null;
  }

  get totalCheckedPagesNumber() {
    return this.selectAllPagesCheckbox ? this.totalUrls : this.newPages.filter((page) => page.checked).length;
  }

  /** Есть ли выбранные страницы */
  hasCheckedPages(): boolean {
    return this.totalCheckedPagesNumber > 0;
  }

  /** Подгрузка следующей пачки существующих в источниках страниц */
  loadMoreExistingPages() {
    this.loadMoreExistingPages$.next();
  }

  /** Подгрузка следующей пачки новых скрауленных страниц */
  loadMoreNewPages() {
    this.loadMoreNewPages$.next();
  }

  /** Обработчик для чекбока «Выбрать все страницы» */
  onSelectAllPagesCheckboxChange() {
    this.newPages.forEach((page) => {
      page.checked = this.selectAllPagesCheckbox;
    });
  }

  /** Открытие подтверждающей модалки об отказе добавлять найденные страницы */
  private openDoNotAddAnyPagesConfirmModal() {
    return this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('addCrawledSitesModalComponent.doNotAddAnyPagesConfirmModal.heading'),
        body: this.translocoService.translate('addCrawledSitesModalComponent.doNotAddAnyPagesConfirmModal.body'),
        confirmButtonText: this.translocoService.translate(
          'addCrawledSitesModalComponent.doNotAddAnyPagesConfirmModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent, {
        centered: true,
      });
  }

  /**
   * Сохранение страниц в качестве источников контента
   *
   * @param action Производимое действие над страницами
   */
  processPages(action: PAGES_ACTIONS) {
    const selectedPages: string[] = this.selectAllPagesCheckbox
      ? []
      : this.newPages.filter((page) => page.checked).map((page) => page.id);

    switch (action) {
      case PAGES_ACTIONS.ADD_NEW:
        this.aiDataModel
          .addContentSources(this.selectAllPagesCheckbox, selectedPages, false)
          .pipe(take(1))
          .subscribe(() => {
            this.showSuccessToast();
            this.onAddContentSources();
          });
        break;
      case PAGES_ACTIONS.ADD_NEW_AND_UPDATE_EXISTING:
        this.aiDataModel
          .addContentSources(this.selectAllPagesCheckbox, selectedPages, true)
          .pipe(take(1))
          .subscribe(() => {
            this.showSuccessToast();
            this.onAddContentSources();
          });
        break;
      case PAGES_ACTIONS.DO_NOT_ADD:
        this.openDoNotAddAnyPagesConfirmModal().result.then(() => {
          this.aiDataModel
            .deleteCrawlingResults()
            .pipe(take(1))
            .subscribe(() => {
              this.onAddContentSources();
            });
        });
        break;
      case PAGES_ACTIONS.UPDATE_EXISTING:
        this.aiDataModel
          .addContentSources(false, [], true)
          .pipe(take(1))
          .subscribe(() => {
            this.showSuccessToast();
            this.onAddContentSources();
          });
        break;
    }
  }

  private onAddContentSources() {
    this.state.isShowCrawlingBanner$.next(false);
    this.state.crawlingUrl$.next(null);
    this.state.crawlingStatus$.next(null);

    this.activeModal.close();
  }

  private showSuccessToast() {
    this.toastService.success(this.translocoService.translate('addCrawledSitesModalComponent.successToast'));
  }
}
