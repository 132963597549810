import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { BranchActionContainerModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/container/container.module';
import { SharedModule } from '@panel/app/shared/shared.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { BotBranchEditorComponent } from './branch-editor.component';
import { AppOnlineConditionEditorModule } from './partials/app-online-condition-editor/app-online-condition-editor.module';
import { BlockActionEditorModule } from './partials/block-action-editor/block-action-editor.module';
import { BlockBranchEditorModule } from './partials/block-branch-editor/block-branch-editor.module';
import { BranchNameEditorModule } from './partials/branch-name-editor/branch-name-editor.module';
@NgModule({
  declarations: [BotBranchEditorComponent],
  imports: [
    CommonModule,
    BranchNameEditorModule,
    LoaderModule,
    ReactiveFormsModule,
    OverlayscrollbarsModule,
    TranslocoModule,
    NgbPopoverModule,
    BlockBranchEditorModule,
    AppOnlineConditionEditorModule,
    BlockActionEditorModule,
    BranchActionContainerModule,
    SharedModule,
  ],
  exports: [BotBranchEditorComponent],
})
export class BotBranchEditorModule {}
