import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLet } from '@taiga-ui/cdk';

import { PlansComparisonModule } from '@panel/app/pages/subscription/pricing/plans-comparison/plans-comparison.module';
import { PricingComponent } from '@panel/app/pages/subscription/pricing/pricing.component';
import { PricingAddonsModule } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.module';
import { PricingPlanDescriptionModule } from '@panel/app/pages/subscription/pricing/pricing-plan-description/pricing-plan-description.module';
import { PricingPlanHeadingModule } from '@panel/app/pages/subscription/pricing/pricing-plan-heading/pricing-plan-heading.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [PricingComponent],
  exports: [PricingComponent],
  imports: [
    CommonModule,
    PlansComparisonModule,
    PricingAddonsModule,
    PricingPlanDescriptionModule,
    PricingPlanHeadingModule,
    SharedModule,
    TranslocoModule,
    TuiLet,
  ],
})
export class PricingModule {}
