<ng-container *transloco="let t; read: 'aiDataSourcesListComponent'">
  <ng-container
    *tuiLet="{
      isShowSettingsBanner: isShowSettingsBanner$ | async,
      isShowCrawlingBanner: state.isShowCrawlingBanner$ | async
    } as values"
  >
    <div class="d-flex justify-space-between align-items-start">
      <div [innerHTML]="t('nav.description')"></div>
      <a
        class="btn btn-borderless-primary"
        uiSref="app.content.aiDataSources.botSettings"
      >
        <i class="btn-icon-left cqi-sm cqi-cog"></i>
        {{ t('aiBotSettingsButton') }}</a
      >
    </div>

    <cq-ai-data-settings-banner
      *ngIf="values.isShowSettingsBanner"
      class="mt-20"
      (clickCloseButton)="closeSettingsBanner()"
    ></cq-ai-data-settings-banner>

    <cq-crawling-status-banner
      *ngIf="values.isShowCrawlingBanner"
      class="mt-20"
      (clickCloseBtn)="onClickCloseBtn()"
    ></cq-crawling-status-banner>

    <div class="mt-20">
      <div class="d-flex align-items-center mb-20">
        <h4 class="mb-0">{{ t('dataSources') }}</h4>
        <ng-container *ngIf="hasAccessToWebCrawler">
          <button
            class="btn btn-primary ml-auto"
            type="button"
            (click)="openStartCrawlingProcessModal()"
            [disabled]="values.isShowCrawlingBanner"
            [ngbTooltip]="t('crawlingInProgressTooltip')"
            [disableTooltip]="!values.isShowCrawlingBanner"
          >
            <i class="btn-icon-left cqi-sm cqi-plus"></i>
            {{ t('addDataSourceButton') }}
          </button>
        </ng-container>
      </div>
      <cq-ai-data-source-table></cq-ai-data-source-table>
    </div>
  </ng-container>
</ng-container>
