<ng-container *transloco="let t; read: 'editorFormatHeaderComponent'">
  <div ngbDropdown>
    <button
      type="button"
      class="btn btn-borderless-primary rounded-0"
      id="editorHeaderDropdown"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      container="body"
      triggers="hover:blur"
    >
      <i class="cqi-sm cqi-paragraph"></i>
    </button>
    <div
      ngbDropdownMenu
      aria-labelledby="editorHeaderDropdown"
    >
      <button
        type="button"
        ngbDropdownItem
        *ngFor="let header of EDITOR_FORMAT_HEADERS"
        (click)="onClickSetHeaderButton(header)"
      >
        <h1
          *ngIf="header === 1"
          class="m-0"
        >
          {{ t('value.1') }}
        </h1>

        <h2
          *ngIf="header === 2"
          class="m-0"
        >
          {{ t('value.2') }}
        </h2>

        <span *ngIf="header === false">{{ t('value.standard') }}</span>
      </button>
    </div>
  </div>
</ng-container>
