import { Injectable } from '@angular/core';
import { ui } from 'angular';

import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/** Сервис для работы с модальным окном "Напиши себе в интеграцию" */
@Injectable({ providedIn: 'root' })
export class WriteToIntegrationModalService {
  /** Инстанс модального окна */
  writeToIntegrationModalInstance: ui.bootstrap.IModalInstanceService | null = null;

  constructor(private readonly uibModalService: UibModalService) {}

  /** Скрытие модального окна */
  hideModal() {
    if (this.writeToIntegrationModalInstance) {
      this.writeToIntegrationModalInstance.close();
      this.writeToIntegrationModalInstance = null;
    }
  }

  /**
   * Открытие модального окна
   */
  showModal(currentApp: App, djangoUser: DjangoUser) {
    if (!this.writeToIntegrationModalInstance) {
      this.writeToIntegrationModalInstance = this.uibModalService.open({
        component: 'cqWriteToIntegrationsWrapper',
        size: 'md modal-dialog-centered',
      });

      this.writeToIntegrationModalInstance.result.finally(() => {
        this.writeToIntegrationModalInstance = null;
      });
    }
  }
}
