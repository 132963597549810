import { Injectable } from '@angular/core';

import { PlanIdService } from '@panel/app/services/billing/plan-id/plan-id.service';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

/** Help-сервис для работы с тарифным планом */
@Injectable({ providedIn: 'root' })
export class PlanHelpService {
  constructor(private l10nHelper: L10nHelperService, private readonly planIdService: PlanIdService) {}

  /**
   * Возвращает стоимость emails при превышении emails-квоты
   *
   * NOTE:
   *  Для US стоимость считается за 1000 emails, а для RU за 1 email
   *
   * @param costPer1 - Стоимость 1 email при перерасходе
   */
  getEmailsCostInCaseOfOverspending(costPer1: number): number {
    let overspendingCost;

    if (this.l10nHelper.isRusCountry()) {
      overspendingCost = costPer1;
    } else {
      overspendingCost = costPer1 * 1000;
    }

    return overspendingCost;
  }

  /** Является ли тарифный план архивным */
  isArchive(): boolean {
    const isV1122 = this.planIdService.isV1122();
    const isLTD2023 = this.planIdService.isLTD2023();
    const isTrial = this.planIdService.isTrial();

    return !isTrial && !isV1122 && !isLTD2023;
  }
}
