<ng-container *transloco="let t; read: 'userSubscriptionsComponent'">
  <div class="mb-0">
    <div
      (click)="collapse.toggle(); collapseStateChange.emit(userSubscriptionsLSName)"
      [attr.aria-expanded]="!isCollapsed"
      class="d-flex align-items-center cursor-pointer"
      aria-controls="collapse"
      data-test="user-subscriptions-collapse"
    >
      <div class="font-weight-bold flex-grow-1 padding-15">{{ t('title') }}</div>
      <div class="mr-5">{{ countOfSubscriptions() }}</div>
      <i
        [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        class="btn-icon cqi-sm mr-15"
      ></i>
    </div>
    <div
      #collapse="ngbCollapse"
      [ngbCollapse]="isCollapsed"
      (ngbCollapseChange)="changeCollapsed()"
    >
      <div class="margin-between-rows-15 px-15 pb-10">
        <ng-container
          *ngIf="
            isHasEmailSubscription() || isSdkPushSubscribed || isShowWebPush || telegramIntegrations.length > 0;
            else zeroData
          "
        >
          <div *ngIf="isHasEmailSubscription()">
            <div class="small text-muted">{{ t('email.title') }}</div>
            <div>{{ 'models.emailStatus.statuses.' + user.emailStatus?.status | transloco }}</div>
          </div>

          <div *ngIf="isSdkPushSubscribed">
            <div class="mb-10 small font-weight-bold">{{ t('inAppPushNotifications.title') }}</div>
            <div class="d-flex align-items-center justify-content-between mb-10">
              <div>
                <div class="small text-muted">{{ t('inAppPushNotifications.chatMessages.title') }}</div>
                <div>{{ getChatMessagesSubscriptionStatusTranslate() }}</div>
              </div>
              <button
                class="btn btn-text-primary"
                type="button"
                (click)="openUnsubscribeSdkChatMessageNotificationsModal()"
                [disabled]="!isSdkPushNotificationsSubscribed"
              >
                <i class="btn-icon cqi-sm cqi-minus-circle"></i>
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <div class="small text-muted">{{ t('inAppPushNotifications.campaigns.title') }}</div>
                <div>{{ getCampaignsSubscriptionStatusTranslate() }}</div>
              </div>
              <button
                class="btn btn-text-primary"
                type="button"
                (click)="openUnsubscribeSdkCampaignsNotificationsModal()"
                [disabled]="!isSdkPushCampaignsSubscribed"
              >
                <i class="btn-icon cqi-sm cqi-minus-circle"></i>
              </button>
            </div>
          </div>

          <div *ngIf="!isOperator">
            <div class="mb-10 small font-weight-bold">{{ t('telegramSubscriptions.title') }}</div>
            <div
              *ngFor="let tgIntegration of telegramIntegrations; trackBy: trackById"
              class="d-flex align-items-center justify-content-between mb-10"
            >
              <div>
                <div class="small text-secondary">{{'@' + tgIntegration.settings.botName }}</div>
                <div *ngIf="isSubscribedTo(tgIntegration); else notSubscribed">
                  {{ t('telegramSubscriptions.subscribed') }}
                </div>
                <ng-template #notSubscribed
                  ><div>{{ t('telegramSubscriptions.notSubscribed') }}</div></ng-template
                >
              </div>
            </div>
          </div>

          <!-- NOTE: Web-push'и не доступны в США и для app'ов, которые были зарегистрированы после END_TIME_FEATURES.WEB_PUSH -->
          <div
            *ngIf="isShowWebPush"
            cq-hide-in-us
          >
            <div class="mb-10 small font-weight-bold">{{ t('webPush.title') }}</div>
            <div class="mb-5 small text-muted">{{ t('webPush.subscribedDevices') }}</div>
            <div
              *ngFor="let push of webPushSubscriptions"
              data-test="user-subscriptions-web-push"
            >
              <i
                *ngIf="push.deviceType === 'mobile'"
                class="cqi-sm cqi-mobile"
                [ngbTooltip]="t('webPush.deviceType.phone')"
                container="body"
              ></i>
              <i
                *ngIf="push.deviceType === 'tablet'"
                class="cqi-sm cqi-mobile"
                [ngbTooltip]="t('webPush.deviceType.tablet')"
                container="body"
              ></i>
              <i
                *ngIf="push.deviceType === 'pc'"
                class="cqi-sm cqi-desktop"
                [ngbTooltip]="t('webPush.deviceType.pc')"
                container="body"
              ></i>
              <span>{{ push.browser }}</span>
            </div>
            <button
              class="btn btn-text-primary text-primary mt-5"
              (click)="openEditPushSubscriptionsModal(webPushSubscriptions)"
              data-test="user-card-user-email-edit-button"
              type="button"
            >
              {{ t('webPush.editSubscriptionsButton') }}
            </button>
          </div>
        </ng-container>
        <ng-template #zeroData>
          <div class="text-muted">{{ t('zeroData') }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
