import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { SERVICE_BILLING_INFO_PAGE_ROUTES } from '@panel/app/pages/service-billing-info/service-billing-info.routes';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ServiceBillingInfoComponent } from './service-billing-info.component';

@NgModule({
  declarations: [ServiceBillingInfoComponent],
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: SERVICE_BILLING_INFO_PAGE_ROUTES }),
    FormsModule,
    TranslocoModule,
    SharedModule,
    NgbCollapseModule,
    NgbAlertModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class ServiceBillingInfoModule {}
