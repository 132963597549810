import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UUID } from 'short-uuid';

import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { Connection } from '@panel/app/services/canvas/tirgger-chain/connections/connection.types';

type ValidationExtra = {
  connections: Connection[];
};

@Injectable()
export class ConnectionValidationService {
  constructor(private readonly transloco: TranslocoService) {}

  validate(source: IBlockView, target: IBlockView, extraData: ValidationExtra): string | null {
    const sourceName = this.transloco.translate(`triggerChainBlock.type.${source.type}`);
    const targetName = this.transloco.translate(`triggerChainBlock.type.${target.type}`);

    if (source.type === TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION) {
      if (!this.isAutoMessage(target)) {
        return this.transloco.translate('triggerChainConnection.errors.sendingConditionToWrongBlockType');
      }
    }

    if (target.type === TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION) {
      return this.transloco.translate('triggerChainConnection.errors.forbiddenTarget', {
        target: targetName,
      });
    }

    if (target.type === TRIGGER_CHAIN_BLOCK_TYPE.REACTION) {
      if (!(this.isAutoMessage(source) || source.type === TRIGGER_CHAIN_BLOCK_TYPE.ACTION)) {
        return this.transloco.translate('triggerChainConnection.errors.forbiddenSourceTypeToReactionType', {
          target: targetName,
        });
      }
    }

    if (this.hasDetectedCycleConnection(source, target, extraData.connections)) {
      return this.transloco.translate('triggerChainConnection.errors.noCyclesAllowed');
    }

    return null;
  }

  private isAutoMessage(target: IBlockView) {
    return [
      TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
      TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
      TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
      TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
      TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
      TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
    ].includes(target.type);
  }

  private hasDetectedCycleConnection(source: IBlockView, target: IBlockView, connections: Connection[]) {
    const checkCycle = (graph: Record<string, UUID[]>, node: UUID, visited: Set<UUID>) => {
      if (visited.has(node)) {
        return true;
      }

      visited.add(node);

      for (const nextBlockUUID of graph[node] || []) {
        if (checkCycle(graph, nextBlockUUID, visited)) {
          return true;
        }
      }

      visited.delete(node);
      return false;
    };

    const graph: Record<string, UUID[]> = {};

    // Создаем граф из существующих связей
    for (const connection of connections) {
      if (!graph[connection.source.stepView.uuid]) {
        graph[connection.source.stepView.uuid] = [];
      }
      if (!graph[connection.target.uuid]) {
        graph[connection.target.uuid] = [];
      }

      graph[connection.source.stepView.uuid].push(connection.target.uuid);
    }

    // Добавляем новую связь
    if (!graph[source.uuid]) {
      graph[source.uuid] = [];
    }

    graph[source.uuid].push(target.uuid);

    // Проверяем наличие циклов во временном графе
    const visited: Set<UUID> = new Set();
    if (checkCycle(graph, source.uuid, visited)) {
      return true;
    }

    return false;
  }
}
