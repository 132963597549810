import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ActionHeaderComponent } from './action-header.component';

@NgModule({
  declarations: [ActionHeaderComponent],
  exports: [ActionHeaderComponent],
  imports: [CommonModule, NgbDropdownModule, TranslocoModule],
})
export class ActionHeaderModule {}
