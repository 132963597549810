(function () {
  'use strict';

  require('./scheduled-message-editor.controller');

  angular.module('myApp.scheduledMessages').component('cqScheduledMessageEditor', cqScheduledMessageEditor());

  function cqScheduledMessageEditor() {
    return {
      bindings: {
        activeMessagesAmounts: '<', // Массив с количеством активных триггерных сообщений по типам
        //autoEvents: '=', // Автособытия
        //amocrmIntegrations: '=', // список AmoCRM интеграций
        telegramIntegrations: '=', // список Telegram интеграций
        emailDomains: '=', // список емейл доменов
        billingInfo: '=', // Информация о биллинге
        currentApp: '=', // текущее приложение
        djangoUser: '=', // Текущий пользователь
        message: '=', // редактируемое сообщение
        //emailNotifications: '=', // Список интеграций нотификации
        //emailRecipients: '=', // Получатели email-оповещений
        //firstStepCallback: '&?', // Callback для вызова в случае если мы находимся на первом шаге визарда
        //isEdit: '=', // редактируется ли триггерное сообщение (если false - создаётся новое)
        //isHideForNewOnboarding: '=?', // Флаг для скрывания элементов для нового онбординга
        //messageSenders: '=', // список отправителей
        //planCapabilities: '=', // Список ограничений по тарифу
        properties: '=', // список свойств и типов событий
        segments: '<', // сегменты
        //setBackwardHandler: '&?', // Обработчик для перемещения назад по шагам визарда из вне
        //setForwardHandler: '&?', // Обработчик для перемещения вперед по шагам визарда из вне
        tags: '<', // теги пользователей
        //teamMembers: '=', // Список операторов
        //usedTemplate: '=', // шаблон, если триггерное сообщение создаётся на его основе
      },
      controller: 'CqScheduledMessageEditorController',
      controllerAs: 'vm',
      template: require('./scheduled-message-editor.html'),
    };
  }
})();
