import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { AI_DATA_CRAWLING_STATUS } from '@http/ai-data/internal-types';
import { AiDataActionModalService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';

/**
 * Баннер для отображения статуса процесса краулинга веб-страниц
 */
@Component({
  selector: 'cq-crawling-status-banner',
  templateUrl: './crawling-status-banner.component.html',
  styleUrls: ['./crawling-status-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrawlingStatusBannerComponent {
  @Output()
  clickCloseBtn: EventEmitter<void> = new EventEmitter<void>();

  protected readonly AI_DATA_CRAWLING_STATUS = AI_DATA_CRAWLING_STATUS;

  constructor(
    readonly state: AiDataSourcesListStateService,
    private readonly aiDataActionModalService: AiDataActionModalService,
  ) {}

  openAddCrawledPagesModal() {
    this.aiDataActionModalService.openAddCrawledPagesModal();
  }
}
