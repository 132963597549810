import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UibModalServiceProvider } from '@panel/app/ajs-upgraded-providers';

import { CompletionRegistrationModalComponent } from './completion-registration-modal.component';

@NgModule({
  declarations: [CompletionRegistrationModalComponent],
  exports: [CompletionRegistrationModalComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [NgbActiveModal, UibModalServiceProvider],
})
export class CompletionRegistrationModalModule {}
