import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { EMAIL_STATUSES } from '@http/email-status/email-status.constants';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';

@Component({
  selector: 'cq-email-operation-select-wrap',
  templateUrl: './email-operation-select-wrap.component.html',
  styleUrls: ['./email-operation-select-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class EmailOperationSelectWrapComponent implements OnInit {
  @Input()
  set value(value: EMAIL_STATUSES | undefined) {
    if (!value) {
      return;
    }

    this.control.setValue(
      {
        ...this.control.value,
        type: ELASTICSEARCH_OPERATION.STR_EQ,
        value: { value },
      },
      { emitEvent: true },
    );
  }

  @Input()
  set type(val: ELASTICSEARCH_OPERATION) {
    this.control.setValue({
      ...this.control.value,
      type: val,
    });
  }

  control = new FormControl(
    {
      type: ELASTICSEARCH_OPERATION.STR_EQ,
      value: {
        value: EMAIL_STATUSES.CONFIRMED,
      },
    },
    { nonNullable: true },
  );

  @Output()
  valueChange = this.control.valueChanges;

  @Output()
  filterValid = new Subject<() => boolean>();

  constructor(
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
  ) {}

  ngOnInit(): void {
    this.filterValid.next(() => {
      this.formSubmitSubject.next();
      return this.control.valid;
    });
    this.control.setValue(this.control.value);
  }
}
