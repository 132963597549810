import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  TriggerChainExternal,
  TriggerChainListItemExternal,
  TriggerChainStepsListExternal,
} from '@panel/app/http/trigger-chain/external-types';
import {
  TriggerChain,
  TriggerChainListItem,
  TriggerChainMessageStep,
} from '@panel/app/http/trigger-chain/internal-types';
import { TriggerChainMapper, TriggerChainParseToInternalExtraData } from '@panel/app/http/trigger-chain/mappers';
import { CHAIN_STATUS } from '@panel/app/pages/trigger-chains/list/trigger-chain-list.constants';

const TRIGGER_CHAIN_BASE_URL = '/trigger_chains';

@Injectable({ providedIn: 'root' })
export class TriggerChainModel {
  constructor(
    private readonly http: HttpClient,
    private readonly triggerChainMapper: TriggerChainMapper,
  ) {}

  changeActiveStatus(
    id: string,
    active: boolean,
    ignoreErrors: boolean = false,
  ): Observable<TriggerChainListItemExternal> {
    return this.http
      .post<TriggerChainListItemExternal>(`${TRIGGER_CHAIN_BASE_URL}/${id}/set_active`, {
        active,
        ignoreErrors,
      })
      .pipe(map((response) => response));
  }

  create(triggerChain: TriggerChain): Observable<TriggerChain> {
    const triggerChainExternal = this.triggerChainMapper.parseToExternal(triggerChain);
    return this.http.post<TriggerChain>(TRIGGER_CHAIN_BASE_URL, triggerChainExternal);
  }

  get(id: string, extraData: TriggerChainParseToInternalExtraData): Observable<TriggerChain> {
    return this.http.get<TriggerChainExternal>(`${TRIGGER_CHAIN_BASE_URL}/${id}`, {}).pipe(
      map((response) => {
        return this.triggerChainMapper.parseToInternal(response, extraData);
      }),
    );
  }

  remove(id: string): Observable<TriggerChainListItemExternal> {
    return this.http.delete<TriggerChainListItemExternal>(`${TRIGGER_CHAIN_BASE_URL}/${id}`);
  }

  getAll(status: CHAIN_STATUS, extraData: TriggerChainParseToInternalExtraData): Observable<TriggerChainListItem[]> {
    const parsedStatus = this.triggerChainMapper.parseChainStatusToExternal(status);

    const params: Record<string, string | number | boolean> = {};

    if (parsedStatus !== null) {
      params['active'] = parsedStatus;
    }

    return this.http.get<TriggerChainListItemExternal[]>(TRIGGER_CHAIN_BASE_URL, { params }).pipe(
      map((response): TriggerChainListItem[] => {
        return response.map((chain) => this.triggerChainMapper.parseChainListToInternal(chain, extraData));
      }),
    );
  }

  getStepList(id: string): Observable<TriggerChainMessageStep[]> {
    const params = new HttpParams({
      fromObject: {
        include_stats: true,
      },
    });

    return this.http.get<TriggerChainStepsListExternal>(`${TRIGGER_CHAIN_BASE_URL}/${id}/messages`, { params }).pipe(
      map((response) => {
        return this.triggerChainMapper.parseChainStepListToInternal(response);
      }),
    );
  }

  update(
    id: string,
    triggerChain: TriggerChain,
    extraData: TriggerChainParseToInternalExtraData,
  ): Observable<TriggerChain> {
    const triggerChainExternal = this.triggerChainMapper.parseToExternal(triggerChain);

    return this.http.put<TriggerChainExternal>(`${TRIGGER_CHAIN_BASE_URL}/${id}`, triggerChainExternal).pipe(
      map((response) => {
        return this.triggerChainMapper.parseToInternal(response, extraData);
      }),
    );
  }
}
