<ng-container *transloco="let t; read: 'editorFormatSizeComponent'">
  <div ngbDropdown>
    <button
      type="button"
      class="btn btn-borderless-primary rounded-0"
      id="editorSizeDropdown"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      container="body"
      triggers="hover:blur"
    >
      <i class="cqi-sm cqi-text-height"></i>
    </button>
    <div
      ngbDropdownMenu
      class="text-center"
      aria-labelledby="editorSizeDropdown"
    >
      <button
        ngbDropdownItem
        type="button"
        *ngFor="let size of EDITOR_FORMAT_SIZES"
        (click)="onClickSetSizeButton(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
</ng-container>
