import { ChangeDetectionStrategy, Component } from '@angular/core';

import { environment } from '@environment';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-question-header',
  templateUrl: './questions-header.component.html',
  styleUrls: ['./questions-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsHeaderComponent {
  landingUrl = environment.landingUrl;

  constructor(private readonly carrotquestHelper: CarrotquestHelper, private readonly l10nHelper: L10nHelperService) {}

  get logoPath(): string {
    return this.l10nHelper.isUsCountry()
      ? 'assets/img/en/questions/dashly-logo.svg'
      : 'assets/img/ru/questions/carrot-quest-logo.svg';
  }

  /** Трекаем клик на логотип */
  trackLogoClick() {
    this.carrotquestHelper.track('Регистрация - клик на логотип');
  }
}
