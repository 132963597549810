import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-ai-sales-promt',
  templateUrl: './ai-sales-promt.component.html',
  styleUrls: ['./ai-sales-promt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiSalesPromtComponent extends AbsCVAFormControlBasedComponent<string> {
  fb = inject(FormBuilder);

  readonly control: FormControl<string> = this.fb.control('', {
    nonNullable: true,
    validators: Validators.required,
  });

  constructor() {
    super();
  }
}
