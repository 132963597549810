<ng-container *transloco="let t; read: 'savedRepliesPopoverComponent'">
  <div class="flex flex-col full-height">
    <div
      class="form-group has-feedback has-feedback-left mb-0 bordered-bottom"
      [cqShowError]="searchControl"
    >
      <i class="cqi-sm cqi-search form-control-feedback text-right text-muted"></i>
      <input
        #searchElement
        class="form-control border-0"
        autocomplete="off"
        [autofocus]="true"
        [formControl]="searchControl"
        [placeholder]="t('searchInput.placeholder')"
      />
      <cq-validation-messages [control]="searchControl">
        <cq-validation-message errorName="maxlength">{{
          t('searchInput.errors.maxlength', { maxLength: MAX_SEARCH_LENGTH })
        }}</cq-validation-message>
      </cq-validation-messages>
    </div>
    <ng-container *tuiLet="savedRepliesList$ | async as savedRepliesList">
      <ng-container *ngIf="isApiRequestPerformed$ | async; else content">
        <div class="p-60 position-relative">
          <cq-loader></cq-loader>
        </div>
      </ng-container>
      <ng-template #content>
        <ul
          ngbNav
          [(activeId)]="selectedRepliesType"
          #nav="ngbNav"
          class="nav nav-tabs-light px-20"
        >
          <li [ngbNavItem]="SAVED_REPLY_ACCESS_TYPE.SHARED">
            <a
              class="py-15 position-relative"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                changeRepliesTypeSubj.next(SAVED_REPLY_ACCESS_TYPE.SHARED)
              "
              ngbNavLink
              ><i class="cqi-sm cqi-users-o mr-5"></i>{{ t('tabTitles.sharedReplies') }}</a
            >
            <ng-template ngbNavContent>
              <ng-container [ngTemplateOutlet]="savedRepliesListTemplate"> </ng-container>
            </ng-template>
          </li>
          <li [ngbNavItem]="SAVED_REPLY_ACCESS_TYPE.PERSONAL">
            <a
              class="py-15 position-relative"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                changeRepliesTypeSubj.next(SAVED_REPLY_ACCESS_TYPE.PERSONAL)
              "
              ngbNavLink
              ><i class="cqi-sm cqi-lock mr-5"></i>{{ t('tabTitles.personalReplies') }}</a
            >
            <ng-template ngbNavContent>
              <ng-container [ngTemplateOutlet]="savedRepliesListTemplate"> </ng-container>
            </ng-template>
          </li>
        </ul>
        <div
          class="flex-grow overflow-y-auto overflow-x-hidden custom-scroll"
          [ngbNavOutlet]="nav"
        ></div>
        <ng-template #savedRepliesListTemplate>
          <ng-container *ngIf="savedRepliesList; else zeroData">
            <ng-container *ngIf="savedRepliesList.length > 0; else zeroData">
              <div>
                <ng-container *ngFor="let savedReply of savedRepliesList; index as i; last as isLast">
                  <div
                    #replyElement
                    class="padding-20 cursor-pointer"
                    [tabindex]="i + 1"
                    [ngClass]="{ 'bordered-bottom': !isLast, active: i === selectedReplyIndex }"
                    (mouseenter)="onMouseenter(i)"
                    (click)="onSelectSavedReply(savedReply)"
                  >
                    <div class="flex align-items-start">
                      <div class="flex-grow">
                        <div
                          class="text-break font-weight-bold"
                          [innerHTML]="isSearch ? savedReply.headerHeadline : savedReply.header"
                        ></div>
                        <div
                          class="saved-reply-body text-break"
                          [innerHTML]="isSearch ? savedReply.bodyHeadline : savedReply.body"
                        ></div>
                      </div>
                      <ng-container *ngIf="i < 9">
                        <div
                          class="label ml-10"
                          [ngClass]="{
                            'label-secondary': isDarkThemeActive,
                            'label-light-secondary': !isDarkThemeActive,
                          }"
                        >
                          {{ getHotkeyForReply(savedReply, i) }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template #zeroData>
          <div
            class="zero-data-container d-flex flex-col align-items-center justify-center mx-auto text-center full-height"
          >
            <ng-container *ngIf="!isSearch">
              <h4>{{ t('zeroData.notSearch.' + selectedRepliesType + '.header') }}</h4>
              <div class="mb-20">
                {{
                  t('zeroData.notSearch.' + selectedRepliesType + (!isOperator ? '.nonOperatorText' : '.operatorText'))
                }}
              </div>
              <ng-container *ngIf="isOperator">
                <a
                  class="btn btn-primary"
                  type="button"
                  uiSref="app.content.conversationsSettings.savedReplies"
                >
                  <i class="btn-icon-left cqi-sm cqi-external-link"></i>
                  {{ t('zeroData.notSearch.' + SAVED_REPLY_ACCESS_TYPE.PERSONAL + '.actionButton') }}
                </a>
              </ng-container>
              <ng-container *ngIf="!isOperator">
                <a
                  class="btn btn-primary"
                  type="button"
                  uiSref="app.content.conversationsSettings"
                  [uiParams]="{ tab: CONVERSATIONS_SETTINGS_TABS.SAVED_REPLIES }"
                >
                  <i class="btn-icon-left cqi-sm cqi-external-link"></i>
                  {{ t('zeroData.notSearch.' + selectedRepliesType + '.actionButton') }}
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isSearch">
              <h4>{{ t('zeroData.search.' + selectedRepliesType + '.header') }}</h4>
              <div>
                <span>{{ t('zeroData.search.' + selectedRepliesType + '.text1') }}</span>
                <a
                  href="#"
                  (click)="resetSearch()"
                  >{{ t('zeroData.search.' + selectedRepliesType + '.text2') }}</a
                ><span>{{ t('zeroData.search.' + selectedRepliesType + '.text3') }}</span>
                <a
                  href="#"
                  (click)="$event.preventDefault(); $event.stopPropagation(); toggleSelectedRepliesType()"
                >
                  {{ t('zeroData.search.' + selectedRepliesType + '.text4') }}
                </a>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
