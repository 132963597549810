import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { App } from '@http/app/app.model';
import {
  ACTIONS_GROUPS,
  ACTIONS_GROUPS_LIST,
  CHAT_BOT_ACTIONS_LIMIT_COUNT,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_ICONS,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { Properties } from '@http/property/property.model';
import { BranchContainerCallbacks } from '@panel/app/pages/chat-bot/content/branch-editor/branch-editor.component';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { CaseStyleHelper } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

enum TARGET_GROUP {
  CONTINUE_CONVERSATION,
  END_CONVERSATION,
}

const CONTINUE_CONVERSATION_OPTIONS = [CHAT_BOT_ACTIONS_TYPES.BUTTON, CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD];

const END_CONVERSATION_OPTIONS = [
  CHAT_BOT_ACTIONS_TYPES.CHANNEL,
  CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
  CHAT_BOT_ACTIONS_TYPES.OPERATOR,
  //CHAT_BOT_ACTIONS_TYPES.ASSISTANT,
  CHAT_BOT_ACTIONS_TYPES.CLOSE,
];

@Component({
  selector:
    'cq-block-branch-editor[actionGroups][actionsToRender][branch][buttonPropertyCheckboxControl][changedControlToButton][changeTargetAction][chatBotType][currentApp][getActionHeaderIndex][getCallbacks][isButtonDisabled][nextBranchOptions][noticeOfTargetAction][properties]',
  templateUrl: './block-branch-editor.component.html',
  styleUrls: ['./block-branch-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockBranchEditorComponent implements OnInit {
  @Input()
  actionGroups!: {
    [k in ACTIONS_GROUPS]: BehaviorSubject<CHAT_BOT_ACTIONS_TYPES[]>;
  };
  @Input()
  actionsToRender!: BotAction[];
  private _branch!: Branch;
  @Input()
  set branch(branch: Branch) {
    this._branch = branch;
    this.selectedTargetGroup = CONTINUE_CONVERSATION_OPTIONS.includes(this.branch.targetAction.value)
      ? TARGET_GROUP.CONTINUE_CONVERSATION
      : TARGET_GROUP.END_CONVERSATION;
  }
  get branch(): Branch {
    return this._branch;
  }
  @Input()
  buttonPropertyCheckboxControl!: GenericFormControl<boolean>;
  @Input('changedControlToButton') // eslint-disable-line
  changedControlToButton$!: Observable<boolean>;
  @Input()
  changeTargetAction!: (actionType: CHAT_BOT_ACTIONS_TYPES) => void;

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Input()
  currentApp!: App;
  @Input()
  getActionHeaderIndex!: (actionType: CHAT_BOT_ACTIONS_TYPES, index: number) => number;
  @Input()
  getCallbacks!: (actionIndex: number, group: ACTIONS_GROUPS) => BranchContainerCallbacks;
  @Input()
  isButtonDisabled!: (actionType: CHAT_BOT_ACTIONS_TYPES) => boolean;
  @Input()
  nextBranchOptions!: Branch[];
  @Input()
  noticeOfTargetAction!: string;
  @Input()
  properties!: Properties;

  @Output()
  botActionCreate: EventEmitter<CHAT_BOT_ACTIONS_TYPES> = new EventEmitter<CHAT_BOT_ACTIONS_TYPES>();
  @Output()
  newButtonCreate: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  checkboxValueChange: EventEmitter<Event> = new EventEmitter<Event>();

  ACTIONS_GROUPS = ACTIONS_GROUPS;
  ACTIONS_GROUPS_LIST = ACTIONS_GROUPS_LIST;
  actionsCollapse: { [key: string]: boolean } = {};
  CHAT_BOT_ACTIONS_LIMIT_COUNT = CHAT_BOT_ACTIONS_LIMIT_COUNT;
  CHAT_BOT_ACTIONS_TYPES = CHAT_BOT_ACTIONS_TYPES;
  CHAT_BOT_ACTIONS_TYPES_ICONS = CHAT_BOT_ACTIONS_TYPES_ICONS;
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP = CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP;
  CHAT_BOT_TYPE = CHAT_BOT_TYPE;

  TARGET_GROUP = TARGET_GROUP;
  selectedTargetGroup: TARGET_GROUP = TARGET_GROUP.END_CONVERSATION;
  targetActionsGrouping = {
    [TARGET_GROUP.CONTINUE_CONVERSATION]: CONTINUE_CONVERSATION_OPTIONS,
    [TARGET_GROUP.END_CONVERSATION]: END_CONVERSATION_OPTIONS,
  };

  /** Доступ до AI-бота */
  accessToAiBot: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    public readonly caseStyleHelper: CaseStyleHelper,
    private readonly scenariosHelper: BotScenariosHelper,
    private readonly transloco: TranslocoService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly featureModel: FeatureModel,
  ) {}

  ngOnInit() {
    this.accessToAiBot = this.planFeatureAccessService.getAccess(PLAN_FEATURE.AI_BOT, this.currentApp);

    if (
      (this.accessToAiBot.hasAccess || this.featureModel.hasAccess(FEATURES.CHAT_GPT)) &&
      !END_CONVERSATION_OPTIONS.includes(CHAT_BOT_ACTIONS_TYPES.ASSISTANT)
    ) {
      END_CONVERSATION_OPTIONS.push(CHAT_BOT_ACTIONS_TYPES.ASSISTANT);
    }
  }

  @tuiPure
  get isMeetingBlock() {
    return this.branch.blockType === 'meeting';
  }

  /** Получение экшена встречи */
  @tuiPure
  getMeetingAction(branch: Branch): BotAction {
    return branch.actions.find((action) => action.type === CHAT_BOT_ACTIONS_TYPES.MEET)!;
  }

  /**
   * Получение действий по группе
   * @param group
   */
  public getRenderActionsByGroup(group: ACTIONS_GROUPS): BotAction[] {
    return this.actionsToRender.filter((action) => {
      return this.CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[group].includes(action.type);
    });
  }

  public showAttachTextTooltip(type: CHAT_BOT_ACTIONS_TYPES): string {
    if (this.isButtonDisabled(type)) {
      return this.transloco.translate('chatBot.branchEditor.countLimitTooltip', {
        limitCount: CHAT_BOT_ACTIONS_LIMIT_COUNT[type],
      });
    }
    return '';
  }

  /**
   * отображать хедер с доп.действиями над экшенами (удаление, копирование и т.д.)
   * @param action
   */
  public showActionHeader(action: BotAction): boolean {
    const actionsWithHeader: CHAT_BOT_ACTIONS_TYPES[] = [
      ...CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.CONTENT],
      CHAT_BOT_ACTIONS_TYPES.BUTTON,
    ];

    return actionsWithHeader.includes(action.type);
  }

  /**
   * Трекинг по группе
   * @param index
   * @param group
   */
  public trackByGroup(index: number, group: ACTIONS_GROUPS) {
    return group;
  }

  /**
   * Трекинг по LinkId
   * @param index
   * @param action
   */
  public trackByActionLinkId(index: number, action: BotAction): string {
    return action.linkId;
  }

  /**
   * Является ли текущий блок частью сценария прерывания
   */
  public get isPartOfInterruptScenario() {
    return this.scenariosHelper.blockIsPartOfInterruptScenario(this.branch);
  }

  onTargetGroupSelect(actionType: CHAT_BOT_ACTIONS_TYPES, sourceGroup: TARGET_GROUP) {
    if (this.selectedTargetGroup === sourceGroup) {
      return;
    }

    this.branch.targetAction.setValue(actionType);
    this.changeTargetAction(actionType);
  }
}
