import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotActionAttachment } from '@http/chat-bot/types/action-internal.types';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

/**
 * Общий компонент для действий работающих с файлами
 */
@Component({
  selector: 'cq-branch-media-actions-editor[actionForm]',
  templateUrl: './media-editor.component.html',
  styleUrls: ['./media-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchMediaActionsEditorComponent {
  @Input() actionForm!: BaseBotActionForm;

  readonly EMPTY_FILE_SIZE = 0;
  readonly MAX_FILE_SIZE = 10 * 1024 * 1024;
  readonly MAX_IMAGE_SUM_WIDTH_AND_HEIGHT = 10000;
  readonly MAX_IMAGE_RATIO = 20;

  constructor(
    readonly fileHelperService: FileHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  get attachments(): GenericFormControl<ChatBotActionAttachment[]> {
    return this.actionForm.controls.attachments;
  }

  get file(): ChatBotActionAttachment | File {
    return this.actionForm.get('attachments').value[0];
  }

  async onFileChange(event: NgxDropzoneChangeEvent) {
    // rejectedFiles сюда добавлены, чтоб до клиента все равно доходила ошибка
    const files = [...event.addedFiles, ...event.rejectedFiles];

    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];
    if (!this.fileHelperService.isAcceptedExtension(file.name)) {
      const message = this.translocoService.translate('chatBot.mediaActionsEditor.toasts.fileExtension');
      this.toastService.danger(message);
      return;
    }

    if (file.size > this.MAX_FILE_SIZE) {
      const message = this.translocoService.translate('chatBot.mediaActionsEditor.toasts.maxFileSize', {
        maxFileSize: this.MAX_FILE_SIZE / 1024 / 1024,
      });
      this.toastService.danger(message);
      return;
    }

    if (file.size === this.EMPTY_FILE_SIZE) {
      const message = this.translocoService.translate('chatBot.mediaActionsEditor.toasts.emptyFileSize');
      this.toastService.danger(message);
      return;
    }

    if (
      this.actionForm.type !== CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE &&
      this.fileHelperService.isAcceptedImageExtension(file.name)
    ) {
      try {
        const { width, height } = await this.loadImage(file);
        if (width + height > this.MAX_IMAGE_SUM_WIDTH_AND_HEIGHT) {
          const message = this.translocoService.translate(
            'chatBot.mediaActionsEditor.toasts.maxImageSumWidthAndHeight',
          );
          this.toastService.danger(message);
          return;
        } else if (Math.max(width / height, height / width) > this.MAX_IMAGE_RATIO) {
          const message = this.translocoService.translate('chatBot.mediaActionsEditor.toasts.maxImageRatio');
          this.toastService.danger(message);
          return;
        }
      } catch (e) {
        console.error('Failed image load', e);
        return;
      }
    }

    // Специфичная валидация для видео-кружка
    if (
      this.actionForm.type === CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE &&
      !this.fileHelperService.isAcceptedVideoExtension(file.name)
    ) {
      const message = this.translocoService.translate('chatBot.mediaActionsEditor.toasts.videoExtension');
      this.toastService.danger(message);
      return;
    }

    // @ts-ignore TODO ANGULAR_TS тут проблема в том, что есть Attachment а есть File, надо разделять контролы для этого
    this.actionForm.controls.attachments.setValue([file]);
    this.cdr.markForCheck();

    this.readFile(file).then(
      function (file: any, fileContents: any) {
        file.url = fileContents;
      }.bind(this, file),
    );
  }

  private readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve(e.target!.result!);
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      reader.readAsDataURL(file);
    });
  }

  loadImage(file: File): Promise<{ width: number; height: number }> {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function (e) {
        reject(e);
      };
      img.src = URL.createObjectURL(file);
    });
  }

  removeFile() {
    this.actionForm.controls.attachments.setValue([]);
  }
}
