import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { SharedModule } from '@panel/app/shared/shared.module';

import { BotCustomPlaceholderComponent } from './bot-custom-placeholder.component';

@NgModule({
  declarations: [BotCustomPlaceholderComponent],
  exports: [BotCustomPlaceholderComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TranslocoModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
  ],
})
export class BotCustomPlaceholderModule {}
