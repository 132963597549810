import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

@Directive({
  selector: '[cqHideInUs]',
})
export class CqHideInUsDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private l10nHelper: L10nHelperService,
  ) {
    if (l10nHelper.isUsCountry()) {
      viewContainer.clear();
    } else {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}
