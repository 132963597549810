import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-bot-triggers',
  templateUrl: './bot-triggers.component.html',
  styleUrls: ['./bot-triggers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotTriggersComponent extends AbsCVAFormControlBasedComponent<boolean> implements OnInit {
  readonly control: FormControl<boolean> = new FormControl<boolean>(false, {
    nonNullable: true,
  });
}
