import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { TriggerChainStep } from '@http/trigger-chain/internal-types';
import { PIXI_APP, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewParser } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.parser';
import { BlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { ActionView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/action.view';
import { AutomessageView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/automessage.view';
import { DelayView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/delay.view';
import { ExitView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/exit.view';
import { FilterView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/filter.view';
import { ReactionView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/reaction.view';
import { SendingConditionsView } from '@panel/app/services/canvas/tirgger-chain/blocks/views/sending-conditions.view';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import { INTERACTION_ENTITY } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';

/**
 * Сервис-фабрика для работы с view шагов
 */
@Injectable()
export class BlockViewFactory {
  constructor(
    private readonly blockViewParser: BlockViewParser,
    private readonly interactionService: InteractionService,
    private readonly interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    private readonly transloco: TranslocoService,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
  ) {}

  /**
   * Создаёт view шага
   *
   * @param step - Шаг
   */
  create(step: TriggerChainStep): IBlockView {
    let data: BlockViewData = this.blockViewParser.getParsedData(step);
    let view = this.getViewInstance(data);

    view.graphicContainer.position.set(data.coordinates.x, data.coordinates.y);

    this.interactionService.makeMovable({
      type: INTERACTION_ENTITY.BLOCK,
      view: view,
    });
    this.interactionService.makeInteractive({
      type: INTERACTION_ENTITY.BLOCK,
      view: view,
    });

    return view;
  }

  getViewInstance(data: BlockViewData): IBlockView {
    const requiredArgs = [this.interactiveBlockPartViewFactory, this.transloco, this.pixiApp.renderer] as const;

    switch (data.type) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
        return new AutomessageView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.DELAY:
        return new DelayView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.FILTER:
        return new FilterView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION:
        return new SendingConditionsView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.REACTION:
        return new ReactionView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT:
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS:
        return new ExitView(data, ...requiredArgs);
      case TRIGGER_CHAIN_BLOCK_TYPE.ACTION:
        return new ActionView(data, ...requiredArgs);
    }
  }
}
