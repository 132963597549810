<ng-container *transloco="let t; read: 'eventTypeActionComponent'">
  <ng-container *ngIf="triggerChainEditorStore.currentApp$ | async as currentApp">
    <div
      class="form-group"
      [cqShowError]="control.controls.keyName"
    >
      <cq-event-type-select
        class="event-select"
        bindValue="name"
        [currentApp]="currentApp"
        [eventTypes]="(triggerChainEditorStore.properties$ | async)?.eventTypes ?? []"
        (eventTypesChange)="eventTypesChanged($event)"
        [showHeader]="false"
        [createTrackMasterEvent]="false"
        [formControl]="control.controls.keyName"
        [placeholderText]="t('eventTypeSelector.placeholder')"
      ></cq-event-type-select>
      <input
        class="hidden"
        [formControl]="control.controls.keyName"
      />

      <cq-validation-messages [control]="control.controls.keyName">
        <cq-validation-message errorName="required">
          {{ t('eventTypeSelector.errors.required') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </ng-container>
</ng-container>
