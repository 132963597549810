<ng-container *transloco="let t; read: 'propertyActionComponent'">
  <div class="d-flex flex-col">
    <div class="form-group">
      <label class="font-weight-normal small">{{ t('propertySelect.label') }}</label>
      <ng-container *ngIf="triggerChainEditorStore.currentApp$ | async as currentApp">
        <cq-property-selector
          [currentApp]="currentApp"
          [formControl]="control.controls.keyName"
          [properties]="(triggerChainEditorStore.properties$ | async)?.userProps ?? []"
          (propertiesChange)="propsChanged($event)"
        ></cq-property-selector>
      </ng-container>
    </div>

    <div
      class="form-group"
      [cqShowError]="control.controls.body"
    >
      <label
        class="font-weight-normal small"
        for="propertyValue"
      >
        {{ t('propertyValueInput.label') }}
      </label>
      <input
        class="form-control"
        id="propertyValue"
        required
        type="text"
        [formControl]="control.controls.body"
        placeholder="{{ t('propertyValueInput.placeholder') }}"
      />
      <cq-validation-messages [control]="control.controls.body">
        <cq-validation-message errorName="required">
          {{ t('propertyValueInput.errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('propertyValueInput.errors.maxlength') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>
</ng-container>
