import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageScheduleMaxUsersCountComponent } from './message-schedule-max-users-count.component';

@NgModule({
  declarations: [MessageScheduleMaxUsersCountComponent],
  imports: [CommonModule, TranslocoDirective, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [MessageScheduleMaxUsersCountComponent],
})
export class MessageScheduleMaxUsersCountModule {}
