import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs/operators';

/* eslint-disable unused-imports/no-unused-imports */
// TODO: Этот импорт тут только для того, что куда-то в ТС включить файл, чтоб билдер видел его
import { SYSTEM_LOG_MESSAGE_CLASSES } from '@http/system-log/system-log.constants';
/* eslint-enable unused-imports/no-unused-imports */
import { UtilsService } from '@panel/app/services/utils/utils.service';

@Injectable({ providedIn: 'root' })
export class SystemLogModel {
  constructor(private readonly http: HttpClient, private readonly utilsService: UtilsService) {}

  /**
   * Получение списка сохранённых ответов
   */
  getList(appId: string) {
    return this.http.get<any[]>('/apps/' + appId + '/systemlog').pipe(
      map((systemLogMessages) => {
        for (let i = 0; i < systemLogMessages.length; i++) {
          this.parse(systemLogMessages[i]);
        }

        systemLogMessages.sort((a, b) => b.created - a.created);

        return systemLogMessages;
      }),
    );
  }

  /**
   * Проверяет наличие непрочтённых сообщений в системном логе
   *
   * @param appId ID приложения
   * @param systemLogMessages Массив сообщений системного лога
   * @return Есть ли в системном логе непрочтённые сообщения
   */
  hasUnread(appId: string, systemLogMessages: any[]) {
    if (systemLogMessages.length) {
      const lastReadId = this.getLastReadId(appId);
      return !~this.utilsService.compareStringNumbers(lastReadId, systemLogMessages[0].id);
    } else {
      return false;
    }
  }

  /**
   * Получение ID последнего прочитанного системного лога
   */
  private getLastReadId(appId: string) {
    const lastReadJson = localStorage.getItem('carrotquest_last_checked_system_log_message_ids') || '{}';
    return JSON.parse(lastReadJson)[appId] || '0';
  }

  /**
   *
   * @param appId
   * @param systemLogMessages
   */
  unreadCount(appId: string, systemLogMessages: any[]) {
    const sortedLog = systemLogMessages.sort((a, b) => b.created.diff(a.created));
    const lastReadId = this.getLastReadId(appId);
    const lastReadIndex = sortedLog.findIndex((item) => item.id === lastReadId);
    return lastReadIndex > -1 ? lastReadIndex : systemLogMessages.length;
  }

  /**
   * Парсинг сообщения системного лога
   *
   * @param {Object} systemLogMessage Сообщение системного лога
   */
  private parse(systemLogMessage: any) {
    systemLogMessage.created = moment(systemLogMessage.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  /**
   * Установка последнего прочитанного сообщения в системном логе по его ID
   *
   * @param appId ID приложения
   * @param systemLogMessagesId ID сообщения системного лога
   */
  setLastReadId(appId: string, systemLogMessagesId: string) {
    const lastReadJson = localStorage.getItem('carrotquest_last_checked_system_log_message_ids') || '{}';
    const lastReadObject = JSON.parse(lastReadJson);

    lastReadObject[appId] = systemLogMessagesId;

    localStorage.setItem('carrotquest_last_checked_system_log_message_ids', JSON.stringify(lastReadObject));
  }
}
