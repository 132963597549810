import Inline from 'quill/blots/inline';

export class EditorCustomBlotButton extends Inline {
  static blotName = 'button';
  // Класс не похож на другие, потому что он используется для cqstatic
  static className = 'carrotquest-messenger-button';
  static tagName = 'A';

  static create(value: any): any {
    let node = super.create(value);

    if (typeof value === 'object' && value.href && value.target) {
      if (value.href) {
        node.setAttribute('href', value.href);
      }

      if (value.target) {
        node.setAttribute('target', value.target);
      }

      node.setAttribute('contenteditable', 'false');
    }

    return node;
  }

  static formats(domNode: HTMLElement) {
    return {
      href: domNode.getAttribute('href'),
      target: domNode.getAttribute('target'),
      text: domNode.innerText,
    };
  }

  public formats(): { [index: string]: any } {
    let format = this.statics.formats(this.domNode, this.scroll);
    let formats = this.attributes.values();

    if (format !== null) {
      formats[this.statics.blotName] = format;
    }

    return formats;
  }

  format(name: string, value: any): any {
    if (name === EditorCustomBlotButton.blotName && value) {
      if (value.href) {
        this.domNode.setAttribute('href', value.href);
      }

      if (value.target) {
        this.domNode.setAttribute('target', value.target);
      }

      if (value.text) {
        this.domNode.innerText = value.text;
      }
    } else {
      super.format(name, value);
    }
  }
}
