import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { MIN_LENGTH_MESSAGE_CONTEXT } from '@panel/app/pages/trigger-messages/email-part-editor/ai-content-maker/ai-content-maker.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-acm-promt',
  templateUrl: './acm-promt.component.html',
  styleUrls: ['./acm-promt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcmPromtComponent extends AbsCVAFormControlBasedComponent<string> {
  readonly MIN_LENGTH_MESSAGE_CONTEXT = MIN_LENGTH_MESSAGE_CONTEXT;

  control: FormControl<string> = this.fb.control<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(MIN_LENGTH_MESSAGE_CONTEXT)],
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}
