import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

import { TriggerChainActionType } from '@http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_ACTION_TYPE, TRIGGER_CHAIN_ACTION_TYPES } from '@http/trigger-chain/trigger-chain.constants';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsListComponent extends AbsCVAFormArrayBasedComponent<TriggerChainActionType, FormControl> {
  readonly control: FormArray<FormControl<TriggerChainActionType>> = this.fb.array<FormControl<TriggerChainActionType>>(
    [],
    [Validators.required],
  );

  readonly TRIGGER_CHAIN_ACTION_TYPES = TRIGGER_CHAIN_ACTION_TYPES;

  readonly TRIGGER_CHAIN_ACTION_TYPE = TRIGGER_CHAIN_ACTION_TYPE;

  actionIconClasses: Record<TRIGGER_CHAIN_ACTION_TYPE, string> = {
    event_type: 'cqi-cursor-arrow',
    property: 'cqi-user-o',
    user_tag: 'cqi-tag-o',
  };

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  addAction(type: TRIGGER_CHAIN_ACTION_TYPE) {
    this.control.push(this.getNewControl(undefined, type));
  }

  copyAction(idx: number) {
    const actionToCopy = this.control.getRawValue()[idx];
    this.control.push(
      this.getNewControl({
        body: actionToCopy.body,
        keyName: actionToCopy.keyName,
        type: actionToCopy.type,
      }),
    );
  }

  deleteAction(idx: number) {
    this.control.removeAt(idx);
  }

  getNewControl(
    action?: TriggerChainActionType,
    type: TRIGGER_CHAIN_ACTION_TYPE = TRIGGER_CHAIN_ACTION_TYPE.EVENT_TYPE,
  ): FormControl {
    if (!action) {
      action = {
        body: null,
        keyName: null,
        type,
      };
    }
    return this.fb.control<TriggerChainActionType>(action, { nonNullable: true });
  }
}
