<ng-container *transloco="let t; read: 'aiSalesListComponent'">
  <div class="d-flex justify-end align-items-start">
    <a
      class="btn btn-borderless-primary"
      uiSref="app.content.aiSales.botSettings"
    >
      <i class="btn-icon-left cqi-sm cqi-cog"></i>
      {{ t('aiSalesBotSettingsButton') }}</a
    >
  </div>
</ng-container>
