import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AiContentMakerBodyJsonConstants } from '@http/message-part/message-part.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-acm-constant-editor',
  templateUrl: './acm-constant-editor.component.html',
  styleUrls: ['./acm-constant-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcmConstantEditorComponent
  extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<AiContentMakerBodyJsonConstants>>
  implements OnInit
{
  @Input({ required: true })
  index: number = 0;

  @Input({ required: true })
  isLast: boolean = false;

  @Output()
  delete: EventEmitter<number> = new EventEmitter<number>();

  control: FormGroup<ToFormGroupControls<AiContentMakerBodyJsonConstants>> = this.fb.group({
    value: this.fb.control<string>('', { nonNullable: true, validators: [Validators.required] }),
    context: this.fb.control<string>('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
