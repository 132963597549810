import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { TriggerChainStepEditorWrapModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-editor-wrap/trigger-chain-step-editor-wrap.module';
import { ActionsListModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/actions-list/actions-list.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainActionEditorComponent } from './trigger-chain-action-editor.component';

@NgModule({
  declarations: [TriggerChainActionEditorComponent],
  exports: [TriggerChainActionEditorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    TriggerChainStepEditorWrapModule,
    ActionsListModule,
    SharedModule,
  ],
})
export class TriggerChainActionEditorModule {}
