import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';

import { UserProperty } from '@http/property/property.model';

type PropertiesOptionsPipe = {
  hasAccessToUsersCustomProperties: boolean;
  sortType: string[];
  userPropsTypeFilter: string[];
};

@Pipe({
  name: 'filterAndSortUserProps',
})
export class FilterAndSortUserPropsPipe implements PipeTransform {
  transform(userProperties: UserProperty[], selectedProp: string, options: PropertiesOptionsPipe): UserProperty[] {
    const filteredEvents = userProperties.filter(
      (userProp: UserProperty) =>
        !userProp.readonly &&
        (options.hasAccessToUsersCustomProperties || userProp.prettyName === selectedProp || userProp.groupOrder !== 5),
    );

    const sortedArray = sortBy(filteredEvents, options.sortType);

    if (options.userPropsTypeFilter.length) {
      return filter(sortedArray, (o) => options.userPropsTypeFilter.includes(o.cls));
    }

    return sortedArray;
  }
}
