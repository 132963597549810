import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap, takeUntil } from 'rxjs/operators';

import { SendScriptInstructionModalParams } from '@panel/app/partials/modals/send-script-instruction/send-script-instruction.types';
import { DestroyService } from '@panel/app/services';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { HttpUtilsService } from '@panel/app/shared/services/utils/http-utils.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-send-script-instruction[ajsModalInstance][modalWindowParams]',
  templateUrl: './send-script-instruction.component.html',
  styleUrls: ['./send-script-instruction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SendScriptInstructionComponent {
  /**
   * Инстанс AJS модалки. Нужен для закрытия.
   * TODO Переделать при переезде на A2 компонента, который вызывает эту модалку
   */
  @Input() ajsModalInstance?: any;
  /** Параметры, передаваемые в модалку */
  @Input() modalWindowParams!: SendScriptInstructionModalParams;
  /** Контрол ввода емейла программиста */
  emailControl: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.email]);
  /** Флаг загрузки данных */
  isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDataLoadingObs$!: Observable<boolean>;

  constructor(
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(ClipboardService) private readonly clipboardService: ClipboardService,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(HttpUtilsService) private readonly httpUtilsService: HttpUtilsService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
  ) {
    /** Подписываемся на изменение флага загрузки данных, чтобы отобразить это в темплейте */
    this.isDataLoadingObs$ = this.isDataLoading$.pipe(
      takeUntil(this.destroy$),
      mergeMap((value: boolean) => of(value)),
    );
  }

  /** Копирование ссылки на инструкция по установке скрипта */
  copyLink(): void {
    this.trackClickCopyLink();

    const link = this.utilsService.getLinkForDeveloper(
      this.modalWindowParams.apiKey,
      this.modalWindowParams.appId,
      this.modalWindowParams.appName,
      this.modalWindowParams.appOrigin,
    );
    this.clipboardService.copy(link);

    this.toastService.success(this.translocoService.translate('general.linkCopied'));
  }

  /** Сабмит формы с отправкой инструкции */
  onSubmit(): void {
    if (this.emailControl.valid) {
      this.isDataLoading$.next(true);

      this.httpUtilsService
        .sendTutorial(this.modalWindowParams.appId, this.emailControl.value)
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            throw err;
          }),
          finalize(() => {
            this.isDataLoading$.next(false);
          }),
        )
        .subscribe(
          () => {
            this.toastService.success(
              this.translocoService.translate('sendScriptInstructionComponent.toasts.scriptSent'),
            );
            this.trackClickSendScript();
            this.ajsModalInstance.close();
          },
          (error) => {},
        );
    } else {
      this.emailControl.markAsTouched();
    }
  }

  /**
   * Трек клика на "Скопировать ссылку на страницу"
   */
  trackClickCopyLink() {
    const event = 'Установка сервиса - кликнул на "Скопировать ссылку на страницу"';

    this.carrotquestHelper.track(event, { app: this.modalWindowParams.appName });
  }

  /**
   * Трек клика на "Отправить код на почту"
   */
  trackClickSendScript(): void {
    const event = 'Установка сервиса - кликнул на "Отправить код на почту"';

    this.carrotquestHelper.track(event, { app: this.modalWindowParams.appName });
  }
}
