import { CHAT_BOT_TYPE } from '../../../../app/http/chat-bot/types/chat-bot-external.types';

(function () {
  'use strict';

  angular
    .module('myApp.chatBot')
    .constant('CHAT_BOT_FILTER_TYPES', getChatBotFilterTypes())
    .factory('CHAT_BOT_TYPES_BY_FILTER_TYPE', getChatBotTypesByFilterType);

  /**
   * Фильтры по типу бота
   *
   * @returns {Object}
   */
  function getChatBotFilterTypes() {
    return {
      WEB_AND_MOBILE: 'web_and_mobile', // На сайте и в мобильном приложении
      FACEBOOK: 'facebook', // Facebook
      TELEGRAM: 'telegram', // Telegram
      WIDGET: 'widget', // Widget
      AI: 'ai', // AI-бот
      AI_SALES: 'ai_sales', // AI-sales бот
    };
  }

  /**
   * Фильтры по типу бота. Каждый фильтр включает в себя разные типы ботов
   *
   * @param CHAT_BOT_FILTER_TYPES
   * @param CHAT_BOT_TYPE
   * @returns {Object}
   */
  function getChatBotTypesByFilterType(CHAT_BOT_FILTER_TYPES) {
    return {
      [CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE]: [CHAT_BOT_TYPE.ROUTING, CHAT_BOT_TYPE.LEAD],
      [CHAT_BOT_FILTER_TYPES.FACEBOOK]: [CHAT_BOT_TYPE.FACEBOOK],
      [CHAT_BOT_FILTER_TYPES.TELEGRAM]: [CHAT_BOT_TYPE.TELEGRAM],
      [CHAT_BOT_FILTER_TYPES.WIDGET]: [CHAT_BOT_TYPE.WIDGET],
    };
  }
})();
