/** Сервис для работы с модальным окном "Закончилась квота" */
import { Injectable } from '@angular/core';
import { ui } from 'angular';

import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { AppBlocks, BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Injectable({ providedIn: 'root' })
export class LimitOfUniqueVisitorsModalService {
  constructor(private readonly uibModalService: UibModalService) {}

  /** Инстанс модального окна */
  limitOfUniqueVisitorsModalServiceInstance: ui.bootstrap.IModalInstanceService | null = null;

  /** Скрытие модального окна */
  hideModal() {
    if (this.limitOfUniqueVisitorsModalServiceInstance) {
      this.limitOfUniqueVisitorsModalServiceInstance.close();
      this.limitOfUniqueVisitorsModalServiceInstance = null;
    }
  }

  /**
   * Открытие модального окна
   *
   * @param {Object=} currentApp - Текущее приложение
   * @param {Object} djangoUser - Текущий django-пользователь
   * @param {Object} billingInfo - Информация о биллинге
   * @param {Object} appBlocks - Информация о блокировках App'а
   */
  showModal(currentApp: App, djangoUser: DjangoUser, billingInfo: BillingInfo, appBlocks: AppBlocks) {
    if (!this.limitOfUniqueVisitorsModalServiceInstance) {
      this.limitOfUniqueVisitorsModalServiceInstance = this.uibModalService.open({
        component: 'cqLimitOfUniqueVisitorsModal',
        keyboard: false,
        resolve: {
          appBlocks: angular.bind(null, angular.identity, appBlocks),
          billingInfo: angular.bind(null, angular.identity, billingInfo),
          currentApp: angular.bind(null, angular.identity, currentApp),
          djangoUser: angular.bind(null, angular.identity, djangoUser),
        },
        backdrop: 'static',
        size: 'sm modal-dialog-centered',
        windowClass: 'limit-of-unique-visitors',
      });

      this.limitOfUniqueVisitorsModalServiceInstance.result.finally(() => {
        this.limitOfUniqueVisitorsModalServiceInstance = null;
      });
    }
  }
}
