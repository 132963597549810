import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { WritePropertySelectorComponent } from './write-property-selector.component';

@NgModule({
  declarations: [WritePropertySelectorComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    ReactiveFormsModule,
    PropertySelectorModule,
  ],
  exports: [WritePropertySelectorComponent],
})
export class WritePropertySelectorModule {}
