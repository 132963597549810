import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { EmailDomain } from '@http/email-domain/email-domain.types';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';

@Injectable({ providedIn: 'root' })
export class EmailDomainModel {
  constructor(private readonly http: HttpClient, private readonly featureModel: FeatureModel) {}

  /**
   * Получение списка доменов
   *
   * @param appId ID текущего приложения
   * @param isActive Получить только активные
   */
  getList(appId: string, isActive: boolean = true): Observable<EmailDomain[] | null> {
    const params = { app: appId, active: isActive };
    if (this.featureModel.hasAccess(FEATURES_ONLY.CUSTOM_EMAIL_DOMAINS)) {
      return this.http.get<EmailDomain[]>('/email_domains', { params });
    }
    return of(null);
  }
}
