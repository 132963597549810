import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environments/carrot-quest/environment.docker';
import { MESSAGE_SCHEDULE_TYPE, ScheduledAutoMessage } from '@http/message/message.types';
import { PSEUDO_DIRECTORY_TYPES, SYSTEM_DIRECTORIES } from '@http/message-directory/message-directory.constants';
import { MESSAGE_PART_TYPE_ICON_CLASSES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-scheduled-auto-message-table',
  templateUrl: './scheduled-auto-message-table.component.html',
  styleUrls: ['./scheduled-auto-message-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduledAutoMessageTableComponent {
  @Input()
  accessToSelectedAutoMessages!: ProductFeatureAccess;

  @Input()
  accessToTelegramAutoMessages!: ProductFeatureAccess;

  @Input()
  activeMessagesAmounts!: any;

  @Input()
  appBlocks: any;

  @Input()
  currentApp: any;

  @Input()
  isAllMessageSelected: any;

  @Input()
  messages: ScheduledAutoMessage[] = [];

  @Output()
  clickActivateButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickArchiveButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickCopyButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickPauseButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickReestablishButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickRenameButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectAllMessage: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectMessage: EventEmitter<any> = new EventEmitter<any>();

  protected MESSAGE_PART_TYPE_ICON_CLASSES: any = MESSAGE_PART_TYPE_ICON_CLASSES;
  protected MESSAGE_PART_TYPES: any = MESSAGE_PART_TYPES;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly transloco: TranslocoService,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  /**
   * Заблокирована ли отправка имейлов пользователем по причине подозрительной активности
   *
   * @returns {boolean}
   */
  isEmailBlockedDueToSuspiciousActivity(message: any): boolean {
    return message.type === 'email' && (this.appBlocks.blockAllEmails || this.appBlocks.blockEmails);
  }

  /**
   * Открыть одно из биллинговых модальных сообщений
   * @param message
   */
  openSomeAutoMessageBillingModal(message: any) {
    if (message.type === MESSAGE_PART_TYPES.TELEGRAM) {
      this.paywallService.showPaywallForAccessDenial(this.currentApp, this.accessToTelegramAutoMessages.denialReason);
      return;
    }

    this.paywallService.showAutoMessageTotalPaywall(
      this.currentApp,
      this.accessToSelectedAutoMessages.denialReason,
      1,
      this.activeMessagesAmounts,
    );
  }

  /**
   * Проверка есть ли у автосообщения удаленные теги
   *
   * @param tags Массив тегов из фильтров автосообщения
   * @returns {boolean}
   */
  isMessageHaveDeletedTags(tags: any): boolean {
    return false;
    //return filter(tags, (tag) => tag.tag.removed).length > 0;
  }

  /**
   * Трек клика на открытие статистики по сообщению
   */
  trackClickOnOpenStatistics(): void {
    this.carrotquestHelper.track('Автосообщения - клик на "Статистика"');
  }

  onClickDropdown(): void {
    this.cdr.detectChanges();
  }

  protected readonly PSEUDO_DIRECTORY_TYPES = PSEUDO_DIRECTORY_TYPES;
  protected readonly SYSTEM_DIRECTORIES = SYSTEM_DIRECTORIES;
  protected readonly MESSAGE_SCHEDULE_TYPE = MESSAGE_SCHEDULE_TYPE;

  weekDaysAsText({ schedulerSettings }: ScheduledAutoMessage) {
    const t = (d: number) => {
      return this.transloco.translate(`messageScheduleSettingsComponent.preview.weekDays.${d}`);
    };

    const weekDays = [...schedulerSettings.daysOfWeek!];
    weekDays.sort();

    if (weekDays.length === 1) {
      return t(weekDays[0]);
    }
    const lastDay = weekDays.pop()!;
    const and = this.transloco.translate(`messageScheduleSettingsComponent.preview.and`);
    return `${weekDays.map((d) => t(d)).join(', ')} ${and} ${t(lastDay)}`;
  }

  monthDaysAsText({ schedulerSettings }: ScheduledAutoMessage) {
    // суффикс (5th, 6th of December)
    const th =
      environment.language === 'en'
        ? this.transloco.translate(`messageScheduleSettingsComponent.preview.daySuffix`)
        : '';

    const monthDays = [...schedulerSettings.daysOfMonth!];
    monthDays.sort();

    if (monthDays.length === 1) {
      return monthDays[0];
    }
    const lastDay = monthDays.pop()!;
    const and = this.transloco.translate(`messageScheduleSettingsComponent.preview.and`);
    return `${monthDays.map((d) => `${d}${th}`).join(', ')} ${and} ${lastDay}`;
  }

  translationNumber({ schedulerSettings }: ScheduledAutoMessage) {
    const firstDay = schedulerSettings.daysOfWeek!.sort()[0];

    switch (firstDay) {
      case 1:
      case 2:
      case 4:
        return 'text1';
      case 3:
      case 5:
      case 6:
        return 'text2';
      case 7:
        return 'text3';
      default:
        throw new Error('There is no other days in week days');
    }
  }

  /**
   * Проверка на наличие доп параметров в автосообщение для включения поповера
   *
   * @param message - Автосообщение
   * @returns {Boolean}
   */
  isMessageTriggersPopoverEnabled(message: ScheduledAutoMessage): boolean {
    return this.isMessageHaveFilters(message) || message.maxUsersCount !== null || message.repeatDelay !== null;
  }

  /**
   * Проверка наличия фильтров в автосообщении
   *
   * @param message Автосообщение
   * @returns {boolean}
   */
  isMessageHaveFilters(message: any): boolean {
    return (
      message.filters.filters.props.length > 0 ||
      message.filters.filters.events.length > 0 ||
      message.filters.filters.tags.length > 0
      // || message.jinjaFilterTemplate !== null
    );
  }

  getRepeatDelayDays(message: any) {
    return message.repeatDelay / 24 / 60 / 60;
  }
}
