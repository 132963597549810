<ng-container *transloco="let t; read: 'savedRepliesListComponent'">
  <div
    class="scroll-zone position-sticky w-100 top-0"
    #topScrollZone
  ></div>
  <div>
    <table
      class="saved-replies-table table table-align-middle"
      #savedRepliesTable
    >
      <tbody
        [config]="sortablejsOptions"
        [nxtSortablejs]="savedReplies"
      >
        <tr *ngFor="let savedReply of savedReplies; index as i; trackBy: trackById">
          @if (sortable) {
            <td class="shrink-by-content">
              <i class="cqi-sm cqi-grip-vertical handle-icon"></i>
            </td>
          }
          <td>
            <div class="flex align-items-center">
              <div class="flex-grow">
                <div
                  class="margin-bottom-5 font-weight-bold text-break"
                  [innerHTML]="isSearch ? savedReply.headerHeadline : savedReply.header"
                ></div>
                <div
                  class="saved-reply-body text-break"
                  [innerHTML]="isSearch ? savedReply.bodyHeadline : savedReply.body"
                ></div>
              </div>
              <div
                class="label label-light-secondary margin-left-10"
                *ngIf="sortable && i < 9"
                [innerText]="getHotkeyForReply(savedReply, i)"
              ></div>
            </div>
          </td>
          <td class="shrink-by-content">
            <div
              [ngbTooltip]="t('actionsNotAvailableTooltip')"
              [disableTooltip]="!(isOperator && savedReply.accessType === SAVED_REPLY_ACCESS_TYPE.SHARED)"
              placement="top-right"
            >
              <button
                class="btn btn-borderless-primary"
                (click)="editSavedReply(savedReply)"
                [disabled]="isOperator && savedReply.accessType === SAVED_REPLY_ACCESS_TYPE.SHARED"
              >
                <i class="btn-icon cqi-sm cqi-pencil"></i>
              </button>
              <button
                class="btn btn-borderless-primary"
                (click)="removeSavedReply(savedReply)"
                [disabled]="isOperator && savedReply.accessType === SAVED_REPLY_ACCESS_TYPE.SHARED"
              >
                <i class="btn-icon cqi-sm cqi-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="scroll-zone position-sticky w-100 bottom-0 margin-top-20-neg"
    #bottomScrollZone
  ></div>
</ng-container>
