import { firstValueFrom } from 'rxjs';
import {
  CHANNELS_WITH_INTEGRATION_REPLY_TIMER,
  PSEUDO_CHANNEL_IDS,
  PSEUDO_CHANNEL_TYPES,
} from '../../../../../app/http/channel/channel.constants';
import {
  CONVERSATION_PART_EDITABLE_TYPES,
  CONVERSATION_PART_NON_EDITABLE_NON_REMOVABLE_TYPES,
  CONVERSATION_PART_REMOVABLE_TYPES,
  CONVERSATION_PART_SENT_VIA,
  CONVERSATION_PART_TYPES,
  REPLY_TYPES,
} from '../../../../../app/http/conversation-part/conversation-part.constants';
import { CONVERSATION_RECIPIENTS_TYPES } from '../../../../../app/http/conversation/conversation.constants';
import { DJANGO_USER_INTEGRATION_TYPES } from '../../../../../app/http/django-user-integration/django-user-integration.constants';
import { FEATURES } from '../../../../../app/http/feature/feature.constants';
import { MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';
import { SAVED_REPLY_ACCESS_TYPE } from '../../../../../app/http/saved-reply/saved-reply.types';
import { SYSTEM_LOG_MESSAGE_TYPES } from '../../../../../app/http/system-log/system-log.constants';
import { KnowledgeBaseActivateComponent } from '../../../../../app/pages/knowledge-base/shared/components/knowledge-base-activate/knowledge-base-activate.component';
import { BILLING_ADD_ONS } from '../../../../../app/services/billing-info/billing-info.constants';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { IMAGE_EXTENSION } from '../../../../shared/services/file-helper/file-helper.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversations')
    .controller('CqConversationsConversationController', CqConversationsConversationController);

  function CqConversationsConversationController(
    $anchorScroll,
    $document,
    $filter,
    $interval,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $translate,
    $uibModal,
    $q,
    dateRangePickerHelper,
    hotkeys,
    ipCookie,
    moment,
    toastr,
    Upload,
    API_ENDPOINT,
    INTEGRATION_TYPES,
    planCapabilityModel,
    PROJECT_NAME,
    billingInfoModel,
    carrotquestHelper,
    caseStyleHelper,
    conversationModel,
    conversationPartModel,
    conversationsStoreService,
    djangoUserIntegrationModel,
    djangoUserModel,
    electronApi,
    featureModel,
    fileHelper,
    integrationModel,
    LongPollConnection,
    messageModel,
    paywallService,
    planFeatureAccessService,
    savedReplyModel,
    slashCommandModel,
    systemError,
    emojiService,
    utilsModel,
    utilsService,
    validationHelper,
    vueConversationFrame,
    modalHelperService,
  ) {
    let vm = this;

    /**
     * Количество дней в течении которого оператор может писать сообщения пользователю
     * в Facebook и Instagram
     *
     * @type {number}
     */
    const HUMAN_AGENT_TIME_EXPIRE = 7;

    /**
     * Regexp для проверки ссылки на Calendly
     *
     * @type {RegExp}
     */
    const CALENDLY_URL_REGEXP = /((https?:)\/\/)?(calendly\.com)\/(\w.*)(\/\w.*)?/;

    /**
     * Название ключа для localStorage для хранения количества кликов по кнопке «Закрыть диалог»
     *
     * @type {string}
     */
    const CLOSE_BUTTON_CLICKS_LS_KEY_NAME = 'close_button_clicks';

    /**
     * Название ключа для localStorage для хранения флага показа онбордингового поповера в хоткей закрытия диалога
     *
     * @type {string}
     */
    const CLOSE_HOTKEY_POPOVER_LS_KEY_NAME = 'close_hotkey_popover';

    /**
     * Последний заданный плейсхолдер у поля для ввода сообщения
     *
     * @type {String}
     */
    let currentPlaceholder;

    let hotKeysArray = [];

    /**
     * Время, через которое typing протухает
     */
    const TYPING_EXPIRY_TIMEOUT = 10 * 1000;

    /**
     * Подключение по RTS к каналу conversation_user_typing
     */
    let typingFromUserRts;

    /**
     * Интервал проверки протухания typing
     */
    let typingExpireInterval = null;

    /**
     * ID контейнера, в котором находится Quill
     *
     * @type {String}
     */
    const MESSAGE_CONTAINER_ID = 'scrollingContainer';

    /**
     * Инстанс quill
     *
     * @type {Object}
     */
    let quillInstance;

    /**
     * ID сообщения к которому надо проскроллить
     *
     * @type {String}
     */
    let SCROLL_TO_PART;

    /**
     * Список Slash-команд
     *
     * @type {Array.<Object>}
     */
    let slashCommands = [
      {
        type: 'conversation',
        command: '/user',
        usage: '',
        desc: $translate.instant('conversationsConversation.slashCommands.userCardCommand.description'),
      },
    ];

    /**
     * Валидные mime типы файлов
     *
     * @type {string}
     */
    const VALID_MIME_TYPES =
      'image/*,application/pdf,application/x-rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,text/html,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/*,application/pgp-signature,video/mp4,application/rtf,video/quicktime,audio/mpeg,video/mpeg,video/webm';

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.accessToIntegrations = planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS, vm.currentApp);
      vm.accessToKnowledgeBase = planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE, vm.currentApp);

      vm.addEmoji = addEmoji;
      vm.activeConversationParts = [];
      vm.activeConversationPartsBefore = null;
      vm.activeConversationPartsNext = null;
      vm.activeConversationTags = [];
      vm.activeConversationTyping = []; // наборы текста со стороны пользователя и других операторов в текущем диалоге
      vm.articlesListIsOpen = false; // Флаг открытия списка статей
      vm.assignedChanged = assignedChanged;
      vm.assignee = null;
      vm.attachFile = attachFile;
      vm.BILLING_ADD_ONS = BILLING_ADD_ONS;
      vm.billingInfoModel = billingInfoModel;
      vm.calendlyPopoverIsOpen = false; // Флаг открытия поповера со списком событий Calendly
      vm.calendlyDjangoUserIntegrationId = null; // ID интеграции с Calendly django-пользователя
      vm.cancelMessageEditing = cancelMessageEditing;
      vm.channel = null;
      vm.channelChanged = channelChanged;
      vm.close = close;
      vm.closeHotkeyOnboardingPopover = closeHotkeyOnboardingPopover;
      vm.conversationDelayDatepicker = {
        dates: {
          // дата на которую отложен диалог
          startDate: '', // по умолчанию не указываем текущее время, т.к. время будет задаваться при открытии календаря
        },
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          singleDatePicker: true,
          timePicker: true,
          timePicker24Hour: true,
          linkedCalendars: false,
          showCustomRangeLabel: false,
          maxDate: moment().add(6, 'month'), // максимальный период для того чтобы отложить диалог - пол года
          opens: 'left',
          parentEl: '#conversations-filters',
          customClass: 'conversations-delay-datepicker',
          eventHandlers: {
            'apply.daterangepicker': applyConversationDelayDatepicker,
          },
        }),
      };
      vm.conversationPartsLoading = false; // Флаг загрузки сообщений при пагинации
      vm.editableMessageId = null; // ID редактируемого сообщения
      vm.conversationStates = {
        showBackContentLoader: false, // Показан ли лоадер при подгрузке контента сверху
        backContentLoading: false, // Происходит ли загрузка контента сверху
        showForwardContentLoader: false, // Показан ли лоадер при подгрузке контента снизу
        forwardContentLoading: false, // Происходит ли загрузка контента снизу
        foundedMessage: vm.activeConversation.isSearching ? vm.activeConversation.found_part.id : false, // Найденное сообщение
        editableMessage: vm.editableMessageId ? vm.editableMessageId : false, // Редактируемое сообщение
        externalService: vm.activeConversation.external_service ? vm.activeConversation.external_service : '',
        hasAccessToAudioTranscribe: planFeatureAccessService.getAccess(PLAN_FEATURE.TRANSCRIBE_AUDIO, this.currentApp)
          .hasAccess, // Доступ к фиче распознавания текста в аудиосообщении
      };
      vm.CONVERSATION_RECIPIENTS_TYPES = CONVERSATION_RECIPIENTS_TYPES;
      vm.canInteractWithConversation = canInteractWithConversation;
      vm.delayConversation = delayConversation;
      vm.djangoUser = $rootScope.djangoUser;
      vm.djangoUserModel = djangoUserModel;
      vm.editMessage = editMessage;
      vm.editMessageRequestPerformed = false; // Флаг выполнения запроса на редактирование сообщения
      vm.emojiSelectorOpen = false;
      vm.additionalOptionsDropdownOpen = false;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.filteredSlashCommands = [];
      vm.getCalendlyTooltipText = getCalendlyTooltipText;
      vm.getConversationUrl = getConversationUrl;
      vm.getDisabledSendButtonTooltipText = getDisabledSendButtonTooltipText;
      vm.getMaxLengthErrorText = getMaxLengthErrorText;
      vm.getReplyDuration = getReplyDuration;
      vm.getTextareaPlaceholder = getTextareaPlaceholder;
      vm.hideArticelList = hideArticleList;
      vm.hideCalendlyPopover = hideCalendlyPopover;
      vm.INTEGRATION_TYPES = INTEGRATION_TYPES;
      vm.isActiveConversationFromSearch = false; //Сейчас активен диалог из поиска или нет
      vm.isConnectionError = isConnectionError;
      vm.isConversationDelayDisabled = isConversationDelayDisabled;
      vm.isConversationReplyTimerNotEnded = isConversationReplyTimerNotEnded;
      vm.isConversationWithReplyTimer = isConversationWithReplyTimer;
      vm.isShowCloseHotkeyOnboardingPopover = false; // Флаг показа поповера с онбордингом в шорткат закрытия диалога
      vm.calendlyAppIntegration = null; // Данные по интеграции Calendly у аппа
      vm.isCalendlyLinkPasted = false; // Флаг показа тултипа у иконки Calendly, когда вставлена ссылка на событие из Calendly в поле ввода сообщения
      vm.isGlued = true; // параметр который отвечает за работу директивы scroll-glue-bottom
      vm.isMessageEditing = false; // Флаг редактирования сообщения
      vm.isMessageSendingAllowed = isMessageSendingAllowed;
      vm.isNoteEditing = false; // Флаг редактирования заметки
      vm.isOperatorLostInConcurrency = isOperatorLostInConcurrency;
      vm.isSearch = false; // включен ли сейчас поиск
      vm.isShowWhatsAppTemplatesBtn = isShowWhatsAppTemplatesBtn;
      vm.isKnowledgeBaseActive = vm.knowledgeBaseSettings !== 'KnowledgeBaseAppDoesNotExist'; // флаг активации базы знаний
      vm.knowledgeBaseDomain = 'https://' + vm.knowledgeBaseSettings.fullDomain; // Домен БЗ
      vm.lastTypingMessageSent; // последнее сообщение, отправленное как typing
      vm.lastTypingSentTime = null; // время предыдущей отправки send writing на сервер
      vm.loadConversationParts = loadConversationParts;
      vm.MESSAGE_CONTAINER_ID = MESSAGE_CONTAINER_ID;
      vm.messageForm = null; // форма, в которой содержится поле ввода сообщения
      vm.msg = '';
      vm.noteActive = false;
      vm.onScrollBottom = onScrollBottom;
      vm.onQuillCreated = onQuillCreated;
      vm.onQuillValueChanges = onQuillValueChanges;
      vm.onScrollTop = onScrollTop;
      vm.onTagAdded = onTagAdded;
      vm.onTagRemoved = onTagRemoved;
      vm.onTextPaste = onTextPaste;
      vm.openConversationDelayDatepicker = openConversationDelayDatepicker;
      vm.openCreateKnowladgeBaseModal = openCreateKnowladgeBaseModal;
      vm.openHotkeysModal = openHotkeysModal;
      vm.openRemoveConversationPartModal = openRemoveConversationPartModal;
      vm.openUserCard = openUserCard;
      vm.paywallService = paywallService;
      vm.PSEUDO_CHANNEL_TYPES = PSEUDO_CHANNEL_TYPES;
      vm.REPLY_TYPES = REPLY_TYPES;
      vm.savedAnswersOpen = false;
      vm.savedRepliesShared = null;
      vm.savedRepliesPersonal = null;
      vm.searchChannel = '';
      vm.selectEditableMessage = selectEditableMessage;
      vm.sendAdvancedMessage = sendAdvancedMessage;
      vm.sendWhatsAppTemplateAsReply = sendWhatsAppTemplateAsReply;
      vm.selectSavedReply = selectSavedReply;
      vm.sendConversationToEmail = sendConversationToEmail;
      vm.sendConversationDisabled = {
        emailBlocked: vm.appBlocks.blockAllEmails || vm.appBlocks.blockEmails, // дисейбл если пользователь заблокирован
        emptyUserEmail: !vm.activeConversation.user.props.$email, // дисейбл если у пользователя нет email
      };
      vm.sendingFiles = [];
      vm.sendReply = sendReply;
      vm.setCalendlyIntegrationId = setCalendlyIntegrationId;
      vm.showHistory = showHistory;
      vm.slashCommandClick = slashCommandClick;
      vm.slashCommandRunning = null;
      vm.slashCommandRunningPromise = null;
      vm.slashCommandRunningShow = false;
      vm.slashCommandSelect = slashCommandSelect;
      vm.slashCommandsOpened = false;
      vm.systemError = systemError;
      vm.toggleNote = toggleNote;
      vm.trackAddTag = trackAddTag;
      vm.trackClickOnArticle = trackClickOnArticle;
      vm.trackClickOnConversationLink = trackClickOnConversationLink;
      vm.trackClickOnEmoji = trackClickOnEmoji;
      vm.trackClickOnKnowledgeBase = trackClickOnKnowledgeBase;
      vm.trackClickOnOpenEmoji = trackClickOnOpenEmoji;
      vm.trackClickOnSavedReply = trackClickOnSavedReply;
      vm.trackClickOnShowHistory = trackClickOnShowHistory;
      vm.trackSendArticle = trackSendArticle;
      vm.trackSendCalendlyEvent = trackSendCalendlyEvent;
      vm.trackSendWhatsAppTemplate = trackSendWhatsAppTemplate;
      vm.VALID_MIME_TYPES = VALID_MIME_TYPES;
      vm.validationHelper = validationHelper;
      vm.whatsAppIntegration = null; // Интеграция WhatsApp Edna, к которой привязан активный диалог

      getSavedReplies();
      getSlashCommands();
      setupHotkeys();

      typingExpireInterval = $interval(checkTypingExpire, 5000);

      $scope.$watch('vm.activeConversation', activateConversation);
      $scope.$watch('vm.msg', watchMsg);
      $scope.$watch('vm.msg', sendTyping);
      $scope.$watch('vm.activeConversation.user.props.$email', watchActiveConversationUserEmail);
      $scope.$on('message', handleRts);

      // 1. Запрашиваем список интеграций django-пользователя, чтобы получить ID интеграции для конкретного django-пользователя
      // 2. Запрашиваем список интеграций приложения, чтобы проверить включены или нет эти интеграции в приложении
      $q.all([
        firstValueFrom(
          djangoUserIntegrationModel.getList(
            Object.keys(DJANGO_USER_INTEGRATION_TYPES).map((key) => DJANGO_USER_INTEGRATION_TYPES[key]),
            vm.djangoUser.id,
          ),
        ), // 1
        integrationModel.getList(
          vm.currentApp.id,
          Object.keys(DJANGO_USER_INTEGRATION_TYPES).map((key) => DJANGO_USER_INTEGRATION_TYPES[key]),
        ), // 2
      ]).then(getListSuccess);

      function activateConversation(conversation, isToSearch) {
        cancelMessageEditing();

        typingFromUserRts && typingFromUserRts.destroy.resolve();

        typingFromUserRts = connectTypingRts(vm.currentApp.id, conversation.user.id);

        vm.REPLY_MAX_LENGTH = getReplyMaxLength();

        vm.conversationStates.externalService = vm.activeConversation.external_service
          ? vm.activeConversation.external_service
          : '';

        // при смене диалога нужно сбросить состояние формы, будто в неё ещё никто ничего не вводил и не отправлял
        vm.messageForm.$setPristine();

        //NOTE isToSearch - true только в случае когда мы выбираем диалог из темплейта и когда этот диалог выведен из поиска.
        vm.noteActive = false;
        vm.msg = '';

        if (conversation) {
          // проверяем на наличие ранее созданного черновика сообщения
          var stringDraft = sessionStorage.getItem('conversation_' + conversation.id + '_draft');
          if (stringDraft) {
            var draft = JSON.parse(stringDraft);
            vm.msg = draft.msg || '';
          }
        }

        // Теги
        vm.activeConversationTags = conversation.tags.map((tag) => ({ tag: tag }));

        // назначение
        if (conversation.assignee) {
          vm.assignee = $filter('filter')(vm.teamMembers, { id: conversation.assignee.id }, true)[0] || null;
        } else {
          vm.assignee = null;
        }

        var channelId = 0;
        if (conversation.channel) {
          channelId = conversation.channel.id;
        }
        for (var i = 0; i < vm.channels.length; i++) {
          if (vm.channels[i].id == channelId) {
            vm.channel = vm.channels[i];
            break;
          }
        }
        vm.isActiveConversationFromSearch = isToSearch;

        if (vm.activeConversation.isSearching) {
          SCROLL_TO_PART = 'foundedMessage-' + vm.activeConversation.found_part.id;
          vm.conversationStates.foundedMessage = vm.activeConversation.found_part.id; // Найденное сообщение
        }

        vm.activeConversationPartsNext = null;
        vm.activeConversationPartsBefore = null;
        vm.activeConversationParts = [];
        vm.isGlued = true;

        if (quillInstance) {
          // при переключении на другой диалог нужно стереть историю изменений, чтобы пользователь не мог отменить изменения, которых не было в этом диалоге, но которые были в предыдущем выбранном диалоге (т.к. инстанс quill один и тот же)
          // если этого не сделать, то пользователь, нажав Ctrl+Z сможет в модель записать текст, который был в предыдущем выбранном диалоге, а это неправильно. В слэке, если что, сделано так же
          quillInstance.history.clear();
          focusTheEnd();
        }

        if (vm.activeConversation.external_service === INTEGRATION_TYPES.WHATS_APP_EDNA) {
          getWhatsAppIntegration(vm.activeConversation);
        }

        loadConversationParts(vm.isSearch);
      }

      /**
       * Проверка протухания набора сообщения
       * Если набираемое сообщение не обновлялось TYPING_EXPIRY_TIMEOUT - оно затирается
       */
      function checkTypingExpire() {
        var currentTimestamp = moment();

        vm.activeConversationTyping = vm.activeConversationTyping.filter((typing) => {
          return currentTimestamp - typing.started < TYPING_EXPIRY_TIMEOUT;
        });
      }

      function getListSuccess(integrations) {
        // Проверяем включены ли в аппе интеграции, чтобы показать кнопки интеграций
        var calendlyAppIntegration = $filter('filter')(integrations[1], { type: INTEGRATION_TYPES.CALENDLY });
        if (calendlyAppIntegration.length > 0) {
          vm.calendlyAppIntegration = calendlyAppIntegration[0];
        }

        // Тут проверяем включены ли у django-пользователя интеграции
        var calendlyIntegration = $filter('filter')(integrations[0], { type: INTEGRATION_TYPES.CALENDLY });
        if (calendlyIntegration.length > 0) {
          vm.calendlyDjangoUserIntegrationId = calendlyIntegration[0].id;
        }
      }

      function getSavedReplies() {
        Promise.all([
          firstValueFrom(savedReplyModel.getList(SAVED_REPLY_ACCESS_TYPE.SHARED)),
          firstValueFrom(savedReplyModel.getList(SAVED_REPLY_ACCESS_TYPE.PERSONAL)),
        ]).then(getSavedRepliesSuccess);

        function getSavedRepliesSuccess([savedRepliesShared, savedRepliesPersonal]) {
          vm.savedRepliesShared = savedRepliesShared;
          vm.savedRepliesPersonal = savedRepliesPersonal;
        }
      }

      function getSlashCommands() {
        firstValueFrom(slashCommandModel.getList(vm.currentApp.id)).then(getSlashCommandsSuccess);

        function getSlashCommandsSuccess(slashCommandList) {
          slashCommands = slashCommands.concat(slashCommandList);
          vm.filteredSlashCommands = slashCommands;
        }
      }

      function sendTyping(message) {
        const trimmedMessage = message.trim();
        const now = moment();
        const diff = now.diff(vm.lastTypingSentTime);

        // отправляем информацию о печати сообщения не чаще, чем раз в 2 секунды
        if (diff < 2000) {
          return;
        }

        if (
          trimmedMessage === '' ||
          vm.noteActive ||
          vm.lastTypingMessageSent == trimmedMessage ||
          vm.isMessageEditing ||
          vm.isNoteEditing
        ) {
          return;
        }

        vm.lastTypingMessageSent = trimmedMessage;
        vm.lastTypingSentTime = moment();

        firstValueFrom(conversationModel.setTyping(vm.activeConversation.id, trimmedMessage));
      }

      /**
       * Отслеживает изменение email у пользователя в активном диалоге, и ставит флаг для блокировки отправки реплик
       * @param newVal
       */
      function watchActiveConversationUserEmail(newVal) {
        vm.sendConversationDisabled.emptyUserEmail = !newVal;
      }

      function watchMsg() {
        if (/^\/[a-z0-9\-_]*$/.test(vm.msg)) {
          vm.slashCommandsOpened = true;
          vm.filteredSlashCommands = $filter('orderBy')(
            $filter('filter')(slashCommands, { command: vm.msg }),
            'command',
          );

          var isActiveCommandInFilteredSlashCommands = !!$filter('filter')(
            vm.filteredSlashCommands,
            { active: true },
            true,
          )[0];

          if (!isActiveCommandInFilteredSlashCommands) {
            for (var i = 0; i < slashCommands.length; i++) {
              slashCommands[i].active = false;
            }

            if (vm.filteredSlashCommands.length) {
              vm.filteredSlashCommands[0].active = true;
            }
          }
        } else {
          vm.isCalendlyLinkPasted = CALENDLY_URL_REGEXP.test(vm.msg) && !vm.isMessageEditing;

          vm.slashCommandsOpened = false;

          // храним черновик сообщения в sessionStorage
          if (typeof vm.activeConversation !== 'undefined' && vm.activeConversation.id && !vm.isMessageEditing) {
            var draftKey = 'conversation_' + vm.activeConversation.id + '_draft';
            if (vm.msg) {
              sessionStorage.setItem(draftKey, JSON.stringify({ msg: vm.msg }));
            } else {
              sessionStorage.removeItem(draftKey);
            }
          }
        }
      }
    }

    /**
     * Отмена редактирования сообщения
     */
    function cancelMessageEditing() {
      vm.isMessageEditing = false;
      vm.isNoteEditing = false;
      var stringDraft = sessionStorage.getItem('conversation_' + vm.activeConversation.id + '_draft');
      if (stringDraft) {
        var draft = JSON.parse(stringDraft);
        if (draft.msg) {
          vm.msg = draft.msg || '';
        }
      } else {
        vm.msg = '';
      }
      vm.conversationStates.editableMessage = false;
      vm.editableMessageId = null;
    }

    /**
     * Сохранение отредактированного сообщения
     */
    function editMessage(editableMessageId) {
      if (isConnectionError()) {
        return;
      }

      let editableMessage = $filter('filter')(vm.activeConversationParts, { id: editableMessageId })[0];

      if (vm.msg.length > vm.REPLY_MAX_LENGTH) {
        return;
      }

      if (
        editableMessage &&
        editableMessage.body.trim().replace('<br>', '\n') !== vm.msg.trim() &&
        vm.msg.trim() !== ''
      ) {
        vm.editMessageRequestPerformed = true;

        let newMsg = vm.msg;
        newMsg = stripTags(newMsg);
        newMsg = newMsg.replace(/\n/g, '<br>');
        firstValueFrom(conversationPartModel.edit(vm.currentApp.id, editableMessageId, newMsg))
          .then(editMessageSuccess)
          .finally(editMessageFinally);
      } else if (vm.msg.trim() === '' && isMessageRemovable(editableMessage)) {
        vm.openRemoveConversationPartModal(editableMessageId).then(cancelMessageEditing);
      } else {
        cancelMessageEditing();
      }

      function editMessageFinally() {
        vm.editMessageRequestPerformed = false;
      }

      function editMessageSuccess(response) {
        if (editableMessage) {
          cancelMessageEditing();
        }
      }
    }

    function destroy() {
      cancelMessageEditing();

      typingExpireInterval && $interval.cancel(typingExpireInterval);

      typingFromUserRts && typingFromUserRts.destroy.resolve();

      for (var i = 0; i < hotKeysArray.length; i++) {
        hotkeys.del(hotKeysArray[i].combo);
      }
    }

    /***
     * Получение доступного для ответа в диалог времени
     *
     * NOTE:
     *  У некоторых интеграций есть ограничение на время ответа пользователю
     *
     * @param conversation - Диалог, у которого нужно проверить доступное для ответа времени
     *
     * @return {*}
     */
    function getReplyDuration(conversation) {
      return CHANNELS_WITH_INTEGRATION_REPLY_TIMER[conversation.external_service];
    }

    /**
     * Вставляет emojiName в текстовое поле на место курсора
     *
     * @param {String} emojiName
     */
    function addEmoji({ emojiName }) {
      insertTextToTextarea(emojiName);

      vm.emojiSelectorOpen = false;
    }

    /**
     * Добавление парта TODO сделать нормально описание функции
     *
     * @param conversation
     * @param body
     * @param type
     * @param file
     * @param bodyJson
     * @returns {{read: boolean, created: number, sent_via: string, _sending: boolean, from: {name: *, id: *, avatar: *, type: string}, id: number, random_id, type: *, body: *, direction: string}}
     */
    function addPart(conversation, body, type, file, bodyJson) {
      var randomId = Math.floor(Math.random() * 2000000000);

      body = $filter('linkify')(body);

      var part = {
        type: type,
        direction: 'a2u',
        body: body,
        id: 0,
        created: new Date().getTime() / 1000,
        read: false,
        _sending: true,
        sent_via: 'web_panel',
        from: {
          name: vm.djangoUser.prefs[vm.currentApp.id].name,
          id: vm.djangoUser.id,
          avatar: vm.djangoUser.prefs[vm.currentApp.id].avatar,
          type: 'admin',
        },
        random_id: randomId,
      };

      if (bodyJson) {
        part.body_json = bodyJson;
      }

      if (electronApi.desktopApp) {
        part.sent_via = 'app_desktop';
      }

      conversationPartModel.parse(part);

      //не пушу новое соощение, если в нем есть файл
      //чтобы сначала отображалась заглушка с загрузкой файла
      //и не надо пушить если это диалог открыт из поиска и у него загружено послденее сообщение
      if (!file && !(vm.isActiveConversationFromSearch && vm.activeConversationPartsNext)) {
        if (vm.activeConversationParts[0] && vm.activeConversationParts[0].conversation === conversation.id) {
          vm.activeConversationParts.push(part);
        }
      }

      conversation.part_last = part;
      conversation.parts_count += 1;

      return part;
    }

    /**
     * Откладывает диалог на установленную дату
     */
    function applyConversationDelayDatepicker() {
      var datepicker = angular.element(document.querySelector('#custom-delay-conversation')).data('daterangepicker');
      var diff = moment(datepicker.startDate).diff(moment(), 'minutes'); // Пересчитываем дату в количество минут до установленной даты

      // Передаем количество минут
      delayConversation(vm.activeConversation, diff);
    }

    function assignedChanged(conversation, assignee) {
      carrotquestHelper.track('Диалоги - переназначил диалог');

      const djangoUser = assignee;
      conversation.assignee = djangoUser;

      var part = addPart(conversation, '', 'assigned');
      part.assignee = djangoUser;
      part._sending = true;

      var params = {
        admin: djangoUser ? djangoUser.id : null,
        randomId: part.random_id,
      };

      // Замеряем время от начала запроса
      var requestTime = performance.now();

      return firstValueFrom(conversationModel.assign(conversation.id, params.admin, params.randomId)).then(
        assignSuccess,
      );

      function assignSuccess(response) {
        // Логируем время запроса
        longRequestTimeLogging(requestTime, conversation, part, 'assigned-changed-request');

        conversation.closed = false;
        part._sending = false;
        part.id = response.data.id;
        part.part_group = response.data.part_group;
      }
    }

    /**
     * Логирование долгих хапросов на сервер
     *
     * @param {Number} requestTime время начала отправки запроса
     * @param {Object} conversation
     * @param {Object} part
     * @param {String} message Записm для поиска в ELK
     */
    function longRequestTimeLogging(requestTime, conversation, part, message) {
      requestTime = performance.now() - requestTime;

      // Логируем только аномально долгие запросы
      if (requestTime > 20000) {
        utilsModel
          .saveLog(
            'long-request-execution',
            message,
            {
              conversation_id_str: conversation.id,
              app_id_str: vm.currentApp.id,
              request_duration_seconds: requestTime / 1000,
              request_timestamp: Date.now(),
            },
            {
              part,
              conversation,
              django_user: vm.djangoUser,
            },
          )
          .subscribe();
      }
    }

    function attachFile(files) {
      if (!vm.activeConversation) {
        return;
      }
      if (!files || files.length != 1) {
        return;
      }

      const file = files[0];

      const fileSizeMB = +(file.size / 1024 / 1024).toFixed(2);
      const fileExt = file.name.split('.').pop();

      // SVG исключён из загрузки в диалоге по причине того, что в него можно вставить <script> и совершить XSS-атаку
      if (
        !fileHelper.isAcceptedExtension(file.name) ||
        fileHelper.isFileHasExtensions(file.name, IMAGE_EXTENSION.SVG)
      ) {
        toastr.error($translate.instant('conversationsConversation.toasts.fileExtension'));
        trackInvalidFileExtension(fileExt, fileSizeMB);
        return;
      }

      var MAX_FILE_SIZE = 10 * 1024 * 1024;
      if (file.size > MAX_FILE_SIZE) {
        toastr.error(
          $translate.instant('conversationsConversation.toasts.maxFileSize', {
            maxFileSize: $filter('number')(MAX_FILE_SIZE / 1000000, 0),
          }),
        );
        trackFileSizeExceed(fileExt, fileSizeMB);
        return;
      }

      var EMPTY_FILE_SIZE = 0;
      if (file.size === EMPTY_FILE_SIZE) {
        toastr.error($translate.instant('conversationsConversation.toasts.emptyFileSize'));
        return;
      }

      var lastMessage = vm.msg;

      vm.msg = '';

      var fileModal = $uibModal.open({
        controller: 'FileConversationModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/file-conversation/file-conversation.html',
        resolve: {
          modalWindowParams: function () {
            const textMaxLength =
              vm.activeConversation.external_service === CONVERSATION_PART_SENT_VIA.TELEGRAM ? 1024 : undefined;

            return {
              activeNote: vm.noteActive,
              file: file,
              message: lastMessage,
              userName: vm.activeConversation.user.name,
              textMaxLength,
            };
          },
        },
        windowClass: utilsService.isDarkThemeActive() ? 'dark-theme' : '',
      });

      fileModal.result.then(fileSendSuccess, fileSendDismiss);

      function fileSendSuccess(response) {
        carrotquestHelper.track('Диалоги - отправил файл');
        vm.msg = response.message;
        sendReply(file);
      }
      function fileSendDismiss(response) {
        if (response === 'backdrop click' || response === 'escape key press') {
          vm.msg = lastMessage;
        } else {
          vm.msg = response.message;
        }
        focusTheEnd();
      }
    }

    function channelChanged(conversation, channel) {
      var part = addPart(conversation, '', 'channel_changed');

      part._sending = true;

      if (channel.id > 0) {
        part.channel = channel.name;
      }

      conversation.channel = channel;

      firstValueFrom(conversationModel.setChannel(conversation.id, channel.id, part.random_id)).then(function (
        response,
      ) {
        part._sending = false;
        part.id = response.data.id;
        part.part_group = response.data.part_group;
      });
    }

    /**
     * Закрытие диалога
     *
     * !!! conversation не просто так сюда передаётся
     * Смысл в том, что по хоткею ctrl+q совершается вначале снятие назначения, а уже после него, асинхронно, вызывается эта функция, закрывающая диалог
     * Дак вот, если нажать хоткей, а затем быстро выбрать другой диалог, то может закрыться тот диалог, который был выбран, а не тот, который хотел закрыть оператор
     * Происходит это из-за того, что vm.activeConversation уже сменился во время выполнения запроса снятия назначения
     * Поэтому был сделан локальный костыль с передачей диалога в параметры
     */
    function close(conversation) {
      // Если ещё не показывался поповер с онбордингом в шорткат закрытия диалога, то считаем клики по кнопке «Закрыть диалог»
      if (!localStorage.getItem(CLOSE_HOTKEY_POPOVER_LS_KEY_NAME)) {
        clicksCounter();
      }

      if (conversation.closed) {
        return;
      }

      let part = addPart(conversation, '', 'closed');
      part._sending = true;

      // Замеряем время от начала запроса
      var requestTime = performance.now();

      firstValueFrom(conversationModel.close(conversation.id, part.random_id)).then(closeSuccess);

      function closeSuccess(response) {
        // Логируем время запроса
        longRequestTimeLogging(requestTime, conversation, part, 'close-channel-request');

        conversation.closed = true;
        part._sending = false;
        part.id = response.data.id;
        part.part_group = response.data.part_group;
      }

      vm.onConversationClose && vm.onConversationClose({ conversation: conversation });
    }

    /**
     * Закрытие поповера с онбордингом в шорткат закрытия диалога
     */
    function closeHotkeyOnboardingPopover() {
      vm.isShowCloseHotkeyOnboardingPopover = false;
    }

    /**
     * Счётчик кликов по кнопке «Закрыть диалог» для показа поповера с онбордингом в шорткат закрытия диалога
     */
    function clicksCounter() {
      let numberOfClicks = localStorage.getItem(CLOSE_BUTTON_CLICKS_LS_KEY_NAME) || 0;
      numberOfClicks++;
      localStorage.setItem(CLOSE_BUTTON_CLICKS_LS_KEY_NAME, numberOfClicks);
      if (numberOfClicks > 5) {
        vm.isShowCloseHotkeyOnboardingPopover = true;
        localStorage.removeItem(CLOSE_BUTTON_CLICKS_LS_KEY_NAME);
        localStorage.setItem(CLOSE_HOTKEY_POPOVER_LS_KEY_NAME, 'true');
      }
    }

    function connectTypingRts(appId, userId) {
      // при подписке затираем всю информацию о предыдущих наборах текста
      vm.activeConversationTyping = [];

      return LongPollConnection([`conversation_user_typing.${appId}.${userId}`], (channel, message) => {
        // если активен диалог из поиска и у него загружены не все новые сообщения, то в этом случае ничего не делаем
        if (vm.isActiveConversationFromSearch && vm.activeConversationPartsNext) {
          return;
        }

        conversationTypingHandler(message);
      });
    }

    /**
     * Можно ли взаимодействовать с диалогом
     * под взаимодействием понимается:
     *  - отправка сообщений
     *  - назначение тегов
     *  - заметки, аттачи и тд
     * @return {boolean}
     */
    function canInteractWithConversation() {
      const externalId = vm.activeConversation.external_id || '';
      return !(externalId.includes('message_auto') || externalId.includes('manual'));
    }

    /**
     * Откладывание текущего диалога
     *
     * @param {Number} minutes Количество минут, на которое нужно отложить диалог
     */
    function delayConversation(conversation, minutes) {
      var part = addPart(conversation, '', 'delayed');
      part._sending = true;

      let conversationParts = vm.activeConversationParts;

      firstValueFrom(conversationModel.delay(conversation.id, minutes, part.random_id))
        .catch(delayError)
        .then(delaySuccess);

      /**
       * Ошибка при откладывании диалога, возникающая в случае когда установленное время раньше текущего
       *
       * @param {Object} error - Ошибка возвращаемая сервером
       */
      function delayError(error) {
        if (error) {
          toastr.error($translate.instant('conversationsConversation.toasts.delayError'));

          var partToRemove = $filter('filter')(conversationParts, { random_id: part.random_id }, true)[0];
          if (partToRemove) {
            // HACK timeout использован для того чтобы избежать дергания,
            //  без timeout надпись со статусом об отложенности диалога появлялется и сразу же пропадает
            $timeout(function () {
              conversationParts.splice(conversationParts.indexOf(partToRemove), 1);
            }, 2000);
          }

          return $q.reject();
        }
      }

      /**
       * Если диалог отложен успешно изменяем статус отправки
       */
      function delaySuccess() {
        part._sending = false;
      }
    }

    function executeFakeCommand(command) {
      if (command.command == '/user') {
        openUserCard();
      }
    }

    function executeSlashCommand(conversation, command) {
      command = command.replace(/(?:\r\n|\r|\n)/g, ' '); // Переносы строк заменим на пробелы
      var firstSpace = command.indexOf(' ');

      if (firstSpace == -1) {
        var params = {
          command: command,
        };
      } else {
        var params = {
          command: command.substring(0, firstSpace),
          params: command.substring(firstSpace).trim(),
        };
      }

      if (params.command == '/user') {
        executeFakeCommand(params);
      } else {
        firstValueFrom(conversationModel.command(conversation.id, params.command, params.params));
      }

      $timeout.cancel(vm.slashCommandRunningPromise);
      vm.slashCommandRunning = params.command;
      vm.slashCommandRunningShow = true;
      vm.slashCommandRunningPromise = $timeout(function () {
        vm.slashCommandRunningShow = false;
      }, 2000);
    }

    /**
     * Фокусировка курсора в конце поля ввода
     */
    function focusTheEnd() {
      // таймаут нужен из-за того, что после изменении модели vm.msg она не сразу рендерится в поле ввода
      $timeout(function () {
        quillInstance.setSelection(quillInstance.getLength() - 1);
        quillInstance.focus();
      });
    }

    /**
     * Получение текста для тултипа иконки Calendly
     *
     * @returns {string|Object}
     */
    function getCalendlyTooltipText() {
      if (vm.isCalendlyLinkPasted) {
        if (vm.activeConversation.user.props.$name) {
          return $translate.instant('conversationsConversation.bottomMenu.calendlyButton.withNameTooltip', {
            name: vm.activeConversation.user.props.$name,
          });
        }

        return $translate.instant('conversationsConversation.bottomMenu.calendlyButton.withoutNameTooltip');
      }

      return $translate.instant('conversationsConversation.bottomMenu.calendlyButton.defaultTooltip', {
        integrationTypeName: $translate.instant('models.integration.types.calendly.name'),
      });
    }

    /**
     * Получение ссылки на диалог по его ID
     *
     * @param {String} conversationId ID диалога
     */
    function getConversationUrl(conversationId) {
      return $state.href('app.content.conversations.detail', { conversationId: conversationId }, { absolute: true });
    }

    /**
     * Получение текста для тултипа у заблокированной кнопки отправки сообщения
     *
     * @returns {string}
     */
    function getDisabledSendButtonTooltipText() {
      if (systemError.states.offline) {
        return $translate.instant('conversationsConversation.bottomMenu.sendButton.offlineTooltip');
      } else if (systemError.states.rtsProblem) {
        return $translate.instant('conversationsConversation.bottomMenu.sendButton.rtsProblemTooltip', {
          projectName: PROJECT_NAME,
        });
      }
    }

    /**
     * Получение текста ошибки при превышении максимальной длины сообщения
     *
     * @type {String}
     */
    function getMaxLengthErrorText() {
      switch (vm.activeConversation.external_service) {
        case CONVERSATION_PART_SENT_VIA.FACEBOOK:
        case CONVERSATION_PART_SENT_VIA.INSTAGRAM:
        case CONVERSATION_PART_SENT_VIA.TELEGRAM:
        case CONVERSATION_PART_SENT_VIA.VIBER:
        case CONVERSATION_PART_SENT_VIA.VK:
        case CONVERSATION_PART_SENT_VIA.WHATS_APP:
          return $translate.instant(
            `conversationsConversation.messageTextarea.errors.maxlengthExternalService.${vm.activeConversation.external_service}`,
            { maxLength: vm.REPLY_MAX_LENGTH },
            'messageformat',
          );
        default:
          return $translate.instant(
            'conversationsConversation.messageTextarea.errors.maxlength',
            { maxLength: vm.REPLY_MAX_LENGTH },
            'messageformat',
          );
      }
    }

    /**
     * Получение инстанса Moment'а
     *
     * @param datetime - Datetime
     *
     * @return {Moment}
     */
    function getMomentInstance(datetime) {
      return moment(datetime * 1000);
    }

    /**
     * Получение максимальной длины сообщения
     *
     * @type {Number}
     */
    function getReplyMaxLength() {
      switch (vm.activeConversation.external_service) {
        case CONVERSATION_PART_SENT_VIA.FACEBOOK:
          return 2000;
        case CONVERSATION_PART_SENT_VIA.INSTAGRAM:
          return 1000;
        case CONVERSATION_PART_SENT_VIA.TELEGRAM:
          return 4096;
        case CONVERSATION_PART_SENT_VIA.VIBER:
          return 7000;
        case CONVERSATION_PART_SENT_VIA.VK:
          return 4096;
        case CONVERSATION_PART_SENT_VIA.WHATS_APP:
          return 4095;
        default:
          return 20000;
      }
    }

    /**
     * Получение плейсхолдера для поля ввода
     */
    function getTextareaPlaceholder() {
      var placeholder = '';

      if (isMessageSendingAllowed()) {
        if (vm.noteActive) {
          placeholder = $translate.instant('conversationsConversation.messageTextarea.placeholder.note', {
            keypressCombination: vm.djangoUser.messenger_send_by_enter ? 'Enter' : 'Ctrl+Enter',
          });
        } else if (isFacebookLastMessageAfterTimeExpire()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.facebookLastMessageAfterTimeExpire',
            { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
            'messageformat',
          );
        } else if (isInstagramLastMessageAfterTimeExpire()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.instagramLastMessageAfterTimeExpire',
            { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
            'messageformat',
          );
        } else {
          placeholder = $translate.instant('conversationsConversation.messageTextarea.placeholder.text', {
            keypressCombination: vm.djangoUser.messenger_send_by_enter ? 'Enter' : 'Ctrl+Enter',
          });
        }
      } else if (!isMessageSendingAllowed() && !isConnectionError()) {
        if (isFacebookTimeExpire()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.facebookTimeExpire',
            { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
            'messageformat',
          );
        } else if (isOperatorLostInConcurrency()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.operatorLostInConcurrency',
          );
        } else if (isInstagramTimeExpire()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.instagramTimeExpire',
            { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
            'messageformat',
          );
        } else if (isWhatsAppIntegrationRemoved()) {
          if (djangoUserModel.isOperator(vm.currentApp.id, vm.djangoUser)) {
            placeholder = $translate.instant(
              'conversationsConversation.messageTextarea.placeholder.whatsAppIntegrationRemoved.operator',
            );
          } else {
            placeholder = $translate.instant(
              'conversationsConversation.messageTextarea.placeholder.whatsAppIntegrationRemoved.admin',
            );
          }
        } else if (isWhatsAppIntegrationDisabled()) {
          if (djangoUserModel.isOperator(vm.currentApp.id, vm.djangoUser)) {
            placeholder = $translate.instant(
              'conversationsConversation.messageTextarea.placeholder.whatsAppIntegrationDisabled.operator',
            );
          } else {
            placeholder = $translate.instant(
              'conversationsConversation.messageTextarea.placeholder.whatsAppIntegrationDisabled.admin',
            );
          }
        } else if (isWhatsAppTimeExpire()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.whatsAppEdnaTimeExpire',
          );
        } else if (isYandexDialogsDisabled()) {
          placeholder = $translate.instant(
            'conversationsConversation.messageTextarea.placeholder.yandexDialogsDisabled',
          );
        }
      } else if (!isMessageSendingAllowed() && isConnectionError()) {
        // Если есть проблемы с соединением, то в плейсхолдере оставляем последний заданный вариант
        placeholder = currentPlaceholder;
      }

      currentPlaceholder = placeholder;

      return placeholder;
    }

    /**
     * Получение интеграции WhatsApp Edna, к которой привязан активный диалог
     * @param activeConversation
     */
    function getWhatsAppIntegration(activeConversation) {
      const currentIntegrationPhone = activeConversation.external_id.split(':')[0];

      integrationModel.whatsAppEdna
        .getIntegrationByPhone(vm.currentApp.id, currentIntegrationPhone)
        .then(getIntegrationSuccess);

      function getIntegrationSuccess(integrations) {
        // NOTE На один номер телефона теоретически может быть настроено несколько интеграций WhatsApp Edna.
        //  Берём первую (можем взять любую), потому что такие интеграции всё равно привязаны к одному ЛК Edna
        vm.whatsAppIntegration = integrations[0];
      }
    }

    function handleRts(event, info) {
      //Если активен диалог из поиска и у него загружены не все новые сообщения, то в этом случае
      if (vm.isActiveConversationFromSearch && vm.activeConversationPartsNext) {
        return;
      }

      const { channel, data } = info;

      // @formatter:off
      switch (true) {
        case channel.includes('conversation_replied_by_user_read.'):
          conversationRepliedByUserReadHandler(data);
          break;
        case channel.includes('conversation_assigned.'):
          conversationAssignedHandler(data);
          break;
        case channel.includes('conversation_closed.'):
          conversationClosedHandler(data);
          break;
        case channel.includes('conversation_delayed.'):
          conversationDelayedHandler(data);
          break;
        case channel.includes('conversation_delay_finished.'):
          conversationDelayFinishedHandler(data);
          break;
        case channel.includes('conversation_tag_added.'):
          conversationTagAddedHandler(data);
          break;
        case channel.includes('conversation_tag_deleted.'):
          conversationTagDeletedHandler(data);
          break;
        case channel.includes('conversation_channel_changed.'):
          conversationChannelChangedHandler(data);
          break;
        case channel.includes('conversation_reply_changed.'):
          conversationReplyChangedHandler(data);
          break;
        case channel.includes('conversation_parts_batch.'):
          conversationsPartBatchesHandler(data);
          break;
        case channel.includes('system_log_added.'):
          systemLogAddedHandler(data);
          break;
        case channel.includes('user_presence_changed.'):
          userPresenceChangedHandler(data);
          break;
      }
      // @formatter:on
    }

    /**
     * Обработичик канала conversation_replied_by_user_read.
     * @param data
     */
    function conversationRepliedByUserReadHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id != data.id) {
        return;
      }

      let callApply = false;

      for (var i = 0; i < vm.activeConversationParts.length; i++) {
        if (vm.activeConversationParts[i].direction === 'a2u') {
          vm.activeConversationParts[i].read = true;
          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Обработка канала ввода текста (typing)
     * @param data
     */
    function conversationTypingHandler(data) {
      if (!vm.activeConversation) {
        return;
      }

      // показываем сообщения только для того диалога, в котором находимся
      if (vm.activeConversation.id != data.conversation) {
        return;
      }

      // игнорируем свои сообщения, их выводить ненужно
      if (data.admin && data.admin.id == vm.djangoUser.id) {
        return;
      }

      // заполняем данные о наборе текста
      let newTyping = {
        ...data,
        started: moment(),
      };

      if (newTyping.admin) {
        newTyping.type = 'typing_admin';
        newTyping.fromId = newTyping.admin.id;
        newTyping.from = newTyping.admin;
      } else if (newTyping.user) {
        newTyping.type = 'typing_user';
        newTyping.fromId = newTyping.user;
      }

      /**
       * Заменён ли старый typing на новый
       */
      let typingAlreadyReplaced = false;

      // пытаемся найти уже существующий typing, если он находится - заменяем его
      vm.activeConversationTyping = vm.activeConversationTyping.map((typing) => {
        if (typing.fromId === newTyping.fromId) {
          typingAlreadyReplaced = true;
          return newTyping;
        } else {
          return typing;
        }
      });

      // если typing не был заменён - он новый, добавляем его
      if (!typingAlreadyReplaced) {
        vm.activeConversationTyping.push(newTyping);
      }

      $scope.$applyAsync();
    }

    /**
     * Обработчик канала conversation_assigned.
     * @param data
     */
    function conversationAssignedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id != data.conversation) {
        return;
      }

      vm.activeConversation.assignee = data.assignee;
      if (!data.assignee) {
        vm.assignee = null;
      } else {
        // !!! не понятно что делать в случае, если придёт член команды, которого нету в списке (т.е. он был создан, пока другие члены команды сидели в диалогах)
        // пока что сделано так, что диалог в этом случае будет отображаться как не назначенный ни на кого
        let teamMember = $filter('filter')(vm.teamMembers, { id: data.assignee.id }, true)[0] || null;
        vm.assignee = teamMember;
      }

      $scope.$applyAsync();
    }

    /**
     * Постобработка закрытия диалога
     * @param data
     */
    function conversationClosedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id != data.conversation) {
        return;
      }

      vm.activeConversation.closed = true;
      $scope.$applyAsync();
    }

    /**
     * Обработчик канала conversation_delayed.
     * @param data
     */
    function conversationDelayedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id != data.conversation) {
        return;
      }

      vm.activeConversation.delayed_until = moment(data.conversation.delayed_until * 1000);
      $scope.$applyAsync();
    }

    /**
     * Обработчик канала conversation_delay_finished.
     * @param data
     */
    function conversationDelayFinishedHandler(data) {
      if (!vm.activeConversation) {
        return;
      }

      let callApply = false;

      (data.conversation || []).forEach((conversation) => {
        if (vm.activeConversation.id != conversation.id) {
          return;
        }
        vm.activeConversation.last_update = moment(conversation.last_update * 1000);
        callApply = true;
      });

      callApply && $scope.$applyAsync();
    }

    /**
     *
     * Постобработка добавления тега к диалогу
     * @param data
     */
    function conversationTagAddedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id !== data.conversation) {
        return;
      }

      let callApply = false;

      const foundAddedTagInList = (vm.activeConversation.tags || []).includes(data.tag);
      if (!foundAddedTagInList) {
        vm.activeConversation.tags.push(data.tag);
        callApply = true;
      }

      const foundAddedTag = vm.activeConversationTags.find((tag) => tag.tag === data.tag);
      if (!foundAddedTag) {
        vm.activeConversationTags = [...vm.activeConversationTags, { tag: data.tag }];
        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Постобработка удаления тега из диалога
     * @param data
     */
    function conversationTagDeletedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id != data.conversation) {
        return;
      }

      let idx = vm.activeConversation.tags.indexOf(data.tag);
      if (idx != -1) {
        vm.activeConversation.tags.splice(idx);
      }

      vm.activeConversationTags = vm.activeConversationTags.filter((t) => t.tag !== data.tag);

      $scope.$applyAsync();
    }

    /**
     * Постобработка изменения канала диалога
     * @param data
     */
    function conversationChannelChangedHandler(data) {
      if (vm.activeConversation.id !== data.conversation) {
        return;
      }

      let foundChannel;

      if (data.channel && data.channel.id) {
        foundChannel = $filter('filter')(vm.channels, { id: data.channel.id }, true)[0];
      }

      // если в data.channel пришёл null - диалог убрали из всех каналов, поэтому в vm.channel нужно положить псевдоканал "Без канала"
      // если data.channel пришёл, но его нет в списке каналов (такое может быть, если был создан новый канал пока оператор сидел в диалогах) - то же самое
      if (!foundChannel) {
        foundChannel = $filter('filter')(
          vm.channels,
          { id: PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL] },
          true,
        )[0];
      }

      vm.channel = foundChannel;

      $scope.$applyAsync();
    }

    /**
     * Постобработка изменения реплики в диалоге
     * @param data
     */
    function conversationReplyChangedHandler(data) {
      if (!vm.activeConversation || vm.activeConversation.id !== data.conversation) {
        return;
      }

      let callApply = false;

      for (let i = 0; i < vm.activeConversationParts.length; i++) {
        if (vm.activeConversationParts[i].id !== data.id) {
          continue;
        }

        vm.activeConversationParts[i].body = data.body;
        if (!moment.isMoment(data.created)) {
          vm.activeConversationParts[i].created = moment(data.created * 1000);
        }
        vm.activeConversationParts[i].edited = data.edited;
        vm.activeConversationParts[i].removed = data.removed;
        vm.activeConversationParts[i].meta_data = data.meta_data;

        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Обработчик сообщений из РТС-канала conversation_parts_batch
     *
     * @param data.conversation - Диалог
     * @param data.parts - Реплики
     * @param data.random_id - Рандомный идентификатор реплики
     */
    function conversationsPartBatchesHandler(data) {
      const conversation = data.conversation;
      const parts = data.parts;
      const randomId = data.random_id;

      // Выход из обработчика, если сообщение содержит в себе пустой диалог или диалог без параметров
      if (!conversation || Object.keys(conversation).length === 0) {
        return;
      }

      // Выход из обработчика, если активный диалог не инициализирован или сообщение не относится к активному диалог
      if (!vm.activeConversation || vm.activeConversation.id !== conversation.id) {
        return;
      }

      // Преобразование полей диалога. Во время рефакторинга раздела с диалогами, необходимо перенести парсинг в модель
      conversation.created = moment.isMoment(conversation.created)
        ? conversation.created
        : getMomentInstance(conversation.created);
      if (conversation.meta_data && conversation.meta_data.answer_time) {
        conversation.meta_data.answer_time = getMomentInstance(conversation.meta_data.answer_time);
      }
      if (conversation.meta_data && conversation.meta_data.delayed_until) {
        conversation.meta_data.delayed_until = getMomentInstance(conversation.meta_data.delayed_until);
      }

      // Обновление данных в активном диалоге
      vm.activeConversation.last_update = conversation.created;
      vm.activeConversation.not_answered_admin_replies = conversation.not_answered_admin_replies;
      vm.activeConversation.last_user_reply_time = moment.isMoment(conversation.last_user_reply_time)
        ? conversation.last_user_reply_time
        : getMomentInstance(conversation.last_user_reply_time);
      vm.activeConversation.delayed_until = conversation.delayed_until && getMomentInstance(conversation.delayed_until);

      // Отрытие диалога, если пользователь или оператор в него написали
      if (conversation.part_last.type === 'reply_user' || conversation.part_last.type === 'reply_admin') {
        vm.activeConversation.closed = false;
      }

      // Обработка пришедших в сообщении реплик
      parts.forEach((part, partIndex) => {
        // Во время рефакторинга раздела с диалогами, необходимо перенести парсинг в модель
        part.created = moment.isMoment(part.created) ? part.created : getMomentInstance(part.created);

        // Поиск реплик пришедших в сообщении РТС в активном диалоге
        const foundPart = findMessagePart(vm.activeConversationParts, part, randomId);
        if (foundPart) {
          if (part.type === 'delayed') {
            foundPart.meta_data = part.meta_data;
            if (!moment.isMoment(foundPart.meta_data.delayed_until)) {
              foundPart.meta_data.delayed_until = getMomentInstance(foundPart.meta_data.delayed_until);
            }
          }
        } else {
          /*
           * Игнорирование реплики с файлом, т.к. её добавление в активный диалог и удаление файла из списка загружаемых
           * должно происходить после успешного ответа сервера, а не сигнала из РТС.
           */
          const isReplyTheSameAdmin = part.type === 'reply_admin' && part.from.id === vm.djangoUser.id;
          const isReplyWithAttachmentsFile =
            part.attachments && part.attachments[0] && part.attachments[0].type === 'file';
          if (!(isReplyTheSameAdmin && isReplyWithAttachmentsFile)) {
            vm.activeConversationParts.push(part);
          }

          part.meta_data.delayed_until = getMomentInstance(part.meta_data.delayed_until);
        }
      });

      vm.channel = conversation.channel
        ? $filter('filter')(vm.channels, { id: conversation.channel.id }, true)[0]
        : $filter('filter')(vm.channels, { id: PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL] }, true)[0];

      // Обработка статусов о наборе текста
      // NOTE пришлось делать через forEach, т.к если делать через vm.activeConversationTyping = vm.activeConversationTyping.filter(...), то при добавлении парта тело диалога скачет
      // NOTE проверить легко: просто из чата набирать текст, чтобы появился typing, а потом его отправить. И во время смены typing на сообщение, будет скачок контента тела диалога
      // NOTE Это происходит из-за переприсвоения массива. Кажется, виноват vue, и я даже пытался это починить в теле диалога, но не вышло
      [...vm.activeConversationTyping].forEach((typing, index) => {
        parts.forEach((part) => {
          let fromId;

          if (part.type === 'reply_user') {
            fromId = part.from;
          } else if (part.type === 'reply_admin') {
            fromId = part.from.id;
          }

          // Удаляем статус о наборе текста после ответа админа или пользователя
          if (typing.fromId === fromId) {
            vm.activeConversationTyping.splice(index, 1);
          }
        });
      });

      $scope.$applyAsync();
    }

    /**
     * Постобработка системных событий
     *  1)удаление тега (какой-то костыль)
     * @param data
     */
    function systemLogAddedHandler(data) {
      if (data.type === SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED) {
        const tag = data.meta_data.tag;

        vm.tags = vm.tags.filter((t) => t.tag !== tag);

        conversationsStoreService.removeTag(tag);

        vm.activeConversationTags = vm.activeConversationTags.filter((t) => t.tag !== tag);

        const activeConversationTagsIndex = vm.activeConversation.tags.indexOf(data.tag);
        if (activeConversationTagsIndex !== -1) {
          vm.activeConversation.tags.splice(activeConversationTagsIndex, 1);
        }

        $scope.$applyAsync();
      }
    }

    /**
     * Постобработка изменения видимости пользователя
     * @param data
     */
    function userPresenceChangedHandler(data) {
      let callApply = false;

      for (let i = 0; i < data.length; i++) {
        if (vm.activeConversation && vm.activeConversation.user && vm.activeConversation.user.id === data[i].user) {
          vm.activeConversation.user.presence = data[i].presence;
          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Поиск реплики в активном диалоге
     *
     * @param {Array<Object>} activeConversationParts Реплики активного диалога
     * @param {Object} part Реплика, которую ищем
     * @param {string?} randomId
     * @returns {Object} Найденная реплика
     */
    function findMessagePart(activeConversationParts, part, randomId) {
      const foundMessagePart = activeConversationParts.find((activePart) => {
        // Проверка по ID реплики, если ID реплики задан
        if (activePart.id) {
          return activePart.id === part.id;
        }

        // Проверка по randomId, если ID реплики не задан
        if (randomId && activePart.random_id === randomId) {
          return true;
        }
      });

      return foundMessagePart;
    }

    /**
     * Скрытие поповера базы знаний
     */
    function hideArticleList() {
      vm.articlesListIsOpen = false;
    }

    /**
     * Скрытие поповера Calendly
     */
    function hideCalendlyPopover() {
      vm.calendlyPopoverIsOpen = false;
    }

    /**
     * Вставка текста в поле ввода
     * Если какой-то текст в поле ввода выделен - он будет заменён на вставляемый текст, так же как это происходит при вставке из буфера обмена
     *
     * @param {string} text Текст для вставки
     */
    function insertTextToTextarea(text) {
      const selection = quillInstance.getSelection(true);

      if (selection.length) {
        // удалять старый текст нужно от имени пользователя, чтобы можно было откатить изменения по Ctrl+Z
        quillInstance.deleteText(selection.index, selection.length, 'user');
      }

      // вставлять новый текст нужно от имени пользователя, чтобы можно было откатить изменения по Ctrl+Z
      quillInstance.insertText(selection.index, text, 'user');
      quillInstance.setSelection(selection.index + text.length);
      quillInstance.focus();
    }

    /**
     * Флаг что есть проблемы с интернет-соединением или с RTS
     *
     * @returns {boolean}
     */
    function isConnectionError() {
      return vm.systemError.states.offline || vm.systemError.states.rtsProblem;
    }

    /** Недоступность возможности отложить диалог */
    function isConversationDelayDisabled() {
      return (
        isWhatsAppIntegrationDisabled() ||
        isWhatsAppIntegrationRemoved() ||
        isOperatorLostInConcurrency() ||
        isYandexDialogsDisabled() ||
        isConnectionError() ||
        vm.activeConversation.closed
      );
    }

    /**
     * Проверка, закончился ли таймер ответа пользователю в диалог
     *
     * NOTE:
     *  Проверка по последнему времени ответа пользователя
     *
     * @param conversation - Диалог, который нужно проверить
     *
     * @return {boolean}
     */
    function isConversationReplyTimerNotEnded(conversation) {
      const lastUserReply = conversation.last_user_reply_time;
      const now = moment();

      return now.diff(lastUserReply, 'hour') < CHANNELS_WITH_INTEGRATION_REPLY_TIMER[conversation.external_service];
    }

    /**
     * Проверка, является ли диалог, диалогом с ограниченным временем ответа
     *
     * NOTE:
     *  У некоторых интеграций есть ограничение на время ответа пользователю
     *
     * @param conversation - Диалог, который нужно проверить
     *
     * @return {boolean}
     */
    function isConversationWithReplyTimer(conversation) {
      return !!CHANNELS_WITH_INTEGRATION_REPLY_TIMER[conversation.external_service];
    }

    /**
     * Посылается ли последнее сообщение в фейсбук
     * После того, как прошло 7 дней оператор может послать только 1 реплику пользователю
     *
     * @returns {boolean}
     */
    function isFacebookLastMessageAfterTimeExpire() {
      var now = moment();
      var expirationTime = moment(vm.activeConversation.last_user_reply_time).add(HUMAN_AGENT_TIME_EXPIRE, 'day');

      // если прошло уже 7 дней, и последняя реплика пользователя была отправлена до истечения 7 дней - даём ему возможность отправить последнюю реплику
      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.FACEBOOK &&
        now > expirationTime &&
        vm.activeConversation.reply_last.created < expirationTime
      );
    }
    /**
     * Посылается ли последнее сообщение в инстаграм
     * После того, как прошло 7 дней оператор может послать только 1 реплику пользователю
     *
     * @returns {boolean}
     */
    function isInstagramLastMessageAfterTimeExpire() {
      var now = moment();
      var expirationTime = moment(vm.activeConversation.last_user_reply_time).add(HUMAN_AGENT_TIME_EXPIRE, 'day');

      // если прошло уже 7 дней, и последняя реплика пользователя была отправлена до истечения 7 дней - даём ему возможность отправить последнюю реплику
      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.INSTAGRAM &&
        now > expirationTime &&
        vm.activeConversation.reply_last.created < expirationTime
      );
    }

    /**
     * Просрочена ли отправка сообщений в фейсбук
     * После того, как прошло 7 дней оператор может послать только 1 реплику пользователю
     *
     * @returns {boolean}
     */
    function isFacebookTimeExpire() {
      var now = moment();
      var expirationTime = moment(vm.activeConversation.last_user_reply_time).add(HUMAN_AGENT_TIME_EXPIRE, 'day');

      // если прошло уже 7 дней, и последняя реплика пользователя была отправлена после 7 дней, то больше сообщений оператор отправить не может
      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.FACEBOOK &&
        now > expirationTime &&
        vm.activeConversation.reply_last.created > expirationTime
      );
    }
    /**
     * Просрочена ли отправка сообщений в инстаграм
     * После того, как прошло 7 дней оператор может послать только 1 реплику пользователю
     *
     * @returns {boolean}
     */
    function isInstagramTimeExpire() {
      var now = moment();
      var expirationTime = moment(vm.activeConversation.last_user_reply_time).add(HUMAN_AGENT_TIME_EXPIRE, 'day');

      // если прошло уже 7 дней, и последняя реплика пользователя была отправлена после 7 дней, то больше сообщений оператор отправить не может
      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.INSTAGRAM &&
        now > expirationTime &&
        vm.activeConversation.reply_last.created > expirationTime
      );
    }

    /**
     * Проверка на редактируемость сообщения
     * Нельзя редактировать сообщение, если оно:
     * 1. Не из CONVERSATION_PART_EDITABLE_TYPES
     * 2. Первое в списке партов и это емейл. Понять, что это емейл можно по комбинации поле message.first и vm.activeConversation.message
     * 3. Сообщение отправлено из интеграции
     * 4. У него пустое body
     * 5. У него есть что-то в body_json (кнопки, статья из БЗ)
     *
     * @param {Object} message Сообщение
     * @returns {boolean}
     */
    function isMessageEditable(message) {
      return (
        !vm.conversationStates.externalService &&
        !!~CONVERSATION_PART_EDITABLE_TYPES.indexOf(message.type) &&
        !~CONVERSATION_PART_NON_EDITABLE_NON_REMOVABLE_TYPES.indexOf(message.sent_via) &&
        !message.external_service &&
        message.body !== '' &&
        (!message.body_json || Object.keys(message.body_json).length === 0)
      );
    }

    /**
     * Проверка на удаляемость сообщения
     * Нельзя удалять сообщение, если оно:
     * 1. Не из CONVERSATION_PART_REMOVABLE_TYPES
     * 2. Первое в списке партов и это емейл. Понять, что это емейл можно по комбинации поле message.first и vm.activeConversation.message
     * 3. Сообщение отправлено из интеграции
     *
     * @param {Object} message Сообщение
     * @returns {boolean}
     */
    function isMessageRemovable(message) {
      return (
        !!~CONVERSATION_PART_REMOVABLE_TYPES.indexOf(message.type) &&
        !~CONVERSATION_PART_NON_EDITABLE_NON_REMOVABLE_TYPES.indexOf(message.sent_via) &&
        !message.external_service
      );
    }

    /**
     * Можно ли отправлять сообщения в чат
     *
     * @returns {boolean|*}
     */
    function isMessageSendingAllowed() {
      return (
        vm.noteActive ||
        !(
          isFacebookTimeExpire() ||
          isInstagramTimeExpire() ||
          isWhatsAppTimeExpire() ||
          isWhatsAppIntegrationDisabled() ||
          isWhatsAppIntegrationRemoved() ||
          isOperatorLostInConcurrency() ||
          isYandexDialogsDisabled() ||
          isConnectionError()
        )
      );
    }

    /**
     * Может ли оператор совершать действия с диалогом, или диалог уже перехватил другой оператор
     *
     * @returns {Boolean}
     */
    function isOperatorLostInConcurrency() {
      return (
        djangoUserModel.isOperatorWithConcurrency(vm.djangoUser, vm.currentApp) &&
        vm.activeConversation.assignee &&
        vm.activeConversation.assignee.id !== vm.djangoUser.id
      );
    }

    /**
     * Проверка нужно ли показать кнопку открытия шаблонов WA.
     * Показываем только для диалогов WA.
     *
     * @param {string} activeConversationExternalService external_service диалога
     * @returns {boolean}
     */
    function isShowWhatsAppTemplatesBtn(activeConversationExternalService) {
      return (
        activeConversationExternalService === INTEGRATION_TYPES.WHATS_APP_EDNA &&
        !isWhatsAppIntegrationDisabled() &&
        !isWhatsAppIntegrationRemoved()
      );
    }

    /**
     * Просрочена ли отправка сообщений в диалог из WhatsApp
     * Можно посылать сообщения только в течение 24 часов после последнего ответа пользователя
     *
     * @returns {boolean}
     */
    function isWhatsAppTimeExpire() {
      const now = moment();
      const diff = now.diff(vm.activeConversation.last_user_reply_time, 'hour');

      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.WHATS_APP_EDNA &&
        diff >= CHANNELS_WITH_INTEGRATION_REPLY_TIMER[vm.activeConversation.external_service]
      );
    }

    /**
     * Проверяем что интеграция с WA для данного диалога WA отключена
     *
     * @returns {boolean}
     */
    function isWhatsAppIntegrationDisabled() {
      return (
        vm.activeConversation.external_service === INTEGRATION_TYPES.WHATS_APP_EDNA && !vm.whatsAppIntegration?.active
      );
    }

    /**
     * Проверяем что интеграция с WA для данного диалога WA удалена
     *
     * @returns {boolean}
     */
    function isWhatsAppIntegrationRemoved() {
      return vm.activeConversation.external_service === INTEGRATION_TYPES.WHATS_APP_EDNA && !vm.whatsAppIntegration;
    }

    /**
     * С 16.08.2021 яндекс.диалоги не работают, https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/67cf7916416c0133b25bb9df?card=Car-25864
     * @return {boolean}
     */
    function isYandexDialogsDisabled() {
      return vm.activeConversation.external_service === INTEGRATION_TYPES.YANDEX_DIALOGS;
    }

    function loadConversationParts(isToSearch) {
      var paginationParams = {};

      //NOTE У диалога part_last есть только в случае если мы получаем список диалогов НЕ из поиска и в случае когда мы написали в диалог в который зашли из поиска. И получается что когда кликаешь на диалог, который вывелся через поиск, но при этом ты в него уже что-то наисал, то у него есть part_last и поэтому он пойдет во 2 ветку условия. Поэтому пришлось добавить isToSearch который говорит о том что был выбран диалог через клик по нему
      if (vm.activeConversation.isSearching && (!vm.activeConversation.part_last || isToSearch)) {
        vm.activeConversation.part_last = null;
        paginationParams.paginateDirection = REPLY_TYPES.AROUND;
        paginationParams.paginatePosition = vm.activeConversation.found_part.id;
        vm.isGlued = false;
      } else {
        paginationParams.paginateDirection = REPLY_TYPES.BEFORE;
      }

      vm.conversationPartsLoading = true;
      let conversationPartsLoadingFor = vm.activeConversation.id; // Запоминаем для какого диалога грузим парты

      var preprocessingTasks = [
        firstValueFrom(conversationPartModel.getList(vm.activeConversation.id, null, paginationParams)),
      ];

      return $q.all(preprocessingTasks).then(loadConversationPartsSuccess).finally(loadConversationPartsFinally);

      function loadConversationPartsSuccess(responses) {
        // Если во время загрузки диалог изменился, то с полученными партами для старого диалога ни чего делат не надо
        // Это надо, чтобы избежать косяков со скролом в диалоге
        if (conversationPartsLoadingFor !== vm.activeConversation.id) {
          return;
        }
        var conversationParts = responses[0];
        var botTip = responses[1];

        //елси при выхове функции мы не указали requestType => получаются сообщения после выбора диалога. Значит надо обновить activeConversationPartsBefore и activeConversationPartsNext
        vm.activeConversationParts = conversationParts.parts;
        vm.activeConversationPartsNext = conversationParts.lastId;
        vm.activeConversationPartsBefore = conversationParts.firstId;

        if (botTip && !angular.equals({}, botTip)) {
          vm.activeConversationParts.push(botTip);
        }

        setConversationPartMessageTitle(vm.activeConversation, vm.activeConversationParts);
      }

      function loadConversationPartsFinally() {
        // Если во время загрузки диалог изменился, то с полученными партами для старого диалога ни чего делат не надо
        // Это надо, чтобы избежать косяков со скролом в диалоге
        if (conversationPartsLoadingFor !== vm.activeConversation.id) {
          return;
        }
        vm.conversationPartsLoading = false;

        if (vm.activeConversation.isSearching && (!vm.activeConversation.part_last || isToSearch)) {
          //если мы открываем сообщения по поиску, надо проскроллить к нужному сообщению. Но на момент вызова функции сообщений еще нет в DOM, поэтому использую $interval. 50мс задано рандомно
          var interval = $interval(scrollToPart, 50);
        }
        function scrollToPart() {
          if (angular.element('#' + SCROLL_TO_PART)[0]) {
            $interval.cancel(interval);

            let container = angular.element('#webView')[0];

            // HACK Никаким другим способом решить проблему с дёрганием не удалось
            //  Чтобы предотвратить смещение viewport'а из-за дозагрузки контента, запрещаем скролить контейнер
            if (container) {
              container.style.overflow = 'hidden';
              container.style.maxHeight = '100%';

              $timeout(() => {
                container.style.overflow = '';
                container.style.maxHeight = '';
              }, 1000);
            }

            document.querySelector('#' + SCROLL_TO_PART).scrollIntoView({ block: 'center' });
          }
        }
      }
    }

    /**
     * Подгружает сообщения в диалоге
     *
     * @param {REPLY_TYPES} requestType
     */
    function loadMoreParts(requestType) {
      var paginationParams = {};

      paginationParams.paginateDirection = requestType;
      paginationParams.paginatePosition =
        requestType === REPLY_TYPES.BEFORE ? vm.activeConversationPartsBefore : vm.activeConversationPartsNext;
      vm.isGlued = requestType === REPLY_TYPES.AFTER; // Скролить вниз только при подгрузке более новых сообщений
      vm.conversationPartsLoading = true;

      if (requestType === REPLY_TYPES.BEFORE) {
        // в момент загрузки контента показываем лоадер сверху
        vm.conversationStates.showBackContentLoader = true;
        vm.conversationStates.backContentLoading = true;
      }

      if (requestType === REPLY_TYPES.AFTER) {
        // в момент загрузки контента показываем лоадер снизу
        vm.conversationStates.showForwardContentLoader = true;
        vm.conversationStates.forwardContentLoading = true;
      }

      firstValueFrom(conversationPartModel.getList(vm.activeConversation.id, null, paginationParams))
        .then(loadMorePartsSuccess)
        .finally(loadMorePartsFinally);

      function loadMorePartsFinally() {
        vm.conversationPartsLoading = false;

        // когда загрузка контента завершена скрываем все лоадеры
        vm.conversationStates.showBackContentLoader = false;
        vm.conversationStates.backContentLoading = false;
        vm.conversationStates.showForwardContentLoader = false;
        vm.conversationStates.forwardContentLoading = false;
      }

      function loadMorePartsSuccess(conversationParts) {
        setConversationPartMessageTitle(vm.activeConversation, conversationParts.parts);

        if (requestType === REPLY_TYPES.BEFORE) {
          //елси получили следующие новые сообщения, но надо обновить activeConversationPartsBefore и добавить их в начало
          vm.activeConversationParts = conversationParts.parts.concat(vm.activeConversationParts);
          vm.activeConversationPartsBefore = conversationParts.firstId;
        } else if (requestType === REPLY_TYPES.AFTER) {
          //елси получили следующие новые сообщения, но надо обновить activeConversationPartsNext и добавить их в конец
          vm.activeConversationParts = vm.activeConversationParts.concat(conversationParts.parts);
          vm.activeConversationPartsNext = conversationParts.lastId;
        }
      }
    }

    /**
     * Колбэк инициализации Quill
     *
     * @param {Quill} instance
     */
    function onQuillCreated(instance) {
      quillInstance = instance;
      vm.quillInstance = quillInstance;

      // NOTE: горячие клавиши биндятся после инициализации. Почему - написано вот тут, ну и ещё много где https://github.com/quilljs/quill/issues/1967
      //  вообще, горячие клавиши в Quill как сильная, так и слабая сторона
      setupQuillHotkeys(quillInstance);
      focusTheEnd();
    }

    /**
     * Коллбэк на изменение текста в поле ввода
     *
     * @param {string} text Значение из поля ввода quill
     */
    function onQuillValueChanges(text) {
      vm.messageForm.message.$setTouched();
      vm.messageForm.message.$setDirty();
      // HACK: тут специально используется $apply, а не $applyAsync,
      //  т.к. иначе при быстром вводе сообщения и мгновенном нажатии на Enter, сообщение уйдёт, но поле ввода не очистится
      //  смотри handleEnter и handleCtrlEnter, используемый там $timeout связан с этим $apply
      $scope.$apply();
    }

    /**
     * Подгрузка новых сообщений при прокрутке вниз
     */
    function onScrollBottom() {
      // Если есть контентент для отображения и в данный момент не происходит его загрузка, делаем запрос на сервер
      if (vm.activeConversationPartsNext && !vm.conversationPartsLoading) {
        loadMoreParts(REPLY_TYPES.AFTER);
      }
    }

    /**
     * Подгрузка новых сообщений при прокрутке наверх
     */
    function onScrollTop() {
      // Если есть контентент для отображения и в данный момент не происходит его загрузка, делаем запрос на сервер
      if (vm.activeConversationPartsBefore && !vm.conversationPartsLoading) {
        loadMoreParts(REPLY_TYPES.BEFORE);
      }
    }

    function onTagAdded(conversation, $tag) {
      var part = addPart(conversation, '', 'tag_added');
      part.tag = $tag.tag;
      part._sending = true;

      firstValueFrom(conversationModel.addTag(conversation.id, part.tag, part.random_id)).then(function (response) {
        part._sending = false;
        part.id = response.data.id;
        part.part_group = response.data.part_group;
      });

      // Добавить в список тегов для поиска
      var found = false;
      for (var i = 0; i < vm.tags.length; i++) {
        if (vm.tags[i].tag === $tag.tag) {
          found = true;
        }
      }
      if (!found) {
        vm.tags = [...vm.tags, $tag];
        conversationsStoreService.addTag($tag.tag);
      }

      // Добавить в текущий разговор
      conversation.tags.push($tag.tag);
    }

    function onTagRemoved(conversation, $tag) {
      var part = addPart(conversation, '', 'tag_deleted');
      part.tag = $tag.tag;
      part._sending = true;

      firstValueFrom(conversationModel.removeTag(conversation.id, part.tag, part.random_id)).then(function (response) {
        part._sending = false;
        part.id = response.data.id;
        part.part_group = response.data.part_group;
      });

      // Удалить из текущего диалога
      var index = conversation.tags.indexOf($tag.tag);
      conversation.tags.splice(index, 1);
    }

    /**
     * Колбэк на вставку текста в поле для ввода сообщения
     *
     * @param event
     */
    function onTextPaste(event) {
      let pastedText;
      if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      } else {
        pastedText = (event.originalEvent || event).clipboardData.getData('text/plain');
      }

      const pastedTextLength = pastedText.length;

      if (pastedTextLength >= 60 && pastedTextLength <= 1200) {
        carrotquestHelper.track('Диалоги - Вставка ответа из буфера обмена', {
          pasted_text_length: pastedTextLength,
          conversation_id: vm.activeConversation.id,
          part_last_id: vm.activeConversation.part_last.id,
          pasted_text: pastedText,
        });
      }
    }

    /**
     * Открывает датапикер для выбора даты на которую будет отложена беседа
     */
    function openConversationDelayDatepicker() {
      var daterangepicker = angular
        .element(document.querySelector('#custom-delay-conversation'))
        .data('daterangepicker');

      // по умолчанию указываем текущее и минимальное время - плюс 5 минут
      var currentTime = moment().add(5, 'minutes');

      // задаем минимальное и выбранное значение
      daterangepicker.setMinDate(currentTime);
      daterangepicker.setStartDate(currentTime);
      daterangepicker.setEndDate(''); // HACK т.к. при повторном выборе значения датапикер воспринимает, что выбрана вторая дата диапозона, обнуляем конечную дату

      daterangepicker.show();
    }

    /**
     * Открывает модальное окно создания базы знаний
     */
    function openCreateKnowladgeBaseModal() {
      let modal = modalHelperService.open(KnowledgeBaseActivateComponent, { size: 'md' });

      modal.componentInstance.modalWindowParams = {
        currentApp: vm.currentApp,
      };
    }

    /**
     * Открытие модалки с горячими клавишами
     */
    function openHotkeysModal() {
      $uibModal
        .open({
          component: 'cqHotkeysModalWrapper',
          resolve: {
            djangoUser: angular.bind(null, angular.identity, vm.djangoUser),
          },
          size: 'lg',
        })
        .result.catch(() => {});
    }

    /**
     * Открытие модалки удаления сообщения из диалога
     */
    function openRemoveConversationPartModal(messageId) {
      var removableConversationPart = $filter('filter')(vm.activeConversationParts, { id: messageId })[0];
      var removeConversationPartModal = $uibModal.open({
        component: 'cqRemoveConversationPartModal',
        resolve: {
          activeConversation: angular.bind(null, angular.identity, vm.activeConversation),
          activeConversationParts: angular.bind(null, angular.identity, [removableConversationPart]),
          conversationStates: angular.bind(null, angular.identity, {
            hideDialogueDate: true,
          }),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          djangoUser: angular.bind(null, angular.identity, vm.djangoUser),
          isDarkThemeActive: angular.bind(null, angular.identity, vm.isDarkThemeActive),
          knowledgeBaseDomain: angular.bind(null, angular.identity, vm.knowledgeBaseDomain),
        },
        size: 'md modal-dialog-scrollable',
        windowClass: utilsService.isDarkThemeActive() ? 'dark-theme' : '',
      });

      return removeConversationPartModal.result.then(removeConversationPartSuccess);

      function removeConversationPartSuccess() {
        removableConversationPart.body = '';
        removableConversationPart.removed = moment().format('X');
        toastr.success($translate.instant('conversationsConversation.toasts.removeConversationPartSuccess'));
      }
    }

    function openUserCard() {
      carrotquestHelper.track('Диалоги - открыл карточку пользователя', { App: vm.currentApp.name });
      $uibModal.open({
        component: 'cqUserCardModal',
        resolve: {
          modalWindowParams: () => {
            return {
              billingInfo: vm.billingInfo,
              currentApp: vm.currentApp,
              djangoUser: vm.djangoUser,
              onOpenConversationClick: vm.onOpenConversationClick,
              userId: vm.activeConversation.user.id,
              updateActiveConversation: updateActiveConversation,
              telegramIntegrations: vm.telegramIntegrations,
            };
          },
        },
        size: 'lg',
        windowClass: 'user-card-modal',
      });

      /**
       * Если поменялся email у пользователя в модалке, то обновляет activeConversation
       * @param email
       */
      function updateActiveConversation(email) {
        vm.updateActiveConversation({ email: email });
      }
    }

    /**
     * Заменяет теги <a> на значение их атрибутра href
     *
     * @param {String} str — Строка, в которой нужно произвести замену
     * @returns {String}
     */
    function replaceLinks(str) {
      var div = $document[0].createElement('div');
      div.innerHTML = str;
      angular.forEach(angular.element(div.querySelectorAll('a')), function (el) {
        var text = $document[0].createTextNode(el.getAttribute('href'));
        el.replaceWith(text);
      });

      return div.innerText;
    }

    /**
     * Отправка сообщения
     * NOTE Не знал как хорошо назвать функцию. По сути, она необходима для отправки сообщений из всяких интеграций и т.п.
     *
     * @param {String} type - Тип сообщения
     * @param {Object|String} body - контент, который будет в body сообщения
     * @param {Object=} bodyJson - контент, который будет в body_json сообщения
     * @param {String=} externalId - id для внешних сервисов
     * @param {File=} attachments - прикрепляемые файлы
     * @param {Boolean=} needAssign - Нужно ли назначить диалог на себя
     */
    function sendAdvancedMessage(type, body, bodyJson, externalId, attachments, needAssign) {
      const hasAttachments = attachments && !!attachments[0];
      let file;
      let parsedBody = body.replace(/\n/g, '<br>');

      var part = addPart(vm.activeConversation, parsedBody, type, attachments, bodyJson);
      caseStyleHelper.keysToUnderscore(bodyJson);
      var params = {
        body: parsedBody,
        bodyJson: bodyJson,
        conversation: vm.activeConversation.id,
        randomId: part.random_id,
        type: type,
        id_as_string: true,
      };

      if (externalId) {
        params.externalId = externalId;
      }

      if (hasAttachments) {
        params.attachment = attachments[0];
        params.attachmentFileName = attachments[0].name;
      }

      if (
        needAssign &&
        vm.djangoUser &&
        !vm.noteActive &&
        (!vm.activeConversation.assignee || vm.activeConversation.assignee.id != vm.djangoUser.id)
      ) {
        var teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];
        vm.assignee = teamMember;
        vm.activeConversation.assignee = teamMember;

        var partAssigned = addPart(vm.activeConversation, '', 'assigned');
        partAssigned.assignee = vm.djangoUser;
        partAssigned._sending = true;

        params.autoAssign = vm.djangoUser.id;
        params.autoAssignRandomId = partAssigned.random_id;
      } else {
        var partAssigned = null;
      }

      if (hasAttachments) {
        const attachment = attachments[0];
        const teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];

        file = {
          conversation: vm.activeConversation.id,
          name: attachment.name,
          mimeType: attachment.type,
          progress: 50,
          error: false,
          body: params.body,
          randomId: part.random_id,
          teamMember: teamMember,
          abort: function () {
            if (this.progress >= 100) {
              return;
            }

            this.error = true;
            if (this.upload) {
              this.upload.abort();
            }

            const idx = vm.sendingFiles.indexOf(this);
            vm.sendingFiles.splice(idx, 1);
          },
        };

        //TODO Sharding: костыль для работы с шардами
        let token = ipCookie('carrotquest_auth_token_panel');
        if (vm.currentApp) {
          token = 'appm-' + vm.currentApp.id + '-' + ipCookie('carrotquest_auth_token_panel');
        }

        //NOTE: при отправке сообещения вместе с файлом делаю это старым способом средствами ngf-file-upload чтобы отображался процесс загрузки файла
        const uploadParams = angular.extend(caseStyleHelper.keysToUnderscore(params)); // Back-end работает с under_score
        file.upload = Upload.upload({
          url: API_ENDPOINT + '/conversations/' + vm.activeConversation.id + '/reply',
          data: uploadParams,
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`,
          },
        }).then(
          function (response) {
            file.progress = 100;
            sendAdvancedMessageSuccess(response.data);
          },
          function (response) {
            if (response.status > 0) {
              file.error = true;
            }

            sendAdvancedMessageError(response.data);
          },
          function (evt) {
            file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
          },
        );
        vm.sendingFiles.push(file);
      } else {
        firstValueFrom(conversationModel.reply(vm.activeConversation.id, params))
          .then(sendAdvancedMessageSuccess)
          .catch(sendAdvancedMessageError);
      }

      // Добавляем сообщение написанное администратором с принудительным скроллом к концу диалога внутри фрейма
      // Если есть вложение добавляем данные о нем
      if (hasAttachments) {
        part.attachments = [
          {
            status: file.progress.toString(),
            filename: attachments[0].name,
            size: attachments[0].size,
            mime_type: attachments[0].type,
          },
        ];
      }
      vueConversationFrame.forceScrollBottom();

      function sendAdvancedMessageSuccess(response) {
        part.id = response.data.id;
        part.part_group = response.data.part_group;
        part._sending = false;

        if (partAssigned) {
          partAssigned._sending = false;
          partAssigned.id = response.data.id_assign_part;
          partAssigned.part_group = response.data.part_group;
        }

        if (vm.activeConversation.isSearching) {
          vm.activeConversation.part_last.id = response.data.id;
          vm.isGlued = true;
          //Надо поставить в false т.к. если было открыто сообщение из поиска и в него что-то написали, то должны подгрузиться последние новые сообщение
          vm.isActiveConversationFromSearch = false;

          loadConversationParts();
        }

        // HACK при ответе в диалоге, чтобы модалка активации больше не показывалась, надо обновить answered_user. Пока это сделано тут. В идеале надо сделать это в модели
        if (!vm.currentApp.activation.answered_user) {
          vm.currentApp.activation.answered_user = moment();
        }

        vueConversationFrame.forceScrollBottom();
      }

      function sendAdvancedMessageError(response) {
        if (response.meta.error === 'IntegrationReplyError') {
          // эта ошибка может свалиться только тогда, когда диалог не актуален, или произошла какая-то неведомая хрень
          // поэтому при ошибках поля устанавливаются вручную так, чтобы заблокировать пользовательский ввод, в зависимости от интеграции
          // не знаю на сколько это правильно, но это было сделать быстрее всего
          switch (response.meta.reason) {
            case 'FacebookTimeExpire':
              // почему сделано именно это - смотри функцию isFacebookTimeExpire
              vm.activeConversation.last_user_reply_time = moment().subtract(HUMAN_AGENT_TIME_EXPIRE + 1, 'day');
              toastr.error(
                $translate.instant(
                  'conversationsConversation.toasts.facebookTimeExpire',
                  { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                  'messageformat',
                ),
              );
              break;
            case 'WhatsAppTimeExpire':
              // почему сделано именно это - смотри функцию isWhatsAppTimeExpire
              vm.activeConversation.last_user_reply_time = moment().subtract(2, 'day');
              toastr.error($translate.instant('conversationsConversation.toasts.whatsAppEdnaTimeExpire'));
              break;
            case 'InstagramTimeExpire':
              // почему сделано именно это - смотри функцию isInstagramTimeExpire
              vm.activeConversation.last_user_reply_time = moment().subtract(HUMAN_AGENT_TIME_EXPIRE + 1, 'day');
              toastr.error(
                $translate.instant(
                  'conversationsConversation.toasts.instagramTimeExpire',
                  { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                  'messageformat',
                ),
              );
              break;
          }
        } else if (response.meta.error === 'ReplyTooBig') {
          toastr.error(
            $translate.instant(
              'conversationsConversation.toasts.replyTooBig',
              { maxLength: vm.REPLY_MAX_LENGTH },
              'messageformat',
            ),
          );
        } else if (response.meta.error === 'LookupError') {
          switch (response.meta.model_name) {
            case 'Article':
              toastr.error($translate.instant('conversationsConversation.toasts.articleDoesNotExistError'));
              break;
          }
        } else {
          systemError.somethingWentWrongToast.show();
        }

        // удаление сообщения, если оно не отправлено
        var partToRemove;

        if (hasAttachments) {
          partToRemove = $filter('filter')(vm.sendingFiles, { randomId: file.randomId }, true)[0];

          if (hasAttachments) {
            // HACK timeout использован для того чтобы избежать дергания,
            //  без timeout надпись со статусом об отложенности диалога появлялется и сразу же пропадает
            $timeout(function () {
              vm.sendingFiles.splice(vm.sendingFiles.indexOf(partToRemove), 1);
            }, 2000);
          }
        } else {
          partToRemove = $filter('filter')(vm.activeConversationParts, { random_id: part.random_id }, true)[0];

          if (partToRemove) {
            // HACK timeout использован для того чтобы избежать дергания,
            //  без timeout надпись со статусом об отложенности диалога появлялется и сразу же пропадает
            $timeout(function () {
              vm.activeConversationParts.splice(vm.activeConversationParts.indexOf(partToRemove), 1);
            }, 2000);
          }
        }
      }
    }

    /**
     * Вставка редактируемого сообщения в поле ввода и переход в режим редактирования
     *
     * @param {String} messageId — ID редактируемого сообщения
     */
    function selectEditableMessage(messageId) {
      var editableMessage = $filter('filter')(vm.activeConversationParts, { id: messageId })[0];

      if (editableMessage) {
        vm.isMessageEditing = true;
        vm.isNoteEditing = editableMessage.type === CONVERSATION_PART_TYPES.NOTE;
        vm.editableMessageId = editableMessage.id;
        var msgBody = editableMessage.body.replace(/<br>/gi, '\n');
        msgBody = emojiService.replaceDivEmojiToNativeEmoji(msgBody);
        msgBody = replaceLinks(msgBody);
        msgBody = decodeMsg(msgBody);
        quillInstance.deleteText(0, quillInstance.getLength(), 'user');
        quillInstance.insertText(0, msgBody, 'user');
        focusTheEnd();

        vm.conversationStates.editableMessage = editableMessage.id;
      }
    }

    /**
     * Постановка сохранённого ответа в поле ввода
     * !!!
     *  нельзя просто так взять и сделать vm.msg = body, т.к. в этом случае пользователь не сможет откатить изменения при помощи Ctrl+Z
     *  применяем сохранённый ответ именно от имени пользователя, чтобы он мог откатить изменения, ну и это логичнее
     *
     * @param {String} body Сохранённый ответ
     */
    function selectSavedReply(body) {
      const parsedBody = decodeMsg(body);

      insertTextToTextarea(parsedBody);

      vm.savedAnswersOpen = false;
    }

    /**
     * Отправка реплик диалога на email
     *
     * @param {Object} conversation - диалог
     * @param {string} recipient - тип получателя
     */
    function sendConversationToEmail(conversation, recipient) {
      firstValueFrom(conversationModel.sendConversationToRecipientEmail(conversation.id, recipient))
        .then(() => {
          toastr.success($translate.instant('conversationsConversation.toasts.sendConversationToEmailSuccess'));
        })
        .catch(() => {
          toastr.error($translate.instant('conversationsConversation.toasts.sendConversationToEmailFailed'));
        })
        .finally(() => {
          vm.additionalOptionsDropdownOpen = false;
        });
    }

    function sendReply(realFile) {
      // эмулируем отправку формы, будто сработал submit. Это нужно из-за того, что кнопка отправки сообщения не сабмитит форму, а просто вызывает эту функцию. То же самое произойдёт по нажатию на Enter/Ctrl+Enter
      //  Но для показа ошибок форма должна быть засабмичена, поэтому это делается принудительно
      vm.messageForm.$commitViewValue();
      vm.messageForm.$setSubmitted();

      if (vm.conversationPartsLoading) {
        return;
      }

      if (!vm.activeConversation) {
        return;
      }

      if (vm.msg.length > vm.REPLY_MAX_LENGTH) {
        return;
      }

      if (isConnectionError()) {
        return;
      }

      // я не знаю как переписать это говно, поэтому костыляю до последнего
      if (vm.msg && !vm.noteActive && vm.msg[0] !== '/') {
        if (isFacebookLastMessageAfterTimeExpire()) {
          $uibModal
            .open({
              controller: 'ConfirmModalController',
              controllerAs: 'vm',
              resolve: {
                modalWindowParams: function () {
                  return {
                    heading: $translate.instant(
                      'conversationsConversation.facebookLastMessageAfterTimeExpireModal.heading',
                    ),
                    body:
                      '\
                  <div class="margin-bottom-20">\
                  ' +
                      $translate.instant(
                        'conversationsConversation.facebookLastMessageAfterTimeExpireModal.body.description1',
                        { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                        'messageformat',
                      ) +
                      '\
                  </div>\
                  <div>\
                    ' +
                      $translate.instant(
                        'conversationsConversation.facebookLastMessageAfterTimeExpireModal.body.description2',
                      ) +
                      '\
                  </div>\
                  ',
                    confirmButtonText: $translate.instant(
                      'conversationsConversation.facebookLastMessageAfterTimeExpireModal.confirmButtonText',
                    ),
                    cancelButtonText: $translate.instant(
                      'conversationsConversation.facebookLastMessageAfterTimeExpireModal.cancelButtonText',
                    ),
                  };
                },
              },
              templateUrl: 'js/shared/modals/confirm/confirm.html',
            })
            .result.then(send);
        } else if (isInstagramLastMessageAfterTimeExpire()) {
          $uibModal
            .open({
              controller: 'ConfirmModalController',
              controllerAs: 'vm',
              resolve: {
                modalWindowParams: function () {
                  return {
                    heading: $translate.instant(
                      'conversationsConversation.instagramLastMessageAfterTimeExpireModal.heading',
                    ),
                    body:
                      '\
                  <div class="margin-bottom-20">\
                  ' +
                      $translate.instant(
                        'conversationsConversation.instagramLastMessageAfterTimeExpireModal.body.description1',
                        { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                        'messageformat',
                      ) +
                      '\
                  </div>\
                  <div>\
                    ' +
                      $translate.instant(
                        'conversationsConversation.instagramLastMessageAfterTimeExpireModal.body.description2',
                      ) +
                      '\
                  </div>\
                  ',
                    confirmButtonText: $translate.instant(
                      'conversationsConversation.instagramLastMessageAfterTimeExpireModal.confirmButtonText',
                    ),
                    cancelButtonText: $translate.instant(
                      'conversationsConversation.instagramLastMessageAfterTimeExpireModal.cancelButtonText',
                    ),
                  };
                },
              },
              templateUrl: 'js/shared/modals/confirm/confirm.html',
            })
            .result.then(send);
        } else {
          send();
        }
      } else {
        send();
      }

      function send() {
        var body = vm.msg;
        var type = vm.noteActive ? 'note' : 'reply_admin';
        if (vm.noteActive) {
          carrotquestHelper.track('Диалоги - отправил заметку');
          type = 'note';
        } else {
          type = 'reply_admin';
        }

        // если в строке одни пробелы и переносы строк - считаем, что строка пустая, и не отправляем её
        if (/^\s*$/.test(body) && !realFile) {
          return;
        }

        // Slash command?
        if (body[0] == '/') {
          vm.msg = '';
          executeSlashCommand(vm.activeConversation, body);
          return;
        }

        body = stripTags(body);
        body = body.replace(/\n/g, '<br>');
        var params = {
          body: body,
          type: type,
          id_as_string: true,
        };
        if (realFile) {
          params.attachment = realFile;
          params.attachmentFileName = realFile.name;
        }

        if (
          vm.djangoUser &&
          !vm.noteActive &&
          (!vm.activeConversation.assignee || vm.activeConversation.assignee.id != vm.djangoUser.id)
        ) {
          var teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];
          vm.assignee = teamMember;
          vm.activeConversation.assignee = teamMember;

          var partAssigned = addPart(vm.activeConversation, '', 'assigned');
          partAssigned.assignee = vm.djangoUser;
          partAssigned._sending = true;

          params.autoAssign = vm.djangoUser.id;
          params.autoAssignRandomId = partAssigned.random_id;
        } else {
          var partAssigned = null;
        }

        vm.msg = '';
        vm.noteActive = false;

        var part = addPart(vm.activeConversation, body, type, realFile, {});
        params.randomId = part.random_id;

        if (realFile) {
          var teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];

          var file = {
            conversation: vm.activeConversation.id,
            name: realFile.name,
            mimeType: realFile.type,
            progress: 50,
            error: false,
            body: params.body,
            randomId: part.random_id,
            teamMember: teamMember,
            abort: function () {
              if (this.progress >= 100) {
                return;
              }

              this.error = true;
              if (this.upload) {
                this.upload.abort();
              }

              var idx = vm.sendingFiles.indexOf(this);
              vm.sendingFiles.splice(idx, 1);
            },
          };

          //TODO Sharding: костыль для работы с шардами
          var token = ipCookie('carrotquest_auth_token_panel');
          if (vm.currentApp) {
            token = 'appm-' + vm.currentApp.id + '-' + ipCookie('carrotquest_auth_token_panel');
          }

          //NOTE: при отправке сообещения вместе с файлом делаю это старым способом средствами ngf-file-upload чтобы отображался процесс загрузки файла
          var uploadParams = angular.extend(caseStyleHelper.keysToUnderscore(params)); // Back-end работает с under_score
          file.upload = Upload.upload({
            url: API_ENDPOINT + '/conversations/' + vm.activeConversation.id + '/reply',
            data: uploadParams,
            method: 'POST',
            headers: {
              Authorization: `Token ${token}`,
            },
          }).then(
            function (response) {
              file.progress = 100;
              sendReplySuccess(response.data);
            },
            function (response) {
              if (response.status > 0) {
                file.error = true;
              }

              sendReplyError(response.data);
            },
            function (evt) {
              file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
            },
          );
          vm.sendingFiles.push(file);
        } else {
          firstValueFrom(conversationModel.reply(vm.activeConversation.id, params))
            .then(sendReplySuccess)
            .catch(sendReplyError);
        }

        // Добавляем сообщение написанное администратором с принудительным скроллом к концу диалога внутри фрейма
        // Если есть вложение добавляем данные о нем
        if (realFile) {
          part.attachments = [
            {
              status: file.progress.toString(),
              filename: realFile.name,
              size: realFile.size,
              mime_type: realFile.type,
            },
          ];
        }
        vueConversationFrame.forceScrollBottom();

        function sendReplySuccess(response) {
          response.data.created = getMomentInstance(response.data.created);

          part.id = response.data.id;
          part.part_group = response.data.part_group;
          part._sending = false;

          if (partAssigned) {
            partAssigned._sending = false;
            partAssigned.id = response.data.id_assign_part;
            partAssigned.part_group = response.data.part_group;
          }

          /*
            Micro front-end на Vue использует vm.sendingFiles для отображения загружаемых файлов.
            После успешной отправки сообщения в micro front-end добавится реплика с загруженным файлом,
            а из vm.sendingFiles его необходимо удалить.
          */
          if (file) {
            [...vm.sendingFiles].reverse().forEach((sendingFile, sendingFileIndex) => {
              if (sendingFile.conversation === response.data.conversation && sendingFile === file) {
                vm.sendingFiles.splice(sendingFileIndex, 1);
                if (
                  vm.activeConversationParts[0].conversation === response.data.conversation &&
                  !findMessagePart(vm.activeConversationParts, response.data)
                ) {
                  vm.activeConversationParts.push(response.data);
                }
              }
            });
          }

          if (vm.activeConversation.isSearching) {
            vm.activeConversation.part_last.id = response.data.id;
            vm.isGlued = true;
            //Надо поставить в false т.к. если было открыто сообщение из поиска и в него что-то написали, то должны подгрузиться последние новые сообщение
            vm.isActiveConversationFromSearch = false;

            loadConversationParts();
          }

          // HACK при ответе в диалоге, чтобы модалка активации больше не показывалась, надо обновить answered_user. Пока это сделано тут. В идеале надо сделать это в модели
          if (!vm.currentApp.activation.answered_user) {
            vm.currentApp.activation.answered_user = moment();
          }

          if (!vm.calendlyAppIntegration || (vm.calendlyAppIntegration.id && !vm.calendlyAppIntegration.active)) {
            // Показываем поповер с предложением воспользоваться интеграцией с Calendly, если пользователь отправил ссылку Calendly
            if (CALENDLY_URL_REGEXP.test(body)) {
              vm.showUseCalendlyPopover()
                .then(trackShowUseCalendlyPopover)
                .catch(() => {});
            }
          }
        }

        function sendReplyError(response) {
          if (response.meta.error === 'IntegrationReplyError') {
            // эта ошибка может свалиться только тогда, когда диалог не актуален, или произошла какая-то неведомая хрень
            // поэтому при ошибках поля устанавливаются вручную так, чтобы заблокировать пользовательский ввод, в зависимости от интеграции
            // не знаю на сколько это правильно, но это было сделать быстрее всего
            switch (response.meta.reason) {
              case 'FacebookTimeExpire':
                // почему сделано именно это - смотри функцию isFacebookTimeExpire
                vm.activeConversation.last_user_reply_time = moment().subtract(HUMAN_AGENT_TIME_EXPIRE + 1, 'day');
                toastr.error(
                  $translate.instant(
                    'conversationsConversation.toasts.facebookTimeExpire',
                    { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                    'messageformat',
                  ),
                );
                break;
              case 'WhatsAppTimeExpire':
                // почему сделано именно это - смотри функцию isWhatsAppTimeExpire
                vm.activeConversation.last_user_reply_time = moment().subtract(2, 'day');
                toastr.error($translate.instant('conversationsConversation.toasts.whatsAppEdnaTimeExpire'));
                break;
              case 'InstagramTimeExpire':
                // почему сделано именно это - смотри функцию isInstagramTimeExpire
                vm.activeConversation.last_user_reply_time = moment().subtract(HUMAN_AGENT_TIME_EXPIRE + 1, 'day');
                toastr.error(
                  $translate.instant(
                    'conversationsConversation.toasts.instagramTimeExpire',
                    { timeExpire: HUMAN_AGENT_TIME_EXPIRE },
                    'messageformat',
                  ),
                );
                break;
            }
          } else if (response.meta.error === 'ReplyTooBig') {
            toastr.error(
              $translate.instant(
                'conversationsConversation.toasts.replyTooBig',
                { maxLength: vm.REPLY_MAX_LENGTH },
                'messageformat',
              ),
            );
          } else if (response.meta.error === 'LookupError') {
            switch (response.meta.model_name) {
              case 'Article':
                toastr.error($translate.instant('conversationsConversation.toasts.articleDoesNotExistError'));
                break;
            }
          } else {
            systemError.somethingWentWrongToast.show();
          }

          // удаление сообщения, если оно не отправлено
          var partToRemove;

          if (realFile) {
            partToRemove = $filter('filter')(vm.sendingFiles, { randomId: file.randomId }, true)[0];

            if (partToRemove) {
              // HACK timeout использован для того чтобы избежать дергания,
              //  без timeout надпись со статусом об отложенности диалога появлялется и сразу же пропадает
              $timeout(function () {
                vm.sendingFiles.splice(vm.sendingFiles.indexOf(partToRemove), 1);
              }, 2000);
            }
          } else {
            partToRemove = $filter('filter')(vm.activeConversationParts, { random_id: part.random_id }, true)[0];

            if (partToRemove) {
              // HACK timeout использован для того чтобы избежать дергания,
              //  без timeout надпись со статусом об отложенности диалога появлялется и сразу же пропадает
              $timeout(function () {
                vm.activeConversationParts.splice(vm.activeConversationParts.indexOf(partToRemove), 1);
              }, 2000);
            }
          }
        }
      }
    }

    /**
     * Отправка шаблона WhatsApp
     *
     * @param reply Распарсенный в реплику шаблон WhatsApp
     */
    function sendWhatsAppTemplateAsReply(reply) {
      const replyAttachment = reply.attachment.length > 0 ? reply.attachment : null;
      const stringifiedBodyJson = JSON.stringify(caseStyleHelper.keysToUnderscore(reply.bodyJson));
      sendAdvancedMessage(
        CONVERSATION_PART_TYPES.REPLY_ADMIN,
        reply.body,
        stringifiedBodyJson,
        null,
        replyAttachment,
        false,
      );
    }

    /**
     * Запись ID интеграции с Calendly
     * @param {String} integrationId ID интеграции с Calendly
     */
    function setCalendlyIntegrationId(integrationId) {
      vm.calendlyDjangoUserIntegrationId = integrationId;
    }

    /**
     * Добавляет в реплику с заглушкой для поп-апов и емейлов дополнительное поле, которое сордержит в себе текст, отображаемый в самой заглушке
     * Например:
     * Это сообщение содержит письмо: ТУТ ТЕМА ПИСЬМА
     * Это сообщение содержит поп-ап: ТУТ НАЗВАНИЕ АВТОСООБЩЕНИЯ
     *
     * @param {Object} activeConversation — Текущий диалог
     * @param {Object} conversationParts — Реплики диалога
     */
    function setConversationPartMessageTitle(activeConversation, conversationParts) {
      var firstAdminReply = $filter('filter')(conversationParts, {
        first: true,
        type: CONVERSATION_PART_TYPES.REPLY_ADMIN,
      })[0];

      if (
        firstAdminReply &&
        !!~[
          MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
          MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.EMAIL,
          MESSAGE_PART_TYPES.POPUP_BIG,
          MESSAGE_PART_TYPES.POPUP_SMALL,
          MESSAGE_PART_TYPES.TELEGRAM,
        ].indexOf(activeConversation.type)
      ) {
        if (firstAdminReply.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_AUTO) {
          firstValueFrom(messageModel.getAutoMessage(activeConversation.message, true, true, true))
            .then(getAutoMessageSuccess)
            .finally(getMessageFinally);
        } else if (firstAdminReply.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_MANUAL) {
          firstValueFrom(messageModel.getManualMessage(activeConversation.message))
            .then(getManualMessageSuccess)
            .finally(getMessageFinally);
        }
      }

      function getAutoMessageSuccess(message) {
        firstAdminReply.messageTitle = message.name;
      }

      function getManualMessageSuccess(message) {
        if (~[MESSAGE_PART_TYPES.EMAIL].indexOf(message.type)) {
          firstAdminReply.messageTitle = $filter('limitTo')(message.subject, 100);
        } else if (~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(message.type)) {
          firstAdminReply.messageTitle = 'ID ' + message.id;
        } else if (message.type === MESSAGE_PART_TYPES.TELEGRAM) {
          const bodyJson = JSON.parse(message.bodyJson);

          let firstTextContent = null;

          for (let i = 0; i < bodyJson.contents.length; i++) {
            let content = bodyJson.contents[i];
            if (content.type === 'text') {
              firstTextContent = content;
              break;
            }
          }

          if (firstTextContent) {
            firstAdminReply.messageTitle = $filter('removeHtmlTags')(firstTextContent.value);
            firstAdminReply.messageTitle = $filter('limitTo')(firstAdminReply.messageTitle, 100);
          } else {
            firstAdminReply.messageTitle = $translate.instant('models.messagePart.customName.telegram');
          }
        } else {
          firstAdminReply.messageTitle = $filter('removeHtmlTags')(message.body);
          firstAdminReply.messageTitle = $filter('limitTo')(firstAdminReply.messageTitle, 100);
        }
      }

      function getMessageFinally() {
        // HACK Этой строчкой кода я заставляю Vue заново выполнить рендер тела диалога, иначе messageTitle не обновляется
        firstAdminReply.created = moment(firstAdminReply.created).add(0, 'seconds');
      }
    }

    /**
     * Устанавливает горячие клавиши для заметки, ссылки на диалог,
     * закрытие диалога, закрытие диалога и снятие назначения, назначения текущего пользователя, снятие назначения,
     * быстрые ответы, открытие карточки пользователя
     *
     */
    function setupHotkeys() {
      var allowIn = ['INPUT', 'SELECT', 'TEXTAREA'];

      //Открыть диалог в отдельной вкладке
      addHotKey({
        combo: 'ctrl+l',
        callback: function (event) {
          event.preventDefault();
          trackUseHotkey();
          window.open(vm.currentApp.id + '/conversations/' + vm.activeConversation.id, '_blank');
        },
      });

      //Активировать режим заметки
      addHotKey({
        combo: 'ctrl+h',
        callback: function (event) {
          event.preventDefault();
          if (!vm.isMessageEditing) {
            trackUseHotkey();
            toggleNote();
          }
        },
      });

      //Закрыть диалог
      addHotKey({
        combo: 'ctrl+o',
        callback: function (event) {
          event.preventDefault();
          if (!isOperatorLostInConcurrency()) {
            trackUseHotkey();
            close(vm.activeConversation);
          }
        },
      });

      //Закрыть диалог и снять назначени
      addHotKey({
        combo: 'ctrl+q',
        callback: function (event) {
          event.preventDefault();
          let conversation = vm.activeConversation;

          trackUseHotkey();
          if (!isOperatorLostInConcurrency()) {
            vm.assignee = null;
            assignedChanged(conversation, null).then(() => close(conversation));
          }
          // Записываем флаги в localStorage, чтобы не показывать онбординговый поповер в этот хоткей
          localStorage.removeItem(CLOSE_BUTTON_CLICKS_LS_KEY_NAME);
          localStorage.setItem(CLOSE_HOTKEY_POPOVER_LS_KEY_NAME, 'true');
        },
      });

      //Снять назначение
      addHotKey({
        combo: 'ctrl+b',
        callback: function (event) {
          event.preventDefault();
          let conversation = vm.activeConversation;

          trackUseHotkey();
          if (!isOperatorLostInConcurrency()) {
            vm.assignee = null;
            assignedChanged(conversation, vm.assignee);
          }
        },
      });

      //Назначить на меня
      addHotKey({
        combo: 'ctrl+m',
        callback: function (event) {
          event.preventDefault();
          trackUseHotkey();
          if (!isOperatorLostInConcurrency()) {
            for (var i = 0; i < vm.teamMembers.length; i++) {
              if (vm.teamMembers[i].id == vm.djangoUser.id) {
                vm.assignee = vm.teamMembers[i];
              }
            }
            assignedChanged(vm.activeConversation, vm.assignee);
          }
        },
      });

      //Открыть карточку пользователя
      //FIXME Перенести в карточку пользователя
      addHotKey({
        combo: 'ctrl+u',
        callback: function (event) {
          event.preventDefault();
          trackUseHotkey();
          openUserCard();
        },
      });

      //Открыть сохраненые ответы
      addHotKey({
        combo: 'ctrl+s',
        callback: function (event) {
          event.preventDefault();
          trackUseHotkey();
          // если нельзя отправлять сообщение, то и вводить что-либо, в т.ч. сохранённые ответы, нельзя
          if (isMessageSendingAllowed()) {
            vm.savedAnswersOpen = true;
          }
        },
      });

      //Быстрый ответ 1-9
      for (var i = 0; i < 9; i++) {
        addHotKey({
          combo: 'ctrl+' + (i + 1),
          callback: function (event) {
            event.preventDefault();
            trackUseHotkey();
            // если нельзя отправлять сообщение, то и вводить что-либо, в т.ч. сохранённые ответы, нельзя
            if (isMessageSendingAllowed()) {
              var index = event.key - 1;
              vm.savedRepliesShared[index] && selectSavedReply(vm.savedRepliesShared[index].body);
            }
          },
        });
        addHotKey({
          combo: 'alt+' + (i + 1),
          callback: function (event) {
            event.preventDefault();
            trackUseHotkey();
            // если нельзя отправлять сообщение, то и вводить что-либо, в т.ч. сохранённые ответы, нельзя
            if (isMessageSendingAllowed()) {
              var index = event.key - 1;
              vm.savedRepliesPersonal[index] && selectSavedReply(vm.savedRepliesPersonal[index].body);
            }
          },
        });
      }

      /**
       * Устанавливает горячую клвашу и записывает хоткей в массив
       * Засовывать в массив приходится мз-за того что после перехода на другую страницу нужна убрать хоткеии текущей
       *
       * @param {Object} hotKeyObject хранит комбинацию клавиш combo и каллбек callback
       */
      function addHotKey(hotKeyObject) {
        hotKeysArray.push(hotKeyObject);
        hotkeys.add({
          combo: hotKeyObject.combo,
          allowIn: allowIn,
          callback: hotKeyObject.callback,
        });
      }

      function trackUseHotkey() {
        carrotquestHelper.track('Диалоги - использовал горячую клавишу', {
          App: vm.currentApp.name,
          app_id: vm.currentApp.id,
        });
      }
    }

    /**
     * Добавление горячих клавиш в Quill
     * Есть много способов добавить горячие клавиши в Quill, но ни один из них кроме unshift'а не является стопроцентным
     * Например, дополнить работу клавиши tab вообще невозможно без unshift. В противном случае придётся переписывать её стандартное поведение
     * Подробнее можно прочитать, например, тут, но вообще жалоб на горячие клавиши в Quill очень много https://github.com/quilljs/quill/issues/1967
     */
    function setupQuillHotkeys(quillInstance) {
      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        handler: handleEnter,
      });

      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        ctrlKey: true,
        handler: handleCtrlEnter,
      });

      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        metaKey: true,
        handler: handleCtrlEnter,
      });

      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        metaKey: true,
        handler: chooseSlashCommand,
      });

      quillInstance.keyboard.bindings['Tab'].unshift({
        key: 'Tab',
        handler: chooseSlashCommand,
      });

      quillInstance.keyboard.bindings['ArrowUp'].unshift({
        key: 'ArrowUp',
        handler: handleKeyUp,
      });

      quillInstance.keyboard.bindings['ArrowDown'].unshift({
        key: 'ArrowDown',
        handler: nextSlashCommand,
      });

      quillInstance.keyboard.addBinding(
        {
          key: 27,
        },
        handleEsc,
      );

      /**
       * Обработчик нажатия кнопки Esc
       *
       * @returns {Boolean|undefined}
       */
      function handleEsc() {
        if (vm.slashCommandsOpened) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          $scope.$applyAsync(function () {
            vm.slashCommandsOpened = false;
          });
        } else if (vm.isMessageEditing) {
          $scope.$applyAsync(function () {
            cancelMessageEditing();
          });
        } else {
          return true;
        }
      }

      /**
       * Выбор Slash-команды
       *
       * @returns {Boolean|undefined}
       */
      function chooseSlashCommand() {
        if (vm.slashCommandsOpened) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          $scope.$applyAsync(function () {
            var activeSlashCommand = $filter('filter')(vm.filteredSlashCommands, { active: true }, true)[0];
            activeSlashCommand && vm.slashCommandClick(activeSlashCommand);
          });
        } else {
          return true;
        }
      }

      /**
       * Обработка нажания Ctrl+Enter
       *
       * @param range
       * @param context
       */
      function handleCtrlEnter(range, context) {
        // стандартный обработчик Enter. Пришлось копаться в исходниках, чтобы понять как его достать, поэтому !!! лучше тут ничего не менять
        var defaultEnterBinding = $filter('filter')(quillInstance.keyboard.bindings['Enter'], { shiftKey: null }, 1)[0];

        if (!$rootScope.djangoUser.messenger_send_by_enter) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          // HACK: раньше вместо $timeout тут использовался $applyAsync, но его пришлось заменить, т.к. иначе при быстром наборе текста и мгновенном нажатии Ctrl+Enter
          //  текст в поле ввода оставался. Это происходило из-за того, что в один и тот же digest-цикл vm.msg присваивалось значение, а внутри функции sendReply() оно опустошалось (vm.msg = '')
          //  Из-за этого AngularJS не сообщал об изменениях модели в Angular в компонент cq-message-input, и в том компоненте не вызывался setter
          $timeout(function () {
            if (vm.isMessageEditing) {
              editMessage(vm.editableMessageId);
            } else {
              sendReply();
            }
          }, 0);
        } else {
          // NOTE: Это сочетание вообще очень сложно переписать и заставить Quill работать так, как надо, но я нашёл способ
          //  Если сообщение по Ctrl+Enter отправлять не надо, то должен отработать обычный Enter, а для этого приходится доставать из стандартных обработчиков именно обработчик Enter и вызывать его ручками
          //  в качестве this в quill нужно передать keyboard инстанса. А извращаться так приходится из-за того, что quill начисто обрубает использование Enter с сочетанием модификаторов (Ctrl, Alt и прочих), за исключением Shift
          angular.bind(quillInstance.keyboard, defaultEnterBinding.handler, range, context)();
        }
      }

      /**
       * Обработка Enter
       *
       * @returns {Boolean|undefined}
       */
      function handleEnter() {
        if ($rootScope.djangoUser.messenger_send_by_enter) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          // HACK: раньше вместо $timeout тут использовался $applyAsync, но его пришлось заменить, т.к. иначе при быстром наборе текста и мгновенном нажатии Enter
          //  текст в поле ввода оставался. Это происходило из-за того, что в один и тот же digest-цикл vm.msg присваивалось значение, а внутри функции sendReply() оно опустошалось (vm.msg = '')
          //  Из-за этого AngularJS не сообщал об изменениях модели в Angular в компонент cq-message-input, и в том компоненте не вызывался setter
          $timeout(function () {
            if (vm.isMessageEditing) {
              editMessage(vm.editableMessageId);
            } else {
              sendReply();
            }
          }, 0);
        } else {
          return true;
        }
      }

      /**
       * Выбор следующей Slash-команды
       *
       * @returns {Boolean|undefined}
       */
      function nextSlashCommand() {
        if (vm.slashCommandsOpened) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          $scope.$applyAsync(function () {
            for (var i = 0; i < vm.filteredSlashCommands.length; i++) {
              if (vm.filteredSlashCommands[i].active) {
                vm.filteredSlashCommands[i].active = false;

                if (vm.filteredSlashCommands[i + 1]) {
                  vm.filteredSlashCommands[i + 1].active = true;
                } else {
                  vm.filteredSlashCommands[0].active = true;
                }
                break;
              }
            }
          });
        } else {
          return true;
        }
      }

      /**
       * Обработчик нажатия кнопки вверх
       *
       * @returns {boolean}
       */
      function handleKeyUp() {
        if (vm.slashCommandsOpened) {
          // внутри себя quill использует обычный addEventListener, поэтому нужно оповестить angular об изменениях
          $scope.$applyAsync(function () {
            for (var i = 0; i < vm.filteredSlashCommands.length; i++) {
              if (vm.filteredSlashCommands[i].active) {
                vm.filteredSlashCommands[i].active = false;

                if (vm.filteredSlashCommands[i - 1]) {
                  vm.filteredSlashCommands[i - 1].active = true;
                } else {
                  vm.filteredSlashCommands[vm.filteredSlashCommands.length - 1].active = true;
                }
                break;
              }
            }
          });
        } else if (!vm.msg && !vm.isMessageEditing) {
          var myMessages = $filter('filter')(vm.activeConversationParts, { from: { id: vm.djangoUser.id } });

          if (myMessages) {
            for (var i = myMessages.length - 1; i >= 0; i--) {
              if (isMessageEditable(myMessages[i])) {
                var editableMessage = myMessages[i];
                selectEditableMessage(editableMessage.id);

                SCROLL_TO_PART = 'editableMessage-' + editableMessage.id;
                // В теле диалога не сразу рендерится id-шник, поэтому скролим через интервал
                var interval = $interval(function () {
                  if (angular.element('#' + SCROLL_TO_PART)[0]) {
                    $interval.cancel(interval);
                    $anchorScroll(SCROLL_TO_PART);
                  }
                }, 50);

                break;
              }
            }
          }
        } else {
          return true;
        }
      }
    }

    function showHistory() {
      vm.onShowHistory && vm.onShowHistory();
    }

    function slashCommandClick(cmd) {
      vm.msg = cmd.command + ' ';
      focusTheEnd();
      vm.slashCommandsOpened = false;
    }

    function slashCommandSelect(cmd) {
      for (var i = 0; i < vm.filteredSlashCommands.length; i++) {
        vm.filteredSlashCommands[i].active = false;
      }

      cmd.active = true;
    }

    function stripTags(str) {
      var tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
      };

      return str.replace(/[&<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
    }

    /**
     * Функция, обратная к функции stripTags. Преобразует html-символы в специсимволы
     *
     * @param {String} body — Декодируемое сообщение
     * @returns {String}
     */
    function decodeMsg(body) {
      var tagsToReplace = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
      };

      return body.replace(/(&amp;|&lt;|&gt;)/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
    }

    /**
     * Включение/отключение режима заметки
     */
    function toggleNote() {
      vm.noteActive = !vm.noteActive;
      quillInstance.focus();
    }

    /**
     * Трек добавления тега
     */
    function trackAddTag() {
      carrotquestHelper.track('Диалоги - добавил тег');
    }

    /**
     * Трек клика на статью из списка статей базы знаний
     */
    function trackClickOnArticle() {
      carrotquestHelper.track('Диалоги - клик на статью из базы знаний');
    }

    /**
     * Трек клика на 'Ссылка на диалог'
     */
    function trackClickOnConversationLink() {
      carrotquestHelper.track('Диалоги - клик на "Ссылка на диалог"');
    }

    /**
     * Трек клика на смайл в списке смайлов
     */
    function trackClickOnEmoji() {
      carrotquestHelper.track('Диалоги - клик на "Вставка имоджи"');
    }

    /**
     * Трек клика на БЗ
     */
    function trackClickOnKnowledgeBase() {
      carrotquestHelper.track('Диалоги - клик на базу знаний');
    }

    /**
     * Трек клика на открытие списка смайлов
     */
    function trackClickOnOpenEmoji() {
      carrotquestHelper.track('Диалоги - клик на "Открыть имоджи"');
    }

    /**
     * Трек клика на сохранённый ответ
     *
     * @param {string} savedReplyHeader — Название сохранённого ответа
     */
    function trackClickOnSavedReply(savedReplyHeader) {
      carrotquestHelper.track('Диалоги - вставил сохранённый ответ', {
        'Название ответа': savedReplyHeader,
      });
    }

    /**
     * Трек клика на 'История диалогов'
     */
    function trackClickOnShowHistory() {
      carrotquestHelper.track('Диалоги - клик на "История диалогов"');
    }

    /**
     * Трек отправки статьи через поповер
     */
    function trackSendArticle() {
      carrotquestHelper.track('Диалоги - вставил статью в диалог');
    }

    /**
     * Трек клика на событие Calendly в поповере
     *
     * @param {String} eventName - Название события
     */
    function trackSendCalendlyEvent(eventName) {
      carrotquestHelper.track('Диалоги - вставил ссылку Calendly в чат', {
        'Названиие встречи': eventName,
      });
    }

    /**
     * Трек отправки шаблона WhatsApp edna
     *
     * @param reply Отправляемая реплика
     */
    function trackSendWhatsAppTemplate(reply) {
      const hasAttachments = reply.attachments?.length > 0 ? 'Да' : 'Нет';
      const hasVariables = Object.keys(reply.bodyJson.whatsappEdnaTemplate.variables).length > 0 ? 'Да' : 'Нет';
      carrotquestHelper.track('Диалоги - отправил HSM сообщение WhatsApp в чат', {
        'User ID': carrotquestHelper.getId(),
        'Есть переменные': hasVariables,
        'Есть прикрепление': hasAttachments,
      });
    }

    /**
     * Трек превышения размера файла
     * @param {string} fileFormat
     * @param {number} fileSize
     */
    function trackFileSizeExceed(fileFormat, fileSize) {
      carrotquestHelper.track('Диалоги - попытка прикрепить файл большего размера', {
        'ID диалога': vm.activeConversation.id,
        'Формат файла': fileFormat,
        'Размер файла в МБ': fileSize,
      });
    }

    /**
     * Трек невалидного формата
     * @param {string} fileFormat
     * @param {number} fileSize
     */
    function trackInvalidFileExtension(fileFormat, fileSize) {
      carrotquestHelper.track('Диалоги - попытка прикрепить файл другого формата', {
        'ID диалога': vm.activeConversation.id,
        'Формат файла': fileFormat,
        'Размер файла в МБ': fileSize,
      });
    }

    /**
     * Трек показа поповера Use Calendly
     */
    function trackShowUseCalendlyPopover() {
      carrotquestHelper.track('Интеграция Calendly - показ поповера', {
        'Источник показа': 'Отправка ссылки на Calendly',
        'Роль пользователя': vm.djangoUser.prefs[vm.currentApp.id].permissions,
      });
    }
  }
})();
