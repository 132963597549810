import { UserProperty } from '@http/property/property.model';

export class JinjaService {
  constructor() {}

  private static jinjaSyntaxRegexp: RegExp = /{{\s*user\['(.*?)'\]\s*\|\s*default\('(.*?)'\)\s*}}/gi;
  private static userPropsSyntaxRegexp: RegExp =
    /<span.*?data-prop-name="(.*?)".*?data-default-value="(.*?)".*?>(.*?)<\/span>/gi;

  static parsePropsToJinja(value: string) {
    return value.replace(
      this.userPropsSyntaxRegexp,
      (match: string, propName: string, defaultValue: string) => `{{user['${propName}']|default('${defaultValue}')}}`,
    );
  }

  static parseJinjaToProps(value: string, userProps: UserProperty[]) {
    return value.replace(this.jinjaSyntaxRegexp, (match: string, propName: string, defaultValue: string) => {
      const prettyName = userProps.find((userProp) => userProp.name === propName)!.prettyName;
      return `<span data-prop-name="${propName}" data-default-value="${defaultValue}" class="badge badge-light-secondary cursor-pointer" contenteditable="false">${prettyName}</span>`;
    });
  }

  static isContainJinjaUserProps(value: string) {
    return this.jinjaSyntaxRegexp.test(value);
  }
}
