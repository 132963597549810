import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { BlockHeaderModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/block-header/block-header.module';
import { ContentBlocksComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/content-blocks/content-blocks.component';
import { MediaBlocksEditorModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/media-blocks-editor/media-blocks-editor.module';
import { TextBlockEditorModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/text-block-editor/text-block-editor.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ContentBlocksComponent],
  exports: [ContentBlocksComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BlockHeaderModule,
    MediaBlocksEditorModule,
    TextBlockEditorModule,
  ],
})
export class ContentBlocksModule {}
