<ng-container *transloco="let t; read: 'editorWrapperComponent'">
  <cq-editor
    [class]="class"
    [format]="format"
    [formControl]="control"
    [minHeight]="minHeight"
    [placeholder]="placeholder"
    [formats]="formats"
    [userProperties]="userProperties"
    (editorChanged)="onEditorChanged()"
    (editorCreated)="onEditorCreated($event)"
    (selectionChanged)="onSelectionChanged()"
    (textChanged)="onTextChanged()"
  ></cq-editor>
</ng-container>
