import { HTMLText, HTMLTextStyle } from '@pixi/text-html';
import { Container, Text, TextStyle } from 'pixi.js';

/**
 * Отрисовывает PIXI.Text элемент на канвасе
 * @param text Текст, окторый необходимо отрендерить
 * @param options Стили для текста
 */
export function renderCanvasText(text: string, options: ConstructorParameters<typeof TextStyle>[0] = {}): Text {
  const TEXT_STYLE = new TextStyle({
    fontSize: 14,
    fill: 0x22252a,
    wordWrap: true,
    breakWords: true,
    fontFamily: ['PT Root UI', 'Helvetica', 'Arial', 'sans-serif'],
    ...options,
  });
  return new Text(text, TEXT_STYLE);
}

/**
 * Отрисовывает PIXI.HTMLText элемент на канвасе
 * @param text - Текст, окторый необходимо отрендерить
 * @param options Стили для текста
 */
export function renderCanvasHTMLText(text: string, options: ConstructorParameters<typeof TextStyle>[0] = {}): HTMLText {
  const TEXT_STYLE = new HTMLTextStyle({
    fontSize: 14,
    lineHeight: 24,
    fill: 0x22252a,
    padding: 0,
    wordWrap: true,
    breakWords: true,
    //text-html поддерживает только веб безопасные шрифты https://www.w3schools.com/cssref/css_websafe_fonts.asp
    // upd: раньше может нельзя было, но теперь можно загрузить нужные шрифты HTMLTextStyle.loadFont()
    fontFamily: ['Arial', 'sans-serif'],
    ...options,
  });

  const htmlContent = `
    <style>
      p {
        margin-bottom: 0;
        margin-top: 3px;
      }
      p:nth-of-type(1) {
        margin: 0;
      }
      a {
        color: #3366bb;
        text-decoration: underline;
      }
      span[class*=badge][class*=badge-light-secondary] {
        display: inline-block;
        padding: 4px 4.5px;
        font-size: 85%;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 4px;
        text-decoration: none;
        background-color: #D5D8DD;
        color: #5C6370;
      }
      ol {
        margin-bottom: 0;
        margin-top: 3px;
        padding-left: 20px;
      }
      ul {
        margin-bottom: 0;
        margin-top: 3px;
        padding-left: 20px;
      }
    </style>
    ${text}
  `;

  return new HTMLText(htmlContent, TEXT_STYLE);
}

/**
 * Отрезает текст по длине, заменяя окончание троеточием
 * @param text
 * @param textMaxLength
 */
export function spliceText(text: string, textMaxLength: number) {
  if (!text.length) {
    return '';
  }
  return text.length > textMaxLength ? `${text.slice(0, textMaxLength - 3)}...` : text;
}

/**
 * Получение высоты элемента без рамки
 * @param element
 */
export function getInnerHeight(element: Container): number {
  const localBounds = element.getLocalBounds();
  // Если localBounds.y отрицательный, это значит что Y смещен на ширину рамки
  if (localBounds.y < 0) {
    // Смещение рамки выражено отрицательным значением, поэтому прибавляем
    // Рамки 2 (верхняя, нижняя), поэтому умножаем на 2
    return Math.abs(element.height + localBounds.y * 2);
  }
  return element.height;
}
