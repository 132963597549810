<ng-container *transloco="let t; read: 'botChannelsComponent'">
  <div class="d-flex mb-10">
    <cq-switch-new [formControl]="switchControl"></cq-switch-new>
    <h4 class="mb-0 ml-10">{{ t('switchHeading') }}</h4>
  </div>
  <div class="text-secondary mb-15">{{ t('text') }}</div>
  <div [ngbCollapse]="!switchControl.value">
    <div class="margin-between-rows-10">
      <ng-container *ngFor="let channel of selectableChannels; let i = index">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [id]="i"
            type="checkbox"
            [(ngModel)]="channel.checked"
            (ngModelChange)="onCheckboxValueChange($event, channel)"
          />
          <label
            class="custom-control-label"
            [for]="i"
          >
            <div class="d-flex align-items-center">
              <cq-channel-icon
                [channel]="channel"
                xs
              ></cq-channel-icon>
              <span class="ml-5">{{ channel.name }}</span>
            </div>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
