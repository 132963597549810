import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgSelectComponent } from '@ng-select/ng-select';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { EventType } from '@http/property/property.model';
import { ModalHelperService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

@Component({
  selector: 'cq-user-event-control',
  templateUrl: './user-event-control.component.html',
  styleUrls: ['./user-event-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectComponent, ReactiveFormsModule, AsyncPipe, NgFooterTemplateDirective, TranslocoModule],
})
export class UserEventControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  @Input({ required: true })
  userEvents!: EventType[];

  @Output()
  userEventsChange: EventEmitter<EventType[]> = new EventEmitter<EventType[]>();

  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
  });

  constructor(
    private readonly eventTypeModel: EventTypeModel,
    private readonly modalHelperService: ModalHelperService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  /**
   * Добавление нового события
   *
   * @param eventTypeName Название события
   */
  addEventType(eventTypeName: EventType['name']): void | boolean {
    if (!eventTypeName) {
      return false;
    }

    const newEventType: EventType = this.eventTypeModel.getDefault(eventTypeName);

    // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
    const existingEventTypeByName = this.userEvents.filter(
      (eventType: EventType) => eventType.name === newEventType.name,
    )[0];
    const existingEventTypeByPrettyName = this.userEvents.filter(
      (eventType: EventType) => eventType.prettyName === newEventType.prettyName,
    )[0];

    if (!existingEventTypeByName && !existingEventTypeByPrettyName) {
      this.userEventsChange.emit([...this.userEvents, newEventType]);
    }
    this.control.setValue(newEventType.name);
  }

  /**
   * Открытие модалки создания нового типа события
   */
  openAddEventModal(): void {
    const createNewEventModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('userEventControlComponent.addEventModal.heading'),
        body: this.translocoService.translate('userEventControlComponent.addEventModal.body'),
        inputPlaceholder: this.translocoService.translate('userEventControlComponent.addEventModal.inputPlaceholder'),
        inputErrorText: this.translocoService.translate('userEventControlComponent.addEventModal.inputErrorText'),
        confirmButtonText: this.translocoService.translate('userEventControlComponent.addEventModal.confirmButtonText'),
      })
      .open(PromptModalComponent);

    createNewEventModal.result.then((newEventTypeName: string) => this.addEventType(newEventTypeName));
  }
}
