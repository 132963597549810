import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { ButtonBlockEditorComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/button-block-editor/button-block-editor.component';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ButtonBlockEditorComponent],
  exports: [ButtonBlockEditorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    NgbDropdownModule,
    EventTypeSelectModule,
    ReactiveFormsModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
  ],
})
export class ButtonBlockEditorModule {}
