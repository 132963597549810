import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { INVOICE_STATUS } from '@http/invoice/invoice.constants';
import { TopUpBalanceModalComponent } from '@panel/app/pages/subscription/general/signed/balance/top-up-balance-modal/top-up-balance-modal.component';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { DestroyService } from '@panel/app/services';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с балансом */
@Component({
  selector: 'cq-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BalanceComponent {
  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly destroy$: DestroyService,
    private readonly modalService: NgbModal,
    private readonly l10nHelperService: L10nHelperService,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {}

  /**
   * Возвращает общую цену перерасхода emails
   *
   * NOTE:
   *  Для US стоимость email при перерасходе считается за 1000 emails, а для RU за 1 email
   *
   * @param overspendingQuantity - Количество emails сверх лимита
   * @param overspendingCost - Стоимость emails при перерасходе
   */
  getOverspendingTotalPrice(overspendingQuantity: number, overspendingCost: number): number {
    let price;

    if (this.l10nHelperService.isUsCountry()) {
      price = (overspendingQuantity * overspendingCost) / 1000;
    } else {
      price = overspendingQuantity * overspendingCost;
    }

    return price;
  }

  /** Достаточно ли средств на балансе для оплаты счетов */
  notEnoughFunds(): Observable<boolean> {
    return combineLatest(
      this.subscriptionStore.billing$.pipe(map((value) => value!.customer.balance)),
      of(this.subscriptionStore.invoices$.toArray()).pipe(
        map((invoices) => invoices.filter((invoice) => invoice.status === INVOICE_STATUS.NOT_PAID)),
        map((value) => value.reduce((prev, current) => prev + current.amountDue, 0)),
      ),
    ).pipe(map(([balance, amountDue]) => balance < amountDue));
  }

  /** Клик по кнопке пополнения баланса */
  onClickTopUpBalance(): void {
    this.trackClickTopUpBalance();
    this.openTopUpBalanceModal();
  }

  /** Открытие модалки пополнения баланса */
  openTopUpBalanceModal(): void {
    const modal = this.modalService.open(TopUpBalanceModalComponent, {
      centered: true,
    });

    modal.result.catch(() => {});
  }

  /**
   * Трек клика по кнопке "Пополнить баланс"
   */
  trackClickTopUpBalance(): void {
    const eventName = 'Оплата - клик по \u0022Пополнить баланс\u0022';

    this.carrotquestHelper.track(eventName);
  }
}
