import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DestroyService } from '@panel/app/services';
import { ServiceInstallationToastService } from '@panel/app/services/service-installation-toast/service-installation-toast.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-get-demo-modal',
  templateUrl: './get-demo-modal.component.html',
  styleUrls: ['./get-demo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class GetDemoModalComponent implements OnInit {
  /** Список приложений */
  @Input() appList!: any[];
  /** Текущее приложение */
  @Input() currentApp!: any;
  /** Текущий пользователь */
  @Input() djangoUser!: any;
  /** Является ли модалка приглашениям на аудит */
  @Input() isAudit!: boolean;
  /** Объект с данными для модального окна */
  @Input() modalWindowParams!: any;

  protected appListEmpty: any;
  protected isAppExists!: boolean;
  protected auditOrDemo: any;
  protected PROJECT_NAME: any;

  protected getDemoForm = this.fb.group({
    name: this.fb.control(null, [Validators.required]),
    phoneOrTelegram: this.fb.control<string | null>(null, [Validators.required]),
    selectedApp: this.fb.control<any>(null),
    inputApp: this.fb.control(null),
  });

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly djangoUserModel: DjangoUserModel,
    protected readonly serviceInstallationToast: ServiceInstallationToastService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.getDemoForm.setValue({
      name: this.modalWindowParams.djangoUser?.name || '',
      phoneOrTelegram: this.modalWindowParams.djangoUser.telephone || '',
      selectedApp: this.modalWindowParams.currentApp ? this.modalWindowParams.currentApp.id : null,
      inputApp: null,
    });

    this.appList = this.modalWindowParams.appList || [];
    this.appListEmpty = !this.appList.length;
    this.isAppExists = !!this.appList.length; // существование нужного аппа
    this.auditOrDemo = this.modalWindowParams.isAudit ? 'getAudit' : 'getDemo';
    this.PROJECT_NAME = environment.projectName;

    this.serviceInstallationToast.hideToast(); // при открытии этой модалки нужно убирать тост, чтобы он не маячил
  }

  /**
   * Закрытие модалки с передачей заполненных свойств из формы
   *
   * @param isValid
   */
  getDemo(isValid: any): void {
    if (!isValid) {
      return;
    }

    this.trackSubmitInfo();

    let identifyObj: any = {
      $name: this.getDemoForm.controls.name.getRawValue(),
    };

    if (this.isTelegramNickname()) {
      identifyObj['$social_telegram'] =
        'https://telegram.me/' + this.getDemoForm.controls.phoneOrTelegram.getRawValue()!.replace('@', '');
    } else {
      identifyObj['$phone'] = this.getDemoForm.controls.phoneOrTelegram.getRawValue();
    }

    this.carrotquestHelper.identify(identifyObj);

    var params: any = {
      name: this.getDemoForm.controls.name.getRawValue(),
      telephone: this.getDemoForm.controls.phoneOrTelegram.getRawValue(),
    };

    if (this.modalWindowParams.app) {
      var prefs: any = {};
      prefs['name__' + this.modalWindowParams.app.id] = this.getDemoForm.controls.name.getRawValue();
      params.prefs = JSON.stringify(prefs);
    }

    firstValueFrom(this.djangoUserModel.save(params)).then(() => this.saveDjangoUserSuccess());
  }

  private saveDjangoUserSuccess(): void {
    this.modalWindowParams.djangoUser.name = this.getDemoForm.controls.name.getRawValue();
    this.modalWindowParams.djangoUser.telephone = this.getDemoForm.controls.phoneOrTelegram.getRawValue();

    if (this.modalWindowParams.app) {
      this.modalWindowParams.djangoUser.prefs[this.modalWindowParams.app.id].name =
        this.getDemoForm.controls.name.getRawValue();
    }

    this.ngbActiveModal.close();
  }

  private trackSubmitInfo(): void {
    let eventName: any;
    let userData: any = {
      Имя: this.getDemoForm.controls.name.getRawValue(),
      url: window.location.pathname,
    };

    if (this.modalWindowParams.isAudit) {
      eventName = 'Админка - "Запросить аудит"';
    } else {
      eventName = 'Заявка на "Запросить демо"';
    }

    if (this.isTelegramNickname()) {
      userData['Телеграм'] = this.getDemoForm.controls.phoneOrTelegram.getRawValue();
    } else {
      userData['Телефон'] = this.getDemoForm.controls.phoneOrTelegram.getRawValue();
    }

    if (this.isAppExists && this.getDemoForm.controls.selectedApp.getRawValue()) {
      userData['Сайт'] = this.appList.find(
        (app) => app.id === this.getDemoForm.controls.selectedApp.getRawValue(),
      ).origin;
    } else if (!this.isAppExists && this.getDemoForm.controls.inputApp.getRawValue()) {
      userData['Сайт'] = this.getDemoForm.controls.inputApp.getRawValue();
    }

    this.carrotquestHelper.track(eventName, userData);
  }

  private isTelegramNickname(): boolean {
    return /^@?(?![_0-9]){1}(\w(?![_])){4,31}$/g.test(this.getDemoForm.controls.phoneOrTelegram.getRawValue()!);
  }
}
