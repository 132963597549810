<ng-container *transloco="let t; read: 'aiSalesPromtComponent'">
  <h4>{{ t('heading') }}</h4>
  <div class="position-relative">
    <textarea
      class="form-control resize-vertical custom-scroll"
      [formControl]="control"
      [placeholder]="t('placeholder')"
      rows="5"
    ></textarea>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>
