(function () {
  'use strict';

  require('./content.controller');

  angular.module('myApp.autoMessages').component('cqAutoMessageContent', cqAutoMessageContent());

  function cqAutoMessageContent() {
    return {
      bindings: {
        activeMessagesAmounts: '<', // Массив с количеством активых автоособщений по типам
        aiContentMakerIntegrations: '=', // список интеграций aiContentMaker
        telegramIntegrations: '=', // список Telegram интеграций
        addMessagePart: '&', // Добавление нового варианта автосообщения (для А/Б тестирования)
        billingInfo: '=', // Информация о биллинге
        currentApp: '=', // текущее приложение
        currentMessage: '=', // Сообщение
        djangoUser: '=', // текущий пользователь
        form: '=', //
        isEdit: '=', //
        isHideForNewOnboarding: '=?', // Флаг для скрывания элементов для нового онбординга
        planCapabilities: '=', // Список ограничений по тарифу
        refreshProportions: '&', // Добавление нового варианта автосообщения (для А/Б тестирования)
        onChangeMessagePartType: '&?', // Колбэк при смене типа варианта сообщения
      },
      controller: 'CqAutoMessageContentController',
      controllerAs: 'vm',
      template: require('./content.html'),
    };
  }
})();
