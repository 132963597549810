<ng-container *transloco="let t">
  <div class="d-inline-flex align-items-center">
    <h3 class="mb-0">{{ t('telegramBotToken.heading') }}</h3>
    <i
      class="cqi-sm cqi-question-circle ml-5"
      [ngbTooltip]="t('telegramBotToken.headingTooltip')"
    ></i>
  </div>
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control"
  >
    <input
      class="form-control"
      [formControl]="control"
      [placeholder]="tokenExample"
      data-test="integration-bot-token-input"
      type="text"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('telegramBotToken.input.errors.required') }}
      </cq-validation-message>
      <cq-validation-message [errorName]="TELEGRAM_API_ERRORS.VALIDATION_ERROR">
        {{ t('telegramBotToken.input.errors.invalid') }}
      </cq-validation-message>
      <cq-validation-message [errorName]="TELEGRAM_API_ERRORS.TELEGRAM_BOT_IDS_NOT_MATCHED">
        {{ t('telegramBotToken.input.errors.botIdNotMatch') }}
      </cq-validation-message>
    </cq-validation-messages>
    <ng-container *ngIf="integration.settings.botName">
      <div class="mt-5">
        <span class="text-secondary">{{ t('telegramBotToken.connectionSuccess') }}</span>
        <a
          [href]="integration.botLink"
          target="_blank"
          >{{'@' + integration.settings.botName }}</a
        >
      </div>
    </ng-container>
  </div>
</ng-container>
