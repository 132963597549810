(function () {
  'use strict';

  require('./cq-scheduled-message-audience.controller');

  angular.module('myApp.scheduledMessages').component('cqScheduledMessageAudience', cqScheduledMessageAudience());

  function cqScheduledMessageAudience() {
    return {
      bindings: {
        currentApp: '=', // Текущий апп
        message: '=', // Сообщение
        messagePart: '=', // Сообщение
        validators: '=',
        customSelectDropdownClass: '=?',
        filtersForm: '=', // Объект формы фильтров
        hideForCanvasBot: '=?', // Скрывать элементы если компонент используется в чат-боте на канвасе
        properties: '=', // Свойства и типы событий
        segments: '=', // Сегменты
        showedSendingFiltersPopoverInAudience: '=', // Статус показа поповера с условиями отправки по URL в "Аудитория"
        telegramIntegrations: '=',
        tags: '=', // Теги
      },
      controller: 'cqScheduledMessageAudienceController',
      controllerAs: 'vm',
      template: require('./cq-scheduled-message-audience.html'),
    };
  }
})();
