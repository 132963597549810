<ng-container *transloco="let t; read: 'editorFormatLinkComponent'">
  <div
    #linkTooltipInstance="ngbTooltip"
    [ngbTooltip]="t('tooltip.insertLink')"
    container="body"
    triggers="hover:blur"
  >
    <button
      #linkPopoverInstance="ngbPopover"
      type="button"
      class="btn btn-borderless-primary rounded-0"
      [ngbPopover]="linkPopover"
      popoverClass="link-popover"
      container="body"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
      (click)="onClickLinkPopoverButton()"
    >
      <i class="cqi-sm cqi-link"></i>
    </button>
  </div>

  <ng-template #linkPopover>
    <form (ngSubmit)="onSubmitForm($event)">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          [formControl]="linkFormGroup.controls.href"
          [placeholder]="t('input.href')"
        />
      </div>

      <div class="form-group">
        <input
          class="form-control"
          type="text"
          [formControl]="linkFormGroup.controls.text"
          [placeholder]="t('input.text')"
        />
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="linkPopoverCheckbox"
          class="custom-control-input"
          type="checkbox"
          [formControl]="linkFormGroup.controls.isBlank"
        />
        <label
          class="custom-control-label"
          for="linkPopoverCheckbox"
          >{{ t('input.isBlank') }}
        </label>
      </div>

      <div class="d-flex justify-end">
        <button
          class="btn btn-primary mt-15"
          type="submit"
          [disabled]="linkFormGroup.invalid"
        >
          {{ t('button.insert') }}
        </button>
      </div>
    </form>
  </ng-template>

  <div #selectionLinkPopoverContainer>
    <div
      #selectionLinkPopoverInstance="ngbPopover"
      [ngbPopover]="selectionLinkPopover"
      popoverClass="link-popover"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
    ></div>
    <ng-template #selectionLinkPopover>
      <form (ngSubmit)="onSubmitForm($event)">
        <ul
          ngbNav
          #selectionLinkPopoverNav="ngbNav"
          [(activeId)]="activeTab"
          class="nav nav-tabs"
        >
          <li [ngbNavItem]="1">
            <button
              ngbNavLink
              type="button"
              (click)="activeTab = 1"
              [ngbTooltip]="t('tooltip.editLink')"
            >
              <i class="cqi-sm cqi-pencil"></i>
            </button>
            <ng-template ngbNavContent>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="linkFormGroup.controls.href"
                  [placeholder]="t('input.href')"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="linkFormGroup.controls.text"
                  [placeholder]="t('input.text')"
                />
              </div>

              <div class="custom-control custom-checkbox">
                <input
                  id="selectionLinkPopoverCheckbox"
                  class="custom-control-input"
                  type="checkbox"
                  [formControl]="linkFormGroup.controls.isBlank"
                />
                <label
                  class="custom-control-label"
                  for="selectionLinkPopoverCheckbox"
                  >{{ t('input.isBlank') }}
                </label>
              </div>

              <div class="d-flex justify-end">
                <button
                  class="btn btn-primary mt-15"
                  type="submit"
                  [disabled]="linkFormGroup.invalid"
                >
                  {{ t('button.update') }}
                </button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button
              ngbNavLink
              type="button"
              (click)="onClickOpenLinkButton()"
              [ngbTooltip]="t('tooltip.openLink')"
            >
              <i class="cqi-sm cqi-external-link"></i>
            </button>
          </li>
          <li [ngbNavItem]="3">
            <button
              ngbNavLink
              type="button"
              (click)="onClickDeleteLinkButton()"
              [ngbTooltip]="t('tooltip.unlink')"
            >
              <i class="cqi-sm cqi-unlink"></i>
            </button>
            <ng-template ngbNavContent></ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="selectionLinkPopoverNav"></div>
      </form>
    </ng-template>
  </div>
</ng-container>
