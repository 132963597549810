import { Injectable } from '@angular/core';

import { AiSalesBotSettingsExternal } from '@http/ai-sales/ai-sales-external.type';
import { AiSalesBotSettings } from '@http/ai-sales/ai-sales-internal.type';

@Injectable({ providedIn: 'root' })
export class AiSalesMapper {
  public parseToInternal(aiSalesBotSettingsExternal: AiSalesBotSettingsExternal): AiSalesBotSettings {
    return {
      callHumanOperatorMessage: aiSalesBotSettingsExternal.callHumanOperatorMessage ?? {
        work: '',
        notWork: '',
      },
      conversationTag: aiSalesBotSettingsExternal.conversationTag ?? null,
      channels: aiSalesBotSettingsExternal.channels ?? null,
      closeTimeout: aiSalesBotSettingsExternal.delayForClosing ?? 15,
      gptTemperature: (aiSalesBotSettingsExternal.gptTemperature ?? 0.7) * 100,
      gptFunctions: aiSalesBotSettingsExternal.gptFunctions ?? [],
      integration: aiSalesBotSettingsExternal.integration,
      openAiApiKey: aiSalesBotSettingsExternal.openAiApiKey ?? '',
      prompt: aiSalesBotSettingsExternal.prompt,
      userEvent: aiSalesBotSettingsExternal.userEvent ?? null,
      userTag: aiSalesBotSettingsExternal.userTag ?? null,
    };
  }

  public parseToExternal(aiSalesBotSettingsInternal: AiSalesBotSettings): AiSalesBotSettingsExternal {
    const { closeTimeout, ...rest } = aiSalesBotSettingsInternal;

    return {
      ...rest,
      gptTemperature: rest.gptTemperature / 100,
      delayForClosing: closeTimeout,
    };
  }
}
