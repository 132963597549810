import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Inject, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@environment';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-outdated-browser-banner',
  templateUrl: './outdated-browser-banner.component.html',
  styleUrls: ['./outdated-browser-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutdatedBrowserBannerComponent {
  @HostBinding('class')
  class = 'bg-light-danger';

  @Output()
  closeButtonClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  learnMoreButtonClick: EventEmitter<void> = new EventEmitter<void>();

  projectName = environment.projectName;

  constructor(
    public readonly carrotquestHelper: CarrotquestHelper,
    private readonly cookieService: CookieService,
    private readonly l10nHelper: L10nHelperService,
    @Inject(WINDOW) public readonly windowRef: Window,
  ) {
    this.carrotquestHelper.track('Баннер для неподдерживаемых браузеров - Показался баннер', {
      'User agent': this.windowRef.navigator.userAgent,
    });
  }

  /**
   * Ссылка на статью в базе знаний о поддерживаемых браузерах
   */
  get outdatedBrowserArticle(): string {
    if (this.l10nHelper.isUsCountry()) {
      return 'https://help.dashly.io/article/3040';
    } else {
      return 'https://help.carrotquest.io/article/10099';
    }
  }

  /**
   * Установка куки
   */
  setOutdatedBrowserBannerCookie() {
    this.cookieService.set('carrotquest_outdated_browser_banner_closed', 'true', 7, '/');
  }

  /**
   * Трек закрытия баннера неподдерживаемых браузеров
   */
  trackClickCloseButton() {
    this.carrotquestHelper.track('Баннер для неподдерживаемых браузеров - Закрыл баннер');
  }

  /**
   * Трек клика по кнопке Подробнее в баннере для неподдерживаемых браузеров
   */
  trackClickLearnMoreButton() {
    this.carrotquestHelper.track('Баннер для неподдерживаемых браузеров - Клик на Подробнее');
  }
}
