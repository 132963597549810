import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { DestroyService } from '@panel/app/services';

@Component({
  selector:
    'cq-answer-to-user-prop[formControlName],cq-answer-to-user-prop[formControl],cq-answer-to-user-prop[ngModel]',
  templateUrl: './answer-to-user-prop.component.html',
  styleUrls: ['./answer-to-user-prop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AnswerToUserPropComponent implements OnInit, ControlValueAccessor {
  setUserProp = false;

  selectedProp: string | null = null;

  constructor(
    private readonly ngControl: NgControl,
    protected readonly messageEditorTriggerState: MessageEditorTriggerState,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error('Use [formControl]/[formControlName]/[(ngModel)] for correct work of cq-answer-to-user-prop');
    }
  }

  ngOnInit(): void {
    if (!this.ngControl || !this.ngControl.valueChanges) {
      return;
    }
    this.setUserProp = !!this.ngControl.value;
  }

  setUserPropChanged() {
    if (!this.setUserProp) {
      this.selectedProp = null;
      this.onChange(null);
    }
  }

  registerOnChange(fn: (v: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string | null): void {
    this.selectedProp = value;
  }

  onChange: (v: string | null) => void = (v: string | null) => {};

  private onTouched: () => void = () => {};
}
