import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { App } from '@http/app/app.model';
import { AiContentMakerBodyJsonVariables } from '@http/message-part/message-part.types';
import { UserProperty } from '@http/property/property.model';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-acm-props-editor',
  templateUrl: './acm-props-editor.component.html',
  styleUrls: ['./acm-props-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcmPropsEditorComponent
  extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<AiContentMakerBodyJsonVariables>>
  implements OnInit
{
  @Input({ required: true })
  index: number = 0;

  @Input({ required: true })
  isLast: boolean = false;

  @Input({ required: true })
  properties: UserProperty[] = [];

  @Input({ required: true })
  currentApp!: App;

  @Output()
  delete: EventEmitter<number> = new EventEmitter<number>();

  control: FormGroup<ToFormGroupControls<AiContentMakerBodyJsonVariables>> = this.fb.group({
    propertyName: this.fb.control<string | null>(null, Validators.required),
    default: this.fb.control<string>('', { nonNullable: true, validators: [Validators.required] }),
    context: this.fb.control<string>('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.control.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }
}
