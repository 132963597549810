import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

import { SavedRepliesModule } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/saved-replies.module';

import { SavedRepliesPageComponent } from './saved-replies-page.component';

@NgModule({
  declarations: [SavedRepliesPageComponent],
  imports: [CommonModule, TranslocoDirective, SavedRepliesModule],
})
export class SavedRepliesPageModule {}
