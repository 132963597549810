import { SUBSCRIPTION_STATUSES } from '../../../../app/services/billing-info/billing-info.constants';

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.modals.partyIsOver').controller('CqPartyIsOverController', CqPartyIsOverController);

  function CqPartyIsOverController($state, $scope, PROJECT_NAME, appModel, djangoUserModel, planVersionService) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.goToApps = goToApps;
      vm.isShowChangeOtherAppsButton = false; // Показывать ли кнопки для выбора другого app'а
      vm.isSubscriptionCancelled = vm.resolve.billingInfo.subscription.status === SUBSCRIPTION_STATUSES.CANCELLED; // Закончилась ли у App'а подписка
      vm.isSubscriptionOver = isSubscriptionOver;
      vm.isSuperAdmin = djangoUserModel.isSuperAdmin(vm.resolve.currentApp.id, vm.resolve.djangoUser); // Является ли пользователь супер админом
      vm.isTrialOver = isTrialOver;
      vm.PROJECT_NAME = PROJECT_NAME;

      // при открытии модального окна, проверим есть ли другие app'ы, чтобы дать возможность вернуться к их выбору
      firstValueFrom(appModel.getList()).then((apps) => {
        // Вычитание 1 исключает текущий App из кол-ва App'ов пользователя
        let countApps = apps.length - 1;

        vm.isShowChangeOtherAppsButton = countApps >= 1;
        $scope.$applyAsync();
      });
    }

    /**
     * Переход на состояние со списком app'ов
     */
    function goToApps() {
      $state.go('account.content', { tab: 'apps' });
    }

    /** Отменена ли подписка */
    function isSubscriptionOver() {
      return !planVersionService.isTrial() && vm.isSubscriptionCancelled;
    }

    /** Отменён ли триал */
    function isTrialOver() {
      return planVersionService.isTrial() && vm.isSubscriptionCancelled;
    }
  }
})();
