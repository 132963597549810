import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Properties } from '@http/property/property.model';
import { EDITOR_FORMATS_DEFAULT } from '@panel/app/partials/editor/editor.constant';

@Component({
  selector: 'cq-editor-page',
  templateUrl: './editor-page.component.html',
  styleUrls: ['./editor-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorPageComponent {
  @Input({ required: true })
  public properties!: Properties;

  protected formats = EDITOR_FORMATS_DEFAULT;

  protected content: string = '';
}
