import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { copy, extend } from 'angular';
import { filter, find, reject } from 'lodash-es';
import { map, mergeMap, Observable, throwError } from 'rxjs';

import { App } from '@http/app/app.model';
import { TeamMember } from '@http/team-member/team-member.types';
import {
  PSEUDO_TEAM_MEMBERS_GROUPS,
  PSEUDO_TEAM_MEMBERS_GROUPS_IDS,
} from '@http/team-member-group/team-member-group.constants';
import { TeamMemberGroupModel } from '@http/team-member-group/team-member-group.model';
import { CaseStyleHelper } from '@panel/app/services';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import {
  EXTENDED_RESPONSE,
  IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS,
} from '@panel/app/shared/constants/http.constants';

import {
  PSEUDO_TEAM_MEMBERS_IDS,
  PSEUDO_TEAM_MEMBERS_TYPES,
  REMOVE_TEAM_MEMBER_ERRORS,
  TEAM_MEMBER_DEFAULT_AVATAR_URL,
  TEAM_MEMBER_PERMISSIONS,
} from './team-member.constants';

/**
 * TODO ANGULAR_TS
 *  Отрефакторить некоторые меты и типизировать модель
 */

/**
 * Сервис для работы с членами команды
 */
@Injectable({ providedIn: 'root' })
export class TeamMemberModel {
  /**
   * Член команды по умолчанию
   *
   * @type {Object}
   */
  static DEFAULT_TEAM_MEMBER = {
    avatar: TEAM_MEMBER_DEFAULT_AVATAR_URL, // URL аватарки по умолчанию
    email: '', // электронная почта
    emailFrom: '', // электронная почта в рассылках
    group: null, // группа
    internalName: '', // внутреннее имя
    name: '', // имя
    notifications: {
      // оповещения о диалогах
      desktop: {
        // веб-версия и десктопное приложение
        assignedMe: true, // назначенных на этого члена команды
        assignedNobody: true, // не назначенных никому
        assignedOtherAdmin: false, // назначенных на другого члена команды
        mentioned: true, // хз что это, лучше спросить у Миши, но он вроде тоже не знает
      },
      email: {
        // почта
        assignedMe: true,
        assignedNobody: true,
        assignedOtherAdmin: false,
        mentioned: true,
      },
      push: {
        // пуши на мобилках
        assignedMe: true,
        assignedNobody: true,
        assignedOtherAdmin: false,
        mentioned: true,
      },
      stats: false, // статистика работы сервиса на почту
    },
    permissions: TEAM_MEMBER_PERMISSIONS.OPERATOR, // права пользователя
    permissionsExport: false, // разрешить экспорт пользователей
    permissionsSendBulk: false, // разрешить массовые рассылки
    permissionsConversationUserList: true, // разрешить показ списка пользователей в диалогах
    presence: 'offline', // статус присутствия оператора в админке
    setDefaultAvatar: false, // флаг возвращения аватарки по умолчанию
  };
  /**
   * Разрешения члена команды на члена команды
   *
   * @type {Object}
   */
  private USER_ON_USER_PERMISSIONS = this.getUserOnUserPermissions();

  public teamMembers = [] as TeamMember[];

  constructor(
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly httpClient: HttpClient,
    private readonly teamMemberGroupModel: TeamMemberGroupModel,
    private readonly translocoService: TranslocoService,
    private readonly l10nHelperService: L10nHelperService,
  ) {}

  /**
   * Добавление нашей поддержки
   *
   * @param {String} appId - ID аппа
   * @returns {*|PromiseLike<T>|Promise<T>}
   */
  public addSupport(appId: string): Observable<any> {
    var params = {
      app: appId,
    };
    return this.httpClient
      .post('/panel/addsupport', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((response: any) => response.data.id));
  }

  /**
   * Проверка разрешений у члена команды
   *
   * @param {TEAM_MEMBER_PERMISSIONS} permissions Разрешение, которое нужно проверить
   * @param {Object} teamMember Член команды
   * @return {Boolean}
   */
  public checkPermissions(permissions: TEAM_MEMBER_PERMISSIONS, teamMember: any) {
    return teamMember.permissions === permissions;
  }

  /**
   * Создание члена команды
   *
   * @param {String} appId ID приложения
   * @param {Object} teamMember Информация о члене команды
   * @param {Object} currentApp FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @param {Boolean} includeGroup Вернуть группу члена команды
   * @return {Promise}
   */
  public create(appId: string, teamMember: TeamMember, currentApp: App, includeGroup: boolean): Observable<any> {
    var params = this.parseToServerFormat(teamMember);

    params.app = appId;
    params.group =
      params.group === PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP] ? null : params.group;
    params.locale = this.l10nHelperService.isUsCountry() ? 'en-us' : 'ru-ru';

    return this.httpClient
      .post('/panel/addadmin', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          this.getList(appId, null, false, false);
          return this.get(appId, response.data.id, currentApp, includeGroup, true);
        }),
      );
  }

  /**
   * Получение члена команды по его ID
   *
   * @param {String} appId ID приложения
   * @param {String} teamMemberId ID члена команды
   * @param {Object} currentApp FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @param {Boolean} includeGroup Вернуть группу члена команды
   * @param {Boolean} includeNotifications Вернуть параметры уведомлений
   * @return {Promise}
   */
  public get(
    appId: string,
    teamMemberId: string,
    currentApp: App,
    includeGroup: boolean,
    includeNotifications: boolean,
  ): Observable<any> {
    const params = {
      include_group: includeGroup,
      include_notifications: includeNotifications,
      app: appId,
    };

    return this.httpClient
      .get('/apps/' + appId + '/admins/' + teamMemberId, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          let teamMember = response.data;

          this.parse(teamMember, includeGroup);
          if (currentApp) {
            this.updateCurrentAppAdmins(currentApp, teamMember);
          }

          return teamMember;
        }),
      );
  }

  /**
   * Получение члена команды по умолчанию
   *
   * @return {Object}
   */
  public getDefault() {
    var defaultTeamMember = copy(TeamMemberModel.DEFAULT_TEAM_MEMBER);
    defaultTeamMember.group = this.teamMemberGroupModel.getWithoutGroup();

    return defaultTeamMember;
  }

  /**
   * Получение списка членов команды по ID приложения
   *
   * @param appId ID приложения
   * @param currentApp FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @param includePseudoTeamMember - Флаг добавления псевдооператора в список
   * @param includeGroup=true - Флаг включения в ответ запроса группы члена команды
   * @param includeConversationsAutoAssign=true - Флаг включения в ответ запроса значения настройки автоназначения диалогов
   * @param includeChatGptBot - Флаг добавления ChatGPT бота в список
   * @return {Promise}
   */
  public getList(
    appId: string,
    currentApp: App | null,
    includePseudoTeamMember: boolean,
    includeGroup: boolean,
    includeConversationsAutoAssign: boolean = true,
    includeChatGptBot: boolean = false,
  ): Observable<any> {
    var params = {
      include_group: includeGroup,
      include_conversations_auto_assign: includeConversationsAutoAssign,
    };

    return this.httpClient
      .get('/apps/' + appId + '/admins', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          var teamMembers = response.data;

          if (!includeChatGptBot) {
            teamMembers = teamMembers.filter((teamMember: TeamMember) => teamMember.type !== 'bot');
          }

          for (var i = 0; i < teamMembers.length; i++) {
            var teamMember = teamMembers[i];

            this.parse(teamMember, includeGroup);

            if (currentApp) {
              this.updateCurrentAppAdmins(currentApp, teamMember);
            }
          }

          if (includePseudoTeamMember) {
            teamMembers.unshift(this.getPseudoTeamMember(PSEUDO_TEAM_MEMBERS_TYPES.ALL_TEAM_MEMBERS));
          }

          copy(teamMembers, this.teamMembers);

          return teamMembers;
        }),
      );
  }

  /**
   * Получение псевдооператора
   *
   * @param {PSEUDO_TEAM_MEMBERS_TYPES} teamMemberType - Тип псевдооператора
   * @returns {Object}
   */
  public getPseudoTeamMember(teamMemberType: PSEUDO_TEAM_MEMBERS_TYPES) {
    let defaultTeamMember = this.getDefault();

    var pseudoTeamMember = Object.assign(defaultTeamMember, {
      name: this.translocoService.translate('models.teamMember.pseudoMemberTypes.' + teamMemberType),
      id: PSEUDO_TEAM_MEMBERS_IDS[teamMemberType],
    });

    return pseudoTeamMember;
  }

  /**
   * Получение списка прав члена команды на члена команды
   *
   * @return {Object}
   */
  public getUserOnUserPermissions() {
    var userOnUserPermissions: any = {};

    // оператор нифига не может делать с другими членами команды
    userOnUserPermissions[TEAM_MEMBER_PERMISSIONS.OPERATOR] = [];
    // админ может делать всё что угодно с операторами
    userOnUserPermissions[TEAM_MEMBER_PERMISSIONS.ADMIN] = [TEAM_MEMBER_PERMISSIONS.OPERATOR];
    // суперадмин может делать всё что угодно со всеми
    userOnUserPermissions[TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN] = [
      TEAM_MEMBER_PERMISSIONS.OPERATOR,
      TEAM_MEMBER_PERMISSIONS.ADMIN,
      TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN,
    ];

    return userOnUserPermissions;
  }

  /**
   * Доступно ли редактирование прав на экспорт
   *
   * @param teamMemberPermission - Права доступа
   */
  hasAccessToEditPermissionExport(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean {
    return ![TEAM_MEMBER_PERMISSIONS.OPERATOR, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN].includes(teamMemberPermission);
  }

  /**
   * Доступно ли редактирование прав на массовую рассылку
   *
   * @param teamMemberPermission - Права доступа
   */
  hasAccessToEditPermissionSendBulk(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean {
    return ![TEAM_MEMBER_PERMISSIONS.OPERATOR, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN].includes(teamMemberPermission);
  }

  /**
   * Доступно ли разрешение на показ списка пользователей в диалогах
   *
   * @param teamMemberPermission - Права доступа
   */
  isDisabledPermissionConversationUserList(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean {
    return ![TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN].includes(teamMemberPermission);
  }

  /**
   * Проверка может ли член команды с определёнными правами управлять другим членом команды с другими правами
   *
   * @param {USER_ON_USER_PERMISSIONS} teamMemberPermissions1 Права члена команды, который хочет произвести действие
   * @param {USER_ON_USER_PERMISSIONS} teamMemberPermissions2 Права члена команды, над которым хотят произвести действие
   * @return {Boolean}
   */
  public hasPermissions(teamMemberPermissions1: any, teamMemberPermissions2: any) {
    return !!~this.USER_ON_USER_PERMISSIONS[teamMemberPermissions1].indexOf(teamMemberPermissions2);
  }

  public isAdmin(teamMember: any) {
    return this.checkPermissions(TEAM_MEMBER_PERMISSIONS.ADMIN, teamMember);
  }

  public isOperator(teamMember: any) {
    return this.checkPermissions(TEAM_MEMBER_PERMISSIONS.OPERATOR, teamMember);
  }

  public isSuperAdmin(teamMember: any) {
    return this.checkPermissions(TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, teamMember);
  }

  /**
   * Парсинг члена команды
   *
   * @param {Object} teamMember Член команды
   * @param {Object} includeGroup=false Флаг включения в ответ запроса группы члена команды
   */
  public parse(teamMember: any, includeGroup?: any) {
    if (includeGroup) {
      if (teamMember.group) {
        this.teamMemberGroupModel.parse(teamMember.group);
      } else {
        teamMember.group = this.teamMemberGroupModel.getWithoutGroup();
      }
    }

    teamMember.internalName = teamMember.nameInternal;
    delete teamMember.nameInternal;
  }

  /**
   * Парсинг члена команды в формат сервера
   *
   * @param {Object} teamMember Член команды
   * @return {Object}
   */
  public parseToServerFormat(teamMember: any) {
    var parsedTeamMember = copy(teamMember);

    // если обновляется аватарка, и это файл - отсылаем файл, при этом контроллируем, что флаг восстановления аватарки по умолчанию не отправится
    if (parsedTeamMember.avatar instanceof File) {
      parsedTeamMember.avatar = teamMember.avatar; // HACK: angular.copy некорректно работает с файлами, поэтому аватар нужно переприсвоить
      delete parsedTeamMember.setDefaultAvatar;
    } else if (parsedTeamMember.setDefaultAvatar) {
      // а если возвращается стандартная аватарка - удаляем всю информацию об аватарке
      delete parsedTeamMember.avatar;
    } else {
      // если нет ни того, ни другого - на сервер ничего посылать не нужно
      delete parsedTeamMember.avatar;
      delete parsedTeamMember.setDefaultAvatar;
    }
    if (parsedTeamMember.group) {
      parsedTeamMember.group = parsedTeamMember.group.id;
    }
    if (parsedTeamMember.internalName) {
      parsedTeamMember.nameInternal = parsedTeamMember.internalName;
      delete parsedTeamMember.internalName;
    }

    return parsedTeamMember;
  }

  /**
   * Удаление члена команды по его ID
   *
   * @param {String} appId - ID приложения
   * @param {Object} teamMember - Член команды
   * @param {Object} currentApp - FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @param {Array} teamMembers - Список членв команды
   * @param {Object} djangoUser - Текущий пользователь
   * @param {Boolean} force - Удаление без проверки
   * @return {Promise}
   */
  public remove(
    appId: string,
    teamMember: TeamMember,
    currentApp: any,
    teamMembers: TeamMember[],
    djangoUser: any,
    force: boolean,
  ): Observable<any> {
    if (!force) {
      // если чувак пытается удалить сам себя - появляется очень много вариантов
      if (teamMember.id === djangoUser.id) {
        let anotherSuperAdmins = filter(teamMembers, function (member) {
          return member.permissions === TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN;
        });

        anotherSuperAdmins = reject(anotherSuperAdmins, function (member) {
          return member.id === teamMember.id;
        });

        if (teamMember.permissions === TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN && teamMembers.length === 1) {
          // если чувак остался единственным суперадмином - предупреждаем его о том, что доступ к приложению сможет восстановить только поддержка
          return throwError(() => REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_TEAM_MEMBERS);
        } else if (
          teamMember.permissions === TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN &&
          teamMembers.length > 1 &&
          !anotherSuperAdmins.length
        ) {
          // если кроме чувака остались ещё члены команды, и среди них нет суперадминов - говорим чуваку, что надо выбрать нового суперадмина
          return throwError(() => REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_SUPER_ADMINS);
        } else {
          // во всех других случаях - просто говорим чуваку, что он удаляет себя
          return throwError(() => REMOVE_TEAM_MEMBER_ERRORS.REMOVE_SELF);
        }
      } else {
        // а если чувак пытается удалить другого члена команды - просто спрашиваем, уверен ли он
        return throwError(() => REMOVE_TEAM_MEMBER_ERRORS.REMOVE_TEAM_MEMBER);
      }
    }

    var params = {
      app: appId,
      id: teamMember.id,
    };

    return this.httpClient
      .post('/panel/removeadmin', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map(() => {
          if (currentApp) {
            this.removeCurrentAppAdmin(currentApp, teamMember.id);
          }
          this.getList(appId, null, false, false);
          return teamMember.id;
        }),
      );
  }

  /**
   * FIXME currentApp.admins: функция-костыль для актуализации currentApp.admins
   * костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   *
   * @param currentApp
   * @param teamMemberId
   */
  public removeCurrentAppAdmin(currentApp: App, teamMemberId: string) {
    var currentAppAdmin = find(currentApp.admins, { id: teamMemberId });

    if (currentAppAdmin) {
      currentApp.admins.splice(currentApp.admins.indexOf(currentAppAdmin), 1);
    }
  }

  /**
   * Сохранение данных о члене команды
   *
   * @param {String} appId ID приложения
   * @param {Object} teamMember Член команды
   * @param {Object} currentApp FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @return {Promise}
   */
  public save(appId: string, teamMember: TeamMember, currentApp: App): Observable<any> {
    const body = this.parseToServerFormat(teamMember);
    body.app = appId;

    this.caseStyleHelper.keysToUnderscore(body);
    body.notifications = JSON.stringify(body.notifications);

    return this.httpClient
      .patch('/panel/editadmin', body, {
        context: new HttpContext().set(IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        mergeMap((response: any) => {
          return this.get(appId, teamMember.id, currentApp, true, true);
        }),
      );
  }

  /**
   * Изменение разрешений члена команды на приложение
   *
   * @param {String} appId ID приложения
   * @param {String} teamMemberId
   * @param {TEAM_MEMBER_PERMISSIONS} newPermissions Новые разрешения члена команды на приложение
   * @param {Object} currentApp FIXME currentApp.admins: костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   * @return {Promise}
   */
  public setPermissions(appId: string, teamMemberId: string, newPermissions: TEAM_MEMBER_PERMISSIONS, currentApp: App) {
    var teamMemberFields: any = {
      id: teamMemberId,
      permissions: newPermissions,
    };

    return this.save(appId, teamMemberFields, currentApp);
  }

  /**
   * FIXME currentApp.admins: функция-костыль для актуализации currentApp.admins
   * костыль из-за того, что в currentApp нужно поддерживать admins в актуальном состоянии. Выпилить, когда никакой код не будет работать с currentApp.admins
   *
   * @param currentApp
   * @param teamMember
   */
  public updateCurrentAppAdmins(currentApp: App, teamMember: any) {
    var currentAppAdmin = find(currentApp.admins, { id: teamMember.id });

    if (currentAppAdmin) {
      extend(currentAppAdmin, teamMember);
    } else {
      currentApp.admins.push(teamMember);
      currentApp.admins.sort(function (a: any, b: any) {
        return a.id - b.id;
      });
    }
  }
}
