import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '@panel/app/shared/shared.module';

import { SavedRepliesPopoverComponent } from './saved-replies-popover.component';

@NgModule({
  declarations: [SavedRepliesPopoverComponent],
  imports: [CommonModule, TranslocoDirective, SharedModule, ReactiveFormsModule, TuiLet, UIRouterModule, NgbNavModule],
})
export class SavedRepliesPopoverModule {}
