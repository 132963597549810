import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { ApiLeadBotResponse } from '@http/chat-bot/types/chat-bot-external.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AMOCRM_FIELDS_TYPE } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmPipeline } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmField, AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationAutoMessage } from '@http/integration/types/integration.types';

/**
 * Сервис для работы с AmoCRM
 */

@Injectable({
  providedIn: 'root',
})
export class AmocrmService {
  constructor(
    private readonly appService: AppService,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  /**
   * Получение списка чат-ботов, которые используют текущую интеграцию AmoCRM
   *
   * @param integrationId ID интеграции
   */
  getChatBotsIntegration(integrationId: string): Observable<ApiLeadBotResponse[]> {
    return this.integrationService.getChatBots<ApiLeadBotResponse[]>(INTEGRATION_TYPES.AMOCRM, integrationId);
  }

  /**
   * Базовый метод создания поля в amoCRM
   * @param fieldType - Тип поля
   * @param integrationId - ID интеграции
   * @param name - Имя поля
   * @private
   */
  private createField(fieldType: AMOCRM_FIELDS_TYPE, integrationId: string, name: string): Observable<unknown> {
    const requestOptions = {
      name,
    };

    return this.http.post<unknown>(
      `/integrations/${INTEGRATION_TYPES.AMOCRM}/${integrationId}/${fieldType}/custom_fields`,
      requestOptions,
    );
  }

  /**
   * Создание нового поля контакта (contact) в amoCRM
   * @param integrationId - ID интеграции
   * @param name - Имя поля контакта
   */
  createContactFields(integrationId: string, name: string) {
    return this.createField('contact', integrationId, name);
  }

  /**
   * Создание нового поля сделки (lead) в amoCRM
   * @param integrationId - ID интеграции
   * @param name - Имя поля сделки
   */
  createLeadFields(integrationId: string, name: string) {
    return this.createField('lead', integrationId, name);
  }

  /**
   * Базовый метод получения списка полей из amoCRM
   * @param fieldType - Тип поля
   * @param integrationId - ID интеграции
   * @private
   */
  private getFields(fieldType: AMOCRM_FIELDS_TYPE, integrationId: string): Observable<AmocrmField[]> {
    const requestOptions = {};

    return this.http
      .get<AmocrmField[]>(
        `/integrations/${INTEGRATION_TYPES.AMOCRM}/${integrationId}/${fieldType}/custom_fields`,
        requestOptions,
      )
      .pipe(catchError(() => of([])));
  }

  /**
   * Получение списка полей контакта (contact) из amoCRM
   * @param integrationId - ID интеграции
   */
  getContactFields(integrationId: string): Observable<AmocrmField[]> {
    return this.getFields('contact', integrationId);
  }

  /**
   * Получение списка полей сделки (lead) из amoCRM
   *
   * @param integrationId - ID интеграции
   */
  getLeadFields(integrationId: string): Observable<AmocrmField[]> {
    return this.getFields('lead', integrationId);
  }

  /**
   * Получение тегов сделок AmoCRM
   *
   * @param integrationId - ID интеграции
   */
  getLeadsTags(integrationId: string): Observable<AmocrmLeadTag[]> {
    return this.http.get<AmocrmLeadTag[]>(`/integrations/${INTEGRATION_TYPES.AMOCRM}/${integrationId}/tags`);
  }

  /**
   * Получение списка воронок и статусов для текущей интеграции
   *
   * @param integrationId ID интеграции
   */
  getPipelinesIntegration(integrationId: string): Observable<AmocrmPipeline[]> {
    return this.http.get<AmocrmPipeline[]>(`/integrations/${INTEGRATION_TYPES.AMOCRM}/${integrationId}/pipelines`);
  }

  /**
   * Получение списка триггерных сообщений, которые используют текущую интеграцию c AmoCRM
   *
   * @param integrationId ID интеграции
   */
  getTriggerMessagesIntegration(integrationId: string): Observable<IntegrationAutoMessage[]> {
    return this.integrationService.getAutoMessages(
      INTEGRATION_TYPES.AMOCRM,
      integrationId,
      this.appService.currentAppId,
    );
  }
}
