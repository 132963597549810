import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';

import { SharedModule } from '@panel/app/shared/shared.module';

import { OtherActionsDropdownComponent } from './other-actions-dropdown.component';

@NgModule({
  declarations: [OtherActionsDropdownComponent],
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule, SharedModule, TranslocoModule, TuiLet],
  exports: [OtherActionsDropdownComponent],
})
export class OtherActionsDropdownModule {}
