import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { APP_TASKS } from '@http/app/app.constants';
import { App } from '@http/app/app.model';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { FEATURE_LABELS_ONLY } from '@http/feature-label/feature-label.constants';
import { FeatureLabelModel } from '@http/feature-label/feature-label.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { TeamMember } from '@http/team-member/team-member.types';
import { DestroyService, OneTimeFlagService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { NAVIGATION_SECTION, NAVIGATIONS_STATES } from '@panel/app/services/block-panel/block-panel.constants';
import { BlockPanelService } from '@panel/app/services/block-panel/block-panel.service';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { BlockingService } from '@panel/app-old/shared/services/blocking/blocking.service';

@Component({
  selector:
    'cq-navigation[billingInfo][djangoUserTempData][notReadAllChannelsCounter][notAssignedAllChannelsCounter][currentApp][djangoUser][teamMembers]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class NavigationComponent implements OnInit {
  @Input()
  billingInfo!: BillingInfo;

  /** Временные данные django-пользователя */
  @Input()
  djangoUserTempData!: DjangoUserTempData;

  /**
   * Количество не прочитанных диалогов по всем каналам
   */
  @Input()
  notReadAllChannelsCounter!: number;

  /**
   * Количество не назначенных диалогов по всем каналам
   */
  @Input()
  notAssignedAllChannelsCounter!: number;

  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  @Input()
  teamMembers!: TeamMember[];

  @HostBinding('class.dark-theme')
  get isDark() {
    return this.utilsService.isDarkThemeActive();
  }

  BILLING_ADD_ONS = BILLING_ADD_ONS;

  /** Список feature-лейблов */
  FEATURE_LABELS_ONLY = FEATURE_LABELS_ONLY;

  NAVIGATION_SECTION = NAVIGATION_SECTION;
  PSEUDO_CHANNEL_IDS = PSEUDO_CHANNEL_IDS;
  PSEUDO_CHANNEL_TYPES = PSEUDO_CHANNEL_TYPES;

  /** Доступ до аналитики диалогов */
  accessToAnalyticsConversations: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до страницы триггерных сообщений */
  accessToAutoMessagesPage: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до страницы чат-ботов */
  accessToChatBotsPage: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до раздела для разработчиков */
  accessToDevelopers: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до воронок */
  accessToEventsFunnels: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до интеграций */
  accessToIntegrations: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до базы знаний */
  accessToKnowledgeBase: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до раздела Лидов */
  accessToLeads: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до статистики ручных рассылок */
  accessToManualMessagesStats: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до отчётов */
  accessToReports: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до мастера сбора данных */
  accessToTrackMasterJsCode: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Доступ до аналитики событий */
  accessToUsersEvents: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  hasUnpaidInvoices = false;
  /**
   * Доступ до цепочек по фичегалке
   */
  hasAccessToTriggerChainFeature = this.featureModel.hasAccess(FEATURES_ONLY.TRIGGER_CHAIN);
  /**
   * Открыт или нет дропдаун "Отчёты"
   */
  isReportsDropdownOpen = false;
  /**
   * Открыт или нет дропдаун "Настройки"
   */
  isSettingsDropdownOpen = false;

  /** Был ли показан tooltip стартер гайда */
  isStarterGuideTooltipHasBeenShown: boolean = this.oneTimeFlagService.isSet(ONE_TIME_FLAGS.STARTER_GUIDE_LOGO_TOOLTIP);

  /**
   * Текущий пользователь админки
   */
  currentTeamMember!: TeamMember;

  /** Является ли текущий пользователь оператором */
  isOperator!: boolean;

  readonly PAID_PLAN_VERSION = PAID_PLAN_VERSION;

  get notAssignedCounter(): string {
    return this.notAssignedAllChannelsCounter > 99 ? '99+' : this.notAssignedAllChannelsCounter.toString();
  }

  /**
   * Есть ли не прочитанные
   */
  get hasNotRead(): boolean {
    return this.notReadAllChannelsCounter > 0;
  }

  /**
   * Есть ли неназначенные
   */
  get hasNotAssigned(): boolean {
    return this.notAssignedAllChannelsCounter > 0;
  }

  /**
   * CSS класс темы
   */
  get themeClass(): string {
    return this.utilsService.isDarkThemeActive() ? 'dark-theme' : '';
  }

  /** Состояние, на которое нужно вести при попытке попасть на страницу оплаты */
  get subscriptionState(): string {
    return this.planVersionService.isTrial() ? 'app.content.subscription.pricing' : 'app.content.subscription';
  }

  //@ngInject
  constructor(
    private readonly blockingService: BlockingService,
    private readonly blockPanelService: BlockPanelService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    public readonly djangoUserModel: DjangoUserModel,
    private readonly featureModel: FeatureModel,
    private readonly featureLabelModel: FeatureLabelModel,
    private readonly oneTimeFlagService: OneTimeFlagService,
    public readonly paywallService: PaywallService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly planVersionService: PlanVersionService,
    private readonly transloco: TranslocoService,
    private readonly uiRouter: UIRouter,
    private readonly utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.accessToAnalyticsConversations = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.ANALYTICS_CONVERSATIONS,
      this.currentApp,
    );
    this.accessToAutoMessagesPage = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_PAGE,
      this.currentApp,
    );
    this.accessToChatBotsPage = this.planFeatureAccessService.getAccess(PLAN_FEATURE.CHAT_BOTS_PAGE, this.currentApp);
    this.accessToDevelopers = this.planFeatureAccessService.getAccess(PLAN_FEATURE.DEVELOPERS, this.currentApp);
    this.accessToEventsFunnels = this.planFeatureAccessService.getAccess(PLAN_FEATURE.EVENTS_FUNNELS, this.currentApp);
    this.accessToIntegrations = this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS, this.currentApp);
    this.accessToKnowledgeBase = this.planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE, this.currentApp);
    this.accessToLeads = this.planFeatureAccessService.getAccess(PLAN_FEATURE.LEADS, this.currentApp);
    this.accessToManualMessagesStats = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.MANUAL_MESSAGES_STATS,
      this.currentApp,
    );
    this.accessToReports = this.planFeatureAccessService.getAccess(PLAN_FEATURE.REPORTS, this.currentApp);
    this.accessToTrackMasterJsCode = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.TRACK_MASTER_JS_CODE,
      this.currentApp,
    );
    this.accessToUsersEvents = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_EVENTS, this.currentApp);

    let hasUnpaidInvoices = false;
    if ('due_invoices_count' in this.billingInfo.subscription) {
      hasUnpaidInvoices = this.billingInfo.subscription.due_invoices_count > 0;
    }
    this.hasUnpaidInvoices = hasUnpaidInvoices;
    /*
    FIXME currentApp.admins: в будущем, когда работа внутри app будет осуществляться не через djangoUser,
     а через teamMember - выпилить это, т.к. teamMember будет резолвиться в состоянии app
     */
    this.currentTeamMember = this.teamMembers.filter((member) => this.djangoUser.id === member.id)[0];

    this.isOperator = this.djangoUserModel.isOperator(this.currentApp.id, this.djangoUser);

    // TS ругается на отсутствие success$
    // Но он добавляется в тип в @uirouter/rx
    // ХЗ почему не происходит обновления типов
    //@ts-ignore
    this.uiRouter.globals.success$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  reportsDropdownOpenChange(isOpen: boolean) {
    this.isReportsDropdownOpen = isOpen;
  }

  settingsDropdownOpenChange(isOpen: boolean) {
    this.isSettingsDropdownOpen = isOpen;
  }

  /**
   * Получение текста для тултипа для каждого раздела в навигации
   *
   * @param section Раздел навигации
   */
  getNavigationItemTooltipText(section: NAVIGATION_SECTION): string {
    const isItemDisabled = this.isNavigationItemDisabled(section);
    const isNeedToShowDisabledTooltip = !this.isOperator && isItemDisabled;

    switch (section) {
      case NAVIGATION_SECTION.DASHBOARD:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.home.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.home.tooltip');
        }
      case NAVIGATION_SECTION.LEADS:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.visitors.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.visitors.tooltip');
        }
      case NAVIGATION_SECTION.AUTO_MESSAGES:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.triggerMessages.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.triggerMessages.tooltip');
        }
      case NAVIGATION_SECTION.CONVERSATIONS:
        if (isNeedToShowDisabledTooltip) {
          if (this.djangoUserTempData && this.djangoUserTempData.task === APP_TASKS.RAISE_LEAD_CONVERSION) {
            return this.transloco.translate('navigationComponent.inbox.disabledTooltip.forMarketerCase');
          }
          return this.transloco.translate('navigationComponent.inbox.disabledTooltip.default');
        } else {
          return this.transloco.translate('navigationComponent.inbox.tooltip');
        }
      case NAVIGATION_SECTION.TRIGGER_CHAIN:
        return this.transloco.translate('navigationComponent.chains.tooltip');
      case NAVIGATION_SECTION.CHAT_BOTS:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.chatBots.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.chatBots.tooltip');
        }
      case NAVIGATION_SECTION.KNOWLEDGE_BASE:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.knowledgeBase.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.knowledgeBase.tooltip');
        }
      case NAVIGATION_SECTION.REPORTS:
        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.reports.disabledTooltip');
        } else {
          return this.transloco.translate('navigationComponent.reports.tooltip');
        }
      case NAVIGATION_SECTION.SETTINGS:
        if (this.isOperator) {
          return this.transloco.translate('navigationComponent.settings.savedRepliesTooltip');
        }

        if (isNeedToShowDisabledTooltip) {
          return this.transloco.translate('navigationComponent.settings.disabledTooltip');
        }

        return this.transloco.translate('navigationComponent.settings.tooltip');
      default:
        return '';
    }
  }

  /**
   * Проверяет, заблокирован ли пункт навигации
   *
   * @param section Раздел навигации
   */
  isNavigationItemDisabled(section: NAVIGATION_SECTION): boolean {
    const isNotDisabledSectionForMarketerCase = [
      NAVIGATION_SECTION.CHAT_BOTS,
      NAVIGATION_SECTION.INTEGRATIONS,
      NAVIGATION_SECTION.SETTINGS,
      NAVIGATION_SECTION.SUBSCRIPTION,
    ].includes(section);
    const hasBlockingForMarketerCase = this.blockingService.hasBlockingForMarketerCase(
      this.currentApp.activation,
      this.djangoUserTempData,
    );

    if (hasBlockingForMarketerCase) {
      if (isNotDisabledSectionForMarketerCase) {
        return false;
      } else {
        return true;
      }
    }

    return NAVIGATIONS_STATES[section].every((navigationSection) =>
      this.blockPanelService.isStateBlocked(navigationSection, this.currentApp, this.djangoUser),
    );
  }

  /**
   * Получение списка состояний для секции
   *
   * @param section
   */
  getStatesForSection(section: NAVIGATION_SECTION): string[] {
    return NAVIGATIONS_STATES[section];
  }

  /**
   * Показывать или нет лейбл New на иконке интеграций
   *
   */
  isShowNewFeatureLabelOnIntegrations(): boolean {
    // показывается всем, кроме операторов и тогда, когда есть хотя бы одна новая интеграция
    let key: keyof typeof INTEGRATION_TYPES;
    for (key in INTEGRATION_TYPES) {
      if (this.featureLabelModel.isNew(INTEGRATION_TYPES[key])) {
        return true;
      }
    }

    return false;
  }
}
