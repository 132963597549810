import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '@panel/app/shared/shared.module';

import { CheckJinjaModalComponent } from './check-jinja-modal.component';

@NgModule({
  declarations: [CheckJinjaModalComponent],
  exports: [CheckJinjaModalComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule, ReactiveFormsModule, NgbAlert, SharedModule],
})
export class CheckJinjaModalModule {}
