import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';

import { EditorFormatHeaderComponent } from './editor-format-header.component';

@NgModule({
  declarations: [EditorFormatHeaderComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    NgbDropdown,
    NgbDropdownToggle,
    NgbTooltip,
    NgbDropdownMenu,
    NgbDropdownItem,
  ],
  exports: [EditorFormatHeaderComponent],
})
export class EditorFormatHeaderModule {}
