import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { VideoViewModalService } from '@panel/app/services/video-view/video-view-modal.service';

import { StarterGuideHeaderComponent } from './starter-guide-header.component';

@NgModule({
  declarations: [StarterGuideHeaderComponent],
  exports: [StarterGuideHeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
  providers: [CarrotquestHelperProvider, VideoViewModalService],
})
export class StarterGuideHeaderModule {}
