(function () {
  'use strict';

  require('./user-card-wrapper.controller');

  angular.module('myApp.userCard').component('cqUserCardWrapper', cqUserCardWrapper());

  function cqUserCardWrapper() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
        onRedirectConversationClick: '<?', // нужно ли после нажатия на кнопку нужно перенаправить в раздел диалоги
        telegramIntegrations: '=',
        emailDomains: '=', // список емейл доменов
      },
      controller: 'CqUserCardWrapperController',
      controllerAs: 'vm',
      template: require('./user-card-wrapper.html'),
    };
  }
})();
