<ng-container *transloco="let t; read: 'messageEditorSendingRepeatComponent'">
  <cq-message-editor-switch
    [heading]="t('heading')"
    [switched]="isEnabled"
    (switchedChange)="isEnabled = $event; isEnabledChange.next($event)"
  >
    <div class="mb-15 text-secondary">
      {{ t('description', { entityName: entityName }) }}
    </div>
    <cq-time-selector
      class="mb-15"
      [formControl]="form.controls.timeConfig"
      [minSeconds]="REPEAT_MIN_SECONDS_DELAY"
      [selectableUnits]="selectableUnits"
      [maxSeconds]="REPEAT_MAX_DAYS_DELAY * 60 * 60 * 24"
      [maxSecondsError]="t('input.errors.max', { maxValue: REPEAT_MAX_DAYS_DELAY })"
      [required]="true"
    ></cq-time-selector>
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        id="not-send-replied"
        type="checkbox"
        [formControl]="form.controls.notSendReplied"
      />
      <label
        class="custom-control-label"
        for="not-send-replied"
      >
        {{ t(translateStrForLabel(isAbTest, hasPopupType)) }}
      </label>
    </div>
  </cq-message-editor-switch>
</ng-container>
