import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { EditorComponent } from '@panel/app/partials/editor/editor.component';
import { EditorFormatBoldModule } from '@panel/app/partials/editor/format/bold/editor-format-bold.module';
import { EditorFormatButtonModule } from '@panel/app/partials/editor/format/button/editor-format-button.module';
import { EditorFormatFileModule } from '@panel/app/partials/editor/format/file/editor-format-file.module';
import { EditorFormatHeaderModule } from '@panel/app/partials/editor/format/header/editor-format-header.module';
import { EditorFormatLinkModule } from '@panel/app/partials/editor/format/link/editor-format-link.module';
import { EditorFormatUserPropertyModule } from '@panel/app/partials/editor/format/user-property/editor-format-user-property.module';
import { EditorFormatSizeModule } from '@panel/app/partials/editor/format/size/editor-format-size.module';

@NgModule({
  declarations: [EditorComponent],
  exports: [EditorComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    ReactiveFormsModule,
    EditorFormatUserPropertyModule,
    EditorFormatFileModule,
    EditorFormatBoldModule,
    EditorFormatLinkModule,
    EditorFormatButtonModule,
    EditorFormatSizeModule,
    EditorFormatHeaderModule,
  ],
})
export class EditorModule {}
