import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TriggerChainStep } from '@panel/app/http/trigger-chain/internal-types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';

@Component({
  selector: 'cq-trigger-chain-step-editor',
  templateUrl: './trigger-chain-step-editor.component.html',
  styleUrls: ['./trigger-chain-step-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TriggerChainStepEditorInteractionsService],
})
export class TriggerChainStepEditorComponent {
  @Input()
  isValidationStrict: boolean = false;

  @Input({ required: true })
  step!: TriggerChainStep;

  stepChangeSubj: Subject<TriggerChainStep> = new Subject<TriggerChainStep>();

  /**
   * Output сделан через отдельный subject и observable от него, чтоб можно было сделать debounce
   * (ну и какие-то другие штуки для всех редакторов, если понадобится)
   *
   * FIXME Тут нельзя использовать debounce/throttle, хз почему, надо разбираться
   */
  @Output()
  readonly stepChange: Observable<TriggerChainStep> = this.stepChangeSubj.asObservable();

  @Output()
  closeEditorClick: Observable<void> = this.editorInteractions.closeClick.asObservable();

  @Output()
  copyClick: Observable<TriggerChainStep> = this.editorInteractions.copyClick.asObservable();

  @Output()
  deleteClick: Observable<TriggerChainStep> = this.editorInteractions.deleteClick.asObservable();

  constructor(
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {}
}
