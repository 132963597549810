import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiFor } from '@taiga-ui/cdk';

import { ConversationChannelModule } from '@panel/app/pages/conversations/conversation-channel/conversation-channel.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';

import { ChannelAssignmentComponent } from './channel-assignment.component';

@NgModule({
  declarations: [ChannelAssignmentComponent],
  imports: [
    CommonModule,
    ConversationChannelModule,
    DirectivesModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    TranslocoModule,
    TuiFor,
  ],
  exports: [ChannelAssignmentComponent],
})
export class ChannelAssignmentModule {}
