//TODO Отрефакторить список цепочек
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';
import { TriggerChainListItem } from '@panel/app/http/trigger-chain/internal-types/trigger-chain.internal.type';

import { TriggerChainListState } from './trigger-chain-list-state.service';

/**
 * Компонент для работы со списком триггерных цепочек
 */
@Component({
  selector: 'cq-trigger-chain-list',
  templateUrl: './trigger-chain-list.component.html',
  styleUrls: ['./trigger-chain-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TriggerChainListState],
})
export class TriggerChainListComponent implements OnInit {
  /** Приложение */
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  chainList!: TriggerChainListItem[];

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  tags!: UserTag[];

  @Input()
  activeTriggerMessagesCount!: number;

  constructor(private readonly triggerChainListState: TriggerChainListState) {}

  ngOnInit() {
    this.triggerChainListState.activeTriggerMessagesCount$.next(this.activeTriggerMessagesCount);
    this.triggerChainListState.currentApp$.next(this.app);
    this.triggerChainListState.chainList$.next(this.chainList);
    this.triggerChainListState.properties$.next(this.properties);
    this.triggerChainListState.tags$.next(this.tags);
  }
}
