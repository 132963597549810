import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { MessageFiltersModule } from '@panel/app/partials/message-editor/message-filters/message-filters.module';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ScheduledAutoMessageTableComponent } from './scheduled-auto-message-table.component';

@NgModule({
  declarations: [ScheduledAutoMessageTableComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
    MomentModule,
    UIRouterModule,
    NgbTooltip,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    SharedModule,
    NgbPopover,
    MessageFiltersModule,
    UserTagModule,
  ],
  exports: [ScheduledAutoMessageTableComponent],
})
export class ScheduledAutoMessageTableModule {}
