/**
 * Сервис для работы со статистикой сообщений
 * !!! Этот сервис создан исключительно для того, чтобы не дублировать код, т.к. статистика для сообщений, тест-групп и вариантов сообщения получается и собирается абсолютно одинакого. Когда-нибудь надо будет придумать другое решение, а не этот сервис
 * !!! Оставлять эти функции в messageModel нельзя, иначе возникает круговые зависимости (например messageModel -> messagePartModel -> messageModel)
 */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { SENDING_TYPES } from '@http/message/message.constants';
import { PaginationParamsRequest } from '@http/types';
import { UserModel } from '@http/user/user.model';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

import { MESSAGE_STATISTICS } from './message-statistics.constants';

@Injectable({ providedIn: 'root' })
export class MessageStatisticsModel {
  constructor(
    private readonly http: HttpClient,
    private readonly userModel: UserModel,
    private readonly utilsService: UtilsService,
  ) {}

  /**
   * Получение агрегированных получателей для контрольной группы
   * Необходимо использовать в случае получения получателей для контрольной группы по всему сообщению в целом, т.к. в сообщении может быть несколько тест-групп, а соответственно и несколько контрольных групп
   *
   * @param messageId ID сообщения
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param sendingType Тип действия со стороны пользователя
   * @param lastSendingId ID последего получателя (используется для пагинации)
   */
  getAggregatedControlGroupSendings(
    messageId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sendingType: SENDING_TYPES,
    lastSendingId?: string,
  ) {
    if (messageId === undefined) {
      throw Error('messageId must be specified');
    }

    if (startDate === undefined) {
      throw Error('startDate must be specified');
    }

    if (endDate === undefined) {
      throw Error('endDate must be specified');
    }

    if (sendingType === undefined) {
      throw Error('sendingType must be specified');
    }

    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    const params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      filter: sendingType,
      after: lastSendingId!,
    };

    return this.http.get('/messages/' + messageId + '/controlgroupsendings', {
      params,
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Получение агрегационной статистики для контрольной группы
   * Необходимо использовать в случае получения статистики по всему сообщению в целом, т.к. в сообщении может быть несколько тест-групп, а соответственно и несколько контрольных групп
   *
   * @param messageId ID сообщения
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param rangeOverride Переопределение группировки
   */
  getAggregatedControlGroupStatistics(
    messageId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    rangeOverride?: typeof MESSAGE_STATISTICS.RANGES[keyof typeof MESSAGE_STATISTICS.RANGES],
  ) {
    let range: any;

    if (messageId === undefined) {
      throw Error('messageId must be specified');
    }

    if (startDate === undefined) {
      throw Error('startDate must be specified');
    }

    if (endDate === undefined) {
      throw Error('endDate must be specified');
    }

    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    // если rangeOverride не задан - группировка определяется автоматически
    if (rangeOverride !== undefined) {
      range = rangeOverride;
    } else {
      // Разницы датой начала и датой конца в днях и месяцах
      const diffInDays = endDate.diff(startDate, 'days');
      const diffInMonths = endDate.diff(startDate, 'months');
      if (diffInDays < 1) {
        range = MESSAGE_STATISTICS.RANGES.HOUR;
      } else if (diffInMonths < 1) {
        range = MESSAGE_STATISTICS.RANGES.DAY;
      } else {
        range = MESSAGE_STATISTICS.RANGES.WEEK;
      }
    }

    const params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      group_range: range,
    };

    return this.http.get('/messages/' + messageId + '/controlgroupstats', { params }).pipe(
      map((data) => {
        this.parseStatistics(data, range, endDate);
        return data;
      }),
    );
  }

  /**
   * Получение получателей для сообщения/тест-группы/варианта сообщения
   *
   * @param entity Сущность, для которой получаются получатели
   * @param entityId ID сущности
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param sendingType Тип действия со стороны пользователя
   * @param paginatorParams Параметры пагинации
   * @param excludeControlGroup Флаг исключения получателей контрольной группы из выборки получателей
   */
  getSendings(
    entity: string,
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sendingType: SENDING_TYPES,
    paginatorParams?: PaginationParamsRequest,
    excludeControlGroup: any = undefined,
  ) {
    if (entityId === undefined) {
      throw Error('entityId must be specified');
    }

    if (startDate === undefined) {
      throw Error('startDate must be specified');
    }

    if (endDate === undefined) {
      throw Error('endDate must be specified');
    }

    if (sendingType === undefined) {
      throw Error('sendingType must be specified');
    }

    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    excludeControlGroup = angular.isDefined(excludeControlGroup) ? excludeControlGroup : false;

    const params: any = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      filter: sendingType,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: (paginatePosition ?? []).join() || null,
      paginate_page_order: paginatePageOrder,
      exclude_control_group: excludeControlGroup,
    };

    return this.http
      .get<any>('/' + entity + '/' + entityId + '/sendings', {
        params,
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, ['props'])
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response) => {
          const sendings = response.data;

          for (let i = 0; i < sendings.length; i++) {
            let sending = sendings[i];
            const sendingId = sending.id;
            const user = sending.user;

            const interactions = angular.copy(sending);

            if (interactions.removed) {
              interactions.removed = moment(interactions.removed * 1000);
            }

            interactions.sended = moment(interactions.sended, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

            delete interactions.user;
            delete interactions.id;

            this.userModel.parseUser(user).subscribe();

            sending = {};
            sending.id = sendingId;
            sending.interactions = interactions;
            sending.user = user;

            sendings[i] = sending;
          }

          return {
            sendings: sendings,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  /**
   * Получение статистики для сообщения/тест-группы/варианта сообщения
   *
   * @param entity Сущность, для которой получается статистика
   * @param entityId ID сущности
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param excludeControlGroup=true Флаг исключения статистики контрольной группы из получения статистики
   * @param rangeOverride Переопределение группировки
   */
  getStatistics(
    entity: string,
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    excludeControlGroup = true,
    rangeOverride: typeof MESSAGE_STATISTICS.RANGES[keyof typeof MESSAGE_STATISTICS.RANGES],
  ) {
    let range: any;

    if (entityId === undefined) {
      throw Error('entityId must be specified');
    }

    if (startDate === undefined) {
      throw Error('startDate must be specified');
    }

    if (endDate === undefined) {
      throw Error('endDate must be specified');
    }

    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    excludeControlGroup = excludeControlGroup !== undefined ? excludeControlGroup : true;

    // если rangeOverride не задан - группировка определяется автоматически
    if (rangeOverride !== undefined) {
      range = rangeOverride;
    } else {
      // Разницы датой начала и датой конца в днях и месяцах
      const diffInDays = endDate.diff(startDate, 'days');
      const diffInMonths = endDate.diff(startDate, 'months');
      if (diffInDays < 1) {
        range = MESSAGE_STATISTICS.RANGES.HOUR;
      } else if (diffInMonths < 1) {
        range = MESSAGE_STATISTICS.RANGES.DAY;
      } else {
        range = MESSAGE_STATISTICS.RANGES.WEEK;
      }
    }

    const params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      exclude_control_group: excludeControlGroup,
      group_range: range,
    };

    return this.http.get<any>('/' + entity + '/' + entityId + '/stats', { params }).pipe(
      map((statistics) => {
        const statisticsKeys = Object.keys(statistics);

        // если ключами статистики являются числа, то эти ключи - id вариантов сообщения, и пришла статистика по каждому из вариантов
        // такое может быть, если запрошена статистика по всем вариантам в тест-группе
        if (this.utilsService.isStringNumber(statisticsKeys[0])) {
          for (let i = 0; i < statisticsKeys.length; i++) {
            this.parseStatistics(statistics[statisticsKeys[i]], range, endDate);
          }
        } else {
          this.parseStatistics(statistics, range, endDate);
        }

        return statistics;
      }),
    );
  }

  /**
   * Парсинг статистики сообщений
   *
   * @param statistics Объект статистики
   * @param range Текущая группировка статистики
   * @param endDate Дата конца периода (необходима в случае недельной группировки, чтобы выставить последнюю дату последней недели)
   */
  private parseStatistics(
    statistics: any,
    range: typeof MESSAGE_STATISTICS.RANGES[keyof typeof MESSAGE_STATISTICS.RANGES],
    endDate: moment.Moment,
  ) {
    for (let statisticName in statistics) {
      statistics[statisticName] = this.parseStatisticsEntity(statistics[statisticName], range, endDate);
    }
  }

  /**
   * Парсинг сущности статистики на объект с лейблами и данными для графика, а так же с суммой сущности за выбранный период
   *
   * @param entityData Объект сущности (ключ - дата, значение - кол-во выполнения сущности в эту дату)
   * @param range Группировка
   * @param endDate Конец периода, необходим в случае, когда range == MESSAGE_STATISTICS.RANGES.WEEK
   */
  private parseStatisticsEntity(
    entityData: any,
    range: typeof MESSAGE_STATISTICS.RANGES[keyof typeof MESSAGE_STATISTICS.RANGES],
    endDate: moment.Moment,
  ) {
    const timeArray = Object.keys(entityData).sort();
    let startTime, endTime, currentTime;
    let innerFormat, outerFormat;
    let labelsArray = [],
      dataArray = [],
      sum = 0;

    switch (range) {
      case MESSAGE_STATISTICS.RANGES.HOUR: {
        innerFormat = 'YYYY-MM-DD HH';
        outerFormat = 'LT';

        for (let i = 0; i < timeArray.length; i++) {
          currentTime = moment(timeArray[i], innerFormat);
          labelsArray[i] = currentTime.format(outerFormat);
          dataArray[i] = entityData[timeArray[i]];
        }
        break;
      }
      case MESSAGE_STATISTICS.RANGES.DAY: {
        innerFormat = 'YYYY-MM-DD';
        outerFormat = 'L';

        for (let i = 0; i < timeArray.length; i++) {
          currentTime = moment(timeArray[i], innerFormat);
          labelsArray[i] = currentTime.format(outerFormat);
          dataArray[i] = entityData[timeArray[i]];
        }
        break;
      }
      case MESSAGE_STATISTICS.RANGES.WEEK: {
        innerFormat = 'YYYY-MM-DD';
        outerFormat = 'L';

        for (let i = 0; i < timeArray.length; i++) {
          startTime = moment(timeArray[i], innerFormat);
          if (timeArray[i + 1]) {
            endTime = moment(timeArray[i + 1], innerFormat).subtract(1, 'days');
          } else {
            endTime = endDate;
          }
          labelsArray[i] = startTime.format(outerFormat) + ' - ' + endTime.format(outerFormat);
          dataArray[i] = entityData[timeArray[i]];
        }
        break;
      }
    }

    sum = dataArray.reduce(function (sum, currentValue) {
      return (sum += currentValue);
    });

    return {
      data: dataArray,
      labels: labelsArray,
      sum: sum,
    };
  }
}
