import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { generate } from 'short-uuid';

import { OpenedWebPageTriggerType } from "@http/message/trigger.types";
import { UtilsModel } from '@http/utils/utils.model';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { TupleUnion } from '@panel/app/shared/types/tuple-union.type';
import { getAsyncUrlValidatorFn } from '@panel/app/shared/validators/async/async-validator';

type Comparison = OpenedWebPageTriggerType['comparison'];

export type MessageEditorOpenedWebPageTriggerTypesForm = OpenedWebPageTriggerType;

@Component({
  selector: 'cq-message-editor-opened-web-page-trigger-type',
  templateUrl: './message-editor-opened-web-page-trigger-type.component.html',
  styleUrls: ['./message-editor-opened-web-page-trigger-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorOpenedWebPageTriggerTypeComponent extends AbsCVAFormGroupBasedComponent<
  ToFormGroupControls<MessageEditorOpenedWebPageTriggerTypesForm>
> {
  @Input()
  isOnlyEvent: boolean = false;

  protected readonly URL_MAX_LENGTH = 255;

  protected readonly URL_MIN_LENGTH = 3;

  readonly control = this.fb.group<ToFormGroupControls<MessageEditorOpenedWebPageTriggerTypesForm>>({
    // этот добавлен в форму, чтоб не заморачиваться его удалением из объекта перед передачей в компонент, можно потом будет подумать над решением
    localId: this.fb.control(generate(), { nonNullable: true }),
    comparison: this.fb.control<Comparison>('eq', { nonNullable: true }),
    url: this.fb.control<string>('', {
      nonNullable: true,
      validators: [
        Validators.maxLength(this.URL_MAX_LENGTH),
        Validators.minLength(this.URL_MIN_LENGTH),
        Validators.required,
      ],
      asyncValidators: this.getValidatorsByFilterType('eq'),
    }),
  });

  get comparison() {
    return this.control.controls.comparison;
  }

  get url() {
    return this.control.controls.url;
  }

  @Output()
  deleteTriggerType: Subject<void> = new Subject();

  protected readonly COMPARISON_OPTIONS: TupleUnion<Comparison> = ['eq', 'neq', 'contains', 'not_contains'];

  constructor(private readonly fb: FormBuilder, private readonly utilsService: UtilsModel) {
    super();
  }

  protected getValidatorsByFilterType(comparison: Comparison): AsyncValidatorFn[] {
    switch (comparison) {
      case 'contains':
      case 'not_contains':
        return [];
      case 'eq':
      case 'neq':
        return [getAsyncUrlValidatorFn(this.utilsService)];
    }
  }

  protected updateValidatorsByComparison(comparison: Comparison) {
    this.control.controls.url.clearAsyncValidators();
    const asyncValidators = this.getValidatorsByFilterType(comparison);
    if (asyncValidators.length > 0) {
      this.control.controls.url.setAsyncValidators(asyncValidators);
    }
    this.control.controls.url.updateValueAndValidity();
  }

  /**
   * При изменении данных снаружи надо обновить валидаторы
   */
  writeValue(val: MessageEditorOpenedWebPageTriggerTypesForm) {
    super.writeValue(val);
    this.updateValidatorsByComparison(val.comparison);
  }
}
