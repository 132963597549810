import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { map as mapLodash } from 'lodash';
import { map } from 'rxjs';

import {
  PSEUDO_TEAM_MEMBERS_GROUPS,
  PSEUDO_TEAM_MEMBERS_GROUPS_IDS,
} from '@http/team-member-group/team-member-group.constants';
import {
  EXTENDED_RESPONSE,
  HIDE_TOAST_ERROR,
  IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS,
} from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class TeamMemberGroupModel {
  DEFAULT_TEAM_MEMBER_GROUP = {
    app: null, // id приложения, в котором находится эта группа
    id: null, // id группы
    isSystem: true, // флаг системного события
    name: '', // название группы
  };

  constructor(private readonly httpClient: HttpClient, private readonly translocoService: TranslocoService) {}

  /**
   * Создание группы членов команды
   *
   * @param {String} name Название группы
   * @param {Object[]} [teamMembers] Список членов команды, которые добавляются в эту группу
   * @return {Promise}
   */
  create(name: any, teamMembers: any) {
    var teamMembersIds = mapLodash(teamMembers, 'id');
    var params = {
      name: name,
      admins: teamMembersIds,
    };

    return this.httpClient
      .post('/admingroups', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(HIDE_TOAST_ERROR, true),
      })
      .pipe(
        map((response: any) => {
          return this.get(response.data.id);
        }),
      );
  }

  /**
   * Получение группы членов команды по ID
   *
   * @param {String} teamMemberGroupId ID группы
   * @return {Promise}
   */
  get(teamMemberGroupId: any) {
    return this.httpClient
      .get('/admingroups/' + teamMemberGroupId, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          var teamMembersGroup = response.data;

          this.parse(teamMembersGroup);

          return teamMembersGroup;
        }),
      );
  }

  /**
   * Получение всех групп членов команды
   *
   * @param {String} appId ID приложения
   * @param {Boolean} includeWithoutGroup Флаг добавления псевдогруппы 'Без группы' в список
   * @param {Boolean} includeAllGroups Флаг добавления псевдогруппы 'Все группы' в список
   * @return {Object}
   */
  getList(appId: any, includeWithoutGroup: any, includeAllGroups: any) {
    return this.httpClient
      .get('/apps/' + appId + '/admingroups', {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          var teamMembersGroups = response.data;

          for (var i = 0; i < teamMembersGroups.length; i++) {
            this.parse(teamMembersGroups[i]);
          }

          if (includeWithoutGroup) {
            teamMembersGroups.push(this.getPseudoTeamMembersGroup(PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP));
          }

          if (includeAllGroups) {
            teamMembersGroups.push(this.getPseudoTeamMembersGroup(PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS));
          }

          return teamMembersGroups;
        }),
      );
  }

  /**
   * Получение псевдогрупыы
   *
   * @param {PSEUDO_TEAM_MEMBERS_GROUPS} groupType Тип псевдогруппы
   * @return {Object}
   */
  getPseudoTeamMembersGroup(groupType: any) {
    var pseudoTeamMembersGroup: any = angular.copy(this.DEFAULT_TEAM_MEMBER_GROUP);

    pseudoTeamMembersGroup.id = PSEUDO_TEAM_MEMBERS_GROUPS_IDS[groupType];
    pseudoTeamMembersGroup.name = this.translocoService.translate('models.teamMemberGroup.systemGroups.' + groupType);

    return pseudoTeamMembersGroup;
  }

  /**
   * Парсинг группы членов команды
   *
   * @param {Object} group Группа
   */
  parse(group: any) {
    if (/^\$/.test(group.name)) {
      group.isSystem = true;
      group.name = this.translocoService.translate('models.teamMemberGroup.systemGroups.' + group.name);
    } else {
      group.isSystem = false;
    }
  }

  /**
   * Удаление группы членов команды по её ID
   *
   * @param {Object} teamMembersGroupId - ID группы членов команды
   * @return {Promise}
   */
  remove(teamMembersGroupId: any) {
    return this.httpClient.delete('/admingroups/' + teamMembersGroupId, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Сохранение данных о группе членов команды
   *
   * @param {Object} teamMembersGroup Группа
   * @param {Object[]} [teamMembers] Список членов команды, которые добавляются в эту группу
   * @return {Promise}
   */
  save(teamMembersGroup: any, teamMembers?: any[]) {
    const teamMembersGroupId = teamMembersGroup.id;
    const teamMembersIds = teamMembers ? mapLodash(teamMembers, 'id') : teamMembers;
    const params = {
      name: teamMembersGroup.name,
      admins: teamMembersIds,
    };

    return this.httpClient
      .patch('/admingroups/' + teamMembersGroupId, params, {
        context: new HttpContext().set(HIDE_TOAST_ERROR, true),
      })
      .pipe(
        map((response: any) => {
          return this.get(teamMembersGroupId);
        }),
      );
  }

  getAllGroups() {
    return this.getPseudoTeamMembersGroup(PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS);
  }

  getWithoutGroup() {
    return this.getPseudoTeamMembersGroup(PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP);
  }
}
