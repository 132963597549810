import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Quill, { Range } from 'quill';
import Link from 'quill/formats/link';
import Delta from 'quill-delta';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, skipWhile, takeUntil } from 'rxjs/operators';

import { UserProperty } from '@http/property/property.model';
import {
  LinkEditorModalComponent,
  MODAL_ACTIONS_TYPE,
  ReturnedLinkEditorModalParams,
} from '@panel/app/pages/chat-bot/content/modals/link-editor/link-editor-modal.component';
import { InsertPropsIntoTextModalComponent } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.component';
import { InsertPropsIntoTextModalData } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.token';
import { QuillTextEditorService } from '@panel/app/partials/quill-text-editor/quill-text-editor.service';
import { DestroyService } from '@panel/app/services';

export type QuillEditorFormat = 'bold' | 'italic' | 'strike' | 'underline' | 'link' | 'list' | 'userProp';

@Component({
  selector: 'cq-quill-text-editor[formControlName],cq-quill-text-editor[formControl],cq-quill-text-editor[ngModel]',
  templateUrl: './quill-text-editor.component.html',
  styleUrls: ['./quill-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    QuillTextEditorService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillTextEditorComponent),
      multi: true,
    },
  ],
})
export class QuillTextEditorComponent implements OnInit, AfterViewInit {
  /**
   * Разрешенные форматы
   */
  @Input()
  set formats(formats: QuillEditorFormat[]) {
    this._formats = formats;
    this.setFormatButtonAvailableMap();
  }
  get formats() {
    return this._formats;
  }
  private _formats: QuillEditorFormat[] = [];

  @Input()
  placeholder: string = this.translocoService.translate('quillTexEditorComponent.quillEditor.placeholder');

  @Input()
  userProps: UserProperty[] = [];

  @ViewChild('editor')
  editorRef!: ElementRef<HTMLElement>;

  /** Событие blur редактора quill */
  blur$ = new Subject<any>();
  /** Флаг открытия модалки */
  modalEditorIsOpen: boolean = false;
  /** Экземпляр quill редактора */
  quill!: Quill;
  /** Элемент редактора */
  protected quillComponentRef: ElementRef | null = null;
  /** Доступные кнопки для форматов */
  formatButtonAvailableMap: Record<QuillEditorFormat, boolean> = {
    bold: false,
    italic: false,
    strike: false,
    underline: false,
    link: false,
    list: false,
    userProp: false,
  };

  formControl = new FormControl();

  private readonly clickOnEditor$: BehaviorSubject<HTMLElement | null> = new BehaviorSubject<HTMLElement | null>(null);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    protected readonly ngbModal: NgbModal,
    protected readonly translocoService: TranslocoService,
    private readonly elementRef: ElementRef,
    private readonly quillTextEditorService: QuillTextEditorService,
  ) {}

  /**
   * Добавить форматирование
   * @param name Имя форматирования
   * @param value Значене для формата
   */
  format(name: string, value: boolean | string = true) {
    const valueToFormat = this.quill!.getFormat()[name] === value ? false : value;
    this.quill!.format(name, valueToFormat, 'user');
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => this.onChange(value));

    this.clickOnEditor$.pipe(takeUntil(this.destroy$)).subscribe((target) => {
      if (target?.closest('.badge') || (target && target.tagName === 'SPAN' && target.classList.contains('badge'))) {
        const defaultValue = target.dataset.defaultValue;
        const propName = target.dataset.propName;

        this.setUserProp(defaultValue, propName);
      }
    });
  }

  ngAfterViewInit() {
    this.initEditor();
  }

  private initEditor(): void {
    this.quillComponentRef = this.elementRef;

    this.quill = this.quillTextEditorService.init({
      selector: this.editorRef.nativeElement,
      placeholder: this.placeholder,
      value: this.formControl.value,
      formats: this.formats,
    });

    this.initQuillSubscriptions();
  }

  /**
   * Добавление ссылок
   */
  setLink(): void {
    let selectionRange = this.quill.getSelection();

    if (selectionRange!.length === 0) {
      selectionRange = this.getLinkRange(selectionRange!);
    }

    const text = this.quill.getText(selectionRange!.index, selectionRange!.length);
    const url: any = this.quill.getFormat().link ?? null;
    this.modalEditorIsOpen = true;
    this.openLinkEditorModal(text, url)
      .then((link) => {
        if (link.action === MODAL_ACTIONS_TYPE.EDIT) {
          const formats = this.quill.getFormat(selectionRange!);
          const delta = new Delta()
            .retain(selectionRange!.index)
            .delete(selectionRange!.length)
            .insert(link.text!, { ...formats, link: link.url });
          this.quill.updateContents(delta, 'user');
        } else {
          this.quill.formatText(selectionRange!, 'link', false, 'user');
        }
      })
      .catch(() => {})
      .finally(() => {
        this.modalEditorIsOpen = false;
        // Курсор сбрасывается поэтмоу возвращаю его на конец ссылки
        this.quill.setSelection(selectionRange!.index + selectionRange!.length, 0);
      });
  }

  protected setUserProp(defaultValue: string | null = null, propName: string | null = null) {
    const modal = this.ngbModal.open(InsertPropsIntoTextModalComponent);
    const userProps = this.userProps;
    const format: any = this.quill.getFormat().userProp || null;
    const selectionRange = this.quill.getSelection(true);
    let selectText = this.quill.getText(selectionRange.index, selectionRange.length);

    if (format) {
      selectText = format.defaultValue;
      propName = format.propName;
    }

    this.modalEditorIsOpen = true;

    (modal.componentInstance.modalWindowParams as InsertPropsIntoTextModalData) = {
      userProps: userProps,
      defaultValue: defaultValue || selectText,
      propName: propName,
    };

    modal.result
      .then((data) => {
        this.quillTextEditorService.insertUserProperty(this.quill, selectionRange, data);
      })
      .catch(() => {})
      .finally(() => {
        this.modalEditorIsOpen = false;
      });
  }

  /**
   * Открытие модалки редактирования ссылок
   * @param text Текст ссылки
   * @param url Адрес ссылки
   * @private
   */
  private openLinkEditorModal(text: string, url: string | null): Promise<ReturnedLinkEditorModalParams> {
    const createNewPropertyModal = this.ngbModal.open(LinkEditorModalComponent, {
      //Пришлось извернуться через кастомные классы, чтобы поднять модалку в отправке ручных автосообщений
      backdropClass: 'link-editor-nodal-backdrop',
      windowClass: 'link-editor-nodal-backdrop',
    });

    createNewPropertyModal.componentInstance.text = text;
    createNewPropertyModal.componentInstance.url = url;

    return createNewPropertyModal.result;
  }

  /**
   * Получить Range для ссылки по selection
   * @param range - Текущее положение курсора на ссылке
   * @private
   */
  private getLinkRange(range: Range): Range {
    //@ts-ignore
    const [link, offset] = this.quill!.scroll.scroll.descendant<Link>(Link, range.index);

    return {
      index: link ? range.index - offset : range.index,
      length: link ? link.length() : 0,
    };
  }

  protected onClickEditor(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.clickOnEditor$.next(event.target as HTMLElement);
  }

  /**
   * Инициализация слушателей для Quill
   */
  initQuillSubscriptions(): void {
    this.quillTextEditorService.changeFormatting$
      .pipe(
        takeUntil(this.destroy$),
        // @ts-ignore квилл кусок мусора, не умеет в типы, эта штука рабочая
        map((event) => event[0]),
      )
      .subscribe((range: Range) => {
        //Т.к. работа с формой идет не через HTML надо руками выставить touched, чтобы валидация начала отрабатывать
        if (!this.formControl.touched && !range) {
          this.formControl.markAsTouched();
          this.onTouched();
        }
        this.changeDetectorRef.detectChanges();
      });

    this.quillTextEditorService.changeContent$
      .pipe(
        takeUntil(this.destroy$),
        map((value) => (value ? value.replace(/<br>/gim, '<br/>') : '')),
        map((value) => value.replace(/<p>(\s+)<\/p>/gim, '')),
        skipWhile((value) => {
          return value === this.formControl.value;
        }),
      )
      .subscribe((value) => {
        this.formControl.setValue(value);
      });
  }

  /**
   * Определяет класс для кнопки в зависимости от того есть ли в месте курсора нужное форматирование
   * @param name Имя форматирования
   * @param value Значение для формата
   */
  getQuillButtonClass(name: string, value: boolean | string = true): string {
    if (
      this.quill &&
      this.quill.hasFocus() &&
      (this.quill.getFormat()[name] === value ||
        (name === 'link' && this.quill.getFormat()[name]) ||
        (name === 'userProp' && this.quill.getFormat()[name]))
    ) {
      return 'active';
    }
    return '';
  }

  /**
   * Установка доступности кнопок форматирования
   */
  setFormatButtonAvailableMap() {
    for (let key in this.formatButtonAvailableMap) {
      //@ts-ignore
      this.formatButtonAvailableMap[key] = false;
    }
    for (let format of this.formats) {
      this.formatButtonAvailableMap[format] = true;
    }
  }

  /** ControlValueAccessor's methods implementation */

  registerOnChange(fn: (v: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  private onChange: (v: string) => void = (v: string) => {};

  private onTouched: () => void = () => {};
}
