import { DOCS_EXTENSION, IMAGE_EXTENSION } from '../../shared/services/file-helper/file-helper.constants';
import {
  PLAN_FEATURE,
  PLAN_FEATURE_BY_MESSAGE_PART_TYPE,
} from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { UserColorsCalculationService } from '../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { MESSAGE_TYPES } from '../../../app/http/message/message.constants';
import {
  UTM_MARKS_MAX_LENGTH,
  UTM_MARKS_SYSTEM_VALUES_ARRAY,
  UTM_MARKS_TYPES_ARRAY,
} from '../../../app/services/utm-mark/utm-mark.constants';
import { firstValueFrom } from 'rxjs';
import {
  EMAIL_TYPES,
  HTML_MAX_SIZE,
  HTML_RECOMMENDED_MAX_SIZE,
  MESSAGE_PART_SENDER_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  POPUP_REPLY_TYPES,
  STORAGE_KEYS,
  TYPE_OS_PREVIEW,
} from '../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.messagePartEditor').controller('CqMessagePartEditorController', CqMessagePartEditorController);

  function CqMessagePartEditorController(
    $filter,
    $q,
    $rootScope,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    ipCookie,
    l10nHelper,
    toastr,
    API_ENDPOINT,
    CDN_ENDPOINT,
    FROALA_EDITOR_KEY,
    LANGUAGE,
    PROJECT_NAME,
    carrotquestHelper,
    caseStyleHelper,
    djangoUserModel,
    featureModel,
    fileHelper,
    froalaHelper,
    messagePartModel,
    messageSenderModel,
    paywallService,
    planFeatureAccessService,
    propertyModel,
    pushSettingsModel,
    templateDirectoryModel,
    utmMarkModel,
    validationHelper,
  ) {
    let vm = this;

    let messagePartEditorErrorHandler = null;
    const PUSH_BODY_MAX_LENGTH = 500;
    const PUSH_SUBJECT_MAX_LENGTH = 100;
    const EMAIL_SUBJECT_MAX_LENGTH = 255;

    /**
     * Класс custom скролла который нужно намерено увелчичить по высоте (читать подробнее в fixErrorMessagesWithScroll)
     * @type {string}
     */
    var SCROLL_CLASS_NAME = 'scroll-container';

    var trackingPageName = $state.includes('app.content.messagesAjs')
      ? 'Автосообщения'
      : $state.includes('app.content.messagesAjs.templates')
      ? 'Шаблоны'
      : 'Ручная рассылка';
    var defaultEmailMessagePart = undefined; // Используется для сравнения с начальным состоянием email при открытии шаблонов
    var defaultPopupChatMessagePart = undefined; // Используется для сравнения с начальным состоянием чата при открытии шаблонов
    var defaultPushMessagePart = undefined; // Используется для сравнения с начальным состоянием push при открытии шаблонов

    vm.$onInit = init;

    function init() {
      vm.currentApp = $rootScope.currentApp; // todo: вообще везде убрать $rootScope.currentApp

      vm.accessToBeeEditor = planFeatureAccessService.getAccess(PLAN_FEATURE.BEE_EDITOR, vm.currentApp);
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);
      vm.isEmailContentMaker = featureModel.hasAccess(FEATURES.EMAIL_CONTENT_MAKER);

      vm.beeEditorIsOpen = false; // Флаг показывающий открыт сейчас bee редактор или нет
      vm.blockPopupBlocksCount = 0; // общее количество блоков в последнем выбранном блочном поп-апе
      vm.caseStyleHelper = caseStyleHelper;
      vm.CDN_ENDPOINT = CDN_ENDPOINT;
      vm.changeUtmMarksSwitch = changeUtmMarksSwitch;
      vm.checkModifiedMessageContent = checkModifiedMessageContent;
      vm.checkPreviousMessagePartType = checkPreviousMessagePartType;
      vm.closePopover = closePopover;
      vm.contentForm = null; // форма редактора варианта сообщения
      vm.submitValidationTgEditor = () => {};
      vm.submitValidationEmailAiContentMakerEditor = () => {};
      vm.submitValidationWebhookEditor = () => {};
      vm.forTgValidation = '';
      vm.telegramEditorValidationChanged = (isValid) => {
        if (isValid) {
          vm.forTgValidation = 'text to pass validation';
        } else {
          vm.forTgValidation = '';
        }
      };
      vm.forEmailAiContentMakerValidation = '';
      vm.emailAiContentMakerEditorValidationChanged = (isValid) => {
        if (isValid) {
          vm.forEmailAiContentMakerValidation = 'text to pass validation';
        } else {
          vm.forEmailAiContentMakerValidation = '';
        }
      };
      vm.forWebhookValidation = '';
      vm.webhookEditorValidationChanged = (isValid) => {
        if (isValid) {
          vm.forWebhookValidation = 'text to pass validation';
        } else {
          vm.forWebhookValidation = '';
        }
      };
      vm.continueEditingBodyJson = sessionStorage.getItem(STORAGE_KEYS.STORAGE_BEE_EDITOR);
      vm.continueEditingPopoverOpen = !!vm.continueEditingBodyJson;
      vm.currentMessageType = $state.includes('app.content.messagesAjs') ? MESSAGE_TYPES.AUTO : MESSAGE_TYPES.MANUAL; // Текущий тип сообщения: авто или ручное
      vm.directories = []; // шаблоны
      vm.djangoUserModel = djangoUserModel;
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.EMAIL_SUBJECT_MAX_LENGTH = EMAIL_SUBJECT_MAX_LENGTH;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.fileHelper = fileHelper;
      vm.fixErrorMessagesWithScroll = fixErrorMessagesWithScroll;
      vm.froalaOptions = {
        // опции для froala editor
        disableRightClick: true,
        events: {
          'froalaEditor.image.error': froalaHelper.handleImageUploadError,
        },
        fontSize: ['10', '11', '12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72', '96'],
        heightMin: 150,
        imageEditButtons: [
          'imageReplace',
          'imageRemove',
          'imageLink',
          'linkOpen',
          'linkEdit',
          'linkRemove',
          'imageAlt',
          'imageSize',
        ],
        imageMaxSize: 1024 * 1024,
        imageUploadURL:
          API_ENDPOINT +
          '/messages/image?froala=true&auth_token=' +
          'appm-' +
          $rootScope.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        videoUploadURL:
          API_ENDPOINT +
          '/messages/files?froala=true&auth_token=' +
          'appm-' +
          $rootScope.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        key: FROALA_EDITOR_KEY,
        language: LANGUAGE,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkList: [
          {
            href: 'https://google.com',
            rel: 'nofollow',
            target: '_blank',
            text: 'Google',
          },
        ],
        paragraphFormat: {
          N: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.normal'),
          H1: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.h1'),
          H2: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.h2'),
        },
        placeholderText: $translate.instant('directives.messagePartEditor.froalaOptions.placeholderText'),
        requestWithCORS: false,
        theme: 'carrot-quest',
        toolbarButtons: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertLink',
          'insertImage',
          'insertVideo',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsMD: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertLink',
          'insertImage',
          'insertVideo',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsSM: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertLink',
          'insertImage',
          'insertVideo',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsXS: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertLink',
          'insertImage',
          'insertVideo',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarSticky: false,
      };
      vm.froalaOptionsForDefaultEmail = {
        // HACK: опции для froala editor для ручного письма. Нужно всё равно разносить на компоненты всю эту лабуду, поэтому проще было продублировать этот объект
        disableRightClick: true,
        events: {
          'froalaEditor.file.error': froalaHelper.handleFileUploadError,
          'froalaEditor.image.error': froalaHelper.handleImageUploadError,
        },
        fileAllowedTypes: [
          'application/msword',
          'application/pdf',
          'application/pgp-signature',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/x-rar',
          'application/x-rar-compressed',
          'application/x-zip-compressed',
          'application/zip',
          'image/gif',
          'image/jpeg',
          'image/jpeg',
          'image/pjpeg',
          'image/png',
          'image/svg+xml',
          'text/html',
          'text/plain',
          `.${DOCS_EXTENSION.CSV}`, // Если указать mime-type, то ничего не работает
        ],
        fileMaxSize: 10 * 1024 * 1024,
        fileUploadURL:
          API_ENDPOINT +
          '/messages/files?froala=true&auth_token=' +
          'appm-' +
          $rootScope.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        fileUseSelectedText: true,
        fontSize: ['10', '11', '12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72', '96'],
        heightMin: 150,
        imageEditButtons: [
          'imageReplace',
          'imageRemove',
          'imageLink',
          'linkOpen',
          'linkEdit',
          'linkRemove',
          'imageAlt',
          'imageSize',
        ],
        imageMaxSize: 1024 * 1024,
        imageUploadURL:
          API_ENDPOINT +
          '/messages/image?froala=true&auth_token=' +
          'appm-' +
          $rootScope.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        videoUploadURL:
          API_ENDPOINT +
          '/messages/files?froala=true&auth_token=' +
          'appm-' +
          $rootScope.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        key: FROALA_EDITOR_KEY,
        language: LANGUAGE,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkList: [
          {
            href: 'https://google.com',
            rel: 'nofollow',
            target: '_blank',
            text: 'Google',
          },
        ],
        paragraphFormat: {
          N: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.normal'),
          H1: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.h1'),
          H2: $translate.instant('directives.messagePartEditor.froalaOptions.paragraphFormat.h2'),
        },
        placeholderText: $translate.instant('directives.messagePartEditor.froalaOptions.placeholderText'),
        requestWithCORS: false,
        theme: 'carrot-quest',
        toolbarButtons: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertFile',
          'insertLink',
          'insertImage',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsMD: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertFile',
          'insertLink',
          'insertImage',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsSM: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertFile',
          'insertLink',
          'insertImage',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarButtonsXS: [
          'bold',
          'paragraphFormat',
          'fontSize',
          'align',
          '|',
          'insertFile',
          'insertLink',
          'insertImage',
          'insert_user_prop',
          'insert_button',
          '|',
          'html',
        ],
        toolbarSticky: false,
      };
      vm.HTML_MAX_SIZE = HTML_MAX_SIZE;
      vm.HTML_RECOMMENDED_MAX_SIZE = HTML_RECOMMENDED_MAX_SIZE;
      vm.hasAccessToMessagePartType = hasAccessToMessagePartType;
      vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
      vm.isOpenLinkWhenClickOnNotification = isOpenLinkWhenClickOnNotification();
      vm.sdkPushClickActionCheckboxRandomId = `is-open-link-when-click-on-notification-${Date.now()}`;
      vm.userActivatedInSdk =
        Boolean(vm.currentApp.activation.installed_sdk_ios) || Boolean(vm.currentApp.activation.installed_sdk_android);
      vm.isShowRecommendedSizeWarning = isShowRecommendedSizeWarning;
      vm.isTemplatePage = $state.includes('app.content.messagesAjs.templates'); // Текущая страница является страницей шаблонов
      vm.isTemplatesPopoverOpen = false; // Флаг показывающий открыт поповер с шаблонами или нет
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MESSAGE_TYPES = MESSAGE_TYPES;
      vm.messagePartModel = messagePartModel;
      vm.messageSenders = [];
      vm.notChooseTemplateError = $state.includes('app.content.messagesAjs')
        ? 'notChooseTemplateErrorInAutoMessage'
        : $state.includes('app.content.messagesAjs.templates')
        ? 'notChooseTemplateErrorInTemplates'
        : 'notChooseTemplateErrorInMessage'; // В зависимости от раздела сайта, в котором находится пользователь, меняет текст в ошибке, если не выбрать шаблон и нажать кнопку далее
      vm.omitReplyTypes = omitReplyTypes;
      vm.openBeeEditor = openBeeEditor;
      vm.openCreateTemplateModal = openCreateTemplateModal;
      vm.openInsertPropsIntoTextModal = openInsertPropsIntoTextModal;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.POPUP_REPLY_TYPES = POPUP_REPLY_TYPES;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.properties = {
        // свойства пользователя, события и типы событий
        eventTypeProps: [],
        eventTypes: [],
        userProps: [],
      };
      vm.parsePropsOption = {
        clearValueFromHtml: true,
        onBadgeClick: openInsertPropsIntoTextModal,
        userPropList: vm.properties.userProps,
        placeholder: {
          text: $translate.instant('directives.messagePartEditor.email.emailSubjectInput.placeholder'),
          color: '#9da3af',
        },
      };
      vm.parseOuterValue = vm.messagePart[MESSAGE_PART_TYPES.EMAIL].subject;
      vm.PUSH_BODY_MAX_LENGTH = PUSH_BODY_MAX_LENGTH;
      vm.PUSH_SUBJECT_MAX_LENGTH = PUSH_SUBJECT_MAX_LENGTH;
      vm.pushSettings = null;
      vm.pushSettingsModel = pushSettingsModel;
      vm.SCROLL_CLASS_NAME = SCROLL_CLASS_NAME;
      vm.setLastSelectedPopupType = setLastSelectedPopupType;
      vm.setMessagePartEditorErrorHandler = setMessagePartEditorErrorHandler;
      vm.setTemplateParams = setTemplateParams;
      vm.sdkActivationTooltipIsOpen = false;
      // CAR-63952 Шаблоны временно скрыты для телеги
      vm.showTemplates = vm.accessToMessagesTemplates.hasAccess && vm.messagePart.type !== MESSAGE_PART_TYPES.TELEGRAM; // Шаблоны открываются, если у типа сообщения дефолтные настройки.
      vm.showTemplatesWatcher = ''; // Нужна для проверки валидации формы. Нельзя пускать пользователя дальше по клику на кнопку 'Далее', если шаблоны открыты.
      vm.toggleSdkActivationTooltip = toggleSdkActivationTooltip;
      vm.trackChangeEmailType = trackChangeEmailType;
      vm.trackChangePopupSize = trackChangePopupSize;
      vm.trackChangeReplyType = trackChangeReplyType;
      vm.trackChangeShowPreview = trackChangeShowPreview;
      vm.trackChangeTemplate = trackChangeTemplate;
      vm.trackClickBeeEditorConnection = trackClickBeeEditorConnection;
      vm.trackClickContinueBeeEditor = trackClickContinueBeeEditor;
      vm.trackClickOnBeeEditor = trackClickOnBeeEditor;
      vm.trackClickOnInsertFromTemplateDropdown = trackClickOnInsertFromTemplateDropdown;
      vm.trackEnableUtmMarks = trackEnableUtmMarks;
      vm.trackSelectBlockPopupMessagePartType = trackSelectBlockPopupMessagePartType;
      vm.trackSelectMessagePartType = trackSelectMessagePartType;
      vm.trackClickOnInsertFromTemplateDropdown = trackClickOnInsertFromTemplateDropdown;
      vm.TYPE_OS_PREVIEW = TYPE_OS_PREVIEW;
      vm.typeOs = TYPE_OS_PREVIEW.IOS;
      vm.iconTheme = getIconTheme();
      vm.UTM_MARKS_MAX_LENGTH = UTM_MARKS_MAX_LENGTH;
      vm.UTM_MARKS_SYSTEM_VALUES_ARRAY = UTM_MARKS_SYSTEM_VALUES_ARRAY;
      vm.UTM_MARKS_TYPES_ARRAY = UTM_MARKS_TYPES_ARRAY;
      vm.validationHelper = validationHelper;
      vm.applyChanges = () => $scope.$applyAsync();

      vm.accessToMessagesTemplates.hasAccess &&
        firstValueFrom(templateDirectoryModel.getList(vm.currentApp.id, true, true)).then(
          getTemplateDirectoriesSuccess,
        );

      $scope.$watch('vm.showTemplates', checkShowTemplatesError);

      if (vm.toggleMessagePartTypeCallback) {
        $scope.$watch('vm.messagePart.type', watchMessagePartType);
      }

      /**
       * Вотчер за типом сообщения
       *
       * @param {MESSAGE_PART_TYPES} newVal
       * @param {MESSAGE_PART_TYPES} oldVal
       */
      function watchMessagePartType(newVal, oldVal) {
        vm.toggleMessagePartTypeCallback({
          messageType: newVal,
        });
      }

      $scope.$watchCollection(getBlocksCount, setBlocksCount);
      if (angular.isUndefined(vm.messagePart)) {
        vm.messagePart = messagePartModel.getDefault();
      }

      if (angular.isUndefined(vm.showAdvancedMessagePartTypes)) {
        vm.showAdvancedMessagePartTypes = true;
      }

      // FIXME HACK: сделано через жопу, лишь бы работало. Это показ конкретного места ошибки внутри редактора автосообщений
      vm.messagePart.handleError = handleError;

      firstValueFrom(messageSenderModel.getList(vm.currentApp.id))
        .then(getMessageSendersSuccess)
        .then(storeComplicatedDefaultEmailMessagePart)
        .then(storeComplicatedDefaultPopupChatMessagePart)
        .then(checkModifiedMessageContent);
      firstValueFrom(propertyModel.getList(vm.currentApp.id)).then(getPropertiesSuccess);
      firstValueFrom(pushSettingsModel.get(vm.currentApp.id))
        .then(getPushSettingsSuccess)
        .then(storeComplicatedDefaultPushMessagePart)
        .then(checkModifiedMessageContent);

      /**
       * Получение количества блоков, добавленных в последний выбранный блочный поп-ап
       *
       * @returns {Number}
       */
      function getBlocksCount() {
        var defaultType = vm.messagePart.lastSelectedBlockPopupType;

        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Поэтому у messagePart, в свойстве lastSelectedBlockPopupType, не появиться отдельного SDK-типа, но нам как-то обработать эту ситуацию.
        if (vm.messagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
          defaultType = MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL;
        }

        var blocksCount = $filter('flatten')(vm.messagePart[defaultType].bodyJson.blocks).length;
        if (vm.messagePart[defaultType].bodyJson.footer) {
          blocksCount++;
        }

        return blocksCount;
      }

      function getMessageSendersSuccess(messageSenders) {
        var messageSenderFromCurrentTeamMember = messageSenderModel.convertFromTeamMember(
          $filter('filter')(vm.currentApp.admins, { id: $rootScope.djangoUser.id }, true)[0],
        );
        messageSenderFromCurrentTeamMember.type = MESSAGE_PART_SENDER_TYPES.TEAM_MEMBER;
        vm.messageSenders.push(messageSenderFromCurrentTeamMember);

        for (var i = 0; i < messageSenders.length; i++) {
          var messageSender = messageSenders[i];

          messageSender.type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
          vm.messageSenders.push(messageSender);
        }

        // если у сообщения задан отправитель - надо проверить, есть ли он в списке отправителей, и если его нет - добавить (такое может быть, если этот отправитель удалён, но остался закреплённым за сообщением)
        if (vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender) {
          var currentMessageSender = $filter('filter')(
            vm.messageSenders,
            { id: vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender.id },
            true,
          )[0];

          if (!currentMessageSender) {
            vm.messageSenders.unshift(vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender);
          }
        } else {
          // если отправителя нет - надо выставить его по умолчанию. Для ручных сообщений это текущий член команды, для автосообщений - отправитель по умолчанию
          if (!vm.showAdvancedMessagePartTypes) {
            vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender = messageSenderFromCurrentTeamMember;
          } else {
            vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender = $filter('filter')(
              vm.messageSenders,
              { isDefault: true },
              true,
            )[0];
          }
        }

        // если у сообщения задан отправитель - надо проверить, есть ли он в списке отправителей, и если его нет - добавить (такое может быть, если этот отправитель удалён, но остался закреплённым за сообщением)
        if (vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender) {
          var currentMessageSender = $filter('filter')(
            vm.messageSenders,
            { id: vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender.id },
            true,
          )[0];

          if (!currentMessageSender) {
            vm.messageSenders.unshift(vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender);
          }
        } else {
          // если отправителя нет - надо выставить его по умолчанию.
          // Для ручных сообщений это текущий член команды
          // Для автосообщений - отправитель по умолчанию
          if (!vm.showAdvancedMessagePartTypes) {
            vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender = messageSenderFromCurrentTeamMember;
          } else {
            vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender = $filter('filter')(
              vm.messageSenders,
              { isDefault: true },
              true,
            )[0];
          }
        }
      }

      function getPropertiesSuccess(properties) {
        vm.properties = properties;

        vm.parsePropsOption = { ...vm.parsePropsOption, userPropList: vm.properties.userProps };
        vm.parseOuterValue = vm.messagePart[MESSAGE_PART_TYPES.EMAIL].subject;

        // NOTE: сложная логика, сложный парсинг!
        // Есть следующий кейс: пользователь создал, например, поп-ап с блоками, в которые добавил НОВЫЕ свойства/события (фактически добавил их в соответствующие списки в properties), перешёл на другую вкладку и вернулся на эту вкладку, при этом properties получились по-новой.
        // И поскольку список получается по-новой - в этом списке отсутствуют новые свойства/события из блоков! Потому что эти свойства/события фактически создаются в бэкэнде за пределами этого компонента, в данном компоненте они просто добавляются в списоки в properties, который приходит в эту функцию
        // Поэтому нужно добавить недостающие свойства/события из варианта сообщения в соответствующие списки
        messagePartModel.extendEventTypesAndUserProperties(vm.messagePart, properties.eventTypes, properties.userProps);

        return properties;
      }

      function getPushSettingsSuccess(pushSettings) {
        vm.pushSettings = pushSettings;

        // из настроек пушей по умолчанию берутся иконка и действие на клик по пушу
        vm.messagePart[MESSAGE_PART_TYPES.PUSH].clickAction = !vm.messagePart[MESSAGE_PART_TYPES.PUSH].clickAction
          ? pushSettings.clickAction
          : vm.messagePart[MESSAGE_PART_TYPES.PUSH].clickAction;
        vm.messagePart[MESSAGE_PART_TYPES.PUSH].icon = !vm.messagePart[MESSAGE_PART_TYPES.PUSH].icon
          ? pushSettings.icon
          : vm.messagePart[MESSAGE_PART_TYPES.PUSH].icon;
      }

      /**
       * Возвращает iconTheme, тема иконки чата (default или dark)
       *
       * @returns {string}
       */
      function getIconTheme() {
        let { iconTheme } = UserColorsCalculationService.getUserColorPalette(
          vm.currentApp.settings.messenger_collapsed_color,
          vm.currentApp.settings.messenger_theme,
        );

        return iconTheme;
      }

      /**
       * Отслеживает открыты шаблоны или нет и показывает ошибку валидации формы,
       * при нажатии на кнопку `Далее`, если открыты шаблоны.
       *
       * @param newVal
       * @param oldVal
       */
      function checkShowTemplatesError(newVal, oldVal) {
        if (newVal !== oldVal) {
          vm.showTemplatesWatcher = !vm.showTemplates ? 'valid' : '';
        }
      }

      /**
       * Присвоение количества блоков, добавленных в последний выбранный блочный поп-ап
       *
       * @param {Number} blocksCount Количество блоков
       */
      function setBlocksCount(blocksCount) {
        vm.blockPopupBlocksCount = blocksCount;
      }

      /**
       * Успешное получение папок шаблонов
       *
       * @param {Array} directories - Список папок
       */
      function getTemplateDirectoriesSuccess(directories) {
        vm.directories = directories;
      }
    }

    function changeUtmMarksSwitch(value) {
      $scope.$apply(() => {
        vm.messagePart[vm.MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = value;
      });
    }

    /**
     * Выполняется, если изменился тип сообщения
     * и проверяет на равенство значение типа сообщения, заданное по умолчанию, с текущим значением
     */
    function checkModifiedMessageContent() {
      var currentMessagePart = vm.messagePart[vm.messagePart.type];
      var defaultMessagePart = messagePartModel.getDefault()[vm.messagePart.type];
      // Нельзя менять showTemplates, пока defaultEmailMessagePart и defaultPushMessagePart не будут получены (т.к. у email и push дефолтное состояние отличается всегда и нужно заполнять его после запроса, а затем чистить поля body)
      // Иначе возникнет ситуация, когда шаблоны сначала не будут показываться,
      // а затем, когда придут данные, шаблоны отобразятся.
      if (vm.messagePart.type === MESSAGE_PART_TYPES.EMAIL) {
        if (defaultEmailMessagePart !== undefined) {
          defaultMessagePart = defaultEmailMessagePart;
        } else {
          return;
        }
      }

      if (vm.messagePart.type === MESSAGE_PART_TYPES.POPUP_CHAT) {
        if (defaultPopupChatMessagePart !== undefined) {
          defaultMessagePart = defaultPopupChatMessagePart;
        } else {
          return;
        }
      }

      if (vm.messagePart.type === MESSAGE_PART_TYPES.PUSH) {
        if (defaultPushMessagePart !== undefined) {
          defaultMessagePart = defaultPushMessagePart;
        } else {
          return;
        }
      }

      vm.showTemplates =
        vm.accessToMessagesTemplates.hasAccess &&
        angular.equals(currentMessagePart, defaultMessagePart) &&
        vm.messagePart.type !== MESSAGE_PART_TYPES.TELEGRAM; // CAR-63952 Шаблоны временно скрыты для телеги
    }

    /**
     * Закрывает поповер с шаблонами
     */
    function closePopover() {
      vm.isTemplatesPopoverOpen = false;
    }

    /**
     * Присваивает значение по умолчанию для defaultEmailMessagePart
     */
    function storeComplicatedDefaultEmailMessagePart() {
      defaultEmailMessagePart = angular.copy(vm.messagePart[MESSAGE_PART_TYPES.EMAIL]);
      defaultEmailMessagePart.default.body = '';
      defaultEmailMessagePart.subject = '';
      defaultEmailMessagePart.html.body = '';
    }

    /**
     * Присваивает значение по умолчанию для defaultPopupChatMessagePart
     */
    function storeComplicatedDefaultPopupChatMessagePart() {
      defaultPopupChatMessagePart = angular.copy(vm.messagePart[MESSAGE_PART_TYPES.POPUP_CHAT]);
      defaultPopupChatMessagePart.body = '';
      defaultPopupChatMessagePart.replyType = POPUP_REPLY_TYPES.TEXT;
    }

    /**
     * Фикс бага со скролом
     * HACK Роман Е. OverlayScroll (на котором основан cq-custom-scroll), не выполняет autoUpdate (когда внутри скролла появляются новые элементы и контейнер-скролла автоматически должен расшириться).
     * HACK Не выполняет он потому, что данные элементы (в данном случае ng-message) имеют position : absolute и их повявление не влияет на расширение DOM контейнера-скролла.
     * HACK Данный фикс сделан, толко для ng-messages находящихся в div с классом указанным в vm.SCROLL_CLASS_NAME
     *
     * @return {boolean}
     */
    function fixErrorMessagesWithScroll() {
      if (angular.isDefined(angular.element('.' + vm.SCROLL_CLASS_NAME + ' ng-message')[0])) {
        angular
          .element('.' + vm.SCROLL_CLASS_NAME + ' ng-messages')
          .addClass('position-relative')
          .css({
            height: angular.element('.' + vm.SCROLL_CLASS_NAME + ' ng-message')[0].offsetHeight,
          });
      }
      // Возвращаем всегда true, чтобы отображать блок с ошибкой если они есть
      return true;
    }

    /**
     * Присваивает значение по умолчанию для defaultPushMessagePart
     */
    function storeComplicatedDefaultPushMessagePart() {
      defaultPushMessagePart = angular.copy(vm.messagePart[MESSAGE_PART_TYPES.PUSH]);
      defaultPushMessagePart.body = '';
    }

    /**
     * HACK: Проверка типа варианта сообщения при его смене
     * Используется для того, чтобы нельзя было переключаться с редактирования поп-апа, если в нём есть ошибки валидациие
     * и можно было переключиться, если ещё не добавлено ни одного блока в поп-ап
     *
     * @param {Object} messagePartType Тип варианта сообщения
     * @param {Event} $event Событие клика на новый тип варианта сообщения
     */
    function checkPreviousMessagePartType(messagePartType, $event) {
      if (vm.popupEditorForm) {
        if (
          ~[
            MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
            MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
            MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          ].indexOf(messagePartType) &&
          hasRealErrorsInPopupEditor()
        ) {
          $event.preventDefault();
          messagePartEditorErrorHandler && messagePartEditorErrorHandler();
        }
      }

      /**
       * Проверяет, есть ли в поп-апе ошибки кроме ошибки отсутствия блоков
       * Если вовращается false - ошибок нет, кроме отсутствия блоков
       * Если возвращается true - ошибки есть в настройках поп-апа/блоках
       *
       * @returns {boolean}
       */
      function hasRealErrorsInPopupEditor() {
        if (vm.popupEditorForm.$valid) {
          return false;
        }

        // HACK: вот тут внимательно, всё очень хитро и суперкостыльно
        //  popupEditorForm обёрнута в contentForm, это можно увидеть в html-коде этого компонента
        //  При этом внутри обеих этих форм есть по одному скрытому инпуту, которые как раз и валидируют количество блоков внутри поп-апа (как минимум должен быть 1 блок)
        //  Дак вот, если в обеих этих формах оба инпута не проходят валидацию по min (по минимальному количеству блоков) и это единственная ошибка - значит других ошибок нет
        //  Почему так сложно? Почему недостаточно проверить только popupEditorForm? Потому что в contentForm потенциально могут быть другие ошибки, которые не должны дать пройти дальше
        //  Почему нельзя проверить только contentForm? Потому что нужно удостовериться, что валидатор min не пройден именно на инпутах количества блоков, а не на других
        if (vm.popupEditorForm && vm.popupEditorForm.$invalid) {
          let popupEditorFormErrors = Object.keys(vm.popupEditorForm.$error);
          let contentFormErrors = Object.keys(vm.contentForm.$error);

          // vm.contentForm.$error['min'].length сравниванется с двойкой, потому что в дополнении к своему инпуту включает в себя ошибку из инпута дочерней формы
          if (
            popupEditorFormErrors.length === 1 &&
            popupEditorFormErrors[0] === 'min' &&
            vm.popupEditorForm.$error['min'].length === 1 &&
            contentFormErrors.length === 1 &&
            contentFormErrors[0] === 'min' &&
            vm.contentForm.$error['min'].length === 2
          ) {
            return false;
          }
        }

        return true;
      }
    }

    /**
     * Обработчик ошибок в случае отправки формы роодительским компонентом
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.contentForm.$setSubmitted();
      vm.submitValidationTgEditor();
      vm.submitValidationEmailAiContentMakerEditor();
      vm.submitValidationWebhookEditor();

      messagePartEditorErrorHandler && messagePartEditorErrorHandler();
    }

    /**
     * Проверка доступа к типу сообщения в тарифном плане
     *
     * @param {MESSAGE_TYPES} messagePartType Тип сообщения
     * @returns {boolean}
     */
    function hasAccessToMessagePartType(messagePartType) {
      const productFeature = PLAN_FEATURE_BY_MESSAGE_PART_TYPE[vm.currentMessageType][messagePartType];

      return planFeatureAccessService.getAccess(productFeature, vm.currentApp).hasAccess;
    }

    /**
     * Флаг показа предупреждения о превышении рекомендуемого объёма письма
     *
     * @param {String} messagePartBody Письмо
     * @returns {*|boolean}
     */
    function isShowRecommendedSizeWarning(messagePartBody) {
      return (
        messagePartBody &&
        messagePartModel.getEmailSize(messagePartBody) >= HTML_RECOMMENDED_MAX_SIZE &&
        messagePartModel.getEmailSize(messagePartBody) < HTML_MAX_SIZE
      );
    }

    /**
     * Пропуск некоторых типов ответов
     *
     * @param {POPUP_REPLY_TYPES} replyType
     */
    function omitReplyTypes(replyType) {
      if (replyType == POPUP_REPLY_TYPES.PUSH) {
        // NOTE: Web-push'и не доступны в США и для app'ов, которые были зарегистрированы после END_TIME_FEATURES.WEB_PUSH
        if (l10nHelper.isUsCountry() || !featureModel.hasAccess(FEATURES.WEB_PUSH, vm.currentApp.created)) {
          return true;
        }

        // HACK: в ручных сообщениях нельзя выбирать тип ответа "Подписка на Web Push уведомления", поэтому сделана вот такая костыльная проверка
        return !($state.includes('app.content.messagesAjs') || $state.includes('app.content.messagesAjs.templates'));
      } else {
        return false;
      }
    }

    /**
     * Проверка настройки "Открывать ссылку при нажатии на push-уведмоление SDK"
     *
     * @returns {boolean}
     */
    function isOpenLinkWhenClickOnNotification() {
      return !!vm.messagePart[MESSAGE_PART_TYPES.SDK_PUSH].clickAction;
    }

    /**
     * Обновление данным парта после применения шаблона
     */
    function updatePartAfterTemplate() {
      if (vm.messagePart.type === vm.MESSAGE_PART_TYPES.SDK_PUSH) {
        vm.isOpenLinkWhenClickOnNotification = isOpenLinkWhenClickOnNotification();
      }

      if (vm.messagePart[MESSAGE_PART_TYPES.EMAIL].subject) {
        vm.parseOuterValue = vm.messagePart[MESSAGE_PART_TYPES.EMAIL].subject;
      }
    }

    /**
     * Открытие визуального редактора электронных писем Bee
     */
    function openBeeEditor(continueEditing) {
      if (vm.accessToBeeEditor.hasAccess) {
        var editableBeeTemplates =
          continueEditing && vm.continueEditingBodyJson
            ? vm.continueEditingBodyJson
            : vm.messagePart.email.bee.bodyJson;
        //Нужно переключить тип на бии
        vm.messagePart[MESSAGE_PART_TYPES.EMAIL].type = vm.EMAIL_TYPES.BEE;
        vm.beeEditorIsOpen = true;

        var beeEditorModal = $uibModal.open({
          component: 'cqBeeEditorModal',
          windowClass: 'bee-editor-modal',
          resolve: {
            currentApp: angular.bind(null, angular.identity, $rootScope.currentApp),
            template: angular.bind(null, angular.identity, editableBeeTemplates),
          },
        });

        beeEditorModal.result.then(function (data) {
          vm.beeEditorIsOpen = false;
          if (data && data.jsonFile) {
            vm.messagePart.email.bee.body = data.htmlFile;
            vm.messagePart.email.bee.bodyJson = data.jsonFile;
            vm.continueEditingBodyJson = undefined;
            vm.continueEditingPopoverOpen = false;
          } else if (data && data.editorJsonFile && data.editorJsonFile !== vm.messagePart.email.bee.bodyJson) {
            vm.continueEditingBodyJson = data.editorJsonFile;
            vm.continueEditingPopoverOpen = true;
          }
        });
      } else {
        paywallService.showPaywall($rootScope.currentApp, vm.accessToBeeEditor.denialReason);
      }
    }

    /** Открытие модалки с добавлением свойства в текст */
    function openInsertPropsIntoTextModal(propName, defaultValue) {
      const selection = $window.getSelection();
      let selectVal;
      let range;
      if (selection.rangeCount) {
        range = selection.getRangeAt(0);
        selectVal = selection.toString();
      }

      const templateModal = $uibModal.open({
        component: 'cqInsertPropsIntoTextWrapperModal',
        resolve: {
          modalWindowParams: function () {
            return {
              propName,
              defaultValue: defaultValue || selectVal ? defaultValue || selectVal : '',
              userProps: vm.properties.userProps,
            };
          },
        },
      });

      if (propName && defaultValue) {
        return templateModal.result;
      }

      return templateModal.result
        .then((data) => {
          const { property, defaultValue } = data;
          const oldValue = vm.messagePart[vm.MESSAGE_PART_TYPES.EMAIL].subject || '';

          //Костыль с обращением к родителю через DOM API
          const parent = document.querySelector('div[contenteditable]');
          if (selection.rangeCount && parent.contains(range.commonAncestorContainer)) {
            range.deleteContents();
            range.insertNode(document.createTextNode(parsePropToJinja(property, defaultValue)));
            vm.parseOuterValue = range.commonAncestorContainer.innerHTML;
          } else {
            vm.parseOuterValue = oldValue + parsePropToJinja(property, defaultValue);
          }
        })
        .catch(() => {});

      function parsePropToJinja(propName, defaultValue) {
        return `{{user['${propName}']|default('${defaultValue}')}}`;
      }
    }

    /**
     * Открытие модалки создания шаблона
     */
    function openCreateTemplateModal() {
      if (!vm.contentForm.$valid) {
        handleError();
        return;
      }
      if (!vm.messagePart) {
        return;
      }

      var createTemplateModal = $uibModal.open({
        component: 'cqSaveMessagePartAsTemplateModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          directories: angular.bind(null, angular.identity, vm.directories),
          messagePart: angular.bind(null, angular.identity, vm.messagePart),
        },
      });
      createTemplateModal.result.then(createTemplate);

      function createTemplate(template) {
        trackSaveAsTemplate(template.name, template.id);
        toastr.success($translate.instant('directives.messagePartEditor.toasts.createTemplateSuccess'));
      }
    }

    /**
     * Установка последнего выбранного типа поп-апа для варианта автосообщения
     *
     * @param {Object} messagePart Вариант автосообщения
     * @param {String} popupType Тип поп-апа
     */
    function setLastSelectedPopupType(messagePart, popupType) {
      messagePart.lastSelectedPopupType = popupType;
    }

    /**
     * Установка функции, которая будет вызываться в случае невалидности редактора варианта сообщения
     *
     * @param {Function} handler
     */
    function setMessagePartEditorErrorHandler(handler) {
      messagePartEditorErrorHandler = handler;
    }

    /**
     * Заполнение варианта автосообщения в соответствии с выбранным шаблоном
     *
     * @param {Object} messagePart Вариант сообщения
     * @param {Object} template Шаблон автосообщения
     */
    function setTemplateParams(messagePart, template) {
      messagePartModel.parseFromTemplate(template, messagePart);
      updatePartAfterTemplate();
    }

    function toggleSdkActivationTooltip() {
      vm.sdkActivationTooltipIsOpen = !vm.sdkActivationTooltipIsOpen;
    }

    /**
     * Трек клика на тип письма
     *
     * @param {EMAIL_TYPES} emailType Тип email'а
     */
    function trackChangeEmailType(emailType) {
      var emailTypeDescription;

      if (emailType === EMAIL_TYPES.DEFAULT) {
        emailTypeDescription = 'Стандартный';
      } else if (emailType == EMAIL_TYPES.HTML) {
        emailTypeDescription = 'HTML';
      } else if (emailType == EMAIL_TYPES.BEE) {
        emailTypeDescription = 'Конструктор писем';
      }
      carrotquestHelper.track(trackingPageName + ' - клик на тип письма', { Тип: emailTypeDescription });
    }

    /**
     * Трек клика на размер поп-апа
     *
     * @param {MESSAGE_PART_TYPES} popupSize Размер поп-апа
     */
    function trackChangePopupSize(popupSize) {
      var popupSizeDescription;

      if (popupSize == MESSAGE_PART_TYPES.POPUP_SMALL) {
        popupSizeDescription = 'Маленький попап';
      } else if (popupSize == MESSAGE_PART_TYPES.POPUP_BIG) {
        popupSizeDescription = 'Большой попап';
      }
      carrotquestHelper.track(trackingPageName + ' - клик на тип поп-апа', { Тип: popupSizeDescription });
    }

    /**
     * Трек клика на тип ответа в поп-апе
     */
    function trackChangeReplyType() {
      carrotquestHelper.track(trackingPageName + ' - клик на тип ответа в сообщении');
    }

    /**
     * Трек клика на 'Показать превью' и 'Скрыть превью'
     */
    function trackChangeShowPreview() {
      carrotquestHelper.track(trackingPageName + ' - клик на свернуть/развернуть превью');
    }

    /**
     * Трек выбора шаблона в 'Вставить содержимое из шаблона'
     *
     * @param {Object} template Вставляемый шаблон
     * @param {Object} messageTemplateSource Источник, из которого был вставлен шаблон
     */
    function trackChangeTemplate(template, messageTemplateSource) {
      carrotquestHelper.track(trackingPageName + ' - вставил содержание из шаблона', {
        App: $rootScope.currentApp.name,
        app_id: vm.currentApp.id,
        page: trackingPageName,
        id_шаблона: template.id,
        Название: template.name,
        Тип: template.type,
        'Источник шаблона': messageTemplateSource,
        'Место вставки': vm.isTemplatesPopoverOpen ? 'popover' : 'new',
      });
    }

    /**
     * Трек клика на кнопку подключения конструктора писем
     */
    function trackClickBeeEditorConnection() {
      carrotquestHelper.track(trackingPageName + ' - клик на подключение конструктора писем');
    }

    /**
     * Трек клика на кнопку "перейти к черновику"
     */
    function trackClickContinueBeeEditor() {
      carrotquestHelper.track('Кликнул на "перейти к черновику"', {
        App: $rootScope.currentApp.name,
        Страница: trackingPageName,
      });
    }

    /**
     * Трек клика на кнопку "Открыть конструктор"
     */
    function trackClickOnBeeEditor() {
      carrotquestHelper.track(trackingPageName + ' - клик на конструктор писем');
    }

    /**
     * Трек включения UTM меток у варианта сообщения
     */
    function trackEnableUtmMarks() {
      carrotquestHelper.track(trackingPageName + ' - Кликнул на "Добавить utm-метки"', {
        App: $rootScope.currentApp.name,
      });
    }

    /**
     * Трек сохранения сообщения как шаблон
     *
     * @param {String} templateName - Имя шаблона
     */
    function trackSaveAsTemplate(templateName, templateId) {
      carrotquestHelper.track('Шаблоны - сохранить текущее сообщение как шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: templateName,
        Тип: vm.messagePart.type,
        id_шаблона: templateId,
      });
    }

    /**
     * Трек выбора конструктора поп-апов
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackSelectBlockPopupMessagePartType(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track(trackingPageName + ' - клик на конструктор поп-апов' + sdkPhrase);
    }

    /**
     * Трек клика на тип варианта сообщения
     *
     * @param {String} type Тип варианта сообщения
     */
    function trackSelectMessagePartType(type) {
      if (~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(type)) {
        type = 'popup';
      } else if (~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(type)) {
        type = 'block_popup';
      }
      carrotquestHelper.track(trackingPageName + ' - клик на тип сообщения', { Тип: type });
    }

    /**
     * Трек по кнопке "Вставить содержание из шаблона"
     */
    function trackClickOnInsertFromTemplateDropdown() {
      carrotquestHelper.track(trackingPageName + ' - клик по "Вставить содержание из шаблона"', {
        App: $rootScope.currentApp.name,
        app_id: vm.currentApp.id,
        page: trackingPageName,
      });
    }
  }
})();
