import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { DeleteDjangoUserModule } from '@panel/app/pages/account/shared/delete-django-user/delete-django-user.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ProfileComponent } from './profile.component';

@NgModule({
  declarations: [ProfileComponent],
  exports: [ProfileComponent],
  imports: [
    CommonModule,
    DeleteDjangoUserModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class ProfileModule {}
