import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, switchMap } from 'rxjs';
import { catchError, debounceTime, filter, finalize, startWith, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { SavedReplyModel } from '@http/saved-reply/saved-reply.model';
import { SAVED_REPLY_ACCESS_TYPE, SavedReply } from '@http/saved-reply/saved-reply.types';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { CONVERSATIONS_SETTINGS_TABS } from '@panel/app/pages/conversations-settings/pages/conversations-settings/conversations-settings.constants';
import { DestroyService } from '@panel/app/services';
import { UtilsService } from '@panel/app/services/utils/utils.service';

@Component({
  selector: 'cq-saved-replies-popover',
  templateUrl: './saved-replies-popover.component.html',
  styleUrls: ['./saved-replies-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SavedRepliesPopoverComponent implements OnInit {
  @Output() selectSavedReply: EventEmitter<SavedReply> = new EventEmitter<SavedReply>();

  /** Флаг выполнения запроса */
  isApiRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Максимальная длинна поисковой фразы */
  MAX_SEARCH_LENGTH = 255;

  /** Контрол с поиском по сохранённым ответам */
  searchControl = new FormControl('', { validators: [Validators.maxLength(this.MAX_SEARCH_LENGTH)] });
  /** Флаг режима поиска */
  isSearch: boolean = false;

  /** Выбранный тип сохранённых ответов */
  selectedRepliesType: SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE.SHARED;
  changeRepliesTypeSubj: BehaviorSubject<SAVED_REPLY_ACCESS_TYPE> = new BehaviorSubject(this.selectedRepliesType);

  /** Поток со списком сохранённых ответов */
  savedRepliesList$: Observable<SavedReply[]> = combineLatest([
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      startWith(null),
      tap((searchPhrase) => (this.isSearch = !!searchPhrase)),
    ),
    this.changeRepliesTypeSubj.pipe(tap((value) => (this.selectedRepliesType = value))),
  ]).pipe(
    tap(() => this.isApiRequestPerformed$.next(true)),
    switchMap(([searchPhrase, repliesType]) =>
      this.savedReplyModel.getList(repliesType, searchPhrase).pipe(
        catchError((error) => {
          return of([]);
        }),
        finalize(() => this.isApiRequestPerformed$.next(false)),
      ),
    ),
    tap((savedReplies) => (this.savedRepliesCache = savedReplies)),
    takeUntil(this.destroy$),
  );

  private savedRepliesCache: SavedReply[] = [];

  SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE;

  /** Является ли текущий пользователь оператором */
  isOperator: boolean = this.djangoUserModel.isOperator(this.appService.currentAppId, this.store.djangoUser);

  isDarkThemeActive: boolean = this.utilsService.isDarkThemeActive();

  @ViewChild('searchElement') searchElement!: ElementRef;
  @ViewChildren('replyElement') replyElements!: QueryList<ElementRef>;
  selectedReplyIndex = 0;

  CONVERSATIONS_SETTINGS_TABS = CONVERSATIONS_SETTINGS_TABS;

  constructor(
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly hostRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly savedReplyModel: SavedReplyModel,
    private readonly store: ConversationsStoreService,
    private readonly utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.initializeKeyboardNavigation();
  }

  /**
   * Получение лейбла с хоткеем для сохранённого ответа
   *
   * @param savedReply
   * @param index
   */
  getHotkeyForReply(savedReply: SavedReply, index: number) {
    if (savedReply.accessType === SAVED_REPLY_ACCESS_TYPE.PERSONAL) {
      return `Alt + ${index + 1}`;
    }

    return `Ctrl + ${index + 1}`;
  }

  initializeKeyboardNavigation() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter((event) => ['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(event.key)),
        tap((event) => {
          const repliesArray = this.savedRepliesCache;
          const repliesNodes = this.replyElements.toArray();
          const isEndRepliesList = this.selectedReplyIndex === repliesArray.length - 1;
          const isStartRepliesList = this.selectedReplyIndex === 0;

          if (repliesNodes.length === 0) {
            return;
          }

          if (event.key === 'Enter') {
            this.onSelectSavedReply(repliesArray[this.selectedReplyIndex]);
            return;
          }

          if (['ArrowDown', 'Tab'].includes(event.key)) {
            this.selectedReplyIndex =
              isEndRepliesList || this.selectedReplyIndex === null ? 0 : this.selectedReplyIndex + 1;
          }

          if (event.key === 'ArrowUp') {
            this.selectedReplyIndex =
              isStartRepliesList || this.selectedReplyIndex === null
                ? repliesArray.length - 1
                : this.selectedReplyIndex - 1;
          }

          repliesNodes.forEach((node, index) => {
            if (index === this.selectedReplyIndex) {
              this.renderer.addClass(node.nativeElement, 'active');
              //node.nativeElement.scrollIntoView({ behavior: 'smooth' });
              node.nativeElement.focus();
            } else {
              this.renderer.removeClass(node.nativeElement, 'active');
            }
          });

          this.searchElement.nativeElement.focus();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onMouseenter(index: number) {
    this.selectedReplyIndex = index;
  }

  onSelectSavedReply(savedReply: SavedReply) {
    this.selectSavedReply.emit(savedReply);
  }

  /**
   * Сбросить поиск и получить актуальный список ответов
   */
  resetSearch(): void {
    this.searchControl.reset();
  }

  /** Переключение типа сохранённых ответов */
  toggleSelectedRepliesType() {
    this.changeRepliesTypeSubj.next(
      this.selectedRepliesType === SAVED_REPLY_ACCESS_TYPE.PERSONAL
        ? SAVED_REPLY_ACCESS_TYPE.SHARED
        : SAVED_REPLY_ACCESS_TYPE.PERSONAL,
    );
  }
}
