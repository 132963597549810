import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import Quill from 'quill';
import { delay, filter, fromEvent, merge, mergeMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { EditorComponent } from '@panel/app/partials/editor/editor.component';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-message-input[value]',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MessageInputComponent implements AfterViewInit {
  @ViewChild(EditorComponent)
  editor!: EditorComponent;

  @Input()
  placeholder: string = '';

  @Input()
  readOnly: boolean = false;

  @Input()
  scrollingContainer?: string;

  /** Значение quill, которое выплёвывается наружу */
  @Input()
  set value(string: string) {
    // в конце значения quill всегда находится \n, поэтому добавляем его
    this.quillValue = string + '\n';
  }

  get value(): string {
    // а тут убираем лишний \n, чтобы не передавать его наружу
    return this.quillValue.slice(0, -1);
  }

  @Output()
  instanceCreate = new EventEmitter<Quill>();

  @Output()
  valueChange = new EventEmitter<string>();

  protected modelChange = new EventEmitter<string>();

  /** значение текста quill */
  quillValue: string = '\n';

  constructor(private readonly destroy$: DestroyService) {}

  ngAfterViewInit(): void {
    /** Срабатывает при изменении контента внутри quill + debounce */
    const debounceContentChanged$ = this.modelChange.pipe(debounceTime(400), distinctUntilChanged());
    /**
     * Срабатывает при блюре поля ввода quill
     * NOTE: пришлось заменить onBlur на стандартный blur поля ввода, т.к. onBlur отрабатывал не сразу после расфокуса поля
     */
    const blur$ = this.editor.editorCreated.pipe(
      mergeMap((editor: Quill) => {
        return fromEvent<FocusEvent>(editor.root, 'blur');
      }),
    );

    /**
     * Срабатывает при любых нажатиях на Enter
     * Нужно для того, чтобы при вызове quill хоткеев с использованием Enter, значение в this.value было актуальное
     */
    const enter$ = this.editor.editorCreated.pipe(
      mergeMap((editor: Quill) => {
        // перехватывает все нажатия Enter, в том числе с клавишами-модификаторами
        return fromEvent<KeyboardEvent>(editor.root, 'keydown');
      }),
      filter((event) => event.keyCode === 13),
    );

    /**
     * Срабатывает при вставке из буфера обмена
     * Нужно для того, чтобы при вставке чего-либо, значение this.value сразу обновилось
     * Конкретный кейс, который эта штука обрабатывает - вставка картинки. При её вставке сразу открывается модалка.
     * Текст в модалке был неактуальный, если пользователь слишком быстро после набора текста вставлял картинку
     */
    const paste$ = this.editor.editorCreated.pipe(
      mergeMap((editor: Quill) => {
        // перехватывает все нажатия Enter, в том числе с клавишами-модификаторами
        return fromEvent<ClipboardEvent>(editor.root, 'paste');
      }),
      delay(0), // delay нужен из-за того, что значение в поле ввода обновляется не в момент совершения события paste, а после него, поэтому само значение нужно читать в следующем цикле event loop
    );

    this.editor.editorCreated.pipe(takeUntil(this.destroy$)).subscribe((editor: Quill) => {
      this.instanceCreate.emit(editor);
    });

    // при совершении какого-либо вышеописанного действия, эммитим значение quill наружу
    merge(debounceContentChanged$, blur$, enter$, paste$)
      .pipe(
        map(() => this.value),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.valueChange.emit(value);
      });
  }
}
