import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import Moment from 'moment';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APP_CATEGORY } from '@http/app/app.constants';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { Modify } from '@panel/app/shared/types/modify.type';

import { ApiCreateAppRequest, ApiGetAppResponse, AppActivation, AppSettings } from './app.types';

export type UpdatableSettings = Partial<
  Pick<
    AppSettings,
    | 'double_opt_in'
    | 'messenger_show_allow_cookies'
    | 'messenger_show_confirm_subscription'
    | 'double_opt_in_logo'
    | 'messenger_allow_cookies_policy'
    | 'messenger_confirm_subscription_policy'
    | 'messenger_confirm_subscription_with_checkbox'
    | 'track_leave_site_attempt'
  >
>;

export type ParsedAppSettings = Modify<
  AppSettings,
  {
    created: Moment.Moment;
  }
>;

export type ParsedAppActivation = Modify<
  AppActivation,
  {
    answered_user: Moment.Moment | null;
    created: Moment.Moment | null;
    created_auto_message: Moment.Moment | null;
    created_email_sending: Moment.Moment | null;
    created_funnel: Moment.Moment | null;
    created_segment: Moment.Moment | null;
    installed_code: Moment.Moment | null;
    invited_operator: Moment.Moment | null;
    reply_user: Moment.Moment | null;
    social_network_integrations_reply_user: Moment.Moment | null;
    used_autoevents: Moment.Moment | null;
    used_integration: Moment.Moment | null;
    auto_msg_sdk: Moment.Moment | null;
  }
>;

export type App = Modify<
  ApiGetAppResponse,
  {
    activation: ParsedAppActivation;
    created: Moment.Moment;
    last_api_connect_time: Moment.Moment | null;
    language: string;
    settings: ParsedAppSettings;
  }
>;

type AppServiceEvent = 'request - Online meetings';

/**
 * Сервис для работы приложениями (апами, сайтами)
 */
@Injectable({ providedIn: 'root' })
export class AppModel {
  /**
   * Типы языков аппа
   */
  static APP_LANGUAGES = {
    RU: 'ru',
    EN: 'en',
  };

  /**
   * Время в днях, которое должно пройти с последнего коннекта, чтобы код считался не установленным.
   */
  static DAYS_BEFORE_CODE_UNINSTALLATION = 3;

  /**
   * Нужен, чтоб при обновлении Апп или его внутреностей
   * можно было сообщить об этом всем компонентам в новом ангуляре и они подтянули изменения
   *
   * Использование: Если что-то обновил, вызови appModel.refresh$.next();
   */
  readonly refresh$ = new Subject();

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private teamMemberModel: TeamMemberModel,
    private l10nHelperService: L10nHelperService,
  ) {}

  /**
   * Создание приложения
   *
   * @param origin Адрес сайта
   * @param name Название приложения
   * @param type Тип сайта
   */
  create(origin: string, name: string, type: string): Observable<any> {
    const params: ApiCreateAppRequest = {
      origin,
      name,
      category: type,
      ignoreErrors: true,
    };

    if (this.cookieService.get('invited_by')) {
      params.invited_by = this.cookieService.get('invited_by');
      this.cookieService.delete('invited_by');
    } else if (localStorage.getItem('invited_by')) {
      // DEPRECATED: раньше использоватся local storage, но это неправльно,
      //  т.к. данные после перехода по реферальной ссылке должны работать только 2 месяца, а не вечность
      params.invited_by = localStorage.getItem('invited_by') ?? undefined;
      localStorage.removeItem('invited_by');
    }

    return this.httpClient
      .post('/apps', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        catchError((meta: any) => {
          if (meta.error) {
            throw meta.error;
          } else {
            throw new Error();
          }
        }),
        map((response: any) => response.data),
      );
  }

  /**
   * Получение информации о приложении по его ID
   *
   * @param appId ID приложения
   */
  get(appId: string): Observable<any> {
    let params = {
      app: appId,
    };

    return this.httpClient
      .get('/apps/' + appId + '/extendedinfo', {
        params,
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          const app = response.data.app;

          return this.parse(app);
        }),
      );
  }

  /**
   * Парсинг приложения
   */
  parse(responseApp: ApiGetAppResponse): App {
    const app = responseApp as unknown as App;

    app.created = moment(responseApp.created * 1000);
    app.last_api_connect_time = this.stringDateToMoment(responseApp.last_api_connect_time, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

    if (app.settings) {
      app.settings.created = moment(responseApp.settings.created * 1000);
      app.settings.messenger_collapsed_color = '#' + responseApp.settings.messenger_collapsed_color;
      app.language = this.l10nHelperService.getTwoLetterLang(responseApp.settings.locale);
      app.settings.email_domain = '@' + responseApp.settings.email_domain;
    }

    app.admins &&
      app.admins.forEach((admin: any) => {
        this.teamMemberModel.parse(admin);
      });

    if (app.activation) {
      app.activation.answered_user = this.stringDateToMoment(responseApp.activation.answered_user);
      app.activation.created = this.stringDateToMoment(responseApp.activation.created);
      app.activation.created_auto_message = this.stringDateToMoment(responseApp.activation.created_auto_message);
      app.activation.created_email_sending = this.stringDateToMoment(responseApp.activation.created_email_sending);
      app.activation.created_funnel = this.stringDateToMoment(responseApp.activation.created_funnel);
      app.activation.created_segment = this.stringDateToMoment(responseApp.activation.created_segment);
      app.activation.installed_code = this.stringDateToMoment(responseApp.activation.installed_code);
      app.activation.invited_operator = this.stringDateToMoment(responseApp.activation.invited_operator);
      app.activation.reply_user = this.stringDateToMoment(responseApp.activation.reply_user);
      app.activation.social_network_integrations_reply_user = this.stringDateToMoment(
        responseApp.activation.social_network_integrations_reply_user,
      );
      app.activation.used_autoevents = this.stringDateToMoment(responseApp.activation.used_autoevents);
      app.activation.used_integration = this.stringDateToMoment(responseApp.activation.used_integration);
      app.activation.auto_msg_sdk = this.stringDateToMoment(responseApp.activation.auto_msg_sdk);
    }
    return app;
  }

  /**
   * Получение активаций аппа
   *
   * @param appId ID приложения
   */
  getActivations(appId: string): Observable<any> {
    return this.httpClient.get('/apps/' + appId + '/activation', {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Получение даты последнего коннекта со стороны чата
   * Если коннектов нет в течение какого-то времени (), то код считается неустановленным
   *
   * @param appId ID приложения
   */
  getLastApiConnectTime(appId: string): Observable<Moment.Moment | null> {
    return this.httpClient
      .get('/apps/' + appId + '/stats', {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          return response.data.last_api_connect_time
            ? moment(response.data.last_api_connect_time, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : null;
        }),
      );
  }

  /**
   * Получение списка доступных пользователю приложений
   */
  getList(): Observable<App[]> {
    const params = {
      apps: true,
    };

    return this.httpClient
      .get('/djangousers/$self_django_user', {
        params,
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          const apps = response.data.apps;

          return apps.map((app: any) => {
            return this.parse(app);
          });
        }),
      );
  }

  /**
   * Получение аналитики приложения
   *
   * @param appId ID приложения
   * @param eventTypeIds ID типов событий
   * @param [ignoreLoadingBar=true] Игнорировать или нет loading bar
   */
  getReport(appId: string, eventTypeIds: string[], ignoreLoadingBar: boolean = true): Observable<any> {
    const params = {
      as_event_type_lists: true,
      event_types: eventTypeIds.join(','),
    };

    return this.httpClient.get('/apps/' + appId + '/eventsdailystats', {
      params,
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Получение описания типа приложения
   *
   * @param appCategories - Категория приложения
   */
  getTypeDescription(appCategories: APP_CATEGORY): string {
    let appTypeDescription;

    switch (appCategories) {
      case APP_CATEGORY.LANDING: {
        appTypeDescription = 'Лендинг';
        break;
      }
      case APP_CATEGORY.SHOP: {
        appTypeDescription = 'Интернет-магазин';
        break;
      }
      case APP_CATEGORY.SAAS: {
        appTypeDescription = 'SaaS';
        break;
      }
      case APP_CATEGORY.OTHER: {
        appTypeDescription = 'Что-то другое';
        break;
      }
    }

    return appTypeDescription;
  }

  /**
   * Заблокировано ли приложение
   *
   * @param app Приложение
   */
  isAppBlocked(app: App): boolean {
    if (!app.last_api_connect_time) {
      return true;
    } else {
      const now = moment().subtract(AppModel.DAYS_BEFORE_CODE_UNINSTALLATION, 'days');

      return !!now.isAfter(app.last_api_connect_time);
    }
  }

  /**
   * Проверка на то что язык аппа русский
   */
  isAppLanguageEn(lang: string): boolean {
    return lang === AppModel.APP_LANGUAGES.EN;
  }

  /**
   * Проверка на то что язык аппа английский
   */
  isAppLanguageRu(lang: string): boolean {
    return lang === AppModel.APP_LANGUAGES.RU;
  }

  muteNotifications(appId: string, conversations: any): Observable<any> {
    const params = {
      conversations,
    };

    return this.httpClient.post('/apps/' + appId + '/mutenotifications', params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Сохранение Firebase-ключа для работы пушей в SDK
   *
   * @param appId ID аппа
   * @param credentials JSON-файл с ключом, который получается пользователем в админке Firebase
   */
  saveFirebaseKey(appId: string, credentials: File): Observable<any> {
    const params: any = {
      credentials,
      errorsException: 'ValidationError',
    };

    return this.httpClient
      .post(`/apps/${appId}/firebase_sdk_credentials`, params, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((response: any) => response.data.project_id));
  }

  /**
   * Сохранение настроек аппа
   *
   * @param appId - ID аппа
   * @param settings - Настройки для сохранения
   * @param ignoreLoadingBar - Флаг показа полосы загразки
   */
  saveSettings(appId: string, settings: UpdatableSettings, ignoreLoadingBar: boolean = true): Observable<any> {
    const params: any = Object.assign({}, settings);

    let formData = new FormData();
    for (let param in params) {
      formData.append(param, params[param]);
    }

    return this.httpClient.patch('/apps/' + appId, formData, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Изменение настройки отправки сообщений по Enter
   *
   * @param messengerSendByEnter - Флаг отправки сообщений по Enter
   */
  setMessengerSendByEnter(messengerSendByEnter: boolean): Observable<any> {
    const params = {
      messenger_send_by_enter: messengerSendByEnter,
    };

    return this.httpClient.patch('/djangousers/$self_django_user', params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Отправка события заданным администраторам в наш апп
   * Например, если нам нужно уведомить всех администраторов о том, что оператор запросил подключение интеграции
   *
   * @param appId ID приложения
   * @param eventType Название события
   * @param eventProps Свойства события. Из бэка вытащить схему не получилось, потому что там JsonField(required=False, json_schema={'type': 'object'})
   * @param adminRoles Список ролей, которым будет отправлено сообщение
   */
  postServiceEvent(
    appId: string,
    eventType: AppServiceEvent,
    eventProps: object,
    adminRoles: TEAM_MEMBER_PERMISSIONS | TEAM_MEMBER_PERMISSIONS[],
  ): Observable<any> {
    const params = {
      event_type: eventType,
      event_props: eventProps,
      admin_roles: Array.isArray(adminRoles) ? adminRoles : [adminRoles],
      ignoreErrors: true,
    };

    return this.httpClient.post('/apps/' + appId + '/service_events', params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  private stringDateToMoment(stringDate?: string | null, ...args: any): Moment.Moment | null {
    if (!isDefined(stringDate)) {
      return null;
    }
    return moment(stringDate, ...args);
  }
}
