import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { AiSalesModel } from '@http/ai-sales/ai-sales.model';
import { App } from '@http/app/app.model';
import { ChannelModel } from '@http/channel/channel.model';
import { ConversationTagsModel } from '@http/conversation-tags/conversation-tags.model';
import { PropertyModel } from '@http/property/property.model';
import { TagModel } from '@http/tag/tag.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { AiSalesComponent } from '@panel/app/pages/ai-sales/ai-sales.component';
import { AiSalesSettingsComponent } from '@panel/app/pages/ai-sales/ai-sales-settings/ai-sales-settings.component';

export const AI_SALES_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.aiSales',
    component: AiSalesComponent,
    url: '',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
    },
    redirectTo: 'app.content.aiSales.botSettings',
  },
  {
    name: 'app.content.aiSales.botSettings',
    component: AiSalesSettingsComponent,
    url: '/ai-sales-bot-settings',
    resolve: [
      {
        token: 'channels',
        deps: [ChannelModel, 'currentApp'],
        resolveFn: getChannels,
      },
      {
        token: 'conversationTags',
        deps: [ConversationTagsModel, 'currentApp'],
        resolveFn: getConversationTags,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: App) => currentApp,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'userTags',
        deps: [TagModel, 'currentApp'],
        resolveFn: getUserTags,
      },
      {
        token: 'aiSalesBotSettings',
        deps: [AiSalesModel],
        resolveFn: getAiBotSettings,
      },
    ],
  },
];

function getAiBotSettings(aiSalesModel: AiSalesModel) {
  return firstValueFrom(aiSalesModel.getSettings());
}

function getChannels(channelModel: ChannelModel, currentApp: App) {
  return firstValueFrom(channelModel.getList(currentApp.id, false, true));
}

function getConversationTags(conversationTagsModel: ConversationTagsModel, currentApp: App) {
  return firstValueFrom(conversationTagsModel.getList(currentApp.id));
}

function getProperties(propertyModel: PropertyModel, currentApp: App) {
  return firstValueFrom(propertyModel.getList(currentApp.id));
}

function getUserTags(tagModel: TagModel, currentApp: App) {
  return firstValueFrom(tagModel.getList(currentApp.id));
}
