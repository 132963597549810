<ng-container *transloco="let t; read: 'editorFormatUserPropertyComponent'">
  <button
    type="button"
    class="btn btn-borderless-primary rounded-0"
    [ngbTooltip]="t('tooltip')"
    container="body"
    (click)="onClickSetUserPropertyButton()"
    triggers="hover:blur"
  >
    <i class="btn-icon cqi-sm cqi-variable"></i>
  </button>
</ng-container>
