import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';

import { PricingStore } from '@panel/app/pages/subscription/pricing/pricing.store';

import { PricingPlanDescriptionComponent } from './pricing-plan-description.component';

@NgModule({
  declarations: [PricingPlanDescriptionComponent],
  exports: [PricingPlanDescriptionComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule, TuiLet],
  providers: [PricingStore],
})
export class PricingPlanDescriptionModule {}
