<div class="container-fluid limited-container padding-bottom-60 margin-bottom-60">
  <nav
    *ngIf="hasAccessToScheduledMessages"
    aria-label="breadcrumb"
  >
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
        <div>
          <span>{{ 'autoMessages.breadcrumbs.title' | transloco }}</span>
          <i
            class="cqi-sm cqi-question-circle"
            [ngbTooltip]="'autoMessages.table.tooltip' | transloco"
            container="body"
            placement="bottom"
          ></i>
        </div>
      </li>
    </ol>
  </nav>

  <div
    class="flex align-items-center no-flex-shrink mb-20"
    [class.mb-20]="hasAccessToScheduledMessages"
  >
    <div
      *ngIf="hasAccessToScheduledMessages"
      class="btn-group btn-group-toggle"
    >
      <label
        class="btn btn-outline-primary"
        [class.active]="selectedMessageTriggerType === 'event'"
      >
        <input
          type="radio"
          [(ngModel)]="selectedMessageTriggerType"
          (ngModelChange)="onChangeMessageTriggerType()"
          [value]="'event'"
        />
        <i class="btn-icon-left cqi-sm cqi-cursor-arrow"></i>
        <span>{{ 'autoMessages.table.messageTriggerTypeSwitcher.event' | transloco }}</span>
      </label>
      <label
        class="btn btn-outline-primary"
        [class.active]="selectedMessageTriggerType === 'schedule'"
      >
        <input
          type="radio"
          [(ngModel)]="selectedMessageTriggerType"
          (ngModelChange)="onChangeMessageTriggerType()"
          [value]="'schedule'"
        />
        <i class="btn-icon-left cqi-sm cqi-clock"></i>
        <span>{{ 'autoMessages.table.messageTriggerTypeSwitcher.schedule' | transloco }}</span>
      </label>
    </div>

    <nav
      *ngIf="!hasAccessToScheduledMessages"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          <div>
            <span>{{ 'autoMessages.breadcrumbs.title' | transloco }}</span>
            <i
              class="cqi-sm cqi-question-circle"
              [ngbTooltip]="'autoMessages.table.tooltip' | transloco"
              container="body"
              placement="bottom"
            ></i>
          </div>
        </li>
      </ol>
    </nav>

    <div class="flex align-items-center margin-left-auto position-relative">
      <div
        *ngIf="selectedMessageTriggerType === 'event'"
        class="flex align-items-center margin-left-auto position-relative"
      >
        <!-- Рекомендованные сообщения -->
        <button
          *ngIf="accessToReadyAutoMessage.hasAccess"
          class="btn btn-text-primary"
          (click)="openReadyAutoMessagesModal(); trackOpenRecommendedTemplates()"
        >
          <i class="btn-icon-left cqi-sm cqi-star"></i>
          <span data-test="trigger-messages-ready-made-templates-button">{{
            'autoMessages.table.recommendedMessagesButtonNew' | transloco
          }}</span>
        </button>
        <button
          *ngIf="!accessToReadyAutoMessage.hasAccess"
          class="btn btn-text-primary"
          type="button"
          (click)="paywallService.showPaywall(currentApp, accessToReadyAutoMessage.denialReason)"
        >
          <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
          <span>{{ 'autoMessages.table.recommendedMessagesButtonNew' | transloco }}</span>
        </button>

        <!-- Шаблоны -->
        <a
          *ngIf="accessToMessagesTemplates.hasAccess"
          class="btn btn-text-primary margin-left-20"
          uiSref="app.content.messagesAjs.templates"
        >
          <i class="btn-icon-left cqi-sm cqi-templates"></i>
          <span>{{ 'autoMessages.table.messageTemplatesButton' | transloco }}</span>
        </a>
        <button
          *ngIf="!accessToMessagesTemplates.hasAccess"
          class="btn btn-text-primary margin-left-20"
          type="button"
          (click)="paywallService.showPaywall(currentApp, accessToMessagesTemplates.denialReason)"
        >
          <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
          <span>{{ 'autoMessages.table.messageTemplatesButton' | transloco }}</span>
        </button>

        <div class="bordered-left margin-left-20 padding-top-25"></div>
      </div>

      <!-- Обучающие статьи -->
      <a
        class="btn btn-text-primary margin-left-20"
        target="_blank"
        (click)="trackClickOnKnowledgeBaseInDescription()"
        [href]="'autoMessages.table.helpArticlesLink.href' | transloco"
      >
        <i class="btn-icon-left cqi-sm cqi-book"></i>
        <span>{{ 'autoMessages.table.helpArticlesLink.text' | transloco }}</span>
      </a>
    </div>
  </div>
  <div class="card position-sticky">
    <div class="card-header sticky-top">
      <div
        *ngIf="!selectedMessages.length && !selectedMessagesAll"
        class="flex justify-space-between"
      >
        <ng-container *ngIf="!isSearchMode; else searchMode">
          <cq-auto-message-status-filter
            [currentDirectory]="currentDirectory"
            [currentStatus]="currentStatus"
            (changeStatus)="onChangeStatus($event)"
          ></cq-auto-message-status-filter>

          <div class="flex flex-grow align-items-center justify-end">
            <cq-directory-filter
              *ngIf="selectedMessageTriggerType === 'event'"
              class="message-directory"
              [currentApp]="currentApp"
              [currentDirectoryId]="currentDirectory.id"
              (changeDirectory)="onChangeDirectory($event)"
            ></cq-directory-filter>

            <cq-auto-message-type-filter
              class="message-part-type"
              [currentApp]="currentApp"
              [currentType]="currentType"
              (changeType)="onChangeType($event)"
            ></cq-auto-message-type-filter>

            <!--Кнопка поиска-->
            <button
              class="btn btn-outline-primary mr-20"
              type="button"
              (click)="resetForSearch()"
            >
              <i class="btn-icon cqi-sm cqi-search"></i>
            </button>
          </div>
        </ng-container>

        <!--Поиск-->
        <ng-template #searchMode>
          <div
            class="form-group has-feedback has-feedback-left flex-grow mb-0 mr-10"
            [cqShowError]="searchForm"
          >
            <input
              class="form-control"
              autocomplete="off"
              type="text"
              [formControl]="searchForm"
              [placeholder]="'autoMessages.table.searchInput.placeholder' | transloco"
            />
            <cq-validation-messages [control]="searchForm">
              <cq-validation-message errorName="minlength">
                {{
                  'autoMessages.table.searchInput.errors.length'
                    | transloco
                      : {
                          max: SEARCH_PATTERN_MAX_LENGTH,
                          min: SEARCH_PATTERN_MIN_LENGTH,
                        }
                }}
              </cq-validation-message>
              <cq-validation-message errorName="maxlength">
                {{
                  'autoMessages.table.searchInput.errors.length'
                    | transloco
                      : {
                          max: SEARCH_PATTERN_MAX_LENGTH,
                          min: SEARCH_PATTERN_MIN_LENGTH,
                        }
                }}
              </cq-validation-message>
            </cq-validation-messages>
            <span class="form-control-feedback">
              <i class="cqi-sm cqi-search text-muted"></i>
            </span>
          </div>
          <button
            class="btn btn-outline-primary mr-20"
            type="button"
            (click)="resetSearch()"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </ng-template>

        <!-- Создать сообщение -->
        <a
          class="btn btn-primary"
          [uiSref]="getCreateMessageState()"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ 'autoMessages.table.createAutoMessageButton' | transloco }}</span>
        </a>
      </div>

      <cq-mass-action-panel
        [accessToSelectedAutoMessages]="accessToSelectedAutoMessages"
        [accessToSelectedTelegramAutoMessages]="accessToSelectedTelegramAutoMessages"
        [activeMessagesAmounts]="activeMessagesAmounts"
        [currentApp]="currentApp"
        [currentDirectory]="currentDirectory"
        [currentStatus]="currentStatus"
        [messagesPaginator]="messagesPaginator"
        [selectedMessages]="selectedMessages"
        [selectedMessagesAll]="selectedMessagesAll"
        (clickActivateButton)="changeMessageStatus(true, $event)"
        (clickArchiveButton)="archiveOrReestablishMessage(false, $event)"
        (clickChangeDirectoryButton)="openSetDirectoryModal($event)"
        (clickCopyButton)="createCopy($event)"
        (clickPauseButton)="changeMessageStatus(false, $event)"
        (clickReestablishButton)="archiveOrReestablishMessage(true, $event)"
      ></cq-mass-action-panel>
    </div>

    <cq-auto-message-table
      *ngIf="selectedMessageTriggerType === 'event'"
      [accessToSelectedAutoMessages]="accessToSelectedAutoMessages"
      [accessToTelegramAutoMessages]="accessToTelegramAutoMessages"
      [activeMessagesAmounts]="activeMessagesAmounts"
      [appBlocks]="appBlocks"
      [currentApp]="currentApp"
      [isAllMessageSelected]="selectedMessagesAll"
      [isSearchMode]="isSearchMode"
      [messages]="messages"
      (clickActivateButton)="changeMessageStatus(true, $event)"
      (clickArchiveButton)="archiveOrReestablishMessage(false, $event)"
      (clickChangeDirectoryButton)="openSetDirectoryModal($event)"
      (clickCopyButton)="createCopy($event)"
      (clickPauseButton)="changeMessageStatus(false, $event)"
      (clickReestablishButton)="archiveOrReestablishMessage(true, $event)"
      (clickRenameButton)="openRenameModal($event)"
      (selectAllMessage)="setSelectForAllMessages($event)"
      (selectMessage)="refreshSelectedMessages()"
    ></cq-auto-message-table>

    <cq-scheduled-auto-message-table
      *ngIf="selectedMessageTriggerType === 'schedule'"
      [messages]="messages"
      [accessToSelectedAutoMessages]="accessToSelectedAutoMessages"
      [accessToTelegramAutoMessages]="accessToTelegramAutoMessages"
      [activeMessagesAmounts]="activeMessagesAmounts"
      [appBlocks]="appBlocks"
      [currentApp]="currentApp"
      [isAllMessageSelected]="selectedMessagesAll"
      (clickActivateButton)="changeScheduledMessageStatus($event)"
      (clickArchiveButton)="archiveOrReestablishMessage(false, $event)"
      (clickCopyButton)="createScheduledMessageCopy($event)"
      (clickPauseButton)="changeScheduledMessageStatus($event)"
      (clickReestablishButton)="archiveOrReestablishMessage(true, $event)"
      (clickRenameButton)="renameScheduledMessage($event)"
      (selectAllMessage)="setSelectForAllMessages($event)"
      (selectMessage)="refreshSelectedMessages()"
    ></cq-scheduled-auto-message-table>

    <div
      *ngIf="messagesPaginator?.paginatePosition && !isMessagesRequestPerformed"
      class="card-body text-center"
    >
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="getMessages()"
      >
        {{ 'general.showMore' | transloco }}
      </button>
    </div>
    <div
      *ngIf="messages.length === 0 && !isMessagesRequestPerformed"
      class="card-body text-center"
    >
      <cq-zero-data class="margin-bottom-10">
        <span>{{ 'autoMessages.table.table.body.zeroData.notHaveActiveAutoMessages' | transloco }}</span>
      </cq-zero-data>
      <a
        class="btn btn-primary"
        [uiSref]="getCreateMessageState()"
        >{{ 'autoMessages.table.table.body.zeroData.createAutoMessageButton' | transloco }}
      </a>
    </div>
  </div>
</div>
<cq-loader
  *ngIf="isMessagesRequestPerformed"
  class="content-loader"
></cq-loader>
