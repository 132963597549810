import Inline from 'quill/blots/inline';

export class EditorCustomBlotSize extends Inline {
  static blotName = 'size';
  static tagName = 'SPAN';

  static create(value: any): any {
    let node = super.create(value);

    node.style.fontSize = value;

    return node;
  }

  static formats(domNode: HTMLElement) {
    return domNode.style.fontSize;
  }

  public formats(): { [index: string]: any } {
    let format = this.statics.formats(this.domNode, this.scroll);
    let formats = this.attributes.values();

    if (format !== null) {
      formats[this.statics.blotName] = format;
    }

    return formats;
  }

  format(name: string, value: any): any {
    if (name === EditorCustomBlotSize.blotName && value) {
      this.domNode.style.fontSize = value;
    } else {
      super.format(name, value);
    }
  }
}
