<ng-container *transloco="let t; read: 'triggerChainActionEditorComponent'">
  <cq-trigger-chain-step-editor-wrap
    [formControl]="form.controls.name"
    (deleteBlockClick)="deleteStepChain()"
    (copyBlockClick)="copyStepChain()"
  >
    <div class="p-20">
      <div class="text-secondary mb-10">{{ t('description') }}</div>

      <div>
        <cq-actions-list [formControl]="form.controls.actions"></cq-actions-list>

        <cq-validation-messages [control]="form.controls.actions">
          <cq-validation-message errorName="required"> {{ t('actionList.errors.required') }} </cq-validation-message>
          <cq-validation-message
            class="hidden"
            errorName="null"
          ></cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>
  </cq-trigger-chain-step-editor-wrap>
</ng-container>
