import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import {
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLinkBase,
  NgbNavOutlet,
  NgbPopover,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';

import { EditorFormatButtonComponent } from './editor-format-button.component';

@NgModule({
  declarations: [EditorFormatButtonComponent],
  exports: [EditorFormatButtonComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    NgbTooltip,
    FormsModule,
    NgbPopover,
    NgbNav,
    ReactiveFormsModule,
    NgbNavItem,
    NgbNavLinkBase,
    NgbNavContent,
    NgbNavOutlet,
  ],
})
export class EditorFormatButtonModule {}
