import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DjangoUser } from '@http/django-user/django-user.types';

@Injectable()
export class ConversationsSettingsPageStore {
  readonly djangoUser$: BehaviorSubject<DjangoUser | null> = new BehaviorSubject<DjangoUser | null>(null);

  get djangoUser() {
    const djangoUser = this.djangoUser$.getValue();
    if (!djangoUser) {
      throw new Error('Cannot access DjangoUser: value is null');
    }

    return djangoUser;
  }

  set djangoUser(djangoUser: DjangoUser) {
    this.djangoUser$.next(djangoUser);
  }
}
