import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AnswerToUserPropComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/answer-to-user-prop/answer-to-user-prop.component';
import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [AnswerToUserPropComponent],
  exports: [AnswerToUserPropComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    PropertySelectorModule,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
})
export class AnswerToUserPropModule {}
