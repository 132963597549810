<ng-container *transloco="let t; read: 'aiContentMakerPersonAndTaskComponent'">
  <label for="personAndTask">
    <h3>{{ t('label') }}</h3>
  </label>
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <textarea
      class="form-control no-resize custom-scroll"
      id="personAndTask"
      rows="4"
      [formControl]="control"
      [placeholder]="t('input.placeholder')"
    ></textarea>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('input.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>
