<ng-container *transloco="let t; read: 'savedRepliesPageComponent'">
  <div class="container-fluid limited-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          aria-current="page"
          class="breadcrumb-item active"
        >
          {{ t('breadcrumb') }}
        </li>
      </ol>
    </nav>
    <cq-saved-replies></cq-saved-replies>
  </div>
</ng-container>
