import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment/moment';
import { Observable } from 'rxjs';

import { INTEGRATION_TYPES, INTEGRATIONS_WARNINGS_BY_TYPE } from '@http/integration/constants/integration.constants';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';

export class IntegrationModel {
  DEFAULT_INTEGRATION = {
    settings: {},
    type: '',
  };

  amocrm: any = {
    getOAuthUrl: this.amocrmGetOAuthUrl.bind(this),
    getPipelines: this.amocrmGetPipelines.bind(this),
    getChatBots: this.getChatBots.bind(null, INTEGRATION_TYPES.AMOCRM),
    getAutoMessages: this.getAutoMessages.bind(null, INTEGRATION_TYPES.AMOCRM),
  };

  bitrix24: any = {
    authorize: this.bitrix24Authorize.bind(this),
    getDealPipelines: this.bitrix24GetDealPipelines.bind(this),
    getDealProps: this.bitrix24GetDealProps.bind(this),
    getLeadPipelines: this.bitrix24GetLeadPipelines.bind(this),
    getLeadProps: this.bitrix24GetLeadProps.bind(this),
    getOAuthUrl: this.bitrix24GetOAuthUrl.bind(this),
  };

  calendly: any = {
    getEventList: this.getCalendlyEventList.bind(this),
  };

  emailNotification: any = {
    getChatBots: this.getChatBots.bind(null, INTEGRATION_TYPES.EMAIL_NOTIFICATION),
    getAutoMessages: this.getAutoMessages.bind(null, INTEGRATION_TYPES.EMAIL_NOTIFICATION),
  };

  facebook: any = {
    authorize: this.facebookAuthorize.bind(this),
    getAppSubscribedPages: this.facebookGetAppSubscribedPages.bind(this),
    getOAuthUrl: this.facebookGetOAuthUrl.bind(this),
    getUserPages: this.facebookGetUserPages.bind(this),
    subscribe: this.facebookSubscribe.bind(this),
  };

  facebookLeads: any = {
    authorize: this.facebookLeadsAuthorize.bind(this),
    getOAuthUrl: this.facebookLeadsGetOAuthUrl.bind(this),
    getForms: this.facebookLeadsGetForms.bind(this),
    getUserPages: this.facebookLeadsGetUserPages.bind(this),
    subscribe: this.facebookLeadsSubscribe.bind(this),
  };

  instagram: any = {
    authorize: this.instagramAuthorize.bind(this),
    getAppSubscribedPages: this.instagramGetAppSubscribedPages.bind(this),
    getOAuthUrl: this.instagramGetOAuthUrl.bind(this),
    getUserPages: this.instagramGetUserPages.bind(this),
    subscribe: this.instagramSubscribe.bind(this),
  };

  myTarget: any = {
    authorize: this.myTargetAuthorize.bind(this),
    getOAuthUrl: this.myTargetGetOAuthUrl.bind(this), // DEPRECATED
    getQuestions: this.myTargetGetQuestions.bind(this),
  };

  retailcrm: any = {
    getCustomerFields: this.retailcrmGetCustomerFields.bind(this),
    getOrderFields: this.retailcrmGetOrderFields.bind(this),
    getOrderStatuses: this.retailcrmGetOrderStatuses.bind(this),
  };

  telegram: any = {
    getPseudoIntegration: this.telegramGetPseudoIntegration.bind(this),
  };

  vk: any = {
    getAppSubscribedGroups: this.vkGetAppSubscribedGroups.bind(this),
  };

  whatsAppEdna: any = {
    getIntegrationByPhone: this.whatsAppEdnaGetIntegrationByPhone.bind(this),
  };

  // @ngInject
  constructor(
    private readonly $translate: any,
    private readonly APIRequest: any,
    private readonly caseStyleHelper: any,
    private readonly utilsModel: any,
  ) {}

  /**
   * Получает URL для OAuth авторизации в amoCRM
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   */
  amocrmGetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.AMOCRM}/oauth_url`, params).then(
      (response: any) => {
        // Получает успешный ответ от API и возвращает OAuth URL
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.oauthUrl || response.data.authUrl;
      },
    );
  }

  /**
   * Получение списка воронок и статусов amoCRM
   * @param id ID интеграции
   */
  amocrmGetPipelines(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.AMOCRM}/${id}/pipelines`, {}).then(
      (response: any) => {
        // Преобразует ключи объекта ответа API в стиль camelCase
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение списка чат-ботов, которые используют текущую интеграцию
   *
   * @param type Тип интеграции
   * @param id ID интеграции
   * @param currentApp Текущее приложение
   * @returns Promise
   */
  getChatBots(type: any, id: any, currentApp: any): any {
    const params: any = {
      app: currentApp.id,
    };

    return this.APIRequest('GET', `/integrations/${type}/${id}/chat_bots`, params).then((response: any) => {
      // Преобразование ключей ответа от сервера в camelCase
      this.caseStyleHelper.keysToCamelCase(response);
      return response.data;
    });
  }

  /**
   * Получение списка автосообщений, которые используют интеграцию
   *
   * @param type Тип интеграции
   * @param id ID интеграции
   * @param currentApp Текущее приложение
   * @returns Promise
   */
  getAutoMessages(type: any, id: any, currentApp: any): any {
    const params: any = {
      app: currentApp.id,
    };

    return this.APIRequest('GET', `/integrations/${type}/${id}/messages`, params).then((response: any) => {
      this.caseStyleHelper.keysToCamelCase(response);
      return response.data;
    });
  }

  /**
   * Получение токена Битрикс24, по входящему коду
   *
   * @param id ID интеграции
   * @param appId ID приложения
   * @param code Код, отправленный битриксом
   * @param csrfToken CSRF-токен
   */
  bitrix24Authorize(id: any, appId: any, code: any, csrfToken: any): any {
    let params: any = {
      app_id: appId,
      csrf_token: csrfToken,
      code: code,
    };

    return this.APIRequest('GET', `/hooks/${INTEGRATION_TYPES.BITRIX24}/${id}/code`, params).then((response: any) => {
      if (Object.keys(response.data).length === 0) {
        return Promise.reject();
      } else {
        let integration: any = response.data;
        this.parseIntegrationToInternalFormat(integration);

        return integration;
      }
    });
  }

  /**
   * Получение статусов СДЕЛКИ Битрикс24
   * @param id ID интеграции
   */
  bitrix24GetDealPipelines(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.BITRIX24}/${id}/deals/status`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение свойств СДЕЛКИ Битрикс24
   * @param id ID интеграции
   */
  bitrix24GetDealProps(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.BITRIX24}/${id}/deals/props`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение статусов ЛИДА Битрикс24
   * @param id ID интеграции
   */
  bitrix24GetLeadPipelines(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.BITRIX24}/${id}/leads/status`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение свойств ЛИДА Битрикс24
   * @param id ID интеграции
   */
  bitrix24GetLeadProps(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.BITRIX24}/${id}/leads/props`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение ссылки для авторизации через OAuth для Битрикс24
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   *
   * @returns Promise
   */
  bitrix24GetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {
      ignoreLoadingBar: true,
    };

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.BITRIX24}/oauth_url`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.authUrl;
      },
    );
  }

  /**
   * Создание интеграции
   *
   * @param appId ID приложения
   * @param integration Интеграция
   * @return Promise
   */
  create(appId: any, integration: any): any {
    integration = this.parseIntegrationToExternalFormat(integration);

    let params: any = {
      app: appId,
      type: integration.type,
      active: integration.active,
      settings: integration.settings,
      name: integration.name,
    };

    return this.APIRequest('POST', '/integrations', params).then((response: any) => {
      let integrationResult = response.data;

      this.parseIntegrationToInternalFormat(integrationResult);

      return integrationResult;
    });
  }

  /**
   * Сохранение авторизационных данных Facebook
   *
   * @param id ID интеграции
   * @param appId ID приложения
   * @param code Код oAuth, который прислал Facebook
   * @return Promise
   */
  facebookAuthorize(id: any, appId: any, code: any): any {
    let params: any = {
      app_id: appId,
      code: code,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK}/${id}/oauth`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение всех подписанных FB страниц аппа (со всех интеграций FB)
   *
   * @param appId
   * @return Promise
   */
  facebookGetAppSubscribedPages(appId: any): any {
    let params: any = {
      app: appId,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK}/subscribed_pages`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение ссылки для авторизации через oAuth
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   *
   * @return Promise
   */
  facebookGetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK}/oauth_url`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.authUrl;
      },
    );
  }

  /**
   * Получение страниц пользователя (ВНИМАНИЕ! Этот метод полностью запрашивает)
   *
   * @param id ID интеграции
   * @return {*}
   */
  facebookGetUserPages(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK}/${id}/user_pages`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.pages;
      },
    );
  }

  facebookSubscribe(integration: any): any {
    let params: any = {
      pages: JSON.stringify(this.caseStyleHelper.keysToUnderscore(integration.settings.pages)),
    };

    return this.APIRequest(
      'POST',
      `/integrations/${INTEGRATION_TYPES.FACEBOOK}/${integration.id}/subscribe`,
      params,
    ).then((response: any) => {
      this.caseStyleHelper.keysToCamelCase(response);

      let pages = response.data.pages;

      // надо удалить лишние страницы, которые по каким-то причинам отсутствуют в Facebook (удалены, на них отобраны права и т.п.)
      for (let id in integration.settings.pages) {
        if (integration.settings.pages.hasOwnProperty(id) && pages[id] === undefined) {
          delete integration.settings.pages[id];
        }
      }

      Object.assign(integration.settings.pages, pages);

      return integration.settings.pages;
    });
  }

  /**
   * Сохранение авторизационных данных Facebook Leads
   *
   * @param id ID интеграции
   * @param appId ID приложения
   * @param code Код oAuth, который прислал Facebook
   * @return Promise
   */
  facebookLeadsAuthorize(id: any, appId: any, code: any): any {
    let params: any = {
      app_id: appId,
      code: code,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK_LEADS}/${id}/oauth`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение ссылки для авторизации через oAuth
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   *
   * @return Promise
   */
  facebookLeadsGetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK_LEADS}/oauth_url`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.authUrl;
      },
    );
  }

  facebookLeadsGetForms(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK_LEADS}/${id}/forms`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.forms;
      },
    );
  }

  /**
   * Получение страниц пользователя (ВНИМАНИЕ! Этот метод полностью запрашивает)
   *
   * @param id ID интеграции
   * @return {*}
   */
  facebookLeadsGetUserPages(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.FACEBOOK_LEADS}/${id}/user_pages`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.pages;
      },
    );
  }

  facebookLeadsSubscribe(integration: any): any {
    let params: any = {
      pages: JSON.stringify(this.caseStyleHelper.keysToUnderscore(integration.settings.pages)),
    };

    return this.APIRequest(
      'POST',
      `/integrations/${INTEGRATION_TYPES.FACEBOOK_LEADS}/${integration.id}/subscribe`,
      params,
    ).then((response: any) => {
      this.caseStyleHelper.keysToCamelCase(response);

      let pages = response.data.pages;

      // надо удалить лишние страницы, которые по каким-то причинам отсутствуют в Facebook (удалены, на них отобраны права и т.п.)
      for (let id in integration.settings.pages) {
        if (integration.settings.pages.hasOwnProperty(id) && pages[id] === undefined) {
          delete integration.settings.pages[id];
        }
      }

      Object.assign(integration.settings.pages, pages);

      return integration.settings.pages;
    });
  }

  /**
   * Сохранение авторизационных данных Facebook
   *
   * @param id ID интеграции
   * @param appId ID приложения
   * @param code Код oAuth, который прислал Facebook
   * @return Promise
   */
  instagramAuthorize(id: any, appId: any, code: any): any {
    let params: any = {
      app_id: appId,
      code: code,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.INSTAGRAM}/${id}/oauth`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение всех подписанных Instagram страниц аппа (со всех интеграций Instagram)
   *
   * @param appId
   * @return Promise
   */
  instagramGetAppSubscribedPages(appId: any): any {
    let params: any = {
      app: appId,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.INSTAGRAM}/subscribed_pages`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение ссылки для авторизации через oAuth
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   * @return Promise
   */
  instagramGetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.INSTAGRAM}/oauth_url`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.authUrl;
      },
    );
  }

  /**
   * Получение страниц пользователя (ВНИМАНИЕ! Этот метод полностью запрашивает)
   *
   * @param id ID интеграции
   * @return {*}
   */
  instagramGetUserPages(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.INSTAGRAM}/${id}/user_pages`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data.pages;
      },
    );
  }

  instagramSubscribe(integration: any): any {
    let params: any = {
      pages: JSON.stringify(this.caseStyleHelper.keysToUnderscore(integration.settings.pages)),
    };

    return this.APIRequest(
      'POST',
      `/integrations/${INTEGRATION_TYPES.INSTAGRAM}/${integration.id}/subscribe`,
      params,
    ).then((response: any) => {
      this.caseStyleHelper.keysToCamelCase(response);

      let pages = response.data.pages;

      // надо удалить лишние страницы, которые по каким-то причинам отсутствуют в Facebook (удалены, на них отобраны права и т.п.)
      for (let id in integration.settings.pages) {
        if (integration.settings.pages.hasOwnProperty(id) && typeof pages[id] === 'undefined') {
          delete integration.settings.pages[id];
        }
      }

      Object.assign(integration.settings.pages, pages);

      return integration.settings.pages;
    });
  }

  /**
   * Получение интеграции по ID
   *
   * @param id ID интеграции
   * @param type Тип интеграции
   * @return Promise
   */
  get(id: any, type: any): any {
    return this.APIRequest('GET', `/integrations/${type}/${id}`, {}).then((response: any) => {
      let integration = response.data;

      this.parseIntegrationToInternalFormat(integration);

      return integration;
    });
  }

  /**
   * Получение списка настроенных интеграций по типу интеграции
   *
   * @param appId ID приложения
   * @param type Тип интеграции
   * @param parseToInternal нужно ли привести тип во внутренний. В некоторых ситуациях делать этого не надо
   *
   * @returns Promise
   */
  getByType(appId: any, type: any, parseToInternal: any = true): any {
    let params: any = {
      app: appId,
    };

    return this.APIRequest('GET', `/integrations/${type}`, params).then((response: any) => {
      let integrations = response.data;

      if (parseToInternal) {
        for (let i = 0; i < integrations.length; i++) {
          this.parseIntegrationToInternalFormat(integrations[i]);
        }
      }

      return integrations;
    });
  }

  getCalendlyEventList(integrationId: any, status = true): Observable<IntegrationExternal[]> {
    const params = {
      active: status,
    };

    return this.APIRequest('GET', `/integrations/calendly/${integrationId}/list_events`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);
        return response.data;
      },
    );
  }

  /**
   * Получение интеграции по-умолчанию с типом type
   *
   * @param type Тип интеграции
   * @return Object
   */
  getDefaultIntegration(type: any): any {
    let defaultIntegration: any = Object.assign({}, this.DEFAULT_INTEGRATION);

    defaultIntegration.name = this.$translate.instant('models.integration.defaultValues.name', {
      integrationTypeName: this.$translate.instant(`models.integration.types.${type}.name`),
    });

    defaultIntegration.type = type;

    if (INTEGRATIONS_WARNINGS_BY_TYPE.hasOwnProperty(type)) {
      defaultIntegration.warnings = INTEGRATIONS_WARNINGS_BY_TYPE[type];
    }

    return defaultIntegration;
  }

  /**
   * Получение списка получателей
   *
   * @param appId - Id-приложения
   *
   * @return Promise
   */
  getEmailRecipients(appId: any): any {
    return this.APIRequest('GET', `/apps/${appId}/emailrecipients/`, {}).then((response: any) => {
      return response.data;
    });
  }

  /**
   * Получение списка настроенных интеграций для приложения
   * NOTE: Подразумевается, что в качестве types передаётся список интеграций, к которым у пользователя есть доступ
   *  То есть они должны доставаться из фичагалок. Но в будущем они должны передаваться не параметром, а просто получаться в этой функции
   *
   * @param appId ID приложения
   * @param types Типы интеграций
   * @return Promise
   */
  getList(appId: any, types: any): any {
    let promises: any[] = [];

    for (const integrationType of types) {
      promises.push(this.getByType(appId, integrationType));
    }

    return Promise.all(promises).then((integrations: any[]) => integrations.flat());
  }

  /**
   * Сохранение авторизационных данных myTarget
   *
   * @param id ID интеграции
   * @param appId ID приложения
   * @param code Код oAuth, который прислал Facebook
   * @return Promise
   */
  myTargetAuthorize(id: any, appId: any, code: any): any {
    let params: any = {
      app_id: appId,
      code: code,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.MYTARGET}/${id}/oauth`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data;
      },
    );
  }

  /**
   * Получение ссылки для авторизации через oAuth
   *
   * @param appId - ID текущего приложения
   * @param integrationId - ID интеграции
   *
   * @return Promise
   */
  myTargetGetOAuthUrl(appId: any, integrationId: any = null): any {
    let params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.MYTARGET}/oauth_url`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data.authUrl;
      },
    );
  }

  /**
   * Список стандартных вопросов
   * @param id ID интеграции
   * @returns Promise
   */
  myTargetGetQuestions(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.MYTARGET}/${id}/questions`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data.questions;
      },
    );
  }

  /**
   * Удаление интеграции
   *
   * @param id ID интеграции
   * @param type Тип интеграции
   * @return Promise
   */
  remove(id: any, type: any): any {
    return this.APIRequest('DELETE', `/integrations/${type}/${id}`, {});
  }

  /**
   * Получение полей покупателя из retailCRM
   *
   * @param id
   */
  retailcrmGetCustomerFields(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.RETAILCRM}/${id}/customer/fields`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data;
      },
    );
  }

  /**
   * Получение полей заказа из retailCRM
   *
   * @param id
   */
  retailcrmGetOrderFields(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.RETAILCRM}/${id}/order/fields`, {}).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data;
      },
    );
  }

  /**
   * Получение справочника статусов заказа из retailCRM
   *
   * @param id
   */
  retailcrmGetOrderStatuses(id: any): any {
    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.RETAILCRM}/${id}/order/statuses`, {}).then(
      (response: any) => {
        // this.caseStyleHelper.keysToCamelCase(response);

        return response.data;
      },
    );
  }

  /**
   * Сохранение интеграции
   *
   * @param appId ID приложения
   * @param integration Интеграция
   * @return Promise
   */
  save(appId: any, integration: any): any {
    let integrationToSave: any = this.parseIntegrationToExternalFormat(integration);

    let params: any = {
      app: appId,
      active: integrationToSave.active,
      settings: integrationToSave.settings,
      name: integrationToSave.name,
    };

    return this.APIRequest('PUT', `/integrations/${integrationToSave.type}/${integration.id}`, params).then(
      (response: any) => {
        let updatedIntegration: any = response.data;

        this.parseIntegrationToInternalFormat(updatedIntegration);

        Object.assign(integration, updatedIntegration);

        return integration;
      },
    );
  }

  /**
   * Получение псеводинтеграции с Telegram.
   * Необходимо для селекта с выбором интеграции в списке ботов.
   */
  telegramGetPseudoIntegration(): any {
    return {
      id: null,
      name: this.$translate.instant('models.integration.telegramIntegration.pseudoIntegrationName'),
    };
  }

  /**
   * Изменение состояния интеграции
   *
   * @param integration Интеграция
   * @return Promise
   */
  toggleState(integration: any): any {
    let params: any = {
      active: !integration.active,
      ignoreErrors: true,
    };

    return this.APIRequest('PATCH', `/integrations/${integration.type}/${integration.id}`, params);
  }

  /**
   * Получение всех сообществ ВК аппа, на которые он подписан (со всех интеграций ВК)
   *
   * @param appId
   * @return Promise
   */
  vkGetAppSubscribedGroups(appId: any): any {
    const params: any = {
      app: appId,
    };

    return this.APIRequest('GET', `/integrations/${INTEGRATION_TYPES.VK}/subscribed_groups`, params).then(
      (response: any) => {
        this.caseStyleHelper.keysToCamelCase(response);

        return response.data;
      },
    );
  }

  /**
   * Получение списка интеграций WhatsApp, которые настроены на текущий номер телефона
   *
   * @param appId ID приложения
   * @param phone Телефон, на который настроена интеграция
   * @returns Promise
   */
  whatsAppEdnaGetIntegrationByPhone(appId: any, phone: any): any {
    const params: any = {
      app: appId,
    };

    return this.APIRequest('GET', `/whatsapp/edna/${phone}`, params).then((response: any) => {
      this.caseStyleHelper.keysToCamelCase(response);

      return response.data;
    });
  }

  /**
   * Парсинг интеграции, пришедшей с сервера
   *
   * @param integration
   */
  parseIntegrationToInternalFormat(integration: any): void {
    // если интеграции запрашивает оператор (а ему нужны интеграции с зумом и календли), то ему не приходят settings
    if (!integration.settings) {
      return;
    }

    const integrationBeforeSwitchingCase = cloneDeep(integration);
    this.caseStyleHelper.keysToCamelCase(integration, ['settings']);
    integration.created = moment(integration.created * 1000);

    // note: костыли специально для амо, битрикса и всяких интеграций, у которых ключами объектов могут быть свойства (например, $name)
    switch (integration.type) {
      case INTEGRATION_TYPES.AMOCRM:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, [
          'amocrm_leads_props_mapping',
          'amocrm_props_mapping',
          'event',
          'event_props',
          'leads_props_mapping',
          'props_mapping',
          'standard_props',
        ]);
        break;
      case INTEGRATION_TYPES.BITRIX24:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, [
          'bitrix24_deals_props_mapping',
          'bitrix24_props_mapping',
          'event',
          'event_props',
          'props_mapping',
          'standard_props',
        ]);
        if (integration.settings.bitrix24Expires) {
          integration.settings.bitrix24Expires = moment(integration.settings.bitrix24Expires * 1000);
        }
        break;
      case INTEGRATION_TYPES.FACEBOOK_LEADS:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, ['mapping']);
        break;
      case INTEGRATION_TYPES.MYTARGET:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, [
          'questions',
          'standard_questions',
        ]);
        if (integration.settings.expirationDate) {
          integration.settings.expirationDate = moment(integration.settings.expirationDate * 1000);
        }
        break;
      case INTEGRATION_TYPES.RETAILCRM:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, [
          'retailcrm_orders_props_mapping',
          'retailcrm_props_mapping',
          'event',
          'event_props',
          'props_mapping',
          'standard_props',
        ]);
        break;
      case INTEGRATION_TYPES.SENDSAY:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, ['props_mapping']);
        break;
      case INTEGRATION_TYPES.ZAPIER:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings, ['webhooks']);
        break;
      default:
        integration.settings = this.caseStyleHelper.keysToCamelCase(integration.settings);
        break;
    }

    let errors = [];
    if ([INTEGRATION_TYPES.OMNIDESK, INTEGRATION_TYPES.USEDESK].includes(integration.type)) {
      if (integration.settings.status === 'error') {
        errors.push('statusError');
      }
    }

    integration.errors = errors;
    if (INTEGRATIONS_WARNINGS_BY_TYPE.hasOwnProperty(integration.type)) {
      integration.warnings = INTEGRATIONS_WARNINGS_BY_TYPE[integration.type];
    }
    if (integration.settings && integration.settings.lastCompletedStep) {
      integration.settings.lastCompletedStep = parseInt(integration.settings.lastCompletedStep, 10);
    }
  }

  /**
   * Парсинг интеграции для отправки на сервер
   *
   * @param integration
   */
  parseIntegrationToExternalFormat(integration: any): any {
    let integrationCopy = angular.copy(integration);

    const integrationBeforeSwitchingCase = cloneDeep(integrationCopy);

    delete integrationCopy.errors;

    // note: костыли специально для амо, битрикса и всяких интеграций, у которых ключами объектов могут быть свойства (например, $name)
    // note: для ZAPIER не нужно - не сохраняется на клиентской стороне
    switch (integration.type) {
      case INTEGRATION_TYPES.AMOCRM:
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, [
            'amocrmLeadsPropsMapping',
            'amocrmPropsMapping',
            'event',
            'eventProps',
            'leadsPropsMapping',
            'propsMapping',
            'standardProps',
          ]),
        );
        break;
      case INTEGRATION_TYPES.BITRIX24:
        if (integrationCopy.settings.bitrix24Expires) {
          integrationCopy.settings.bitrix24Expires = integrationCopy.settings.bitrix24Expires.unix();
        }
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, [
            'bitrix24DealsPropsMapping',
            'bitrix24PropsMapping',
            'event',
            'eventProps',
            'propsMapping',
            'standardProps',
          ]),
        );
        break;
      case INTEGRATION_TYPES.FACEBOOK_LEADS:
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, ['mapping']),
        );
        break;
      case INTEGRATION_TYPES.MYTARGET:
        if (integrationCopy.settings.expirationDate) {
          integrationCopy.settings.expirationDate = integrationCopy.settings.expirationDate.unix();
        }
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, ['questions', 'standardQuestions']),
        );
        break;
      case INTEGRATION_TYPES.RETAILCRM:
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, [
            'retailcrmOrdersPropsMapping',
            'retailcrmPropsMapping',
            'event',
            'eventProps',
            'propsMapping',
            'standardProps',
          ]),
        );
        break;
      case INTEGRATION_TYPES.SENDSAY:
        integrationCopy.settings = JSON.stringify(
          this.caseStyleHelper.keysToUnderscore(integrationCopy.settings, ['propsMapping']),
        );
        break;
      case INTEGRATION_TYPES.ZENDESK:
        integrationCopy.settings = this.caseStyleHelper.keysToUnderscore(integrationCopy.settings);
        break;
      case INTEGRATION_TYPES.INSTAGRAM:
        integrationCopy.settings = JSON.stringify(this.caseStyleHelper.keysToUnderscore(integrationCopy.settings));
        break;
      default:
        integrationCopy.settings = JSON.stringify(this.caseStyleHelper.keysToUnderscore(integrationCopy.settings));
        break;
    }

    return integrationCopy;
  }
}
