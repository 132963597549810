import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

type Form = {
  repeatDelayEnabled: FormControl<boolean>;
  repeatDelay: FormControl<number>;
};

const REPEAT_DELAY_DELIMITER = 24 * 60 * 60;

@Component({
  selector: 'cq-message-schedule-repeat-delay',
  templateUrl: './message-schedule-repeat-delay.component.html',
  styleUrls: ['./message-schedule-repeat-delay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class MessageScheduleRepeatDelayComponent {
  @Input({ required: true })
  set repeatDelay(value: number | null) {
    if (value === null) {
      return;
    }
    this.form.setValue(
      {
        repeatDelayEnabled: true,
        repeatDelay: value / REPEAT_DELAY_DELIMITER,
      },
      { emitEvent: false },
    );
  }

  readonly MAX_DAYS = 30;

  readonly form = this.fb.group<Form>({
    repeatDelayEnabled: this.fb.control(false, { nonNullable: true }),
    repeatDelay: this.fb.control(10, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(1), Validators.max(this.MAX_DAYS)],
    }),
  });

  @Output()
  repeatDelayChange: Observable<number | null> = this.form.valueChanges.pipe(
    filter(() => this.form.valid),
    map(() => {
      const { repeatDelayEnabled, repeatDelay } = this.form.getRawValue();
      if (!repeatDelayEnabled) {
        return null;
      }
      return repeatDelay * REPEAT_DELAY_DELIMITER;
    }),
  );

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.form.markAllAsTouched();
    this.formSubmitSubject.next();
    return this.form.status !== 'PENDING'
      ? Promise.resolve(this.form.valid)
      : firstValueFrom(
          this.form.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.form.valid),
          ),
        );
  });

  constructor(
    private readonly fb: FormBuilder,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
  ) {}
}
