<ng-container *transloco="let t; read: 'scheduledAutoMessageTableComponent'">
  <table class="trigger-messages-table table table-align-middle table-sticky-header">
    <thead>
      <tr>
        <th>{{ t('heading.name') }}</th>
        <th>{{ t('heading.status') }}</th>
        <th>{{ t('heading.lastRun') }}</th>
        <th>{{ t('heading.runsCount') }}</th>
        <th>{{ t('heading.created') }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let message of messages">
        <!--НАЗВАНИЕ-->
        <td>
          <a
            class="link-unstyled d-block mb-5"
            uiSref="app.content.scheduledMessages.edit"
            [uiParams]="{ messageId: message.id }"
          >
            <i
              class="cqi-sm"
              [ngClass]="MESSAGE_PART_TYPE_ICON_CLASSES[message.type]"
              [title]="'models.message.messagePartTypes.' + message.type | transloco"
            ></i>
            <span
              class="text-break"
              [title]="message.name"
              >{{ message.name }}</span
            >
          </a>
          <!--div
            *ngIf="isMessageHaveDeletedTags(message.filters.filters.tags)"
            class="margin-bottom-10"
          >
            <i class="cqi-sm cqi-triangle-exclamation text-danger"></i>
            <span class="text-muted">{{ 'autoMessages.table.table.body.name.messageHasDeletedTag' | transloco }}</span>
          </div-->
          <div
            class="d-inline small text-muted"
            [ngClass]="{ 'dashed cursor-pointer': isMessageTriggersPopoverEnabled(message) }"
            [ngbPopover]="triggersPopover"
            [popoverContext]="{ message: message }"
            [disablePopover]="!isMessageTriggersPopoverEnabled(message)"
            popoverClass="triggers-popover"
            placement="bottom-left"
          >
            <span>{{ 'autoMessages.table.table.body.name.condition' | transloco }}</span>

            <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.DAILY">{{
              'messageScheduleSettingsComponent.preview.everyday.text'
                | transloco: { time: message.schedulerSettings.time }
            }}</span>
            <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.WEEKLY">{{
              'messageScheduleSettingsComponent.preview.weekDays.' + translationNumber(message)
                | transloco
                  : {
                      time: message.schedulerSettings.time,
                      weekDays: weekDaysAsText(message)
                    }
            }}</span>
            <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.MONTHLY">{{
              'messageScheduleSettingsComponent.preview.monthDays.text'
                | transloco
                  : {
                      time: message.schedulerSettings.time,
                      monthDays: monthDaysAsText(message)
                    }
            }}</span>
          </div>
          <div class="flex margin-between-cols-10">
            <!--УВЕДОМЛЕНИЕ О БЛОКИРОВАНИИ EMAIL РАССЫЛКИ-->
            <span
              *ngIf="isEmailBlockedDueToSuspiciousActivity(message)"
              class="label label-danger"
              [ngbTooltip]="'autoMessages.table.table.body.name.blockedMessageTooltip.suspiciousActivity' | transloco"
              container="body"
              >{{ 'autoMessages.table.table.body.name.messageStatus.blocked' | transloco }}</span
            >
          </div>
        </td>

        <td>
          <!--СТАТУС-->
          <span
            *ngIf="message.active"
            class="message-status label label-light-success"
          >
            <span class="message-status-text">{{
              'autoMessages.table.table.body.name.messageStatus.active' | transloco
            }}</span>
          </span>
          <span
            *ngIf="!message.active"
            class="message-status label label-light-danger"
          >
            <span class="message-status-text">{{
              'autoMessages.table.table.body.name.messageStatus.paused' | transloco
            }}</span>
          </span>
        </td>

        <!-- ДАТА ПОСЛЕДНЕГО ЗАПУСКА-->
        <td>
          <div *ngIf="message.stats?.lastSendingTime">{{ message.stats?.lastSendingTime | amDateFormat: 'LLL' }}</div>
          <div
            *ngIf="!message.stats?.lastSendingTime"
            class="text-muted"
          >
            {{ 'autoMessages.table.table.body.notSended' | transloco }}
          </div>
        </td>

        <!--КОЛИЧЕСТВО ЗАПУСКОВ-->
        <td>
          <h4 class="no-margin-bottom">{{ message.stats?.sendingCount }}</h4>
        </td>

        <!--ДАТА СОЗДАНИЯ-->
        <td>
          <div>{{ message.created | amDateFormat: 'LLL' }}</div>
        </td>

        <td class="shrink-by-content">
          <a
            class="btn btn-borderless-primary"
            (click)="trackClickOnOpenStatistics()"
            uiSref="app.content.messagesAjs.manual.table"
            [uiParams]="{ scheduledMessageId: message.id }"
            title="{{ 'autoMessages.table.table.body.statisticsButton.title' | transloco }}"
          >
            <i class="btn-icon cqi-sm cqi-chart-bar"></i>
          </a>
          <div
            ngbDropdown
            class="d-inline-block"
            dropdownClass="dropdown"
          >
            <button
              type="button"
              class="btn btn-borderless-primary"
              ngbDropdownToggle
              (click)="onClickDropdown()"
            >
              <i class="btn-icon cqi-sm cqi-ellipsis"></i>
            </button>
            <div
              ngbDropdownMenu
              class="dropdown-menu-right"
            >
              <!--Активировать сообщение. Для телеги-->
              <ng-container *ngIf="message.type === 'telegram'">
                <ng-container
                  *ngTemplateOutlet="activateTmp; context: { $implicit: accessToTelegramAutoMessages.hasAccess }"
                >
                </ng-container>
              </ng-container>
              <!--Активировать сообщение. Для НЕ телеги-->
              <ng-container *ngIf="message.type !== 'telegram'">
                <ng-container
                  *ngTemplateOutlet="activateTmp; context: { $implicit: accessToSelectedAutoMessages.hasAccess }"
                >
                </ng-container>
              </ng-container>

              <!--Активировать сообщение. Шаблон-->
              <ng-template
                #activateTmp
                let-hasAccessToActivate
              >
                <ng-container *ngIf="!message.active">
                  <!--Без ограничений-->
                  <button
                    *ngIf="hasAccessToActivate; else activeMessageWithoutRestrictions"
                    class="dropdown-item"
                    href
                    type="button"
                    (click)="clickActivateButton.emit(message)"
                  >
                    <i class="dropdown-item-icon-left cqi-sm cqi-play-o"></i>
                    <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.activated' | transloco }}</span>
                  </button>
                  <!--С ограничением-->
                  <ng-template #activeMessageWithoutRestrictions>
                    <button
                      class="dropdown-item"
                      type="button"
                      (click)="openSomeAutoMessageBillingModal(message)"
                    >
                      <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                      <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.activated' | transloco }}</span>
                    </button>
                  </ng-template>
                </ng-container>
              </ng-template>
              <!--Приостановить сообщение-->
              <button
                *ngIf="message.active"
                class="dropdown-item"
                type="button"
                (click)="clickPauseButton.emit(message)"
              >
                <i class="dropdown-item-icon-left cqi-sm cqi-pause"></i>
                <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.pause' | transloco }}</span>
              </button>
              <!--Архивировать сообщение-->
              <!--button
                *ngIf="message.directory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
                class="dropdown-item"
                type="button"
                (click)="clickArchiveButton.emit(message)"
              >
                <i class="dropdown-item-icon-left cqi-sm cqi-archive"></i>
                <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.archive' | transloco }}</span>
              </button-->
              <!--Восстановить сообщение-->
              <!--button
                *ngIf="message.directory.name === SYSTEM_DIRECTORIES.ARCHIVE"
                class="dropdown-item"
                type="button"
                (click)="clickReestablishButton.emit(message)"
              >
                <i class="dropdown-item-icon-left cqi-sm cqi-archive"></i>
                <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.reestablish' | transloco }}</span>
              </button-->
              <!--Создать копию-->
              <button
                class="dropdown-item"
                type="button"
                (click)="clickCopyButton.emit(message)"
              >
                <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
                <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.createCopy' | transloco }}</span>
              </button>
              <!--Переименовать-->
              <button
                class="dropdown-item"
                type="button"
                (click)="clickRenameButton.emit(message)"
              >
                <i class="dropdown-item-icon-left cqi-sm cqi-pencil"></i>
                <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.rename' | transloco }}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-template
    #triggersPopover
    let-message="message"
  >
    <div class="padding-top-5 padding-bottom-5">
      <div class="font-weight-bold margin-bottom-5">
        {{ 'autoMessages.table.triggersPopover.heading' | transloco }}
      </div>

      <div class="d-inline">
        <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.DAILY">{{
          'messageScheduleSettingsComponent.preview.everyday.text' | transloco: { time: message.schedulerSettings.time }
        }}</span>
        <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.WEEKLY">{{
          'messageScheduleSettingsComponent.preview.weekDays.' + translationNumber(message)
            | transloco
              : {
                  time: message.schedulerSettings.time,
                  weekDays: weekDaysAsText(message)
                }
        }}</span>
        <span *ngIf="message.schedulerSettings.type === MESSAGE_SCHEDULE_TYPE.MONTHLY">{{
          'messageScheduleSettingsComponent.preview.monthDays.text'
            | transloco
              : {
                  time: message.schedulerSettings.time,
                  monthDays: monthDaysAsText(message)
                }
        }}</span>
      </div>

      <hr />
      <div *ngIf="isMessageHaveFilters(message)">
        <div class="font-weight-bold margin-bottom-5">
          {{ 'autoMessages.table.triggersPopover.dispatchUrl.heading' | transloco }}
        </div>
      </div>

      <!-- Фильтры -->
      <div *ngIf="isMessageHaveFilters(message)">
        <div>
          <span *ngIf="message.filters.type === 'and'">{{
            'autoMessages.table.triggersPopover.satisfies.allFilters' | transloco
          }}</span>
          <span *ngIf="message.filters.type === 'or'">{{
            'autoMessages.table.triggersPopover.satisfies.anyOfFilters' | transloco
          }}</span>
        </div>

        <div *ngFor="let filter of message.filters.filters.props; first as first">
          <div *ngIf="first">
            {{ 'autoMessages.table.triggersPopover.properties' | transloco }}
          </div>
          <span>{{ filter.userProp.prettyName }}</span>
          <cq-message-filters
            class="d-inline"
            [filter]="filter"
          ></cq-message-filters>
        </div>

        <div *ngFor="let filter of message.filters.filters.events; first as first">
          <div *ngIf="first">
            {{ 'autoMessages.table.triggersPopover.events' | transloco }}
          </div>
          <span>{{ filter.prettyName }}</span>
          <cq-message-filters
            class="d-inline"
            [filter]="filter"
          ></cq-message-filters>
        </div>

        <span
          class="no-space"
          *ngFor="let filter of message.filters.filters.tags; first as first; last as last"
        >
          <div
            *ngIf="first"
            class="mt-10"
          >
            {{ 'autoMessages.table.triggersPopover.tags' | transloco }}
          </div>
          <cq-user-tag [tag]="filter.tag"></cq-user-tag>
          <span *ngIf="!last">,&nbsp;</span>
        </span>
      </div>

      <div
        *ngIf="message.maxUsersCount !== null"
        class="mt-10"
      >
        <div>
          {{ 'scheduledMessageEditor.messageAudience.preview.maxUsersCount' | transloco }}
        </div>
        <div>
          {{ 'scheduledMessageEditor.messageAudience.preview.people' | transloco: { value: message.maxUsersCount } }}
        </div>
      </div>
      <div
        *ngIf="message.repeatDelay !== null"
        class="mt-10"
      >
        <div>
          {{ 'scheduledMessageEditor.messageAudience.preview.repeatDelay' | transloco }}
        </div>
        <div>
          {{
            'scheduledMessageEditor.messageAudience.preview.days' | transloco: { value: getRepeatDelayDays(message) }
          }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
