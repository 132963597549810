<ng-container *transloco="let t; read: 'editorFormatUploadFileComponent'">
  <div
    #fileTooltipInstance="ngbTooltip"
    [ngbTooltip]="t('tooltip.uploadFile')"
    container="body"
    triggers="hover:blur"
  >
    <button
      #filePopoverInstance="ngbPopover"
      type="button"
      class="btn btn-borderless-primary rounded-0"
      [ngbPopover]="filePopover"
      popoverClass="file-popover"
      container="body"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
      (click)="onClickFilePopoverButton()"
    >
      <i class="cqi-sm cqi-page"></i>
    </button>
  </div>

  <ng-template #filePopover>
    <ngx-dropzone
      class="padding-30 card text-center file-upload cursor-pointer no-margin"
      [accept]="EDITOR_FORMAT_FILE_TYPES_DEFAULT"
      [multiple]="false"
      [maxFileSize]="EDITOR_FORMAT_FILE_MAX_SIZE"
      (change)="onFileChange($event)"
    >
      <ng-container *ngIf="!isRequestPerform">
        <ngx-dropzone-label class="d-flex flex-column justify-content-center align-items-center h-100">
          <div>
            <b>{{ t('dropzone.title') }}</b>
          </div>
          <div>{{ t('dropzone.description') }}</div>
        </ngx-dropzone-label>
      </ng-container>

      <ng-container *ngIf="isRequestPerform">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
          <i
            class="cqi-sm cqi-spinner d-inline-block spin"
            style="font-size: 60px"
          ></i>
        </div>
      </ng-container>
    </ngx-dropzone>
  </ng-template>

  <div #selectionFilePopoverContainer>
    <div
      #selectionFilePopoverInstance="ngbPopover"
      [ngbPopover]="selectionFilePopover"
      popoverClass="file-popover"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
    ></div>
    <ng-template #selectionFilePopover>
      <form (ngSubmit)="onSubmitForm($event)">
        <ul
          ngbNav
          #selectionFilePopoverNav="ngbNav"
          [(activeId)]="activeTab"
          class="nav nav-tabs"
        >
          <li [ngbNavItem]="1">
            <button
              ngbNavLink
              type="button"
              (click)="activeTab = 1"
              [ngbTooltip]="t('tooltip.editFileLink')"
            >
              <i class="cqi-sm cqi-pencil"></i>
            </button>
            <ng-template ngbNavContent>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="fileFormGroup.controls.href"
                  [placeholder]="t('input.href')"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="fileFormGroup.controls.text"
                  [placeholder]="t('input.text')"
                />
              </div>

              <div class="custom-control custom-checkbox">
                <input
                  id="selectionFilePopoverCheckbox"
                  class="custom-control-input"
                  type="checkbox"
                  [formControl]="fileFormGroup.controls.isBlank"
                />
                <label
                  class="custom-control-label"
                  for="selectionFilePopoverCheckbox"
                  >{{ t('input.isBlank') }}
                </label>
              </div>

              <div class="d-flex justify-end">
                <button
                  class="btn btn-primary mt-15"
                  type="submit"
                  [disabled]="fileFormGroup.invalid"
                >
                  {{ t('button.update') }}
                </button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button
              ngbNavLink
              type="button"
              (click)="onClickOpenFileButton()"
              [ngbTooltip]="t('tooltip.openFileLink')"
            >
              <i class="cqi-sm cqi-external-link"></i>
            </button>
          </li>
          <li [ngbNavItem]="3">
            <button
              ngbNavLink
              type="button"
              (click)="onClickDeleteFileButton()"
              [ngbTooltip]="t('tooltip.unlink')"
            >
              <i class="cqi-sm cqi-unlink"></i>
            </button>
            <ng-template ngbNavContent></ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="selectionFilePopoverNav"></div>
      </form>
    </ng-template>
  </div>
</ng-container>
