import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHeaderTemplateDirective, NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";
import { TuiLet } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { AddCrawledPagesModalComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/add-crawled-pages-modal/add-crawled-pages-modal.component';
import { AiDataActionModalService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataActionModalTextService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal-text-service/ai-data-action-modal-text.service';
import { AiDataSourceListComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-list.component';
import { AiDataSourceTableComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-table/ai-data-source-table.component';
import { AiDataSourceTableItemComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-table-item/ai-data-source-table-item.component';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { CategoryFilterComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/category-filter/category-filter.component';
import { SearchComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/search/search.component';
import { SourceFilterComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/source-filter/source-filter.component';
import { StartCrawlingProcessModalComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/start-crawling-process-modal/start-crawling-process-modal.component';
import { AutoMessageStatusFilterModule } from '@panel/app/pages/auto-messages/components/list/status-filter/auto-message-status-filter.module';
import { DowngradeAbilitiesModule } from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/downgrade-abilities/downgrade-abilities.module';
import { SharedModule } from '@panel/app/shared/shared.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { AiDataSettingsBannerComponent } from './ai-data-settings-banner/ai-data-settings-banner.component';
import { CrawlingStatusBannerComponent } from './crawling-status-banner/crawling-status-banner.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';

@NgModule({
  declarations: [
    AiDataSourceListComponent,
    AiDataSourceTableComponent,
    AiDataSourceTableItemComponent,
    StatusFilterComponent,
    SourceFilterComponent,
    CategoryFilterComponent,
    AddCrawledPagesModalComponent,
    SearchComponent,
    AiDataSettingsBannerComponent,
    StartCrawlingProcessModalComponent,
    CrawlingStatusBannerComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    UIRouterModule,
    FormsModule,
    LoaderModule,
    SharedModule,
    ReactiveFormsModule,
    MomentModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgOptimizedImage,
    AutoMessageStatusFilterModule,
    DowngradeAbilitiesModule,
    TuiLet,
    NgbNavModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgHeaderTemplateDirective,
  ],
  providers: [AiDataSourcesListStateService, AiDataActionModalService, AiDataActionModalTextService],
})
export class AiDataSourceListModule {}
