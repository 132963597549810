<ng-container *transloco="let t; read: 'triggerChainGeneralStatisticsTableItemComponent'">
  <!--Название сообщения-->
  <td>
    <i
      class="cqi-sm"
      [ngClass]="getMessageTypeIcon(chainStep.type)"
    ></i>
    <span>{{ chainStep.name }}</span>
  </td>
  <!--Всего отправлено	-->
  <td>{{ sendedStats }}</td>
  <!--Прочитали-->
  <td>
    <cq-progressbar
      *ngIf="chainStep.type !== MESSAGE_PART_TYPES.TELEGRAM; else emptyStat"
      [value]="readStats"
      [max]="sendedStats"
    >
      <ng-template #leftContent>
        <div class="fade statistic-percent">
          {{ getPercentStats(readStats) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ readStats }}</div>
      </ng-template>
    </cq-progressbar>
  </td>
  <!--Ответили-->
  <td>
    <cq-progressbar
      *ngIf="
        !(
          chainStep.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL ||
          chainStep.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG
        );
        else emptyStat
      "
      [value]="repliedStats"
      [max]="sendedStats"
    >
      <ng-template #leftContent>
        <div class="fade statistic-percent">
          {{ getPercentStats(repliedStats) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ repliedStats }}</div>
      </ng-template>
    </cq-progressbar>
  </td>
  <!--Клик по кнопке/ссылке-->
  <td>
    <cq-progressbar
      [value]="clickedStats"
      [max]="sendedStats"
      ><ng-template #leftContent>
        <div class="position-relative">
          <div class="fade statistic-percent">
            {{ getPercentStats(clickedStats) }}
          </div>
          <div class="position-absolute top-0 fade statistic-count">{{ clickedStats }}</div>
        </div>
        <i
          *ngIf="needToShowBlockPopUpsTipTooltip()"
          class="cqi-sm cqi-question-circle margin-left-10"
          [ngbTooltip]="t('blockPopupsTipTooltip')"
          container="body"
        ></i></ng-template
    ></cq-progressbar>
  </td>
  <!--Отписались-->
  <td>
    <cq-progressbar
      [value]="unsubscribedStats"
      [max]="sendedStats"
      ><ng-template #leftContent
        ><div class="fade statistic-percent">
          {{ getPercentStats(unsubscribedStats) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ unsubscribedStats }}</div></ng-template
      ></cq-progressbar
    >
  </td>
  <td>
    <a
      class="btn btn-borderless-primary"
      uiSref="app.content.triggerChains.statistics.detailed"
      [uiParams]="{ id: chainId, messageId: chainStep.id }"
    >
      <i class="cqi-sm cqi-chart-bar"></i>
    </a>
  </td>
  <ng-template #emptyStat>
    <span class="text-muted">–</span>
  </ng-template>
</ng-container>
