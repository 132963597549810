import { ui } from 'angular';

import { App } from '@http/app/app.model';
import { EmailDomain } from '@http/email-domain/email-domain.types';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';

/**
 * Сервис для вызова модального окна с карточкой пользователя
 */

export class UserCardModalService {
  /**
   * Инстанс модального окна с карточкой пользователя
   */
  userCardModalModalInstance: any;

  //@ngInject
  constructor(private $uibModal: ui.bootstrap.IModalService) {}

  /**
   * Скрытие модального окна с карточкой пользователя
   */
  public hideModal() {
    this.userCardModalModalInstance.close();
  }

  /**
   * Открытие модального окна карточкой пользователя
   */
  public showModal(
    billingInfo: any,
    currentApp: App,
    djangoUser: any,
    userId: string,
    telegramIntegrations: TelegramIntegration[],
    emailDomains?: EmailDomain[],
  ) {
    this.userCardModalModalInstance = this.$uibModal.open({
      component: 'cqUserCardModal',
      resolve: {
        modalWindowParams: () => {
          return {
            billingInfo: billingInfo,
            currentApp: currentApp,
            djangoUser: djangoUser,
            onRedirectConversationClick: true,
            userId: userId,
            telegramIntegrations,
            emailDomains,
          };
        },
      },
      size: 'lg',
      windowClass: 'user-card-modal',
    });

    this.userCardModalModalInstance.result.finally(() => {
      this.userCardModalModalInstance = null;
    });
  }
}
