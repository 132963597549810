<ng-container *transloco="let t">
  <hr
    *ngIf="index > 1"
    class="margin-bottom-20 no-margin-top"
  />

  <div
    *ngIf="showHeader"
    class="margin-bottom-10 flex align-items-center justify-space-between"
  >
    <div [class.font-weight-bold]="boldHeader">
      <ng-container *ngIf="isTargetAction; else someActions">
        <span>{{ index }}. {{ t('models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(action.type)) }}</span>
      </ng-container>
      <ng-template #someActions>
        <i
          class="cqi-sm margin-right-5"
          [ngClass]="CHAT_BOT_ACTIONS_TYPES_ICONS[action.type]"
        ></i>
        <span>{{ getTranslateForActionType(action.type) }}</span>
      </ng-template>
    </div>
    <ng-container *ngIf="action.type !== ACTION_TYPE.MEET">
      <ng-container *ngIf="showDeleteButtonOnly; else actionDropdown">
        <button
          class="btn btn-borderless-primary"
          (click)="callbacks.removeAction(action)"
          container="body"
          ngbTooltip="{{ t('chatBot.botActionContainer.actionsTooltips.remove') }}"
          placement="top"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-trash"></i>
        </button>
      </ng-container>
      <ng-template #actionDropdown>
        <div ngbDropdown>
          <button
            class="btn btn-borderless-primary"
            ngbDropdownToggle
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-ellipsis"></i>
          </button>
          <div
            class="dropdown-menu-right"
            ngbDropdownMenu
          >
            <button
              *ngIf="callbacks.isShowUpButton(action)"
              (click)="callbacks.moveActionUp(action)"
              class="dropdown-item"
              type="button"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-arrow-up"></i>
              <span>{{ t('chatBot.botActionContainer.actionActionsDropdown.up') }}</span>
            </button>
            <button
              *ngIf="callbacks.isShowDownButton(action)"
              (click)="callbacks.moveActionDown(action)"
              class="dropdown-item"
              type="button"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-arrow-down"></i>
              <span>{{ t('chatBot.botActionContainer.actionActionsDropdown.down') }}</span>
            </button>
            <button
              *ngIf="callbacks.isShowCopyButton(action)"
              (click)="callbacks.createCopy(action)"
              class="dropdown-item"
              type="button"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
              <span>{{ t('chatBot.botActionContainer.actionActionsDropdown.copy') }}</span>
            </button>
            <button
              *ngIf="!callbacks.isLastContentAction(action)"
              (click)="callbacks.removeAction(action)"
              class="dropdown-item"
              type="button"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
              <span>{{ t('chatBot.botActionContainer.actionActionsDropdown.remove') }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="action.type">
    <ng-container *ngSwitchCase="ACTION_TYPE.TEXT">
      <cq-branch-action-text
        [actionForm]="action.form"
        [userProps]="propertyOptions.userProps"
      ></cq-branch-action-text>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.FILE">
      <cq-branch-media-actions-editor [actionForm]="action.form"></cq-branch-media-actions-editor>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.VIDEO_NOTE">
      <cq-branch-media-actions-editor [actionForm]="action.form"></cq-branch-media-actions-editor>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.MEET">
      <cq-branch-action-meeting [actionForm]="$any(action.form)"></cq-branch-action-meeting>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.BUTTON">
      <cq-branch-action-button
        (branchCreate)="callbacks.createNewBranch($event, action)"
        (buttonCreate)="callbacks.createNewButton()"
        (toBranch)="callbacks.openBranch($event)"
        [chatBotType]="chatBotType"
        [chatBotId]="chatBotId"
        [actionForm]="$any(action.form)"
        [targetBranchOptions]="nextBranchOptions"
      ></cq-branch-action-button>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.BUTTONS_PROPERTY">
      <cq-buttons-property
        [actionForm]="action.form"
        [currentApp]="currentApp"
        [propertyOptions]="propertyOptions"
      ></cq-buttons-property>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.CHANNEL">
      <cq-branch-action-channel [actionForm]="action.form"></cq-branch-action-channel>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.ASSISTANT">
      <cq-branch-action-assistant [actionForm]="action.form"></cq-branch-action-assistant>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.OPERATOR">
      <cq-branch-action-operator [actionForm]="action.form"></cq-branch-action-operator>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.USER_TAG">
      <cq-branch-action-user-tag
        [currentApp]="currentApp"
        [actionForm]="action.form"
      ></cq-branch-action-user-tag>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.EVENT">
      <cq-branch-action-event
        [actionForm]="action.form"
        [currentApp]="currentApp"
        [propertyOptions]="propertyOptions"
      ></cq-branch-action-event>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.PROPERTY">
      <cq-branch-action-property
        [actionForm]="action.form"
        [currentApp]="currentApp"
        [propertyOptions]="propertyOptions"
      ></cq-branch-action-property>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.PROPERTY_FIELD">
      <cq-branch-action-property-field
        (branchCreate)="callbacks.createNewBranch($event, action)"
        (toBranch)="callbacks.openBranch($event)"
        [actionForm]="$any(action.form)"
        [chatBotType]="chatBotType"
        [chatBotId]="chatBotId"
        [currentApp]="currentApp"
        [propertyOptions]="propertyOptions"
        [targetBranchOptions]="nextBranchOptions"
      ></cq-branch-action-property-field>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.EMAIL_NOTIFICATION">
      <cq-branch-action-email-notification
        [actionForm]="action.form"
        [currentApp]="currentApp"
        [propertyOptions]="propertyOptions"
      ></cq-branch-action-email-notification>
    </ng-container>
    <ng-container *ngSwitchCase="ACTION_TYPE.AMOCRM_NOTIFICATION">
      <cq-branch-action-amocrm-notification
        [actionForm]="$any(action.form)"
        [action]="action"
      ></cq-branch-action-amocrm-notification>
    </ng-container>
  </ng-container>
</ng-container>
