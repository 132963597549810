<ng-container *transloco="let t; read: 'quillTexEditorComponent'">
  <div class="cq-quill">
    <div
      #editor
      (click)="onClickEditor($event)"
    ></div>

    <div
      id="custom-toolbar"
      class="d-flex ql-toolbar"
    >
      <div
        *ngIf="formats.length"
        class="card w-100"
        [ngbCollapse]="!((quillComponentRef && quill && formats.length !== 0 && quill.hasFocus()) || modalEditorIsOpen)"
      >
        <div class="card-footer d-flex justify-content-around padding-5 no-border-top bg-light-secondary">
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['bold']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('bold')"
            (click)="format('bold')"
            [ngbTooltip]="t('boldButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-bold"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['italic']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('italic')"
            (click)="format('italic')"
            [ngbTooltip]="t('italicButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-italic"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['underline']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('underline')"
            (click)="format('underline')"
            [ngbTooltip]="t('underlineButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-underline"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['strike']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('strike')"
            (click)="format('strike')"
            [ngbTooltip]="t('strikeButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-strikethrough"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['link']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('link')"
            (click)="setLink()"
            [ngbTooltip]="t('linkButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-link"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['list']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('list', 'ordered')"
            (click)="format('list', 'ordered')"
            [ngbTooltip]="t('orderedButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-list-ol"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['list']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('list', 'bullet')"
            (click)="format('list', 'bullet')"
            [ngbTooltip]="t('bulletButtonTooltip')"
            placement="top-right"
          >
            <i class="btn-icon cqi-sm cqi-list-ul"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['userProp']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('userProp')"
            (click)="setUserProp()"
            [ngbTooltip]="t('insertUserPropTooltip')"
            placement="top-right"
          >
            <i class="btn-icon cqi-sm cqi-variable"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
