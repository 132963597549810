import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { CancellationExactlyModalComponent } from './cancellation-exactly-modal.component';

@NgModule({
  declarations: [CancellationExactlyModalComponent],
  exports: [CancellationExactlyModalComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslocoModule, TuiLet],
  providers: [CarrotquestHelperProvider, SubscriptionStore, NgbActiveModal],
})
export class CancellationExactlyModalModule {}
