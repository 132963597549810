import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgMathPipesModule } from 'ngx-pipes';

import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchMediaActionsEditorComponent } from './media-editor.component';

@NgModule({
  declarations: [BranchMediaActionsEditorComponent],
  imports: [CommonModule, TranslocoModule, NgMathPipesModule, NgxDropzoneModule, SharedModule],
  exports: [BranchMediaActionsEditorComponent],
})
export class BranchMediaActionsEditorModule {}
