import { IPromise, IQService } from 'angular';

import {
  ACCEPTABLE_DOCS_EXTENSIONS,
  ACCEPTABLE_EXTENSIONS,
  ACCEPTABLE_IMAGES_EXTENSIONS,
  ACCEPTABLE_VIDEO_EXTENSIONS,
} from '@panel/app-old/shared/services/file-helper/file-helper.constants';

/** Разделитель между расширениями */
export const EXTENSION_DELIMITER = ', ';

/** Размеры изображения */
type ImageSize = {
  width: number;
  height: number;
};

/** Сервис для работы с файлами */
export class FileHelperService {
  //@ngInject
  constructor(private $q: IQService) {}

  /** Получение допустимых расширений файлов
   *
   * @param excludedExtensions - Одно или несколько исключаемых расширений
   */
  getAcceptableFilesExtensions(excludedExtensions?: string | string[]): string {
    return this.getMappedExtensions(ACCEPTABLE_EXTENSIONS, excludedExtensions);
  }

  /** Получение допустимых расширений файлов с документами
   *
   * @param excludedExtensions - Одно или несколько исключаемых расширений
   */
  getAcceptableDocsExtensions(excludedExtensions?: string | string[]): string {
    return this.getMappedExtensions(ACCEPTABLE_DOCS_EXTENSIONS, excludedExtensions);
  }

  /** Получение допустимых расширений файлов с изображениями
   *
   * @param excludedExtensions - Одно или несколько исключаемых расширений
   */
  getAcceptableImagesExtensions(excludedExtensions?: string | string[]): string {
    return this.getMappedExtensions(ACCEPTABLE_IMAGES_EXTENSIONS, excludedExtensions);
  }

  /** Получение допустимых расширений файлов с видео
   *
   * @param excludedExtensions - Одно или несколько исключаемых расширений
   */
  getAcceptableVideoExtensions(excludedExtensions?: string | string[]): string {
    return this.getMappedExtensions(ACCEPTABLE_VIDEO_EXTENSIONS, excludedExtensions);
  }

  /**
   * Получение объекта Blob из File
   *
   * @param file - Файл
   */
  getBlob(file: File): Blob {
    return new Blob([file], { type: file.type });
  }

  /**
   * Получение регулярного выражения для поиска расширения файла
   *
   * @param extensions - Список расширений из которого нужно составить RegExp
   */
  getExtensionsRegExp(extensions: string[]): RegExp {
    return new RegExp(extensions.map((ext) => `\.${ext}`).join('|'), 'i');
  }

  /**
   * Получение размеров изображения
   *
   * @param image - Файл с изображением
   */
  getImageSize(image: File): IPromise<ImageSize> {
    let deferred = this.$q.defer<ImageSize>();
    let img = new Image();

    img.onload = () => {
      deferred.resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.src = this.getObjectUrl(image); // путь до объекта загруженного изображения меняется в зависимости от браузера

    return deferred.promise;
  }

  /**
   * Получение списка расширений строкой
   *
   * @param acceptedExtensions - Список допустимых расширений
   * @param excludedExtensions - Одно или несколько исключаемых расширений
   */
  getMappedExtensions(acceptedExtensions: string[], excludedExtensions: string | string[] = []): string {
    if (!Array.isArray(excludedExtensions)) {
      excludedExtensions = [excludedExtensions];
    }

    return acceptedExtensions
      .filter((ext) => !excludedExtensions.includes(ext))
      .map((ext) => `.${ext}`)
      .join(EXTENSION_DELIMITER);
  }

  /**
   * Получение ссылки на объект blob
   *
   * @param blob - Blob файла
   */
  getObjectUrl(blob: File | Blob): string {
    return URL.createObjectURL(blob);
  }

  /**
   * Является ли расширение допустимым
   *
   * @param fileName - Название файла вместе с расширением
   */
  isAcceptedExtension(fileName: string): boolean {
    return this.getExtensionsRegExp(ACCEPTABLE_EXTENSIONS).test(fileName);
  }

  /**
   * Является ли расширение видео допустимым
   *
   * @param fileName - Название видео файла вместе с расширением
   */
  isAcceptedVideoExtension(fileName: string): boolean {
    return this.getExtensionsRegExp(ACCEPTABLE_VIDEO_EXTENSIONS).test(fileName);
  }

  /**
   * Является ли расширение расширением изображения
   *
   * @param fileName - Название файла вместе с расширением
   */
  isAcceptedImageExtension(fileName: string): boolean {
    return this.isFileHasExtensions(fileName, ACCEPTABLE_IMAGES_EXTENSIONS);
  }

  /**
   * Является ли файл определенного разрешения
   *
   * @param fileName - Название файла
   * @param extensions - Одно или несколько расширений, которые нужно проверить в файле
   */
  isFileHasExtensions(fileName: string, extensions: string | string[]): boolean {
    if (!Array.isArray(extensions)) {
      extensions = [extensions];
    }

    return this.getExtensionsRegExp(extensions).test(fileName);
  }
}
