import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import { INTERACTION_ENTITY } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { ActionView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/action.view';
import { AutomessageSentView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/automessage-sent.view';
import { DelayView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/delay.view';
import { DelayPassedView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/delay-passed.view';
import { FilterMatchView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/filter-match.view';
import { FilterNotMatchView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/filter-not-match.view';
import { NoReactionView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/no-reaction.view';
import { ReactionView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/reaction.view';
import { SendingConditionsTriggersView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/views/sending-conditions-triggers.view';

/**
 * Сервис-фабрика для работы с view экшенов
 */
@Injectable()
export class InteractiveBlockPartViewFactory {
  constructor(private readonly interactionService: InteractionService, private readonly transloco: TranslocoService) {}

  /**
   * Создаёт view экшена
   *
   * @param type - Тип
   * @param step - Шаг
   */
  create(type: INTERACTIVE_BLOCK_PART, step: IBlockView): AbsInteractiveBlockPartView {
    let blockPartView = this.getViewInstance(type, step);
    this.interactionService.makeInteractive({
      type: INTERACTION_ENTITY.INTERACTIVE_BLOCK_PART,
      view: blockPartView,
    });

    step.interactiveBlockParts.push(blockPartView);

    return blockPartView;
  }

  private getViewInstance(type: INTERACTIVE_BLOCK_PART, step: IBlockView): AbsInteractiveBlockPartView {
    const requiredArgs = [this.transloco] as const;

    switch (type) {
      case INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT:
        return new AutomessageSentView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.REACTION:
        return new ReactionView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.NO_REACTION:
        return new NoReactionView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.DELAY:
        return new DelayView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.DELAY_PASSED:
        return new DelayPassedView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.FILTER_MATCH:
        return new FilterMatchView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.FILTER_NOT_MATCH:
        return new FilterNotMatchView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS:
        return new SendingConditionsTriggersView(step, ...requiredArgs);
      case INTERACTIVE_BLOCK_PART.ACTION:
        return new ActionView(step, ...requiredArgs);
      default:
        throw new Error('This is not handled case');
    }
  }
}
