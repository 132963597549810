import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { ArticlesLimitationBannerModule } from '@panel/app/pages/knowledge-base/shared/components/articles-limitation-banner/articles-limitation-banner.module';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ArticleEditComponent } from './article-edit.component';

@NgModule({
  declarations: [ArticleEditComponent],
  exports: [ArticleEditComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ArticlesLimitationBannerModule,
    UIRouterModule,
    NgbTooltipModule,
    SharedModule,
    FormsModule,
    AccessDenialPopoverModule,
    PipesModule,
    ReactiveFormsModule,
  ],
})
export class ArticleEditModule {}
