import { firstValueFrom } from 'rxjs';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '../../../../../app/http/channel/channel.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversations')
    .controller('CqConversationsConversationHistoryController', CqConversationsConversationHistoryController);

  function CqConversationsConversationHistoryController(
    $filter,
    $q,
    $stateParams,
    channelModel,
    conversationModel,
    teamMemberModel,
  ) {
    var vm = this;

    vm.$onChanges = onChanges;
    vm.$onInit = init;

    function init() {
      vm.conversations = []; // список диалогов
      vm.conversationsPaginator = null; // пагинация списка диалогов
      vm.getConversationsFromHistory = getConversationsFromHistory;
      vm.isConversationsLoading = false; // загружаются ли в данный момент диалоги
      vm.onlyWithUserReplies = true; // получать диалоги только с ответом от пользователя

      getConversationsFromHistory(true, true);
    }

    function onChanges(changes) {
      if (changes.userId && !changes.userId.isFirstChange()) {
        getConversationsFromHistory(true);
      }
    }

    /**
     * Получение каналов
     * Если они уже были получены, или они пришли из родительского компонента - они и возвращаются
     *
     * @returns {Promise}
     */
    function getChannels() {
      if (angular.isUndefined(vm.channels)) {
        return firstValueFrom(channelModel.getList($stateParams.appId, false, true)).then(
          (channels) => (vm.channels = channels),
        );
      } else {
        return $q.resolve(vm.channels);
      }
    }

    /**
     * Получение истории диалогов
     *
     * @param {Boolean=} forceReload Принудительно перезагрузить весь список, а не добавлять полученные диалоги в конец текущего списка
     * @param {Boolean=} repeatIfEmpty Повторить запрос с onlyWithUserReplies = false в случае отсутствия диалогов
     */
    function getConversationsFromHistory(forceReload, repeatIfEmpty = false) {
      vm.isConversationsLoading = true;

      if (forceReload) {
        vm.conversations = [];
        vm.conversationsPaginator = null;
      }

      $q.all([
        firstValueFrom(conversationModel.getListByUserId(vm.userId, vm.conversationsPaginator, vm.onlyWithUserReplies)),
        getChannels(),
        getTeamMembers(),
      ])
        .then(getHistorySuccess)
        .finally(getHistoryFinally);

      function getHistoryFinally() {
        /*Если ушел 2ой запрос, то 1ый не должен поменять флаг загрузки*/
        !repeatIfEmpty && (vm.isConversationsLoading = false);
      }

      function getHistorySuccess(results) {
        var conversations = results[0];
        var channels = results[1];
        var teamMembers = results[2];

        if (vm.onlyWithUserReplies && conversations.conversations.length === 0) {
          if (repeatIfEmpty) {
            vm.onlyWithUserReplies = false;
            /**
             * Добавлен вызов тут, потому что ng-model в старом ангуляре не может запустить ng-change,
             * если ng-model менялся программно
             */
            getConversationsFromHistory(true);
          }
        } else {
          /*
          Раз повторного запроса не проихошло имено этот запрос обновить флаг загрузки
          */
          repeatIfEmpty = false;
          for (var i = 0; i < conversations.conversations.length; i++) {
            var conversation = conversations.conversations[i];

            conversation.assignee =
              conversation.assignee && $filter('filter')(teamMembers, { id: conversation.assignee.id }, true)[0];
            conversation.channel = conversation.channel
              ? $filter('filter')(channels, { id: conversation.channel.id }, true)[0]
              : $filter('filter')(channels, { id: PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL] }, true)[0];
          }

          vm.conversations = vm.conversations.concat(conversations.conversations);
          vm.conversationsPaginator = conversations.paginatorParams;
        }
      }
    }

    /**
     * Получение членов команды
     * Если они уже были получены, или они пришли из родительского компонента - они и возвращаются
     *
     * @returns {Promise}
     */
    function getTeamMembers() {
      if (angular.isUndefined(vm.teamMembers)) {
        return firstValueFrom(teamMemberModel.getList($stateParams.appId)).then(
          (teamMembers) => (vm.teamMembers = teamMembers),
        );
      } else {
        return $q.resolve(vm.teamMembers);
      }
    }
  }
})();
