import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { ChannelModel } from '@http/channel/channel.model';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { ServiceBillingInfoComponent } from '@panel/app/pages/service-billing-info/service-billing-info.component';

export const SERVICE_BILLING_INFO_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    parent: 'app.content',
    name: 'serviceBillingInfo',
    url: '/service-billing', // https://carrotquest.io/panel/2782/service-billing
    component: ServiceBillingInfoComponent,
    resolve: [
      {
        token: 'capabilities',
        deps: ['currentApp', PlanCapabilityModel],
        resolveFn: getCapabilitiesList,
      },
      {
        token: 'activeTriggerMessageAmount',
        deps: [MessageModel],
        resolveFn: getActiveMessagesAmount,
      },
      {
        token: 'activeFacebookBotsAmount',
        deps: [ChatBotModel],
        resolveFn: getActiveFacebookBotsAmount,
      },
      {
        token: 'activeLeadBotsAmount',
        deps: ['currentApp', ChatBotModel],
        resolveFn: getActiveLeadBotsAmount,
      },
      {
        token: 'activeTgBotsAmount',
        deps: [ChatBotModel],
        resolveFn: getActiveTgBotsAmount,
      },
      {
        token: 'activeWelcomeBotsAmount',
        deps: [ChatBotModel],
        resolveFn: getActiveWelcomeBotsAmount,
      },
      {
        token: 'activeChatBotsAmount',
        deps: ['currentApp', ChatBotModel],
        resolveFn: getActiveChatBotsAmount,
      },
      {
        token: 'manualChannelsAmount',
        deps: ['currentApp', ChannelModel],
        resolveFn: getManualChannels,
      },
    ],
  },
];

function getCapabilitiesList(currentApp: App, planCapabilityModel: PlanCapabilityModel) {
  return firstValueFrom(planCapabilityModel.getList(currentApp.id));
}

function getActiveMessagesAmount(messageModel: MessageModel) {
  return firstValueFrom(messageModel.getActiveTriggerMessageCount());
}

function getActiveFacebookBotsAmount(chatBotModel: ChatBotModel) {
  return firstValueFrom(chatBotModel.getFacebookBot()).then((response) => {
    const facebookBots = response ? [response].filter((bot) => bot.active) : [];
    return facebookBots.length;
  });
}

function getActiveLeadBotsAmount(currentApp: App, chatBotModel: ChatBotModel) {
  return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
    return response.chatBots.length;
  });
}

function getActiveTgBotsAmount(chatBotModel: ChatBotModel) {
  return firstValueFrom(chatBotModel.getTelegramBotList(false, MESSAGE_STATUSES.ACTIVE)).then((telegramBots) => {
    return telegramBots.length;
  });
}

function getActiveWelcomeBotsAmount(chatBotModel: ChatBotModel) {
  return firstValueFrom(chatBotModel.getRoutingBot()).then((response) => {
    const welcomeBots = response ? [response].filter((bot) => bot.active) : [];
    return welcomeBots.length;
  });
}

function getActiveChatBotsAmount(currentApp: App, chatBotModel: ChatBotModel) {
  return firstValueFrom(chatBotModel.getActiveChatBotsAmounts(currentApp.id)).then((response) => {
    return response.amount;
  });
}

function getManualChannels(currentApp: App, channelModel: ChannelModel) {
  return firstValueFrom(channelModel.getList(currentApp.id)).then((channels) => {
    return channels.filter((channel) => channel.type === CHANNEL_TYPES.MANUAL).length;
  });
}
