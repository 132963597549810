import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectComponent } from '@ng-select/ng-select';

import { MessageEditorSwitchModule } from '@panel/app/partials/message-editor/shared/message-editor-switch/message-editor-switch.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageEditorSendingHoursComponent } from './message-editor-sending-hours.component';

@NgModule({
  declarations: [MessageEditorSendingHoursComponent],
  exports: [MessageEditorSendingHoursComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MessageEditorSwitchModule,
    NgSelectComponent,
  ],
})
export class MessageEditorSendingHoursModule {}
