import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Quill, { Range } from 'quill';
import { takeUntil } from 'rxjs/operators';

import { UserProperty } from '@http/property/property.model';
import { EditorFormatUserProperty } from '@panel/app/partials/editor/format/user-property/editor-format-user-property.type';
import { EditorService } from '@panel/app/partials/editor/service/editor.service';
import { InsertPropsIntoTextModalComponent } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.component';
import { InsertPropsIntoTextModalData } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.token';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-editor-format-user-property',
  templateUrl: './editor-format-user-property.component.html',
  styleUrls: ['./editor-format-user-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class EditorFormatUserPropertyComponent {
  @Input({ required: true })
  public editor!: Quill;

  @Input({ required: true })
  public userProperties: UserProperty[] = [];

  private selection: Range | null = null;

  constructor(private editorService: EditorService, private ngbModal: NgbModal, private destroy$: DestroyService) {
    this.editorService.clickOnEditor$.pipe(takeUntil(this.destroy$)).subscribe((target) => {
      if (target === null) {
        return;
      }

      if (target.closest('.badge') || (target.tagName === 'SPAN' && target.classList.contains('badge'))) {
        let defaultValue = target.dataset.defaultValue ?? null;
        let propertyName = target.dataset.propName ?? null;

        this.startSettingUserProperty(propertyName, defaultValue);
      }
    });
  }

  protected onClickSetUserPropertyButton(): void {
    let selection = this.editor.getSelection(true);
    let selectedText = this.editor.getText(selection.index, selection.length);

    this.startSettingUserProperty(null, selectedText);
  }

  private openSettingUserPropertyModal(propertyName: string | null, defaultValue: string | null): NgbModalRef {
    let modal = this.ngbModal.open(InsertPropsIntoTextModalComponent);

    (modal.componentInstance.modalWindowParams as InsertPropsIntoTextModalData) = {
      userProps: this.userProperties,
      defaultValue: defaultValue,
      propName: propertyName,
    };

    return modal;
  }

  private saveSelection(): void {
    this.selection = this.editor.getSelection();
  }

  private startSettingUserProperty(propertyName: string | null = null, defaultValue: string | null = null): void {
    this.saveSelection();

    this.openSettingUserPropertyModal(propertyName, defaultValue)
      .result.then((userPropertyData: EditorFormatUserProperty) => {
        this.editorService.formatUserPropertyInsert(this.editor, this.selection, userPropertyData);
      })
      .catch(() => {});
  }
}
