import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { StateService } from '@uirouter/core';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { EMAIL_STATUSES } from '@http/email-status/email-status.constants';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { MESSAGE_TYPES } from '@http/message/message.constants';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { User } from '@http/user/types/user.type';
import { EditUserPropertyModalComponent } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.component';
import { EDIT_USER_PROPERTY_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.token';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE_BY_MESSAGE_PART_TYPE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-user-card-head[currentApp][djangoUser][user]',
  templateUrl: './user-card-head.component.html',
  styleUrls: ['./user-card-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class UserCardHeadComponent implements OnInit {
  /**
   * Текущее приложение
   */
  @Input()
  currentApp!: App;
  /**
   * Текущий пользователь
   */
  @Input()
  djangoUser!: DjangoUser;
  /**
   *  Надо ли показывать кнопку закрытия модалки
   */
  @Input()
  isClosable: boolean = false;
  /** Пользователь */
  @Input()
  user!: User;

  /**
   * Callback закрытия модалки
   */
  @Output()
  closeBtnClick: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback подтверждения электронной почты
   */
  @Output()
  doubleOptIn: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback написать пользователю
   */
  @Output()
  writeToUser: EventEmitter<MESSAGE_PART_TYPES> = new EventEmitter<MESSAGE_PART_TYPES>();
  /**
   * Callback скрытия пользователя
   */
  @Output()
  hideUser: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback обновления имени пользователя
   */
  @Output()
  nameUpdate: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback удаления пользователя
   */
  @Output()
  removeUser: EventEmitter<MESSAGE_PART_TYPES> = new EventEmitter<MESSAGE_PART_TYPES>();
  /**
   * Callback отписки пользователя
   */
  @Output()
  unsubscribeUser: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback скрытия/показа чата у пользователя
   */
  @Output()
  hideChat: EventEmitter<boolean> = new EventEmitter<boolean>();

  MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;

  /**
   * URL карточки пользователя
   */
  userCardUrl!: string;
  /**
   * Есть лидоступ до веб-пушей
   */
  hasAccessToWebPush: boolean = false;
  /**
   * Есть ли права на удаление
   *
   * у операторов нет прав на удаление лидов
   */
  hasRemovePermissions = false;
  /**
   * Пользовател активировался в SDK
   */
  isUserActivatedInSdk: boolean = false;
  /**
   * Задизейблена ли кнопка отписки
   */
  isEmailButtonsDisabled: boolean = false;

  /**
   * Проверка на веб-версию панели
   */
  isWebApp: boolean = this.utilsService.webApp;

  constructor(
    private readonly $state: StateService,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly clipboard: ClipboardService,
    private readonly destroy$: DestroyService,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly featureModel: FeatureModel,
    private readonly modalHelperService: ModalHelperService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly toastService: ToastService,
    private readonly transloco: TranslocoService,
    public readonly utilsService: UtilsService,
  ) {}

  /** ID пользователя */
  private _userId!: string;

  get userId(): string {
    return this._userId;
  }

  @Input()
  set userId(id: string) {
    this._userId = id;
    this.userCardUrl = this.$state.href('app.content.users.detail', { userId: id }, { absolute: true });
  }

  /**
   * Получение статуса адреса електронной почты пользователя
   */
  get userEmailStatus(): EMAIL_STATUSES {
    // NOTE: поскольку статус EMAIL_STATUSES.NO_EMAIL никогда не приходит с бэкэнда,
    //  то отсутствие емейла у пользователя означает пустое поле emailStatus, поэтому тут делается отдельная проверка на его наличие
    return (this.user.emailStatus && this.user.emailStatus.status) || EMAIL_STATUSES.NO_EMAIL;
  }

  ngOnInit(): void {
    this.initVariables();

    this.clipboard.copyResponse$.pipe(takeUntil(this.destroy$)).subscribe((response: IClipboardResponse) => {
      if (response.isSuccess && response.content === this.userCardUrl) {
        this.onCopyUrlSuccess();
      }
    });
  }

  /**
   * Проверка на ограничение по тарифному плану
   * @param partType - Тип парта сообщения
   */
  accessToPartTypesManual(
    partType: Exclude<MESSAGE_PART_TYPES, 'all' | 'control_group' | 'run_script' | 'lead_bot' | 'webhook'>,
  ): ProductFeatureAccess {
    const productFeature = PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.MANUAL][partType];

    return this.planFeatureAccessService.getAccess(productFeature, this.currentApp);
  }

  /**
   * Копирование адреса карточки ползьователя в буфер обмена
   */
  public copyUserCardUrl() {
    this.clipboard.copy(this.userCardUrl);
  }

  /**
   * Открыть модалку редактирования свойств пользователя
   */
  openEditUserNameModal(): void {
    this.modalHelperService
      .provide(EDIT_USER_PROPERTY_MODAL_DATA_TOKEN, {
        propertyName: '$name',
        propertyValue: this.user.props.$name,
        userId: this.user.id,
      })
      .open(EditUserPropertyModalComponent, {
        centered: true,
        scrollable: true,
      })
      .result.then((propertyValue: string) => {
        this.nameUpdate.emit();
      })
      .catch(() => {});
  }

  /**
   * Трек клика на "Выслать подтверждение подписки на письма"
   */
  trackClickSendSubscriptionConfirmationEmail() {
    this.carrotquestHelper.track('Карточка пользователя - кликнул на "Выслать подтверждение подписки на письма"', {
      app: this.currentApp.name,
      'app id': this.currentApp.id,
    });
  }

  /**
   * Трек клика на отписку пользователя от писем
   */
  trackClickUnsubscribeUserFromEmail() {
    this.carrotquestHelper.track('Карточка пользователя - кликнул на "Отписать пользователя от писем"', {
      app: this.currentApp.name,
      'app id': this.currentApp.id,
    });
  }

  /**
   * Трек клика на скрытие пользователя
   */
  trackClickHideUser() {
    this.carrotquestHelper.track('Карточка пользователя - кликнул на "Скрыть пользователя"', {
      app: this.currentApp.name,
      'app id': this.currentApp.id,
    });
  }

  /**
   * Трек клика на полное удаление пользователя
   */
  trackClickRemoveUser() {
    this.carrotquestHelper.track('Карточка пользователя - кликнул на "Полностью удалить пользователя"', {
      app: this.currentApp.name,
      'app id': this.currentApp.id,
    });
  }

  /**
   * Трек клика на "Скрывать чат у пользователя"
   */
  trackClickHideChat() {
    this.carrotquestHelper.track('Карточка пользователя - кликнул на "Скрывать чат у пользователя"', {
      app: this.currentApp.name,
      'app id': this.currentApp.id,
    });
  }

  /**
   * Инициализация переменных
   * @private
   */
  private initVariables() {
    //NOTE: Web-push'и не доступны в США и для app'ов, которые были зарегистрированы после END_TIME_FEATURES.WEB_PUSH
    this.hasAccessToWebPush = this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.currentApp.created);
    this.isUserActivatedInSdk =
      Boolean(this.currentApp.activation.installed_sdk_ios) ||
      Boolean(this.currentApp.activation.installed_sdk_android);
    this.isEmailButtonsDisabled =
      !this.user.emailStatus ||
      ![EMAIL_STATUSES.CONFIRMED, EMAIL_STATUSES.NOT_CONFIRMED].includes(this.user.emailStatus.status);
    this.hasRemovePermissions = !this.djangoUserModel.isOperator(this.currentApp.id, this.djangoUser);
  }

  /**
   * Успешное копирование URL'а на карточку пользователя
   * @private
   */
  private onCopyUrlSuccess() {
    this.toastService.success(this.transloco.translate('general.linkCopied'));
  }
}
