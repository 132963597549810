import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { finalize, take } from 'rxjs/operators';

import { AiSalesModel } from '@http/ai-sales/ai-sales.model';
import { AiSalesBotSettings } from '@http/ai-sales/ai-sales-internal.type';
import { App } from '@http/app/app.model';
import { Channel } from '@http/channel/channel.model';
import { Properties } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { AiSalesBotSettingsForm } from './ai-sales-settings.type';

@Component({
  selector: 'cq-ai-sales-settings',
  templateUrl: './ai-sales-settings.component.html',
  styleUrls: ['./ai-sales-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiSalesSettingsComponent implements OnInit {
  @Input({ required: true })
  aiSalesBotSettings!: AiSalesBotSettings;

  @Input({ required: true })
  channels!: Channel[];

  @Input({ required: true })
  conversationTags!: string[];

  @Input({ required: true })
  currentApp!: App;

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  userTags!: UserTag[];

  readonly aiSalesModel = inject(AiSalesModel);
  readonly cdr = inject(ChangeDetectorRef);
  readonly fb = inject(FormBuilder);
  readonly toastService = inject(ToastService);
  readonly translocoService = inject(TranslocoService);

  settingsForm!: FormGroup<AiSalesBotSettingsForm>;

  isApiRequestPerformed: boolean = false;

  ngOnInit(): void {
    this.settingsForm = this.fb.group<AiSalesBotSettingsForm>({
      callHumanOperatorMessage: this.fb.group({
        notWork: this.fb.control(this.aiSalesBotSettings.callHumanOperatorMessage.notWork, {
          nonNullable: true,
        }),
        work: this.fb.control(this.aiSalesBotSettings.callHumanOperatorMessage.work, { nonNullable: true }),
      }),
      conversationTag: this.fb.control(this.aiSalesBotSettings.conversationTag),
      channels: this.fb.control(this.aiSalesBotSettings.channels),
      closeTimeout: this.fb.control(this.aiSalesBotSettings.closeTimeout, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      gptTemperature: this.fb.control(this.aiSalesBotSettings.gptTemperature, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      gptFunctions: this.fb.control(this.aiSalesBotSettings.gptFunctions, { nonNullable: true }),
      openAiApiKey: this.fb.control(this.aiSalesBotSettings.openAiApiKey, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      prompt: this.fb.control(this.aiSalesBotSettings.prompt, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      userEvent: this.fb.control(this.aiSalesBotSettings.userEvent),
      userTag: this.fb.control(this.aiSalesBotSettings.userTag),
    });
  }

  saveSettings(): void {
    if (!this.settingsForm.valid) {
      this.settingsForm.markAsTouched();
      return;
    }

    this.isApiRequestPerformed = true;

    const params = {
      ...this.settingsForm.getRawValue(),
      integration: this.aiSalesBotSettings.integration,
    };

    this.aiSalesModel
      .saveSettings(params)
      .pipe(
        take(1),
        finalize(() => {
          this.isApiRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.toastService.success(this.translocoService.translate('aiSalesSettingsComponent.saveSuccessToast'));
      });
  }
}
