import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AmocrmLeadTagsReadonlyComponent } from './amocrm-lead-tags-readonly.component';

@NgModule({
  declarations: [AmocrmLeadTagsReadonlyComponent],
  exports: [AmocrmLeadTagsReadonlyComponent],
  imports: [CommonModule, TranslocoModule],
})
export class AmocrmLeadTagsReadonlyModule {}
