<ng-container *transloco="let t; read: 'messageScheduleSettingsComponent'">
  <cq-message-editor-collapse [collapse]="false">
    <h4
      titleContent
      class="m-0 align-content-center flex-grow-1 no-flex-basis mt-5"
    >
      {{ t('title') }}
    </h4>

    <div expandContent>
      <div class="btn-group btn-group-toggle">
        <label
          class="btn btn-outline-primary"
          [class.active]="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.DAILY"
        >
          <input
            type="radio"
            [formControl]="form.controls.type"
            [value]="MESSAGE_SCHEDULE_TYPE.DAILY"
          />
          <span>{{ t('typeSelector.' + MESSAGE_SCHEDULE_TYPE.DAILY) }}</span>
        </label>
        <label
          class="btn btn-outline-primary"
          [class.active]="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.WEEKLY"
        >
          <input
            type="radio"
            [formControl]="form.controls.type"
            [value]="MESSAGE_SCHEDULE_TYPE.WEEKLY"
          />
          <span>{{ t('typeSelector.' + MESSAGE_SCHEDULE_TYPE.WEEKLY) }}</span>
        </label>
        <label
          class="btn btn-outline-primary"
          [class.active]="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.MONTHLY"
        >
          <input
            type="radio"
            [formControl]="form.controls.type"
            [value]="MESSAGE_SCHEDULE_TYPE.MONTHLY"
          />
          <span>{{ t('typeSelector.' + MESSAGE_SCHEDULE_TYPE.MONTHLY) }}</span>
        </label>
      </div>

      <div
        *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.WEEKLY"
        class="mt-15"
      >
        <div class="mb-10">{{ t('weekForm.sendOn') }}</div>
        <div class="margin-between-rows-10">
          <div
            *ngFor="let weekDay of weekDays"
            class="custom-control custom-checkbox"
          >
            <input
              class="custom-control-input"
              id="weekDay-{{ weekDay }}"
              type="checkbox"
              (click)="changeWeekDayStatus(weekDay)"
              [checked]="daySelected(weekDay, this.form.controls.daysOfWeek.value)"
            />
            <label
              class="custom-control-label"
              for="weekDay-{{ weekDay }}"
              >{{ t('weekForm.' + weekDay) }}
            </label>
          </div>
        </div>
        <div class="d-flex mt-20">
          <span
            class="cursor-pointer text-primary dashed"
            (click)="selectWeekDays([1, 2, 3, 4, 5])"
            >{{ t('weekForm.selectWeekdays') }}</span
          >
          <span
            class="cursor-pointer text-primary dashed ml-20"
            (click)="selectWeekDays([6, 7])"
            >{{ t('weekForm.selectWeekend') }}</span
          >
        </div>
      </div>

      <div
        *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.MONTHLY"
        class="mt-15"
      >
        <div class="mb-10">{{ t('monthForm.sendOn') }}</div>
        <div class="monthDaysGrid">
          <button
            *ngFor="let monthDay of monthDays"
            class="btn btn-borderless-primary"
            (click)="changeMonthDayStatus(monthDay)"
            [class.active]="daySelected(monthDay, this.form.controls.daysOfMonth.value)"
          >
            {{ monthDay }}
          </button>
        </div>
      </div>

      <div class="d-flex align-items-center mt-15">
        <span class="mr-10">{{ t('timeForm.sendAt') }}</span>
        <div [cqShowError]="form.controls.timeH">
          <input
            class="form-control time-input"
            required
            cqNumber
            placeholder="00"
            [maxNumber]="23"
            [formControl]="form.controls.timeH"
          />
          <cq-validation-messages [control]="form.controls.timeH">
            <cq-validation-message errorName="required">{{ t('timeForm.errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
        <span class="margin-right-5 margin-left-5">:</span>
        <div [cqShowError]="form.controls.timeM">
          <input
            class="form-control time-input"
            required
            cqNumber
            placeholder="00"
            [maxNumber]="59"
            [formControl]="form.controls.timeM"
          />
          <cq-validation-messages [control]="form.controls.timeM">
            <cq-validation-message errorName="required">{{ t('timeForm.errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
        <div
          class="text-muted small ml-10"
          [innerHTML]="t('timeForm.timezone', { timezone: timezone })"
        ></div>
      </div>
    </div>
    <div collapsedContent>
      <ng-container *ngIf="showPreview; else noData">
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.DAILY">{{
          t('preview.everyday.text', { time: timeStr })
        }}</span>
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.WEEKLY">{{
          t('preview.weekDays.' + translationNumber, { time: timeStr, weekDays: weekDaysAsText })
        }}</span>
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.MONTHLY">{{
          t('preview.monthDays.text', { time: timeStr, monthDays: monthDaysAsText })
        }}</span>
      </ng-container>
      <ng-template #noData>
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.DAILY">{{ t('preview.everyday.noData') }}</span>
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.WEEKLY">{{
          t('preview.weekDays.noData')
        }}</span>
        <span *ngIf="form.controls.type.value === MESSAGE_SCHEDULE_TYPE.MONTHLY">{{
          t('preview.monthDays.noData')
        }}</span>
      </ng-template>
    </div>
  </cq-message-editor-collapse>
</ng-container>
