import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ElementRef,
  Inject,
  Injectable,
  Injector,
  NgZone,
  Renderer2,
  runInInjectionContext,
  ViewContainerRef,
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig, NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class BootstrapElementsFactory {
  constructor(
    private readonly applicationRef: ApplicationRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private readonly popoverConfig: NgbPopoverConfig,
    private readonly renderer: Renderer2,
    private readonly tooltipConfig: NgbTooltipConfig,
    private readonly viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {}

  /**
   * Создание тултипа
   * @param element - элемент на котором будет рендерится тултип
   * @param viewRef - viewContainer элемента на котором надо показать тултип
   * @param customConfig - Кастомный конфиг для тултипа
   */
  public createTooltip(
    element: ElementRef<HTMLElement>,
    viewRef: ViewContainerRef = this.viewContainerRef,
    customConfig: NgbTooltipConfig = this.tooltipConfig,
  ): NgbTooltip {
    // Создание через runInInjectionContext это небольшой хак
    // Это не стоит копировать. Лучше посмотри как сделано в цепочках
    return runInInjectionContext(this.injector, () => {
      let tooltip = new NgbTooltip();

      tooltip.positionTarget = element.nativeElement;

      if (customConfig.autoClose) {
        tooltip.autoClose = customConfig.autoClose;
      }

      if (customConfig.placement) {
        tooltip.placement = customConfig.placement;
      }

      if (customConfig.triggers) {
        tooltip.triggers = customConfig.triggers;
      }

      if (customConfig.container) {
        tooltip.container = customConfig.container;
      }

      if (customConfig.disableTooltip) {
        tooltip.disableTooltip = customConfig.disableTooltip;
      }

      if (customConfig.tooltipClass) {
        tooltip.tooltipClass = customConfig.tooltipClass;
      }

      if (customConfig.openDelay) {
        tooltip.openDelay = customConfig.openDelay;
      }

      if (customConfig.closeDelay) {
        tooltip.closeDelay = customConfig.closeDelay;
      }

      return tooltip;
    });
  }

  /**
   * Создание поповера
   * @param element - элемент на котором будет рендерится поповер
   * @param viewRef - viewContainer элемента на котором надо показать поповер
   * @param customConfig - Кастомный конфиг для поповера
   */
  public createPopover(
    element: ElementRef<HTMLElement>,
    viewRef: ViewContainerRef = this.viewContainerRef,
    customConfig: Partial<NgbPopoverConfig> = {},
  ): NgbPopover {
    const config = Object.assign({}, this.popoverConfig, customConfig);
    // Создание через runInInjectionContext это небольшой хак
    // Это не стоит копировать. Лучше посмотри как сделано в цепочках
    return runInInjectionContext(this.injector, () => {
      let popover = new NgbPopover();

      popover.positionTarget = element.nativeElement;

      if (config.autoClose) {
        popover.autoClose = config.autoClose;
      }

      if (config.placement) {
        popover.placement = config.placement;
      }

      if (config.triggers) {
        popover.triggers = config.triggers;
      }

      if (config.container) {
        popover.container = config.container;
      }

      if (config.disablePopover) {
        popover.disablePopover = config.disablePopover;
      }

      if (config.popoverClass) {
        popover.popoverClass = config.popoverClass;
      }

      if (config.openDelay) {
        popover.openDelay = config.openDelay;
      }

      if (config.closeDelay) {
        popover.closeDelay = config.closeDelay;
      }

      return popover;
    });
  }
}
