import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '@environment';
import { MessageAttachmentTemporary } from '@http/message-part/message-part.types';
import { CaseStyleHelper } from '@panel/app/services';
import { EXTENDED_RESPONSE, HIDE_TOAST_ERROR } from '@panel/app/shared/constants/http.constants';

@Injectable({
  providedIn: 'root',
})
export class MessageUtilsModel {
  constructor(private readonly httpClient: HttpClient, private readonly caseStyleHelper: CaseStyleHelper) {}

  /**
   * Получение абсолютной ссылки до папки с картинкой
   */
  getPathToImages(imageUrl: any): string {
    let appId: any;
    let path: any;

    // HACK: из-за старого бага на фронте вместо imageUrl в некоторых сообщениях может быть undefined, поэтому такую ситуацию надо отловить, чтобы код ниже не свалился
    if (!imageUrl) {
      return '';
    }

    if (imageUrl === 'default.png') {
      path = environment.userFilesUrl + '/message-images/';
    } else if (imageUrl.includes(environment.userFilesUrl)) {
      const relativePath = imageUrl.split(environment.userFilesUrl + '/message-images/')[1];

      if (relativePath === 'default.png') {
        path = environment.userFilesUrl + '/message-images/';
      } else {
        appId = relativePath.split('/')[0];
        path = environment.userFilesUrl + '/message-images/' + appId + '/';
      }
    } else {
      appId = imageUrl.split('-')[0];
      path = environment.userFilesUrl + '/message-images/' + appId + '/';
    }

    return path;
  }

  /**
   * Сохранение картинки на сервере
   */
  saveImage(backgroundImage: any): Observable<string> {
    const body = {
      file: backgroundImage,
    };

    return this.httpClient
      .post('/messages/image', body, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          // возвращается абсолютный путь до картинки
          return this.getPathToImages(response.data.filename) + response.data.filename;
        }),
      );
  }

  /**
   * Сохранение файла триггерного сообщения
   *
   * @param file Файл включая картинки
   * @returns {Promise}
   */
  saveFile(file: MessageAttachmentTemporary): Observable<any> {
    // TODO Car-64627 Оказывается если из формы достать Blob, то это будет File
    const formData = new FormData();
    formData.append('file', file.fileBlob, file.filename);
    const params = {
      file: formData.get('file'),
    };

    return this.httpClient
      .post('/messages/files', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          return response.data;
        }),
      );
  }

  /**
   * Получение абсолютной ссылки до папки с файлом
   */
  getPathToFile(): string {
    return `${environment.userFilesUrl}/messagesfiles/`;
  }

  /**
   * Проверка фильтра на валидность или соответствие пользователя фильтру
   * - Если не указан userId - будет проверка валидации
   * - Если указан userId - будет проверка соответствия пользователя фильтру
   *
   * @param jinjaFilterTemplate
   * @param userId
   */
  public checkJinjaFilterTemplate(jinjaFilterTemplate: string, userId?: string) {
    let body = {
      jinja_filter_template: jinjaFilterTemplate,
      user: userId,
    };

    return this.httpClient.post<{ validationResult: boolean }>('/messages/jinja_template/validate', body, {
      context: new HttpContext().set(HIDE_TOAST_ERROR, true),
    });
  }
}
