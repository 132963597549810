import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { map } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { DjangoUser } from '@http/django-user/django-user.types';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { CONVERSATIONS_SETTINGS_TABS } from '@panel/app/pages/conversations-settings/pages/conversations-settings/conversations-settings.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-button-menu[djangoUser]',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuComponent implements OnInit {
  @Input()
  djangoUser!: DjangoUser;

  @Output()
  changeTheme: EventEmitter<boolean> = new EventEmitter();

  @Output()
  searchByUserClicked: EventEmitter<boolean> = new EventEmitter();

  @Output()
  openChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('userList')
  public tooltip!: NgbTooltip;

  currentApp: App = this.store.currentApp$.getValue()!;

  isDarkThemeActive: boolean = this.utilsService.isDarkThemeActive();
  teamMemberWithAutoAssign$ = this.store.teamMembers$.pipe(
    map((teamMembers) => teamMembers.filter((member) => member.conversationsAutoAssign?.readyForAutoAssign).length),
  );

  /** Доступ до автоназначения диалогов */
  accessToConversationsAutoAssignment: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /**
   * Флаг с доступом до поиска по пользователям
   */
  get hasPermissionToUserList(): boolean {
    return this.djangoUser.prefs[this.appService.currentAppId].permissions_conversation_user_list;
  }

  /**
   * Флаг с доступом до кнопки настройки каналов
   */
  get hasPermissionMoreOperator(): boolean {
    return this.djangoUser.prefs[this.appService.currentAppId].permissions !== TEAM_MEMBER_PERMISSIONS.OPERATOR;
  }

  constructor(
    private readonly utilsService: UtilsService,
    private readonly $state: StateService,
    private readonly appService: AppService,
    private carrotquestHelper: CarrotquestHelper,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly store: ConversationsStoreService,
    readonly paywallService: PaywallService,
  ) {}

  ngOnInit() {
    this.accessToConversationsAutoAssignment = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.CONVERSATIONS_AUTO_ASSIGNMENT,
      this.currentApp,
    );
  }

  toggleTheme() {
    this.isDarkThemeActive = !this.isDarkThemeActive;
    this.changeTheme.emit(this.isDarkThemeActive);
  }

  /** Перенаправляет на раздел "Настройка каналов" */
  goToConversationsChannelsSettingsPage(): void {
    this.trackIntentToGoToConversationsSettings();

    this.$state.go('app.content.conversationsSettings', { tab: CONVERSATIONS_SETTINGS_TABS.CHANNELS });
  }

  /** Перенаправляет на раздел "Автораспределение диалоггов" */
  goToConversationsAutoAssignmentSettingsPage(): void {
    this.$state.go('app.content.conversationsSettings', { tab: CONVERSATIONS_SETTINGS_TABS.AUTO_ASSIGNMENT });
  }

  /** Трекает намеренье перейти в раздел "Настройки диалогов" */
  trackIntentToGoToConversationsSettings(): void {
    // Историческое название события
    this.carrotquestHelper.track('Диалоги - клик на "Добавить канал"');
  }
}
