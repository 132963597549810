<ng-container *transloco="let t; read: 'aiContentMakerComponent'">
  <form (submit)="$event.preventDefault(); submit()">
    <div class="card align-items-center">
      <div class="card-body">
        <cq-ai-content-maker-company-info [control]="promtsControls.companyInfo"></cq-ai-content-maker-company-info>

        <hr />
        <cq-ai-content-maker-person-and-task
          [control]="promtsControls.aiPersonaAndTask"
        ></cq-ai-content-maker-person-and-task>

        <hr />
        <cq-ai-content-maker-faq [control]="promtsControls.faq"></cq-ai-content-maker-faq>

        <hr />
        <cq-ai-content-maker-rules [control]="promtsControls.rules"></cq-ai-content-maker-rules>

        <hr />
        <cq-ai-content-maker-gpt-version [control]="settingControls.gptModelName"></cq-ai-content-maker-gpt-version>

        <hr />
        <cq-ai-content-maker-api-key [control]="settingControls.openAiApiKey"></cq-ai-content-maker-api-key>

        <hr />
        <cq-ai-content-maker-creativity-level
          [control]="settingControls.gptTemperature"
        ></cq-ai-content-maker-creativity-level>

        <hr />
        <cq-integration-name-control [control]="nameControl"></cq-integration-name-control>
      </div>
    </div>

    <ng-container>
      <div class="text-right">
        <button
          class="btn btn-primary btn-lg"
          type="submit"
          *ngIf="isExistIntegration"
        >
          {{ 'models.integration.actions.save' | transloco }}
        </button>
        <button
          class="btn btn-primary btn-lg"
          type="submit"
          *ngIf="!isExistIntegration"
        >
          {{ 'models.integration.actions.create' | transloco }}
        </button>
      </div>
    </ng-container>
  </form>
</ng-container>
