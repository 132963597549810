<ng-container *transloco="let t; read: 'editorComponent'">
  <ng-container *ngIf="hasInitializedEditor() && !isToolbarEmpty()">
    <div
      id="custom-toolbar"
      class="d-flex"
    >
      <ng-container *ngFor="let format of formats">
        <ng-container *ngIf="EDITOR_FORMAT.BOLD === format">
          <cq-editor-format-bold [editor]="editor"></cq-editor-format-bold>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.BUTTON === format">
          <cq-editor-format-button
            [editor]="editor"
            [editorComponentRef]="editorComponentRef"
          ></cq-editor-format-button>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.DIVIDER === format">
          <div class="divider"></div>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.FILE === format">
          <cq-editor-format-file
            [editor]="editor"
            [editorComponentRef]="editorComponentRef"
          ></cq-editor-format-file>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.HEADER === format">
          <cq-editor-format-header [editor]="editor"></cq-editor-format-header>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.LINK === format">
          <cq-editor-format-link
            [editor]="editor"
            [editorComponentRef]="editorComponentRef"
          ></cq-editor-format-link>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.SIZE === format">
          <cq-editor-format-size [editor]="editor"></cq-editor-format-size>
        </ng-container>

        <ng-container *ngIf="EDITOR_FORMAT.USER_PROPERTY === format">
          <cq-editor-format-user-property
            [editor]="editor"
            [userProperties]="userProperties"
          ></cq-editor-format-user-property>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div
    #editorContainerRef
    class="no-border"
  ></div>
</ng-container>
