<div class="d-flex flex-column">
  <cq-trigger-chain-canvas-actions
    [hasAccessToCanvasScreenshotFeature]="hasAccessToCanvasScreenshotFeature"
    [hasAccessToCopyTemplate]="hasAccessToCopyTemplate"
    [isFullscreen]="canvasBaseService.isFullscreen"
    [zoomValue]="(canvasBaseService.zoomValue$ | async) ?? 0"
    (clickOnAddChainLinkButton)="onClickOnAddChainStepButton($event)"
    (clickOnChangeZoomButton)="onClickOnChangeZoomButton($event)"
    (clickOnCopyStructureForTemplate)="onClickCopyStructureForTemplate()"
    (clickOnGoToStartButton)="moveViewportToSendingConditions()"
    (clickOnTakeScreenshotButton)="takeScreenshot()"
    (clickOnToggleFullscreenModeButton)="onClickOnToggleFullscreenModeButton()"
  ></cq-trigger-chain-canvas-actions>

  <div class="canvas-container position-absolute">
    <div
      #canvasContainer
      (wheel)="onWheel($event)"
      class="flex flex-grow-1 position-relative overflow-hidden h-100"
    >
      <cq-trigger-chain-overlay></cq-trigger-chain-overlay>
    </div>
  </div>

  <cq-loader
    *ngIf="screenshotInProgress"
    @screenshotAnimation
    [background]="false"
    class="content-loader position-absolute"
  >
    {{ 'triggerChainsCanvasComponent.screenshotInProgress' | transloco }}
  </cq-loader>
</div>
