import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { FEATURES, FEATURES_ONLY } from '../../../../../app/http/feature/feature.constants';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '../../../../../app/http/channel/channel.constants';
import { firstValueFrom } from 'rxjs';
import { CONVERSATION_ASSISTANT_TYPES } from '../../../../../app/http/conversation/conversation.constants';
import {
  CONVERSATION_PART_SYSTEM_TYPES,
  CONVERSATION_PART_TYPES,
} from '../../../../../app/http/conversation-part/conversation-part.constants';
import { SYSTEM_LOG_MESSAGE_TYPES } from '../../../../../app/http/system-log/system-log.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversations')
    .controller('CqConversationsConversationListController', CqConversationsConversationListController);

  function CqConversationsConversationListController(
    $filter,
    $interval,
    $scope,
    $state,
    $timeout,
    $translate,
    hotkeys,
    moment,
    toastr,
    carrotquestHelper,
    channelModel,
    conversationModel,
    conversationPartModel,
    conversationsStoreService,
    dateRangePickerHelper,
    djangoUserModel,
    electronApi,
    emojiService,
    featureModel,
    planFeatureAccessService,
    muteNotificationsService,
    queueRtsService,
  ) {
    var vm = this;

    /**
     * Псевдоназначения, вдобавок к членам команды
     *
     * @type {Object}
     */
    var PSEUDO_ASSIGNEES = {
      ALL: 'all', // без фильтра
      MINE_AND_NOT_ASSIGNED: 'mineAndNotAssigned', // мои и неназначенные
      NOT_ASSIGNED: 'notAssigned', // неназначенные
    };

    /**
     * Список назначения для фильтра по членам команды
     *
     * @type {Array.<String|Object>}
     */
    var assignees = [PSEUDO_ASSIGNEES.ALL, PSEUDO_ASSIGNEES.NOT_ASSIGNED, PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED];

    /**
     * Включать ли закрытые диалоги в список
     * Эта переменная используется в запросе списка диалогов и зависит от значения vm.status
     * Если null - значит без разницы закрыт диалог или открыт
     *
     * @type {Boolean|null}
     */
    var closed = false;

    /**
     * Включать ли отложенные диалоги в список
     * Эта переменная используется в запросе списка диалогов и зависит от значения vm.status
     * Если null - значит без разницы отложен диалог или нет
     *
     * @type {Boolean|null}
     */
    var delayed = false;

    /**
     * Зарегистрированные горячие клавиши
     *
     * @type {Array}
     */
    var hotKeysArray = [];

    /**
     * Промис интервала обновления времени внутри карточки сообщения
     *
     * @type {Promise}
     */
    var refreshTimeTickInterval = null;

    /**
     * Статусы для фильтрации диалогов
     *
     * @type {Object}
     */
    const STATUSES = {
      ALL: 'all',
      CLOSED: 'closed',
      DELAYED: 'delayed',
      OPENED: 'opened',
      UNANSWERED: 'unanswered', // Это псевдо статус, выставляется при vm.unanswered === true
    };

    /**
     * Массив статусов для фильтрации диалогов
     *
     * @type {Array.<String>}
     */
    const STATUSES_ARRAY = [STATUSES.OPENED, STATUSES.DELAYED, STATUSES.CLOSED, STATUSES.ALL];

    vm.$onInit = init;
    vm.$onChanges = onChanges;
    vm.$onDestroy = destroy;

    function init() {
      /** Доступ до AI-бота */
      vm.accessToAiBot = planFeatureAccessService.getAccess(PLAN_FEATURE.AI_BOT, vm.currentApp);

      conversationsStoreService.availableAssistants$.next(getAllowedAssistants());

      vm.assignee = PSEUDO_ASSIGNEES.ALL; // выбранный элемент из списка назначенных (может быть как членом команды, так и константой)
      vm.assignees = assignees;
      vm.assistantType = null; // Фильтр по боту
      vm.CONVERSATION_ASSISTANT_TYPES = CONVERSATION_ASSISTANT_TYPES;
      vm.conversations = []; // список диалогов
      vm.conversationsLoading = false; // флаг загрузки списка диалогов в текущий момент
      vm.conversationsPaginator = null; // пагинация списка сообщений
      vm.conversationsRequestQueue = []; // флаг загрузки списка диалогов в текущий момент
      vm.currentSearchPhrase = ''; // фраза по которой искалось в последний раз
      vm.djangoUserModel = djangoUserModel;
      vm.FEATURES = FEATURES;
      vm.featureModel = featureModel;
      vm.getConversations = getConversations;
      vm.includeNotAssigned = true; // включать ли неразобранные диалоги в список
      vm.includeNoTags = false; // Фильтр "Без тегов". используется в дропдауне тегов
      vm.includeNoTagsApplied = vm.includeNoTags; // Примененный фильтр "Без тегов"
      vm.initShowUseCalendlyPopoverFn = initShowUseCalendlyPopoverFn;
      vm.isConversationsEqual = isConversationsEqual;
      vm.isSearch = false; // включен ли сейчас поиск по репликам
      vm.isShowSearchInput = false; // флаг видимиости поля для поиска
      vm.isStatusFilterDisabled = isStatusFilterDisabled;
      vm.isStatusFilterItemDisabled = isStatusFilterItemDisabled;
      vm.onUnansweredFilterChange = (value) => {
        $timeout(() => {
          vm.unanswered = value;
          trackClickOnUnanswered();
        });
      };
      vm.onStatusFilterChange = (value) => {
        $timeout(() => {
          vm.status = value;
          trackClickOnTheFilterByStatusButton();
        });
      };
      vm.onAssigneeValueChange = onAssigneeValueChange;
      vm.onSelectedTagsFiltersChange = (value) => {
        $timeout(() => {
          vm.includeNoTagsApplied = value.withoutTags;
          vm.selectedTags = value.selectedTags;
        });
      };
      vm.onDateRangeChange = (value) => {
        $timeout(() => {
          trackClickOnTheFilterByDateButton();
          vm.search = {
            ...vm.search,
            dateRange: {
              startDate: value.from,
              endDate: value.to,
            },
          };

          if (vm.currentSearchPhrase) {
            vm.submitSearchForm(true, vm.currentSearchPhrase, true);
          }
        });
      };
      vm.onSearchPhraseChange = (value) => {
        $timeout(() => {
          vm.currentSearchPhrase = value;
          submitSearchForm(true, value, true);
        });
      };
      vm.onClickToggleSearchInput = () => {
        if (vm.isShowSearchInput) {
          closeSearch();
        } else {
          trackClickSearchButton();
          vm.isShowSearchInput = !vm.isShowSearchInput;
        }
      };
      vm.PSEUDO_ASSIGNEES = PSEUDO_ASSIGNEES;
      vm.search = {
        dateRange: {
          startDate: moment().subtract(90, 'days'),
          endDate: moment(),
        },
      };
      vm.searchAssignee = ''; // поиск члена команды по имени
      vm.searchPhrase = ''; // фраза для поиска реплик
      vm.selectedTags = []; // выбранные теги для фильтрации
      vm.selectConversation = selectConversation;
      vm.status = STATUSES.OPENED; // выбранный статус для фильтрации
      vm.ALL_CHANNELS_ID = PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS];
      vm.prevStatus = vm.status; // Предыдущий выбранный статус. Нужен чтобы возвращать предыдущий статус после выключения неотвеченных диалогов
      vm.statuses = STATUSES_ARRAY;
      vm.submitSearchForm = submitSearchForm;
      vm.teamMember = null; // выбранный член команды для фильтрации
      vm.toggleAssistantType = toggleAssistantType;
      vm.trackClickOnUnanswered = trackClickOnUnanswered;
      vm.trackClickOnTheFilterByStatusButton = trackClickOnTheFilterByStatusButton;
      vm.trackOpenMenu = trackOpenMenu;
      vm.unanswered = false; // выбран ли фильтр 'Показывать только неотвеченные'

      muteNotificationsService.start(vm.currentApp.id);

      // HACK: костыль, чтобы быстрее сделать блокировку фильтра для конкурирующих операторов
      if (djangoUserModel.isOperatorWithConcurrency(vm.djangoUser, vm.currentApp)) {
        vm.assignee = PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED;
        vm.teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];
        vm.includeNotAssigned = true;
      }

      // текущий член команды должен быть первым в списке
      assignees.push.apply(assignees, $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true));
      assignees.push.apply(assignees, $filter('filter')(vm.teamMembers, { id: '!' + vm.djangoUser.id }, true));

      getConversations().then(selectFirstConversation);
      createRefreshTimeTick();
      setupHotKeys();

      $scope.$on('message', handleRts);
      if (featureModel.hasAccess(FEATURES_ONLY.FARFOR_FIX)) {
        queueRtsService.executeNext$.subscribe((next) => {
          if (next) {
            conversationsPartBatchesHandler(next);
          }
        });
      }

      $scope.$watch('vm.conversation', watchConversation, true);
      $scope.$watchCollection(
        '[vm.channel, vm.unanswered, vm.assignee, vm.selectedTags, vm.status, vm.assistantType, vm.includeNoTagsApplied]',
        watchFilters,
      );

      /**
       * Слушатель клика по нотификациям из Desktop-приложения
       */
      electronApi.onConversationNotificationClick((event, arg) => {
        const currentConversation = $filter('filter')(vm.conversations, { id: arg })[0];
        currentConversation && selectConversation(currentConversation);
      });

      /**
       * Создание тика обновления времени внутри карточки диалога
       */
      function createRefreshTimeTick() {
        // интервал обновления времени в дочерних компонентах (используется в карточке диалога)
        refreshTimeTickInterval = $interval(broadcastTickEvent, 30000);

        function broadcastTickEvent() {
          $scope.$broadcast('refreshTime');
        }
      }

      /**
       * Выбор первого диалога после первого получения списка диалогов или выбор диалога по клику на нотификацию из десктоп-приложения
       */
      function selectFirstConversation() {
        let currentConversation;
        if ($state.params.conversationId) {
          currentConversation = $filter('filter')(vm.conversations, { id: $state.params.conversationId })[0];
        }

        selectConversation(currentConversation || vm.conversations[0]);
      }

      /**
       * Обработка изменений внутри текущего диалога
       *
       * @param newValue
       * @param oldValue
       */
      function watchConversation(newValue, oldValue) {
        // если диалог как-то изменился и при этом включён поиск - нужно обновить все диалоги с таким же ID, т.к. при поиске диалог с одним и тем же ID может встретиться несколько раз
        if (newValue && oldValue && newValue.id == oldValue.id && vm.isSearch) {
          // обновление назначения диалогов оператору
          if (!angular.equals(newValue.assignee, oldValue.assignee)) {
            for (var i = 0; i < vm.conversations.length; i++) {
              if (vm.conversations[i] != newValue && vm.conversations[i].id == newValue.id) {
                vm.conversations[i].assignee = newValue.assignee;
                vm.conversations[i] = { ...vm.conversations[i] };
              }
            }
          }

          // обновление канала
          if (!angular.equals(newValue.channel, oldValue.channel)) {
            for (var i = 0; i < vm.conversations.length; i++) {
              if (vm.conversations[i] != newValue && vm.conversations[i].id == newValue.id) {
                vm.conversations[i].channel = newValue.channel;
                vm.conversations[i] = { ...vm.conversations[i] };
              }
            }
          }

          // обновление списка тегов
          if (!angular.equals(newValue.tags, oldValue.tags)) {
            for (var i = 0; i < vm.conversations.length; i++) {
              if (vm.conversations[i] != newValue && vm.conversations[i].id == newValue.id) {
                vm.conversations[i].tags = newValue.tags;
                vm.conversations[i] = { ...vm.conversations[i] };
              }
            }
          }
        }

        // если поиск отключён
        if (newValue && oldValue && newValue.id == oldValue.id && !vm.isSearch) {
          // при добавлении новой реплики нужно передвинуть диалог наверх списка в том случае, если он присутствует в списке диалогов
          if (isConversationsEqual(newValue, oldValue)) {
            if (newValue.part_last.id != oldValue.part_last.id) {
              var conversation = $filter('filter')(vm.conversations, { id: newValue.id }, true)[0];
              if (conversation) {
                vm.conversations.splice(vm.conversations.indexOf(conversation), 1);
                vm.conversations.unshift({ ...conversation });
              }
            }
          }
        }
      }

      /**
       * Обработка изменений в фильтрах
       *
       * @param newValue
       * @param oldValue
       */
      function watchFilters(newValue, oldValue) {
        const newUnanswered = newValue[1];
        const newAssignee = newValue[2];
        const newStatus = newValue[4];
        const newAssistantType = newValue[5];
        const oldUnanswered = oldValue[1];
        const oldAssignee = oldValue[2];
        const oldStatus = oldValue[4];
        const oldAssistantType = oldValue[5];
        let wasConflicts = false; // был ли конфликт между фильтрами изменяющими друг друга

        if (newValue !== oldValue) {
          if (newUnanswered !== oldUnanswered) {
            if (newUnanswered) {
              vm.status = STATUSES.UNANSWERED;
              wasConflicts = true;
            } else {
              vm.status = vm.prevStatus;
              wasConflicts = true;
            }
          }

          // если изменился статус - нужно присвоить переменным для запроса списка диалогов соответствующие значения
          if (newStatus !== oldStatus) {
            vm.prevStatus = oldStatus;
            switch (newStatus) {
              case STATUSES.ALL:
                // без разницы закрыты или отложены диалоги
                closed = null;
                delayed = null;
                break;
              case STATUSES.CLOSED:
                // диалоги должны быть закрыты, и без разницы отложены они или нет
                closed = true;
                delayed = null;
                break;
              case STATUSES.DELAYED:
                // диалоги должны быть отложенными, и без разницы закрыты они или нет
                closed = null;
                delayed = true;
                break;
              case STATUSES.OPENED:
              case STATUSES.UNANSWERED:
                // диалоги должны быть не отложены и не закрыты
                closed = false;
                delayed = false;
                break;
            }
          }

          // Если включается фильтр по боту, то дополнительно нужно оставить только фильтр по статусу открытости
          if (newAssistantType !== oldAssistantType) {
            if (newAssistantType !== null) {
              vm.teamMember = null;
              vm.includeNotAssigned = true;
              vm.assignee = '';
              // Если выбран фильтр по боту, то нужно выбрать статус «Все диалоги»:
              // диалоги с ботом не делятся на открытие или закрытые, они просто невидимые
              vm.status = STATUSES.ALL;
            } else {
              // Если выбирается не бот, то нужно выставить предыдущий статус открытости
              vm.status = vm.prevStatus;
              wasConflicts = true;
            }

            // NOTE если мы переключились с бота на бота, то wasConflicts должен быть false
            if (!oldAssistantType) {
              wasConflicts = true;
            }
          }

          if (newAssignee !== oldAssignee && newAssignee !== '') {
            vm.assistantType = null;
            if (newAssignee === PSEUDO_ASSIGNEES.ALL) {
              vm.teamMember = null;
              vm.includeNotAssigned = true;
            } else if (newAssignee === PSEUDO_ASSIGNEES.NOT_ASSIGNED) {
              vm.teamMember = { id: '0' }; // HACK: чтобы получить неразобранные диалоги - нужно на бэкэнд послать 0, поэтому сделан такой костыль
              vm.includeNotAssigned = true;
            } else if (newAssignee === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED) {
              vm.teamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0];
              vm.includeNotAssigned = true;
            } else {
              vm.teamMember = newAssignee;
              vm.includeNotAssigned = false;
            }

            /**
             * NOTE если мы переключились с оператора на оператора, то wasConflicts должен быть false
             */
            if (!oldAssignee) {
              wasConflicts = true;
            }
          }

          // !!! так сделано, чтобы не делать несколько раз запросы к серверу при фильтроах измменяющих друг друга.
          //  Возникает вопрос: а почему просто не вызвать return в условии выше? А потому что все остальные действия в вотчере должны выполниться, если вдруг изменилось сразу несколько фильтров
          if (!wasConflicts) {
            if (vm.isSearch) {
              // если хотя бы 1 фильтр изменился и при этом до этого был активирован поиск - сразу же закрываем поиск, т.к. нельзя одновременно использовать и фильтры, и поиск
              closeSearch();
            } else {
              // иначе - просто полностью обновляем список диалогов
              getConversations(true);
            }
          }
        }
      }
    }

    function onChanges(changes) {
      if (changes.conversationId && !changes.conversationId.isFirstChange()) {
        for (var i = 0; changes.conversationId.currentValue && i < vm.conversations.length; i++) {
          if (vm.conversations[i].id === changes.conversationId.currentValue) {
            vm.conversation = vm.conversations[i];
            return;
          }
        }

        vm.conversation = null;
      }
    }

    function destroy() {
      muteNotificationsService.stop();
      refreshTimeTickInterval && $interval.cancel(refreshTimeTickInterval);

      for (var i = 0; i < hotKeysArray.length; i++) {
        hotkeys.del(hotKeysArray[i].combo);
      }
    }

    /**
     * Отключение поиска
     */
    function closeSearch() {
      vm.isShowSearchInput = false;
      vm.searchPhrase = '';
      vm.currentSearchPhrase = '';

      if (vm.isSearch) {
        vm.isSearch = false;
        vm.search.dateRange.endDate = moment(); // Сбрасываем дату в поиске на дефолтную
        vm.search.dateRange.startDate = moment().subtract(90, 'days'); // Сбрасываем дату в поиске на дефолтную
        getConversations(true);
      }
    }

    /**
     * Функция проверяет удовлетворяет ли диалог фильтрам
     * FIXME: Отрефакторить эту функцию при рефакторинге RTS
     *
     *
     * todo функция оставлена для совместимости старых ответов из РТС
     * @param {Object} data Диалог или данные из ртс который нужно проверить
     * @param {String} status Статус дилога
     * @param {String} assignee Id оператора или псевдоназначение
     * @param {Array} tags Теги
     * @param {String} channelId ID канала
     * @param {CONVERSATION_ASSISTANT_TYPES} assistantType Тип бота
     * @returns {Boolean} True если удовлетворяет фильтрам
     */
    function conversationPartFilter(data, status, assignee, tags, channelId, assistantType) {
      function conversation(prop) {
        return data[prop] ? data[prop] : data[prop.replace('conversation_', '')];
      }

      // проверка назначения диалога
      if (
        !~[PSEUDO_ASSIGNEES.ALL, PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED, PSEUDO_ASSIGNEES.NOT_ASSIGNED].indexOf(
          assignee,
        )
      ) {
        if (!conversation('assignee') || conversation('assignee').id != assignee.id) {
          return false;
        }
      } else if (
        assignee === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED &&
        conversation('assignee') &&
        conversation('assignee').id != vm.djangoUser.id
      ) {
        return false;
      } else if (assignee === PSEUDO_ASSIGNEES.NOT_ASSIGNED && conversation('assignee')) {
        return false;
      }

      // проверка на метку об ответе администратора
      if (vm.unanswered && !conversation('conversation_admin_unread_count')) {
        return false;
      }

      // проверка статуса диалога (открыт/закрыт)
      switch (status) {
        case STATUSES.CLOSED:
          if (!conversation('conversation_closed')) {
            return false;
          }
          break;
        case STATUSES.DELAYED:
          // HACK: поскольку поля приходят с дерьмовыми именами - пришлось скопипастить код из conversationModel.isDelayed. FIXME: Когда вместе с репликой будет приходить диалог - переделать на вызов conversationModel.isDelayed
          if (
            !(
              !conversation('conversation_closed') &&
              conversation('conversation_delayed_until') &&
              conversation('conversation_delayed_until') > conversation('conversation_last_update')
            )
          ) {
            return false;
          }
          break;
        case STATUSES.OPENED:
          // HACK: поскольку поля приходят с дерьмовыми именами - пришлось скопипастить код из conversationModel.isDelayed. FIXME: Когда вместе с репликой будет приходить диалог - переделать на вызов conversationModel.isDelayed
          if (
            conversation('conversation_closed') ||
            (!conversation('conversation_closed') &&
              conversation('conversation_delayed_until') &&
              conversation('conversation_delayed_until') > conversation('conversation_last_update'))
          ) {
            return false;
          }
          break;
      }

      // проверка канала диалога
      if (channelId === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]) {
        // если выбран псевдоканал "Все каналы" - надо проверить только разрешения пользователя на канал в диалоге. Если разрешений нет - диалог в канал не добавляется
        if (conversation('channel') && !channelModel.hasPermissions(conversation('channel').id)) {
          return false;
        }
      } else if (channelId === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL]) {
        // если выбран псевдоканал "Без канала" - надо проверить, что у диалога нет канала, что логично
        if (conversation('channel')) {
          return false;
        }
      } else {
        // если выбран какой-либо канал (не псевдоканал), то в список диалогов попадут только диалоги с таким же каналом
        if (
          !conversation('channel') ||
          channelId != conversation('channel').id ||
          !channelModel.hasPermissions(conversation('channel').id)
        ) {
          return false;
        }
      }

      // проверка удовлетворения тегов диалога
      var hasFilter = tags.length == 0;
      for (var i = 0; i < tags.length; i++) {
        var conversation_tags = conversation('conversation_tags');
        for (var j = 0; j < conversation_tags.length; j++) {
          if (conversation_tags[j] == tags[i].tag) {
            hasFilter = true;
          }
        }
      }
      if (!hasFilter) {
        return false;
      }

      switch (true) {
        case conversation('assistant_type') === vm.CONVERSATION_ASSISTANT_TYPES.LEAD_BOT:
          break;
        case conversation('assistant_type') === vm.CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT:
          break;
        case conversation('assistant_type') === vm.CONVERSATION_ASSISTANT_TYPES.TELEGRAM_BOT:
          break;
        case conversation('assistant_type') !== assistantType:
          return false;
      }

      return true;
    }

    /**
     * Функция проверяет удовлетворяет ли диалог фильтрам
     * FIXME: Отрефакторить эту функцию при рефакторинге RTS
     *
     * @param {Object} conversation Диалог или данные из ртс который нужно проверить
     * @param {String} status Статус дилога
     * @param {String} assignee Id оператора или псевдоназначение
     * @param {Array} tags Теги
     * @param {String} channelId ID канала
     * @param {CONVERSATION_ASSISTANT_TYPES} assistantType Тип бота
     *
     * @returns {Boolean} True если удовлетворяет фильтрам
     */
    function conversationInFilter(conversation, status, assignee, tags, channelId, assistantType) {
      switch (true) {
        // если назначен не в псевдоканал и есть назнеаченный пользователь
        //  или диалог не на назначенного пользователя
        case (![PSEUDO_ASSIGNEES.ALL, PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED, PSEUDO_ASSIGNEES.NOT_ASSIGNED].includes(
          assignee,
        ) &&
          !conversation.assignee) ||
          (typeof assignee === 'object' && 'id' in assignee && conversation.assignee.id !== assignee.id):
        // если назначен на меня, но неверный оператор, то неудовлетворяет фильтру
        case assignee === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED &&
          conversation.assignee &&
          conversation.assignee.id != vm.djangoUser.id:
        // если назначен и одновременно не назначен???
        case assignee === PSEUDO_ASSIGNEES.NOT_ASSIGNED && conversation.assignee:
        // если ответа оператору нет, то не удовлтворяет фильтру
        case vm.unanswered && !conversation.admin_unread_count:
          return false;
      }

      // проверка статуса диалога (открыт/закрыт)
      switch (status) {
        case STATUSES.CLOSED:
          if (!conversation.closed) {
            return false;
          }
          break;
        case STATUSES.DELAYED:
          // HACK: поскольку поля приходят с дерьмовыми именами - пришлось скопипастить код из conversationModel.isDelayed.
          // FIXME: Когда вместе с репликой будет приходить диалог - переделать на вызов conversationModel.isDelayed
          if (
            !(
              !conversation.closed &&
              conversation.delayed_until &&
              conversation.delayed_until > conversation.last_update
            )
          ) {
            return false;
          }
          break;
        case STATUSES.OPENED:
          // HACK: поскольку поля приходят с дерьмовыми именами - пришлось скопипастить код из conversationModel.isDelayed.
          // FIXME: Когда вместе с репликой будет приходить диалог - переделать на вызов conversationModel.isDelayed
          if (
            conversation.closed ||
            (!conversation.closed &&
              conversation.delayed_until &&
              conversation.delayed_until > conversation.last_update)
          ) {
            return false;
          }
          break;
      }

      // проверка канала диалога
      if (channelId === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]) {
        // если выбран псевдоканал "Все каналы" - надо проверить только разрешения пользователя на канал в диалоге. Если разрешений нет - диалог в канал не добавляется
        if (conversation.channel && !channelModel.hasPermissions(conversation.channel.id)) {
          return false;
        }
      } else if (channelId === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL]) {
        // если выбран псевдоканал "Без канала" - надо проверить, что у диалога нет канала, что логично
        if (conversation.channel) {
          return false;
        }
      } else {
        // если выбран какой-либо канал (не псевдоканал), то в список диалогов попадут только диалоги с таким же каналом
        if (
          !conversation.channel ||
          channelId != conversation.channel.id ||
          !channelModel.hasPermissions(conversation.channel.id)
        ) {
          return false;
        }
      }

      // проверка удовлетворения тегов диалога
      for (let i = 0; i < tags.length; i++) {
        for (let j = 0; j < conversation.tags.length; j++) {
          if (conversation.tags[j] === tags[i].tag) {
            return true;
          }
        }
      }

      switch (true) {
        case conversation.assistant_type === vm.CONVERSATION_ASSISTANT_TYPES.LEAD_BOT:
          break;
        case conversation.assistant_type === vm.CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT:
          break;
        case conversation.assistant_type === vm.CONVERSATION_ASSISTANT_TYPES.TELEGRAM_BOT:
          break;
        case conversation.assistant_type !== assistantType:
          return false;
      }

      return true;
    }

    /**
     * FIXME: Отрефакторить эту функцию при рефакторинге RTS
     *
     * @param id
     * @param channel
     * @param data
     */
    function conversationUnshift(id, channel, data) {
      if (channel && !channelModel.hasPermissions(channel.id)) {
        return;
      }
      //Делаем защиту от одновременной отправки нескольких запросов по одному диалогу
      if (vm.conversationsRequestQueue.indexOf(id) > -1) {
        if (featureModel.hasAccess(FEATURES_ONLY.FARFOR_FIX)) {
          queueRtsService.addToQueue(data);
        }
        return;
      }
      vm.conversationsRequestQueue.push(id);

      firstValueFrom(conversationModel.get(id)).then(getConversationSuccess);

      function getConversationSuccess(conversation) {
        //delete conversation.conversation.parts;
        //HACK гребаный костыль из-за того что ртс может прислать два сообщение друг за другом.
        var conversationsFilter = $filter('filter')(vm.conversations, { id: id }, true);
        if (conversationsFilter.length == 0) {
          //Т.к мы не можем доверять порядку ртс сообщений, возможно полученый диалог уже не удовлетворяет фильтрам, нужно проверить
          if (
            conversationPartFilter(
              conversation,
              vm.status,
              vm.assignee,
              vm.selectedTags,
              vm.channel.id,
              vm.assistantType,
            )
          ) {
            vm.conversations.unshift(conversation);
          }
        } else {
          let newConversation = {
            ...conversationsFilter[0],
            part_last: conversation.part_last,
            parts_count: conversationsFilter[0].parts_count + 1,
          };

          vm.conversations.splice(vm.conversations.indexOf(conversationsFilter[0]), 1, newConversation);
        }

        for (var i = 0; i < vm.conversationsRequestQueue.length; i++) {
          if (vm.conversationsRequestQueue[i] == id) {
            if (featureModel.hasAccess(FEATURES_ONLY.FARFOR_FIX)) {
              queueRtsService.executeNext();
            }
            vm.conversationsRequestQueue.splice(i, 1);
          }
        }
      }
    }

    /**
     * Получить разрешенных ассистенов
     * @returns {CONVERSATION_ASSISTANT_TYPES[]}
     */
    function getAllowedAssistants() {
      const assistants = [];

      if (featureModel.hasAccess(FEATURES.AI_SALES)) {
        assistants.push(CONVERSATION_ASSISTANT_TYPES.AI_SALES);
      }

      if (featureModel.hasAccess(FEATURES.YANDEX_AI)) {
        assistants.push(CONVERSATION_ASSISTANT_TYPES.YANDEX_AI);
      }

      if (featureModel.hasAccess(FEATURES.FACEBOOK_BOT)) {
        assistants.push(CONVERSATION_ASSISTANT_TYPES.FACEBOOK_BOT);
      }

      if (vm.accessToAiBot.hasAccess || featureModel.hasAccess(FEATURES.CHAT_GPT)) {
        assistants.push(CONVERSATION_ASSISTANT_TYPES.CHAT_GPT);
      }

      assistants.push(CONVERSATION_ASSISTANT_TYPES.TELEGRAM_BOT);
      assistants.push(CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT);

      return assistants;
    }

    /**
     * Получение списка диалогов
     *
     * @param {Boolean=} forceReload Принудительно перезагрузить весь список, а не добавлять полученные диалоги в конец текущего списка
     * @return {Promise}
     */
    function getConversations(forceReload) {
      if (forceReload) {
        vm.conversations = [];
        vm.conversationsPaginator = null;
      }

      vm.conversationsLoading = true;

      if (vm.currentSearchPhrase) {
        // если введена фраза для поиска - нужно искать диалоги по введённой строке
        return firstValueFrom(
          conversationModel.search(
            vm.currentApp.id,
            vm.currentSearchPhrase,
            vm.search.dateRange.startDate,
            vm.search.dateRange.endDate,
            vm.conversationsPaginator,
          ),
        )
          .then(getConversationsSuccess)
          .finally(getConversationsFinally)
          .finally(setSearchToTrue);
      } else {
        // если строка поиска не задана - нужно искать диалоги по заданным фильтрам
        const tags = !vm.selectedTags.length && vm.includeNoTagsApplied ? null : $filter('map')(vm.selectedTags, 'tag');
        return firstValueFrom(
          conversationModel.getList(
            vm.currentApp.id,
            closed,
            delayed,
            vm.unanswered,
            vm.includeNotAssigned,
            vm.channel.id,
            vm.teamMember && vm.teamMember.id,
            tags,
            vm.assistantType,
            vm.conversationsPaginator,
          ),
        )
          .then(getConversationsSuccess)
          .finally(getConversationsFinally)
          .finally(setSearchToFalse);
      }

      function getConversationsSuccess(data) {
        for (var i = 0; i < data.conversations.length; i++) {
          muteNotificationsService.addConversationId(data.conversations[i].id);
        }

        vm.conversations = vm.conversations.concat(data.conversations);
        vm.conversationsPaginator = data.paginatorParams;

        // Запустить онбординг пользователя (создать диалог), если пользователь не активировался
        // NOTE времено поменяли процедуру онбординга (создания диалога)
        //if (vm.conversations.length == 0 && !vm.currentApp.activation.reply_user) {
        //  conversationModel.runOnboarding();
        //}
      }

      function getConversationsFinally() {
        $scope.$apply(() => {
          vm.conversationsLoading = false;
        });
      }

      function setSearchToFalse() {
        vm.isSearch = false;
      }

      function setSearchToTrue() {
        vm.isSearch = true;
      }
    }

    /**
     * Обработчик каналов RTS
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      if (vm.isSearch) {
        return;
      }

      let channel = info.channel,
        data = info.data;

      // @formatter:off
      switch (true) {
        case channel.includes('conversation_started_user.'):
          conversationStartedUserHandler(data);
          break;
        case channel.includes('conversation_parts_batch.'):
          if (featureModel.hasAccess(FEATURES_ONLY.FARFOR_FIX)) {
            queueRtsService.execute(data);
          } else {
            conversationsPartBatchesHandler(data);
          }
          break;
        case channel.includes('conversation_delay_finished.'):
          conversationDelayFinishedHandler(data);
          break;
        case channel.includes('conversation_reply_changed.'):
          conversationReplyChangedHandler(data);
          break;
        case channel.includes('conversation_replied_by_user_read.'):
          conversationRepliedByUserReadHandler(data);
          break;
        case channel.includes('user_presence_changed.'):
          userPresenceChangedHandler(data);
          break;
        case channel.includes('user_merge_finished.'):
          userMergeFinishedHandler(data);
          break;
        case channel.includes('user_removed.'):
          usersRemovedHandler(data);
          break;
        case channel.includes('system_log_added.'):
          systemLogAddedHandler(data);
          break;
      }
      // @formatter:on
    }

    /**
     * Обработчик канала conversation_started_user.
     * Старт диалога с пользователем
     * @param data
     */
    function conversationStartedUserHandler(data) {
      // Вставляем только те разговоры, которые юзер сам начал
      // Если начал админ диалог, то вставлять надо только в случае если есть ответ

      if (data.message != null) {
        return;
      }

      let callApply = false;

      if (conversationPartFilter(data, vm.status, vm.assignee, vm.selectedTags, vm.channel.id, vm.assistantType)) {
        conversationUnshift(data.id, data.channel, data);
        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Обработчик получения массива реплик для правильного порядка сообщений бота и не только
     *
     * @param data
     * @param {Object} data.conversation
     * @param {Array} data.parts
     */
    function conversationsPartBatchesHandler(data) {
      const { conversation = {}, parts = [] } = data;

      const foundConversation = $filter('filter')(vm.conversations, { id: conversation.id }, true)[0];

      // Обновить список диалогов слева
      if (foundConversation) {
        vm.conversations.splice(vm.conversations.indexOf(foundConversation), 1);
      }

      // Не показывать диалоги ботов
      const botList = [CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT, CONVERSATION_ASSISTANT_TYPES.TELEGRAM_BOT];
      if (botList.includes(conversation.assistant_type)) {
        return;
      }

      //Смотрим удовлетворяет дилог фильтрам
      const convInFilter = conversationInFilter(
        conversation,
        vm.status,
        vm.assignee,
        vm.selectedTags,
        vm.channel.id,
        vm.assistantType,
      );
      if (convInFilter) {
        if (foundConversation) {
          updateConversation(conversation, parts);
          vm.conversations.unshift({ ...foundConversation, ...conversation });
        } else if (conversation.replied) {
          // только если в диалоге есть реплики пользьзователя
          // то добавляем его в список
          conversationUnshift(conversation.id, conversation.channel, data);
        }
      }

      // Waiting for mute
      muteNotificationsService.addConversationId(conversation.id);

      $scope.$applyAsync();
    }

    /**
     * Обработчик канала conversation_replied_by_user_read
     * @param data
     */
    function conversationRepliedByUserReadHandler(data) {
      let callApply = false;

      for (let i = 0; i < vm.conversations.length; i++) {
        if (
          !vm.isSearch &&
          vm.conversations[i].id == data.id &&
          vm.conversations[i].important_part_last.direction == 'a2u'
        ) {
          vm.conversations[i].reply_last.read = true;
          vm.conversations[i] = { ...vm.conversations[i] };
          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Постобработка изменения реплики
     * @param data
     */
    function conversationReplyChangedHandler(data) {
      let callApply = false;

      for (let i = 0; i < vm.conversations.length; i++) {
        if (vm.conversations[i].id === data.conversation && vm.conversations[i].important_part_last.id === data.id) {
          vm.conversations[i].important_part_last.body = emojiService.replaceEmojis(data.body);
          vm.conversations[i].important_part_last.edited = data.edited;
          vm.conversations[i].important_part_last.removed = data.removed;
          vm.conversations[i] = { ...vm.conversations[i] };
          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Обработчик канала conversation_delay_finished.
     * @param data
     */
    function conversationDelayFinishedHandler(data) {
      let callApply = false;

      if ([STATUSES.DELAYED].includes(vm.status) && !vm.isSearch) {
        // если выбран статус Отложенные и поиск выключен - нужно удалить диалог из списка
        for (let i = 0; i < vm.conversations.length; i++) {
          for (let j = 0; j < data.conversations.length; j++) {
            var conversation = data.conversations[j];
            if (vm.conversations[i].id === conversation.id) {
              vm.conversations.splice(i, 1);
              callApply = true;
            }
          }
        }
      } else {
        let foundedConversation; // найденный диалог
        let foundedConversationInFilter = false; // найденный диалог удовлетворяет фильтрам
        for (let i = 0; i < data.conversations.length; i++) {
          for (let j = 0; j < vm.conversations.length; j++) {
            if (data.conversations[i].id === vm.conversations[j].id) {
              vm.conversations[j].last_update = moment(data.conversations[i].last_update * 1000);
              foundedConversation = vm.conversations[j];
              foundedConversationInFilter = conversationInFilter(
                foundedConversation,
                vm.status,
                vm.assignee,
                vm.selectedTags,
                vm.channel.id,
                vm.assistantType,
              );
              callApply = true;
            }
          }

          const iterConversationInFilter = conversationInFilter(
            data.conversations[i],
            vm.status,
            vm.assignee,
            vm.selectedTags,
            vm.channel.id,
            vm.assistantType,
          );
          if (!foundedConversation && !vm.isSearch && iterConversationInFilter) {
            conversationUnshift(data.conversations[i].id, data.conversations[i].channel, data);
            callApply = true;
          } else if (foundedConversation && !vm.isSearch && foundedConversationInFilter) {
            vm.conversations.splice(vm.conversations.indexOf(foundedConversation), 1);
            vm.conversations.unshift({ ...foundedConversation });
            callApply = true;
          }

          foundedConversation = undefined;
          foundedConversationInFilter = false;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Постобработка изменения пристутствия пользователя
     * @param data
     */
    function userPresenceChangedHandler(data) {
      let callApply = false;

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < vm.conversations.length; j++) {
          if (vm.conversations[j].user && vm.conversations[j].user.id === data[i].user) {
            vm.conversations[j].user.presence = data[i].presence;
            vm.conversations[j] = { ...vm.conversations[j] };
            callApply = true;
          }
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Постобработка слияния пользователя
     * @param data
     */
    function userMergeFinishedHandler(data) {
      let callApply = false;

      for (let i = 0; i < vm.conversations.length; i++) {
        if (vm.conversations[i].user.id === data.removed) {
          callApply = true;

          firstValueFrom(conversationModel.get(vm.conversations[i].id)).then(function (conversation) {
            vm.conversations[i] = conversation;

            callApply && $scope.$applyAsync();
          });

          break;
        }
      }
    }

    /**
     * Постобработка удаления пользователя
     * @param data
     */
    function usersRemovedHandler(data) {
      // УДАЛЕНИЕ ПОЛЬЗОВАТЕЛЕЙ
      let callApply = false;
      let removedUserIds = data.ids; // при удалении приходит массив ids с ID удалённых пользователей

      // поиск и удаление диалогов с пользователями, чьи ID пришли в RTS
      for (let i = 0; i < removedUserIds.length; i++) {
        const removedUserId = removedUserIds[i];

        for (let j = vm.conversations.length - 1; j >= 0; j--) {
          if (vm.conversations[j].user.id === removedUserId) {
            vm.conversations.splice(j, 1);
            callApply = true;
          }
        }

        // если был удалён текущий диалог - просто зануляем его
        // todo upd 09-06-2021... хрен знает зачем это, оставил чтоб ничо не упало
        if (vm.conversation && ~removedUserId.indexOf(vm.conversation.user.id)) {
          selectConversation(null);
          callApply = true;
        }
      }

      // если в списке диалогов больше не осталось диалогов, но можно загрузить ещё - загружаем ещё
      if (!vm.conversations.length && vm.conversationsPaginator.paginatePosition) {
        getConversations();
        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Обработчик системных каналов
     * todo 1) какой-то костыль для тегов
     * @param data
     */
    function systemLogAddedHandler(data) {
      if (data.type === SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED) {
        let callApply = false;
        let tag = data.meta_data.tag;

        let tagsIndex = vm.tags.indexOf($filter('filter')(vm.tags, { tag: tag })[0]);
        if (tagsIndex !== -1) {
          vm.tags.splice(tagsIndex, 1);
          callApply = true;
        }

        for (let i = 0; i < vm.conversations.length; i++) {
          let removedTagIndex = vm.conversations[i].tags.indexOf(tag);

          if (removedTagIndex !== -1) {
            vm.conversations[i].tags.splice(removedTagIndex, 1);
            vm.conversations[i] = { ...vm.conversations[i] };
            callApply = true;
          }
        }

        callApply && $scope.$applyAsync();
      }
    }

    /**
     * Обновляет данные диалога на пришедшие из ртс
     * Функция написана из-за того, что при включенном поиске два диалога равны друг другу,
     * не просто когда их референсы или ID равны,
     * а когда равны ID найденной реплики
     *
     * @param {Object} conversation Диалог
     * @param {Array} parts реплики диалога
     */
    function updateConversation(conversation, parts) {
      conversationModel.parse(conversation);
      parts.forEach((el) => conversationPartModel.parse(el));

      // Поиск самой последней реплики с самым поздним временем создания
      let partCreatedTimes = parts.map((part) =>
        isMomentInstance(part.created) ? part.created.milliseconds() : part.created,
      );
      let latestTime = Math.max(...partCreatedTimes);
      let lastUpdatedPart = parts[partCreatedTimes.lastIndexOf(latestTime)];

      // Обнуление счётчика не прочитанных админом сообщений, если последней репликой был ответ админа или статья в БЗ
      if ([CONVERSATION_PART_TYPES.REPLY_ADMIN, CONVERSATION_PART_TYPES.ARTICLE].includes(lastUpdatedPart.type)) {
        conversation.admin_unread_count = 0;
      }

      // В "последние" должны попадать все реплики, кроме системных
      if (!isSystemConversationPartType(lastUpdatedPart)) {
        conversation.important_part_last = lastUpdatedPart;
        conversation.reply_last = lastUpdatedPart;
      }
    }

    /**
     * Прокидывание функции для показа поповера use-calendly-popover из списка каналов
     * Так приходится делать на границе Angular и AngularJS
     *
     * @param {function} fn Функция показа поповера
     */
    function initShowUseCalendlyPopoverFn(fn) {
      vm.showUseCalendlyPopover = fn;
    }

    /**
     * Равны ли 2 диалога друг другу
     * Функция написана из-за того, что при включенном поиске два диалога равны друг другу
     * не просто когда их референсы или ID равны,
     * а когда равны ID найденной реплики
     *
     * @param {Object} conversation1 Диалог №1
     * @param {Object} conversation2 Диалог №2
     * @returns {Boolean}
     */
    function isConversationsEqual(conversation1, conversation2) {
      // NOTE: для удобства я разбил условие равенства диалогов на несколько
      // если поиск отключён, то диалоги равны тогда, когда равны их ID
      if (conversation1 && conversation2 && !vm.isSearch && conversation1.id == conversation2.id) {
        return true;
      }

      // если поиск включен, то диалоги равны тогда, когда равны ID найденных реплик
      if (
        conversation1 &&
        conversation2 &&
        vm.isSearch &&
        conversation1.found_part &&
        conversation2.found_part &&
        conversation1.found_part.id == conversation2.found_part.id
      ) {
        return true;
      }

      return false;
    }

    /**
     * Является ли datetime инстансом Moment'а
     *
     * @param datetime - Datetime
     *
     * @return {boolean}
     */
    function isMomentInstance(datetime) {
      return moment.isMoment(datetime);
    }

    /**
     * Выбраны ли в данный момент фильтры по умолчанию
     *
     * @returns {Boolean}
     */
    function isDefaultFilters() {
      return (
        vm.status === STATUSES.OPENED &&
        vm.assignee === PSEUDO_ASSIGNEES.ALL &&
        vm.selectedTags.length === 0 &&
        vm.channel.id === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS] &&
        !vm.unanswered
      );
    }

    /**
     * Дизейблить ли кнопку выбора статуса открытости диалога
     *
     * @returns {boolean}
     */
    function isStatusFilterDisabled() {
      return !!vm.unanswered || vm.assistantType === CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT;
    }

    /**
     * Дизейблить ли элемент фильтра в фильтре статуса открытости диалога
     *
     * @param {STATUSES} status Статус открытости диалога
     * @returns {boolean}
     */
    function isStatusFilterItemDisabled(status) {
      return status === STATUSES.DELAYED && vm.assistantType === CONVERSATION_ASSISTANT_TYPES.YANDEX_AI;
    }

    /**
     * Является ли тип реплики системным
     *
     * @param partType - Реплика диалога
     *
     * @return {boolean}
     */
    function isSystemConversationPartType(partType) {
      return CONVERSATION_PART_SYSTEM_TYPES.includes(partType.type);
    }

    function onAssigneeValueChange(value) {
      $timeout(() => {
        if (Object.values(PSEUDO_ASSIGNEES).includes(value) || value.hasOwnProperty('id')) {
          vm.assignee = value;
        } else if (Object.values(CONVERSATION_ASSISTANT_TYPES).includes(value)) {
          vm.assistantType = value;
        }
      });
    }

    /**
     * Действия при закрытии диалога
     * NOTE: эту функцию нужно вызывать из родительского компонента, т.к. список диалогов по-другому никак не узнает о закрытии диалога, но я пока не могу это реализовать, не понимаю как
     *  Сейчас всё и так работает, через RTS. Но функцию всё равно не удалять, когда-нибудь я решу эту проблему
     *
     * @param conversation
     */
    function onConversationClose(conversation) {
      // если поиск отключен и текущий диалог не соответствует выбранным фильтрам - его нужно убрать из списка
      if (
        !vm.isSearch &&
        !conversationModel.isSatisfiesFilters(
          conversation,
          closed,
          delayed,
          vm.unanswered,
          vm.includeNotAssigned,
          vm.channel.id,
          vm.teamMember && vm.teamMember.id,
          $filter('map')(vm.selectedTags, 'tag'),
        )
      ) {
        // приходится производить поиск по ID, т.к. список диалогов мог уже несколько раз перезагрузиться,
        var conversationForRemove = $filter('filter')(vm.conversations, { id: conversation.id }, true)[0];

        if (conversationForRemove) {
          vm.conversations.splice(conversationForRemove, 1);
        }
      }
    }

    /**
     * Выбор диалога
     */
    function selectConversation(conversation) {
      // при любой смене диалога нужно вызвать функцию родительского компонента, если она есть
      if (!isConversationsEqual(vm.conversation, conversation)) {
        vm.conversation = conversation;

        vm.onConversationChange &&
          vm.onConversationChange({
            conversation: conversation,
            isToSearch: vm.isSearch,
          });
      }
    }

    /**
     * Установка горячих клавиш
     */
    function setupHotKeys() {
      var allowIn = ['INPUT', 'SELECT', 'TEXTAREA'];

      addHotKey({
        combo: 'pageup',
        callback: function (event) {
          event.preventDefault();
          trackUseHotKey();
          for (var i = 0; i < vm.conversations.length; i++) {
            if (vm.conversations[i].id == vm.conversation.id) {
              var j = i - 1;
              if (j >= 0) {
                selectConversation(vm.conversations[j]);
              }
              return;
            }
          }
        },
      });

      addHotKey({
        combo: 'pagedown',
        callback: function (event) {
          event.preventDefault();
          trackUseHotKey();
          for (var i = 0; i < vm.conversations.length; i++) {
            if (vm.conversations[i].id == vm.conversation.id) {
              var j = i + 1;
              if (j < vm.conversations.length) {
                selectConversation(vm.conversations[j]);
              }
              return;
            }
          }
        },
      });

      /**
       * Устанавливает горячую клвашу и записывает хоткей в массив
       * Засовывать в массив приходится мз-за того что после перехода на другую страницу нужна убрать хоткеии текущей
       *
       * @param {Object} hotKeyObject хранит комбинацию клавиш combo и каллбек callback
       */
      function addHotKey(hotKeyObject) {
        hotKeysArray.push(hotKeyObject);
        hotkeys.add({
          combo: hotKeyObject.combo,
          allowIn: allowIn,
          callback: hotKeyObject.callback,
        });
      }
    }

    /**
     * Сабмит формы с поиском. Нужна для отработки нажатия на enter
     *
     * @param {Boolean} isValid Валидность формы
     * @param {String} searchPhrase Фраза для поиска
     * @param {Boolean=} preventPhraseCompare Не сравнивать текущую поисковую фразу с предыдущей
     */
    function submitSearchForm(isValid, searchPhrase, preventPhraseCompare) {
      // Если форма невалидна, то не выполняем поиск
      if (!isValid) {
        return;
      }

      if (preventPhraseCompare || vm.currentSearchPhrase !== searchPhrase) {
        trackEnteredSearchQuery();
        vm.currentSearchPhrase = searchPhrase;
        getConversations(true);
      }
    }

    /**
     * Включение или выключение фильтрации по боту
     *
     * @param {CONVERSATION_ASSISTANT_TYPES} assistantType Тип бота, по которому будет происходить фильтрация
     */
    function toggleAssistantType(assistantType) {
      vm.assistantType = assistantType;
      vm.status = STATUSES.OPENED;
    }

    /**
     * Трек клика на фильтр 'Показывать только неотвеченные диалоги"
     */
    function trackClickOnUnanswered() {
      carrotquestHelper.track('Диалоги - клик на "Показать только неотвеченные диалоги"', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Права пользователя': vm.djangoUser.prefs[vm.currentApp.id].permissions,
      });
    }

    /**
     * Трек клика нажатия на фильтр в списке фильтров статуса диалога
     */
    function trackClickOnTheFilterByStatusButton() {
      carrotquestHelper.track('Диалоги - Применил  фильтр по статусу диалога', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Права пользователя': vm.djangoUser.prefs[vm.currentApp.id].permissions,
      });
    }

    /**
     * Трек применения фильтра по дате по клику в датапикере
     */
    function trackClickOnTheFilterByDateButton() {
      carrotquestHelper.track('Диалоги - применил фильтр по дате в поиске', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Права пользователя': vm.djangoUser.prefs[vm.currentApp.id].permissions,
      });
    }

    /**
     * Трек клика на кнопку поиска
     */
    function trackClickSearchButton() {
      carrotquestHelper.track('Диалоги - Клик по поиску', { App: vm.currentApp.name });
    }

    /**
     * Трек поискового запроса
     */
    function trackEnteredSearchQuery() {
      carrotquestHelper.track('Диалоги - Ввел поисковый запрос', { App: vm.currentApp.name });
    }

    /**
     * Трек открытия меню
     * @param isOpened
     */
    function trackOpenMenu(isOpened) {
      isOpened && carrotquestHelper.track('Диалоги - клик на бургер в фильтре диалогов');
    }

    /**
     * Трек использования горячей клавиши
     */
    function trackUseHotKey() {
      carrotquestHelper.track('Диалоги - использовал горячую клавишу', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }
  }
})();
