import { EventEmitter, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import Quill, { Range } from 'quill';
import Delta from 'quill-delta';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EditorCustomBlotButton } from '@panel/app/partials/editor/blot/editor-custom-blot-button';
import { EditorCustomBlotFile } from '@panel/app/partials/editor/blot/editor-custom-blot-file';
import { EditorCustomBlotLink } from '@panel/app/partials/editor/blot/editor-custom-blot-link';
import { EditorCustomBlotSize } from '@panel/app/partials/editor/blot/editor-custom-blot-size';
import { EditorCustomBlotUserProperty } from '@panel/app/partials/editor/blot/editor-custom-blot-user-property';
import { EDITOR_FORMAT } from '@panel/app/partials/editor/editor.constant';
import { EditorOptions } from '@panel/app/partials/editor/editor.type';
import { EditorFormatButton } from '@panel/app/partials/editor/format/button/editor-format-button.type';
import { EditorFormatFile } from '@panel/app/partials/editor/format/file/editor-format-file.type';
import { EditorFormatLink } from '@panel/app/partials/editor/format/link/editor-format-link.type';
import { EditorFormatUserProperty } from '@panel/app/partials/editor/format/user-property/editor-format-user-property.type';
import { DestroyService } from '@panel/app/services';

@Injectable({ providedIn: 'root' })
export class EditorService {
  public clickOnEditor$: EventEmitter<HTMLElement> = new EventEmitter();
  public changeSelection$: Observable<void>;

  private changeSelection$$: Subject<void> = new Subject<void>();

  constructor(private destroy$: DestroyService, @Inject(WINDOW) readonly windowRef: Window) {
    this.changeSelection$ = this.changeSelection$$.asObservable();
  }

  public formatBold(editor: Quill, state: boolean): void {
    editor.format('bold', state);
  }

  public formatButtonDelete(editor: Quill, selection: Range | null): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotButton) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta().retain(startPosition).delete(endPosition);

    editor.updateContents(delta, 'user');
  }

  public formatFileDelete(editor: Quill, selection: Range | null): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotFile) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta().retain(startPosition).delete(endPosition);

    editor.updateContents(delta, 'user');
  }

  public formatButtonInsert(editor: Quill, selection: Range | null, format: EditorFormatButton): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotButton) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta()
      .retain(startPosition)
      .delete(endPosition)
      .insert(format.text, {
        button: {
          href: format.href,
          target: format.target,
        },
      });

    editor.updateContents(delta, 'user');
  }

  public formatHeader(editor: Quill, lever: number | boolean): void {
    editor.format('header', lever);
  }

  public formatFileInsert(editor: Quill, selection: Range | null, format: EditorFormatFile): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotFile) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta()
      .retain(startPosition)
      .delete(endPosition)
      .insert(format.text, {
        file: {
          href: format.href,
          target: format.target,
        },
      });

    editor.updateContents(delta, 'user');
  }

  public formatLinkInsert(editor: Quill, selection: Range | null, format: EditorFormatLink): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotLink) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta()
      .retain(startPosition)
      .delete(endPosition)
      .insert(format.text, {
        link: {
          href: format.href,
          target: format.target,
        },
      });

    editor.updateContents(delta, 'user');
  }

  public formatLinkOpen(href: string): void {
    this.windowRef.open(href, '_blank', 'noopener,noreferrer');
  }

  public formatLinkDelete(editor: Quill, selection: Range | null): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotLink) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta().retain(startPosition).delete(endPosition);

    editor.updateContents(delta, 'user');
  }

  public formatSize(editor: Quill, size: number | boolean): void {
    if (size === false) {
      editor.format('size', false);
    } else {
      editor.format('size', `${size}px`);
    }
  }

  public formatUserPropertyInsert(editor: Quill, selection: Range | null, format: EditorFormatUserProperty): void {
    let startPosition = selection ? selection.index : 0;
    let endPosition = selection ? selection.length : 0;

    let [leaf] = editor.getLeaf(startPosition);
    if (leaf && leaf.parent instanceof EditorCustomBlotUserProperty) {
      startPosition = editor.getIndex(leaf);
      endPosition = leaf.length();
    }

    let delta = new Delta()
      .retain(startPosition)
      .delete(endPosition)
      .insert(format.prettyName, {
        userProperty: {
          defaultValue: format.defaultValue,
          prettyName: format.prettyName,
          propertyName: format.property,
        },
      });

    editor.updateContents(delta, 'api');
  }

  public hasFormat(format: string, editor: Quill, selection: Range | null): boolean {
    return !!editor.getFormat(selection ?? undefined)[format];
  }

  public init(el: HTMLElement, options: EditorOptions): Quill {
    Quill.register(EditorCustomBlotButton);
    Quill.register(EditorCustomBlotFile);
    Quill.register(EditorCustomBlotLink);
    Quill.register(EditorCustomBlotSize);
    Quill.register(EditorCustomBlotUserProperty);

    let editor = new Quill(el, {
      modules: {
        toolbar: false, // False, потому что toolbar кастомный
      },
      placeholder: options.placeholder ?? '',
      theme: 'snow',
      readOnly: options.readOnly ?? false,
      formats: options.format === 'text' ? [] : options.formats.filter((format) => format !== EDITOR_FORMAT.DIVIDER),
    });

    editor.root.innerHTML = options.initialValue ?? '';
    editor.root.classList.add('mousetrap'); // Чтобы не мешало работе горячих клавиш

    // Нужно отслеживать клики по редактору для реализации сложной логики с форматированием
    fromEvent<Event>(editor.root, 'click').subscribe((event) => {
      event.preventDefault();

      this.clickOnEditor$.emit(event.target as HTMLElement);
    });

    fromEvent<Event>(editor, 'selection-change')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeSelection$$.next();
      });

    /*
      Исправляет ситуацию, когда НЕ выделяя текст, добавить его форматирование и ни чего не писать.
      Quill добавит span.ql-cursor, и уберёт он его только если что-нибудь отредактировать или изменить положение курсора,
      НО если снять фокус с поля, ни чего не сделав, он оставит этот span.
    */
    fromEvent<FocusEvent>(editor.root, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let cursorElement = editor.root.querySelector('span.ql-cursor');
        if (cursorElement === null) {
          return;
        }

        if (cursorElement.parentNode) {
          cursorElement.parentNode.removeChild(cursorElement);
        }
      });

    return editor;
  }
}
