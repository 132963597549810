import { Injectable } from '@angular/core';
import { isArray, some } from 'lodash-es';

import { App } from '@http/app/app.model';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { PlanCapabilityHelper } from '@panel/app/services/plan-capability-helper/plan-capability-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/** Сервис для работы с пейволами */

@Injectable({ providedIn: 'root' })
export class PaywallService {
  constructor(
    private readonly planCapabilityHelper: PlanCapabilityHelper,
    private readonly uibModalService: UibModalService,
  ) {}

  /**
   * Показ пейволла
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе к фиче
   */
  showPaywall(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null): any {
    if (denialReason === null) {
      return this.showDefaultPaywall(currentApp, denialReason);
    }

    const reasons: ProductFeatureDenialReason[] = isArray(denialReason) ? denialReason : [denialReason];

    if (some(reasons, (reason) => reason.paidPlanVersion === PAID_PLAN_VERSION.LTD)) {
      return this.showLTD2023Paywall(currentApp, denialReason);
    }

    if (some(reasons, 'needChat')) {
      return this.showPaywallForAccessDenial(currentApp, denialReason);
    }

    // Общий пейволл показывается, если пользователь столкнулся с ограничением доступа до нескольких фич одновременно
    return this.showDefaultPaywall(currentApp, denialReason);
  }

  /**
   * Показ пейвола для пользователей превысивших лимит автосообщений
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   * @param messageCount - Количество автосообщений которые собираются активировать
   * @param activeTriggerMessagesCount - Количество активных автосообщений
   * @param showReturnToEditButton - Показ кнопки "Вернуться к редактированию"
   *
   */
  showAutoMessageTotalPaywall(
    currentApp: App,
    denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null,
    messageCount: number,
    activeTriggerMessagesCount: number,
    showReturnToEditButton: boolean = true,
  ) {
    return this.uibModalService.open({
      component: 'cqPlanCapabilityAutoMessageTotalPaywallModal',
      resolve: {
        modalWindowParams: function () {
          return {
            currentApp,
            denialReason,
            messageCount,
            activeTriggerMessagesCount,
            showReturnToEditButton,
          };
        },
      },
      size: 'md modal-dialog-centered',
    });
  }

  /**
   * Показ пейволла для пользователей, которым необходимо написать в чат, чтобы получить доступ до фичи
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   */
  showPaywallForAccessDenial(
    currentApp: App,
    denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null,
  ) {
    return this.uibModalService.open({
      component: 'cqAccessDenialModalWrapper',
      resolve: {
        modalWindowParams: function () {
          return {
            currentApp,
            denialReason,
          };
        },
      },
      size: 'md modal-dialog-centered',
    });
  }

  /**
   * Показ пейволла для пользователей LTD
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   */
  showLTD2023Paywall(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null) {
    return this.uibModalService.open({
      component: 'cqAccessDenialModalWrapper',
      resolve: {
        modalWindowParams: function () {
          return {
            currentApp,
            denialReason,
          };
        },
      },
      size: 'md modal-dialog-centered',
    });
  }

  /**
   * Показ стандартного пейволла с тарифами, таблицами сравнения и аддонами
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   */
  showDefaultPaywall(
    currentApp: App,
    denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null,
  ): void {
    this.planCapabilityHelper.showPaywall(currentApp, denialReason);
  }
}
