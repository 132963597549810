import { flatMapDeep, xor } from 'lodash-es';
import uniq from 'lodash-es/uniq';
import { UUID } from 'short-uuid';
import { create, each, enforce, group, omitWhen, test } from 'vest';

import { TriggerChain, TriggerChainStep } from '@panel/app/http/trigger-chain/internal-types';

import {
  automessageStepValidator,
  triggerChainActionStepValidator,
  triggerChainDelayStepValidator,
  triggerChainFilterStepValidator,
  triggerChainReactionStepValidator,
  triggerChainSendingConditionStepValidator,
} from './trigger-chain-step-validators';

export const triggerChainValidator = create<string, UUID>((chain: TriggerChain) => {
  test('name', () => {
    enforce(chain.name).isNotEmpty();
  });

  let stepUUIDIDsWithoutIncomingConnections = getStepUUIDsWithoutIncomingConnections(chain);
  omitWhen(stepUUIDIDsWithoutIncomingConnections.length === 0, () => {
    each(stepUUIDIDsWithoutIncomingConnections, (stepUUID) => {
      group(stepUUID, () => {
        test(`hasIncomingConnectionsEmpty`, () => {
          enforce(stepUUID).isBlank();
        });
      });
    });
  });

  each(chain.steps, (step) => {
    validateTriggerChainStep(step);
  });
});

export function validateTriggerChainStep(step: TriggerChainStep) {
  switch (step.type) {
    case 'action':
      return triggerChainActionStepValidator(step);
    case 'autoMessage':
      return automessageStepValidator(step);
    case 'filter':
      return triggerChainFilterStepValidator(step);
    case 'reaction':
      return triggerChainReactionStepValidator(step);
    case 'sendingConditions':
      return triggerChainSendingConditionStepValidator(step);
    case 'delay':
      return triggerChainDelayStepValidator(step);
    default:
      return;
  }
}

export function getStepUUIDsWithoutIncomingConnections(chain: TriggerChain): UUID[] {
  let uuids: UUID[] = [];
  let nextUUIDs: UUID[] = [];

  flatMapDeep(chain.steps, (step) => {
    switch (step.type) {
      case 'action':
      case 'autoMessage':
        uuids.push(step.uuid);

        if (step.meta.nextStep) {
          nextUUIDs.push(step.meta.nextStep);
        }
        break;
      case 'sendingConditions':
        if (step.meta.nextStep) {
          nextUUIDs.push(step.meta.nextStep);
        }
        break;
      case 'delay':
      case 'reaction':
      case 'filter':
        uuids.push(step.uuid);

        if (step.meta.nextStep) {
          nextUUIDs.push(step.meta.nextStep);
        }
        if (step.meta.nextStepOnFail) {
          nextUUIDs.push(step.meta.nextStepOnFail);
        }
        break;
      default:
        uuids.push(step.uuid);
    }
  });

  return xor(uuids, uniq(nextUUIDs));
}

/**
 * Это немного костыль, но он мне нравится больше, чем официальное решение.
 * Для того, чтоб адекватно валидировать массивы, надо для каждого теста внутри each добавлять уникальный ключи
 * В качестве альтернативы можно сбрасывать стейт тестов
 * (таким образом мы лишаемся каких-то оптимизаций на стороне веста, но мне не нравится тема, с добавлением уникального ключа на каждый тест, странно это)
 *
 * https://vestjs.dev/docs/writing_tests/advanced_test_features/dynamic_tests
 * https://vestjs.dev/docs/understanding_state
 */
export function resetValidationState() {
  automessageStepValidator.reset();
  triggerChainFilterStepValidator.reset();
  triggerChainReactionStepValidator.reset();
  triggerChainSendingConditionStepValidator.reset();
  triggerChainDelayStepValidator.reset();
  triggerChainActionStepValidator.reset();

  triggerChainValidator.reset();
}
