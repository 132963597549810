import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { CostComponent } from './cost.component';

@NgModule({
  declarations: [CostComponent],
  exports: [CostComponent],
  imports: [CommonModule, NgbPopoverModule, SharedModule, TranslocoModule, TuiLet],
  providers: [SubscriptionStore],
})
export class CostModule {}
