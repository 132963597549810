<ng-container *transloco="let t; read: 'aiSalesSettingsComponent'">
  <div class="container-fluid limited-container padding-bottom-60">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a uiSref="app.content.messagesAjs.chatBot.list">
            {{ t('breadcrumbs.aiSales') }}
          </a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ t('breadcrumbs.aiSalesBotSettings') }}
        </li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-body p-30">
        <div class="wrapper">
          <form (ngSubmit)="saveSettings()">
            <cq-ai-sales-promt [formControl]="settingsForm.controls.prompt"></cq-ai-sales-promt>
            <cq-gpt-function-selector
              class="mt-30"
              [formControl]="settingsForm.controls.gptFunctions"
            ></cq-gpt-function-selector>
            <cq-bot-channels
              class="mt-30"
              [formControl]="settingsForm.controls.channels"
              [channels]="channels"
            ></cq-bot-channels>

            <cq-creativity-level-control
              class="mt-30"
              [formControl]="settingsForm.controls.gptTemperature"
            ></cq-creativity-level-control>

            <hr class="my-30" />

            <div class="mt-30">
              <h4>{{ t('botReply.heading') }}</h4>
              <div class="d-flex">
                <cq-working-time-reply-control
                  class="flex-grow-1"
                  [formControl]="settingsForm.controls.callHumanOperatorMessage.controls.work"
                ></cq-working-time-reply-control>
                <cq-non-working-time-reply-control
                  class="flex-grow-1 ml-20"
                  [formControl]="settingsForm.controls.callHumanOperatorMessage.controls.notWork"
                ></cq-non-working-time-reply-control>
              </div>
            </div>

            <hr class="my-30" />

            <cq-close-timeout-control [formControl]="settingsForm.controls.closeTimeout"></cq-close-timeout-control>

            <div class="mt-30">
              <h4 class="mb-5">{{ t('usersEventAndTag.heading') }}</h4>
              <div class="text-secondary">{{ t('usersEventAndTag.description') }}</div>
              <cq-user-tag-control
                class="mt-15"
                [formControl]="settingsForm.controls.userTag"
                [(tags)]="userTags"
              ></cq-user-tag-control>
              <cq-user-event-control
                class="mt-15"
                [formControl]="settingsForm.controls.userEvent"
                [(userEvents)]="properties.eventTypes"
              ></cq-user-event-control>
              <cq-conversation-tag-control
                class="mt-15"
                [formControl]="settingsForm.controls.conversationTag"
                [(tags)]="conversationTags"
              ></cq-conversation-tag-control>
            </div>

            <div class="mt-30">
              <h4 class="mb-5">{{ t('openAiApiKey.heading') }}</h4>
              <input
                class="form-control"
                [formControl]="settingsForm.controls.openAiApiKey"
              />
            </div>

            <input
              class="hidden"
              id="formSubmit"
              type="submit"
              [disabled]="isApiRequestPerformed"
            />
          </form>
        </div>
      </div>
      <div class="card-footer position-sticky bottom-0 border-0 pt-0">
        <div class="wrapper">
          <hr class="mt-0" />
          <label
            class="btn btn-primary"
            for="formSubmit"
            type="button"
            [ngClass]="{ disabled: isApiRequestPerformed }"
          >
            {{ t('saveSettingsButton') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-container>
