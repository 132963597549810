import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { App } from '@http/app/app.model';
import { Counters } from '@http/channel/channel.model';
import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';
import { DjangoUser } from '@http/django-user/django-user.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { ConversationTag } from '@panel/app/partials/conversation-tags/conversation-tags.component';

@Injectable({
  providedIn: 'root',
})
export class ConversationsStoreService {
  readonly availableAssistants$: BehaviorSubject<CONVERSATION_ASSISTANT_TYPES[]> = new BehaviorSubject<
    CONVERSATION_ASSISTANT_TYPES[]
  >([]);
  readonly channelsCounter$: BehaviorSubject<Counters | null> = new BehaviorSubject<Counters | null>(null);
  readonly currentApp$: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);
  readonly djangoUser$: BehaviorSubject<DjangoUser | null> = new BehaviorSubject<DjangoUser | null>(null);
  /** Теги диалогов */
  readonly tags$: BehaviorSubject<ConversationTag[]> = new BehaviorSubject<ConversationTag[]>([]);
  readonly teamMembers$: BehaviorSubject<TeamMember[]> = new BehaviorSubject<TeamMember[]>([]);

  get currentApp() {
    const currentApp = this.currentApp$.getValue();
    if (!currentApp) {
      throw new Error('Cannot access CurrentApp: value is null');
    }

    return currentApp;
  }
  set currentApp(currentApp: App) {
    this.currentApp$.next(currentApp);
  }

  get djangoUser() {
    const djangoUser = this.djangoUser$.getValue();
    if (!djangoUser) {
      throw new Error('Cannot access DjangoUser: value is null');
    }

    return djangoUser;
  }
  set djangoUser(djangoUser: DjangoUser) {
    this.djangoUser$.next(djangoUser);
  }

  /**
   * Добавление нового тега диалога
   *
   * @param tagName Название тега
   */
  addTag(tagName: string) {
    this.tags$.next([...this.tags$.getValue(), { tag: tagName }]);
  }

  /**
   * Удаление тега диалога
   *
   * @param tagName Название тега
   */
  removeTag(tagName: string) {
    this.tags$.next(this.tags$.getValue().filter((t) => t.tag !== tagName));
  }
}
