import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import Quill from 'quill';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserProperty } from '@http/property/property.model';
import { EDITOR_FORMAT } from '@panel/app/partials/editor/editor.constant';
import { EditorService } from '@panel/app/partials/editor/service/editor.service';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EditorService],
})
export class EditorComponent extends AbsCVAFormControlBasedComponent<string> implements AfterViewInit {
  @ViewChild('editorContainerRef')
  public editorContainerRef!: ElementRef<HTMLElement>;

  @Input({ required: true })
  public format: 'text' | 'html' = 'html';

  @Input({ required: false })
  public minHeight: string | 'auto' = 'auto';

  @Input({ required: false })
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value ?? this.translocoService.translate('editorComponent.placeholder');

    if (this._editor) {
      this._editor.root.dataset['placeholder'] = this._placeholder;
    }
  }

  @Input({ required: false })
  public formats: Array<EDITOR_FORMAT> = [];

  @Input({ required: false })
  public get readOnly(): boolean {
    return this._readOnly;
  }
  public set readOnly(value: boolean) {
    this._readOnly = value ?? false;

    if (this._editor) {
      if (value) {
        this._editor.disable();
      } else {
        this._editor.enable();
      }
    }
  }

  @Input({ required: false })
  public userProperties: UserProperty[] = [];

  @Input({ required: false })
  public validators: ValidatorFn[] = [];

  @Output()
  public editorChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editorCreated: EventEmitter<Quill> = new EventEmitter<Quill>();

  @Output()
  public selectionChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public textChanged: EventEmitter<void> = new EventEmitter<void>();

  public control: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  public get editor(): Quill {
    if (this._editor === null) {
      throw new Error('Editor has not been initialized');
    }

    return this._editor;
  }
  public set editor(editor: Quill) {
    this._editor = editor;
  }

  protected EDITOR_FORMAT: typeof EDITOR_FORMAT = EDITOR_FORMAT;
  protected editorComponentRef: ElementRef<EditorComponent>;
  protected selection: Range | null = null;

  private _editor: Quill | null = null;
  private _placeholder: string = '';
  private _readOnly: boolean = false;

  constructor(
    private editorService: EditorService,
    private elementRef: ElementRef,
    private translocoService: TranslocoService,
  ) {
    super();

    this.editorComponentRef = this.elementRef;
  }

  writeValue(val: any): void {
    super.writeValue(val);

    if (this.hasInitializedEditor()) {
      this.editor.setText(val);
    }
  }

  public ngAfterViewInit(): void {
    let el: HTMLElement = this.editorContainerRef.nativeElement;

    if (this.validators.length > 0) {
      this.initValidators(this.validators);
    }

    this.initEditor(el);
  }

  protected hasInitializedEditor(): boolean {
    return this._editor !== null;
  }

  private initEditor(el: HTMLElement): void {
    this.editor = this.editorService.init(el, {
      format: this.format,
      initialValue: this.control.getRawValue(),
      placeholder: this.placeholder,
      readOnly: this.readOnly,
      formats: this.formats,
    });

    fromEvent<Event>(this.editor, 'editor-change')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editorChanged.emit();
      });

    this.editorService.changeSelection$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectionChanged.emit();
    });

    fromEvent<Event>(this.editor, 'text-change')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let content = '';

        switch (this.format) {
          case 'html':
            content = this.editor.root.innerHTML;
            break;
          case 'text':
            content = this.editor.getText();
            break;
        }

        this.control.setValue(content);
        this.textChanged.emit();
      });

    (this.editorContainerRef.nativeElement.getElementsByClassName('ql-editor')[0] as HTMLElement).style.minHeight =
      this.minHeight;

    setTimeout(() => {
      this.editorCreated.emit(this.editor);
    }, 0);
  }

  private initValidators(validators: ValidatorFn[]): void {
    this.control.setValidators(validators);
  }

  protected isToolbarEmpty(): boolean {
    return this.formats.length === 0;
  }
}
