<ng-container *transloco="let t; read: 'aiContentMakerCreativityLevelComponent'">
  <label for="creativity">
    <h3>{{ t('label') }}</h3>
  </label>
  <ngx-slider
    id="creativity"
    [formControl]="control"
    [options]="sliderOptions"
  ></ngx-slider>
  <div class="d-flex justify-content-between">
    <span>{{ t('slider.labels.accuracy') }}</span>
    <span>{{ t('slider.labels.creativity') }}</span>
  </div>
</ng-container>
