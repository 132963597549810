import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Quill from 'quill';

import { EditorService } from '../../service/editor.service';
import { EDITOR_FORMAT_HEADERS } from './editor-format-header.constant';

@Component({
  selector: 'cq-editor-format-header',
  templateUrl: './editor-format-header.component.html',
  styleUrls: ['./editor-format-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFormatHeaderComponent {
  @Input({ required: true })
  public editor!: Quill;

  protected readonly EDITOR_FORMAT_HEADERS = EDITOR_FORMAT_HEADERS;

  constructor(private editorService: EditorService) {}

  protected onClickSetHeaderButton(level: number | boolean): void {
    this.formatHeader(level);
  }

  private formatHeader(level: number | boolean): void {
    this.editorService.formatHeader(this.editor, level);
  }
}
