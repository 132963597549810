import { CommonModule, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { AiContentMakerComponent } from '@panel/app/pages/integrations/content/ai-content-maker/ai-content-maker.component';
import { AiContentMakerCompanyInfoComponent } from '@panel/app/pages/integrations/content/ai-content-maker/ai-content-maker-company-info/ai-content-maker-company-info.component';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AiContentMakerApiKeyComponent } from './ai-content-maker-api-key/ai-content-maker-api-key.component';
import { AiContentMakerCreativityLevelComponent } from './ai-content-maker-creativity-level/ai-content-maker-creativity-level.component';
import { AiContentMakerFaqComponent } from './ai-content-maker-faq/ai-content-maker-faq.component';
import { AiContentMakerGptVersionComponent } from './ai-content-maker-gpt-version/ai-content-maker-gpt-version.component';
import { AiContentMakerPersonAndTaskComponent } from './ai-content-maker-person-and-task/ai-content-maker-person-and-task.component';
import { AiContentMakerRulesComponent } from './ai-content-maker-rules/ai-content-maker-rules.component';

@NgModule({
  declarations: [
    AiContentMakerComponent,
    AiContentMakerCompanyInfoComponent,
    AiContentMakerPersonAndTaskComponent,
    AiContentMakerFaqComponent,
    AiContentMakerRulesComponent,
    AiContentMakerGptVersionComponent,
    AiContentMakerApiKeyComponent,
    AiContentMakerCreativityLevelComponent,
  ],
  exports: [AiContentMakerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    IntegrationNameControlModule,
    NgIf,
    UIRouterModule,
    SharedModule,
    NgbAlert,
    NgxSliderModule,
  ],
})
export class AiContentMakerModule {}
