<ng-container *transloco="let t; read: 'messageInputComponent'">
  <cq-editor
    class="no-border"
    [formats]="[]"
    [(ngModel)]="quillValue"
    (ngModelChange)="modelChange.emit($event)"
    [placeholder]="placeholder"
    [readOnly]="readOnly"
    format="text"
    minHeight="100px"
  ></cq-editor>
</ng-container>
