import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { map } from 'rxjs/operators';

import {
  ApiGetSavedRepliesListResponse,
  SAVED_REPLY_ACCESS_TYPE,
  SavedReply,
} from '@http/saved-reply/saved-reply.types';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class SavedReplyModel {
  /**
   * Сохранённый ответ по-умолчанию
   */
  DEFAULT_SAVED_REPLY = {
    app: '', // ID приложения, в котором находится этот ответ
    body: '', // Текст сохранённого ответа
    header: '', // Заголовок сохранённого ответа
    id: '', // ID сохранённого ответа
    order: 0, // Приоритет сохранённого ответа
    accessType: SAVED_REPLY_ACCESS_TYPE.SHARED,
  };

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Смена порядка сохранённого ответа
   *
   * @param savedReplyId ID сохранённого ответа
   * @param newOrder Порядковое число
   */
  changeOrder(savedReplyId: string, newOrder: number) {
    return this.edit(savedReplyId, undefined, undefined, undefined, newOrder);
  }

  /**
   * Создание сохранённого ответа
   *
   * @param savedReply Сохранённый ответ
   * @param isLowestOrder Если указать true, то ответ создастся с наименьшим приоритетом
   */
  create(savedReply: SavedReply, isLowestOrder: boolean) {
    const params = {
      body: savedReply.body,
      header: savedReply.header,
      access_type: savedReply.accessType,
      lowest_order: isLowestOrder,
    };

    return this.httpClient.post('/saved_replies', params);
  }

  /**
   * Редактирование сохранённого ответа
   *
   * @param savedReplyId ID сохранённого ответа
   * @param body Текст сохранённого ответа
   * @param header Заголовок сохранённого ответа
   * @param accessType Тип доступа сохранённого ответа
   * @param order Приоритет сохранённого ответа
   */
  edit(
    savedReplyId?: string,
    body?: string,
    header?: string | null,
    accessType?: SAVED_REPLY_ACCESS_TYPE,
    order?: number,
  ) {
    const params = {
      body,
      header,
      access_type: accessType,
      order,
    };

    return this.httpClient.patch(`/saved_replies/${savedReplyId}`, params);
  }

  /**
   * Получение дефолтного сохранённого ответа
   */
  getDefault() {
    return cloneDeep(this.DEFAULT_SAVED_REPLY);
  }

  /**
   * Получение списка сохранённых ответов
   *
   * @param accessType Тип доступа сохранённого ответа
   * @param searchString Строка, по которой будет идти поиск среди всех ответов
   */
  getList(accessType: SAVED_REPLY_ACCESS_TYPE, searchString?: string | null) {
    let params: Record<string, any> = {
      access_type: accessType,
    };

    if (searchString) {
      params['search_string'] = searchString;
    }

    return this.httpClient.get<ApiGetSavedRepliesListResponse>(`/saved_replies`, { params }).pipe(
      map(({ count, items }) => {
        let savedRepliesList = orderBy(items, ['order'], ['desc']);

        return savedRepliesList;
      }),
    );
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param savedReplyId ID сохранённого ответа
   */
  remove(savedReplyId: string) {
    return this.httpClient.delete(`/saved_replies/${savedReplyId}`, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }
}
