import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { SharedModule } from '@panel/app/shared/shared.module';

import { UniqueUsersComponent } from './unique-users.component';

@NgModule({
  declarations: [UniqueUsersComponent],
  exports: [UniqueUsersComponent],
  imports: [CommonModule, NgbProgressbarModule, NgbTooltipModule, SharedModule, TranslocoModule, TuiLet],
  providers: [L10nHelperService, SubscriptionStore],
})
export class UniqueUsersModule {}
