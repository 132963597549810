import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Text } from 'pixi.js';

import { TriggerChainActionType } from '@http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_ACTION_TYPE } from '@http/trigger-chain/trigger-chain.constants';
import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TextStyleForRender } from '@panel/app/services/canvas/common/render/canvas-render.types';
import { AbsBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.view';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ActionBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasTexture, ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/** View шага "Действие" */
export class ActionView extends AbsBlockView<TRIGGER_CHAIN_BLOCK_TYPE.ACTION> {
  constructor(
    data: ActionBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  /** Рендерит контент блок */
  renderContent(): Container {
    let text = CanvasRenderService.renderText(this.t('description'), {
      color: HEX_COLOR.GRAY_400,
      wordWrapWidth: 252,
    });

    let renderedElements: any[] = [];

    renderedElements.push(text);

    if (this.data.actions.length) {
      renderedElements.push(this.renderActionList(this.data.actions));
      renderedElements.push(this.renderInteractiveBlocks());
    } else {
      renderedElements.push(this.renderEmptyAction());
    }

    return ElementBuilder.wrapElementsInRectangle(renderedElements, {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
      },
    });
  }

  private renderActionList(actions: TriggerChainActionType[]): Container {
    let actionItems: Container[] = [];
    actions.forEach((action) => actionItems.push(this.renderAction(action)));

    return ElementBuilder.wrapElementsInRectangle(actionItems, {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 5,
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }

  private renderInteractiveBlocks(): Container {
    if (this.interactiveBlockParts.length === 0) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.ACTION, this);
    } else {
      this.interactiveBlockParts[0].rerender();
    }

    return this.interactiveBlockParts[0].graphicContainer;
  }

  getTitleIconCode(): string {
    return UNICODE_ICON['cqi-flash-filled'];
  }

  getTitleIconColorIfDefault(): number {
    return 0x5c5cd6;
  }

  getTitleIconColorIfSelected(): number {
    return 0xffffff;
  }

  getTitleIconBackgroundTextureIfDefault(): AtlasTexture {
    return 'circle-background-white-15';
  }

  getTitleIconBackgroundTextureIfSelected(): AtlasTexture {
    return 'circle-background-primary600-15';
  }

  getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture {
    return 'circle-border-danger600-15';
  }

  private renderEmptyAction(): Container {
    let text = this.renderEmptyActionText();

    return ElementBuilder.wrapElementsInRectangle([text], {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }

  private renderEmptyActionText(): Container {
    let text: string = this.t('placeholder');
    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_650,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    };

    return CanvasRenderService.renderText(text, style);
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.action.${key}`, params);
  }

  private getActionText(action: TriggerChainActionType): Container {
    let actionText = this.t(`actionType.${action.type}`, {
      body: action.body,
      keyName: action.keyName,
    });

    return CanvasRenderService.renderText(actionText, {
      color: HEX_COLOR.GRAY_400,
      wordWrap: true,
      wordWrapWidth: 232,
    });
  }

  private getActionIcon(type: TRIGGER_CHAIN_ACTION_TYPE): Text {
    let icon;

    switch (type) {
      case TRIGGER_CHAIN_ACTION_TYPE.EVENT_TYPE:
        icon = UNICODE_ICON['cqi-cursor-arrow'];
        break;
      case TRIGGER_CHAIN_ACTION_TYPE.PROPERTY:
        icon = UNICODE_ICON['cqi-user-o'];
        break;
      case TRIGGER_CHAIN_ACTION_TYPE.USER_TAG:
        icon = UNICODE_ICON['cqi-tag-o'];
        break;
    }

    return CanvasRenderService.renderIcon(icon);
  }

  private renderAction(action: TriggerChainActionType) {
    const icon = this.getActionIcon(action.type);
    const text = this.getActionText(action);

    return ElementBuilder.wrapElementsInRectangle([icon, text], {
      placement: {
        flexDirection: 'row',
        marginBetweenCols: 5,
      },
    });
  }
}
