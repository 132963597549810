import unionBy from 'lodash-es/unionBy';
import without from 'lodash-es/without';
import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '../../../app/shared/constants/localstorage.keys';
import { firstValueFrom } from 'rxjs';
import { FEATURES } from '../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.userCard').controller('CqUserCardController', CqUserCardController);

  function CqUserCardController(
    $filter,
    $q,
    $scope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    $timeout,
    toastr,
    carrotquestHelper,
    featureModel,
    conversationModel,
    eventModel,
    djangoUserModel,
    l10nHelper,
    LongPollConnection,
    messageSenderModel,
    planFeatureAccessService,
    propertyModel,
    pushSubscriptionModel,
    tagModel,
    userModel,
  ) {
    var vm = this;

    /** Инстанс с объектом RTS */
    let rts;

    /** Список каналов, которые будет прослушивать по RTS */
    let RTS_EVENTS;

    vm.$onInit = init;
    vm.$onDestroy = onDestroy;

    function init() {
      vm.accessToUsersEvents = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_EVENTS, vm.currentApp);

      vm.addPinnedProp = addPinnedProp;
      vm.changePinnedPropsOrder = changePinnedPropsOrder;
      vm.chatMessage = '';
      vm.chatSending = false;
      vm.conversations = [];
      vm.conversationsLoading = false;
      vm.consentSettingsEnabled = consentSettingsEnabled;
      vm.customProps = [];
      vm.events = []; // Список совершенных событий пользователя
      vm.eventsLoaded = false;
      vm.eventsPaginator = null;
      vm.eventTypes = []; // Список типов событий пользователя
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.hasRemovePermissions = !djangoUserModel.isOperator(vm.currentApp.id, vm.djangoUser); // у операторов нет прав на удаление лидов
      vm.isClosable = angular.isDefined(vm.close);
      vm.isShowUserSubscriptions = isShowUserSubscriptions;
      vm.isUserHasCrmProps = isUserHasCrmProps;
      vm.isUserHasEcomProps = isUserHasEcomProps;
      vm.isUserHasInitialUtmProps = isUserHasInitialUtmProps;
      vm.isUserHasLastUtmProps = isUserHasLastUtmProps;
      vm.isUserHasRoistatProps = isUserHasRoistatProps;
      vm.isUserHasSocialProps = isUserHasSocialProps;
      vm.keypress = keypress;
      vm.newChatLoading = false;
      vm.onAddUserNote = onAddUserNote;
      vm.onRemoveUserNote = onRemoveUserNote;
      vm.openBanUserModal = openBanUserModal;
      vm.openAddUserPropertyModal = openAddUserPropertyModal;
      vm.openEditUserPropertyModal = openEditUserPropertyModal;
      vm.openHideUserModal = openHideUserModal;
      vm.openRemoveUserModal = openRemoveUserModal;
      vm.openSendSubscriptionConfirmationEmailModal = openSendSubscriptionConfirmationEmailModal;
      vm.openUnsubscribeUserFromEmailModal = openUnsubscribeUserFromEmailModal;
      vm.pinnedProps = [];
      vm.pushSubscriptions = []; // список подписок на пуши
      vm.removePinnedProp = removePinnedProp;
      vm.refreshPushSubscriptions = refreshPushSubscriptions;
      vm.removeUserProperty = removeUserProperty;
      vm.resetUsers = resetUsers;
      vm.send = send;
      vm.tags = []; // список всех тегов
      vm.toogleLSUncollapsedBlock = toogleLSUncollapsedBlock;
      vm.updateEvents = updateEvents;
      vm.updateProps = updateProps;
      vm.user = {};
      vm.userDoesntExists = undefined; // флаг существования пользователя
      vm.userProps = []; // Список свойств пользователя
      vm.writeOneUser = writeOneUser;

      RTS_EVENTS = [`event.${vm.currentApp.id}.${vm.userId}`, `user_props_changed.${vm.currentApp.id}.${vm.userId}`];

      $scope.$on('message', handleRts);

      $scope.$watch('vm.userId', onUserIdChange);

      firstValueFrom(userModel.getPinnedProps(vm.currentApp.id, vm.djangoUser.id)).then((pinnedPropsArray) => {
        vm.pinnedProps = pinnedPropsArray;
        $scope.$applyAsync();
      });

      firstValueFrom(propertyModel.getList(vm.currentApp.id)).then(function (properties) {
        vm.userProps = properties.userProps;
        vm.eventTypes = properties.eventTypes;

        for (var i = 0; i < vm.eventTypes.length; i++) {
          vm.eventTypes[i].isGroupLoading = false;
          vm.eventTypes[i].isGroupOpen = false;
        }
        $scope.$applyAsync();
      });

      firstValueFrom(tagModel.getList(vm.currentApp.id, false)).then(function (tagList) {
        vm.tags = tagList;
        $scope.$applyAsync();
      });

      /**
       * Обработка сообщений RTS
       *
       * @param event
       * @param info
       */
      function handleRts(event, info) {
        // нужно перехватывать RTS-сообщения при удалении или склейке текущего пользователя, чтобы в соответствии с этим обновлять карточку
        let channel = info.channel,
          data = info.data;

        switch (true) {
          case channel.includes('user_merge_finished.'):
            userMergeFinishedHandler(data);
            break;
          case channel.includes('users_removed.'):
            usersRemovedHandler(data);
            break;
        }
      }

      /**
       * Заппинивание св-ва
       * @param prop
       */
      function addPinnedProp(prop) {
        vm.pinnedProps = [prop, ...vm.pinnedProps];
        firstValueFrom(userModel.setPinnedProps(vm.pinnedProps, vm.currentApp.id, vm.djangoUser.id)).then(() =>
          $scope.$applyAsync(),
        );
      }

      /**
       * Изменение порядка закрепленных св-в
       *
       * @param pinnedPropsList
       */
      function changePinnedPropsOrder(pinnedPropsList = []) {
        vm.pinnedProps = [...pinnedPropsList];
        firstValueFrom(userModel.setPinnedProps(vm.pinnedProps, vm.currentApp.id, vm.djangoUser.id)).then(() =>
          $scope.$applyAsync(),
        );
      }

      /**
       * Удаление запиненного св-ва
       * @param prop
       */
      function removePinnedProp(prop) {
        vm.pinnedProps = vm.pinnedProps.filter((propName) => prop !== propName);
        firstValueFrom(userModel.setPinnedProps(vm.pinnedProps, vm.currentApp.id, vm.djangoUser.id)).then(() =>
          $scope.$applyAsync(),
        );
      }

      /**
       * Обработка изменения ID пользователя
       *
       * @param newValue
       * @param oldValue
       */
      function onUserIdChange(newValue, oldValue) {
        // NOTE возможно стоит перенести эту функцию в userMergeFinishedHandler
        if (newValue) {
          resetUsers();
        }
      }
    }

    function onDestroy() {
      rts.destroy.resolve();
    }

    /**
     * Постобработка срабатывания нового события пользователя
     *
     * @param newEvent Данные, пришедшие по RTS
     */
    function eventHandler(newEvent) {
      // Редкий кейс, но может быть такое, что события дублируются в списке всех событий пользователя
      if (!!vm.events.find((userEvent) => userEvent.id === newEvent.id)) {
        return;
      }

      eventModel.parse(newEvent);

      const props = [];
      for (let key in newEvent.props) {
        props.push({
          key: $filter('prettyEventPropName')(key),
          value: newEvent.props[key],
        });
      }
      newEvent.propsArray = props;
      newEvent.newItem = true;
      vm.events.unshift(newEvent);
      $scope.$applyAsync();
    }

    /**
     * Удаление свойства у пользователя
     *
     * @param prop Объект свойства
     */
    function removeUserProperty(prop) {
      $uibModal
        .open({
          component: 'cqRemoveUserPropertyModal',
          resolve: {
            modalWindowParams: () => {
              return {
                currentAppId: angular.bind(null, angular.identity, vm.currentApp.id),
                propertyName: angular.bind(null, angular.identity, prop.key),
                userId: angular.bind(null, angular.identity, vm.user.id),
              };
            },
          },
          size: 'md modal-dialog-centered',
        })
        .result.then(() => {
          vm.customProps.splice(vm.customProps.indexOf(prop), 1);
        });
    }

    /**
     * Постобработка слияния пользователя
     *
     * @param data Данные, пришедшие по RTS
     */
    function userMergeFinishedHandler(data) {
      let callApply = false;

      // если текущий пользователь склеился в нового - надо получить информацию о новом пользователе
      if (data.removed === vm.userId) {
        vm.userId = data.new;
        callApply = true;
        // TODO: Возможно, тут имеет смысл показывать поп-ап о том, что пользователь склеился (как это делается при удалении пользователя)
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Постобработка обновления свойства пользователя
     *
     * @param data Данные, пришедшие по RTS
     */
    function userPropsChangedHandler(data) {
      vm.updateProps().finally(() => $scope.$applyAsync());
    }

    /**
     * Постобработка удаления пользователя
     *
     * @param data Данные, пришедшие по RTS
     */
    function usersRemovedHandler(data) {
      // при удалении приходит массив ids с ID удалённых пользователей
      if (~data.ids.indexOf(vm.userId)) {
        vm.userDoesntExists = true;
        openUserHasBeenRemovedModal();
      }
    }

    function updateProps() {
      return firstValueFrom(userModel.get(vm.userId)).then(getUserSuccess, getUserError);

      function getUserError(response) {
        if (response.meta.error === 'BadRequest' || response.meta.error === 'LookupError') {
          vm.userDoesntExists = true;
        }
        return $q.reject();
      }

      function getUserSuccess(user) {
        vm.userDoesntExists = false;
        vm.user = user;
        vm.user.props.$banned = vm.user.props.$banned || false;

        for (var i = 0; i < vm.user.tags.length; i++) {
          var tag = vm.user.tags[i];

          tag.name = JSON.parse(tag.name);
        }

        sortProps();
        $scope.$applyAsync();
      }
    }

    function resetUsers() {
      updateProps().then(function () {
        resetEvents();
        refreshPushSubscriptions();
        vm.conversations = [];
        conversationsLoadNext();
        resetRtsConnection();
      });
    }

    /** Переподключение RTS */
    function resetRtsConnection() {
      if (rts) {
        rts.destroy.resolve();
      }

      rts = LongPollConnection(RTS_EVENTS, function (channel, message) {
        switch (true) {
          case channel.includes('event.'):
            eventHandler(message);
            break;
          case channel.includes('user_props_changed.'):
            userPropsChangedHandler(message);
            break;
        }
      });
    }

    function sortProps() {
      vm.customProps = [];

      for (var key in vm.user.propsCustom) {
        vm.customProps.push({
          key: key,
          value: vm.user.propsCustom[key],
        });
      }
    }

    function conversationsLoadNext() {
      vm.conversationsLoading = true;
      // запрашивается только 1 диалог, чтобы понять, были ли у пользователя диалоги вообще
      // NOTE: посмотреть счётчики в свойствах user.props.$conversation_* недостаточно, т.к. они не учитывают диалоги из соцсетей и диалоги, которые начал пользователь (так сказал Миша)
      firstValueFrom(conversationModel.getListByUserId(vm.userId, { paginateCount: 1 }, false, true))
        .then(getListSuccess)
        .finally(getListFinally);

      function getListSuccess(response) {
        vm.conversations = vm.conversations.concat(response.conversations);
        $scope.$applyAsync();
      }

      function getListFinally() {
        vm.newChatLoading = false;
        vm.conversationsLoading = false;
      }
    }

    function updateEvents() {
      if (!vm.accessToUsersEvents.hasAccess) {
        return;
      }

      vm.eventsLoaded = false;

      firstValueFrom(userModel.getEvents(vm.userId, null, true, vm.eventsPaginator)).then(getUserEventsSuccess);

      function getUserEventsSuccess(data) {
        // Делаю объединение через unionBy по id, потому что могут быть кейсы, когда в массив придёт два одинаковых события
        vm.events = unionBy(vm.events, data.events, 'id');
        vm.eventsPaginator = data.paginatorParams;

        for (var i = 0; i < vm.events.length; i++) {
          var props = [];
          for (var key in vm.events[i].props) {
            props.push({
              key: $filter('prettyEventPropName')(key),
              value: vm.events[i].props[key],
            });
          }
          vm.events[i].propsArray = props;
        }

        vm.eventsLoaded = true;
        $scope.$applyAsync();
      }
    }

    function resetEvents() {
      vm.events = [];
      vm.eventsPaginator = null;
      updateEvents();
    }

    /**
     * Написать одному пользователю
     *
     * TODO когда SendManualMessageModalController перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent
     * @param type
     */
    function writeOneUser(type) {
      if (!vm.user) {
        return;
      }

      var writeUserNamesHint = vm.user.name;
      var writeUserCounterHint = 1;
      var writeManualIds = [vm.user.id];
      var writeFilters = '{}';
      var writeType = type;

      switch (type) {
        case 'popup_chat':
          carrotquestHelper.track('Карточка пользователя - кликнул на "Начать чат"', { App: vm.currentApp.name });
          break;
        case 'popup_small':
          carrotquestHelper.track('Карточка пользователя - кликнул на "Показать попап"', { App: vm.currentApp.name });
          break;
        case 'email':
          carrotquestHelper.track('Карточка пользователя - кликнул на "Отправить емейл"', { App: vm.currentApp.name });
          break;
        case 'push':
          carrotquestHelper.track('Карточка пользователя - кликнул на "Отправить Web Push"', {
            App: vm.currentApp.name,
          });
          break;
      }

      var modalInstance = $uibModal.open({
        backdrop: 'static',
        controller: 'SendManualMessageModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/send-manual-message/send-manual-message.html',
        resolve: {
          billingInfo: angular.bind(null, angular.identity, vm.billingInfo),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          djangoUser: angular.bind(null, angular.identity, vm.djangoUser),
          manualMessageParams: function () {
            return {
              filters: writeFilters,
              manualIds: writeManualIds,
              type: writeType,
              usersCount: writeUserCounterHint,
              userNames: writeUserNamesHint,
              telegramIntegrations: vm.telegramIntegrations,
              emailDomains: vm.emailDomains,
            };
          },
        },
        size: 'right',
        windowClass: 'manual-message',
      });

      modalInstance.result.then(function () {
        toastr.success($translate.instant('userCard.toasts.messageSended'));
      });
    }

    function send(msg) {
      if (vm.chatSending) {
        return;
      }
      carrotquestHelper.track('Карточка пользователя - отправил сообщение');

      vm.chatSending = true;
      vm.newChatLoading = true;

      firstValueFrom(userModel.sendMessage(vm.user.id, msg)).then(sendMessageSuccess).catch(sendMessageError);

      function sendMessageError(error) {
        vm.chatSending = false;
      }

      function sendMessageSuccess(response) {
        vm.chatSending = false;
        vm.conversations = [];
        conversationsLoadNext();
      }
    }

    function keypress(event, msg) {
      if ((event.which == 13 || event.which == 10) && (event.ctrlKey || event.metaKey)) {
        send(msg);
      }
    }

    /** Показывать ли подписки пользователя */
    function isShowUserSubscriptions() {
      return (
        vm.user.emailStatus ||
        (!l10nHelper.isUsCountry() &&
          featureModel.hasAccess(FEATURES.WEB_PUSH, vm.currentApp.created) &&
          vm.pushSubscriptions?.length > 0)
      );
    }

    /** Есть ли у пользователя свойства для CRM */
    function isUserHasCrmProps() {
      return vm.user.props.$bitrix24_id || vm.user.props.$bitrix24_responsible || vm.user.props.$retailcrm_id;
    }

    /** Есть ли у пользователя свойства для интернет-магазинов */
    function isUserHasEcomProps() {
      return (
        vm.user.props.$cart_amount ||
        vm.user.props.$last_payment ||
        vm.user.props.$revenue ||
        vm.user.props.$viewed_products ||
        vm.user.props.$cart_items ||
        vm.user.props.$last_order_status ||
        vm.user.props.$discount ||
        vm.user.props.$orders_count ||
        vm.user.props.$group ||
        vm.user.props.$profit ||
        vm.user.props.$ordered_items ||
        vm.user.props.$ordered_categories ||
        vm.user.props.$viewed_categories
      );
    }

    /** Есть ли у пользователя свойства с начальными UTM-метками */
    function isUserHasInitialUtmProps() {
      return (
        vm.user.props.$initial_utm_campaign ||
        vm.user.props.$initial_utm_content ||
        vm.user.props.$initial_utm_medium ||
        vm.user.props.$initial_utm_source ||
        vm.user.props.$initial_utm_term
      );
    }

    /** Есть ли у пользователя свойства с последними UTM-метками */
    function isUserHasLastUtmProps() {
      return (
        vm.user.props.$last_utm_campaign ||
        vm.user.props.$last_utm_content ||
        vm.user.props.$last_utm_medium ||
        vm.user.props.$last_utm_source ||
        vm.user.props.$last_utm_term
      );
    }

    /** Есть ли у пользователя свойства Roistat */
    function isUserHasRoistatProps() {
      return vm.user.props.$roistat_visit || vm.user.props.$lead_cost;
    }

    /** Есть ли у пользователя свойства социальных сетей */
    function isUserHasSocialProps() {
      return (
        vm.user.props.$social_vk ||
        vm.user.props.$social_facebook ||
        vm.user.props.$social_instagram ||
        vm.user.props.$social_foursquare ||
        vm.user.props.$social_googleplus ||
        vm.user.props.$social_pinterest ||
        vm.user.props.$social_twitter ||
        vm.user.props.$social_skype ||
        vm.user.props.$social_telegram ||
        vm.user.props.$social_whatsapp
      );
    }

    /**
     * Добавление новой заметки о пользователе
     *
     * @param {UserNote} userNote Заметка о пользователе
     */
    function onAddUserNote(userNote) {
      vm.user.notes = [...vm.user.notes, userNote];
    }

    /**
     * Удаление заметки о пользователе
     *
     * @param {UserNote} userNote Заметка о пользователе
     */
    function onRemoveUserNote(userNote) {
      vm.user.notes = without(vm.user.notes, userNote);
    }

    /**
     * Открытие модалки бана пользователя (скрытия чата)
     *
     * TODO когда userModel перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent     *
     *
     * @param {Boolean} flag Флаг бана. Если true - пользователя надо забанить. Если false - разбанить
     */
    function openBanUserModal(flag) {
      if (flag) {
        var banModal = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('userCard.banModal.heading'),
                body: $translate.instant('userCard.banModal.body'),
                confirmButtonText: $translate.instant('userCard.banModal.confirmButtonText'),
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        });

        banModal.result.then(angular.bind(null, ban, flag));
      } else {
        ban(flag);
      }

      function ban() {
        return firstValueFrom(userModel.ban(vm.user.id, flag)).then(banSuccess);

        function banSuccess() {
          if (flag) {
            toastr.success($translate.instant('userCard.toasts.chatHidden'));
            vm.user.props.$banned = !vm.user.props.$banned;
            trackUserBanned();
          } else {
            toastr.success($translate.instant('userCard.toasts.chatVisible'));
            vm.user.props.$banned = !vm.user.props.$banned;
          }
        }
      }
    }

    /**
     * Открытие модалки добавления свойства пользователя
     */
    function openAddUserPropertyModal() {
      const addUserPropertyModal = $uibModal.open({
        component: 'cqAddUserPropertyModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          propertyList: angular.bind(null, angular.identity, vm.userProps),
          userId: angular.bind(null, angular.identity, vm.userId),
        },
        size: 'md modal-dialog-centered',
      });

      addUserPropertyModal.result.then(addUserPropertySuccess);

      function addUserPropertySuccess() {
        carrotquestHelper.track('Карточка пользователя - добавил свойство');

        vm.updateProps();
      }
    }

    /**
     * Открытие модалки редактирования свойства пользователя
     *
     * @param {String} propertyName — Название свойства
     * @param {String} propertyValue — Значение свойства
     */
    function openEditUserPropertyModal(propertyName, propertyValue) {
      const editUserPropertyModal = $uibModal.open({
        component: 'cqEditUserPropertyModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          propertyName: angular.bind(null, angular.identity, propertyName),
          propertyValue: angular.bind(null, angular.identity, propertyValue),
          userId: angular.bind(null, angular.identity, vm.user.id),
        },
        size: 'md modal-dialog-centered',
      });

      editUserPropertyModal.result.then(editUserPropertySuccess);

      function editUserPropertySuccess() {
        vm.updateProps();
      }
    }

    /**
     * Открытие модалки скрытия пользователя
     *
     * TODO когда userModel перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent
     */
    function openHideUserModal() {
      var hideUserModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('userCard.hideUserModal.heading'),
              body: $translate.instant('userCard.hideUserModal.body', { userName: vm.user.name }),
              confirmButtonText: $translate.instant('userCard.hideUserModal.confirmButtonText'),
            };
          },
        },
      });

      hideUserModal.result.then(hideUser);

      function hideUser() {
        firstValueFrom(userModel.remove(vm.currentApp.id, vm.userId)).then(removeSuccess);

        function removeSuccess() {
          trackUserHidden();
          toastr.success($translate.instant('userCard.toasts.userIsHidden'));
        }
      }
    }

    /**
     * Колбэк на удачное копирование ссылки на карточку пользователя в буфер обмена
     */
    function onUserCardUrlCopied() {
      toastr.success($translate.instant('general.linkCopied'));
    }

    /**
     * Открытие модалки отправки письма подтверждения
     *
     * TODO когда SendSubscriptionConfirmationEmailModalController перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent
     */
    function openSendSubscriptionConfirmationEmailModal() {
      var modalInstance = $uibModal.open({
        controller: 'SendSubscriptionConfirmationEmailModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/send-subscription-confirmation-email/send-subscription-confirmation-email.html',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          messageSender: angular.bind(messageSenderModel, messageSenderModel.getNoReply, vm.currentApp.language),
          users: angular.bind(null, angular.identity, vm.user),
          usersCount: angular.bind(null, angular.identity, 1),
        },
      });

      modalInstance.result.then(showSuccessToast);

      function showSuccessToast() {
        trackSentSubscriotionConfirmationEmail();
        toastr.success($translate.instant('userCard.toasts.subscriptionConfirmationEmailSended'));
      }
    }

    /**
     * Открытие модалки полного удаления пользователя
     *
     * TODO когда userModel перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent
     */
    function openRemoveUserModal() {
      var removeUserModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('userCard.removeUserModal.heading'),
              body:
                '\
                <div class="margin-bottom-20">' +
                $translate.instant('userCard.removeUserModal.body.description1', { userName: vm.user.name }) +
                '</div>\
                <div class="margin-bottom-20">' +
                $translate.instant('userCard.removeUserModal.body.description2') +
                '</div>\
                <div>' +
                $translate.instant('userCard.removeUserModal.body.description3', { userName: vm.user.name }) +
                '</div>\
                <hr>\
                <small class="text-danger">' +
                $translate.instant('userCard.removeUserModal.body.warning') +
                '</small>\
              ',
              confirmButtonText: $translate.instant('userCard.removeUserModal.confirmButtonText'),
            };
          },
        },
      });

      removeUserModal.result.then(removeUserPermanently);

      function removeUserPermanently() {
        firstValueFrom(userModel.removePermanently(vm.userId)).then(removeUserPermanentlySuccess);

        function removeUserPermanentlySuccess() {
          trackUserRemoved();
          toastr.success($translate.instant('userCard.toasts.userPermanentlyRemoved'));

          // после удаления вызываем функцию закрытия карточки пользователя, если такая имеется, либо перезагружаем текущее состояние
          if (angular.isFunction(vm.close)) {
            vm.close({ removedUserID: vm.userId });
          } else {
            $state.go($state.current, $stateParams, {
              reload: $state.current.name,
            });
          }
        }
      }
    }

    /**
     * Открытие модалки отправки пользователям письма подтверждения подписки
     *
     * TODO когда UnsubscribeFromEmailsModalController перепишется на A2+
     *  этот метод надо перенести в UserCardHeadComponent
     */
    function openUnsubscribeUserFromEmailModal() {
      var unsubscribeUserFromEmailModal = $uibModal.open({
        controller: 'UnsubscribeFromEmailsModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/unsubscribe-from-emails/unsubscribe-from-emails.html',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          users: angular.bind(null, angular.identity, [vm.user]),
          usersCount: angular.bind(null, angular.identity, 1),
        },
      });

      unsubscribeUserFromEmailModal.result.then(showSuccessToast);

      function showSuccessToast() {
        trackUserUnsubscribedFromEmail();
        toastr.success($translate.instant('userCard.toasts.userUnsubscribedFromEmails'));
      }
    }

    /**
     * Открытие модалки, означающей, что пользователь был удалён кем-то другим, пока была открыта карточка этого пользователя
     */
    function openUserHasBeenRemovedModal() {
      $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('userCard.userHasBeenRemovedModal.heading'),
              body: $translate.instant('userCard.userHasBeenRemovedModal.body'),
              confirmButtonClass: 'hidden',
              cancelButtonText: $translate.instant('general.close'),
            };
          },
        },
      });
    }

    /**
     * Включены ли настройки о соглашениях
     */
    function consentSettingsEnabled() {
      return (
        vm.currentApp.settings.data_processing_policy || vm.currentApp.settings.messenger_show_confirm_subscription
      );
    }

    /**
     * Обновление подписок на пуши
     */
    function refreshPushSubscriptions() {
      if (vm.userId) {
        firstValueFrom(pushSubscriptionModel.getList(vm.userId)).then(pushSubscriptionListSuccess);
      }

      function pushSubscriptionListSuccess(subscriptions) {
        vm.pushSubscriptions = subscriptions;
      }
    }

    function /**
     * Переключение состояния свернутости блока в LS
     */
    toogleLSUncollapsedBlock(LSName) {
      let LSValue = localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS);
      if (LSValue) {
        // Если есть значение, то убираем его
        if (LSValue.includes(LSName)) {
          LSValue = LSValue.replace(LSName, '');
          // Если нет, то добавляем
        } else {
          LSValue += ` ${LSName}`;
        }
        localStorage.setItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS, LSValue.trim());
        // Если записи в LS нет, то добавляем ее
      } else {
        localStorage.setItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS, LSName);
      }
    }

    /**
     * Трек отправки подтверждения подписки
     */
    function trackSentSubscriotionConfirmationEmail() {
      carrotquestHelper.track('Карточка пользователя - выслал подтверждение подписки на письма', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек скрытия чата (бан пользователя)
     */
    function trackUserBanned() {
      carrotquestHelper.track('Карточка пользователя - скрывал чат у пользователя', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек скрытия пользователя
     */
    function trackUserHidden() {
      carrotquestHelper.track('Карточка пользователя - скрыл пользователя', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек полного удаления пользователя
     */
    function trackUserRemoved() {
      carrotquestHelper.track('Карточка пользователя - полностью удалил пользователя', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек отписки пользователя от писем
     */
    function trackUserUnsubscribedFromEmail() {
      carrotquestHelper.track('Карточка пользователя - отписал пользователя от писем', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }
  }
})();
