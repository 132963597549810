import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectComponent } from "@ng-select/ng-select";

import { SharedModule } from '@panel/app/shared/shared.module';

import { UserTagActionComponent } from './user-tag-action.component';

@NgModule({
  declarations: [UserTagActionComponent],
  exports: [UserTagActionComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SharedModule, NgSelectComponent],
})
export class UserTagActionModule {}
