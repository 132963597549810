import Moment from 'moment';

import { DATA_SOURCE_STATUS } from '@http/ai-data/ai-data.constants';
import {
  COMMUNICATION_STYLE,
  REPLIES_LENGTH,
} from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.types';

/** Типы контента, из которых AI-бот может брать данные */
export enum AiContentKind {
  KNOWLEDGE_BASE_ARTICLE,
  URL,
}

export type AiData = {
  contentId: string;
  description: string;
  kind: AiContentKind;
  lastAiSync: Moment.Moment;
  name: string;
  removed: Moment.Moment | null;
  status: DATA_SOURCE_STATUS;
  checked: boolean;
};

export type AiDataBotSettings = {
  assistantEnabled: boolean;
  channels: string[] | null;
  addressForm: AiBotAddressForm;
  callHumanOperatorMessage: {
    work: string;
    notWork: string;
  };
  communicationStyle: COMMUNICATION_STYLE;
  customRules: (string | null)[];
  delayForClosing: number;
  gptTemperature: number;
  integration: string;
  replyWords: REPLIES_LENGTH;
  serviceDescription: string | null;
  serviceName: string | null;
  stopPhrases: string[];
  userEvent: string | null;
  userTag: string | null;
};

export enum AiBotAddressForm {
  INFORMAL, // На «ты»
  NEUTRAL, // На «вы»
  FORMAL, // На «Вы»
}

export enum AI_DATA_CRAWLING_STATUS {
  CREATED,
  IN_PROGRESS,
  FINISHED,
}

export type AiDataCrawlingPage = { id: string; url: string; checked: boolean };

export type AiDataCrawlingResult = {
  id: string;
  status: AI_DATA_CRAWLING_STATUS;
  startUrl: string;
  totalUrlsFound: number;
  result: AiDataCrawlingPage[];
};
