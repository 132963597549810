import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { defer, Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { StepActionsDirective } from '@panel/app/shared/visual-components/stepper/step-actions/step-actions.directive';
import { StepLabelDirective } from '@panel/app/shared/visual-components/stepper/step-label/step-label.directive';

type CqStepCallback<T = any> = () => Observable<T> | Promise<T> | T;

type ObservableCallback<T = any> = () => Observable<T>;

const DUMMY_CALLBACK: ObservableCallback = () => of(null);

/**
 * Нужна для превращения любого типа переданного callback-а в Observable,
 * который возвращает результат выполнения этого callback
 */
const callbackToObservable = <T = any>(fn: () => Observable<T> | Promise<T> | T): Observable<T> => {
  const result = fn();
  if (result instanceof Observable) {
    return result;
  }
  if (result instanceof Promise) {
    return defer(() => result);
  }
  return of(result);
};

@Component({
  selector: 'cq-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent {
  @Input()
  set onEnter(value: CqStepCallback) {
    this._onEnterCallback = () => callbackToObservable(value);
  }
  private _onEnterCallback: ObservableCallback = DUMMY_CALLBACK;

  @Input()
  set onExit(value: CqStepCallback) {
    this._onExitCallback = () => callbackToObservable(value);
  }
  private _onExitCallback: ObservableCallback = DUMMY_CALLBACK;

  @Input()
  set validator(value: boolean | CqStepCallback<boolean>) {
    if (typeof value === 'boolean') {
      this._validate = of(value);
    } else {
      this._validate = callbackToObservable(value);
    }
  }
  private _validate: Observable<boolean> = of(true);

  @Input('label') // eslint-disable-line
  readonly textLabel: string | null = null;

  /**
   * Обязывает шаг быть валидным, чтоб пройти на следующий
   */
  @Input()
  readonly requireValid: boolean = false;

  @ContentChild(StepLabelDirective, { read: TemplateRef })
  readonly templateLabel: TemplateRef<any> | null = null;

  @ContentChild(StepActionsDirective, { read: TemplateRef })
  readonly templateActions: TemplateRef<any> | null = null;

  @ViewChild(TemplateRef, { static: true })
  readonly templateContent: TemplateRef<any> | null = null;

  visited = false;

  valid = true;

  get label(): PolymorpheusContent {
    if (!this.textLabel && !this.templateLabel) {
      throw new Error('Provide label for step');
    }
    return this.templateLabel ?? this.textLabel!;
  }

  get content(): TemplateRef<any> {
    if (!this.templateContent) {
      throw new Error('Provide content for step');
    }
    return this.templateContent;
  }

  get actions(): TemplateRef<any> {
    if (!this.templateActions) {
      throw new Error('Provide actions for step');
    }
    return this.templateActions;
  }

  get onEnter(): ObservableCallback {
    return this._onEnterCallback;
  }

  get onExit(): ObservableCallback {
    return this._onExitCallback;
  }

  /**
   * Обновляет флаг валидности шага и отдает Observable с текущей валидностью
   */
  actualizeValidity(): Observable<boolean> {
    return this._validate.pipe(
      tap((valid) => (this.valid = valid)),
      first(),
    );
  }
}
