import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainStepEditorWrapModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-editor-wrap/trigger-chain-step-editor-wrap.module';
import { TriggerChainDelayEditorComponent } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-delay-editor/trigger-chain-delay-editor.component';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { TimeSelectorModule } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.module';
import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { DatetimePickerModule } from '@panel/app/shared/components/datetime-picker/datetime-picker.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TriggerChainDelayEditorComponent],
  exports: [TriggerChainDelayEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbTooltipModule,
    TimeSelectorModule,
    TranslocoModule,
    TriggerChainStepEditorWrapModule,
    ReactiveFormsModule,
    SharedModule,
    NgbAlertModule,
    DatetimePickerModule,
    EventTypeSelectModule,
    PropertySelectorModule,
  ],
})
export class TriggerChainDelayEditorModule {}
