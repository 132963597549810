import { TranslocoService } from '@jsverse/transloco';

import { AiContentMakerIntegrationForm } from '@http/integration/integrations/ai-content-maker/ai-content-maker-form';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import {
  AiContentMakerIntegrationExternal,
  AiContentMakerIntegrationSettingsExternal,
} from './ai-content-maker-integration.interface';

export class AiContentMakerIntegration extends SettingsIntegration<AiContentMakerIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: AiContentMakerIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.AI_CONTENT_MAKER;
  /** Настройки интеграции */
  settings: AiContentMakerIntegrationSettingsExternal = {
    gptModelName: '',
    gptTemperature: 0.7,
    openAiApiKey: '',
    promptsJson: {
      companyInfo: '',
      aiPersonaAndTask: '',
      faq: '',
      rules: '',
    },
  };

  constructor(translocoService: TranslocoService, integrationExternal?: AiContentMakerIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new AiContentMakerIntegrationForm(this);
  }

  /**
   * Обновляет занчения формы настроек
   * @private
   */
  private updateFormBySettings() {
    this.form.controls.settings.patchValue(this.settings);
  }

  /**
   * Обновление настроек интегарции
   *
   * @param settings - Настройки интеграции во внешнем формате
   * @param updateFormSettings=false - Флаг для обновления формы настроек
   */
  updateSettings(settings: AiContentMakerIntegrationSettingsExternal, updateFormSettings: boolean = false): void {
    super.updateSettings(settings);

    if (updateFormSettings) {
      this.updateFormBySettings();
    }
  }

  get externalFormat(): AiContentMakerIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
    };
  }
}
