<ng-container *transloco="let t; read: 'acmConstantEditorComponent'">
  <div
    class="form-group"
    [cqShowError]="control.controls.value"
  >
    <label> {{ t('value.label') }}: </label>
    <div class="d-flex">
      <div class="w-100">
        <input
          class="form-control"
          placeholder="{{ t('value.placeholder') }}"
          [formControl]="control.controls.value"
        />
        <cq-validation-messages [control]="control.controls.value">
          <cq-validation-message errorName="required">
            {{ t('value.errors.required') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <button
        class="btn btn-outline-primary ml-10"
        type="button"
        (click)="delete.emit(index)"
      >
        <i class="cqi-sm cqi-times"></i>
      </button>
    </div>
  </div>

  <div
    [ngClass]="{
      'mb-20': isLast
    }"
  >
    <label for="context">{{ t('context.label') }}</label>
    <textarea
      class="form-control no-resize custom-scroll"
      [formControl]="control.controls.context"
      [placeholder]="t('context.placeholder')"
      id="context"
      rows="3"
    ></textarea>
    <cq-validation-messages [control]="control.controls.context">
      <cq-validation-message errorName="required">
        {{ t('context.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
  <hr *ngIf="!isLast" />
</ng-container>
