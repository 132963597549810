import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbAlert, NgbCollapse, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  NgFooterTemplateDirective,
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
} from '@ng-select/ng-select';
import { TuiLet } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';

import { AppService } from '@http/app/services/app.service';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';
import { CommunicationStyleControlComponent } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/communication-style-control/communication-style-control.component';
import { BotChannelsComponent } from '@panel/app/partials/bot-channels/bot-channels.component';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { CloseTimeoutControlComponent } from '@panel/app/partials/close-timeout-control/close-timeout-control.component';
import { CreativityLevelControlComponent } from '@panel/app/partials/creativity-level-control/creativity-level-control.component';
import { NonWorkingTimeReplyControlComponent } from '@panel/app/partials/non-working-time-reply-control/non-working-time-reply-control.component';
import { UserEventControlComponent } from '@panel/app/partials/user-event-control/user-event-control.component';
import { UserTagControlComponent } from '@panel/app/partials/user-tag-control/user-tag-control.component';
import { WorkingTimeReplyControlComponent } from '@panel/app/partials/working-time-reply-control/working-time-reply-control.component';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AddressFormControlComponent } from './address-form-control/address-form-control.component';
import { AiDataBotSettingsComponent } from './ai-data-bot-settings.component';
import { BotRuleControlComponent } from './bot-rules-control/bot-rule-control.component';
import { BotTriggersComponent } from './bot-triggers/bot-triggers.component';
import { BotsListComponent } from './bots-list/bots-list.component';
import { CompanyInfoControlComponent } from './company-info-control/company-info-control.component';
import { CompanyNameControlComponent } from './company-name-control/company-name-control.component';
import { RepliesLengthControlComponent } from './replies-length-control/replies-length-control.component';
import { StopPhrasesControlComponent } from './stop-phrases-control/stop-phrases-control.component';

@NgModule({
  declarations: [
    AiDataBotSettingsComponent,
    CommunicationStyleControlComponent,
    AddressFormControlComponent,
    RepliesLengthControlComponent,
    StopPhrasesControlComponent,
    BotRuleControlComponent,
    CompanyNameControlComponent,
    CompanyInfoControlComponent,
    BotTriggersComponent,
    BotsListComponent,
  ],
  imports: [
    CreativityLevelControlComponent,
    CommonModule,
    TranslocoDirective,
    UIRouterModule,
    ReactiveFormsModule,
    SharedModule,
    TuiLet,
    NgxSliderModule,
    NgbAlert,
    NgbTooltip,
    NgbCollapse,
    FormsModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
    NgFooterTemplateDirective,
    ChannelIconModule,
    UserTagControlComponent,
    UserEventControlComponent,
    NonWorkingTimeReplyControlComponent,
    WorkingTimeReplyControlComponent,
    CloseTimeoutControlComponent,
    BotChannelsComponent,
  ],
  providers: [AiDataBotSettingsStateService, AppService],
})
export class AiDataBotSettingsModule {}
