import { Injectable } from '@angular/core';

import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class L10nHelperService {
  currentCountry: 'US' | 'RU' = environment.country;

  LANGUAGE_MAP: Record<string, string> = {
    en: 'en-us',
    ru: 'ru-ru',
    es: 'es-es',
  };
  /**
   * Получение четырёхсимвольного представления языка по двухсимвольному
   */
  getFourLetterLang(twoLetterLang: string): string {
    if (twoLetterLang !== undefined) {
      twoLetterLang = twoLetterLang.toLowerCase();
    }
    // считаем, что если ничего не найдено - возвращаем значение, поданное на вход, обработка ошибок незачем
    return this.LANGUAGE_MAP[twoLetterLang] || twoLetterLang;
  }

  /**
   * Получение двухсимвольного представления языка по четырёхсимвольному
   *
   * @param {String} fourLetterLang Четырёхсимвольное представление языка
   */
  getTwoLetterLang(fourLetterLang: string): string {
    if (fourLetterLang !== undefined) {
      fourLetterLang = fourLetterLang.toLowerCase();
    }
    // считаем, что если ничего не найдено - возвращаем значение, поданное на вход, обработка ошибок незачем
    return (
      Object.keys(this.LANGUAGE_MAP)
        .filter((key) => this.LANGUAGE_MAP[key] === fourLetterLang)
        .shift() || fourLetterLang
    );
  }

  isUsCountry(): boolean {
    return this.currentCountry === 'US';
  }

  static isUsCountry(): boolean {
    return environment.country === 'US';
  }

  isRusCountry(): boolean {
    return this.currentCountry === 'RU';
  }
}
