<ng-container *transloco="let t; read: 'addDataSourcesModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <ng-container *ngIf="(isCrawlingStarted$ | async) === false">
    <div class="modal-body">
      <div class="mb-15">{{ t('toDo', {projectName}) }}</div>
      <div
        class="form-group mb-10"
        [cqShowError]="urlControl"
      >
        <input
          class="form-control"
          type="text"
          [formControl]="urlControl"
          [placeholder]="t('urlInput.placeholder')"
        />
        <cq-validation-messages [control]="urlControl">
          <cq-validation-message errorName="required">{{ t('urlInput.errors.required') }}</cq-validation-message>
          <cq-validation-message errorName="invalidUrl">{{ t('urlInput.errors.notUrl') }}</cq-validation-message>
          <cq-validation-message errorName="notAvailable">{{
            t('urlInput.errors.notAvailable')
          }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="text-secondary">
        <ng-container *ngIf="!urlControl.value">{{ t('description.withoutUrl') }}</ng-container>
        <ng-container *ngIf="urlControl.value">
          <span [innerHTML]="t('description.withUrl', { url: urlControl.value })"></span>
        </ng-container>
      </div>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="activeModal.dismiss()"
      >
        {{ 'general.cancel' | transloco }}
      </button>
      <button
        class="btn btn-primary"
        type="button"
        [disabled]="isRequestPerformed$ | async"
        (click)="startCrawling()"
      >
        {{ t('startButton') }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="isCrawlingStarted$ | async">
    <div class="modal-body align-items-center d-flex flex-col p-50 text-center">
      <i class="cqi-sm cqi-clock cqi-3x text-primary d-inline-block mb-20"></i>
      <h4>{{ t('crawlingStarted.heading') }}</h4>
      <div
        class="mb-20 mw-600"
        [innerHTML]="t('crawlingStarted.text')"
      ></div>
      <button
        class="btn btn-primary"
        type="button"
        (click)="activeModal.close()"
      >
        {{ t('crawlingStarted.buttonText') }}
      </button>
    </div>
  </ng-container>
</ng-container>
