import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';
import { GetListRequestParams, GetListResponse } from '@http/user/types/user.type';
import { UserModel } from '@http/user/user.model';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-install-script-modal',
  templateUrl: './check-jinja-modal.component.html',
  styleUrls: ['./check-jinja-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class CheckJinjaModalComponent {
  _app$ = new BehaviorSubject<App | null>(null);
  @Input({ required: true })
  set app(app: App) {
    this._app$.next(app);
  }

  @Input({ required: true })
  jinja!: string;

  MIN_SEARCH_LENGTH = 3;
  MAX_SEARCH_LENGTH = 15;

  /**
   * Контрол поиска лидов
   */
  searchControl = this.fb.control(null, [
    Validators.minLength(this.MIN_SEARCH_LENGTH),
    Validators.maxLength(this.MAX_SEARCH_LENGTH),
  ]);

  /**
   * Результаты тестирования фильтра на лиде
   */
  testResultsMap: Record<string, boolean | string> = {};

  /**
   * Флаг загрузки данных
   */
  isDataLoading = false;

  /**
   * Количество лидов при первом запросе
   *
   * NOTE Надо для корректного отображения zero-data и поля поиска
   */
  firstUserRequestCount: number | null = null;

  /**
   * Список пользователей
   */
  userList$ = combineLatest([
    this._app$.pipe(
      distinctUntilChanged(),
      filter((app) => !!app),
    ),
    this.searchControl.valueChanges.pipe(
      startWith(null),
      filter(() => this.searchControl.valid),
      debounceTime(300),
    ),
  ]).pipe(
    tap(() => {
      this.isDataLoading = true;
      this.cdr.markForCheck();
    }),
    map(([app, searchTerm]) => {
      let filters: GetListRequestParams['filters'];

      if (searchTerm) {
        filters = {
          type: 'or',
          filters: {
            events: [],
            tags: [],
            props: [
              {
                userProp: {
                  name: '$name',
                },
                value: {
                  value: searchTerm,
                },
                type: ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS,
              },
              {
                userProp: {
                  name: '$email',
                },
                value: {
                  value: searchTerm,
                },
                type: ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS,
              },
            ],
          },
        };
      }

      return [app, filters];
    }),
    switchMap(([app, filters]) =>
      this.userModel
        //@ts-ignore
        .getList(app.id, { sortOrder: 'desc', convertPropsTypes: false, filters: filters })
        .pipe(finalize(() => (this.isDataLoading = false))),
    ),
    map((resp: GetListResponse) => resp.users),
    tap((users) => {
      if (this.firstUserRequestCount === null) {
        this.firstUserRequestCount = users.length;
      }
    }),
    takeUntil(this.destroy$),
    shareReplay(1),
  );

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    private readonly messageUtilsModel: MessageUtilsModel,
    private readonly userModel: UserModel,
    protected readonly destroy$: DestroyService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  /**
   * Проверка jinja фильтра на пользователе
   * @param userId
   */
  checkJinja(userId: string) {
    firstValueFrom(this.messageUtilsModel.checkJinjaFilterTemplate(this.jinja, userId))
      .then((response) => {
        this.testResultsMap = {
          ...this.testResultsMap,
          [userId]: response.validationResult,
        };
        this.cdr.markForCheck();
      })
      .catch(() => {
        this.testResultsMap = {
          ...this.testResultsMap,
          [userId]: false,
        };
        this.cdr.markForCheck();
      });
  }
}
