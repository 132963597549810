import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { DirectoryFilterModule } from '@panel/app/pages/auto-messages/components/list/directory-filter/directory-filter.module';
import { MassActionPanelModule } from '@panel/app/pages/auto-messages/components/list/mass-action-panel/mass-action-panel.module';
import { AutoMessageStatusFilterModule } from '@panel/app/pages/auto-messages/components/list/status-filter/auto-message-status-filter.module';
import { AutoMessageTableModule } from '@panel/app/pages/auto-messages/components/list/table/auto-message-table.module';
import { AutoMessageTypeFilterModule } from '@panel/app/pages/auto-messages/components/list/type-filter/auto-message-type-filter.module';
import { ScheduledAutoMessageTableModule } from '@panel/app/pages/auto-messages/components/list-scheduled/scheduled-auto-message-table/scheduled-auto-message-table.module';
import { AutoMessageLoopModalModule } from '@panel/app/pages/auto-messages/modals/list/loop/auto-message-loop-modal.module';
import { AutoMessageSetDirectoryModalModule } from '@panel/app/pages/auto-messages/modals/list/set-directory/auto-message-set-directory-modal.module';
import { DirectoryEditorModalModule } from '@panel/app/pages/auto-messages/modals/shared/directory-editor/directory-editor-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageListPageComponent } from './auto-message-list-page.component';

@NgModule({
  declarations: [AutoMessageListPageComponent],
  exports: [AutoMessageListPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    AutoMessageStatusFilterModule,
    DirectoryFilterModule,
    AutoMessageTypeFilterModule,
    AutoMessageTableModule,
    MassActionPanelModule,
    DirectoryEditorModalModule,
    TranslocoModule,
    NgbTooltipModule,
    UIRouterModule,
    AutoMessageLoopModalModule,
    AutoMessageSetDirectoryModalModule,
    ReactiveFormsModule,
    FormsModule,
    ScheduledAutoMessageTableModule,
  ],
})
export class AutoMessageListPageModule {}
