import Inline from 'quill/blots/inline';

export class CustomUserProp extends Inline {
  static blotName = 'userProp';
  static tagName = 'SPAN';
  static className = 'badge';

  static create(value: any): any {
    let node = super.create(value);

    if (typeof value === 'object' && value.propName && value.defaultValue && value.prettyName) {
      if (value.propName) {
        node.dataset.propName = value.propName;
      }
      if (value.defaultValue) {
        node.dataset.defaultValue = value.defaultValue;
      }

      node.classList.add('badge-light-secondary', 'cursor-pointer');
      node.setAttribute('contenteditable', 'false');
    }

    return node;
  }

  static formats(domNode: HTMLElement) {
    return {
      propName: domNode.dataset.propName,
      defaultValue: domNode.dataset.defaultValue,
      prettyName: domNode.innerText,
    };
  }

  public formats(): { [index: string]: any } {
    const formats = this.attributes.values();
    const format = this.statics.formats(this.domNode, this.scroll);
    if (format != null) {
      formats[this.statics.blotName] = format;
    }
    return formats;
  }

  format(name: string, value: any): any {
    if (name === CustomUserProp.blotName && value) {
      if (value.propName) {
        this.domNode.dataset.propName = value.propName;
      }
      if (value.defaultValue) {
        this.domNode.dataset.defaultValue = value.defaultValue;
      }
      if (value.prettyName) {
        this.domNode.innerText = value.prettyName;
      }
    } else {
      super.format(name, value);
    }
  }
}
