<ng-container *transloco="let t; read: 'answerToUserPropComponent'">
  <cq-checkbox-control
    [(ngModel)]="setUserProp"
    class="my-10"
    (change)="setUserPropChanged()"
  >
    {{ t('checkboxLabel') }}

    <i
      class="cqi-sm cqi-question-circle"
      placement="top"
      [ngbTooltip]="t('checkboxTooltip')"
    ></i>
  </cq-checkbox-control>

  <ng-container *ngIf="messageEditorTriggerState.currentApp$ | async as currentApp">
    <cq-property-selector
      *ngIf="setUserProp"
      [currentApp]="currentApp"
      [properties]="(messageEditorTriggerState.userProps$ | async) ?? []"
      (propertiesChange)="messageEditorTriggerState.userProps$.next($event)"
      [(ngModel)]="selectedProp"
      (ngModelChange)="onChange($event)"
    ></cq-property-selector>
  </ng-container>
</ng-container>
