import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';

@Component({
  selector: 'cq-bots-list',
  templateUrl: './bots-list.component.html',
  styleUrls: ['./bots-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotsListComponent implements OnInit {
  chatBots: ChatBot<CHAT_BOT_TYPE.LEAD>[] = [];

  constructor(
    private readonly aiDataBotSettingsStateService: AiDataBotSettingsStateService,
    private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit() {
    const chatGptIntegrationId = this.aiDataBotSettingsStateService.aiDataBotSettings$.getValue()?.integration;
    this.integrationService
      .getChatBots<ChatBot<CHAT_BOT_TYPE.LEAD>[]>(INTEGRATION_TYPES.CHAT_GPT, chatGptIntegrationId!)
      .subscribe((result) => (this.chatBots = result));
  }

  /**
   * Получение веток бота, в которых есть назначение на AI-бота
   *
   * @param chatBot - Бот, у которого нужно получить ветки
   */
  getChatBotBranches(chatBot: ChatBot<CHAT_BOT_TYPE.LEAD>): ChatBotBranch[] {
    if (!chatBot) {
      return [];
    }

    return chatBot.branches.filter((branch: ChatBotBranch) => {
      return branch.actions.filter((action: ChatBotAction) => {
        return action.type === CHAT_BOT_ACTIONS_TYPES.ASSISTANT;
      }).length;
    });
  }

  /**
   * Получение отформатированного списка блоков бота
   *
   * @example
   * «Блок 1», «Блок 2»
   * @param chatBotBranches Ветки бота
   */
  getFormattedBlocksNames(chatBotBranches: ChatBotBranch[]): string {
    return chatBotBranches.map((block) => `«${block.name}»`).join(', ');
  }

  trackByFn(index: number, item: ChatBot<CHAT_BOT_TYPE.LEAD>) {
    return item.id;
  }
}
