import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import Quill, { Range } from 'quill';
import { delay } from 'rxjs';

import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import {
  EDITOR_FORMAT_FILE_MAX_SIZE,
  EDITOR_FORMAT_FILE_TYPES_DEFAULT,
} from '@panel/app/partials/editor/format/file/editor-format-file.constant';
import { EditorService } from '@panel/app/partials/editor/service/editor.service';

@Component({
  selector: 'cq-editor-format-file',
  templateUrl: './editor-format-file.component.html',
  styleUrls: ['./editor-format-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFormatFileComponent implements OnInit {
  @ViewChild('fileTooltipInstance', { static: false })
  public fileTooltipInstance!: NgbTooltip;

  @ViewChild('filePopoverInstance', { static: false })
  public filePopoverInstance!: NgbPopover;

  @ViewChild('selectionFilePopoverContainer', { static: false })
  public selectionFilePopoverContainer!: ElementRef;

  @ViewChild('selectionFilePopoverInstance', { static: false })
  selectionFilePopoverInstance!: NgbPopover;

  @Input({ required: true })
  public editor!: Quill;

  @Input({ required: true })
  public editorComponentRef!: ElementRef;

  protected EDITOR_FORMAT_FILE_MAX_SIZE: number = EDITOR_FORMAT_FILE_MAX_SIZE;
  protected EDITOR_FORMAT_FILE_TYPES_DEFAULT: string = EDITOR_FORMAT_FILE_TYPES_DEFAULT;

  protected activeTab: number = 1;
  protected fileFormGroup = this.formBuilder.group({
    href: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    text: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isBlank: this.formBuilder.control(true, {
      validators: [],
      nonNullable: true,
    }),
  });
  protected isRequestPerform: boolean = false;

  private selection: Range | null = null;

  constructor(
    private editorService: EditorService,
    private formBuilder: FormBuilder,
    private messageUtilsModel: MessageUtilsModel,
  ) {}

  ngOnInit(): void {
    this.editorService.clickOnEditor$.pipe(delay(200)).subscribe((target) => {
      if (target && target.classList.contains('cq-editor-file')) {
        let formatNode = target as HTMLAnchorElement;

        this.saveSelection();
        this.setSelectionFilePopoverPosition(formatNode);

        this.fileFormGroup.setValue({
          href: formatNode.getAttribute('href') ?? '',
          text: formatNode.innerText,
          isBlank: formatNode.getAttribute('target') === '_blank',
        });

        this.openSelectionFilePopover();
      }
    });
  }

  protected deleteFile(): void {
    this.editorService.formatFileDelete(this.editor, this.selection);
    this.selectionFilePopoverInstance.close();
  }

  protected onClickFilePopoverButton(): void {
    this.saveSelection();
    this.closeTooltip();

    let href = '';
    let text = '';
    let blank = '_blank';

    if (this.selection) {
      text = this.editor.getText(this.selection.index, this.selection.length);
    }

    this.fileFormGroup.setValue({
      href,
      text,
      isBlank: blank === '_blank',
    });

    this.filePopoverInstance.open();
  }

  protected onFileChange(event: NgxDropzoneChangeEvent): void {
    this.isRequestPerform = true;

    let file = event.addedFiles[0];

    this.messageUtilsModel
      .saveFile({
        fileBlob: file,
        filename: file.name,
        mimeType: file.type,
        size: file.size,
        url: '',
      })
      .subscribe((uploadedFile) => {
        this.fileFormGroup.controls.href.setValue(uploadedFile.url);

        if (this.fileFormGroup.controls.text.getRawValue() === '') {
          this.fileFormGroup.controls.text.setValue(file.name);
        }

        this.insertFile();
      });
  }

  protected openFile(): void {
    let { href } = this.fileFormGroup.getRawValue();

    this.editorService.formatLinkOpen(href);

    this.resetActiveTab();
  }

  protected insertFile(): void {
    let { href, text, isBlank } = this.fileFormGroup.getRawValue();
    let target = isBlank ? '_blank' : '_target';

    this.editorService.formatFileInsert(this.editor, this.selection, {
      text,
      href,
      target,
    });

    this.fileFormGroup.setValue({
      href: '',
      text: '',
      isBlank: true,
    });

    this.closeFilePopover();
    this.closeSelectionFilePopover();
    this.resetActiveTab();

    setTimeout(() => {
      this.isRequestPerform = false;
    }, 200); // Дожидаемся анимации закрытия поповеров
  }

  private closeFilePopover(): void {
    this.filePopoverInstance.close();
  }

  private closeSelectionFilePopover(): void {
    this.selectionFilePopoverInstance.close();
  }

  private closeTooltip(): void {
    this.fileTooltipInstance.close();
  }

  protected onClickDeleteFileButton(): void {
    this.deleteFile();
    this.closeSelectionFilePopover();
  }

  protected onClickOpenFileButton(): void {
    this.openFile();
    this.closeSelectionFilePopover();
  }

  protected onSubmitForm(event: SubmitEvent): void {
    event.stopPropagation();

    this.insertFile();
    this.closeSelectionFilePopover();
  }

  protected openSelectionFilePopover(): void {
    this.selectionFilePopoverInstance.open();
  }

  private saveSelection(): void {
    this.selection = this.editor.getSelection(true);
  }

  private setSelectionFilePopoverPosition(formatNode: HTMLElement): void {
    let width = formatNode.offsetWidth;
    let height = formatNode.offsetHeight;

    let rect = formatNode.getBoundingClientRect();
    let containerRect = this.editorComponentRef.nativeElement.getBoundingClientRect();

    let top = rect.top - containerRect.top + this.editorComponentRef.nativeElement.scrollTop;
    let left = rect.left - containerRect.left + this.editorComponentRef.nativeElement.scrollLeft;

    this.selectionFilePopoverContainer.nativeElement.style.position = 'absolute';
    this.selectionFilePopoverContainer.nativeElement.style.top = `${top + height}px`;
    this.selectionFilePopoverContainer.nativeElement.style.left = `${left + width / 2}px`;
  }

  private resetActiveTab(): void {
    this.activeTab = 1;
  }
}
