import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EditorModule } from '@panel/app/partials/editor/editor.module';

import { MessageInputComponent } from './message-input.component';

@NgModule({
  declarations: [MessageInputComponent],
  imports: [CommonModule, FormsModule, TranslocoModule, EditorModule, ReactiveFormsModule],
  exports: [MessageInputComponent],
})
export class MessageInputModule {}
