import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { ChatBotActionBodyJsonMapper } from '@http/chat-bot/mappers/body-json.mapper';
import {
  ApiChatBotActionAttachmentResponse,
  ApiChatBotActionRequest,
  ApiChatBotActionResponse,
} from '@http/chat-bot/types/action-external.types';
import { ChatBotAction, ChatBotActionAttachment } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { Properties } from '@http/property/property.model';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';
import { JinjaService } from '@panel/app/shared/services/jinja/jinja.service';

export class ChatBotActionMapper {
  static parseAttachmentToInternalFormat(
    responseAttachment: ApiChatBotActionAttachmentResponse,
  ): ChatBotActionAttachment {
    return {
      // TODO: TAG:ACTION_TYPES
      //  Разобратсья с дублированием свойств под разными названиями.
      //  Разобраться с тем, на сколько нужны тут поля, которые добавляет vue (или кто-нибудь еще)
      //  Пока-что присвоения перенесены из старого кода
      $ngfBlobUrl: '',
      $ngfDataUrlFilterInProgress: false,
      created: responseAttachment.created,
      filename: responseAttachment.filename,
      // TODO: TAG:ACTION_TYPES
      //  Разобраться, почему в экстернал это number, а в интернал это string
      filesize: String(responseAttachment.size),
      id: responseAttachment.id,
      mimeType: responseAttachment.mimeType,
      name: responseAttachment.filename,
      size: responseAttachment.size,
      type: responseAttachment.mimeType,
      url: responseAttachment.url,
    };
  }

  /**
   * Парсинг действия ветки во внутрений формат
   */
  static parseToInternalFormat<BodyJson extends object = EMPTY_BODY_JSON>(
    externalAction: ApiChatBotActionResponse<BodyJson>,
  ): ChatBotAction<BodyJson> {
    let body = externalAction.body;
    if (externalAction.type === CHAT_BOT_ACTIONS_TYPES.USER_TAG) {
      body = JSON.parse(body);
    } else if (externalAction.type === CHAT_BOT_ACTIONS_TYPES.TEXT) {
      body = body.replace(/<br>/g, '\n');
    }

    return {
      id: externalAction.id,
      linkId: externalAction.id,
      type: externalAction.type,
      active: externalAction.active,
      isReplyAction: externalAction.isReplyAction,
      body,
      bodyJson: externalAction.bodyJson,
      attachments: externalAction.attachments.map(this.parseAttachmentToInternalFormat),
      // TODO TAG:ACTION_TYPES Изменить default значение если поменяется тип
      currentBranchLinkId: '',
      nextBranchId: externalAction.nextBranchId,
      nextBranchLinkId: externalAction.nextBranchId,
      order: externalAction.order,
      nextBranch: null,
      isInvalid: false,
      keyName: externalAction.keyName,
      prettyKeyName: null,
      integrationName: null,
    };
  }

  static parseAttachmentToRequestFormat(attachment: ChatBotActionAttachment): string {
    if (!attachment.id) {
      throw new Error('Could not get attachment id');
    }
    return attachment.id;
  }

  /**
   * Парсинг действия ветки в серверный формат
   */
  static parseToRequestFormat(action: ChatBotAction): ApiChatBotActionRequest {
    let body = action.body ?? '';
    let bodyJson = ChatBotActionBodyJsonMapper.parseToExternalFormat(action);

    if (action.type === CHAT_BOT_ACTIONS_TYPES.USER_TAG) {
      body = JSON.stringify(body);
    } else if (action.type === CHAT_BOT_ACTIONS_TYPES.TEXT) {
      body = body.replace(/\n/g, '<br>');
      body = JinjaService.parsePropsToJinja(body);
    }

    let parsedAction: ApiChatBotActionRequest = {
      id: action.id,
      active: action.active,
      type: action.type,
      body,
      body_json: bodyJson,
      attachments: action.attachments.map(this.parseAttachmentToRequestFormat),
      key_name: action.keyName ?? '',
    };

    // TODO: TAG:ACTION_TYPES Разобраться, можно ли передавать в любом случае
    //  (чтоб можно было просто избавиться от этого куска)
    if (ChatBotModel.isConnectionSourceAction(action.type)) {
      if (action.nextBranchId) {
        parsedAction.next_branch_id = action.nextBranchId;
      } else if (action.nextBranchLinkId) {
        parsedAction.next_branch_link_id = action.nextBranchLinkId;
      }
    }

    return parsedAction;
  }

  /**
   * Парсер для свойст и событий. Подставляет в действие человеческое имя свойства/события
   * @param branches Список веток
   * @param properties Свойства
   */
  static parseActionsKeyName(branches: ChatBotBranch[], properties: Properties) {
    branches.forEach((branch) => {
      branch.actions.forEach((action) => {
        switch (action.type) {
          case CHAT_BOT_ACTIONS_TYPES.EVENT:
            let event = properties.eventTypes.filter((event) => event.name === action.keyName)[0];
            action.prettyKeyName = event ? event.prettyName : action.keyName;
            break;
          case CHAT_BOT_ACTIONS_TYPES.PROPERTY:
          case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
          case CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY:
            let property = properties.userProps.filter((event) => event.name === action.keyName)[0];
            action.prettyKeyName = property ? property.prettyName : action.keyName;
            break;
          default:
            action.prettyKeyName = null;
        }
      });
    });
  }

  /**
   * Получаем название интеграции для подстановки в текст экшена в блоке
   *
   * @param branches - Ветки бота
   * @param emailNotificationsExternal - Список созданных в App'е интеграций "Уведомления на email" в вншенем формате
   */
  static parseActionsIntegrationName(
    branches: ChatBotBranch[],
    emailNotificationsExternal: EmailNotificationIntegrationExternal[],
  ): void {
    branches.forEach((branch) => {
      branch.actions.forEach((action) => {
        switch (action.type) {
          case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
            let integration = emailNotificationsExternal.filter((integration) => integration.id === action.body)[0];
            action.integrationName = integration.name;
            break;
          default:
            action.integrationName = null;
        }
      });
    });
  }
}
