import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { EditorFormatUserPropertyComponent } from './editor-format-user-property.component';

@NgModule({
  declarations: [EditorFormatUserPropertyComponent],
  exports: [EditorFormatUserPropertyComponent],
  imports: [CommonModule, TranslocoDirective, NgbTooltip],
})
export class EditorFormatUserPropertyModule {}
