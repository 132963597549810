import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLet } from '@taiga-ui/cdk';
import { MomentModule } from 'ngx-moment';

import { AppService } from '@http/app/services/app.service';
import { StripePayModalModule } from '@panel/app/pages/subscription/modals/stripe-pay-modal/stripe-pay-modal.module';
import { PlanChangeConfirmModalComponent } from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/plan-change-confirm-modal.component';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';

import { DowngradeAbilitiesModule } from './downgrade-abilities/downgrade-abilities.module';

@NgModule({
  declarations: [PlanChangeConfirmModalComponent],
  exports: [PlanChangeConfirmModalComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    DowngradeAbilitiesModule,
    TranslocoModule,
    MomentModule,
    PipesModule,
    TuiLet,
    StripePayModalModule,
  ],
  providers: [AppService],
})
export class PlanChangeConfirmModalModule {}
