import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';

import { TelegramBotStoreProvider } from '@panel/app/ajs-upgraded-providers';

import { TgTriggerTypesComponent } from './tg-trigger-types.component';

@NgModule({
  exports: [TgTriggerTypesComponent],
  declarations: [TgTriggerTypesComponent],
  imports: [CommonModule, NgbTooltipModule, ReactiveFormsModule, TranslocoModule, TuiLet, UIRouterModule],
  providers: [TelegramBotStoreProvider],
})
export class TgTriggerTypesModule {}
