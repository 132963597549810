import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@environment';
import { AppService } from '@http/app/services/app.service';
import { MESSAGE_SCHEDULE_TYPE, ScheduledAutoMessage } from '@http/message/message.types';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

type MessageScheduleSettingsForm = {
  type: FormControl<MESSAGE_SCHEDULE_TYPE>;
  timeH: FormControl<string>;
  timeM: FormControl<string>;
  daysOfWeek: FormControl<number[]>;
  daysOfMonth: FormControl<number[]>;
};

@Component({
  selector: 'cq-message-schedule-settings',
  templateUrl: './message-schedule-settings.component.html',
  styleUrls: ['./message-schedule-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class MessageScheduleSettingsComponent {
  @Input({ required: true })
  set settings(value: ScheduledAutoMessage['schedulerSettings']) {
    const timeSplit = value.time.split(':');
    this.form.setValue(
      {
        type: value.type,
        timeH: timeSplit[0] ?? '',
        timeM: timeSplit[timeSplit.length - 1] ?? '',
        daysOfWeek: value.daysOfWeek ?? [],
        daysOfMonth: value.daysOfMonth ?? [],
      },
      { emitEvent: false },
    );
  }

  readonly form = this.fb.group<MessageScheduleSettingsForm>({
    type: this.fb.control<MESSAGE_SCHEDULE_TYPE>(MESSAGE_SCHEDULE_TYPE.DAILY, { nonNullable: true }),
    timeH: this.fb.control<string>('00', { nonNullable: true, validators: [Validators.required] }),
    timeM: this.fb.control<string>('00', { nonNullable: true, validators: [Validators.required] }),
    daysOfWeek: this.fb.control<number[]>([], { nonNullable: true }),
    daysOfMonth: this.fb.control<number[]>([], { nonNullable: true }),
  });

  @Output()
  settingsChange: Observable<ScheduledAutoMessage['schedulerSettings']> = this.form.valueChanges.pipe(
    filter(() => this.form.valid),
    map(() => {
      const { type, timeM, timeH, daysOfMonth, daysOfWeek } = this.form.getRawValue();

      return {
        type,
        time: this.timeStr,
        daysOfMonth: type === MESSAGE_SCHEDULE_TYPE.MONTHLY ? daysOfMonth : [],
        daysOfWeek: type === MESSAGE_SCHEDULE_TYPE.WEEKLY ? daysOfWeek : [],
      };
    }),
  );

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.form.markAllAsTouched();
    this.formSubmitSubject.next();
    return this.form.status !== 'PENDING'
      ? Promise.resolve(this.form.valid)
      : firstValueFrom(
          this.form.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.form.valid),
          ),
        );
  });

  readonly MESSAGE_SCHEDULE_TYPE = MESSAGE_SCHEDULE_TYPE;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appService: AppService,
    private readonly transloco: TranslocoService,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
  ) {}

  readonly weekDays = [1, 2, 3, 4, 5, 6, 7];

  readonly monthDays = Array.from({ length: 31 }, (_, i) => i + 1);

  get timezone() {
    return this.appService.app.settings.timezone;
  }

  get timeStr() {
    return `${this.form.controls.timeH.value.padStart(2, '0')}:${this.form.controls.timeM.value.padStart(2, '0')}`;
  }

  changeWeekDayStatus(day: number) {
    const index = this.form.controls.daysOfWeek.value.indexOf(day);
    if (index > -1) {
      this.form.controls.daysOfWeek.value.splice(index, 1);
    } else {
      this.form.controls.daysOfWeek.value.push(day);
    }
    this.form.controls.daysOfWeek.setValue([...this.form.controls.daysOfWeek.value]);
  }

  selectWeekDays(days: number[]) {
    this.form.controls.daysOfWeek.setValue(days);
  }

  changeMonthDayStatus(day: number) {
    const index = this.form.controls.daysOfMonth.value.indexOf(day);

    const newValue = [...this.form.controls.daysOfMonth.value];
    if (index > -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(day);
    }
    this.form.controls.daysOfMonth.setValue(newValue);
  }

  daySelected(day: number, days: number[]) {
    return days.includes(day);
  }

  get weekDaysAsText() {
    const t = (d: number) => {
      return this.transloco.translate(`messageScheduleSettingsComponent.preview.weekDays.${d}`);
    };

    const weekDays = [...this.form.controls.daysOfWeek.value];
    weekDays.sort();

    if (weekDays.length === 1) {
      return t(weekDays[0]);
    }
    const lastDay = weekDays.pop()!;
    const and = this.transloco.translate(`messageScheduleSettingsComponent.preview.and`);
    return `${weekDays.map((d) => t(d)).join(', ')} ${and} ${t(lastDay)}`;
  }

  get monthDaysAsText() {
    const monthDays = [...this.form.controls.daysOfMonth.value];
    monthDays.sort();

    // суффикс (5th, 6th of December)
    const th =
      environment.language === 'en'
        ? this.transloco.translate(`messageScheduleSettingsComponent.preview.daySuffix`, { firstDay: monthDays[0] })
        : '';

    if (monthDays.length === 1) {
      return monthDays[0];
    }
    const lastDay = monthDays.pop()!;
    const and = this.transloco.translate(`messageScheduleSettingsComponent.preview.and`);
    return `${monthDays.map((d) => `${d}${th}`).join(', ')} ${and} ${lastDay}`;
  }

  get translationNumber() {
    const firstDay = this.form.controls.daysOfWeek.value.sort()[0];

    switch (firstDay) {
      case 1:
      case 2:
      case 4:
        return 'text1';
      case 3:
      case 5:
      case 6:
        return 'text2';
      case 7:
        return 'text3';
      default:
        throw new Error('There is no other days in week days');
    }
  }

  get showPreview(): boolean {
    const hasTime = this.form.controls.timeH.value.length > 0 && this.form.controls.timeM.value.length > 0;

    switch (this.form.controls.type.value) {
      case MESSAGE_SCHEDULE_TYPE.DAILY:
        return hasTime;
      case MESSAGE_SCHEDULE_TYPE.WEEKLY:
        return hasTime && this.form.controls.daysOfWeek.value.length > 0;
      case MESSAGE_SCHEDULE_TYPE.MONTHLY:
        return hasTime && this.form.controls.daysOfMonth.value.length > 0;
    }
  }
}
