import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UIRouterModule } from '@uirouter/angular';

import { BotChannelsComponent } from '@panel/app/partials/bot-channels/bot-channels.component';
import { CloseTimeoutControlComponent } from '@panel/app/partials/close-timeout-control/close-timeout-control.component';
import { ConversationTagControlComponent } from '@panel/app/partials/conversation-tag-control/conversation-tag-control.component';
import { CreativityLevelControlComponent } from '@panel/app/partials/creativity-level-control/creativity-level-control.component';
import { NonWorkingTimeReplyControlComponent } from '@panel/app/partials/non-working-time-reply-control/non-working-time-reply-control.component';
import { UserEventControlComponent } from '@panel/app/partials/user-event-control/user-event-control.component';
import { UserTagControlComponent } from '@panel/app/partials/user-tag-control/user-tag-control.component';
import { WorkingTimeReplyControlComponent } from '@panel/app/partials/working-time-reply-control/working-time-reply-control.component';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AiSalesPromtComponent } from './ai-sales-promt/ai-sales-promt.component';
import { AiSalesSettingsComponent } from './ai-sales-settings.component';
import { GptFunctionSelectorComponent } from './gpt-function-selector/gpt-function-selector.component';

@NgModule({
  exports: [AiSalesSettingsComponent],
  declarations: [AiSalesSettingsComponent, GptFunctionSelectorComponent, AiSalesPromtComponent],
  imports: [
    CreativityLevelControlComponent,
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule,
    UserTagControlComponent,
    UserEventControlComponent,
    NonWorkingTimeReplyControlComponent,
    WorkingTimeReplyControlComponent,
    CloseTimeoutControlComponent,
    BotChannelsComponent,
    NgSelectComponent,
    SharedModule,
    ConversationTagControlComponent,
  ],
})
export class AiSalesSettingsModule {}
