import { PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { firstValueFrom } from 'rxjs';

import { MessageModel } from '@http/message/message.model';
import { OpenedSdkPageTriggerType, OpenedWebPageTriggerType } from '@http/message/trigger.types';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { EventType } from '@http/property/property.model';
import { TriggerChainListItem } from '@http/trigger-chain/internal-types';
import { TriggerChainModel } from '@http/trigger-chain/trigger-chain.model';
import { TriggerChainListState } from '@panel/app/pages/trigger-chains/list/trigger-chain-list-state.service';
import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import { ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { DestroyService } from '@panel/app/services/destroy.service';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  //eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cq-trigger-chain-table-item]',
  templateUrl: './trigger-chain-table-item.component.html',
  styleUrls: ['./trigger-chain-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class TriggerChainTableItemComponent implements OnInit {
  @Input({ required: true })
  chain!: TriggerChainListItem;

  @Output()
  chainChange: EventEmitter<void> = new EventEmitter();

  accessToAutoMessagesTotal: ProductFeatureAccess = { hasAccess: true, denialReason: null };
  accessToTelegramAutoMessages: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    private readonly percentPipe: PercentPipe,
    private readonly messageModel: MessageModel,
    private readonly modalHelperService: ModalHelperService,
    public readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly planCapabilityModel: PlanCapabilityModel,
    private readonly paywallService: PaywallService,
    private readonly triggerChainModel: TriggerChainModel,
    private readonly toastService: ToastService,
    private readonly triggerChainListState: TriggerChainListState,
    private readonly translocoService: TranslocoService,
    private readonly triggerChainTrackService: TriggerChainTrackService,
  ) {}

  ngOnInit() {
    this.accessToAutoMessagesTotal = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.triggerChainListState.currentApp$.value!,
      this.triggerChainListState.activeTriggerMessagesCount$.value + this.chain.messageStepsCount,
      '<=',
    );
    this.chain.hasTelegramMessageSteps &&
      (this.accessToTelegramAutoMessages = this.planFeatureAccessService.getAccess(
        PLAN_FEATURE.CHAIN_MESSAGES_TELEGRAM,
        this.triggerChainListState.currentApp$.value!,
      ));
  }

  async changeStatus() {
    const newStatus = !this.chain.active;

    await firstValueFrom(this.triggerChainModel.changeActiveStatus(this.chain.id, newStatus, true)).catch((meta) => {
      if (meta.error === 'TriggerChainActivationError') {
        let text = this.translocoService.translate('triggerChainAdditionalActionsComponent.toasts.invalid');
        this.toastService.danger(text);
      }
    });
    await this.updateActiveChainCount();
    this.trackChangeTriggerChainStatus(newStatus);
    this.chainChange.emit();
  }

  async remove() {
    try {
      await this.modalHelperService
        .provide(CONFIRM_MODAL_DATA_TOKEN, {
          heading: this.translocoService.translate('triggerChainTableItemComponent.modals.remove.heading'),
          body: this.translocoService.translate('triggerChainTableItemComponent.modals.remove.body'),
          confirmButtonText: this.translocoService.translate('general.remove'),
        })
        .open(ConfirmModalComponent).result;
    } catch (e) {
      return;
    }
    await firstValueFrom(this.triggerChainModel.remove(this.chain.id));
    if (this.chain.active) {
      await this.updateActiveChainCount();
    }

    this.chainChange.emit();
  }

  @tuiPure
  isEventRemoved(eventType: EventType): boolean {
    if (!eventType.id) {
      return true;
    }

    return !eventType.visible || typeof eventType === undefined;
  }

  openSomeAutoMessageBillingModal() {
    if (!this.accessToAutoMessagesTotal.hasAccess) {
      this.paywallService.showAutoMessageTotalPaywall(
        this.triggerChainListState.currentApp$.value!,
        this.accessToAutoMessagesTotal.denialReason,
        this.chain.messageStepsCount,
        this.triggerChainListState.activeTriggerMessagesCount$.value,
        false,
      );
    } else if (!this.accessToTelegramAutoMessages.hasAccess) {
      this.paywallService.showPaywallForAccessDenial(
        this.triggerChainListState.currentApp$.value!,
        this.accessToTelegramAutoMessages.denialReason,
      );
    }
  }

  get hasOpenedWebPageTriggersTypes(): boolean {
    return !!this.chain.sendingConditions.triggerTypes.openedWebPageTriggers.length;
  }

  get hasOpenedSdkPageTriggersTypes(): boolean {
    return !!this.chain.sendingConditions.triggerTypes.openedSdkPageTriggers.length;
  }

  get isTriggersPopoverEnabled(): boolean {
    return (
      this.chain.sendingConditions.eventTypes.length > 1 ||
      this.hasFilters ||
      this.hasOpenedWebPageTriggersTypes ||
      this.hasOpenedSdkPageTriggersTypes
    );
  }

  get hasJinja() {
    return this.chain.sendingConditions.jinjaFilterTemplate;
  }

  /** Проверка наличия фильтров в первом шаге цепочки */
  get hasFilters() {
    const { props, events, tags } = this.chain.sendingConditions.filters.filters;

    return props.length > 0 || events.length > 0 || tags.length > 0 || this.hasJinja;
  }

  getChainEventTriggerPrettyName(event: EventType): string {
    if (this.isEventRemoved(event)) {
      return this.translocoService.translate('general.deletedEvent');
    }

    return event.prettyName;
  }

  getOpenedWebPageComparisonPrettyName(comparison: OpenedWebPageTriggerType['comparison']): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedWebPage.${comparison}`);
  }

  getOpenedSdkPageComparisonPrettyName(comparison: OpenedSdkPageTriggerType['comparison']): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedSdkPage.${comparison}`);
  }

  getPercentStats(statValue: number): string {
    const value = statValue / this.chain.stats.sent;

    return this.percentPipe.transform(value ? value : 0) ?? '0%';
  }

  /**
   * Обновление кол-ва активных цепочек
   */
  async updateActiveChainCount() {
    const newActiveTriggerMessageCount = await firstValueFrom(this.messageModel.getActiveTriggerMessageCount());

    this.triggerChainListState.activeTriggerMessagesCount$.next(newActiveTriggerMessageCount);
  }

  trackByOpenedWebPageTriggers(index: number, openedPageTriggerType: OpenedWebPageTriggerType): string {
    return openedPageTriggerType.localId;
  }

  trackByOpenedSdkPageTriggers(index: number, openedPageTriggerType: OpenedSdkPageTriggerType): string {
    return openedPageTriggerType.localId;
  }

  trackByTriggers(index: number, trigger: EventType): string {
    return trigger.id;
  }

  trackChangeTriggerChainStatus(activeStatus: boolean) {
    let isFirstRun = this.chain.stats.lastSent === null;
    if (isFirstRun) {
      return this.triggerChainTrackService.trackFirstRunChain();
    }

    if (activeStatus) {
      return this.triggerChainTrackService.trackActivateChain();
    }

    return this.triggerChainTrackService.trackPauseChain();
  }
}
