import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '@environment';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { LinkForDeveloper } from '@panel/app/services/utils/utils.types';
import { ElectronApiService } from '@panel/app-old/shared/services/electron-api/electron-api.service';

/***
 * TODO:
 *  - compareStringNumbers: переписать, чтобы TS не ругался;
 *  - isStateActive: указать тип параметра;
 */
/**
 * Сервис для всяких вспомогательных штук
 */
@Injectable({ providedIn: 'root' })
export class UtilsService {
  /**
   * Проверка на веб-версию панели
   * NOTE Также есть десктоп-приложение
   */
  readonly webApp: boolean;

  constructor(
    private readonly router: UIRouter,
    private readonly electronApi: ElectronApiService,
    private readonly l10nHelper: L10nHelperService,
  ) {
    this.webApp = !this.electronApi.desktopApp;
  }

  /** Флаг показа тултипа про настройку сервиса на логотипе в навигации */
  isStarterGuideTooltipOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Сравнение строковых чисел (например, ID сущностей)
   *
   * NOTE:
   *  Вернёт -1, если a < b, 0, если a == b, 1, если a > b
   *
   * @param a - Первое строковое число
   * @param b - Второе строковое число
   */
  //@ts-ignore
  compareStringNumbers(a: string, b: string): number {
    let isFirstNumberNegative = a.indexOf('-') == 0;
    let isSecondNumberNegative = b.indexOf('-') == 0;

    if (isFirstNumberNegative && !isSecondNumberNegative) {
      return -1;
    } else if (!isFirstNumberNegative && isSecondNumberNegative) {
      return 1;
    } else {
      // если оба числа негативные - убираем у них знак минус
      if (isFirstNumberNegative) {
        a = a.slice(1);
        b = b.slice(1);
      }

      // строковые числа сравнимы только тогда, когда количество символов в них равно, поэтому их надо добить незначащими нулями
      a = this.padFirstToSecond(a, b);
      b = this.padFirstToSecond(b, a);

      if (a > b) {
        return isFirstNumberNegative ? -1 : 1;
      } else if (a == b) {
        return 0;
      } else if (a < b) {
        return isFirstNumberNegative ? 1 : -1;
      }
    }
  }

  /** Активирование темной темы */
  darkThemeActivate(): void {
    this.setDarkTheme(true);
  }

  /** Деактивирование темной темы */
  darkThemeDeactivate(): void {
    this.setDarkTheme(false);
  }

  /**
   * Получение полного пути до внешних страниц
   *
   * NOTE:
   *  Функция была написана в связи с переходом на новый Angular,
   *  т.к. на локалке по-другому никак не перейти по ссылке на другой проект, порт/адрес не будет совпадать
   *
   * @param relativePath - Относительный путь внутри внешних страниц
   */
  getExternalPagesAbsoluteUrl(relativePath: string): string {
    return environment.externalPagesUrl + '/' + relativePath;
  }

  /**
   * Получение ссылки на инструкцию по установке скрипта для программиста
   *
   * @param apiKey API-ключ приложения
   * @param appId ID приложения
   * @param appName Название приложения
   * @param appOrigin Сайт приложения
   */
  getLinkForDeveloper(apiKey: string, appId: string, appName: string, appOrigin: string): string {
    const linkForDeveloper: LinkForDeveloper = {
      // Объект, который преобразуется в ссылку для программиста
      apiKey: apiKey,
      appId: appId,
      appName: appName,
    };

    if (appOrigin) {
      linkForDeveloper.appOrigin = appOrigin;
    }

    return this.router.stateService.href('code', linkForDeveloper, { absolute: true });
  }

  /**
   * Получение полного пути до админки
   *
   * NOTE:
   *  Функция была написана в связи с переходом на новый Angular,
   *  т.к. на локалке по-другому никак не перейти по ссылке на другой проект, порт/адрес не будет совпадать
   *
   * @param relativePath - Относительный путь внутри админки
   */
  getPanelAbsoluteUrl(relativePath: string): string {
    return environment.panelUrl + '/' + relativePath;
  }

  /** Активна ли тёмная тема */
  isDarkThemeActive(): boolean {
    let globsWithDarkTheme = ['app.content.conversations.general', 'app.content.conversations.detail'];
    let darkThemeState = false;

    for (const glob of globsWithDarkTheme) {
      if (this.router.stateService.includes(glob)) {
        darkThemeState = true;
        break;
      }
    }

    return localStorage.getItem('cq-dark-theme') === 'true' && darkThemeState;
  }

  /**
   * Проверка является ли домен подключенным или же стандартным
   *
   * @param emailDomain - Email-домен
   */
  isEmailDomainConnected(emailDomain: string): boolean {
    let isDefaultDomain = false; // будем проверять является ли emailDomain стандартным

    // если в домене присутствует собака - берём именно сам домен, после собаки
    if (~emailDomain.indexOf('@')) {
      emailDomain = emailDomain.split('@')[1];
    }

    // на разных серверах стандартные домены имеют разный вид
    // более того, у каждой страны может быть несколько стандартных доменов
    if (this.l10nHelper.isUsCountry()) {
      isDefaultDomain = /^app\d+\.mail\.dashly\.app$/.test(emailDomain);
    } else {
      isDefaultDomain =
        /^app\d+\.mail\.carrotquest\.io$/.test(emailDomain) || /^app\d+\.mail-carrotquest\.io$/.test(emailDomain);
    }

    return !isDefaultDomain;
  }

  /** Является ли текущее устройство мобильным */
  isMobile(): boolean {
    return window.matchMedia('only screen and (max-width: 760px)').matches;
  }

  /**
   * Активированы ли состояния из globs в данный момент. true возвращается, если хотя бы одно состояние активировано
   *
   * @param globs - Состояние/список состояний, проверку которых нужно осуществить
   */
  isStateActive(...globs: any): boolean {
    for (const glob of globs) {
      if (this.router.stateService.includes(glob)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Является ли входная строка ID сущности
   *
   * @param string - Входная строка
   */
  isStringNumber(string: string): boolean {
    return typeof string === 'string' && /^\d+$/.test(string);
  }

  /**
   * Добивание первого строкового числа до второго путём добавления незначащих нулей
   *
   * NOTE:
   *  Если первое строковое число заведомо длиннее - добивка не осуществляется
   *
   * @param a - Первое строковое число
   * @param b - Второе строковое число
   */
  padFirstToSecond(a: string, b: string): string {
    if (a.length >= b.length) {
      return a;
    } else {
      let paddedA = a;

      for (let i = 0; i < b.length - a.length; i++) {
        paddedA = '0' + paddedA;
      }

      return paddedA;
    }
  }

  /**
   * Включение и отключение тёмной темы
   *
   * @param activate - Активировать или деактивировать тёмную тему
   */
  setDarkTheme(activate: boolean): void {
    localStorage.setItem('cq-dark-theme', String(activate));
  }
}
