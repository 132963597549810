<ng-container *transloco="let t; read: 'messageScheduleMaxUsersCountComponent'">
  <div class="d-flex align-items-center pb-15">
    <cq-switch-new
      class="mr-15"
      [formControl]="form.controls.maxUsersCountEnabled"
    ></cq-switch-new>
    <div class="white-space-nowrap">
      <h5 class="d-inline-block mb-0 font-weight-bold">{{ t('heading') }}</h5>
    </div>
  </div>

  <div
    class="pl-50"
    *ngIf="form.controls.maxUsersCountEnabled.value"
  >
    <div class="mb-10">{{ t('description', { maxUsersCount: form.controls.maxUsersCount.value }) }}</div>
    <div class="flex">
      <div
        [cqShowError]="form.controls.maxUsersCount"
        class="mr-0 mb-0"
      >
        <input
          cqNumber
          class="form-control max-users-input"
          [formControl]="form.controls.maxUsersCount"
        />
        <cq-validation-messages [control]="form.controls.maxUsersCount">
          <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
          <cq-validation-message errorName="min">{{ t('errors.min', { minValue: MIN_VALUE }) }}</cq-validation-message>
          <cq-validation-message errorName="max">{{ t('errors.max', { maxValue: MAX_VALUE }) }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="ml-10 align-content-center">{{ t('afterInput', { value: form.controls.maxUsersCount.value }) }}</div>
    </div>
    <div
      *ngIf="receiverCount !== null"
      class="mt-15 d-flex align-items-center text-secondary"
    >
      <i class="cqi-sm cqi-info mr-5"></i>
      <span>{{ t('receiverCount', { value: receiverCount }) }}</span>
    </div>
  </div>
</ng-container>
