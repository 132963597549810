import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLet } from '@taiga-ui/cdk';

import { CostModule } from '@panel/app/pages/subscription/general/signed/plan-info/cost/cost.module';
import { EmailsModule } from '@panel/app/pages/subscription/general/signed/plan-info/emails/emails.module';
import { NameModule } from '@panel/app/pages/subscription/general/signed/plan-info/name/name.module';
import { PeriodModule } from '@panel/app/pages/subscription/general/signed/plan-info/period/period.module';
import { SubscriptionStatusModule } from '@panel/app/pages/subscription/general/signed/plan-info/subscription-status/subscription-status.module';
import { UniqueUsersModule } from '@panel/app/pages/subscription/general/signed/plan-info/unique-users/unique-users.module';

import { PlanInfoComponent } from './plan-info.component';

@NgModule({
  declarations: [PlanInfoComponent],
  exports: [PlanInfoComponent],
  imports: [
    CommonModule,
    CostModule,
    EmailsModule,
    NameModule,
    PeriodModule,
    SubscriptionStatusModule,
    UniqueUsersModule,
    TuiLet,
  ],
})
export class PlanInfoModule {}
