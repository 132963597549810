import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, combineLatest, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, debounceTime, finalize, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { SavedReplyModel } from '@http/saved-reply/saved-reply.model';
import { SAVED_REPLY_ACCESS_TYPE, SavedReply } from '@http/saved-reply/saved-reply.types';
import { ConversationsSettingsPageStore } from '@panel/app/pages/conversations-settings/conversations-settings-page.store';
import { EditSavedReplyModalComponent } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/edit-saved-reply-modal/edit-saved-reply-modal.component';
import { EDIT_SAVED_REPLY_MODAL_DATA_TOKEN } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/edit-saved-reply-modal/edit-saved-reply-modal.token';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-saved-replies',
  templateUrl: './saved-replies.component.html',
  styleUrls: ['./saved-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SavedRepliesComponent {
  /** Флаг выполнения запроса */
  protected isApiRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Максимальная длинна поисковой фразы */
  MAX_SEARCH_LENGTH = 255;

  /** Контрол с поиском по сохранённым ответам */
  protected searchControl = new FormControl('', { validators: [Validators.maxLength(this.MAX_SEARCH_LENGTH)] });
  /** Флаг режима поиска */
  protected isSearch: boolean = false;

  /** Выбранный тип сохранённых ответов */
  selectedRepliesType: SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE.SHARED;
  changeRepliesTypeSubj: BehaviorSubject<SAVED_REPLY_ACCESS_TYPE> = new BehaviorSubject(this.selectedRepliesType);

  /** Поток для обновления списка сохранённых ответов */
  updateListSubj: Subject<void> = new Subject();

  /** Поток со списком сохранённых ответов */
  savedRepliesList$: Observable<SavedReply[]> = combineLatest([
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      startWith(null),
      tap((searchPhrase) => (this.isSearch = !!searchPhrase)),
    ),
    this.changeRepliesTypeSubj.pipe(tap((value) => (this.selectedRepliesType = value))),
    this.updateListSubj.pipe(startWith(null)),
  ]).pipe(
    tap(() => this.isApiRequestPerformed$.next(true)),
    switchMap(([searchPhrase, repliesType]) =>
      this.savedReplyModel.getList(repliesType, searchPhrase).pipe(
        catchError((error) => {
          return of([]);
        }),
        finalize(() => this.isApiRequestPerformed$.next(false)),
      ),
    ),
    takeUntil(this.destroy$),
  );

  /** Является ли текущий пользователь оператором */
  isOperator: boolean = this.djangoUserModel.isOperator(this.appService.currentAppId, this.store.djangoUser);

  protected readonly SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE;

  constructor(
    private readonly appService: AppService,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly destroy$: DestroyService,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly modalHelperService: ModalHelperService,
    private readonly savedReplyModel: SavedReplyModel,
    private readonly store: ConversationsSettingsPageStore,
    private readonly toastr: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Смена приоритета сохранённого ответа
   *
   * @param newVal Содержит сохранённый ответ и новый порядок
   */
  changeSavedReplyOrder(newVal: { savedReply: SavedReply; newOrder: number }): void {
    this.isApiRequestPerformed$.next(true);

    this.savedReplyModel
      .changeOrder(newVal.savedReply.id, newVal.newOrder)
      .pipe(
        take(1),
        finalize(() => {
          this.isApiRequestPerformed$.next(false);
        }),
      )
      .subscribe(() => {
        this.updateListSubj.next();
      });
  }

  /** Можно ли сортировать ответы */
  isSortable() {
    if (this.isSearch) {
      return false;
    }

    if (this.isOperator && this.selectedRepliesType === SAVED_REPLY_ACCESS_TYPE.SHARED) {
      return false;
    }

    return true;
  }

  /**
   * Открытие модалки создания сохранённого ответа
   */
  openCreateSavedReplyModal(initialReplyType: SAVED_REPLY_ACCESS_TYPE): void {
    const defaultSavedReply = this.savedReplyModel.getDefault();
    defaultSavedReply.accessType = initialReplyType;

    const modal = this.modalHelperService
      .provide(EDIT_SAVED_REPLY_MODAL_DATA_TOKEN, {
        savedReply: defaultSavedReply,
      })
      .open(EditSavedReplyModalComponent);

    modal.result
      .then((result: { reply: SavedReply; addToTheEnd: boolean }) => {
        this.isApiRequestPerformed$.next(true);

        this.createSavedReply(result.reply, result.addToTheEnd)
          .pipe(
            take(1),
            finalize(() => {
              this.isApiRequestPerformed$.next(false);
            }),
          )
          .subscribe(() => {
            this.trackCreateSavedReply(result.reply.header);
            this.toastr.success(this.translocoService.translate('savedRepliesComponent.toasts.created'));
            this.updateListSubj.next();
          });
      })
      .catch(() => {});
  }

  /**
   * Открытие модалки редактирования сохранённого ответа
   */
  openEditSavedReplyModal(savedReply: SavedReply): void {
    const savedReplyCopy = { ...savedReply };

    const modal = this.modalHelperService
      .provide(EDIT_SAVED_REPLY_MODAL_DATA_TOKEN, {
        savedReply: savedReplyCopy,
      })
      .open(EditSavedReplyModalComponent);

    modal.result
      .then((result: { reply: SavedReply; addToTheEnd: boolean }) => {
        this.isApiRequestPerformed$.next(true);

        this.editSavedReply(result.reply)
          .pipe(
            take(1),
            finalize(() => {
              this.isApiRequestPerformed$.next(false);
            }),
          )
          .subscribe(() => {
            this.toastr.success(this.translocoService.translate('savedRepliesComponent.toasts.saved'));
            this.updateListSubj.next();
          });
      })
      .catch(() => {});
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param savedReply Сохранённый ответ
   */
  openRemoveSavedReplyModal(savedReply: SavedReply): void {
    let modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('savedRepliesComponent.removeSavedReplyModal.heading'),
        body: `
          <div class="margin-bottom-20">${
            savedReply.header
              ? this.translocoService.translate('savedRepliesComponent.removeSavedReplyModal.body.withHeader', {
                  header: savedReply.header,
                })
              : this.translocoService.translate('savedRepliesComponent.removeSavedReplyModal.body.withoutHeader')
          }</div>
          <div class="card padding-20 no-margin-bottom">
            <div class="text-break">${savedReply.body}</div>
          </div>
        `,
        confirmButtonText: this.translocoService.translate('general.remove'),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => {
      this.isApiRequestPerformed$.next(true);

      this.removeSavedReply(savedReply.id)
        .pipe(
          take(1),
          finalize(() => {
            this.isApiRequestPerformed$.next(false);
          }),
        )
        .subscribe(() => {
          this.toastr.success(this.translocoService.translate('savedRepliesComponent.toasts.deleted'));
          this.updateListSubj.next();
        });
    });
  }

  /**
   * Сбросить поиск и получить актуальный список ответов
   */
  resetSearch(): void {
    this.searchControl.reset();
  }

  /** Переключение типа сохранённых ответов */
  toggleSelectedRepliesType() {
    this.changeRepliesTypeSubj.next(
      this.selectedRepliesType === SAVED_REPLY_ACCESS_TYPE.PERSONAL
        ? SAVED_REPLY_ACCESS_TYPE.SHARED
        : SAVED_REPLY_ACCESS_TYPE.PERSONAL,
    );
  }

  /**
   * Трек создания нового сохранённого ответа
   *
   * @param savedReplyHeader — Название сохранённого ответа
   */
  trackCreateSavedReply(savedReplyHeader: string | null): void {
    this.carrotquestHelper.track('Сохраненные ответы - создан новый ответ', {
      'Название ответа': savedReplyHeader,
    });
  }

  private createSavedReply(createdSavedReply: SavedReply, addToTheEnd: boolean) {
    return this.savedReplyModel.create(createdSavedReply, addToTheEnd);
  }

  private editSavedReply(editedSavedReply: SavedReply) {
    return this.savedReplyModel.edit(
      editedSavedReply.id,
      editedSavedReply.body,
      editedSavedReply.header,
      editedSavedReply.accessType,
    );
  }

  private removeSavedReply(id: string) {
    return this.savedReplyModel.remove(id);
  }
}
