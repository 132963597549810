import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { toArray } from 'lodash-es';

import { DjangoUser } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { FeatureModel } from '@http/feature/feature.model';
import { FEATURE_LABELS_ONLY } from '@http/feature-label/feature-label.constants';
import { FeatureLabelModel } from '@http/feature-label/feature-label.model';
import { TeamMember } from '@http/team-member/team-member.types';
import { CONVERSATIONS_SETTINGS_TABS } from '@panel/app/pages/conversations-settings/pages/conversations-settings/conversations-settings.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-conversations-settings',
  templateUrl: './conversations-settings.component.html',
  styleUrls: ['./conversations-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsSettingsComponent implements OnInit {
  /** Приложение */
  @Input() app!: any;
  /** Информация о биллинге */
  @Input() billingInfo!: any;
  /** Каналы */
  @Input() channels!: any[];
  /** Django пользователь */
  @Input() djangoUser!: DjangoUser;
  /** Временные данные django-пользователя */
  @Input() djangoUserTempData!: DjangoUserTempData;
  /** Сохранённые ответы */
  @Input() savedReplies!: any[];
  /** Члены команды */
  @Input() teamMembers!: TeamMember[];

  protected CONVERSATIONS_SETTINGS_TABS = CONVERSATIONS_SETTINGS_TABS;
  protected FEATURE_LABELS_ONLY = FEATURE_LABELS_ONLY;
  protected activeTab: any;

  /** Доступ до автоназначения диалогов */
  accessToConversationsAutoAssignment: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    protected readonly uiRouter: UIRouter,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly featureModel: FeatureModel,
    protected readonly featureLabelModel: FeatureLabelModel,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.trackEnteringToPage();

    this.removeFeatureLabel(FEATURE_LABELS_ONLY.NEW_PAGE_INBOX_SETTINGS);

    this.accessToConversationsAutoAssignment = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.CONVERSATIONS_AUTO_ASSIGNMENT,
      this.app,
    );

    this.activeTab = CONVERSATIONS_SETTINGS_TABS.CHANNELS;

    this.initActiveTab();
  }

  initActiveTab(): void {
    if (this.uiRouter.stateService.params.tab) {
      const tab = toArray(CONVERSATIONS_SETTINGS_TABS).find((tab) => tab === this.uiRouter.stateService.params.tab);

      this.activeTab = tab || this.activeTab;
    }
  }

  /**
   * Удаляет feature-лейбл, чтобы он больше не показывался
   *
   * @param featureLabel - Feature-лейбл
   */
  removeFeatureLabel(featureLabel: any): void {
    this.featureLabelModel.remove(featureLabel);
  }

  /** Трекает заход в раздел "Настройки диалогов" */
  trackEnteringToPage(): void {
    const eventName = 'Настройки диалогов – зашел';

    this.carrotquestHelper.track(eventName);
  }
}
