<cq-permission-denied *ngIf="!accessToTrackMasterJsCode.hasAccess"></cq-permission-denied>

<div *ngIf="accessToTrackMasterJsCode.hasAccess">
  <div *ngFor="let settingGroup of TRACK_MASTER_SETTING_GROUPS_LIST; trackBy: trackBySettingGroup; first as first">
    <h3 [ngClass]="{ 'margin-top-20': !first }">
      {{ 'models.trackMaster.settingGroups.' + settingGroup | transloco }}
    </h3>
    <hr />
    <div
      class="element padding-10"
      [ngClass]="{ expanded: expanded[setting] }"
      *ngFor="let setting of TRACK_MASTER_SETTING_BY_GROUPS[settingGroup]; trackBy: trackBySetting"
    >
      <div class="flex align-items-center justify-space-between">
        <div
          class="track-master-setting-icon margin-right-10"
          [ngClass]="setting"
        ></div>
        <div class="flex-grow">
          <div>
            <h4 class="no-margin-bottom font-weight-bold">
              {{ 'models.trackMaster.settings.' + setting | transloco }}
            </h4>
            <div class="text-muted">{{ 'trackMaster.settingsDescription.' + setting | transloco }}</div>
          </div>
        </div>
        <div class="button-container">
          <!--Начать-->
          <a
            *ngIf="
              (indexOf(AUTO_EVENTS_GROUPS_LIST, setting) >= 0 && !getAutoEventsByGroup(setting).length) ||
              indexOf(
                [TRACK_MASTER_SETTINGS.INTEGRATIONS, TRACK_MASTER_SETTINGS.AUTO_TRACK, TRACK_MASTER_SETTINGS.JS_CODE],
                setting
              ) >= 0
            "
            class="btn btn-primary btn-block"
            [uiSref]="
              setting === TRACK_MASTER_SETTINGS.INTEGRATIONS
                ? 'app.content.integrations.general'
                : 'app.content.trackmaster.details.unconfigured'
            "
            [uiParams]="setting === TRACK_MASTER_SETTINGS.INTEGRATIONS ? {} : { autoEventGroup: setting }"
            (click)="trackClickAddSetting(setting)"
            >{{ 'trackMaster.startSetupButton' | transloco }}</a
          >
          <!--Документация-->
          <a
            *ngIf="
              indexOf(AUTO_EVENTS_GROUPS_LIST, setting) === -1 &&
              indexOf(
                [TRACK_MASTER_SETTINGS.INTEGRATIONS, TRACK_MASTER_SETTINGS.AUTO_TRACK, TRACK_MASTER_SETTINGS.JS_CODE],
                setting
              ) === -1
            "
            class="btn btn-primary btn-block"
            target="_blank"
            [href]="'trackMaster.documentationsUrlList.' + setting | transloco"
            >{{ 'trackMaster.documentationButton' | transloco }}</a
          >
          <!--Настроено-->
          <button
            *ngIf="indexOf(AUTO_EVENTS_GROUPS_LIST, setting) >= 0 && getAutoEventsByGroup(setting).length"
            class="btn btn-outline-secondary btn-block"
            (click)="expanded[setting] = !expanded[setting]"
          >
            <span>{{
              'trackMaster.configuredButton' | transloco: { settingCount: getAutoEventsByGroup(setting).length }
            }}</span>
            <i
              *ngIf="!expanded[setting]"
              class="btn-icon-right cqi-sm cqi-chevron-down"
            ></i>
            <i
              *ngIf="expanded[setting]"
              class="btn-icon-right cqi-sm cqi-chevron-up"
            ></i>
          </button>
        </div>
      </div>

      <!--Список настроек-->
      <div
        *ngIf="indexOf(AUTO_EVENTS_GROUPS_LIST, setting) >= 0"
        [ngbCollapse]="!expanded[setting]"
        (ngbCollapseChange)="expanded[setting] = $event"
      >
        <div class="flex justify-space-between">
          <div class="track-master-setting-icon margin-right-10 invisible"></div>
          <div class="margin-left-50 margin-right-20 margin-top-10 flex-grow">
            <hr class="no-margin-top" />
            <div class="flex justify-space-between align-items-center">
              <div>{{ 'trackMaster.addMoreDescription.' + setting | transloco }}</div>
              <!--Добавить еще-->
              <a
                class="btn btn-primary"
                uiSref="app.content.trackmaster.details.unconfigured"
                [uiParams]="{ autoEventGroup: setting }"
                (click)="trackClickAddSetting(setting)"
                >{{ 'trackMaster.createdEvent.addMoreButton' | transloco }}</a
              >
            </div>
            <hr class="margin-top-10" />
            <div
              class="margin-bottom-15"
              *ngFor="let autoEvent of getAutoEventsByGroup(setting); trackBy: trackByAutoEventId"
            >
              <div class="flex justify-space-between align-items-center">
                <div class="flex-grow">
                  <h4
                    class="margin-top-10 bold text-primary cursor-pointer"
                    uiSref="app.content.trackmaster.details.configured"
                    [uiParams]="{ autoEventGroup: autoEvent.group, autoEventId: autoEvent.id }"
                    (click)="trackEditAutoEvent(autoEvent.group)"
                  >
                    {{ autoEvent.name }}
                  </h4>
                  <span
                    *ngIf="!autoEvent.active"
                    class="disabled"
                    >{{ 'trackMaster.createdEvent.suspended' | transloco }}</span
                  >
                  <h4>{{ autoEvent.desc }}</h4>
                </div>
              </div>
              <div
                *ngIf="
                  [AUTO_EVENTS_TYPES.CLICK, AUTO_EVENTS_TYPES.FORM, AUTO_EVENTS_TYPES.INPUT].indexOf(autoEvent.type) !==
                  -1
                "
                class="flex align-items-center margin-bottom-10"
              >
                <div class="margin-right-10 no-flex-shrink">
                  {{ 'trackMaster.createdEvent.cssSelector' | transloco }}
                </div>
                <input
                  class="form-control"
                  disabled
                  [ngModel]="autoEvent.selector"
                />
              </div>
              <div
                *ngIf="
                  [AUTO_EVENTS_TYPES.CLICK, AUTO_EVENTS_TYPES.FORM, AUTO_EVENTS_TYPES.INPUT].indexOf(autoEvent.type) ===
                  -1
                "
                class="flex align-items-center margin-bottom-10"
              >
                <div class="margin-right-10 no-flex-shrink">{{ 'trackMaster.createdEvent.pageUrl' | transloco }}</div>
                <input
                  class="form-control"
                  disabled
                  [ngModel]="autoEvent.url"
                />
              </div>
              <hr class="no-margin" />
            </div>
          </div>
          <div class="button-container">
            <button class="btn btn-outline-secondary btn-block invisible">
              <span>{{
                'trackMaster.configuredButton' | transloco: { settingCount: getAutoEventsByGroup(setting).length }
              }}</span>
              <i class="btn-icon-right cqi-sm cqi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
