import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';

import { App } from '@http/app/app.model';
import { AiContentMakerBodyJsonVariables } from '@http/message-part/message-part.types';
import { UserProperty } from '@http/property/property.model';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-acm-add-props',
  templateUrl: './acm-add-props.component.html',
  styleUrls: ['./acm-add-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcmAddPropsComponent
  extends AbsCVAFormArrayBasedComponent<AiContentMakerBodyJsonVariables, FormControl>
  implements OnInit
{
  @Input({ required: true })
  properties: UserProperty[] = [];

  @Input({ required: true })
  currentApp!: App;

  control: FormArray<FormControl<AiContentMakerBodyJsonVariables>> = this.fb.array<
    FormControl<AiContentMakerBodyJsonVariables>
  >([]);

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isEditorCollapsed: boolean = true;

  get defaultVariable() {
    return {
      propertyName: null,
      default: '',
      context: '',
    };
  }

  addVariable() {
    let control: FormControl<AiContentMakerBodyJsonVariables> = this.getNewControl();

    this.control.push(control);
  }

  deleteVariable(index: number) {
    this.control.removeAt(index);
  }

  getNewControl(variable?: AiContentMakerBodyJsonVariables): FormControl<AiContentMakerBodyJsonVariables> {
    if (!variable) {
      variable = this.defaultVariable;
    }

    return this.fb.control<AiContentMakerBodyJsonVariables>(variable, { nonNullable: true });
  }
}
