/**
 * Директива фильтрации по сегментам
 */
(function () {
  'use strict';

  require('./filter-segments.controller');
  require('./sending-filters-popover.html');

  //TODO разнести cqFilterSegmentsVertical и cqFilterSegmentsHorizontal на два отдельных компонента с общим контроллером
  angular
    .module('myApp.filterSegments')
    .component('cqFilterSegmentsVertical', cqFilterSegments())
    .component('cqFilterSegmentsHorizontal', cqFilterSegments('horizontal'));

  function cqFilterSegments(template) {
    return {
      bindings: {
        allowDeletingTags: '=?', // Разрешить удалять теги
        changed: '&?', // Callback при изменении фильтров
        currentApp: '=', //Текущий апп
        // кастомный класс для дропдауна селектора. Понадобился в компоннетах старого ангуляра в триггерных цепочках,
        // чтоб перебить z-index модалки редактора условий отправки. Если она переписана на новый ангуляр,
        // флаг можно удалять тоже
        customSelectDropdownClass: '=?',
        filters: '=', // Фильтры
        disableJinjaFilters: '=?',
        jinjaFilterTemplate: '=', // Jinja фильтр
        jinjaFilterTemplateCheckingResult: '=',
        shownAudienceFilterType: '=', // Выбранный тип фильтров аудитории
        filtersForm: '=?', // Объект формы фильтров
        hideSegments: '=?', // Скрыть сегменты
        messagePageType: '=?', // Тип страницы, на котором размещен компонент, необходимо для корректных переводов
        pageType: '=?', // Страница, на который показывается компонент
        props: '=', // Свойста
        segments: '=?', // Сегменты
        showedSendingFiltersPopoverInAudience: '=', // Статус показа поповера с условиями отправки по URL в "Аудитория"
        telegramIntegrations: '=',
        tags: '=', // Теги,
        valid: '=?', //Валидностсть формы
      },
      controller: 'FilterSegmentsController',
      controllerAs: 'vm',
      template:
        template === 'horizontal' ? require('./filter-segments-horizontal.html') : require('./filter-segments.html'),
    };
  }
})();
