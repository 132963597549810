import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Addon } from '@http/addon/addon';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

/** Компонент для работы с аддоном */
@Component({
  selector: 'cq-addon[addon][isSubscriptionActive][numbersUniqueUsers][paymentPeriod][clickConnectButton]',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonComponent {
  /** Аддон */
  @Input()
  addon!: Addon;

  /** Активная подписка */
  @Input()
  isSubscriptionActive!: boolean;

  /** Количество уникальных пользователей в тарифе */
  @Input()
  numbersUniqueUsers!: number;

  /** Платёжный период */
  @Input()
  paymentPeriod!: number;

  /** Callback на клик по кнопке подключения */
  @Output()
  clickConnectButton: EventEmitter<Addon> = new EventEmitter();

  constructor(readonly l10nHelper: L10nHelperService, readonly subscriptionStore: SubscriptionStore) {}

  readonly BILLING_ADD_ONS = BILLING_ADD_ONS;

  /** Стоимость перерасхода, которая будет выставлена в счёте */
  getOverspendingPricePerPeriod(currentValue: number, maxValue: number) {
    const overspendingCount = currentValue - maxValue;
    return overspendingCount * this.addon.overspendingPrice;
  }

  /** Произошёл ли перерасход по аддону */
  isOverspending(currentValue: number, maxValue: number): boolean {
    return currentValue > maxValue;
  }

  /** Обработчик клика по кнопке подключения */
  onClickConnectButton(addon: Addon): void {
    this.clickConnectButton.next(addon);
  }
}
