import { InjectionToken, Provider } from '@angular/core';

import { UtilsService } from '@panel/app/services/utils/utils.service';

export const IS_WEB_APP = new InjectionToken<boolean>('Detects if app is opened from browser');

export const isWebAppTokenProvider: Provider = {
  provide: IS_WEB_APP,
  useFactory: (utilsService: UtilsService) => utilsService.webApp,
  deps: [UtilsService],
};
