import { InjectionToken } from '@angular/core';

import { SavedReply } from '@http/saved-reply/saved-reply.types';

/** Тип данных, передаваемых в EditSavedReplyModal */
export type EditSavedReplyModalData = {
  savedReply: SavedReply;
};

/**
 * Токен для передачи данных через DI в EditSavedReplyModal
 */
export const EDIT_SAVED_REPLY_MODAL_DATA_TOKEN = new InjectionToken<EditSavedReplyModalData>(
  'EditSavedReplyModal data provider',
);
