import { firstValueFrom } from 'rxjs';
import { ARTICLES_COUNT_LIMIT } from '../../../../app/http/knowledge-base/knowledge-base.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular.module('myApp.knowledgeBase').controller('CqArticleEditController', CqArticleEditController);

  function CqArticleEditController(
    $filter,
    $scope,
    $state,
    $translate,
    $uibModal,
    ipCookie,
    toastr,
    API_ENDPOINT,
    FROALA_EDITOR_KEY,
    LANGUAGE,
    articleModel,
    carrotquestHelper,
    caseStyleHelper,
    froalaHelper,
    l10nHelper,
    paywallService,
    planFeatureAccessService,
    validationHelper,
  ) {
    let vm = this;

    /**
     * Максимальная длина описания статьи
     *
     * @type {number}
     */
    var MAX_DESCRIPTION_LENGTH = 255;

    /**
     * Максимальная длина названия статьи
     *
     * @type {number}
     */
    var MAX_NAME_LENGTH = 100;

    /**
     * Минимальная длина названия статьи
     *
     * @type {number}
     */
    var MIN_NAME_LENGTH = 3;

    /**
     * Набор кнопок в редакторе статей
     *
     * @type {array}
     */
    var toolbarButtons = [
      'clearFormatting',
      '|',
      'bold',
      'italic',
      'paragraphFormat',
      'fontSize',
      'align',
      '|',
      'insertCode',
      '|',
      'insertLink',
    ];

    vm.$onInit = init;
    function init() {
      vm.accessToKnowledgeBasePro = planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE_PRO, vm.currentApp);

      carrotquestHelper.track('База знаний - переход на статью');

      vm.onArticleNameChange = onArticleNameChange;
      vm.onArticleDescriptionChange = onArticleDescriptionChange;
      vm.apiMessageArticleAlreadyExistsError = false; // Флаг уже существующей статьи с таким именем
      vm.articlesLeftOnStandart = ARTICLES_COUNT_LIMIT - vm.articlesCount; // Количество оставшихся статей для создания на базовом плане
      vm.froalaOptions = {
        // опции для froala editor
        disableRightClick: true,
        events: {
          'froalaEditor.image.error': froalaHelper.handleImageUploadError,
        },
        fontSize: ['13', '15', '17', '23'],
        heightMin: 100,
        toolbarInline: true,
        quickInsertEnabled: true,
        quickInsertButtons: ['image', 'video', 'table', 'insertPre', 'ol', 'ul', 'hr'],
        lineBreakerTags: ['.table-responsive', 'hr', 'form', 'dl', 'span.fr-video', '.fr-embedly', 'pre'],
        pastePlain: true,
        imageEditButtons: [
          'imageReplace',
          'imageRemove',
          'imageLink',
          'linkOpen',
          'linkEdit',
          'linkRemove',
          'imageAlt',
          'imageSize',
        ],
        imageMaxSize: 1024 * 1024,
        imageUploadURL:
          API_ENDPOINT +
          '/knowledgebase/image?froala=true&auth_token=' +
          'appm-' +
          vm.currentApp.id +
          '-' +
          ipCookie('carrotquest_auth_token_panel'),
        key: FROALA_EDITOR_KEY,
        language: LANGUAGE,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkList: [
          {
            href: 'https://google.com',
            rel: 'nofollow',
            target: '_blank',
            text: 'Google',
          },
        ],
        listAdvancedTypes: false,
        imageDefaultWidth: 0,
        paragraphFormat: {
          N: $translate.instant('articleEdit.froalaOptions.paragraphFormat.normal'),
          H1: $translate.instant('articleEdit.froalaOptions.paragraphFormat.h1'),
          H2: $translate.instant('articleEdit.froalaOptions.paragraphFormat.h2'),
        },
        placeholderText: $translate.instant('articleEdit.froalaOptions.placeholderText'),
        requestWithCORS: false,
        theme: 'carrot-quest',
        toolbarButtons: toolbarButtons,
        toolbarButtonsMD: toolbarButtons,
        toolbarButtonsSM: toolbarButtons,
        toolbarButtonsXS: toolbarButtons,
        toolbarSticky: false,
      };
      vm.MAX_DESCRIPTION_LENGTH = MAX_DESCRIPTION_LENGTH;
      vm.MAX_NAME_LENGTH = MAX_NAME_LENGTH;
      vm.MIN_NAME_LENGTH = MIN_NAME_LENGTH;
      vm.initialPublicStatus = vm.article.public;
      vm.onArticlePublicChange = onArticlePublicChange;
      vm.onQuillCreated = onQuillCreated;
      vm.save = save;
      vm.trackClickSaveArticle = trackClickSaveArticle;
      vm.validationHelper = validationHelper;

      $state.is('app.content.knowledgeBaseArticleEditAjs.copy') && preparingCreateCopy();

      // Если мы создаем новую статью, надо присвоить текущую категорию, в которой мы находимся
      if (!vm.article.id) {
        vm.article.category = vm.currentCategory;
      }

      // Если не подключен PRO модуль => надо сделать статус инексации таким же как и статус опубликованности
      if (vm.article.id && !vm.accessToKnowledgeBasePro.hasAccess) {
        vm.article.robotsIndex = vm.article.public;
      }

      //NOTE с какого то хера ng-quill-editor, елси чтото написать а потом удалить,
      // вместо путсой строки он возвращает строку с переносом.
      // Если этого не делать, то поле перестает валидироваться по атрибуту required
      $scope.$watch('[vm.article.name, vm.article.shortDescription]', function (newVal, oldVal) {
        if (newVal[0].match(/\n/g)) {
          vm.article.name = newVal[0].replace(/\n/g, '');
        }
        if (newVal[1].match(/\n/g)) {
          vm.article.shortDescription = newVal[1].replace(/\n/g, '');
        }
      });

      $scope.$watch('vm.billingInfo.addons', watchBillingInfoAddons);

      function watchBillingInfoAddons(newVal, oldVal) {
        if (newVal !== oldVal) {
          vm.accessToKnowledgeBasePro.hasAccess = planFeatureAccessService.getAccess(
            PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
            vm.currentApp,
          );
        }
      }
    }

    /**
     * Добавление горячих клавиш в Quill
     */
    function blockEnterKey(quillInstance) {
      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        ctrlKey: true,
        handler: blockEnter,
      });

      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        metaKey: true,
        handler: blockEnter,
      });

      quillInstance.keyboard.bindings['Enter'].unshift({
        key: 'Enter',
        shiftKey: null,
        handler: blockEnter,
      });

      /**
       * Обработки нажатия на Enter
       *
       * @return {boolean}
       */
      function blockEnter() {
        return false;
      }
    }

    /**
     * Подготавливает к созданию его копии
     */
    function preparingCreateCopy() {
      vm.article.name = vm.article.name + ' (' + $translate.instant('articleEdit.copy') + ')';
      vm.article.id = null;
    }

    /**
     * Сохранение статьи
     *
     * @param isValid Флаг валидности формы
     */
    function save(isValid) {
      if (isValid) {
        vm.apiMessageArticleAlreadyExistsError = false;

        if (vm.article.id) {
          firstValueFrom(articleModel.save(vm.currentApp.id, vm.article, 'ArticleAlreadyExistsError'))
            .then(articleSaveSuccess)
            .catch(saveError);
        } else {
          firstValueFrom(articleModel.create(vm.currentApp.id, vm.article, 'ArticleAlreadyExistsError'))
            .then(articleCreateSuccess)
            .catch(saveError);
        }
      } else {
        // В случае провала валидации осуществляем скрол вверх, чтобы пользователь увидел невалидные поля
        angular.element('#content')[0].scrollTo(0, 0);
      }

      function articleCreateSuccess(response) {
        trackCreateArticle(vm.article.public);
        // Если количество созданных статей ARTICLES_COUNT_LIMIT - 1, и пользователь создал еще одну =>
        // => он создал последнюю для стандартного модуля
        if (vm.articlesCount === ARTICLES_COUNT_LIMIT - 1 && !vm.accessToKnowledgeBasePro.hasAccess) {
          trackCreatedLastArticle();
          openLimitReachedModal();
        }

        $state.go('app.content.knowledgeBaseArticleEditAjs', {
          articleId: response.id,
          categoryId: vm.currentCategory.id,
        });
        toastr.success($translate.instant('articleEdit.toasts.createArticleSuccess'));
      }

      function articleSaveSuccess(response) {
        trackSaveArticle(vm.article.public);
        vm.initialPublicStatus = vm.article.public;
        toastr.success($translate.instant('articleEdit.toasts.saveArticleSuccess'));
      }

      function saveError(meta) {
        if (meta && meta.error === 'ArticleAlreadyExistsError') {
          vm.apiMessageArticleAlreadyExistsError = true;
        }
      }
    }

    /**
     * Callback смены статуса опбликованности
     *
     * @param publicStatus
     */
    function onArticlePublicChange(publicStatus) {
      if (!publicStatus) {
        vm.article.robotsIndex = false;
      } else if (publicStatus && !vm.accessToKnowledgeBasePro.hasAccess) {
        vm.article.robotsIndex = true;
      }
    }

    /**
     * Колбэк инициализации Quill
     *
     * @param {Quill} quillInstance
     */
    function onQuillCreated(quillInstance) {
      blockEnterKey(quillInstance);
    }

    /**
     * Открытие модалки о превышении количества созданный статей
     */
    function openLimitReachedModal() {
      var limitReachedModal = $uibModal.open({
        component: 'cqLimitReachedModalModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
        },
      });

      limitReachedModal.result.then(goToPro);

      function goToPro(addon) {
        paywallService.showPaywall(vm.currentApp, vm.accessToKnowledgeBasePro.denialReason);
      }
    }

    /**
     * Трек клика сохранения статьи
     */
    function trackClickSaveArticle() {
      carrotquestHelper.track('База знаний - клик на "Сохранить"');
    }

    /**
     * Трек создания последней статьи
     */
    function trackCreatedLastArticle() {
      carrotquestHelper.track('База знаний - Создал 30-ю статью');
    }

    /**
     * Трек создания статьи
     *
     * @param {Boolean} articlePublic - Статус опубликованости статьи
     */
    function trackCreateArticle(articlePublic) {
      carrotquestHelper.track('База знаний - создал статью', {
        'Статус публикации': articlePublic,
      });
    }

    /**
     * Трек сохранения статьи
     *
     * @param {Boolean} articlePublic - Статус опубликованости статьи
     */
    function trackSaveArticle(articlePublic) {
      carrotquestHelper.track('База знаний - сохранил статью', {
        'Статус публикации': articlePublic,
      });
    }

    function onArticleNameChange() {
      vm.articlePreviewForm.name.$setTouched();
      vm.articlePreviewForm.name.$setDirty();
      $scope.$apply();
    }

    function onArticleDescriptionChange() {
      vm.articlePreviewForm.description.$setTouched();
      vm.articlePreviewForm.description.$setDirty();
      $scope.$apply();
    }
  }
})();
