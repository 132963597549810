<div class="flex align-items-center">
  <ng-select
    class="w-100 mr-10"
    [items]="directoriesStore$ | async"
    [clearable]="false"
    [(ngModel)]="currentDirectoryId"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (change)="onChange($event)"
    bindLabel="prettyName"
    bindValue="id"
    groupBy="isSystem"
    placeholder="{{ 'autoMessages.table.directorySelect.placeholder' | transloco }}"
  >
    <ng-template
      ng-label-tmp
      let-item="item"
    >
      <i class="cqi-sm cqi-folder mr-5"></i>
      <span>{{ item.prettyName }}</span>
    </ng-template>

    <ng-template
      ng-optgroup-tmp
      let-item="item"
    >
      <span *ngIf="item.isSystem">{{ 'autoMessages.table.directorySelect.systemDirectories' | transloco }}</span>
      <span *ngIf="!item.isSystem">{{ 'autoMessages.table.directorySelect.userDirectories' | transloco }}</span>
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
    >
      <div class="flex align-items-center justify-space-between">
        <div
          class="text-truncate"
          [title]="item.prettyName"
        >
          {{ item.prettyName }}
        </div>
        <button
          *ngIf="!item.isSystem"
          class="btn btn-text-primary"
          (click)="$event.stopPropagation(); openEditDirectoryModal(item)"
          [attr.title]="'autoMessages.table.messageTypeSelect.choices.renameTitle' | transloco"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
      </div>
    </ng-template>

    <ng-template ng-footer-tmp>
      <button
        class="btn btn-secondary btn-block d-block"
        (click)="openEditDirectoryModal()"
        type="button"
      >
        {{ 'autoMessages.table.directorySelect.actionText' | transloco }}
      </button>
    </ng-template>
  </ng-select>
</div>
