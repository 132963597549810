import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';

const REGEXPS = {
  INTEGER: /^-?\d+$/,
  FLOAT: /^-?\d+(\.\d{0,2})?$/,
};

@Directive({
  selector: '[cqNumber]',
})
export class NumberDirective {
  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly elementRef: ElementRef<HTMLInputElement>,
  ) {}

  @Input()
  numberType: 'INTEGER' | 'FLOAT' = 'INTEGER';

  @Input()
  maxNumber: number | null = null;

  @HostListener('beforeinput', ['$event'])
  onBeforeInput(e: InputEvent) {
    const currentRegexp = REGEXPS[this.numberType];
    // Список всех inputType https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes
    if (!e.inputType.startsWith('insert')) {
      return;
    }
    const eventTarget = e.target as HTMLInputElement;

    const beforeValue = this.elementRef.nativeElement.value;
    const addedValue = (e.data || e.dataTransfer?.getData('text/plain')) ?? '';

    const valueAfterInput =
      beforeValue.slice(0, eventTarget.selectionStart ?? beforeValue.length) +
      addedValue +
      beforeValue.slice(eventTarget.selectionEnd ?? beforeValue.length);

    if (!currentRegexp.test(valueAfterInput)) {
      e.preventDefault();
    }

    if (this.numberType === 'INTEGER' && this.maxNumber !== null && Number(valueAfterInput) > this.maxNumber) {
      e.preventDefault();
    }
  }

  // Такая штука нужна, потому что после "перетаскивания" строки в инпут, она вставляется с пробелами до и после.
  @HostListener('input', ['$event'])
  onInput(e: InputEvent) {
    const target = e.target as HTMLInputElement;
    target.value = target.value.replace(/ /g, '');
  }
}
