import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { EventSelectComponent } from './event-select.component';

@NgModule({
  declarations: [EventSelectComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
  exports: [EventSelectComponent],
})
export class EventSelectModule {}
