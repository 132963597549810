import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { createWidget } from '@typeform/embed';

import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { LS_MOVE_STARTER_GUIDE_MODAL } from '@panel/app/shared/constants/localstorage.keys';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

enum idsTypeformQuiz {
  RU = 'xyBoFwR0',
  US = 'pZeE8GqY',
}
@Component({
  selector: 'cq-quiz-in-registration-modal',
  templateUrl: './quiz-in-registration-modal.component.html',
  styleUrls: ['./quiz-in-registration-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizInRegistrationModalComponent {
  /** Инстанс модального окна на AngularJS */
  @Input()
  ajsModalInstance?: any;

  activeLang: string = this.translocoService.getActiveLang();

  isStartQuiz: boolean = false;

  typeformContainer!: HTMLElement;

  idForTypeformQuiz: string = idsTypeformQuiz[this.l10nHelper.currentCountry];

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(DOCUMENT)
    private readonly document: Document,
    public readonly l10nHelper: L10nHelperService,
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
    private readonly $uibModal: UibModalService,
    private readonly translocoService: TranslocoService,
  ) {}

  onSubmitQuiz() {
    this.localStorage.setItem(LS_MOVE_STARTER_GUIDE_MODAL, 'true');
    this.trackSubmitRegistrationQuiz();
  }

  skipQuiz() {
    this.$uibModal.open({
      component: 'cqQuizSkipConfirmModalWrapper',
      size: 'md modal-dialog-centered',
      backdrop: false,
    });
  }

  /** Вызов квиза из typeform */
  startQuizInTypeform() {
    this.isStartQuiz = true;

    const options = {
      container: this.document.getElementById('typeform-container') as HTMLElement,
      onReady: this.trackEnterOnRegistrationQuiz.bind(this),
      onSubmit: this.onSubmitQuiz.bind(this),
    };

    createWidget(this.idForTypeformQuiz, options);
  }

  trackEnterOnRegistrationQuiz() {
    this.carrotquestHelper.track('Онбординг - квиз для простого запуска начат');
  }
  trackSubmitRegistrationQuiz() {
    this.carrotquestHelper.track('Онбординг - квиз для простого запуска пройден');
  }
}
