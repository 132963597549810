/** Тип view экшенов */
export enum INTERACTIVE_BLOCK_PART {
  /** Сообщение отправлено */
  AUTOMESSAGE_SENT = 'chat_sent',
  /** Задержка */
  DELAY = 'delay',
  /** Дата задержки прошла */
  DELAY_PASSED = 'delay_passed',
  /** Аудитория соответствует фильтрам */
  FILTER_MATCH = 'filter_match',
  /** Аудитория не соответствует фильтрам */
  FILTER_NOT_MATCH = 'filter_not_match',
  /** Реакция не задана */
  NO_REACTION = 'no_reaction',
  /** Реакция задана */
  REACTION = 'reaction',
  /** Триггеры условий отправки */
  SENDING_CONDITION_TRIGGERS = 'sending_condition_triggers',
  /** Действие выбрано */
  ACTION = 'action',
}
