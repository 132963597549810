import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

import { OutdatedBrowserBannerComponent } from './outdated-browser-banner.component';

@NgModule({
  declarations: [OutdatedBrowserBannerComponent],
  exports: [OutdatedBrowserBannerComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [L10nHelperService, CarrotquestHelperProvider],
})
export class OutdatedBrowserBannerModule {}
