<ng-container *transloco="let t; read: 'messageScheduleRepeatDelayComponent'">
  <div class="d-flex align-items-center pb-15">
    <cq-switch-new
      class="mr-15"
      [formControl]="form.controls.repeatDelayEnabled"
    ></cq-switch-new>
    <div class="white-space-nowrap">
      <h5 class="d-inline-block mb-0 font-weight-bold">{{ t('heading') }}</h5>
    </div>
  </div>

  <div
    class="pl-50"
    *ngIf="form.controls.repeatDelayEnabled.value"
  >
    <div class="mb-10">{{ t('description') }}</div>
    <div class="flex">
      <div
        [cqShowError]="form.controls.repeatDelay"
        class="flex mr-0 mb-0"
      >
        <input
          cqNumber
          class="form-control repeat-delay-input"
          [formControl]="form.controls.repeatDelay"
        />
        <cq-validation-messages [control]="form.controls.repeatDelay">
          <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
          <cq-validation-message errorName="min">{{ t('errors.min') }}</cq-validation-message>
          <cq-validation-message errorName="max">{{ t('errors.max', { maxValue: MAX_DAYS }) }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="ml-10 align-content-center">{{ t('afterInput', { value: form.controls.repeatDelay.value }) }}</div>
    </div>
  </div>
</ng-container>
