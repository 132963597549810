import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { MAX_AFTER_DELAY } from '@http/message/message.constants';
import { CLICKHOUSE_PROPERTY_TYPE } from '@http/property/property.constants';
import { UserProperty } from '@http/property/property.model';
import { TriggerChainStepDelay } from '@http/trigger-chain/internal-types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { TimeSelectorConfig } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.component';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

type DelayFormConfig = {
  name: FormControl<string>;
  delay: FormControl<TimeSelectorConfig | null>;
  propertyName: FormControl<string | null>;
  waitForDate: FormControl<moment.Moment | null>;
};

enum DELAY_GROUP {
  FIXED = 'fixed',
  DYNAMIC = 'dynamic',
}
enum DELAY_TYPE {
  DELAY = 'seconds',
  WAIT_FOR_DATE = 'date',
  DELAY_BASED_ON_PROPERTY = 'beforeDateBasedOnProperty',
}

@Component({
  selector: 'cq-trigger-chain-delay-editor[app][step]',
  templateUrl: './trigger-chain-delay-editor.component.html',
  styleUrls: ['./trigger-chain-delay-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainDelayEditorComponent {
  @Input({ required: true })
  app!: App;

  readonly form = new FormGroup<DelayFormConfig>({
    name: new FormControl('', { nonNullable: true }),
    delay: new FormControl<TimeSelectorConfig>({ time: 5, unit: TIME_UNITS.SECOND }, { nonNullable: true }),
    propertyName: new FormControl<string | null>(null),
    waitForDate: new FormControl(null),
  });

  readonly CLICKHOUSE_PROPERTY_TYPE = CLICKHOUSE_PROPERTY_TYPE;
  readonly DELAY_TYPE = DELAY_TYPE;
  readonly DELAY_GROUP = DELAY_GROUP;

  selectedDelayType: DELAY_TYPE = DELAY_TYPE.DELAY;
  selectedDelayGroup: DELAY_GROUP = DELAY_GROUP.FIXED;

  private _step!: TriggerChainStepDelay;
  @Input({ required: true })
  set step(step: TriggerChainStepDelay) {
    this._step = step;
    this.form.setValue({
      name: step.name,
      delay: step.meta.delay,
      propertyName: step.meta.propertyName,
      waitForDate: step.meta.waitForDate ? moment.unix(step.meta.waitForDate) : null,
    });
    this.selectedDelayGroup = this.getSelectedDelayGroup();
    this.selectedDelayType = this.getSelectedDelayType();
  }

  get step() {
    return this._step;
  }

  @Output()
  stepChange: Observable<TriggerChainStepDelay> = this.form.valueChanges.pipe(
    map((controlValues) => {
      controlValues.name && (this.step.name = controlValues.name);
      controlValues.delay && (this.step.meta.delay = controlValues.delay);
      this.step.meta.propertyName = controlValues.propertyName ?? null;
      this.step.meta.waitForDate = controlValues.waitForDate ? controlValues.waitForDate.unix() : null;
      return this.step;
    }),
  );

  readonly MAX_DELAY = MAX_AFTER_DELAY;

  constructor(
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    protected readonly triggerChainEditorStore: TriggerChainEditorStore,
    protected readonly l10nHelper: L10nHelperService,
  ) {}

  deleteStepChain() {
    this.editorInteractions.deleteClick.next(this.step);
  }

  copyStepChain() {
    this.editorInteractions.copyClick.next(this.step);
  }

  getSelectedDelayGroup() {
    if (this.form.controls.propertyName.value) {
      return DELAY_GROUP.DYNAMIC;
    }
    return DELAY_GROUP.FIXED;
  }

  getSelectedDelayType() {
    if (this.form.controls.waitForDate.value) {
      return DELAY_TYPE.WAIT_FOR_DATE;
    }

    if (this.form.controls.propertyName.value) {
      return DELAY_TYPE.DELAY_BASED_ON_PROPERTY;
    }

    return DELAY_TYPE.DELAY;
  }

  onPropertiesChange(userProps: UserProperty[]) {
    this.triggerChainEditorStore.properties$.next({
      ...this.triggerChainEditorStore.properties$.getValue(),
      userProps,
    });
  }
  onDelayGroupChange(value: DELAY_GROUP) {
    this.resetDelay();
    this.resetDelayTypeByDelayGroup(value);
    this.selectedDelayGroup = value;
  }
  onDelayTypeChange(value: DELAY_TYPE) {
    this.resetDelay();

    if (value === DELAY_TYPE.WAIT_FOR_DATE) {
      this.form.controls.waitForDate.setValue(moment().tz(this.app.settings.timezone));
    }
  }

  resetDelay() {
    this.form.controls.propertyName.reset(null);
    this.form.controls.waitForDate.reset(null);
    this.form.controls.delay.reset({ time: 5, unit: TIME_UNITS.SECOND });
  }

  resetDelayTypeByDelayGroup(delayGroup: DELAY_GROUP) {
    if (DELAY_GROUP.FIXED === delayGroup) {
      this.selectedDelayType = DELAY_TYPE.DELAY;
    }
    if (DELAY_GROUP.DYNAMIC === delayGroup) {
      this.selectedDelayType = DELAY_TYPE.DELAY_BASED_ON_PROPERTY;
    }
  }
}
