import angular from 'angular';
import { firstValueFrom, Subject } from 'rxjs';
import { UserColorsCalculationService } from '../../../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { ChatBotModel } from '../../../../../app/http/chat-bot/chat-bot.model';
import { ActiveChatWidget, WidgetBot } from '../../../../../app/http/chat-bot/types/chat-bot-internal.types';
import { CHAT_BOT_ACTIONS_TYPES } from '../../../../../app/http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';
import { ChatBotActionMapper } from '../../../../../app/http/chat-bot/mappers/action.mapper';
import { JinjaService } from '../../../../../app/shared/services/jinja/jinja.service';

(function () {
  'use strict';

  angular.module('myApp.chatBot.widgetBot').controller('cqWidgetBotEditController', cqWidgetBotEditController);

  function cqWidgetBotEditController(
    $filter,
    $state,
    $timeout,
    $translate,
    $q,
    toastr,
    CDN_ENDPOINT,
    DEFAULT_ORDER_SOCIAL_NETWORKS,
    chatBotModel,
    defaultBotHelper,
    carrotquestHelper,
    wizardHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.activateBot = changeBotStatus.bind(vm, true);
      vm.bot = vm.widgetBot ?? defaultBotHelper.getInitialBot(CHAT_BOT_TYPE.WIDGET);
      vm.cdnEndpoint = CDN_ENDPOINT;
      vm.chatBotSnapshot = angular.copy(vm.bot);
      vm.deletedBranches = [];
      vm.getCurrentActiveChatWidget = getCurrentActiveChatWidget;
      vm.initUpdateBotFn = initUpdateBotFn;
      vm.updateBot = () => {};
      vm.isEditing = $state.is('app.content.messagesAjs.widgetBot.edit');
      vm.firstStepOnExitCallback = () => {};
      vm.onChatWidgetFieldValueChange = onChatWidgetFieldValueChange;
      vm.onEmbeddedIntoChatValueChange = onEmbeddedIntoChatValueChange;
      vm.onValidationCallbackReady = onValidationCallbackReady;
      vm.onChatWidgetOrderChanged = onChatWidgetOrderChanged;
      vm.pauseBot = changeBotStatus.bind(vm, false);
      vm.setBotSubject = new Subject();
      vm.setBot$ = vm.setBotSubject.asObservable();
      vm.save = save;
      vm.step = 1; // Текущий шаг редактора
      vm.createBot = createBot;
      vm.validate = () => {};
      vm.validateButtonTextFn = () => {};
      vm.validateHeaderFn = () => {};
      vm.validateOverheaderFn = () => {};
      vm.wizard = null; //Визард

      vm.script = `<div data-cq-botid="${vm.bot.id}"></div>\n<script type="text/javascript" src="${vm.cdnEndpoint}/embedded.js"></script>`;

      setSettingsForChatPreview();

      wizardHelper.getWizard().then((wizard) => (vm.wizard = wizard));

      if (!vm.widgetBot) {
        vm.bot.name = $translate.instant('widgetBot.header.name');
        /** @type {ActiveChatWidget[]} */
        vm.activeChatWidgetList = [...vm.activeChatWidgetList, parseWidgetBotToActive(vm.bot)];
      }
      // Отдельная переменная для имени нужна, его можно было изменить
      // Без нее это сделать не получится из-за связки Ajs A2+
      vm.botName = vm.bot.name;

      vm.chatWidgetVisible = vm.bot.chatWidget.visible;
      vm.chatWidgetSubHeader = vm.bot.chatWidget.subHeader;
      vm.chatWidgetHeader = vm.bot.chatWidget.header;
      vm.chatWidgetButtonText = vm.bot.chatWidget.buttonText;

      if (!vm.isEditing) {
        trackOpenCreatePage();
      } else {
        trackOpenEditPage();
      }

      ChatBotActionMapper.parseActionsKeyName(vm.bot.branches, vm.properties);
      if (isContainJinjaUserProps(vm.bot.branches)) {
        parseJinjaToProps(vm.bot.branches, vm.properties.userProps);
      }
    }

    /**
     * Меняет статус активности роутинг-бота
     * @param activateStatus
     * @returns {angular.IPromise<void[]>}
     */
    function changeBotStatus(activateStatus) {
      if (activateStatus) {
        trackSetActiveBot();
      } else {
        trackSetPauseBot();
      }

      save(activateStatus);
    }

    /**
     * Получение веток, которые изменились после последнего сохранения
     *
     * @return {Object}
     */
    function getChangedBotBranches() {
      var changedBot = angular.copy(vm.bot);
      changedBot.branches = [];

      for (let i = 0; i < vm.bot.branches.length; i++) {
        const branch = vm.bot.branches[i];

        const branchSnapshot = $filter('filter')(vm.chatBotSnapshot.branches, { id: branch.id }, true)[0]; // Ветка до сохранения

        //Если нет id => это новая ветка
        // или имя веток отличается
        // или количество действий изменилось
        // или изменились координаты положения
        // => ветка поменялась
        if (
          !branch.id ||
          branchSnapshot.name !== branch.name ||
          branch.actions.length !== branchSnapshot.actions.length ||
          isCoordinatesChanged(branch.coordinates, branchSnapshot.coordinates)
        ) {
          changedBot.branches.push({ ...branch });
          continue;
        }

        for (let k = 0; k < branch.actions.length; k++) {
          const action = branch.actions[k];
          const filterQuery = action.id ? { id: action.id } : { linkId: action.linkId };
          const actionBranchSnapshot = $filter('filter')(branchSnapshot.actions, filterQuery, true)[0]; // Действие ветки до сохранения

          //Если есть нет id => это новое действие
          // или body отличается
          // или key_name отличается
          // или у прикрепления нет ID
          // или отличается порядок
          // или изменлось свойство NextBranch
          // => ветка поменялась
          if (
            !action.id ||
            // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
            (action.body !== actionBranchSnapshot.body && action.body !== null && actionBranchSnapshot.body !== '') ||
            (!angular.equals(action.bodyJson, actionBranchSnapshot.bodyJson) && action.bodyJson !== null) ||
            (action.keyName !== actionBranchSnapshot.keyName &&
              // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
              action.keyName !== null &&
              actionBranchSnapshot.keyName !== '') ||
            (action.type === CHAT_BOT_ACTIONS_TYPES.FILE && !action.attachments[0]?.id) ||
            action.order !== actionBranchSnapshot.order ||
            action.nextBranchLinkId !== actionBranchSnapshot.nextBranchLinkId
          ) {
            changedBot.branches.push({ ...branch });
            break;
          }
        }
      }

      return changedBot;

      function isCoordinatesChanged(branchCoords, branchSnapshotCoords) {
        // Считам что что-то поменялось если
        // branchSnapshot не имеет координат, а branch их получила
        // или branchSnapshot и branch имеет координаты и они не совпадают
        return (
          (!branchSnapshotCoords && branchCoords) ||
          // костыль: "> 3" сделано потому, что там при отрисовке происходит небольшое смещение, которое я не хочу править)
          (branchSnapshotCoords && branchCoords && Math.abs(branchSnapshotCoords.x - branchCoords.x) > 3) ||
          Math.abs(branchSnapshotCoords.y - branchCoords.y) > 3
        );
      }
    }

    /**
     * Инициализация функции по обновлению бота
     *
     * @param {Function} cb
     */
    function initUpdateBotFn(cb) {
      vm.updateBot = cb;
    }

    /**
     * Проверка наличия Jinja-фильтров в содержимом ветках бота
     * @param {ChatBotBranch[]} branches Ветки бота
     */
    function isContainJinjaUserProps(branches) {
      return branches.some((branch) => {
        return branch.actions.some((action) => {
          if (action.type === CHAT_BOT_ACTIONS_TYPES.TEXT) {
            return JinjaService.isContainJinjaUserProps(action.body);
          }

          return false;
        });
      });
    }

    /**
     * Замена Jinja-фильтров на стилизованные бейджи со свойствами пользователя
     * @param {ChatBotBranch[]} branches Ветки бота
     * @param {UserProperty[]} userProps Свойства пользователя
     */
    function parseJinjaToProps(branches, userProps) {
      branches.forEach((branch) => {
        branch.actions.forEach((action) => {
          const isTextType = action.type === CHAT_BOT_ACTIONS_TYPES.TEXT;
          const isJinjaExist = JinjaService.isContainJinjaUserProps(action.body);

          if (isTextType && isJinjaExist) {
            action.body = JinjaService.parseJinjaToProps(action.body, userProps);
          }
        });
      });
    }

    /**
     * Колбэк на изменение полей чат-виджета
     *
     * @param {keyof ChatWidget} field Изменяемое поле
     * @param value Значение
     */
    function onChatWidgetFieldValueChange(field, value) {
      $timeout(() => {
        vm.bot.chatWidget[field] = value;
        vm.bot = { ...vm.bot };

        // Изменить значения нужно не только в текущем боте, но и в списке активных embedded-ботов
        let currentBot = getCurrentActiveChatWidget();
        if (currentBot) {
          currentBot[field] = value;
          vm.activeChatWidgetList = [...vm.activeChatWidgetList];
        }
      });
    }

    /**
     * Колбэк на смену значения показа embedded-бота в чате
     *
     * @param {Boolean} value
     */
    function onEmbeddedIntoChatValueChange(value) {
      $timeout(() => {
        onChatWidgetFieldValueChange('visible', value);
        let currentBot = getCurrentActiveChatWidget();
        if (value) {
          // Добавляем currentBot в массив, если его там нет
          if (!currentBot) {
            vm.activeChatWidgetList.splice(vm.bot.chatWidget.order, 0, parseWidgetBotToActive(vm.bot));
          }
        } else {
          // Удаляем currentBot из массива, если он там есть
          if (currentBot) {
            const index = vm.activeChatWidgetList.findIndex((chatWidget) => chatWidget.action.chatBotId === vm.bot.id);
            if (index !== -1) {
              vm.activeChatWidgetList.splice(index, 1);
            }
          }
        }
        // Создаем новую копию массива для триггера обновления
        vm.activeChatWidgetList = [...vm.activeChatWidgetList];
      });
    }

    /**
     * Компонент с формой нового ангуляра по готовности отправляет callback, которым можно проверять валидность формы
     * @param cb { Function }
     */
    function onValidationCallbackReady(cb) {
      vm.firstStepOnExitCallback = cb;
    }

    /**
     * Колбэк на смену порядка активного чат-виджета
     *
     * @param {ActiveChatWidget[]} data
     */
    function onChatWidgetOrderChanged(data) {
      const newIndex = data.findIndex((chatWidget) => chatWidget.id === vm.bot.chatWidget.id);
      onChatWidgetFieldValueChange('order', newIndex);
    }

    /**
     * Сливает бота полученного с сервера с текущим ботом
     *
     * @param {Object} chatBot - Чат-бот
     * @param {Object} mapIds - Сопоставление linkId и id
     */
    function mergeBotFromServer(chatBot, mapIds) {
      for (var linkId in mapIds) {
        var branch = $filter('filter')(vm.bot.branches, { linkId: linkId }, true)[0];

        branch.id = mapIds[linkId];

        branch.parentBranchIds.splice(-1, 1, branch.id);
      }

      for (var i = 0; i < vm.bot.branches.length; i++) {
        var branch = $filter('filter')(chatBot.branches, { id: vm.bot.branches[i].id }, true)[0];

        // Чтобы код ниже работал нормально, надо гарантировать, что в момент получения бота с созданными действиями
        // их количество и порядок был таким же как и в момент сохранения, т.е. пользователь не производил ни каких действий с действиями (сорян за тавтологию)
        // на текущий меомент это гарантируется лоадером
        // таким образом i-ое действие текущего бота и равно i-му действию бота, пришедшего с бекенда
        for (var g = 0; g < vm.bot.branches[i].actions.length; g++) {
          var action = vm.bot.branches[i].actions[g];
          action.id = branch.actions[g].id;

          if (ChatBotModel.isConnectionSourceAction(action.type) && action.nextBranchLinkId) {
            action.nextBranchId = branch.actions[g].nextBranchId;
          }
        }
      }
    }

    /**
     * Получение текущего чат-виджета из списка активных чат-виджетов
     *
     * @return {ActiveChatWidget}
     */
    function getCurrentActiveChatWidget() {
      return vm.activeChatWidgetList.find((chatWidget) => chatWidget.action.chatBotId === vm.bot.id || !chatWidget.id);
    }

    /**
     * Создание и запуск бота
     */
    function createBot() {
      const activateStatus = true;

      save(activateStatus)
        .then(() => {
          vm.deletedBranches = [];
        })
        .catch(() => {});
    }

    /**
     * Парсинг бота в «активного» бота
     *
     * @param {WidgetBot} widgetBot
     * @return {ActiveChatWidget}
     */
    function parseWidgetBotToActive(widgetBot) {
      return {
        id: widgetBot.chatWidget?.id,
        subHeader: widgetBot.chatWidget?.subHeader,
        header: widgetBot.chatWidget?.header,
        buttonText: widgetBot.chatWidget?.buttonText,
        order: widgetBot.chatWidget?.order,
        action: {
          chatBotId: widgetBot.id,
        },
      };
    }

    /**
     * Функция сохранения бота
     * @param {boolean} activateStatus
     */
    function save(activateStatus) {
      activateStatus = activateStatus ?? vm.widgetBot.active;

      const validators = [];

      validators.push(vm.firstStepOnExitCallback('save'));

      const isOverheaderValid = vm.validateOverheaderFn().then((valid) => {
        return valid ? Promise.resolve() : Promise.reject();
      });

      const isHeaderValid = vm.validateHeaderFn().then((valid) => {
        return valid ? Promise.resolve() : Promise.reject();
      });

      const isButtonTextValid = vm.validateButtonTextFn().then((valid) => {
        return valid ? Promise.resolve() : Promise.reject();
      });

      validators.push(isOverheaderValid, isHeaderValid, isButtonTextValid);

      return Promise.all(validators)
        .then(vm.isEditing ? saveWidgetBot : createWidgetBot)
        .then((response) => {
          return vm.isEditing ? saveSuccess(response) : createSuccess(response);
        })
        .then(vm.activeChatWidgetList.length > 0 ? saveChatWidgetListOrder : new Promise((res) => res()))
        .catch(saveOrCreateError)
        .finally(saveOrCreateFinally);

      function saveWidgetBot() {
        trackSaveBot();
        vm.isRequestPerforming = true;
        const botChanges = getChangedBotBranches();

        return firstValueFrom(
          chatBotModel.saveWidgetBot(
            vm.currentApp.id,
            { ...botChanges, name: vm.botName, active: activateStatus, chatWidget: vm.bot.chatWidget },
            vm.deletedBranches,
          ),
        );
      }

      function createWidgetBot() {
        if (activateStatus) {
          trackCreateAndSetActive();
        } else {
          trackCreateAndSetPaused();
        }
        vm.isRequestPerforming = true;
        return firstValueFrom(
          chatBotModel.createWidgetBot(vm.currentApp.id, {
            ...vm.bot,
            name: vm.botName,
            active: activateStatus,
            chatWidget: vm.bot.chatWidget,
          }),
        );
      }

      function saveSuccess(response) {
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        mergeBotFromServer(response.chatBot, response.mapIds);
        vm.widgetBot.active = response.chatBot.active;
        vm.chatBotSnapshot = angular.copy(vm.bot);
        vm.deletedBranches = [];
        vm.setBotSubject.next({ chatBot: vm.bot, mapIds: response.mapIds, doNotRedrawCanvas: true });
        return $q.resolve();
      }

      function createSuccess(response) {
        // После создания бота нужно добавить ID чат-виджету, чтобы тот попал в список для сортировки
        const isChatWidgetActive = response.chatBot.chatWidget.visible;
        if (isChatWidgetActive) {
          const currentActiveChatWidget = getCurrentActiveChatWidget();
          currentActiveChatWidget.id = response.chatBot.chatWidget.id;
        }

        $state.go('app.content.messagesAjs.widgetBot.edit', {
          widgetBotId: response.chatBot.id,
        });
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        return $q.resolve();
      }

      function saveOrCreateError() {
        return $q.reject();
      }

      function saveOrCreateFinally() {
        vm.isRequestPerforming = false;
      }
    }

    /** Сохранение сортировки чат-виджетов */
    function saveChatWidgetListOrder() {
      const idsArray = vm.activeChatWidgetList.filter((chatWidget) => chatWidget.id).map((chatWidget) => chatWidget.id);
      return firstValueFrom(chatBotModel.saveChatWidgetListOrder(vm.currentApp.id, idsArray)).then(
        (response) => (vm.activeChatWidgetList = response),
      );
    }

    /** Установка различных настроек для корректного отображения превью чата */
    function setSettingsForChatPreview() {
      vm.accentHexColor = vm.chatSettings.messenger_collapsed_color;
      vm.isMainSettings = true;
      vm.isWaitingOperatorResponse = true;
      vm.orderedSocialNetworks = updateOrderSocialNetworks();
      vm.trackingDataKnown = false; // Знаем ли мы данные пользователя
      vm.workingTime = false; // Рабочее ли сейчас время
      // В статике есть логика того, что мы меняем его цвет, если цвет у клиента слишком светлый в светлой теме
      vm.accentHexColor = UserColorsCalculationService.getMainUserColor(
        vm.chatSettings.messenger_collapsed_color,
        vm.chatSettings.messenger_theme,
      );

      const {
        accentHoveredHexColor,
        isLightOnClientsColorLooksWell,
        contrastColor,
        userColorAndThemeDependent,
        iconTheme,
        isContrastGood,
      } = UserColorsCalculationService.getUserColorPalette(vm.accentHexColor, vm.chatSettings.messenger_theme);

      vm.accentHoveredColor = accentHoveredHexColor;
      vm.contrastColor = contrastColor;
      vm.userColorAndThemeDependent = userColorAndThemeDependent;
      vm.doAccentAndWhiteMatchGood = isLightOnClientsColorLooksWell;
      vm.iconTheme = iconTheme;
      vm.isContrastGood = isContrastGood;
    }

    /**
     * Трек открытия страницы создания
     */
    function trackOpenCreatePage() {
      carrotquestHelper.track('Widget-бот - перешел на страницу создания');
    }

    /**
     * Трек открытия страницы редактирования
     */
    function trackOpenEditPage() {
      carrotquestHelper.track('Widget-бот - зашел на страницу редактирования');
    }

    /**
     * Трек создания бота в статусе активен
     */
    function trackCreateAndSetActive() {
      carrotquestHelper.track('Widget-бот - создал и запустил');
    }

    /**
     * Трек создания бота в статусе приостановлен
     */
    function trackCreateAndSetPaused() {
      carrotquestHelper.track('Widget-бот - создал, но не запустил');
    }

    /**
     * Трек сохранения бота
     */
    function trackSaveBot() {
      carrotquestHelper.track('Widget-бот - сохранил изменения');
    }

    /**
     * Трек смены статуса бота на "Активен"
     */
    function trackSetActiveBot() {
      carrotquestHelper.track('Widget-бот - активировал');
    }

    /**
     * Трек смены статуса бота на "Приостановлен"
     */
    function trackSetPauseBot() {
      carrotquestHelper.track('Widget-бот - приостановил');
    }

    /**
     * Удаление выключенных и обновление порядка соц. сетей
     *
     * @returns {*[]}
     */
    function updateOrderSocialNetworks() {
      const orderSocialNetworks = vm.chatSettings.messenger_icon_order
        ? vm.chatSettings.messenger_icon_order
        : DEFAULT_ORDER_SOCIAL_NETWORKS;

      let newOrderSocialNetworks = [];

      orderSocialNetworks.forEach((socialName) => {
        if (vm.chatSettings[`messenger_icon_${socialName}_show`]) {
          newOrderSocialNetworks.push(socialName);
        }
      });

      return newOrderSocialNetworks;
    }
  }
})();
