import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import Quill from 'quill';
import { map, skip, takeUntil } from 'rxjs/operators';

import { UserProperty } from '@http/property/property.model';
import { EDITOR_FORMAT } from '@panel/app/partials/editor/editor.constant';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-editor-wrapper[value]',
  templateUrl: './editor-wrapper.component.html',
  styleUrls: ['./editor-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class EditorWrapperComponent implements OnInit {
  @Input({ required: false })
  public class: string = '';

  @Input({ required: true })
  public format: 'text' | 'html' = 'html';

  @Input({ required: false })
  public minHeight: string = '';

  @Input({ required: false })
  public placeholder: string = '';

  @Input({ required: false })
  public formats: Array<EDITOR_FORMAT> = [];

  @Input({ required: false })
  public userProperties: UserProperty[] = [];

  @Input({ required: false })
  public validators: ValidatorFn[] = [];

  @Input({ required: false })
  public value: string = '';

  @Output()
  public editorChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editorCreated: EventEmitter<Quill> = new EventEmitter<Quill>();

  @Output()
  public selectionChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public textChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public valueChange = new EventEmitter<string>();

  protected control: FormControl<string> = this.formBuilder.control('', { nonNullable: true });

  constructor(private destroy$: DestroyService, private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.control.setValue(this.value, { emitEvent: false });

    this.control.valueChanges
      .pipe(
        skip(1),
        map((value) => value.replace(/\n$/, '')),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.value = value;
        this.valueChange.emit(this.value);
      });
  }

  protected onEditorChanged(): void {
    this.editorChanged.emit();
  }

  protected onEditorCreated(editor: Quill): void {
    this.editorCreated.next(editor);
  }

  protected onSelectionChanged(): void {
    this.selectionChanged.emit();
  }

  protected onTextChanged(): void {
    this.textChanged.emit();
  }
}
