import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionComponent, NgSelectComponent } from "@ng-select/ng-select";

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { IntegrationCardModule } from '@panel/app/pages/integrations/integration-card/integration-card.module';
import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { IntegrationListComponent } from './integration-list.component';

@NgModule({
  declarations: [IntegrationListComponent],
  exports: [IntegrationListComponent],
  imports: [
    CommonModule,
    FormsModule,
    IntegrationCardModule,
    NgbTooltipModule,
    PermissionDeniedModule,
    SharedModule,
    TranslocoModule,
    NgSelectComponent,
    NgOptionComponent,
  ],
  providers: [CarrotquestHelperProvider],
})
export class IntegrationListModule {}
