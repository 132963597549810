import { Container, Text } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

export class ActionView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.ACTION> {
  /** Тип */
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.ACTION;

  hasConnection(): boolean {
    return this.stepView.data.nextStepConnected;
  }

  renderContent(): Container {
    let elementsForRender: Container[] = [];

    elementsForRender.push(this.renderValue());
    elementsForRender.push(this.connectionPoint);

    const el = ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      background: {
        sideTextureName: 'background-success950-272-side',
        innerTextureName: 'background-success950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
          y: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
        },
      },
    });

    this.addAndPositionConnectionPoint(el);

    return el;
  }

  private getMessageTypeText(): Container {
    let typeName = this.transloco.translate(`interactiveBlockPart.action.label`);

    return CanvasRenderService.renderText(typeName, {
      color: HEX_COLOR.SUCCESS_400,
      wordWrap: false,
    });
  }

  private getMessageTypeIcon(): Text {
    return CanvasRenderService.renderIcon(UNICODE_ICON['cqi-check-circle-o'], { color: HEX_COLOR.SUCCESS_400 });
  }

  private renderValue(): Container {
    const icon = this.getMessageTypeIcon();

    const blockTypeText = this.getMessageTypeText();

    return ElementBuilder.wrapElementsInRectangle([icon, blockTypeText], {
      placement: {
        marginBetweenCols: 5,
      },
    });
  }
}
