import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { AI_SALES_ROUTES } from '@panel/app/pages/ai-sales/ai-sales.routes';
import { AiSalesListModule } from '@panel/app/pages/ai-sales/ai-sales-list/ai-sales-list.module';
import { AiSalesSettingsModule } from '@panel/app/pages/ai-sales/ai-sales-settings/ai-sales-settings.module';

import { AiSalesComponent } from './ai-sales.component';

@NgModule({
  declarations: [AiSalesComponent],
  imports: [
    AiSalesListModule,
    AiSalesSettingsModule,
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: AI_SALES_ROUTES }),
  ],
})
export class AiSalesModule {}
