import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TriggerChainActionType } from '@http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_ACTION_TYPE } from '@http/trigger-chain/trigger-chain.constants';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-user-tag-action',
  templateUrl: './user-tag-action.component.html',
  styleUrls: ['./user-tag-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagActionComponent extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<TriggerChainActionType>> {
  readonly control: FormGroup<ToFormGroupControls<TriggerChainActionType>> = this.fb.group<
    ToFormGroupControls<TriggerChainActionType>
  >({
    body: this.fb.control(null, { nonNullable: true, validators: [Validators.required] }),
    keyName: this.fb.control(null),
    type: this.fb.control(TRIGGER_CHAIN_ACTION_TYPE.USER_TAG, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    private readonly fb: FormBuilder,
    readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {
    super();
  }
}
