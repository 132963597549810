<ng-container *transloco="let t; read: 'propertySelectorComponent'">
  <div [cqShowError]="control">
    <ng-select
      [items]="
        properties
          | filterAndSortUserProps
            : control.value
            : {
                hasAccessToUsersCustomProperties: accessToUsersCustomProperties.hasAccess,
                sortType,
                userPropsTypeFilter,
              }
      "
      [formControl]="control"
      bindLabel="prettyName"
      bindValue="name"
      (change)="selectedChange.next($event)"
      [clearable]="false"
      groupBy="group"
      placeholder="{{ t('placeholder') }}"
      [required]="true"
      [trackByFn]="trackByFn"
      [classList]="['ng-touched']"
      [virtualScroll]="true"
    >
      <ng-container *ngIf="customOptionTemplate; else defaultTemplate">
        <ng-template
          ng-option-tmp
          let-option="item"
        >
          <ng-container *ngTemplateOutlet="customOptionTemplate; context: { option }"></ng-container>
        </ng-template>
      </ng-container>
      <ng-template #defaultTemplate>
        <ng-template
          ng-option-tmp
          let-option="item"
        >
          <span class="white-space-normal">{{ option.prettyName }}</span>
        </ng-template>
      </ng-template>

      <ng-container *ngIf="accessToUsersCustomProperties.hasAccess">
        <ng-container *ng-footer-tmp>
          <button
            class="btn btn-secondary full-width"
            (click)="openAddPropertyModal()"
            type="button"
          >
            {{ t('addPropertyButton') }}
          </button>
        </ng-container>
      </ng-container>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>
