(function () {
  'use strict';

  require('./conversations-general.controller');

  angular.module('myApp.conversations').component('cqConversationsGeneral', cqConversationsGeneral());

  function cqConversationsGeneral() {
    return {
      bindings: {
        appBlocks: '=', // данные о блокировках приложения
        billingInfo: '=', // информация по биллингу
        channelCounters: '=', // счётчики каналов
        channels: '=', // список каналов
        conversations: '=', // списко диалогов
        currentApp: '=', // текущее приложение
        djangoUser: '=', // django-пользователь
        knowledgeBaseSettings: '=', // настройки БЗ
        telegramIntegrations: '=',
        emailDomains: '=', // список емейл доменов
        teamMembers: '=', // список членов команды
      },
      controller: 'CqConversationsGeneralController',
      controllerAs: 'vm',
      template: require('./conversations-general.html'),
    };
  }
})();
