import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import {
  MESSAGE_PART_TYPE_ICON_CLASSES,
  MESSAGE_PART_TYPES,
  MESSAGE_PART_TYPES_ARRAY,
} from '@http/message-part/message-part.constants';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-auto-message-type-filter',
  templateUrl: './auto-message-type-filter.component.html',
  styleUrls: ['./auto-message-type-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageTypeFilterComponent implements OnInit {
  @Input()
  currentApp: any;

  @Input()
  currentType: any;

  @Output()
  changeType: EventEmitter<any> = new EventEmitter<any>();

  protected FILTERED_MESSAGE_PART_TYPES_ARRAY: any = [];
  protected MESSAGE_PART_TYPE_ICON_CLASSES: any = MESSAGE_PART_TYPE_ICON_CLASSES;
  protected MESSAGE_PART_TYPES: any = MESSAGE_PART_TYPES;

  constructor(private readonly featureModel: FeatureModel, private readonly l10nHelperService: L10nHelperService) {}

  ngOnInit() {
    this.FILTERED_MESSAGE_PART_TYPES_ARRAY = this.filterMessagePartTypesArray(MESSAGE_PART_TYPES_ARRAY);
  }

  /**
   * Фильтрация массива типов варианта сообщения
   *
   * @param {MESSAGE_PART_TYPES_ARRAY} messagePartTypesArray
   * @returns {MESSAGE_PART_TYPES_ARRAY}
   */
  filterMessagePartTypesArray(messagePartTypesArray: any): any {
    let filteredMessagePartTypesArray = angular.copy(messagePartTypesArray);

    for (let i = filteredMessagePartTypesArray.length - 1; i >= 0; i--) {
      let messagePartType = filteredMessagePartTypesArray[i];

      if (~[MESSAGE_PART_TYPES.CONTROL_GROUP].indexOf(messagePartType)) {
        filteredMessagePartTypesArray.splice(i, 1);
      }

      // NOTE: Web-push'и не доступны в США и для app'ов, которые были зарегистрированы после END_TIME_FEATURES.WEB_PUSH
      if (
        ~[MESSAGE_PART_TYPES.PUSH].indexOf(messagePartType) &&
        (this.l10nHelperService.isUsCountry() ||
          !this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.currentApp && this.currentApp.created))
      ) {
        filteredMessagePartTypesArray.splice(i, 1);
      }

      const userActivatedInSdk =
        !!this.currentApp.activation.installed_sdk_ios || !!this.currentApp.activation.installed_sdk_android;
      // NOTE: Доступ до SDK-типов только по фичагалке
      if (
        !!~[
          MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
          MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.SDK_PUSH,
        ].indexOf(messagePartType) &&
        !userActivatedInSdk
      ) {
        filteredMessagePartTypesArray.splice(i, 1);
      }
    }

    return filteredMessagePartTypesArray;
  }

  onChangeType(type: any) {
    this.changeType.emit(type);
  }
}
