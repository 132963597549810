<overlay-scrollbars class="h-100 os-host-flexbox">
  <ng-container *ngIf="triggerChainEditorStore.currentApp$ | async as currentApp">
    <!-- С *ngSwitchCase не работает type narrowing, поэтому *ngIf -->
    <ng-container *ngIf="step.type === 'autoMessage'">
      <cq-trigger-chain-automessage-editor
        [(autoMessageStep)]="step"
        (autoMessageStepChange)="stepChangeSubj.next($event)"
      >
      </cq-trigger-chain-automessage-editor>
    </ng-container>
    <ng-container *ngIf="step.type === 'delay'">
      <cq-trigger-chain-delay-editor
        class="full-height"
        [app]="currentApp"
        [(step)]="step"
        (stepChange)="stepChangeSubj.next($event)"
      ></cq-trigger-chain-delay-editor>
    </ng-container>

    <ng-container *ngIf="step.type === 'filter'">
      <cq-trigger-chain-filter-editor-modal-wrap
        [app]="currentApp"
        [(step)]="step"
        [properties]="(triggerChainEditorStore.properties$ | async) ?? {userProps: [], eventTypeProps: [], eventTypes: [],}"
        (stepChange)="stepChangeSubj.next($event)"
        [isValidationStrict]="isValidationStrict"
        class="full-height"
      ></cq-trigger-chain-filter-editor-modal-wrap>
    </ng-container>

    <ng-container *ngIf="step.type === 'sendingConditions'">
      <cq-trigger-chain-sending-conditions-editor
        [(sendingConditionsStep)]="step"
        (sendingConditionsStepChange)="stepChangeSubj.next($event)"
      >
      </cq-trigger-chain-sending-conditions-editor>
    </ng-container>
    <ng-container *ngIf="step.type === 'reaction'">
      <cq-trigger-chain-reaction-editor
        [(step)]="step"
        (stepChange)="stepChangeSubj.next($event)"
        [isValidationStrict]="isValidationStrict"
      >
      </cq-trigger-chain-reaction-editor>
    </ng-container>
    <ng-container *ngIf="step.type === 'action'">
      <cq-trigger-chain-action-editor
        [(step)]="step"
        (stepChange)="stepChangeSubj.next($event)"
        class="full-height"
      >
      </cq-trigger-chain-action-editor>
    </ng-container>
  </ng-container>
</overlay-scrollbars>
