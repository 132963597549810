import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasKey',
})
export class HasKeyPipe implements PipeTransform {
  transform(value: Record<string, unknown> | unknown[], key: string): boolean {
    return value && key in value;
  }
}
