import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { MessageEditorCollapseModule } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageScheduleSettingsComponent } from './message-schedule-settings.component';

@NgModule({
  declarations: [MessageScheduleSettingsComponent],
  imports: [CommonModule, TranslocoDirective, MessageEditorCollapseModule, ReactiveFormsModule, SharedModule],
  exports: [MessageScheduleSettingsComponent],
})
export class MessageScheduleSettingsModule {}
