/* eslint simple-import-sort/imports: Off */
// всё подключено именно в том порядке, в котором было подключено в старом индексном файле
require('../config/additional-scripts');
require('jquery');
require('moment');
import angular from 'angular';
import angularTranslate from 'angular-translate';
import angularUiBootstrap from 'angular-ui-bootstrap';
// entities
import './shared/entities/entities.module';
// models
import './shared/models/models.module';
// Ace editor
import 'brace/mode/javascript';
import 'brace/mode/python';
import 'brace/mode/html';
import 'brace/mode/json';
import 'brace/theme/monokai';
import 'brace/ext/searchbox';

// services
import './shared/services/services.module';
import './angular-downgraded-providers';
// models
import './shared/modals/modals.module';

require('vue/dist/vue');
require('../lib/vueConversationFrame/vueConversationFrame.umd');
const ngVue = require('ngVue');
require('accounting');
require('../lib/amcharts/amcharts.min');
require('../lib/amcharts/serial.min');
const ngAnimate = require('angular-animate');
const ngMessages = require('angular-messages');
const ngSanitize = require('angular-sanitize');
const angularClipboard = require('angular-clipboard');
const angularCookie = require('angular-cookie');
const angularFilter = require('angular-filter');
require('angular-hotkeys');
const angularLoadingBar = require('angular-loading-bar');
const angularToastr = require('angular-toastr');
require('messageformat');
require('angular-translate/dist/angular-translate-interpolation-messageformat/angular-translate-interpolation-messageformat');
require('angular-translate/dist/angular-translate-loader-partial/angular-translate-loader-partial');
const angularVsRepeat = require('angular-vs-repeat');
const uiRouter = require('@uirouter/angularjs');
const uiSelect = require('../lib/angular-ui/ui-select');
require('daterangepicker');
require('../lib/angular-daterangepicker/angular-daterangepicker');
require('chart.js');
require('jquery-colpick');
require('../lib/flag-converter/converter');
require('froala-editor');
require('froala-editor/js/languages/ru');
require('froala-editor/js/plugins/align.min');
require('froala-editor/js/plugins/code_view.min');
require('froala-editor/js/plugins/colors.min');
require('froala-editor/js/plugins/entities.min');
require('froala-editor/js/plugins/file.min');
require('froala-editor/js/plugins/font_size.min');
require('froala-editor/js/plugins/image.min');
require('froala-editor/js/plugins/line_breaker.min');
require('froala-editor/js/plugins/line_height.min');
require('froala-editor/js/plugins/link.min');
require('froala-editor/js/plugins/lists.min');
require('froala-editor/js/plugins/paragraph_format.min');
require('froala-editor/js/plugins/quick_insert.min');
require('froala-editor/js/plugins/table.min');
require('froala-editor/js/plugins/video.min');
require('angular-froala');
require('moment/locale/ru');
const angularMoment = require('angular-moment');
const ngFileUpload = require('ng-file-upload');
require('overlayscrollbars');
require('papaparse');
const angularjsSlider = require('angularjs-slider');
require('split.js');
require('urijs');
// directives
require('./shared/directives/directives.module');

// filters
require('./shared/filters/filters.module');

// components
require('./components/auto-messages/auto-messages.module');
require('./components/browser-frame/browser-frame.module');
require('./components/chat-bot/chat-bot.module');
require('./components/chat-settings/chat-settings.module');
require('./components/completion-registration/completion-registration.module');
require('./components/conversation-in-history/conversation-in-history.module');
require('./components/conversation-with-self/conversation-with-self.module');
require('./components/conversations/conversations.module');
require('./components/conversations-statistics/conversations-statistics.module');
require('./components/dashboard/dashboard.module');
require('./components/email-frame/email-frame.module');
require('./components/email-preview/email-preview.module');
require('./components/email-templates/email-templates.module');
require('./components/external-template-preview/external-template-preview.module');
require('./components/filter-segments/filter-segments.module');
require('./components/funnel-chart/funnel-chart.module');
require('./components/funnels/funnels.module');
require('./components/general/general.module');
require('./components/header/header.module');
require('./components/integrations/integrations.module');
require('./components/live/live.module');
require('./components/knowledge-base/knowledge-base.module');
require('./components/maintenance/maintenance.module');
require('./components/manual-messages/manual-messages.module');
require('./components/message-part-editor/message-part-editor.module');
require('./components/message-preview/message-preview.module');
require('./components/message-senders/message-senders.module');
require('./components/message-template/message-template.module');
require('./components/message-templates/message-templates.module');
require('./components/message-templates-popover/message-templates-popover.module');
require('./components/plan-capability/plan-capability.module');
require('./components/popup-editor/popup-editor.module');
require('./components/privacy-settings/privacy-settings.module');
require('./components/push-settings/push-settings.module');
require('./components/questions/questions.module');
require('./components/ready-message-templates/ready-message-templates.module');
require('./components/saved-bee-template/saved-bee-template.module');
require('./components/scheduled-messages/scheduled-messages.module.js');
require('./components/service-installation/service-installation.module');
require('./components/service-installation-for-developer/service-installation-for-developer.module');
require('./components/service-section/service-section.module');
require('./components/settings/settings.module');
require('./components/starter-guide/starter-guide.module');
require('./components/system-log-message/system-log-message.module');
require('./components/team-members/team-member.module');
require('./components/track-master/track-master.module');
require('./components/trigger-chains/trigger-chains.module');
require('./components/user-card/user-card.module');
require('./components/users/users.module');
require('./components/vote-preview/vote-preview.module');
require('./components/zero-data/zero-data.module');

angular.module('myApp', [
  angularClipboard.name,
  angularLoadingBar,
  angularFilter,
  angularMoment,
  'cfp.hotkeys',
  'daterangepicker',
  'froala',
  angularCookie,
  ngAnimate,
  ngFileUpload,
  ngMessages,
  ngSanitize,
  ngVue.ngVue.name,
  angularTranslate,
  angularjsSlider,
  angularToastr,
  angularUiBootstrap,
  uiRouter.default,
  'ui.router.upgrade',
  uiSelect,
  angularVsRepeat,
  'myApp.angularDowngradedProviders',
  'myApp.autoMessages',
  'myApp.browserFrame',
  'myApp.chatBot',
  'myApp.chatSettings',
  'myApp.completionRegistration',
  'myApp.config',
  'myApp.conversationInHistory',
  'myApp.conversationWithSelf',
  'myApp.conversations',
  'myApp.conversationsStatistics',
  'myApp.dashboard',
  'myApp.directives',
  'myApp.emailFrame',
  'myApp.emailPreview',
  'myApp.emailTemplates',
  'myApp.entities',
  'myApp.externalTemplatePreviewApp',
  'myApp.filters',
  'myApp.filterSegments',
  'myApp.funnelChart',
  'myApp.funnels',
  'myApp.general',
  'myApp.header',
  'myApp.integrations',
  'myApp.knowledgeBase',
  'myApp.live',
  'myApp.maintenance',
  'myApp.manualMessages',
  'myApp.messagePartEditor',
  'myApp.messagePreview',
  'myApp.messageSenders',
  'myApp.messageTemplate',
  'myApp.messageTemplates',
  'myApp.messageTemplatesPopover',
  'myApp.modals',
  'myApp.models',
  'myApp.planCapability',
  'myApp.popupEditor',
  'myApp.privacySettings',
  'myApp.pushSettings',
  'myApp.questions',
  'myApp.readyMessageTemplates',
  'myApp.savedBeeTemplate',
  'myApp.scheduledMessages',
  'myApp.serviceInstallation',
  'myApp.serviceInstallationForDeveloper',
  'myApp.services',
  'myApp.serviceSection',
  'myApp.settings',
  'myApp.starterGuide',
  'myApp.systemLogMessage',
  'myApp.teamMembers',
  'myApp.trackMaster',
  'myApp.triggerChains',
  'myApp.userCard',
  'myApp.users',
  'myApp.votePreview',
  'myApp.zeroData',
]);

require('./app.constants');
require('./app.config');
require('./app.routes');
