import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import orderBy from 'lodash-es/orderBy';
import { firstValueFrom } from 'rxjs';

import { App, AppModel } from '@http/app/app.model';
import { AddAppModalComponent } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal.component';
import { ModalHelperService } from '@panel/app/services';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent {
  /** список приложений текущего пользователя */
  @Input() appList!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;

  /** фильтр поиска по имени приложения */
  protected appSearchString: string = '';
  /** Является ли пользователь админом */
  protected isWebApp: any = this.utilsService.webApp;

  get filteredApps(): App[] {
    return orderBy(
      this.appList.filter((app: any) => app.name.toLowerCase().includes(this.appSearchString.toLowerCase())),
      [
        (app) => /^\d/.test(app.name), // Сначала сортируем по признаку начала с цифры
        (app) => app.name.toLowerCase(), // Затем сортируем по имени
      ],
      ['desc', 'asc'], // Порядок сортировки: сначала цифры (по убыванию, чтобы они были в начале), затем буквы (по возрастанию)
    );
  }

  constructor(
    protected readonly appModel: AppModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
    protected readonly utilsService: UtilsService,
    protected readonly modalHelperService: ModalHelperService,
  ) {}

  /**
   * Открытие приложения по его ID
   *
   * @param appId ID приложения
   */
  openApp(appId: any): void {
    this.uiRouter.stateService.go('app', { appId: appId });
  }

  /**
   * Создание нового приложения
   *
   * @param closable Можно ли закрыть модалку для создания приложения
   */
  openCreateAppModal(closable: any): void {
    let backdrop: any = true;

    if (closable !== undefined && !closable) {
      backdrop = 'static';
    }

    let modal = this.modalHelperService.open(AddAppModalComponent, {
      backdrop: backdrop,
      size: 'lg',
    });

    modal.result
      .then((appId: any) => firstValueFrom(this.appModel.get(appId)))
      .then((app: any) => {
        this.openApp(app.id);
      })
      .catch(() => {});
  }

  /**
   * Открытие модалки о превышении количества добавленных сайтов
   */
  openTooMuchAppsModal(): void {
    let modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('account.apps.tooMuchAppsModal.heading'),
        body: this.translocoService.translate('account.apps.tooMuchAppsModal.body'),
        confirmButtonText: this.translocoService.translate('account.apps.tooMuchAppsModal.confirmButtonText'),
        cancelButtonClass: 'hidden',
      })
      .open(ConfirmModalComponent);

    modal.result
      .then(() => {
        this.carrotquestHelper.sendChatMessage(
          this.translocoService.translate('account.apps.tooMuchAppsModal.chatMessage'),
        );
      })
      .catch(() => {});
  }

  /**
   * Трек клика на строку поиска приложения
   */
  trackClickOnAppSearch(): void {
    this.carrotquestHelper.track('Аккаунт - Мои сайты - клик на поиск');
  }

  trackByFn(index: number) {
    return index;
  }
}
