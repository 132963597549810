import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

const $ = require('jquery');

const icons = [
  { froalaIcon: 'align', cqiSmIcon: 'align-left' },
  { froalaIcon: 'align-center', cqiSmIcon: 'align-center' },
  { froalaIcon: 'align-justify', cqiSmIcon: 'align-justify' },
  { froalaIcon: 'align-left', cqiSmIcon: 'align-left' },
  { froalaIcon: 'align-right', cqiSmIcon: 'align-right' },
  { froalaIcon: 'bold', cqiSmIcon: 'bold' },
  { froalaIcon: 'changeEditorColor', cqiSmIcon: 'adjust' },
  { froalaIcon: 'clearFormatting', cqiSmIcon: 'eraser' },
  { froalaIcon: 'color', cqiSmIcon: 'tint' },
  { froalaIcon: 'colors', cqiSmIcon: 'tint' },
  { froalaIcon: 'colorsBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'fileBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'fontSize', cqiSmIcon: 'text-height' },
  { froalaIcon: 'formatOL', cqiSmIcon: 'list-ol' },
  { froalaIcon: 'formatUL', cqiSmIcon: 'list-ul' },
  { froalaIcon: 'html', cqiSmIcon: 'code' },
  { froalaIcon: 'image-align', cqiSmIcon: 'align-left' },
  { froalaIcon: 'image-align-center', cqiSmIcon: 'align-justify' },
  { froalaIcon: 'image-align-left', cqiSmIcon: 'align-left' },
  { froalaIcon: 'image-align-right', cqiSmIcon: 'align-right' },
  { froalaIcon: 'imageAlign', cqiSmIcon: 'align-justify' },
  { froalaIcon: 'imageAlt', cqiSmIcon: 'info' },
  { froalaIcon: 'imageBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'imageByURL', cqiSmIcon: 'link' },
  { froalaIcon: 'imageCaption', cqiSmIcon: 'comment-out' },
  { froalaIcon: 'imageDisplay', cqiSmIcon: 'star' },
  { froalaIcon: 'imageLink', cqiSmIcon: 'link' },
  { froalaIcon: 'imageRemove', cqiSmIcon: 'trash' },
  { froalaIcon: 'imageReplace', cqiSmIcon: 'exchange-alt' },
  { froalaIcon: 'imageSize', cqiSmIcon: 'arrows-alt' },
  { froalaIcon: 'imageStyle', cqiSmIcon: 'magic' },
  { froalaIcon: 'imageUpload', cqiSmIcon: 'upload' },
  { froalaIcon: 'indent', cqiSmIcon: 'indent-right' },
  { froalaIcon: 'insert_button_icon', cqiSmIcon: 'hand-pointer' },
  { froalaIcon: 'insert_user_prop', cqiSmIcon: 'user-o' },
  { froalaIcon: 'insertCode', cqiSmIcon: 'braces' },
  { froalaIcon: 'insertFile', cqiSmIcon: 'page' },
  { froalaIcon: 'insertHR', cqiSmIcon: 'minus' },
  { froalaIcon: 'insertImage', cqiSmIcon: 'image' },
  { froalaIcon: 'insertLink', cqiSmIcon: 'link' },
  { froalaIcon: 'insertPre', cqiSmIcon: 'code' },
  { froalaIcon: 'insertTable', cqiSmIcon: 'table' },
  { froalaIcon: 'insertVideo', cqiSmIcon: 'film' },
  { froalaIcon: 'italic', cqiSmIcon: 'italic' },
  { froalaIcon: 'lineHeight', cqiSmIcon: 'arrows-alt-v' },
  { froalaIcon: 'linkBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'linkEdit', cqiSmIcon: 'pencil' },
  { froalaIcon: 'linkList', cqiSmIcon: 'search' },
  { froalaIcon: 'linkOpen', cqiSmIcon: 'external-link' },
  { froalaIcon: 'linkRemove', cqiSmIcon: 'unlink' },
  { froalaIcon: 'linkStyle', cqiSmIcon: 'magic' },
  { froalaIcon: 'outdent', cqiSmIcon: 'indent-left' },
  { froalaIcon: 'paragraphFormat', cqiSmIcon: 'paragraph' },
  { froalaIcon: 'quickInsert', cqiSmIcon: 'plus' },
  { froalaIcon: 'redo', cqiSmIcon: 'redo' },
  { froalaIcon: 'remove', cqiSmIcon: 'eraser' },
  { froalaIcon: 'selectAll', cqiSmIcon: 'mouse-pointer' },
  { froalaIcon: 'strikeThrough', cqiSmIcon: 'strikethrough' },
  { froalaIcon: 'subscript', cqiSmIcon: 'subscript' },
  { froalaIcon: 'superscript', cqiSmIcon: 'superscript' },
  { froalaIcon: 'tableBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'tableCellHorizontalAlign', cqiSmIcon: 'align-left' },
  { froalaIcon: 'tableCells', cqiSmIcon: 'square-o' },
  { froalaIcon: 'tableCellStyle', cqiSmIcon: 'magic' },
  { froalaIcon: 'tableCellVerticalAlign', cqiSmIcon: 'arrows-alt-v' },
  { froalaIcon: 'tableColorRemove', cqiSmIcon: 'eraser' },
  { froalaIcon: 'tableColumns', cqiSmIcon: 'bars-v' },
  { froalaIcon: 'tableHeader', cqiSmIcon: 'heading' },
  { froalaIcon: 'tableRows', cqiSmIcon: 'bars' },
  { froalaIcon: 'tableStyle', cqiSmIcon: 'paint-brush' },
  { froalaIcon: 'underline', cqiSmIcon: 'underline' },
  { froalaIcon: 'undo', cqiSmIcon: 'undo' },
  { froalaIcon: 'video-align', cqiSmIcon: 'align-left' },
  { froalaIcon: 'video-align-center', cqiSmIcon: 'align-justify' },
  { froalaIcon: 'video-align-left', cqiSmIcon: 'align-left' },
  { froalaIcon: 'video-align-right', cqiSmIcon: 'align-right' },
  { froalaIcon: 'videoAlign', cqiSmIcon: 'align-center' },
  { froalaIcon: 'videoBack', cqiSmIcon: 'arrow-left' },
  { froalaIcon: 'videoByURL', cqiSmIcon: 'link' },
  { froalaIcon: 'videoDisplay', cqiSmIcon: 'star' },
  { froalaIcon: 'videoEmbed', cqiSmIcon: 'code' },
  { froalaIcon: 'videoRemove', cqiSmIcon: 'trash' },
  { froalaIcon: 'videoReplace', cqiSmIcon: 'exchange-alt' },
  { froalaIcon: 'videoSize', cqiSmIcon: 'arrows-alt' },
  { froalaIcon: 'videoUpload', cqiSmIcon: 'upload' },
];

@Injectable({ providedIn: 'root' })
export class FroalaHelperService {
  constructor(private translocoService: TranslocoService) {}

  /**
   * Автофокусировка на редакторе
   * HACK: флаг autofocus почему-то не работает, возможно, froala старая, либо это angular-froala глючит. Поэтому пришлось делать свою функцию автофокуса
   *  Кейс предполагает использовать эту функцию на событие 'froalaEditor.initialized'
   *
   * @param event
   * @param editor
   */
  autoFocusOnEvent(event: any, editor: any): any {
    // таймаут планирует выполнение функции в следующую итерацию digest-цикла, чтобы html успел отрендериться
    setTimeout(() => {
      editor.events.focus();
    });
  }

  /**
   * Обработка ошибок загрузки файлов
   *
   * @param {Event} e Вызванное событие
   * @param {Object} editor Инстанс редактора
   * @param {Object} error Ошибка, содержащая в себе код и сообщение
   * @param {Object} response Ответ с сервера
   */
  handleFileUploadError(error: any, editor: any): any {
    var customErrorMessage = '';

    switch (error.code) {
      case 5:
        customErrorMessage = this.translocoService.translate('services.froalaHelper.fileUpload.errors.fileMaxSize', {
          fileMaxSize: editor.opts.fileMaxSize / (1024 * 1024),
        });
        break;
      case 6:
        customErrorMessage = this.translocoService.translate(
          'services.froalaHelper.fileUpload.errors.fileAllowedTypes',
        );
        break;
    }

    if (customErrorMessage) {
      // да, это не говнокод, а официальный способ подставить своё кастомное сообщение об ошибке https://github.com/froala/wysiwyg-editor/issues/1358#issuecomment-228046725
      var popup = editor.popups.get('file.insert');
      var layer = popup.find('.fr-file-progress-bar-layer');
      layer.find('h3').text(customErrorMessage);
    }
  }

  /**
   * Обработка ошибок загрузки картинок
   * Всё сделано по этому мануалу https://www.froala.com/wysiwyg-editor/docs/events#image.error
   *
   * @param {Event} e Вызванное событие
   * @param {Object} editor Инстанс редактора
   * @param {Object} error Ошибка, содержащая в себе код и сообщение
   */
  handleImageUploadError(error: any, editor: any): any {
    var customErrorMessage = '';

    switch (error.code) {
      case 5:
        customErrorMessage = this.translocoService.translate('services.froalaHelper.imageUpload.errors.imageMaxSize', {
          imageMaxSize: editor.opts.imageMaxSize / (1024 * 1024),
        });
        break;
      case 6:
        customErrorMessage = this.translocoService.translate(
          'services.froalaHelper.imageUpload.errors.imageAllowedTypes',
          {
            imageAllowedTypes: editor.opts.imageAllowedTypes.join(', '),
          },
        );
        break;
    }

    if (customErrorMessage) {
      //this.toastr.danger(customErrorMessage);
    }
  }

  /**
   * Настройка froala, добавление новых методов, кнопок и т.д.
   */
  setup(): any {
    let translocoService = this.translocoService;

    $.FroalaEditor.DEFAULTS.quickInsertEnabled = false;
    $.FroalaEditor.DEFAULTS.quickInsertTags = ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote'];

    // HACK: в текущей версии фроалы (2.9.1) нету перевода для высоты строки, поэтому нужно задать его принудительно для русского языка
    $.FroalaEditor.LANGUAGE['ru'].translation['Line Height'] = translocoService.translate(
      'services.froalaHelper.lineHeightButton',
    );

    $.FroalaEditor.DefineIconTemplate('cqi-sm', '<i class="cqi-sm cqi-[NAME]"></i>');
    $.FroalaEditor.ICON_DEFAULT_TEMPLATE = 'cqi-sm';

    icons.forEach((icon: any) => {
      $.FroalaEditor.DefineIcon(icon.froalaIcon, { NAME: icon.cqiSmIcon });
    });

    $.FroalaEditor.RegisterCommand('insert_user_prop', {
      title: this.translocoService.translate('services.froalaHelper.insertUserProp.title'),
      type: 'dropdown',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      options: {
        name: translocoService.translate('services.froalaHelper.insertUserProp.options.name'),
        email: translocoService.translate('services.froalaHelper.insertUserProp.options.email'),
        phone: translocoService.translate('services.froalaHelper.insertUserProp.options.phone'),
      },
      callback: function (cmd: any, val: any) {
        if (val == 'name') {
          this.html.insert("{{ user['$name'] }}", true);
        } else if (val == 'email') {
          this.html.insert("{{ user['$email'] }}", true);
        } else if (val == 'phone') {
          this.html.insert("{{ user['$phone'] }}", true);
        }
      },
    });

    $.FroalaEditor.RegisterCommand('insert_button', {
      title: this.translocoService.translate('services.froalaHelper.insertButton.title'),
      icon: 'insert_button_icon',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      options: {},
      callback: function () {
        this.html.insert(
          '<a href="https://example.com" class="carrotquest-messenger-button">' +
            translocoService.translate('services.froalaHelper.insertButton.callback.link.text') +
            '</a>',
          true,
        );
      },
    });

    $.FroalaEditor.RegisterCommand('changeEditorColor', {
      title: translocoService.translate('services.froalaHelper.changeEditorColor.title'),
      icon: 'changeEditorColor',
      toggle: true,
      callback: function () {
        var $btn = this.$tb.find('.fr-command[data-cmd="changeEditorColor"]');

        if (this.$el.hasClass('dark-background')) {
          $btn.removeClass('fr-active').attr('aria-pressed', false);
          this.$el.removeClass('dark-background');
        } else {
          $btn.addClass('fr-active').attr('aria-pressed', true);
          this.$el.addClass('dark-background');
        }
      },
    });

    $.FroalaEditor.RegisterCommand('insertCode', {
      title: translocoService.translate('services.froalaHelper.insertCode.title'),
      icon: 'insertCode',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      options: {},
      callback: function () {
        var htmlCleanedSelection = this.html.getSelected().replace(/<(?:.|\n)*?>/gm, ' '); // HACK нужно использовать нативный метод froala для очистки тегов, но у меня не удалось заставить его работать

        // Для удобства выделения по двойному клику проверяем есть ли в конце пробел и переносим его за пределы тега
        var insertString =
          htmlCleanedSelection.slice(-1) === ' '
            ? '<code>' + htmlCleanedSelection.slice(0, -1) + '</code> '
            : '<code>' + htmlCleanedSelection + '</code>';

        this.html.insert(insertString, true);
      },
    });

    $.FroalaEditor.RegisterQuickInsertButton('insertPre', {
      title: translocoService.translate('services.froalaHelper.insertPre.title'),
      icon: 'insertPre',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      options: {},
      callback: function () {
        //@ts-ignore
        this.html.insert('<pre></pre><p></p>', true);
      },
    });

    $.FroalaEditor.VIDEO_PROVIDERS.push(
      {
        test_regex: /^https?:\/\/vk\.com\/video_ext\.php\?oid=(-?\d+)&id=(\d+).*$/,
        url_regex: /https?:\/\/vk\.com\/video_ext\.php\?oid=(-?\d+)&id=(\d+)(.*)$/,
        url_text: 'https://vk.com/video_ext.php?oid=$1&id=$2$3',
        html: '<iframe width="640" height="360" src="{url}" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>',
        provider: 'vk',
      },
      {
        test_regex: /^https?:\/\/vk\.com\/video(?:\?\z=video)?(-?\d+)_(\d+).*$/,
        url_regex: /https?:\/\/vk\.com\/video(?:\?\z=video)?(-?\d+)_(\d+).*$/,
        url_text: 'https://vk.com/video_ext.php?oid=$1&id=$2',
        html: '<iframe width="640" height="360" src="{url}" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>',
        provider: 'vk',
      },
    );
  }
}
