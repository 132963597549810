import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { ConversationChannelModule } from '@panel/app/pages/conversations/conversation-channel/conversation-channel.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AssignNewSuperAdminModalComponent } from './assign-new-super-admin-modal.component';

@NgModule({
  declarations: [AssignNewSuperAdminModalComponent],
  exports: [AssignNewSuperAdminModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    SharedModule,
    ConversationChannelModule,
    FormsModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class AssignNewSuperAdminModalModule {}
