import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Quill from 'quill';

import { EditorService } from '@panel/app/partials/editor/service/editor.service';

import { EDITOR_FORMAT_SIZES } from './editor-format-size.constant';

@Component({
  selector: 'cq-editor-format-size',
  templateUrl: './editor-format-size.component.html',
  styleUrls: ['./editor-format-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFormatSizeComponent {
  @Input({ required: true })
  public editor!: Quill;

  protected readonly EDITOR_FORMAT_SIZES = EDITOR_FORMAT_SIZES;

  constructor(private editorService: EditorService) {}

  protected onClickSetSizeButton(size: number | boolean): void {
    this.formatSize(size);
  }

  private formatSize(size: number | boolean): void {
    this.editorService.formatSize(this.editor, size);
  }
}
