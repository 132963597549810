import { Container } from 'pixi.js';

import { getActionsBackgroundRect } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { renderCanvasHTMLText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH } from './abstract';

export class BotTextAction extends BaseActionABS<EMPTY_BODY_JSON> {
  protected style!: ActionStyle;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);

    const textWithBg = this.render();
    if (textWithBg) {
      this.container.addChild(textWithBg);
    }
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 7,
        horizontal: 10,
      },
      border: {
        size: 0,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: 0xf1f2f4,
      },
    };
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  redraw() {
    if (this.destroyed) {
      return;
    }
    this.container.removeChildren();
    const textWithBg = this.render();
    this.container.addChild(textWithBg);
  }

  render(): Container {
    const padding = this.style.padding.horizontal * 2;
    const fontSize = 14;
    const textOptions = {
      fontSize,
      lineHeight: fontSize * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - padding,
    };

    const value = this.getTransformPropsText(this.form.controls.body.value);
    const text = renderCanvasHTMLText(value ?? '', textOptions);
    const bg = getActionsBackgroundRect(
      text.height + this.style.padding.vertical * 2,
      this.style.background.color,
      this.pixiApp.renderer,
    );
    bg.zIndex = 1;
    bg.addChild(text);
    text.position.set(this.style.padding.horizontal, this.style.padding.vertical);
    return bg;
  }

  get height(): number {
    return this.container.height;
  }

  get width(): number {
    return this.container.width;
  }

  get renderReady(): boolean {
    return this.container.renderable && this.container.children.length > 0;
  }

  getTransformPropsText(value: string | null) {
    if (value?.includes('data-default-value')) {
      return value.replace(/<span(.*?)data-default-value="(.*?)"(.*?)>(.*?)<\/span>/gi, (match, s1, s2, s3, s4) => {
        return `<span${s1}data-default-value="${s4}"${s3}>${s2}</span>`;
      });
    }
    return value;
  }
}
