import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@environment';
import { CloudPayments } from '@panel/app/pages/subscription/general/services/cloud-payments/cloud-payments';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

/** Сервис для работы с платёжным сервисом CloudPayments */
@Injectable({
  providedIn: 'root',
})
export class CloudPaymentsService {
  constructor(private readonly cookieService: CookieService, private readonly l10nHelper: L10nHelperService) {}

  /** Инициализация виджета CloudPayments */
  init(): CloudPayments {
    const language = this.l10nHelper.isUsCountry() ? 'en-US' : 'ru-RU';

    return new cp.CloudPayments({
      googlePaySupport: false,
      language: language,
    });
  }

  /**
   * Проведение двухстадийной оплаты
   * TODO Написать на человеческом языке что делает этот метод
   *
   * @param cloudPayments Инстанс CloudPayments
   * @param description Описание назначения оплаты
   * @param amount Сумма оплаты
   * @param invoiceId ID счёта
   * @param djangoUserEmail Email
   * @param onSuccess Функция, которая выполнится после успешного завершения оплаты и закрытия виджета
   * @param onFail Функция, которая выполнится после неуспешного завершения оплаты и закрытия виджета
   */
  auth(
    cloudPayments: CloudPayments,
    description: string,
    amount: number,
    invoiceId: string,
    djangoUserEmail: string,
    onSuccess: (options: any) => any = () => {},
    onFail: (reason: string, options: any) => any = this.defaultOnFailCallback,
  ): void {
    cloudPayments.auth(
      {
        publicId: environment.cloudPaymentsPublicId,
        description,
        amount,
        currency: environment.currency.currency,
        invoiceId,
        accountId: djangoUserEmail,
        data: {
          auth_token: this.cookieService.get('carrotquest_auth_token_panel'),
        },
        skin: 'mini',
      },
      onSuccess,
      onFail,
    );
  }

  /**
   * Проведение одностадийной оплаты
   * TODO Написать на человеческом языке что делает этот метод
   *
   * @param cloudPayments Инстанс CloudPayments
   * @param description Описание назначения оплаты
   * @param amount Сумма оплаты
   * @param invoiceId ID счёта
   * @param djangoUserEmail Email
   * @param onSuccess Функция, которая выполнится после успешного завершения оплаты и закрытия виджета
   * @param onFail Функция, которая выполнится после неуспешного завершения оплаты и закрытия виджета
   */
  charge(
    cloudPayments: CloudPayments,
    description: string,
    amount: number,
    invoiceId: string,
    djangoUserEmail: string,
    onSuccess: (options: any) => any,
    onFail: (reason: string, options: any) => any = this.defaultOnFailCallback,
  ): void {
    cloudPayments.charge(
      {
        publicId: environment.cloudPaymentsPublicId,
        description,
        amount,
        currency: environment.currency.currency,
        invoiceId,
        accountId: djangoUserEmail,
        skin: 'mini',
      },
      onSuccess,
      onFail,
    );
  }

  private defaultOnFailCallback(reason: string, options: any): void {
    console.log(reason);
  }
}
