export type SavedReply = {
  app: string;
  body: string;
  bodyHeadline?: string; // Приходит только при поиске
  header: string | null;
  headerHeadline?: string | null; // Приходит только при поиске
  id: string;
  order: number;
  accessType: SAVED_REPLY_ACCESS_TYPE;
};

export enum SAVED_REPLY_ACCESS_TYPE {
  SHARED,
  PERSONAL,
}

export type ApiGetSavedRepliesListResponse = {
  count: number;
  items: SavedReply[];
};
