import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgMultiLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { ConversationOperatorModule } from '@panel/app/pages/conversations/conversation-operator/conversation-operator.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AssignmentTypeControlComponent } from './assignment-type-control/assignment-type-control.component';
import { AutoAssignmentComponent } from './auto-assignment.component';
import { EnablingFeatureControlComponent } from './enabling-feature-control/enabling-feature-control.component';
import { TeamMembersSelectControlComponent } from './team-members-select-control/team-members-select-control.component';

@NgModule({
  declarations: [
    AutoAssignmentComponent,
    EnablingFeatureControlComponent,
    TeamMembersSelectControlComponent,
    AssignmentTypeControlComponent,
  ],
  imports: [
    CommonModule,
    TranslocoDirective,
    ReactiveFormsModule,
    SharedModule,
    NgOptimizedImage,
    ConversationOperatorModule,
    FormsModule,
    NgSelectComponent,
    NgMultiLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
  exports: [AutoAssignmentComponent],
})
export class AutoAssignmentModule {}
