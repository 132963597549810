<ng-container *transloco="let t; read: 'editSavedReplyModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ isEdit ? t('editSavedReplyHeading') : t('createSavedReplyHeading') }}
    </h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <form
      autocomplete="off"
      novalidate
      (ngSubmit)="createOrSave()"
    >
      <!--ЗАГОЛОВОК-->
      <div
        class="form-group margin-bottom-20"
        [cqShowError]="savedReplyForm.controls.header"
      >
        <label class="mb-10">{{ t('savedReplyForm.headerInput.label') }}</label>
        <input
          class="form-control"
          name="header"
          [formControl]="savedReplyForm.controls.header"
          [placeholder]="t('savedReplyForm.headerInput.placeholder')"
        />
        <cq-validation-messages [control]="savedReplyForm.controls.header">
          <cq-validation-message errorName="maxlength">
            {{
              t('savedReplyForm.headerInput.errors.maxlength', {
                maxLength: MAX_HEADER_LENGTH,
              })
            }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <!--ТЕКСТ-->
      <div
        class="form-control-container"
        [cqShowError]="savedReplyForm.controls.body"
      >
        <label class="mb-10">{{ t('savedReplyForm.bodyInput.label') }}</label>
        <textarea
          class="form-control full-max-width no-resize"
          name="body"
          rows="7"
          [formControl]="savedReplyForm.controls.body"
          [placeholder]="t('savedReplyForm.bodyInput.placeholder')"
        ></textarea>
        <cq-validation-messages [control]="savedReplyForm.controls.body">
          <cq-validation-message errorName="required">
            {{ t('savedReplyForm.bodyInput.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{ t('savedReplyForm.bodyInput.errors.maxlength', { maxLength: MAX_BODY_LENGTH }) }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <!--КОМУ ДОСТУПЕН ОТВЕТ-->
      <div
        *ngIf="!isEdit"
        class="mt-20"
      >
        <label class="mb-10">{{ t('savedReplyForm.replyType.label') }}</label>
        <div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              id="radio-shared"
              type="radio"
              [value]="SAVED_REPLY_ACCESS_TYPE.PERSONAL"
              [formControl]="savedReplyForm.controls.accessType"
            />
            <label
              class="custom-control-label"
              for="radio-shared"
              >{{ t('savedReplyForm.replyType.values.personal') }}</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              id="radio-personal"
              type="radio"
              [value]="SAVED_REPLY_ACCESS_TYPE.SHARED"
              [formControl]="savedReplyForm.controls.accessType"
            />
            <label
              class="custom-control-label"
              for="radio-personal"
              >{{ t('savedReplyForm.replyType.values.shared') }}</label
            >
          </div>
        </div>
      </div>

      <div
        *ngIf="!isEdit"
        class="form-group margin-top-20"
      >
        <label class="mb-10">{{ t('savedReplyForm.addToTheEndSelect.label') }}</label>
        <ng-select
          name="addToTheEnd"
          [items]="ADD_TO_THE_END_OPTIONS"
          [searchable]="false"
          [clearable]="false"
          [(ngModel)]="addToTheEnd"
        >
          <ng-template
            ng-label-tmp
            ng-option-tmp
            let-item="item"
          >
            <span
              [innerHTML]="t('savedReplyForm.addToTheEndSelect.values.' + item, { hotkey: getHotkeyText() })"
            ></span>
          </ng-template>
        </ng-select>
      </div>
      <input
        id="form.submit"
        class="hidden"
        type="submit"
      />
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="form.submit"
      type="button"
      >{{ isEdit ? ('general.save' | transloco) : t('createButton') }}</label
    >
  </div>
</ng-container>
