<ng-container *transloco="let t; read: 'addCrawledSitesModalComponent'">
  <div class="modal-header pb-0">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <ng-container
    *ngIf="newPages.length === 0 && existingPages.length === 0 && (isPagesLoading$ | async); else modalContent"
  >
    <div class="modal-body">
      <cq-loader class="d-block position-relative py-30"></cq-loader>
    </div>
  </ng-container>

  <ng-template #modalContent>
    <div class="modal-body pt-15">
      <div
        class="mb-15"
        [innerHTML]="
            t('resultHeading', {
              url: crawledUrl,
              totalUrls,
            })
          "
      ></div>
      <ng-container *ngIf="newPages.length > 0 && existingPages.length > 0">
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav nav-tabs-light"
        >
          <li ngbNavItem>
            <a ngbNavLink>{{ t('tabTitles.newPages') }}</a>
            <ng-template ngbNavContent>
              <div class="custom-control custom-checkbox mb-25">
                <input
                  class="custom-control-input"
                  id="selectAll"
                  type="checkbox"
                  [(ngModel)]="selectAllPagesCheckbox"
                  (change)="onSelectAllPagesCheckboxChange()"
                />
                <label
                  class="custom-control-label"
                  for="selectAll"
                >
                  {{ t('selectAllPagesCheckbox') }}
                </label>
              </div>
              <div class="margin-between-rows-15">
                <ng-container *ngFor="let page of newPages; let i = index">
                  <ng-template
                    [ngTemplateOutlet]="pageTemplateWithCheckbox"
                    [ngTemplateOutletContext]="{ page: page, index: i }"
                  ></ng-template>
                </ng-container>
              </div>
              <ng-container *ngIf="canLoadMoreNewPages">
                <ng-template
                  [ngTemplateOutlet]="loadMoreButtonTemplate"
                  [ngTemplateOutletContext]="{ clickFn: loadMoreNewPages.bind(this) }"
                ></ng-template>
              </ng-container>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>{{ t('tabTitles.existingPages') }}</a>
            <ng-template ngbNavContent>
              <div class="margin-between-rows-15">
                <ng-container *ngFor="let page of existingPages; let i = index">
                  <ng-template
                    [ngTemplateOutlet]="pageTemplate"
                    [ngTemplateOutletContext]="{ url: page.url }"
                  ></ng-template>
                </ng-container>
              </div>
              <ng-container *ngIf="canLoadMoreExistingPages">
                <ng-template
                  [ngTemplateOutlet]="loadMoreButtonTemplate"
                  [ngTemplateOutletContext]="{ clickFn: loadMoreExistingPages.bind(this) }"
                ></ng-template>
              </ng-container>
            </ng-template>
          </li>
        </ul>
        <div
          [ngbNavOutlet]="nav"
          class="mt-20"
        ></div>
      </ng-container>
      <ng-container *ngIf="newPages.length > 0 && existingPages.length === 0">
        <hr />
        <div class="custom-control custom-checkbox mb-25">
          <input
            class="custom-control-input"
            id="selectAll"
            type="checkbox"
            [(ngModel)]="selectAllPagesCheckbox"
            (change)="onSelectAllPagesCheckboxChange()"
          />
          <label
            class="custom-control-label"
            for="selectAll"
          >
            {{ t('selectAllPagesCheckbox') }}
          </label>
        </div>
        <div class="margin-between-rows-15">
          <ng-container *ngFor="let page of newPages; let i = index">
            <ng-template
              [ngTemplateOutlet]="pageTemplateWithCheckbox"
              [ngTemplateOutletContext]="{ page: page, index: i }"
            ></ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="canLoadMoreNewPages">
          <ng-template
            [ngTemplateOutlet]="loadMoreButtonTemplate"
            [ngTemplateOutletContext]="{ clickFn: loadMoreNewPages.bind(this) }"
          ></ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="newPages.length === 0 && existingPages.length > 0">
        <hr />
        <div class="margin-between-rows-15">
          <ng-container *ngFor="let page of existingPages; let i = index">
            <ng-template
              [ngTemplateOutlet]="pageTemplate"
              [ngTemplateOutletContext]="{ url: page.url }"
            ></ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="canLoadMoreExistingPages">
          <ng-template
            [ngTemplateOutlet]="loadMoreButtonTemplate"
            [ngTemplateOutletContext]="{ clickFn: loadMoreExistingPages.bind(this) }"
          ></ng-template>
        </ng-container>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="activeModal.dismiss()"
      >
        {{ 'general.cancel' | transloco }}
      </button>
      <ng-container *ngIf="newPages.length > 0 && existingPages.length > 0">
        <ng-container *ngIf="hasCheckedPages()">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.ADD_NEW_AND_UPDATE_EXISTING)"
            [disabled]="isRequestPerformed$ | async"
          >
            {{ t('buttons.addNewAndUpdateExistingPages') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.ADD_NEW)"
            [disabled]="isRequestPerformed$ | async"
          >
            {{ t('buttons.onlyAddNewPages') }}
          </button>
        </ng-container>
        <ng-container *ngIf="!hasCheckedPages()">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.UPDATE_EXISTING)"
            [disabled]="isRequestPerformed$ | async"
          >
            {{ t('buttons.onlyUpdateExistingPages') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.DO_NOT_ADD)"
          >
            {{ t('buttons.doNotAddAnyPages') }}
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="newPages.length > 0 && existingPages.length === 0">
        <ng-container *ngIf="hasCheckedPages()">
          <button
            class="btn btn-primary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.ADD_NEW)"
            [disabled]="isRequestPerformed$ | async"
          >
            {{ t('buttons.addNewPages', { pagesCount: totalCheckedPagesNumber }) }}
          </button>
        </ng-container>
        <ng-container *ngIf="!hasCheckedPages()">
          <button
            class="btn btn-primary"
            type="button"
            (click)="processPages(PAGES_ACTIONS.DO_NOT_ADD)"
          >
            {{ t('buttons.doNotAddAnyPages') }}
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="newPages.length === 0 && existingPages.length > 0">
        <button
          class="btn btn-primary"
          type="button"
          (click)="processPages(PAGES_ACTIONS.UPDATE_EXISTING)"
          [disabled]="isRequestPerformed$ | async"
        >
          {{ t('buttons.updateExistingPages', { pagesCount: totalUrls }) }}
        </button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template
    #pageTemplate
    let-url="url"
  >
    <div class="text-break">{{ url }}</div>
  </ng-template>

  <ng-template
    #pageTemplateWithCheckbox
    let-page="page"
    let-i="index"
  >
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        [id]="i"
        type="checkbox"
        [(ngModel)]="page.checked"
        [disabled]="selectAllPagesCheckbox"
      />
      <label
        class="custom-control-label"
        [for]="i"
      >
        <ng-template
          [ngTemplateOutlet]="pageTemplate"
          [ngTemplateOutletContext]="{ url: page.url }"
        ></ng-template>
      </label>
    </div>
  </ng-template>

  <ng-template
    #loadMoreButtonTemplate
    let-clickFn="clickFn"
  >
    <button
      class="btn btn-secondary w-100 mt-20"
      type="button"
      (click)="clickFn()"
      [disabled]="isPagesLoading$ | async"
    >
      {{ t('loadMoreButton') }}
    </button>
  </ng-template>
</ng-container>
