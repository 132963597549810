import moment from 'moment';

import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { MESSAGE_PART_TYPES, RECIPIENT_TYPES } from '@http/message-part/message-part.constants';
import { MessagePart } from '@http/message-part/message-part.types';
import { ExternalFiltersAJS, FiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

import { AutoMessageTriggerType, AutoMessageTriggerTypeExternal } from './trigger.types';

/**
 * Тип автосообщения
 */
export type Message = {
  /** Флаг активности */
  active?: boolean;
  activeTestGroup?: {
    closed: null;
    created: moment.Moment;
    id?: string;
    message: string;
    name?: string;
    parts?: object[]; //TODO ANGULAR_TS Описать парты
  };
  afterDelay?: number;
  afterTimeMaxValue?: number;
  afterTimeTimeUnit?: string; //TODO ANGULAR_TS TIME_UNITS
  afterTimeTimeUnits: TIME_UNITS[]; //TODO ANGULAR_TS TIME_UNITS
  isAfterTime?: boolean;
  afterTimeValue?: number;
  /** ID приложения, в котором находится триггерное сообщение */
  app?: string;
  body?: string | LeadBot['id'];
  bodyJson?: string;
  bot?: LeadBot;
  /** Дата создания */
  checked?: boolean;
  controlGroup?: {
    //TODO ANGULAR_TS описать парты
    body: string;
    bodyJson: string;
    closed: null;
    created: string;
    id: string;
    lastSent: object | null;
    msender: object | null;
    muser: object | null;
    name: string;
    proportion: number;
    pureHtml: boolean;
    recipientType: string;
    replyType: string;
    sendSdkPush: boolean;
    subject: null;
    testGroup: string;
    token: string;
    type: string;
    utmMarks: object | null;
  };
  created?: moment.Moment;
  deliveryTime?: moment.Moment | null;
  deliveryTimeEnd?: string;
  deliveryTimeStart?: string;
  directory?: null | object;
  eventClicked?: string;
  eventRead?: string;
  eventReplied?: string;
  eventSended?: string;
  eventUnsubscribed?: string;
  expirationInterval?: number | null;
  expirationTime?: moment.Moment | null;
  filters: FiltersAJS;
  goalEventCost: number | null;
  goalEventCostProp: string | null;
  goalEventCostType?: string; //TODO ANGULAR_TS надо сделать нормальную константу
  goalEventMaxTimeout?: number;
  goalEventTimeout: number | null;
  goalEventTimeoutTimeUnit?: string; //TODO ANGULAR_TS TIME_UNITS
  goalEventTimeoutTimeUnits?: string[]; //TODO ANGULAR_TS TIME_UNITS,
  goalEventType: string | null;
  jinjaFilterTemplate: string | null; // Jinja фильтры
  hasGoal?: boolean;
  hasTest?: boolean;
  /** ID триггерного сообщения */
  id?: string;
  isControlGroupEnabled?: boolean;
  isMessageHaveFilters?: boolean;
  manualIds?: [];
  messageType?: string; //TODO ANGULAR_TS MESSAGE_TYPES
  /** Название */
  name?: string;
  notSendReplied: boolean;
  /** Интеграции связанные с нотификациями */
  notificationIntegrations: any;
  parts?: object[]; //TODO ANGULAR_TS описать парты
  progress?: number;
  pureHtml?: boolean;
  recipientType?: string; //TODO ANGULAR_TS RECIPIENT_TYPES
  repeatDelay?: number;
  repeatDelayTimeUnit?: string; //TODO ANGULAR_TS TIME_UNIT_MEASURES или TIME_UNITS
  repeatDelayTimeUnits?: string[]; //TODO ANGULAR_TS TIME_UNIT_MEASURES или TIME_UNITS
  repeatMaxDelay?: number;
  isRepeat?: boolean;
  replyType?: string; //TODO ANGULAR_TS надо сделать нормальную константу
  sendSdkPush?: boolean;
  /** URL-фильтры */
  sendingFilters: any;
  isSendAtTime?: boolean;
  sendTimeValueFromH?: string;
  sendTimeValueFromM?: string;
  sendTimeValueToH?: string;
  sendTimeValueToM?: string;
  stats: any;
  status?: string; // TODO ANGULAR_TS MESSAGE_STATUSES
  subject?: string;
  token?: string;
  triggers: string[];
  type?: string; //TODO ANGULAR_TS MESSAGE_PART_TYPES
  userStatuses: string | null; //TODO ANGULAR_TS USER_STATUSES_FOR_SENDING
  utmMarks?: object | null;
  triggerTypes: AutoMessageTriggerType;
};

export enum MESSAGE_SCHEDULE_TYPE {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export type ScheduledAutoMessage = {
  id?: string;
  name: string;
  type: MESSAGE_PART_TYPES;
  recipientType: RECIPIENT_TYPES;
  created?: moment.Moment;
  messageType: 'manual';
  emailDomain?: string;
  active: boolean;
  filters: any;
  schedulerSettings: {
    time: string;
    daysOfWeek?: number[];
    daysOfMonth?: number[];
    type: MESSAGE_SCHEDULE_TYPE;
  };
  stats?: {
    sendingCount: number;
    nextSendingTime: string;
    lastSendingTime: string | null;
  };
  maxUsersCount: number | null;
  repeatDelay: number | null;
  eventRead: string;
  eventReplied: string;
  eventClicked: string;
  eventUnsubscribed: string;
  parts: [MessagePart];
};

/**
 * Параметры удаления сообщений
 * TODO ANGULAR_TS взято из DEFAULT_DELETE_MESSAGE conditions-sending.model.ts
 */
export type DeleteMessage = {
  deleteType: 'certain_date' | 'time_interval' | null;
  interval: {
    value: number;
    unit: string; //TODO ANGULAR_TS TIME_UNITS
    units: string[]; //TODO ANGULAR_TS TIME_UNITS[]
  };
  time: {
    date: moment.Moment | null;
    datepickerOptions: {
      singleDatePicker: true;
      maxDate: moment.Moment | null;
      minDate: moment.Moment | null;
      drops: 'down' | 'up' | 'auto';
    };
    hours: moment.Moment | number | null;
    minutes: moment.Moment | number | null;
    time: moment.Moment | number | null;
  };
};

export type AutoMessageRequestParams = {
  active: boolean; // Включение авто-сообщения после создания|сохранения
  afterDelay: 'after_time' | 'immediately' | 0; // Timeout отправки автосообщения
  app: string; // ID app'a
  closeTest: boolean; // Закрывать предыдущую тест-группу
  deliveryTimeEnd: string; // Нижняя граница времени, доступного для отправки авто-сообщения
  deliveryTimeStart: string; // Верхняя граница времени, доступного для отправки авто-сообщения
  directory: string | null; // ID папки, в которой сохдается/сохраняется авто-сообщение
  eventClicked: string | boolean; // Трекинг события по клику на авто-сообщение
  eventRead: string | boolean; // Трекинг события по прочтению авто-сообщения
  eventReplied: string | boolean; // Трекинг события по ответу на авто-сообщение
  eventSended: string | boolean; // Трекинг события по отправке авто-сообщение
  eventUnsubscribed: string | boolean; // Трекинг события по отприске на авто-сообщения
  expirationInterval?: string; // Через сколько удалиться авто-сообщение, если пользователь его не прочитал
  expirationTime?: string; // Время удаления авто-сообщения, если пользователь его не прочитал
  filters: ExternalFiltersAJS; // Аудитория, на которую будет отправлено авто-сообщение
  goalEventCost?: number; // Ценность цели в деньгах
  goalEventCostProp?: string | null; // Событие для ценности цели
  goalEventTimeout: number | null; // Скорость достижения цели
  goalEventType: string | null; // Тип события достижения цели
  jinjaFilterTemplate: string | null; // Jinja фильтры
  messageType: string; // Тип сообщения, для авто-сообщений всегда 'auto'
  name: string; // Название авто-сообщения
  notificationIntegrations?: Record<string, object> | null; // Интеграции-оповещения
  notSendReplied: boolean; // Не отправлять авто-сообщения, тем, кто на него ответил
  parts: object; // Содержимое авто-сообщения
  repeatDelay: number; // Время повторной отправки авто-сообщения
  sendingFilters: string | boolean | null; // Массив URL, на которых будет показываться автосообщение
  testAutomessageGraph: boolean; // Проверка сообщения на зацикливание
  triggers: string | string[]; // Триггеры, по которым отправлять авто-сообщение
  triggerTypes: AutoMessageTriggerTypeExternal[];
  userStatuses: boolean | null; // Отправка авто-сообщения, в завимости от статуса пользователя
};
