import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { Properties, UserProperty } from '@http/property/property.model';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BotPropertyFieldActionForm } from '@panel/app/pages/chat-bot/forms/actions';
import { DestroyService } from '@panel/app/services';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

@Component({
  selector:
    'cq-branch-action-property-field[actionForm][currentApp][propertyOptions][targetBranchOptions][chatBotType]',
  templateUrl: './property-field.component.html',
  styleUrls: ['./property-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BranchActionPropertyFieldComponent implements OnInit {
  @Input() actionForm!: BotPropertyFieldActionForm; // Форма с действием

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input() propertyOptions!: Properties; // Массив с свойствами и событиями
  @Input() targetBranchOptions!: Branch[]; // Массив с ветками

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Output() branchCreate: EventEmitter<BlockType> = new EventEmitter(); // Callback на создание ветки
  @Output() toBranch: EventEmitter<Branch | string> = new EventEmitter(); // Callback на переход в ветку

  /** Название проекта */
  projectName: string = environment.projectName;

  constructor(protected readonly destroy$: DestroyService, private readonly l10nHelperService: L10nHelperService) {}

  ngOnInit() {
    this.actionForm.controls.active.valueChanges
      .pipe(
        filter((v) => !v),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.actionForm.controls.keyName.reset();
        this.actionForm.prettyKeyName = null;
      });
  }

  /**
   * Получение ветки по linkId
   *
   * @param linkId - LinkID ветки
   */
  getBranchOnByLinkId(linkId: Branch['linkId']): Branch | undefined {
    return this.targetBranchOptions.find((branch: Branch) => {
      return branch.linkId === linkId;
    });
  }

  onChangeProperty(event: UserProperty) {
    this.actionForm.prettyKeyName = event.prettyName;
    // TODO: Это хак, чтоб сказать канвасу, что надо перерисовать, так как изменился prettyKeyName
    // Надо избавляться от этого
    this.actionForm.controls.keyName.setValue(event.name);
  }

  /**
   * Записывает id выбранной ветки
   *
   * @param branch - ветка бота
   */
  onChangeBranch(branch: Branch): void {
    this.actionForm.nextBranchId = branch.id;
  }

  get validatableField() {
    return ['$email', '$phone'].includes(this.actionForm.controls.keyName.value);
  }

  get isActiveMessengerShowConfirmSubscription() {
    return this.currentApp.settings.messenger_show_confirm_subscription;
  }

  get isNewSettingsForPrivacyPolicy() {
    return !(
      !this.currentApp.settings.terms_of_email_storage &&
      !this.currentApp.settings.terms_of_phone_storage &&
      this.currentApp.settings.messenger_confirm_subscription_policy
    );
  }

  get isCustomPlaceholderAllowed() {
    if (!this.chatBotType) {
      return false;
    }

    const isWebBot = [CHAT_BOT_TYPE.ROUTING, CHAT_BOT_TYPE.LEAD, CHAT_BOT_TYPE.WIDGET].includes(this.chatBotType);
    const isPropertyFieldActive = this.actionForm.controls.active.value;

    return isWebBot && isPropertyFieldActive;
  }

  get isRusCountry() {
    return this.l10nHelperService.isRusCountry();
  }
}
