import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { MessageEditorOpenedWebPageTriggerTypeComponent } from '@panel/app/partials/message-editor/trigger/opened-web-page-trigger-type/message-editor-opened-web-page-trigger-type/message-editor-opened-web-page-trigger-type.component';
import { MessageEditorOpenedWebPageTriggerTypesComponent } from '@panel/app/partials/message-editor/trigger/opened-web-page-trigger-type/message-editor-opened-web-page-trigger-types.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [MessageEditorOpenedWebPageTriggerTypesComponent, MessageEditorOpenedWebPageTriggerTypeComponent],
  exports: [MessageEditorOpenedWebPageTriggerTypesComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    SharedModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
  ],
})
export class MessageEditorOpenedWebPageTriggerTypesModule {}
