import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';

import { environment } from '@environment';
import { AppService } from '@http/app/services/app.service';
import { WebSocketMessage } from '@panel/app/services/web-socket/web-socket.types';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private websocket$!: WebSocketSubject<WebSocketMessage> | null;

  constructor(
    private readonly appService: AppService,
    private readonly cookieService: CookieService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  /**
   * Создание потока с соединением к RTS
   * @param rtsChannels Список каналов RTS, к которым нужно подключиться
   */
  public connect(rtsChannels: string | string[]): Observable<WebSocketMessage> {
    this.websocket$ = new WebSocketSubject({
      url: this.getUrl(rtsChannels),
      closeObserver: {
        next: () => this.handleWebSocketClose(),
      },
    });

    return this.websocket$;
  }

  /** Колбэк на закрытие соединения с RTS */
  private handleWebSocketClose(): void {
    if (this.websocket$) {
      this.websocket$.complete();
      this.websocket$ = null;
    }
  }

  /**
   * Формирование URL для подключения к WSS
   * @param rtsChannels Список каналов RTS, к которым нужно подключиться
   */
  private getUrl(rtsChannels: string | string[]): string {
    const token = `appm-${this.appService.app.id}-${this.cookieService.get('carrotquest_auth_token_panel')}`;
    const rtsHost = environment.useNewRts ? environment.realTimeServicesHostNew : environment.realTimeServicesHost;
    const protocol = this.document.location.protocol === 'https:' ? 'wss' : 'ws';

    return `${protocol}:${rtsHost}/websocket/${this.getParsedChannels(rtsChannels)}?auth_token=${token}`;
  }

  /**
   * Формирование списка событий из RTS, на которые нужно подписаться
   * @param rtsChannels Список каналов RTS, к которым нужно подключиться
   */
  private getParsedChannels(rtsChannels: string | string[]): string {
    return Array.isArray(rtsChannels) ? rtsChannels.join('/') : rtsChannels;
  }
}
