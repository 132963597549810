import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { UIRouter } from '@uirouter/core';
import { firstValueFrom, merge } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import {
  CHANNEL_TYPES,
  CHANNEL_TYPES_ARRAY,
  REQUIRED_INTEGRATIONS_FOR_CHANNELS,
  SYSTEM_CHANNEL_TYPES,
} from '@http/channel/channel.constants';
import { Channel, ChannelModel } from '@http/channel/channel.model';
import { FeatureModel } from '@http/feature/feature.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { Properties } from '@http/property/property.model';
import { TeamMember } from '@http/team-member/team-member.types';
import { UserTag } from '@http/user/types/user.type';
import { UtilsModel } from '@http/utils/utils.model';
import {
  URL_FILTER_TYPE,
  UrlFilterConfiguratorComponent,
  UrlFilterConfiguratorOptions,
  UrlFilterFormGroup,
} from '@panel/app/partials/url-filter-configurator/url-filter-configurator.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE, FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import { FilterParser } from '@panel/app/services/filter/filter.parser';
import { EventFilter, PropertyFilter, TagFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { FilterAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

function getAutoSetValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    if (!control.parent.get('referrerEnabled')!.touched && !control.parent.get('userFiltersEnabled')!.touched) {
      return null;
    }

    const isValid = control.parent.value.referrerEnabled || control.parent.value.userFiltersEnabled;
    return control.value && !isValid ? { autoSet: control.value } : null;
  };
}

function getEmptyFiltersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    if (
      !control.parent.get('eventFilters')!.touched &&
      !control.parent.get('propertyFilters')!.touched &&
      !control.parent.get('tagFilters')!.touched
    ) {
      return null;
    }

    const filtersAreEmpty =
      control.parent.value.eventFilters.length === 0 &&
      control.parent.value.propertyFilters.length === 0 &&
      control.parent.value.tagFilters.length === 0;

    return control.value && filtersAreEmpty ? { emptyFilter: control.value } : null;
  };
}

@Component({
  selector: 'cq-channel-editor',
  templateUrl: './channel-editor.component.html',
  styleUrls: ['./channel-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ChannelEditorComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  channel!: Channel;

  @Input()
  channels: Channel[] = [];

  @Input()
  properties!: Properties;

  @Input()
  tags!: UserTag[];

  @Input()
  instagramPages: any[] = [];

  @Input()
  facebookPages: any[] = [];

  @Input()
  vkGroups: any[] = [];

  @Input()
  whatsAppIntegrations: any[] = [];

  @Input()
  telegramIntegrations: any[] = [];

  /** Члены команды */
  @Input() teamMembers!: TeamMember[];

  manualChannelsAmount!: number;

  isEdit: boolean = true;

  accessToMessagesChannels: ProductFeatureAccess = { hasAccess: true, denialReason: null };
  accessToMessageChannelAutoSet: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  protected readonly CHANNEL_NAME_MAX_LENGTH = 30;

  protected channelForm = this.fb.group({
    type: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required, this.channelTypeAlreadyExistsValidator()],
    }),
    name: this.fb.control('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(this.CHANNEL_NAME_MAX_LENGTH),
        this.channelNameAlreadyExistsValidator(),
      ],
    }),
    isPrior: this.fb.control(false, { nonNullable: true }),
    operators: this.fb.control<string[]>([], { nonNullable: true, validators: [Validators.required] }),
    autoSet: this.fb.control(false, { nonNullable: true, validators: [getAutoSetValidator()] }),
    referrerEnabled: this.fb.control(false, { nonNullable: true }),
    referrerFilters: this.fb.array<UrlFilterFormGroup>([]),
    userFiltersEnabled: this.fb.control(false, { nonNullable: true, validators: [getEmptyFiltersValidator()] }),
    selectedFilterType: this.fb.control(false, { nonNullable: true }),
    eventFilters: this.fb.control<EventFilter[]>(
      [
        {
          eventId: null,
          eventType: FILTER_EVENT_TYPE.COUNT,
          operation: {
            type: ELASTICSEARCH_OPERATION.KNOWN,
            value: {
              value: 0,
            },
          },
        },
      ],
      { nonNullable: true },
    ),
    propertyFilters: this.fb.control<PropertyFilter[]>(
      [
        {
          propertyName: null,
          operation: {
            type: ELASTICSEARCH_OPERATION.KNOWN,
            value: {
              value: 0,
            },
          },
        },
      ],
      { nonNullable: true },
    ),
    tagFilters: this.fb.control<TagFilter[]>([], { nonNullable: true }),
    emailForwardingEnabled: this.fb.control(false, { nonNullable: true }),
    emailFilters: this.fb.array<FormControl<string>>([]),
    fbAndInstaPages: this.fb.control<string[]>([], { nonNullable: true }),
    vkGroups: this.fb.control<string[]>([], { nonNullable: true }),
    whatsappPhones: this.fb.control<string[]>([], { nonNullable: true }),
    tgBotIds: this.fb.control<string[]>([], { nonNullable: true }),
  });

  protected apiChannelTypeAlreadyExists = false;
  protected apiChannelNameAlreadyExists = false;

  protected readonly NEW_CHANNEL_PRIORITY = [true, false];
  protected readonly FILTER_LOGICAL_OPERATION = FILTER_LOGICAL_OPERATION;
  protected readonly CHANNEL_TYPES_ARRAY = CHANNEL_TYPES_ARRAY;
  protected readonly INTEGRATION_TYPES = INTEGRATION_TYPES;
  protected readonly PROJECT_NAME = environment.projectName;
  protected readonly CHANNEL_TYPES = CHANNEL_TYPES;

  protected readonly urlFilterConfiguratorOptions: UrlFilterConfiguratorOptions = {
    enableOnlyOptions: [URL_FILTER_TYPE.CONTAINS, URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS],
    addDefaultFilterOnInit: true,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly transloco: TranslocoService,
    private readonly featureModel: FeatureModel,
    private readonly destroy$: DestroyService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly utilsModel: UtilsModel,
    private readonly filterParser: FilterParser,
    private readonly channelModel: ChannelModel,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly toastr: ToastService,
    private readonly uiRouter: UIRouter,
    private readonly filterAjsModel: FilterAjsModel,
    private readonly modalHelper: ModalHelperService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    // КАНАЛ В ФОРМУ
    this.channelForm.controls.type.setValue(this.channel.type, { emitEvent: false });
    this.channelForm.controls.name.setValue(this.channel.name, { emitEvent: false });
    this.channelForm.controls.isPrior.setValue(this.channel.isPrior, { emitEvent: false });
    this.channelForm.controls.operators.setValue(this.channel.operators, { emitEvent: false });
    this.channelForm.controls.autoSet.setValue(this.channel.autoSet, { emitEvent: false });
    this.channelForm.controls.referrerEnabled.setValue(this.channel.autoSetSettings.referrer.enabled, {
      emitEvent: false,
    });
    this.channelForm.controls.userFiltersEnabled.setValue(this.channel.autoSetSettings.userFilters.enabled, {
      emitEvent: false,
    });
    this.channel.autoSetSettings.referrer.filters.forEach((page, index) => {
      const group = UrlFilterConfiguratorComponent.getNewUrlFilter(
        this.utilsModel,
        page.subpages ? URL_FILTER_TYPE.CONTAINS : URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS,
        page.url,
      );
      (this.channelForm.get('referrerFilters') as FormArray).push(group, { emitEvent: false });
    });

    this.channelForm.controls.selectedFilterType.setValue(
      this.channel.autoSetSettings.userFilters.filters.type === FILTER_LOGICAL_OPERATION.OR,
    );
    this.channelForm.controls.eventFilters.setValue(this.channel.autoSetSettings.userFilters.filters.filters.events, {
      emitEvent: false,
    });
    this.channelForm.controls.propertyFilters.setValue(this.channel.autoSetSettings.userFilters.filters.filters.props, {
      emitEvent: false,
    });
    this.channelForm.controls.tagFilters.setValue(this.channel.autoSetSettings.userFilters.filters.filters.tags, {
      emitEvent: false,
    });

    this.channelForm.controls.emailForwardingEnabled.setValue(this.channel.autoSetSettings.emails.enabled, {
      emitEvent: false,
    });
    this.channel.autoSetSettings.emails.filters.forEach((email) => {
      if (this.channelForm.controls.emailForwardingEnabled.value) {
        this.addEmailFilterControl(email, false);
      }
    });

    this.channelForm.controls.fbAndInstaPages.setValue(this.channel.autoSetSettings.pages.filters);
    this.channelForm.controls.vkGroups.setValue(this.channel.autoSetSettings.groups.filters);
    this.channelForm.controls.whatsappPhones.setValue(this.channel.autoSetSettings.accountPhones.filters);
    this.channelForm.controls.tgBotIds.setValue(this.channel.autoSetSettings.botIds.filters);

    // В ОБРАТНУЮ СТОРОНУ

    this.channelForm.valueChanges.pipe(takeUntil(this.destroy$), startWith(null)).subscribe((value) => {
      // так как мы работаем с новыми компонентами фильтров, а каналы сделаны со старыми - представляю суперкостыль
      // парсим новые в экстернал формат, чтоб потом в старый, чтоб потом снова в экстернал, но уже в модели каналов
      const filters = this.filterAjsModel.linkWithPropsAndTags(
        {
          type: this.channelForm.controls.selectedFilterType.value
            ? FILTER_LOGICAL_OPERATION.OR
            : FILTER_LOGICAL_OPERATION.AND,
          filters: [
            ...this.filterParser.parsePropertyFiltersToExternal(this.channelForm.controls.propertyFilters.value),
            ...this.filterParser.parseEventFiltersToExternal(this.channelForm.controls.eventFilters.value),
            ...this.filterParser.parseTagFiltersToExternal(this.channelForm.controls.tagFilters.value),
          ] as unknown as FilterAJS[],
        },
        this.properties,
        this.tags,
      );

      this.channel = {
        ...this.channel,
        name: this.channelForm.controls.name.value.trim(),
        type: this.channelForm.controls.type.value,
        isPrior: this.channelForm.controls.isPrior.value,
        operators: this.channelForm.controls.operators.value,
        autoSet: this.channelForm.controls.autoSet.value,
        autoSetSettings: {
          referrer: {
            enabled: this.channelForm.controls.referrerEnabled.value,
            filters: this.channelForm.controls.referrerFilters.value.map((v) => {
              return {
                url: v.match!,
                subpages: v.type === URL_FILTER_TYPE.CONTAINS,
              };
            }),
          },
          userFilters: {
            enabled: this.channelForm.controls.userFiltersEnabled.value,
            filters,
          },
          emails: {
            enabled: this.channelForm.controls.emailForwardingEnabled.value,
            filters: this.channelForm.controls.emailFilters.controls.map((control) => control.value),
          },
          pages: {
            ...this.channel.autoSetSettings.pages,
            filters: this.channelForm.controls.fbAndInstaPages.value,
          },
          groups: {
            ...this.channel.autoSetSettings.groups,
            filters: this.channelForm.controls.vkGroups.value,
          },
          accountPhones: {
            ...this.channel.autoSetSettings.accountPhones,
            filters: this.channelForm.controls.whatsappPhones.value,
          },
          botIds: {
            ...this.channel.autoSetSettings.botIds,
            filters: this.channelForm.controls.tgBotIds.value,
          },
        },
      };

      if (value?.emailForwardingEnabled === true && value.emailFilters?.length === 0) {
        this.addEmailFilterControl('', false);
      }

      if (value?.emailForwardingEnabled === false && value.emailFilters?.length === 1 && value.emailFilters[0] === '') {
        this.removeEmailFilter(0);
      }
    });

    this.manualChannelsAmount = this.channels.filter((channel) => channel.type === CHANNEL_TYPES.MANUAL).length;

    this.accessToMessagesChannels = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.MESSAGES_CHANNELS,
      this.currentApp,
      this.manualChannelsAmount,
    );
    this.accessToMessageChannelAutoSet = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.MESSAGES_CHANNELS_AUTO_SET,
      this.currentApp,
    );

    // суперкостыли, чтоб показать ошибки как было
    merge(
      this.channelForm.controls.eventFilters.valueChanges,
      this.channelForm.controls.propertyFilters.valueChanges,
      this.channelForm.controls.tagFilters.valueChanges,
      extractTouchedChanges(this.channelForm.controls.eventFilters),
      extractTouchedChanges(this.channelForm.controls.propertyFilters),
      extractTouchedChanges(this.channelForm.controls.tagFilters),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // no comments
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.ngZone.run(() => {
              this.channelForm.controls.userFiltersEnabled.updateValueAndValidity();
              this.changeDetectorRef.markForCheck();
            });
          });
        });
      });

    // суперкостыли, чтоб показать ошибки как было
    merge(
      this.channelForm.controls.userFiltersEnabled.valueChanges,
      this.channelForm.controls.referrerEnabled.valueChanges,
      extractTouchedChanges(this.channelForm.controls.userFiltersEnabled),
      extractTouchedChanges(this.channelForm.controls.eventFilters),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // no comments
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.ngZone.run(() => {
              this.channelForm.controls.autoSet.updateValueAndValidity();
              this.changeDetectorRef.markForCheck();
            });
          });
        });
      });
  }

  addEmailFilterControl(value: string = '', emitEvent = true) {
    const control = this.fb.control(value, { nonNullable: true, validators: [Validators.required, Validators.email] });
    this.channelForm.controls.emailFilters.push(control, { emitEvent });
  }

  // на самом деле вместо этого надо enable/disable контролы, но и так сойдет)00))
  get formIsValid() {
    this.channelForm.markAllAsTouched();

    let valid =
      this.channelForm.controls.type.valid &&
      this.channelForm.controls.name.valid &&
      this.channelForm.controls.isPrior.valid &&
      this.channelForm.controls.operators.valid;

    if (this.channelForm.controls.type.value === CHANNEL_TYPES.MANUAL && this.channelForm.controls.autoSet.value) {
      if (this.channelForm.controls.referrerEnabled.value) {
        valid = valid && this.channelForm.controls.referrerFilters.valid;
      }

      if (this.channelForm.controls.userFiltersEnabled.value) {
        valid =
          valid &&
          this.channelForm.controls.selectedFilterType.valid &&
          this.channelForm.controls.eventFilters.valid &&
          this.channelForm.controls.propertyFilters.valid &&
          this.channelForm.controls.tagFilters.valid;
      }
    }

    if (this.channelForm.controls.type.value === CHANNEL_TYPES.EMAIL) {
      valid =
        valid && this.channelForm.controls.emailForwardingEnabled.valid && this.channelForm.controls.emailFilters.valid;
    }

    return valid;
  }

  setDefaultPages() {
    switch (this.channelForm.controls.type.value) {
      case CHANNEL_TYPES.FACEBOOK:
        this.channelForm.controls.fbAndInstaPages.setValue(this.facebookPages.map((el) => el.id));
        break;
      case CHANNEL_TYPES.INSTAGRAM:
        this.channelForm.controls.fbAndInstaPages.setValue(this.instagramPages.map((el) => el.id));
        break;
      default:
        break;
    }
  }

  @tuiPure
  getFilteredChannelTypes(channelTypes: CHANNEL_TYPES[]): CHANNEL_TYPES[] {
    return channelTypes.filter((channelType) => {
      // создан ли системный канал такого типа
      let isSystemChannelAlreadyExists =
        SYSTEM_CHANNEL_TYPES.includes(channelType) && this.channels.find((channel) => channel.type === channelType);
      // если канал требует интеграцию, то есть ли у пользователя доступ к интеграции
      let isIntegrationChannelAccessible =
        !checkIfIntegrationChannelAccessible(channelType) ||
        (REQUIRED_INTEGRATIONS_FOR_CHANNELS[channelType] &&
          this.featureModel.hasAccess(REQUIRED_INTEGRATIONS_FOR_CHANNELS[channelType]));

      // системные каналы, которые уже созданы нельзя повторно выводить в список
      return !isSystemChannelAlreadyExists && isIntegrationChannelAccessible;

      function checkIfIntegrationChannelAccessible(
        channelType: CHANNEL_TYPES,
      ): channelType is keyof typeof REQUIRED_INTEGRATIONS_FOR_CHANNELS {
        return Object.keys(REQUIRED_INTEGRATIONS_FOR_CHANNELS).includes(channelType);
      }
    });
  }

  saveChannel(): void {
    if (!this.formIsValid) {
      return;
    }
    firstValueFrom(this.channelModel.save(this.channel))
      .then(() => {
        this.trackSaveChannel();
        if (this.channel.type === CHANNEL_TYPES.MANUAL && this.channel.autoSet) {
          this.trackAutoChannel();
        } else if (this.channel.type === CHANNEL_TYPES.EMAIL && this.channel.autoSetSettings.emails.enabled) {
          this.trackAutoEmailChannel();
        }
        this.toastr.success(this.transloco.translate('channelEditor.toasts.channelSaved'));

        this.redirectToConversationsSettings();
      })
      .catch((response: any) => {
        if (response.error === 'ChannelNameAlreadyExistsError') {
          this.apiChannelNameAlreadyExists = true;
          this.channelForm.controls.name.updateValueAndValidity();
        } else if (response.error === 'ChannelTypeAlreadyExistsError') {
          this.apiChannelTypeAlreadyExists = true;
          this.channelForm.controls.type.updateValueAndValidity();
        }
      });
  }

  createChannel() {
    if (!this.formIsValid) {
      return;
    }

    firstValueFrom(this.channelModel.create(this.currentApp.id, this.channel))
      .then(() => {
        this.trackCreateChannel();
        this.toastr.success(this.transloco.translate('channelEditor.toasts.channelCreated'));

        this.redirectToConversationsSettings();
      })
      .catch((response: any) => {
        if (response.error === 'ChannelNameAlreadyExistsError') {
          this.apiChannelNameAlreadyExists = true;
          this.channelForm.controls.name.updateValueAndValidity();
        } else if (response.error === 'ChannelTypeAlreadyExistsError') {
          this.apiChannelTypeAlreadyExists = true;
          this.channelForm.controls.type.updateValueAndValidity();
        }
      });
  }

  channelTypeAlreadyExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.apiChannelTypeAlreadyExists ? { channelTypeAlreadyExists: control.value } : null;
    };
  }

  channelNameAlreadyExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.apiChannelNameAlreadyExists ? { channelNameAlreadyExists: control.value } : null;
    };
  }

  refreshNewChannelPriority(): void {
    this.channelForm.controls.isPrior.setValue(this.channelForm.controls.type.value !== CHANNEL_TYPES.MANUAL);
  }

  isShowDelimiter(): boolean {
    return (
      [
        CHANNEL_TYPES.MANUAL,
        CHANNEL_TYPES.EMAIL,
        CHANNEL_TYPES.FACEBOOK,
        CHANNEL_TYPES.INSTAGRAM,
        CHANNEL_TYPES.WHATS_APP,
        CHANNEL_TYPES.TELEGRAM,
      ].includes(this.channelForm.controls.type.value as CHANNEL_TYPES) || this.isShowVkSettings()
    );
  }

  /** Редиректит на раздел "Настройки диалогов" */
  redirectToConversationsSettings() {
    this.uiRouter.stateService.go('app.content.conversationsSettings');
  }

  openRemoveChannel() {
    this.modalHelper
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.transloco.translate('channelEditor.removeChannelModal.header'),
        body: this.transloco.translate('channelEditor.removeChannelModal.body'),
        confirmButtonText: this.transloco.translate('general.remove'),
      })
      .open(ConfirmModalComponent)
      .result.then(() => {
        return firstValueFrom(this.channelModel.remove(this.channel.id!));
      })
      .then(() => {
        this.trackRemoveChannel();
        this.toastr.success(this.transloco.translate('channelEditor.toasts.channelRemoved'));

        this.redirectToConversationsSettings();
      });
  }

  /**
   * Добавление всех членов команды в список операторов канала
   */
  addAllOperators() {
    let channelOperators = this.channelForm.controls.operators.value; // Извлекаем id каждого оператора

    let remainingOperators = this.teamMembers.map((op) => op.id).filter((opId) => !channelOperators.includes(opId));

    this.channelForm.controls.operators.setValue([...this.channelForm.controls.operators.value, ...remainingOperators]);
  }

  removeEmailFilter(index: number) {
    this.channelForm.controls.emailFilters.removeAt(index);
  }

  /**
   * Трек сохранения/создания канала с включенным автоназначением для своих каналов
   */
  trackAutoChannel(): void {
    this.carrotquestHelper.track('Каналы - включил автоназначение', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  /**
   * Трек сохранения/создания канала с включенным автоназначением для почтовых каналов
   */
  trackAutoEmailChannel(): void {
    this.carrotquestHelper.track('Каналы - включил автоназначение в почтовом канале', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  /**
   * Трек переключения условия автоназначения
   */
  trackChangeAutoSet(): void {
    this.carrotquestHelper.track('Каналы - выбрал условие автоназначения', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  /**
   * Трек создания канала
   */
  trackCreateChannel(): void {
    this.carrotquestHelper.track('Каналы - создал канал', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  /**
   * Трек удаления канала
   */
  trackRemoveChannel(): void {
    this.carrotquestHelper.track('Каналы - удалил канал', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  /**
   * Трек сохранения канала
   */
  trackSaveChannel(): void {
    this.carrotquestHelper.track('Каналы - сохранил канал', {
      App: this.currentApp.name,
      Название: this.channel.name,
      Тип: this.channel.type,
    });
  }

  searchOperatorFn(term: string, item: any) {
    return !!item.name && item.name.toLowerCase().search(term.toLowerCase()) > -1;
  }

  trackById(item: any) {
    return item.id;
  }

  /**
   * Проверяет включено ли автопопадание с указанной страницы FB в этот канал
   * @param pageId ID страницы Facebook
   * @returns
   */
  pageIsChecked(pageId: any): boolean {
    if (!this.channel.autoSetSettings.pages.enabled) {
      // костыль для ФБ и Инсты, для выбора всех страниц, в случае когда канал был автоматически создан
      // и пользователь перешел к его редактированию
      // enabled: false возможна только при первом создании одного из типов интеграций
      //
      return true;
    }
    return this.channelForm.controls.fbAndInstaPages.value.includes(pageId);
  }

  togglePage(pageId: any) {
    if (this.pageIsChecked(pageId)) {
      const index = this.channelForm.controls.fbAndInstaPages.value.findIndex((i) => i === pageId);
      this.channelForm.controls.fbAndInstaPages.value.splice(index, 1);
      this.channelForm.controls.fbAndInstaPages.setValue([...this.channelForm.controls.fbAndInstaPages.value]);
    } else {
      this.channelForm.controls.fbAndInstaPages.setValue([...this.channelForm.controls.fbAndInstaPages.value, pageId]);
    }
  }

  isShowVkSettings() {
    return this.channelForm.controls.type.value === CHANNEL_TYPES.VK && !!this.channel.autoSetSettings.groups;
  }

  vkGroupIsChecked(groupId: any): boolean {
    if (!this.channel.autoSetSettings.groups.enabled) {
      // костыль для VK, для выбора всех сообществ, в случае когда канал был автоматически создан
      // и пользователь перешел к его редактированию
      // enabled: false возможна только при первом создании одного из типов интеграций
      //
      return true;
    }
    return this.channelForm.controls.vkGroups.value.includes(groupId);
  }

  toggleVkGroup(groupId: any) {
    if (this.vkGroupIsChecked(groupId)) {
      const index = this.channelForm.controls.vkGroups.value.findIndex((i) => i === groupId);
      this.channelForm.controls.vkGroups.value.splice(index, 1);
      this.channelForm.controls.vkGroups.setValue([...this.channelForm.controls.vkGroups.value]);
    } else {
      this.channelForm.controls.vkGroups.setValue([...this.channelForm.controls.vkGroups.value, groupId]);
    }
  }

  integrationWhatsAppIsChecked(phone: any): boolean {
    return this.channel.autoSetSettings.accountPhones.filters?.includes(phone) || false;
  }

  toggleWhatsAppIntegration(phone: any) {
    if (this.integrationWhatsAppIsChecked(phone)) {
      const index = this.channelForm.controls.whatsappPhones.value.findIndex((i) => i === phone);
      this.channelForm.controls.whatsappPhones.value.splice(index, 1);
      this.channelForm.controls.whatsappPhones.setValue([...this.channelForm.controls.whatsappPhones.value]);
    } else {
      this.channelForm.controls.whatsappPhones.setValue([...this.channelForm.controls.whatsappPhones.value, phone]);
    }
  }

  integrationTelegramIsChecked(integrationId: any): boolean {
    let integration = this.telegramIntegrations.find((integration) => integration.id === integrationId);
    let bot_id = integration.settings.botId || integration.settings.botToken.split(':')[0];
    return this.channelForm.controls.tgBotIds.value.includes(bot_id);
  }

  toggleTelegramAppIntegration(integrationId: any) {
    let integration = this.telegramIntegrations.find((integration) => integration.id === integrationId);
    let bot_id = integration.settings.botId || integration.settings.botToken.split(':')[0];

    if (this.integrationTelegramIsChecked(integrationId)) {
      const index = this.channelForm.controls.tgBotIds.value.findIndex((i) => i === bot_id);
      this.channelForm.controls.tgBotIds.value.splice(index, 1);
      this.channelForm.controls.tgBotIds.setValue([...this.channelForm.controls.tgBotIds.value]);
    } else {
      this.channelForm.controls.tgBotIds.setValue([...this.channelForm.controls.tgBotIds.value, bot_id]);
    }
  }

  clearApiChannelTypeAlreadyExistsError() {
    this.apiChannelTypeAlreadyExists = false;
    this.channelForm.controls.type.updateValueAndValidity();
  }

  clearApiChannelNameAlreadyExistsError() {
    this.apiChannelNameAlreadyExists = false;
    this.channelForm.controls.name.updateValueAndValidity();
  }
}
