<ng-container *transloco="let t; read: 'crawlingStatusBannerComponent'">
  <div class="card m-0">
    <div class="card-body d-flex align-items-center">
      <ng-container *tuiLet="state.crawlingStatus$ | async as crawlingStatus">
        <ng-container
          *ngIf="
            crawlingStatus === AI_DATA_CRAWLING_STATUS.CREATED || crawlingStatus === AI_DATA_CRAWLING_STATUS.IN_PROGRESS
          "
        >
          <i class="cqi-sm cqi-2x cqi-search text-primary"></i>
          <div class="ml-15">
            <h4 class="mb-5">{{ t('inProgress.heading') }}</h4>
            <div class="text-secondary">{{ t('inProgress.text', { url: state.crawlingUrl$ | async }) }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="crawlingStatus === AI_DATA_CRAWLING_STATUS.FINISHED">
          <i class="cqi-sm cqi-2x cqi-check-circle text-primary"></i>
          <div class="ml-15">
            <h4 class="mb-5">{{ t('finished.heading') }}</h4>
            <div class="d-flex">
              <button
                class="btn btn-text-primary text-primary"
                type="button"
                (click)="openAddCrawledPagesModal()"
              >
                {{ t('finished.link') }}
              </button>
              <span class="text-secondary">{{ t('finished.text') }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
