import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { Channel } from '@http/channel/channel.model';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { DestroyService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { SharedModule } from '@panel/app/shared/shared.module';

type SelectableChannel = Channel & { checked: boolean };

@Component({
  selector: 'cq-bot-channels',
  templateUrl: './bot-channels.component.html',
  styleUrls: ['./bot-channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  standalone: true,
  imports: [ReactiveFormsModule, SharedModule, NgbCollapse, FormsModule, ChannelIconModule, TranslocoModule, NgForOf],
})
export class BotChannelsComponent extends AbsCVAFormControlBasedComponent<string[] | null> implements OnInit {
  @Input({ required: true })
  channels!: Channel[];

  readonly control: FormControl<string[] | null> = new FormControl<string[] | null>(null);

  selectedChannelsCache!: string[];
  selectableChannels!: SelectableChannel[];
  switchControl!: FormControl<boolean>;

  get nonNullableValue() {
    return this.control.value ?? [];
  }

  constructor(readonly destroy$: DestroyService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.selectedChannelsCache = this.nonNullableValue;
    this.selectableChannels = this.mapChannelsToSelectable(this.channels, this.nonNullableValue);
    this.switchControl = new FormControl(!!this.control.value, {
      nonNullable: true,
    });

    this.switchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.control.setValue(value ? this.selectedChannelsCache : null);
    });
  }

  onCheckboxValueChange(checked: boolean, channel: SelectableChannel) {
    const selectedChannels = this.nonNullableValue;
    let newValue: string[];

    if (checked) {
      newValue = [...selectedChannels, channel.id!];
    } else {
      newValue = selectedChannels.filter((id) => id !== channel.id);
    }

    this.control.setValue(newValue);
    this.selectedChannelsCache = newValue;
  }

  private mapChannelsToSelectable(channels: Channel[], selectedChannels: string[]): SelectableChannel[] {
    return channels.map((channel) => {
      return {
        ...channel,
        checked: channel.id ? selectedChannels?.includes(channel.id) : false,
      };
    });
  }
}
