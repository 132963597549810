import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AiSalesMapper } from '@http/ai-sales/ai-sales.mapper';
import { AiSalesBotSettingsExternal } from '@http/ai-sales/ai-sales-external.type';
import { AiSalesBotSettings } from '@http/ai-sales/ai-sales-internal.type';

@Injectable({ providedIn: 'root' })
export class AiSalesModel {
  http: HttpClient = inject(HttpClient);
  aiSalesMapper = inject(AiSalesMapper);

  /** Получение настроек AI-sales бота */
  getSettings(): Observable<AiSalesBotSettings> {
    return this.http
      .get<AiSalesBotSettingsExternal>(`/integrations/ai_sales`, {})
      .pipe(map((response) => this.aiSalesMapper.parseToInternal(response)));
  }

  /**
   * Сохранение настроек AI-sales бота
   *
   * @param settings Настройки AI-sales бота
   */
  saveSettings(settings: AiSalesBotSettings) {
    const params = this.aiSalesMapper.parseToExternal(settings);

    return this.http.patch(`/integrations/ai_sales`, params);
  }
}
