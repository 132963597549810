import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import Quill, { Range } from 'quill';
import { takeUntil } from 'rxjs';

import { EditorService } from '@panel/app/partials/editor/service/editor.service';
import { DestroyService } from '@panel/app/services';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';

@Component({
  selector: 'cq-editor-format-bold',
  templateUrl: './editor-format-bold.component.html',
  styleUrls: ['./editor-format-bold.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class EditorFormatBoldComponent implements OnInit {
  @Input({ required: true })
  public editor!: Quill;

  protected isFormatActive: boolean = false;

  private selection: Range | null = null;

  constructor(
    private editorService: EditorService,
    private changeDetectorRef: ChangeDetectorRef,
    private destroy$: DestroyService,
  ) {}

  public ngOnInit() {
    this.editor.keyboard.bindings['Enter'].unshift({
      key: 'Enter',
      format: ['bold'],
      collapsed: true,
      handler: (range: any, context: any) => {
        setTimeout(() => {
          if (context.format['bold']) {
            this.toggleFormat(true);
          }
        }, 0);

        return true;
      },
    });

    this.editorService.changeSelection$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.saveSelection();

      if (this.selection === null) {
        return;
      }

      this.toggleFormatActiveState(
        isDefined(this.editor.getFormat(this.selection.index, this.selection.length)['bold']),
      );
    });
  }

  protected onClickToggleBoldButton(): void {
    this.toggleFormat(!this.isFormatActive);
  }

  private toggleFormat(state: boolean): void {
    this.editorService.formatBold(this.editor, state);

    this.toggleFormatActiveState(state);
  }

  private toggleFormatActiveState(state: boolean): void {
    this.isFormatActive = state;
    this.changeDetectorRef.detectChanges();
  }

  private saveSelection(): void {
    this.selection = this.editor.getSelection();
  }
}
