import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MessageEditorSwitchModule } from '@panel/app/partials/message-editor/shared/message-editor-switch/message-editor-switch.module';
import { UrlFilterConfiguratorModule } from '@panel/app/partials/url-filter-configurator/url-filter-configurator.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageEditorDispatchUrlComponent } from './message-editor-dispatch-url.component';

@NgModule({
  declarations: [MessageEditorDispatchUrlComponent],
  exports: [MessageEditorDispatchUrlComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    TranslocoModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbDropdownModule,
    UrlFilterConfiguratorModule,
    SharedModule,
    MessageEditorSwitchModule,
    ReactiveFormsModule,
  ],
})
export class MessageEditorDispatchUrlModule {}
