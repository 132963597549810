import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { AceModule } from 'ngx-ace-wrapper';

import { AiContentMakerComponent } from '@panel/app/pages/trigger-messages/email-part-editor/ai-content-maker/ai-content-maker.component';
import { IntegrationSelectModule } from '@panel/app/partials/integration-select/integration-select.module';
import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AcmAddConstantComponent } from './components/acm-add-constant/acm-add-constant.component';
import { AcmAddPropsComponent } from './components/acm-add-props/acm-add-props.component';
import { AcmConstantEditorComponent } from './components/acm-constant-editor/acm-constant-editor.component';
import { AcmPromtComponent } from './components/acm-promt/acm-promt.component';
import { AcmPropsEditorComponent } from './components/acm-props-editor/acm-props-editor.component';

@NgModule({
  exports: [AiContentMakerComponent],
  declarations: [
    AiContentMakerComponent,
    AcmAddPropsComponent,
    AcmAddConstantComponent,
    AcmPromtComponent,
    AcmPropsEditorComponent,
    AcmConstantEditorComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoModule,
    AceModule,
    IntegrationSelectModule,
    FormsModule,
    SharedModule,
    PropertySelectorModule,
    ReactiveFormsModule,
    TuiLet,
    NgbCollapse,
  ],
})
export class AiContentMakerModule {}
