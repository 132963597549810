import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

import { MessageTelegramContent, MessageTelegramContentType } from '@http/message-part/message-part.types';
import { contentIconsMap } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.constants';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-content-blocks',
  templateUrl: './content-blocks.component.html',
  styleUrls: ['./content-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlocksComponent
  extends AbsCVAFormArrayBasedComponent<MessageTelegramContent, FormControl>
  implements OnInit
{
  /**
   * Максимальное количество блоков с контентом
   */
  readonly MAX_CONTENT_BLOCKS = 10;

  readonly control: FormArray<FormControl<MessageTelegramContent>> = this.fb.array<FormControl<MessageTelegramContent>>(
    [],
    [Validators.maxLength(this.MAX_CONTENT_BLOCKS)],
  );

  /**
   * Разрешено ли добавить новый блок
   */
  isAllowToAddContentBlock = false;

  readonly contentTypeList: MessageTelegramContentType[] = ['text', 'file', 'video_note'];

  readonly contentIconsMap = contentIconsMap;

  constructor(private readonly fb: FormBuilder, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.updateAddBlocksAbility();
  }

  protected addContentBlock(type: MessageTelegramContentType): void {
    if (!this.isAllowToAddContentBlock) {
      return;
    }
    let control: FormControl<MessageTelegramContent> = this.getNewControl(undefined, type);

    this.control.push(control);
    this.updateAddBlocksAbility();
  }

  protected deleteContentBlock(index: number): void {
    this.control.removeAt(index);
    this.updateAddBlocksAbility();
  }

  protected copyBLock(index: number): void {
    if (!this.isAllowToAddContentBlock) {
      return;
    }
    const rawValue = this.control.at(index).getRawValue();
    const control: FormControl<MessageTelegramContent> = this.getNewControl(rawValue);

    this.control.push(control);
    this.updateAddBlocksAbility();
  }

  protected moveBlock(index: number, newIndex: number): void {
    if (newIndex < 0 || newIndex >= this.control.controls.length) {
      throw new Error('Wrong new index for moving');
    }
    const control = this.control.at(index);
    this.control.removeAt(index);
    this.control.insert(newIndex, control);
    this.cdr.markForCheck();
  }

  getDefaultContent(type: MessageTelegramContentType): MessageTelegramContent {
    return {
      type,
      value: null,
      attachment: null,
    };
  }

  getNewControl(
    content?: MessageTelegramContent,
    type: MessageTelegramContentType = 'text',
  ): FormControl<MessageTelegramContent> {
    if (!content) {
      content = this.getDefaultContent(type);
    }

    return this.fb.control<MessageTelegramContent>(content, { nonNullable: true });
  }

  private updateAddBlocksAbility() {
    this.isAllowToAddContentBlock = this.control.length < this.MAX_CONTENT_BLOCKS;
  }

  protected readonly iconsMap = contentIconsMap;
}
