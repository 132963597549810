import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';
import { MESSAGE_PART_SENDER_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.scheduledMessages').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.scheduledMessages', {
        url: '',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
        },
      })

      .state('app.content.scheduledMessages.create', {
        url: '/messages/scheduled/create',
        bindings: {
          //autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          //amocrmIntegrations: 'amocrmIntegrations',
          telegramIntegrations: 'telegramIntegrations',
          emailDomains: 'emailDomains',
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          //directories: 'directories',
          djangoUser: 'djangoUser',
          message: 'message',
          //emailNotifications: 'emailNotifications',
          //emailRecipients: 'emailRecipients',
          //isEdit: 'isEdit',
          //messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          //teamMembers: 'teamMembers',
          //usedTemplate: 'usedTemplate',
        },
        component: 'cqScheduledMessageEditor',
        resolve: {
          //autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          //amocrmIntegrations: getAmocrmIntegrations,
          telegramIntegrations: getTelegramActiveIntegrations,
          emailDomains: getEmailDomains,
          //directories: getDirectories,
          message: getDefaultMessage,
          //emailNotifications: getEmailNotificationIntegrations,
          //emailRecipients: getEmailNotificationRecipients,
          //isEdit: angular.bind(null, angular.identity, false),
          //messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          //teamMembers: getTeamMembers,
          //usedTemplate: angular.bind(null, angular.identity, {}),
        },
      })

      .state('app.content.scheduledMessages.edit', {
        url: '/messages/scheduled/{messageId:id}',
        bindings: {
          //autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          //amocrmIntegrations: 'amocrmIntegrations',
          telegramIntegrations: 'telegramIntegrations',
          emailDomains: 'emailDomains',
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          //directories: 'directories',
          djangoUser: 'djangoUser',
          message: 'message',
          //emailNotifications: 'emailNotifications',
          //emailRecipients: 'emailRecipients',
          //isEdit: 'isEdit',
          //messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          //teamMembers: 'teamMembers',
          //usedTemplate: 'usedTemplate',
        },
        component: 'cqScheduledMessageEditor',
        reloadOnSearch: false,
        resolve: {
          //autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          //amocrmIntegrations: getAmocrmIntegrations,
          telegramIntegrations: getTelegramActiveIntegrations,
          emailDomains: getEmailDomains,
          //directories: getDirectories,
          message: getMessage,
          //emailNotifications: getEmailNotificationIntegrations,
          //emailRecipients: getEmailNotificationRecipients,
          //isEdit: angular.bind(null, angular.identity, true),
          //messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          //teamMembers: getTeamMembers,
          //usedTemplate: angular.bind(null, angular.identity, {}),
        },
      })

      .state('app.content.scheduledMessages.edit.copy', {
        url: '/copy',
      });
  }

  function getDefaultMessage(messageModel) {
    return messageModel.getDefaultScheduledMessage();
  }

  function getAutoEvents(currentApp, trackMasterModel) {
    return firstValueFrom(trackMasterModel.getList(currentApp.id));
  }

  /**
   * Получение количества активных автосообщений
   */
  function getActiveMessagesAmounts(messageModel) {
    return firstValueFrom(messageModel.getActiveTriggerMessageCount());
  }

  /**
   * Получение всех тегов пользователя
   *
   * @param currentApp
   * @param tagModel
   * @returns {Promise}
   */
  function getAllUserTags(currentApp, tagModel) {
    return firstValueFrom(tagModel.getList(currentApp.id));
  }

  /**
   * Получение списка директорий автосообщений
   *
   * @param currentApp
   * @param messageDirectoryModel
   * @returns {Promise}
   */
  function getDirectories(currentApp, messageDirectoryModel) {
    return firstValueFrom(messageDirectoryModel.getList(currentApp.id, true, true));
  }

  function getTelegramIntegrations(integrationModel, currentApp) {
    return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
      return [...response[0]].filter((integration) => {
        return integration.active === true;
      });
    });
  }

  /**
   * Получение автосообщения по messageId
   */
  function getMessage($stateParams, messageModel, filterAjsModel, properties, tags) {
    return firstValueFrom(messageModel.getScheduledAutoMessage($stateParams.messageId, properties)).then((message) => {
      filterAjsModel.linkWithPropsAndTags(message.filters, properties, tags);
      return message;
    });
  }

  /**
   * Получение варианта сообщения из готового шаблона
   *
   * @param $stateParams
   * @param $transition$
   * @param readyMessageTemplateModel
   * @returns {*}
   */
  function getMessagePartFromReadyTemplate($stateParams, $transition$, readyMessageTemplateModel) {
    let messagePartModel = $transition$.injector().get('messagePartModel');

    if ($stateParams.readyTemplateId) {
      return firstValueFrom(readyMessageTemplateModel.get($stateParams.readyTemplateId)).then(
        messagePartModel.parseFromTemplate.bind(messagePartModel),
      );
    } else {
      return messagePartModel.parseFromTemplate(readyMessageTemplateModel.getDefault());
    }
  }

  /**
   * Получение варианта сообщения
   *
   * @param $stateParams
   * @param $transition$
   * @param currentApp
   * @param planFeatureAccessService
   *
   * @returns {Promise}
   */
  function getMessagePartFromTemplate($stateParams, $transition$, currentApp, planFeatureAccessService) {
    let messageTemplateModel = $transition$.injector().get('messageTemplateModel');
    let messagePartModel = $transition$.injector().get('messagePartModel');
    const accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, currentApp);

    if (accessToMessagesTemplates.hasAccess && $stateParams.templateId) {
      return firstValueFrom(messageTemplateModel.get($stateParams.templateId)).then(
        messagePartModel.parseFromTemplate.bind(messagePartModel),
      );
    } else {
      return messagePartModel.parseFromTemplate(messageTemplateModel.getDefault());
    }
  }

  /**
   * Получение отправителей
   *
   * @param $transition$
   * @param currentApp
   */
  function getMessageSenders($transition$, currentApp) {
    let messageSenderModel = $transition$.injector().get('messageSenderModel');

    return firstValueFrom(messageSenderModel.getList(currentApp.id)).then(getMessageSendersListSuccess);

    function getMessageSendersListSuccess(messageSenders) {
      for (let i = 0; i < messageSenders.length; i++) {
        messageSenders[i].type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
      }

      return messageSenders;
    }
  }

  /**
   * Получения списка email-нотификаций для аппа
   */
  function getEmailNotificationIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
  }

  /**
   * Получение списка получателей email-оповещений
   */
  function getEmailNotificationRecipients(currentApp, integrationModel) {
    return integrationModel.getEmailRecipients(currentApp.id);
  }

  function getAmocrmIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
  }

  /**
   * Получение списка активных ТГ интеграций
   */
  function getTelegramActiveIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel
      .getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)
      .then((tgIntegrationList) => tgIntegrationList.filter((integration) => integration.active));
  }

  /**
   * Получение списка емейл доменов
   */
  function getEmailDomains(currentApp, emailDomainModel) {
    return firstValueFrom(emailDomainModel.getList(currentApp.id));
  }

  /**
   * Получение списка свойств и типов событий
   *
   * @param currentApp
   * @param propertyModel
   * @return {Promise}
   */
  function getProperties(currentApp, propertyModel) {
    return firstValueFrom(propertyModel.getList(currentApp.id));
  }

  /**
   * Получение настроек пушей
   *
   * @param $transition$
   * @param currentApp
   * @returns {Promise}
   */
  function getPushSettings($transition$, currentApp) {
    let pushSettingsModel = $transition$.injector().get('pushSettingsModel');

    return firstValueFrom(pushSettingsModel.get(currentApp.id));
  }

  /**
   * Получение сегментов
   *
   * @param $transition$
   * @param currentApp
   * @param tags
   * @param properties
   * @returns {*}
   */
  function getSegments($transition$, currentApp, tags, properties) {
    let filterAjsModel = $transition$.injector().get('filterAjsModel');
    let segmentModel = $transition$.injector().get('segmentModel');

    return firstValueFrom(segmentModel.getList(currentApp.id, true)).then(getSegmentsSuccess);

    function getSegmentsSuccess(segments) {
      for (var i = 0; i < segments.length; i++) {
        filterAjsModel.linkWithPropsAndTags(segments[i].filters, properties, tags);
      }
      return segments;
    }
  }

  /**
   * Получение членов команды
   *
   * @param $transition$
   * @param currentApp
   */
  function getTeamMembers($transition$, currentApp) {
    let teamMemberModel = $transition$.injector().get('teamMemberModel');

    return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
  }
})();
