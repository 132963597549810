import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '@http/app/services/app.service';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { SAVED_REPLY_ACCESS_TYPE } from '@http/saved-reply/saved-reply.types';
import { ConversationsSettingsPageStore } from '@panel/app/pages/conversations-settings/conversations-settings-page.store';
import {
  EDIT_SAVED_REPLY_MODAL_DATA_TOKEN,
  EditSavedReplyModalData,
} from '@panel/app/pages/conversations-settings/shared/components/saved-replies/edit-saved-reply-modal/edit-saved-reply-modal.token';

type SavedReplyFormGroup = {
  header: FormControl<string>;
  body: FormControl<string>;
  accessType: FormControl<SAVED_REPLY_ACCESS_TYPE>;
};

@Component({
  selector: 'cq-edit-saved-reply-modal',
  templateUrl: './edit-saved-reply-modal.component.html',
  styleUrls: ['./edit-saved-reply-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSavedReplyModalComponent {
  /**
   * Доступные варианты куда можно добавить новый сохранённый ответ: в начало (false) или в конец (true) списка
   */
  protected ADD_TO_THE_END_OPTIONS: boolean[] = [true, false];

  /**
   * Максимальная длина текста сохранённого ответа
   */
  protected MAX_BODY_LENGTH = 20000;

  /**
   * Максимальная длина заголовка сохранённого ответа
   */
  protected MAX_HEADER_LENGTH = 255;

  /** Флаг добавления ответа в конец списка */
  protected addToTheEnd: boolean = true;

  /** Определение режима: редактирование или создание */
  protected isEdit: boolean = !!this.data.savedReply.id;

  /** Является ли текущий пользователь оператором */
  protected isOperator: boolean = this.djangoUserModel.isOperator(this.appService.currentAppId, this.store.djangoUser);

  protected savedReplyForm: FormGroup<SavedReplyFormGroup> = new FormGroup<SavedReplyFormGroup>({
    header: new FormControl(this.data.savedReply.header ?? '', {
      validators: Validators.maxLength(this.MAX_HEADER_LENGTH),
      nonNullable: true,
    }),
    body: new FormControl(this.decodeMsg(this.data.savedReply.body), {
      validators: [Validators.required, Validators.maxLength(this.MAX_BODY_LENGTH)],
      nonNullable: true,
    }),
    accessType: new FormControl(
      { value: this.getReplyTypeFormControlInitialValue(), disabled: this.isOperator },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
  });

  protected readonly SAVED_REPLY_ACCESS_TYPE = SAVED_REPLY_ACCESS_TYPE;

  constructor(
    private readonly appService: AppService,
    @Inject(EDIT_SAVED_REPLY_MODAL_DATA_TOKEN)
    public readonly data: EditSavedReplyModalData,
    private readonly djangoUserModel: DjangoUserModel,
    protected readonly ngbActiveModal: NgbActiveModal,
    private readonly store: ConversationsSettingsPageStore,
  ) {}

  createOrSave(): void {
    if (!this.savedReplyForm.valid) {
      return;
    }

    this.data.savedReply = {
      ...this.data.savedReply,
      ...this.savedReplyForm.getRawValue(),
    };

    this.ngbActiveModal.close({
      reply: this.data.savedReply,
      addToTheEnd: this.isEdit ? null : this.addToTheEnd,
    });
  }

  /**
   * Преобразует html-символы в специсимволы
   *
   * @param body — Декодируемое сообщение
   * @returns {String}
   */
  decodeMsg(body: any): any {
    if (!body) {
      return '';
    }

    const tagsToReplace: { [key: string]: string } = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
    };

    return body.replace(/(&amp;|&lt;|&gt;)/g, (tag: string) => tagsToReplace[tag] || tag);
  }

  getHotkeyText() {
    if (this.savedReplyForm.controls.accessType.value === SAVED_REPLY_ACCESS_TYPE.PERSONAL) {
      return 'Alt + 1';
    }

    return 'Ctrl + 1';
  }

  getReplyTypeFormControlInitialValue() {
    if (this.isOperator) {
      return SAVED_REPLY_ACCESS_TYPE.PERSONAL;
    }

    return this.data.savedReply.accessType;
  }
}
