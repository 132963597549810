import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainOverlayEventService } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/services';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  TRIGGER_CHAIN_BLOCK_TYPE,
  TRIGGER_CHAIN_CONTENT_BLOCK_TYPES,
  TRIGGER_CHAIN_EXIT_BLOCK_TYPES,
  TRIGGER_CHAIN_LOGIC_BLOCK_TYPES,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';

@Component({
  selector: 'cq-tc-connection-point-overlay',
  templateUrl: './tc-connection-point-overlay.component.html',
  styleUrls: ['./tc-connection-point-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcConnectionPointOverlayComponent implements AfterViewInit {
  @Input()
  @HostBinding('style.width.px')
  width: number = 0;

  @Input()
  @HostBinding('style.height.px')
  height: number = 0;

  @Input()
  @HostBinding('style.left.px')
  x = 0;

  @Input()
  @HostBinding('style.top.px')
  y = 0;

  @ViewChild(NgbDropdown)
  dropdown!: NgbDropdown;

  protected _overlaySource?: AbsInteractiveBlockPartView;

  set overlaySource(value: AbsInteractiveBlockPartView) {
    this._overlaySource = value;
  }

  get overlaySource(): AbsInteractiveBlockPartView {
    if (!this._overlaySource) {
      throw new Error('Could not find overlay source');
    }

    return this._overlaySource;
  }

  protected TRIGGER_CHAIN_BLOCK_TYPE = TRIGGER_CHAIN_BLOCK_TYPE;

  protected TRIGGER_CHAIN_EXIT_BLOCK_TYPES = TRIGGER_CHAIN_EXIT_BLOCK_TYPES;

  protected TRIGGER_CHAIN_CONTENT_BLOCK_TYPES = TRIGGER_CHAIN_CONTENT_BLOCK_TYPES;

  protected TRIGGER_CHAIN_LOGIC_BLOCK_TYPES = TRIGGER_CHAIN_LOGIC_BLOCK_TYPES;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly triggerChainOverlayEventService: TriggerChainOverlayEventService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {}

  ngAfterViewInit() {
    this.dropdown.open();
    // это нам нужно, чтоб дропдаун подхватил необходимость изменений и показался
    this.changeDetectorRef.markForCheck();
  }

  protected onAddBlockButtonClick(blockType: TRIGGER_CHAIN_BLOCK_TYPE) {
    this.triggerChainOverlayEventService.emitEvent({
      type: 'connection-point-overlay',
      event: {
        blockType,
        overlaySource: this.overlaySource,
      },
    });
  }

  protected getClassForStepIcon(blockType: TRIGGER_CHAIN_BLOCK_TYPE): string {
    switch (blockType) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
        return 'cqi-comment-out-filled text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.DELAY:
        return 'cqi-stopwatch text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
        return 'cqi-envelope text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.FILTER:
        return 'cqi-user text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
        return 'cqi-popup-big text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
        return 'cqi-telegram text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
        return 'cqi-mobile-push text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
        return 'cqi-webhook text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.REACTION:
        return 'cqi-bell text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS:
        return 'cqi-check-circle text-success';
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT:
        return 'cqi-exit text-danger';
      case TRIGGER_CHAIN_BLOCK_TYPE.ACTION:
        return 'cqi-flash-filled text-primary';
      default:
        throw new Error('Case is not handled');
    }
  }

  /**
   * Проверка типа шага на доступность
   * @param stepType
   */
  isActive(stepType: TRIGGER_CHAIN_BLOCK_TYPE) {
    if (stepType === TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH) {
      const app = this.triggerChainEditorStore.currentApp$.getValue();

      return !!app?.activation?.installed_sdk_ios || !!app?.activation?.installed_sdk_android;
    }
    return true;
  }
}
