import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { NgArrayPipesModule } from 'ngx-pipes';

import { AddonComponent } from '@panel/app/pages/subscription/general/signed/addons/addon/addon.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [AddonComponent],
  exports: [AddonComponent],
  imports: [CommonModule, SharedModule, TranslocoModule, TuiLet, NgArrayPipesModule, NgbTooltip],
})
export class AddonModule {}
