import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { StateParams } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { ChannelModel } from '@http/channel/channel.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { PropertyModel } from '@http/property/property.model';
import { TagModel } from '@http/tag/tag.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { ConversationsSettingsPageComponent } from '@panel/app/pages/conversations-settings/conversations-settings-page.component';
import { ConversationsSettingsPageStore } from '@panel/app/pages/conversations-settings/conversations-settings-page.store';
import { ChannelEditorComponent } from '@panel/app/pages/conversations-settings/pages/channel-editor/channel-editor.component';
import { ConversationsSettingsComponent } from '@panel/app/pages/conversations-settings/pages/conversations-settings/conversations-settings.component';
import { SavedRepliesPageComponent } from '@panel/app/pages/conversations-settings/pages/saved-replies-page/saved-replies-page.component';
import { FilterParser } from '@panel/app/services/filter/filter.parser';

export const CONVERSATIONS_SETTINGS_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.conversationsSettings',
    url: '/conversations-settings?{tab}',
    redirectTo: 'app.content.conversationsSettings.tabs',
    component: ConversationsSettingsPageComponent,
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
  },
  {
    name: 'app.content.conversationsSettings.tabs',
    url: '',
    component: ConversationsSettingsComponent,
    resolve: [
      {
        token: 'app',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'channels',
        deps: ['channels'],
        resolveFn: (channels: any) => channels,
      },
      {
        token: 'djangoUser',
        deps: [ConversationsSettingsPageStore, 'djangoUser'],
        resolveFn: (conversationsSettingsPageStore: ConversationsSettingsPageStore, djangoUser: DjangoUser) => {
          conversationsSettingsPageStore.djangoUser = djangoUser;
          return djangoUser;
        },
      },
      {
        token: 'djangoUserTempData',
        deps: ['djangoUserTempData'],
        resolveFn: (djangoUserTempData: DjangoUserTempData) => djangoUserTempData,
      },
      {
        token: 'channels',
        deps: [ChannelModel, 'currentApp'],
        resolveFn: getChannels,
      },
      {
        token: 'teamMembers',
        deps: [TeamMemberModel, 'currentApp'],
        resolveFn: getTeamMembers,
      },
    ],
  },
  {
    name: 'app.content.conversationsSettings.savedReplies',
    url: '/saved-replies',
    component: SavedRepliesPageComponent,
    resolve: [
      {
        token: 'djangoUser',
        deps: [ConversationsSettingsPageStore, 'djangoUser'],
        resolveFn: (conversationsSettingsPageStore: ConversationsSettingsPageStore, djangoUser: DjangoUser) => {
          conversationsSettingsPageStore.djangoUser = djangoUser;
          return djangoUser;
        },
      },
    ],
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.OPERATOR],
    },
  },
  // так как в рамках панельки каналы - часть настроек диалогов, первоначальная идея была поменять пути на
  // /conversation-settings/channels и тд, но пришлось бы менять потенциально в бз и других местах,
  // поэтому оставили как есть
  {
    name: 'app.content.channels',
    redirectTo: 'app.content.channels.create',
    url: '/channels',
    component: ConversationsSettingsPageComponent,
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
  },
  {
    name: 'app.content.channels.create',
    component: ChannelEditorComponent,
    url: '/create',
    resolve: [
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'channels',
        deps: [ChannelModel, 'currentApp'],
        resolveFn: getChannels,
      },
      {
        token: 'channel',
        deps: [ChannelModel],
        resolveFn: getDefaultChannel,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', TagModel],
        resolveFn: getTags,
      },
      {
        token: 'facebookPages',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getFacebookPages,
      },
      {
        token: 'instagramPages',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getInstagramPages,
      },
      {
        token: 'vkGroups',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getVkGroups,
      },
      {
        token: 'whatsAppIntegrations',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getWhatsAppIntegrations,
      },
      {
        token: 'telegramIntegrations',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getTelegramIntegrations,
      },
      {
        token: 'teamMembers',
        deps: [TeamMemberModel, 'currentApp'],
        resolveFn: getTeamMembers,
      },
    ],
  },
  {
    name: 'app.content.channels.edit',
    component: ChannelEditorComponent,
    url: `/{channelId}`,
    resolve: [
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'channels',
        deps: [ChannelModel, 'currentApp'],
        resolveFn: getChannels,
      },
      {
        token: 'channel',
        deps: [ChannelModel, '$stateParams', FilterParser],
        resolveFn: getChannelById,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', TagModel],
        resolveFn: getTags,
      },
      {
        token: 'facebookPages',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getFacebookPages,
      },
      {
        token: 'instagramPages',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getInstagramPages,
      },
      {
        token: 'vkGroups',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getVkGroups,
      },
      {
        token: 'whatsAppIntegrations',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getWhatsAppIntegrations,
      },
      {
        token: 'telegramIntegrations',
        deps: ['integrationModel', 'currentApp'],
        resolveFn: getTelegramIntegrations,
      },
      {
        token: 'teamMembers',
        deps: [TeamMemberModel, 'currentApp'],
        resolveFn: getTeamMembers,
      },
    ],
  },
];

function getProperties(propertyModel: PropertyModel, currentApp: App) {
  return firstValueFrom(propertyModel.getList(currentApp.id));
}

function getTags(currentApp: App, tagModel: TagModel) {
  return firstValueFrom(tagModel.getList(currentApp.id));
}

/**
 * Получает список каналов
 */
function getChannels(channelModel: ChannelModel, currentApp: any) {
  return firstValueFrom(channelModel.getList(currentApp.id));
}

/** Получает список членов команды */
function getTeamMembers(teamMemberModel: TeamMemberModel, currentApp: any) {
  return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, false, true));
}

function getDefaultChannel(channelModel: ChannelModel) {
  return channelModel.getDefaultChannel(CHANNEL_TYPES.MANUAL);
}

function getChannelById(channelModel: ChannelModel, stateParams: StateParams, filterParser: FilterParser) {
  return firstValueFrom(channelModel.get(stateParams.channelId)).then((channel) => {
    if (channel.autoSetSettings.userFilters) {
      //@ts-ignore потому что типы говно и не сходятся
      channel.autoSetSettings.userFilters.filters = filterParser.parseToInternal(
        //@ts-ignore потому что типы говно и не сходятся
        channel.autoSetSettings.userFilters?.filters ?? { type: 'or', filters: [] },
      );
      channel.autoSetSettings.userFilters.filters['type'] =
        //@ts-ignore потому что типы говно и не сходятся
        channel.autoSetSettings.userFilters.filters.logicalOperation;
      //@ts-ignore потому что типы говно и не сходятся
      delete channel.autoSetSettings.userFilters.filters.logicalOperation;
    }
    channel.autoSetSettings = {
      ...ChannelModel.DEFAULT_CHANNEL.autoSetSettings,
      ...channel.autoSetSettings,
    };

    return channel;
  });
}

/**
 * Получение списка доступных для фильтра страниц FB
 */
function getFacebookPages(integrationModel: any, currentApp: App) {
  return integrationModel.facebook.getAppSubscribedPages(currentApp.id);
}

/**
 * Получение списка доступных для фильтра страниц Instagram
 */
function getInstagramPages(integrationModel: any, currentApp: App) {
  return integrationModel.instagram.getAppSubscribedPages(currentApp.id);
}

/**
 * Получение списка сообществ ВК, на которые подписан апп
 */
function getVkGroups(integrationModel: any, currentApp: App) {
  return integrationModel.vk.getAppSubscribedGroups(currentApp.id);
}

/**
 * Получение списка доступных интеграций с WhatsApp
 */
function getWhatsAppIntegrations(integrationModel: any, currentApp: App) {
  return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.WHATS_APP_EDNA)]).then((response) => {
    return [...response[0]].filter((integration) => {
      return integration.active === true;
    });
  });
}

/**
 * Получение списка доступных интеграций с Telegram
 */
function getTelegramIntegrations(integrationModel: any, currentApp: App) {
  return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
    return [...response[0]].filter((integration) => {
      return integration.active === true;
    });
  });
}
