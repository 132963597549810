<ng-container *transloco="let t; read: 'savedRepliesComponent'">
  <div class="card mb-0 border-0">
    <div class="card-header">
      <form
        class="ml-n10"
        novalidate
      >
        <div
          class="form-group has-feedback has-feedback-left mb-0"
          [cqShowError]="searchControl"
        >
          <input
            class="form-control border-0"
            autocomplete="off"
            name="search"
            [formControl]="searchControl"
            [placeholder]="t('searchForm.searchInput.placeholder')"
          />
          <span class="form-control-feedback">
            <i class="cqi-sm cqi-search text-muted"></i>
          </span>
          <cq-validation-messages [control]="searchControl">
            <cq-validation-message errorName="maxlength">{{
              t('searchForm.searchInput.errors.maxlength', { maxLength: MAX_SEARCH_LENGTH })
            }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </form>
    </div>
    <div class="p-20 d-flex align-items-center justify-content-between bordered-bottom">
      <div class="btn-group btn-group-toggle">
        <label
          class="btn btn-outline-primary"
          [ngClass]="{ active: selectedRepliesType === SAVED_REPLY_ACCESS_TYPE.SHARED }"
        >
          <input
            name="eventType"
            type="radio"
            [value]="SAVED_REPLY_ACCESS_TYPE.SHARED"
            [ngModel]="selectedRepliesType"
            (ngModelChange)="changeRepliesTypeSubj.next(SAVED_REPLY_ACCESS_TYPE.SHARED)"
          />
          <i class="btn-icon-left cqi-sm cqi-users-o"></i>
          {{ t('typeBtnGroup.shared') }}
        </label>
        <label
          class="btn btn-outline-primary"
          [ngClass]="{ active: selectedRepliesType === SAVED_REPLY_ACCESS_TYPE.PERSONAL }"
        >
          <input
            name="eventType"
            type="radio"
            [value]="SAVED_REPLY_ACCESS_TYPE.PERSONAL"
            [ngModel]="selectedRepliesType"
            (ngModelChange)="changeRepliesTypeSubj.next(SAVED_REPLY_ACCESS_TYPE.PERSONAL)"
          />
          <i class="btn-icon-left cqi-sm cqi-lock"></i>
          {{ t('typeBtnGroup.personal') }}
        </label>
      </div>
      <button
        class="btn btn-primary"
        (click)="openCreateSavedReplyModal(selectedRepliesType)"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{ t('addSavedReplyButton') }}
      </button>
    </div>
    <ng-container *tuiLet="savedRepliesList$ | async as savedRepliesList">
      <ng-container *ngIf="isApiRequestPerformed$ | async; else content">
        <div class="p-60 position-relative">
          <cq-loader></cq-loader>
        </div>
      </ng-container>
      <ng-template #content>
        <ng-container *ngIf="savedRepliesList; else zeroData">
          <ng-container *ngIf="savedRepliesList.length > 0; else zeroData">
            <cq-saved-replies-list
              (savedReplyOrderChanged)="changeSavedReplyOrder($event)"
              (savedReplyEdited)="openEditSavedReplyModal($event)"
              (savedReplyRemoved)="openRemoveSavedReplyModal($event)"
              [isSearch]="isSearch"
              [savedReplies]="savedRepliesList"
              [sortable]="isSortable()"
            ></cq-saved-replies-list>
          </ng-container>
        </ng-container>
        <ng-template #zeroData>
          <div class="zero-data-container p-50 mx-auto text-center">
            <ng-container *ngIf="!isSearch">
              <h4>{{ t('zeroData.notSearch.' + selectedRepliesType + '.header') }}</h4>
              <div class="mb-20">
                {{
                  t('zeroData.notSearch.' + selectedRepliesType + (!isOperator ? '.nonOperatorText' : '.operatorText'))
                }}
              </div>
              <button
                class="btn btn-primary"
                type="button"
                (click)="openCreateSavedReplyModal(SAVED_REPLY_ACCESS_TYPE.PERSONAL)"
              >
                {{
                  t(
                    'zeroData.notSearch.' +
                      (isOperator ? SAVED_REPLY_ACCESS_TYPE.PERSONAL : selectedRepliesType) +
                      '.actionButton'
                  )
                }}
              </button>
            </ng-container>
            <ng-container *ngIf="isSearch">
              <h4>{{ t('zeroData.search.' + selectedRepliesType + '.header') }}</h4>
              <div>
                <span>{{ t('zeroData.search.' + selectedRepliesType + '.text1') }}</span>
                <a
                  href="#"
                  (click)="resetSearch()"
                  >{{ t('zeroData.search.' + selectedRepliesType + '.text2') }}</a
                ><span>{{ t('zeroData.search.' + selectedRepliesType + '.text3') }}</span>
                <a
                  href="#"
                  (click)="toggleSelectedRepliesType()"
                  >{{ t('zeroData.search.' + selectedRepliesType + '.text4') }}</a
                >
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
