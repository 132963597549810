<ng-container *transloco="let t; read: 'modals.checkJinjaModalComponent'">
  <div class="modal-header">
    <div>
      <h3 class="modal-title">{{ t('heading') }}</h3>
      <small
        class="text-secondary"
        [innerHTML]="t('description')"
      ></small>
    </div>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <!--Поле поиска-->
    <div
      class="form-group has-feedback has-feedback-left mb-10"
      *ngIf="firstUserRequestCount"
      [cqShowError]="searchControl"
    >
      <input
        class="form-control"
        [placeholder]="t('searchInput.placeholder')"
        [formControl]="searchControl"
      />
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
      <cq-validation-messages [control]="searchControl">
        <cq-validation-message errorName="maxlength">
          {{ t('searchInput.errors.length', { min: MIN_SEARCH_LENGTH, max: MAX_SEARCH_LENGTH }) }}
        </cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{ t('searchInput.errors.length', { min: MIN_SEARCH_LENGTH, max: MAX_SEARCH_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <div class="position-relative">
      <ng-container *ngIf="userList$ | async as userList; else dataLoading">
        <!--Нет лидов-->
        <ng-container *ngIf="firstUserRequestCount === 0; else usersList">
          <ng-container *ngTemplateOutlet="zeroData; context: { zeroKey: 'hasNoUsers' }"></ng-container>
        </ng-container>

        <ng-template #usersList>
          <!--Список лидов-->
          <ng-container *ngIf="!isDataLoading; else dataLoading">
            <ng-container *ngIf="userList.length > 0; else zeroData">
              <div
                *ngFor="let user of userList; let last = last"
                class="flex flex-row pb-10 pt-10"
                [ngClass]="{ 'border-bottom': !last }"
              >
                <!--Аватарка, имя-->
                <div class="d-flex flex-grow-1">
                  <div class="avatar mr-10">
                    <span [outerHTML]="user.avatar"></span>
                  </div>

                  <div class="d-flex flex-col justify-center avatar-and-name-container">
                    <span class="text-truncate">{{ user.name }}</span>
                    <small
                      *ngIf="user.props.$email"
                      class="text-muted text-truncate"
                    >
                      {{ user.props.$email }}
                    </small>
                  </div>
                </div>

                <!--Кнопки-->
                <div class="d-flex">
                  <!--Ссылка на пользователя-->
                  <a
                    class="btn btn-borderless-primary mr-10"
                    uiSref="app.content.users.detail"
                    [uiParams]="{ userId: user.id }"
                    target="_blank"
                  >
                    <i class="cqi-sm cqi-external-link"></i>
                    <span>{{ t('openUserCardLink') }}</span>
                  </a>

                  <!--Тестировать-->
                  <div class="d-flex align-items-center check-button-container">
                    <button
                      *ngIf="!(testResultsMap | hasKey: user.id); else testResult"
                      class="btn btn-borderless-primary"
                      (click)="checkJinja(user.id)"
                      type="button"
                    >
                      {{ t('testButton') }}
                    </button>
                    <ng-template #testResult>
                      <span
                        class="pl-10"
                        [ngClass]="{ 'text-success': testResultsMap[user.id], 'text-danger': !testResultsMap[user.id] }"
                      >
                        {{ t('testResult.' + testResultsMap[user.id]) }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>

      <!--Zero data-->
      <ng-template
        #zeroData
        let-zeroKey="zeroKey"
      >
        <cq-zero-data
          class="py-20"
          [imgHeightPx]="160"
          [src]="'assets/img/default/users/default/zero-data-leads.png'"
        >
          <span>{{ t('zeroData.' + (zeroKey ? zeroKey : 'hasNoSearchedUsers')) }}</span>
        </cq-zero-data>
      </ng-template>

      <!--Loader-->
      <ng-template #dataLoading>
        <div class="loader-container">
          <cq-loader></cq-loader>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
