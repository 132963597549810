import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';

(function () {
  'use strict';

  angular.module('myApp.manualMessages').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.manual', {
        url: '/manual',
        redirectTo: 'app.content.messagesAjs.manual.table',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.messagesAjs.manual.table', {
        url: '?{scheduledMessageId}',
        bindings: {
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
        },
        component: 'cqManualMessagesTable',
      })

      .state('app.content.messagesAjs.manual.statistics', {
        url: '/{messageId:id}',
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          currentMessage: 'currentMessage',
          djangoUser: 'djangoUser',
          pushSettings: 'pushSettings',
          teamMembers: 'teamMembers',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqManualMessageStatistics',
        resolve: {
          channels: getChannels,
          currentMessage: getMessage,
          pushSettings: getPushSettings,
          teamMembers: getTeamMembers,
          telegramIntegrations: getTelegramIntegrations,
        },
      });
  }

  /**
   * Получение списка каналов
   *
   * @param channelModel
   * @param currentApp
   * @return {Promise}
   */
  function getChannels(channelModel, currentApp) {
    return firstValueFrom(channelModel.getList(currentApp.id, false, true));
  }

  function getTelegramIntegrations(integrationModel, currentApp) {
    return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
      return [...response[0]].filter((integration) => {
        return integration.active === true;
      });
    });
  }

  /**
   * Получение автосообщение по messageId
   *
   * @param $stateParams
   * @param $transition$
   * @param messageModel
   * @returns {Promise}
   */
  function getMessage($stateParams, $transition$, messageModel) {
    let messagePartModel = $transition$.injector().get('messagePartModel');

    return getMessage().then(getMessageParts);

    function getMessage() {
      return firstValueFrom(messageModel.getManualMessage($stateParams.messageId));
    }

    function getMessageParts(currentMessage) {
      return firstValueFrom(messagePartModel.getMessageTestGroupParts(currentMessage.activeTestGroup.id)).then(
        function (parts) {
          currentMessage.activeTestGroup.parts = parts;
          return currentMessage;
        },
      );
    }
  }

  /**
   * Получение настреок пушей
   *
   * @param $transition$
   * @param currentApp
   * @returns {Promise}
   */
  function getPushSettings($transition$, currentApp) {
    let pushSettingsModel = $transition$.injector().get('pushSettingsModel');

    return firstValueFrom(pushSettingsModel.get(currentApp.id));
  }

  /**
   * Получение членов команды
   *
   * @param $transition$
   * @param currentApp
   */
  function getTeamMembers($transition$, currentApp) {
    let teamMemberModel = $transition$.injector().get('teamMemberModel');

    return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
  }
})();
