import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с квотами по emails */
@Component({
  selector: 'cq-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsComponent {
  /** Свёрнута ли детальная информация о расходованных emails */
  protected isCollapsed = true;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly l10nHelperService: L10nHelperService,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {}

  /**
   * Возвращает общую цену перерасхода emails
   *
   * NOTE:
   *  Для US стоимость email при перерасходе считается за 1000 emails, а для RU за 1 email
   *
   * @param overspendingQuantity - Количество emails сверх лимита
   * @param overspendingCost - Стоимость emails при перерасходе
   */
  getOverspendingTotalPrice(overspendingQuantity: number, overspendingCost: number): number {
    let price;

    if (this.l10nHelperService.isUsCountry()) {
      price = (overspendingQuantity * overspendingCost) / 1000;
    } else {
      price = overspendingQuantity * overspendingCost;
    }

    return price;
  }

  /**
   * Получение соотношения использованной квоты писем к общей квоте
   *
   * @param spentEmails Использованные емейлы
   * @param quota Общая квота
   */
  getRatio(spentEmails: number, quota: number): number {
    return spentEmails / quota;
  }

  /** Обработчик клика на кнопку "Детальная информация" */
  onClickDetailsButton(): void {
    this.isCollapsed = !this.isCollapsed;

    this.trackOnClickDetailsButton();
  }

  /** Трекинг клика на кнопку "Детальная информация" */
  trackOnClickDetailsButton(): void {
    const eventName = 'Оплата - клик на \u0022Подробнее\u0022 в квоте писем';

    this.carrotquestHelper.track(eventName);
  }
}
