import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { FEATURE_LABELS_ONLY } from '@http/feature-label/feature-label.constants';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  CANVAS_ZOOM_DIRECTION,
  CANVAS_ZOOM_SETTINGS,
} from '@panel/app/services/canvas/common/base/canvas-base.constants';
import { CanvasScreenshotService } from '@panel/app/services/canvas/common/screenshot/canvas-screenshot.service';
import {
  TRIGGER_CHAIN_BLOCK_TYPE,
  TRIGGER_CHAIN_CONTENT_BLOCK_TYPES,
  TRIGGER_CHAIN_EXIT_BLOCK_TYPES,
  TRIGGER_CHAIN_LOGIC_BLOCK_TYPES,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-trigger-chain-canvas-actions',
  templateUrl: './trigger-chain-canvas-actions.component.html',
  styleUrls: ['./trigger-chain-canvas-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CanvasScreenshotService],
})
export class TriggerChainCanvasActionsComponent {
  /** Доступ до возможности делать скриншот канваса */
  @Input({ required: true })
  hasAccessToCanvasScreenshotFeature!: boolean;

  /** Наличи доступа к копированию структуры цепочки для создания шаблонов */
  @Input({ required: true })
  hasAccessToCopyTemplate!: boolean;

  /** Редактор открыт в fullscreen режиме */
  @Input({ required: true })
  isFullscreen!: boolean;

  /** Значение zoom в canvas */
  @Input({ required: true })
  zoomValue!: number;

  /** Callback на клик по кнопке добавление нового звена в цепочки */
  // TODO Поменять тип Subject
  @Output()
  clickOnAddChainLinkButton: Subject<any> = new Subject<any>();

  /** Callback на клик по кнопке изменения zoom в canvas */
  @Output()
  clickOnChangeZoomButton: Subject<CANVAS_ZOOM_DIRECTION | CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE> = new Subject<
    CANVAS_ZOOM_DIRECTION | CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE
  >();

  /** Callback на клик по кнопке копирования структуры */
  @Output()
  clickOnCopyStructureForTemplate: Subject<void> = new Subject<void>();

  /** Callback на клик по кнопке возврата в начало цепочки */
  @Output()
  clickOnGoToStartButton: Subject<void> = new Subject<void>();

  /** Callback на клик по кнопке создания скриншота цепочки */
  @Output()
  clickOnTakeScreenshotButton: Subject<void> = new Subject<void>();

  /** Callback на клик по кнопке переключения fullscreen режима */
  @Output()
  clickOnToggleFullscreenModeButton: Subject<void> = new Subject<void>();

  /** Направление изменений значения zoom */
  CANVAS_ZOOM_DIRECTION = CANVAS_ZOOM_DIRECTION;

  /** Настройки zoom */
  CANVAS_ZOOM_SETTING = CANVAS_ZOOM_SETTINGS;

  FEATURE_LABELS_ONLY = FEATURE_LABELS_ONLY;

  /** Типы шагов триггерной цепочки связанные с контентом */
  TRIGGER_CHAIN_CONTENT_BLOCK_TYPES = TRIGGER_CHAIN_CONTENT_BLOCK_TYPES;

  TRIGGER_CHAIN_BLOCK_TYPE = TRIGGER_CHAIN_BLOCK_TYPE;

  /** Типы шагов триггерной цепочки связанные с логикой */
  TRIGGER_CHAIN_LOGIC_BLOCK_TYPES = TRIGGER_CHAIN_LOGIC_BLOCK_TYPES;

  /** Типы шагов триггерной цепочки связанные с окончанием */
  TRIGGER_CHAIN_EXIT_BLOCK_TYPES = TRIGGER_CHAIN_EXIT_BLOCK_TYPES;

  constructor(
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    private readonly l10nHelper: L10nHelperService,
  ) {}

  /** Возвращает класс для иконки переключения fullscreen режима */
  getClassForToggleFullscreenIcon(): string {
    return this.isFullscreen ? 'cqi-compress' : 'cqi-expand';
  }

  /**
   * Возвращает класс иконки для типа шага
   *
   * @param stepType - Тип шага
   */
  getClassForStepIcon(stepType: TRIGGER_CHAIN_BLOCK_TYPE): string {
    switch (stepType) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
        return 'cqi-comment-out-filled text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.DELAY:
        return 'cqi-stopwatch text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
        return 'cqi-envelope text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.FILTER:
        return 'cqi-user text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
        return 'cqi-popup-big text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
        return 'cqi-telegram text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
        return 'cqi-mobile-push text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
        return 'cqi-webhook text-secondary';
      case TRIGGER_CHAIN_BLOCK_TYPE.REACTION:
        return 'cqi-bell text-primary';
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS:
        return 'cqi-check-circle text-success';
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT:
        return 'cqi-exit text-danger';
      case TRIGGER_CHAIN_BLOCK_TYPE.ACTION:
        return 'cqi-flash-filled text-primary';
      default:
        throw new Error('Case is not handled');
    }
  }

  /** Обрабатывает клик по кнопке добавления нового звена в цепочку */
  // TODO Поменять тип chainLinkType
  onClickAddChainLinkButton(chainLinkType: TRIGGER_CHAIN_BLOCK_TYPE): void {
    this.clickOnAddChainLinkButton.next(chainLinkType);
  }

  /** Обрабатывает клик по кнопке изменения zoom в canvas */
  onClickChangeZoomButton(value: CANVAS_ZOOM_DIRECTION | CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE): void {
    this.clickOnChangeZoomButton.next(value);
  }

  onClickCopyStructureForTemplate(): void {
    this.clickOnCopyStructureForTemplate.next();
  }

  /** Обрабатывает клик по кнопке возврата в начало цепочки */
  onClickGoToStartButton(): void {
    this.clickOnGoToStartButton.next();
  }

  /** Обрабатывает клик по кнопке создания скриншота */
  onClickTakeScreenshot(): void {
    this.clickOnTakeScreenshotButton.next();
  }

  /** Обрабатывает клик по кнопке переключения fullscreen режима */
  onClickToggleFullscreenModeButton(): void {
    this.clickOnToggleFullscreenModeButton.next();
  }

  /**
   * Проверка типа шага на доступность
   * @param stepType
   */
  isActive(stepType: TRIGGER_CHAIN_BLOCK_TYPE) {
    if (stepType === TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH) {
      const app = this.triggerChainEditorStore.currentApp$.getValue();

      return !!app?.activation?.installed_sdk_ios || !!app?.activation?.installed_sdk_android;
    }
    return true;
  }

  get sdkPopoverLink() {
    if (this.l10nHelper.isUsCountry()) {
      return 'https://help.dashly.io/article/2487';
    } else {
      return 'https://help.carrotquest.io/article/5891';
    }
  }
}
