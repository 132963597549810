import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FAKE_PLAN_CAPABILITIES,
  PLAN_CAPABILITIES,
  PLAN_CAPABILITIES_RELEASE_DATE,
} from 'app/http/plan-capability/plan-capability.constants';
import Moment from 'moment';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { Capability, ComparisonOperator } from '@http/plan-capability/plan-capability.types';
import { BILLING_PLAN_IDS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

/**
 * Модель характеристик тарифного плана
 */
@Injectable({ providedIn: 'root' })
export class PlanCapabilityModel {
  constructor(
    private http: HttpClient,
    private billingInfoModel: BillingInfoModel,
    private l10nHelper: L10nHelperService,
  ) {}

  /** Список доступных возможностей плана */
  private capabilities: Capability[] = [];

  /**
   * Получение списка доступных возможностей
   *
   * @param appId - Id app'a
   */
  public getList(appId: string): Observable<Capability[]> {
    return this.http.get<Capability[]>(`/apps/${appId}/billing/plan/capabilities/`).pipe(
      tap((response) => {
        this.capabilities = response;
      }),
    );
  }

  /**
   * Проверка доступности возможности для плана
   * Если массив с фичами пустой, то считаем, что апп на старом тарифе => все фичи доступны
   * Если нужно ограничить тариф без участия Back-end, добавляем ограничение в FAKE_PLAN_CAPABILITIES
   *
   * @param capability - Название возможности
   * @param amount - Количество фичей
   * @param comparisonOperator - Оператор сравнения для фич с лимитом
   */
  public hasAccess(
    capability: PLAN_CAPABILITIES,
    amount?: number,
    comparisonOperator: ComparisonOperator = '<',
  ): boolean {
    if (this.isFakeCapability(capability)) {
      return this.hasAccessForFakeCapability(capability);
    }

    if (environment.sandboxEnabled || this.capabilities.length === 0) {
      return true;
    }

    let capabilityObject = this.capabilities.find((cap) => cap.name === capability);
    if (amount && capabilityObject) {
      return capabilityObject.limit === null || this.compareAmount(amount, capabilityObject.limit, comparisonOperator);
    }

    return !!capabilityObject;
  }

  /**
   * Получить лимит по ограничению
   * @param capability
   */
  public getLimit(capability: PLAN_CAPABILITIES): null | number {
    let capabilityObject = this.capabilities.find((cap) => cap.name === capability);

    if (capabilityObject) {
      return capabilityObject.limit;
    }

    return null;
  }

  /**
   * Проверка доступности fake-ограничения для плана
   *
   * @param capability - Название ограничения
   */
  public hasAccessForFakeCapability(capability: PLAN_CAPABILITIES): boolean {
    //@ts-ignore
    switch (this.billingInfoModel.billingInfo.subscription?.plan_id) {
      case BILLING_PLAN_IDS.FREE_TRIAL:
        return false;
      default:
        return true;
    }
  }

  /**
   * Определение участия app'а в ограничениях по тарифу
   *
   * @param currentApp - Объект с информацией о app'е
   */
  public isCapabilityMode(currentApp: App): boolean {
    let capabilitiesReleaseDate: Moment.Moment;
    let isAppAfterCapabilitiesRelease: boolean;
    let isAppHasCapabilities: boolean;

    if (this.l10nHelper.isUsCountry()) {
      capabilitiesReleaseDate = moment(PLAN_CAPABILITIES_RELEASE_DATE.US);
    } else {
      capabilitiesReleaseDate = moment(PLAN_CAPABILITIES_RELEASE_DATE.RU);
    }

    isAppAfterCapabilitiesRelease = currentApp.created.isAfter(capabilitiesReleaseDate);
    isAppHasCapabilities = this.capabilities.length !== 0;

    return isAppAfterCapabilitiesRelease || isAppHasCapabilities;
  }

  /**
   * Является ли ограничение имитационным
   *
   * @param capability - Возможность, которую нужно проверить
   */
  public isFakeCapability(capability: PLAN_CAPABILITIES): boolean {
    return FAKE_PLAN_CAPABILITIES.includes(capability);
  }

  /**
   * Сравнение количества фичей с их лимитом в тарифе
   *
   * @param amount Количество фичей
   * @param limit Лимит по количеству фичей в тарифе
   * @param comparisonOperator Оператор сравнения
   * @private
   */
  private compareAmount(amount: number, limit: number, comparisonOperator: ComparisonOperator): boolean {
    switch (comparisonOperator) {
      case '<':
        return amount < limit;
      case '<=':
        return amount <= limit;
    }
  }
}
