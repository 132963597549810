import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationIconComponent } from './integration-icon.component';

@NgModule({
  declarations: [IntegrationIconComponent],
  exports: [IntegrationIconComponent],
  imports: [TranslocoModule],
})
export class IntegrationIconModule {}
