import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TriggerChainActionType, TriggerChainStepAction } from '@http/trigger-chain/internal-types';
import { TriggerChainActionForm } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/trigger-chain-action-editor.types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';

@Component({
  selector: 'cq-trigger-chain-action-editor',
  templateUrl: './trigger-chain-action-editor.component.html',
  styleUrls: ['./trigger-chain-action-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainActionEditorComponent {
  @Input({ required: true })
  set step(step: TriggerChainStepAction) {
    this._step = step;

    this.form.setValue(
      {
        name: step.name,
        actions: step.meta.actions,
      },
      { emitEvent: false },
    );
  }

  readonly form: FormGroup<TriggerChainActionForm> = this.fb.group<TriggerChainActionForm>({
    name: this.fb.control('', { nonNullable: true, validators: [Validators.required] }),
    actions: this.fb.control<TriggerChainActionType[]>([], { nonNullable: true, validators: [Validators.required] }),
  });

  private _step!: TriggerChainStepAction;

  get step(): TriggerChainStepAction {
    return this._step;
  }

  @Output()
  stepChange: Observable<TriggerChainStepAction> = this.form.valueChanges.pipe(
    map((controlValues) => {
      if (controlValues.name) {
        this.step.name = controlValues.name;
      }

      if (controlValues.actions) {
        this.step.meta.actions = controlValues.actions;
      }

      return this.step;
    }),
  );

  constructor(
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    private fb: FormBuilder,
  ) {}

  deleteStepChain() {
    this.editorInteractions.deleteClick.next(this.step);
  }

  copyStepChain() {
    this.editorInteractions.copyClick.next(this.step);
  }
}
