import { DomSanitizer } from '@angular/platform-browser';
import { downgradeInjectable } from '@angular/upgrade/static';
import { module } from 'angular';

import { AppModel } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { ArticleModel } from '@http/article/article.model';
import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { BillingHttpService } from '@http/billing/http/billing-http.service';
import { ChannelModel } from '@http/channel/channel.model';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { DefaultBotHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-bot.helper';
import { DefaultBranchHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-branch.helper';
import { ChatBotStatisticsModel } from '@http/chat-bot-statistics/chat-bot-statistics.model';
import { ChatBotTemplateModel } from '@http/chat-bot-template/chat-bot-template.model';
import { ChatSettingsModel } from '@http/chat-settings/chat-settings.model';
import { ConversationModel } from '@http/conversation/conversation.model';
import { ConversationPartModel } from '@http/conversation-part/conversation-part.model';
import { ConversationStatisticsModel } from '@http/conversation-statistics/conversation-statistics.model';
import { ConversationTagsModel } from '@http/conversation-tags/conversation-tags.model';
import { DashboardModel } from '@http/dashboard/dashboard.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUserIntegrationModel } from '@http/django-user-integration/django-user-integration.model';
import { EmailDomainModel } from '@http/email-domain/email-domain.model';
import { EmailKarmaModel } from '@http/email-karma/email-karma.model';
import { EmailStatusModel } from '@http/email-status/email-status.model';
import { EventModel } from '@http/event/event.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { FeatureModel } from '@http/feature/feature.model';
import { FeatureLabelModel } from '@http/feature-label/feature-label.model';
import { FunnelModel } from '@http/funnel/funnel.model';
import { InvoiceHttpService } from '@http/invoice/http/invoice-http.service';
import { KnowledgeBaseModel } from '@http/knowledge-base/knowledge-base.model';
import { MessageModel } from '@http/message/message.model';
import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { MessagePreviewService } from '@http/message-preview/message-preview.service';
import { MessageSenderModel } from '@http/message-sender/message-sender.model';
import { MessageStatisticsModel } from '@http/message-statistics/message-statistics.model';
import { MessageTemplateModel } from '@http/message-template/message-template.model';
import { MessageTestGroupModel } from '@http/message-test-group/message-test-group.model';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import { NewsHelperService } from '@http/news-helper/news-helper.service';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { PropertyModel } from '@http/property/property.model';
import { PushSettingsModel } from '@http/push-settings/push-settings.model';
import { PushSubscriptionModel } from '@http/push-subscription/push-subscription.model';
import { ReadyMessageTemplateModel } from '@http/ready-message-template/ready-message-template.model';
import { SavedReplyModel } from '@http/saved-reply/saved-reply.model';
import { SegmentModel } from '@http/segment/segment.model';
import { SlashCommandModel } from '@http/slash-command/slash-command.model';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { SystemLogModel } from '@http/system-log/system-log.model';
import { TagModel } from '@http/tag/tag.model';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { TeamMemberGroupModel } from '@http/team-member-group/team-member-group.model';
import { MemberInvitationsService } from '@http/team-member-invitation/services/member-invitations.service';
import { TemplateDirectoryModel } from '@http/template-directory/template-directory.model';
import { TrackMasterModel } from '@http/track-master/track-master.model';
import { TriggerChainModel } from '@http/trigger-chain/trigger-chain.model';
import { UserModel } from '@http/user/user.model';
import { UserNoteModel } from '@http/user-note/user-note.model';
import { UtilsModel } from '@http/utils/utils.model';
import { VoteModel } from '@http/vote/vote.model';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { ConversationModalsService } from '@panel/app/pages/conversations/shared/services/conversation-modals.service';
import { GenericCaseStyleHelper, ModalHelperService, OneTimeFlagService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { BlockPanelHelper } from '@panel/app/services/block-panel-helper/block-panel-helper.service';
import { FilterParser } from '@panel/app/services/filter/filter.parser';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { FirstPromoterService } from '@panel/app/services/first-promoter/first-promoter.service';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { MessagePreviewScreenshotService } from '@panel/app/services/message-preview-screenshot/message-preview-screenshot.service';
import { PlanCapabilityHelper } from '@panel/app/services/plan-capability-helper/plan-capability-helper.service';
import { PopupBlockModel } from '@panel/app/services/popup-block/popup-block.model';
import { ServiceInstallationToastService } from '@panel/app/services/service-installation-toast/service-installation-toast.service';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { UtmMarkModel } from '@panel/app/services/utm-mark/utm-mark.model';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';
import { FroalaHelperService } from '@panel/app/shared/services/froala-helper/froala-helper.service';
import { QueueRtsService } from '@panel/app/shared/services/queue-rts/queue-rts.service';

module('myApp.angularDowngradedProviders', [])
  .service('appModel', downgradeInjectable(AppModel))
  .service('domSanitizer', downgradeInjectable(DomSanitizer))
  .service('appService', downgradeInjectable(AppService))
  .service('articleCategoryModel', downgradeInjectable(ArticleCategoryModel))
  .service('articleModel', downgradeInjectable(ArticleModel))
  .service('billingHttpService', downgradeInjectable(BillingHttpService))
  .service('billingInfoModel', downgradeInjectable(BillingInfoModel))
  .service('billingPlanService', downgradeInjectable(BillingPlanService))
  .service('defaultBotHelper', downgradeInjectable(DefaultBotHelper))
  .service('defaultBranchHelper', downgradeInjectable(DefaultBranchHelper))
  .service('chatBotModel', downgradeInjectable(ChatBotModel))
  .service('chatBotStatisticsModel', downgradeInjectable(ChatBotStatisticsModel))
  .service('chatBotTemplateModel', downgradeInjectable(ChatBotTemplateModel))
  .service('chatSettingsModel', downgradeInjectable(ChatSettingsModel))
  .service('conversationModel', downgradeInjectable(ConversationModel))
  .service('conversationPartModel', downgradeInjectable(ConversationPartModel))
  .service('conversationStatisticsModel', downgradeInjectable(ConversationStatisticsModel))
  .service('conversationTagsModel', downgradeInjectable(ConversationTagsModel))
  .service('dashboardModel', downgradeInjectable(DashboardModel))
  .service('djangoUserIntegrationModel', downgradeInjectable(DjangoUserIntegrationModel))
  .service('emailDomainModel', downgradeInjectable(EmailDomainModel))
  .service('emailKarmaModel', downgradeInjectable(EmailKarmaModel))
  .service('emailStatusModel', downgradeInjectable(EmailStatusModel))
  .service('eventModel', downgradeInjectable(EventModel))
  .service('eventTypeModel', downgradeInjectable(EventTypeModel))
  .service('funnelModel', downgradeInjectable(FunnelModel))
  .service('featureModel', downgradeInjectable(FeatureModel))
  .service('featureLabelModel', downgradeInjectable(FeatureLabelModel))
  .service('billingPlanService', downgradeInjectable(BillingPlanService))
  .service('channelModel', downgradeInjectable(ChannelModel))
  .service('messageModel', downgradeInjectable(MessageModel))
  .service('conversationModalsService', downgradeInjectable(ConversationModalsService))
  .service('djangoUserModel', downgradeInjectable(DjangoUserModel))
  .service('emojiService', downgradeInjectable(EmojiService))
  .service('firstPromoterService', downgradeInjectable(FirstPromoterService))
  .service('memberInvitationsService', downgradeInjectable(MemberInvitationsService))
  .service('messageStatisticsModel', downgradeInjectable(MessageStatisticsModel))
  .service('messageTestGroupModel', downgradeInjectable(MessageTestGroupModel))
  .service('messagePreviewService', downgradeInjectable(MessagePreviewService))
  .service('froalaHelper', downgradeInjectable(FroalaHelperService))
  .service('genericCaseStyleHelper', downgradeInjectable(GenericCaseStyleHelper))
  .service('invoiceHttpService', downgradeInjectable(InvoiceHttpService))
  .service('knowledgeBaseModel', downgradeInjectable(KnowledgeBaseModel))
  .service('blockPanelHelper', downgradeInjectable(BlockPanelHelper))
  .service('modalHelperService', downgradeInjectable(ModalHelperService))
  .service('newsHelperService', downgradeInjectable(NewsHelperService))
  .service('paywallService', downgradeInjectable(PaywallService))
  .service('l10nHelper', downgradeInjectable(L10nHelperService))
  .service('propertyModel', downgradeInjectable(PropertyModel))
  .service('planCapabilityHelper', downgradeInjectable(PlanCapabilityHelper))
  .service('planCapabilityModel', downgradeInjectable(PlanCapabilityModel))
  .service('utilsService', downgradeInjectable(UtilsService))
  .service('planFeatureAccessService', downgradeInjectable(PlanFeatureAccessService))
  .service('planVersionService', downgradeInjectable(PlanVersionService))
  .service('pushSubscriptionModel', downgradeInjectable(PushSubscriptionModel))
  .service('readyMessageTemplateModel', downgradeInjectable(ReadyMessageTemplateModel))
  .service('queueRtsService', downgradeInjectable(QueueRtsService))
  .service('savedReplyModel', downgradeInjectable(SavedReplyModel))
  .service('slashCommandModel', downgradeInjectable(SlashCommandModel))
  .service('starterGuideModel', downgradeInjectable(StarterGuideModel))
  .service('systemLogModel', downgradeInjectable(SystemLogModel))
  .service('tagModel', downgradeInjectable(TagModel))
  .service('teamMemberGroupModel', downgradeInjectable(TeamMemberGroupModel))
  .service('teamMemberModel', downgradeInjectable(TeamMemberModel))
  .service('serviceInstallationToast', downgradeInjectable(ServiceInstallationToastService))
  .service('trackMasterModel', downgradeInjectable(TrackMasterModel))
  .service('messageDirectoryModel', downgradeInjectable(MessageDirectoryModel))
  .service('triggerChainModel', downgradeInjectable(TriggerChainModel))
  .service('oneTimeFlagHelper', downgradeInjectable(OneTimeFlagService))
  .service('conversationsStoreService', downgradeInjectable(ConversationsStoreService))
  .service('messageUtilsModel', downgradeInjectable(MessageUtilsModel))
  .service('popupBlockModel', downgradeInjectable(PopupBlockModel))
  .service('utmMarkModel', downgradeInjectable(UtmMarkModel))
  .service('userModel', downgradeInjectable(UserModel))
  .service('userNoteModel', downgradeInjectable(UserNoteModel))
  .service('utilsModel', downgradeInjectable(UtilsModel))
  .service('voteModel', downgradeInjectable(VoteModel))
  .service('pushSettingsModel', downgradeInjectable(PushSettingsModel))
  .service('templateDirectoryModel', downgradeInjectable(TemplateDirectoryModel))
  .service('messageTemplateModel', downgradeInjectable(MessageTemplateModel))
  .service('messageSenderModel', downgradeInjectable(MessageSenderModel))
  .service('segmentModel', downgradeInjectable(SegmentModel))
  .service('filterAjsModel', downgradeInjectable(FilterAjsModel))
  .service('filterParser', downgradeInjectable(FilterParser))
  .service('messagePartModel', downgradeInjectable(MessagePartModel))
  .service('messagePreviewScreenshotService', downgradeInjectable(MessagePreviewScreenshotService));
