import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import Quill, { Range } from 'quill';
import { delay } from 'rxjs';

import { EditorService } from '@panel/app/partials/editor/service/editor.service';

@Component({
  selector: 'cq-editor-format-button',
  templateUrl: './editor-format-button.component.html',
  styleUrls: ['./editor-format-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFormatButtonComponent implements OnInit {
  @ViewChild('buttonTooltipInstance', { static: false })
  buttonTooltipInstance!: NgbTooltip;

  @ViewChild('buttonPopoverInstance', { static: false })
  buttonPopoverInstance!: NgbPopover;

  @ViewChild('selectionButtonPopoverContainer', { static: false })
  selectionButtonPopoverContainer!: ElementRef;

  @ViewChild('selectionButtonPopoverInstance', { static: false })
  selectionButtonPopoverInstance!: NgbPopover;

  @Input({ required: true })
  public editor!: Quill;

  @Input({ required: true })
  public editorComponentRef!: ElementRef;

  protected activeTab: number = 1;
  protected buttonFormGroup = this.formBuilder.group({
    href: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    text: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isBlank: this.formBuilder.control(true, {
      validators: [],
      nonNullable: true,
    }),
  });

  private selection: Range | null = null;

  constructor(private formBuilder: FormBuilder, private editorService: EditorService) {}

  ngOnInit(): void {
    this.editorService.clickOnEditor$.pipe(delay(200)).subscribe((target) => {
      if (target && target.classList.contains('carrotquest-messenger-button')) {
        let formatNode = target as HTMLAnchorElement;

        this.saveSelection();
        this.setSelectionButtonPopoverPosition(formatNode);

        this.buttonFormGroup.setValue({
          href: formatNode.getAttribute('href') ?? '',
          text: formatNode.innerText,
          isBlank: formatNode.getAttribute('target') === '_blank',
        });

        this.openSelectionButtonPopover();
      }
    });
  }

  protected deleteButton(): void {
    this.editorService.formatButtonDelete(this.editor, this.selection);
    this.selectionButtonPopoverInstance.close();
  }

  protected onClickButtonPopoverButton(): void {
    this.saveSelection();
    this.closeTooltip();

    let href = '';
    let text = '';
    let blank = '_blank';

    if (this.selection) {
      text = this.editor.getText(this.selection.index, this.selection.length);
    }

    this.buttonFormGroup.setValue({
      href,
      text,
      isBlank: blank === '_blank',
    });

    this.buttonPopoverInstance.open();
  }

  protected openLink(): void {
    let { href } = this.buttonFormGroup.getRawValue();

    this.editorService.formatLinkOpen(href);

    this.resetActiveTab();
  }

  protected insertButton(): void {
    let { href, text, isBlank } = this.buttonFormGroup.getRawValue();
    let target = isBlank ? '_blank' : '_target';

    this.editorService.formatButtonInsert(this.editor, this.selection, {
      text,
      href,
      target,
    });

    this.buttonFormGroup.setValue({
      href: '',
      text: '',
      isBlank: true,
    });

    this.closeButtonPopover();
    this.closeSelectionButtonPopover();
    this.resetActiveTab();
  }

  private closeButtonPopover(): void {
    this.buttonPopoverInstance.close();
  }

  private closeSelectionButtonPopover(): void {
    this.selectionButtonPopoverInstance.close();
  }

  private closeTooltip(): void {
    this.buttonTooltipInstance.close();
  }

  protected onClickDeleteButtonButton(): void {
    this.deleteButton();
    this.closeSelectionButtonPopover();
  }

  protected onClickOpenButtonButton(): void {
    this.openLink();
    this.closeSelectionButtonPopover();
  }

  protected onSubmitForm(event: SubmitEvent): void {
    event.stopPropagation();

    this.insertButton();
    this.closeSelectionButtonPopover();
  }

  protected openSelectionButtonPopover(): void {
    this.selectionButtonPopoverInstance.open();
  }

  private saveSelection(): void {
    this.selection = this.editor.getSelection(true);
  }

  private setSelectionButtonPopoverPosition(formatNode: HTMLElement): void {
    let width = formatNode.offsetWidth;
    let height = formatNode.offsetHeight;

    let rect = formatNode.getBoundingClientRect();
    let containerRect = this.editorComponentRef.nativeElement.getBoundingClientRect();

    let top = rect.top - containerRect.top + this.editorComponentRef.nativeElement.scrollTop;
    let left = rect.left - containerRect.left + this.editorComponentRef.nativeElement.scrollLeft;

    this.selectionButtonPopoverContainer.nativeElement.style.position = 'absolute';
    this.selectionButtonPopoverContainer.nativeElement.style.top = `${top + height}px`;
    this.selectionButtonPopoverContainer.nativeElement.style.left = `${left + width / 2}px`;
  }

  private resetActiveTab(): void {
    this.activeTab = 1;
  }
}
