import angular from 'angular';
import { firstValueFrom, Subject } from 'rxjs';
import {
  PLAN_CAPABILITIES,
  PLAN_CAPABILITIES_REASON,
} from '../../../../../app/http/plan-capability/plan-capability.constants';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { CHAT_BOT_ACTIONS_TYPES } from '../../../../../app/http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';
import { ChatBotActionMapper } from '../../../../../app/http/chat-bot/mappers/action.mapper';
import { ChatBotModel } from '../../../../../app/http/chat-bot/chat-bot.model';
import { JinjaService } from '../../../../../app/shared/services/jinja/jinja.service';

(function () {
  'use strict';

  angular.module('myApp.chatBot.telegramBot').controller('cqTelegramBotEditController', cqTelegramBotEditController);

  function cqTelegramBotEditController(
    $scope,
    $filter,
    $state,
    $stateParams,
    $timeout,
    $translate,
    $uibModal,
    $q,
    toastr,
    PANEL_URL,
    billingInfoModel,
    chatBotModel,
    defaultBotHelper,
    carrotquestHelper,
    planCapabilityHelper,
    planCapabilityModel,
    planFeatureAccessService,
    telegramBotStore,
    wizardHelper,
  ) {
    let vm = this;

    /** Инстанс тоста, предупреждающего о настройке триггера и интеграции для бота */
    let configureBotWarningToast;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.activateBot = changeBotStatus.bind(vm, true);
      vm.bot = vm.telegramBot ?? defaultBotHelper.getInitialBot(CHAT_BOT_TYPE.TELEGRAM);
      vm.chatBotSnapshot = angular.copy(vm.bot);
      vm.accessToTelegramBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TELEGRAM_BOTS,
        vm.currentApp,
        vm.activeChatBotsAmounts.amount,
      );
      vm.deletedBranches = [];
      vm.initUpdateBotFn = initUpdateBotFn;
      vm.updateBot = () => {};
      vm.isPreventHasAccessToTelegramBots = isPreventHasAccessToTelegramBots;
      vm.isCopying = $state.is('app.content.messagesAjs.telegramBot.edit.copy'); // Состояние копирования TG-бота
      vm.isEditing = $state.is('app.content.messagesAjs.telegramBot.edit');
      vm.firstStepOnExitCallback = () => {};
      vm.launchAndLeave = launchAndLeave;
      vm.onClickSaveBotButton = onClickSaveBotButton;
      vm.onValidationCallbackReady = onValidationCallbackReady;
      vm.pauseBot = changeBotStatus.bind(vm, false);
      vm.PLAN_CAPABILITIES = PLAN_CAPABILITIES;
      vm.planCapabilityForBanner = getPlanCapabilityForBanner();
      vm.planCapabilityHelper = planCapabilityHelper;
      vm.setBotSubject = new Subject();
      vm.setBot$ = vm.setBotSubject.asObservable();
      vm.save = save;
      vm.step = 1; // Текущий шаг создания/редактирования Telegram-бота
      vm.telegramIntegrationIdChange = (value) => $timeout(() => (vm.telegramIntegrationId = value), 0);
      vm.triggerTypesChange = (value) => $timeout(() => (vm.triggerTypes = value), 0);
      vm.validate = () => {};
      vm.validateTgIntegrationIdFn = () => {};
      vm.validateTriggerTypeFn = () => {};
      vm.wizard = null; //Визард

      wizardHelper.getWizard().then((wizard) => (vm.wizard = wizard));

      if (!vm.telegramBot) {
        vm.bot.name = $translate.instant('telegramBot.header.name');
      }

      // Отдельная переменная для имени нужна, его можно было изменить
      // Без нее это сделать не получится из-за связки Ajs A2+
      vm.botName = vm.bot.name;
      vm.triggerTypes = vm.bot.triggerTypes;

      const telegramIntegrationIdList = vm.telegramIntegrationList.map((integration) => integration.id);
      /** Обнулим интеграцию, если была выбрана интеграция, которая сейчас отключена или уже удалена */
      if (!telegramIntegrationIdList.includes(vm.bot.integration)) {
        vm.bot.integration = null;
      }
      vm.telegramIntegrationId = vm.bot.integration;

      if (vm.isCopying) {
        prepareTelegramBotCopy();
      }

      if (!vm.isEditing) {
        trackOpenCreatePage();
      } else {
        trackOpenEditPage();
      }

      ChatBotActionMapper.parseActionsKeyName(vm.bot.branches, vm.properties);
      if (isContainJinjaUserProps(vm.bot.branches)) {
        parseJinjaToProps(vm.bot.branches, vm.properties.userProps);
      }

      telegramBotStore.editableTelegramBot$.next(vm.bot);
      telegramBotStore.editableTelegramBotIntegrationId$.next(vm.telegramIntegrationId);
      telegramBotStore.telegramBotAllList$.next(vm.telegramBotAllList);
      telegramBotStore.telegramIntegrationList$.next(vm.telegramIntegrationList);

      $scope.$watchGroup(['vm.telegramIntegrationId', 'vm.triggerTypes'], (newValues) => {
        const telegramIntegrationId = newValues[0];
        const triggerTypes = newValues[1];

        if ((!telegramIntegrationId || !triggerTypes.length) && !configureBotWarningToast) {
          configureBotWarningToast = toastr.warning($translate.instant('telegramBot.toast.configureBot'), {
            extendedTimeOut: 0,
            tapToDismiss: false,
            timeOut: 0,
            preventDuplicates: true,
          });
        }

        if (telegramIntegrationId && triggerTypes.length) {
          toastr.clear(configureBotWarningToast);
          configureBotWarningToast = null;
        }
      });
    }

    function destroy() {
      toastr.clear(configureBotWarningToast);
      configureBotWarningToast = null;
    }

    /**
     * Меняет статус активности Telegram-бота
     * @param activateStatus
     * @returns {angular.IPromise<void[]>}
     */
    function changeBotStatus(activateStatus) {
      if (activateStatus && !vm.accessToTelegramBots.hasAccess) {
        return planCapabilityHelper.showPaywall(vm.currentApp, PLAN_CAPABILITIES.CHAT_BOTS_TOTAL);
      }

      if (activateStatus) {
        trackSetActiveBot();
      } else {
        trackSetPauseBot();
      }

      if (activateStatus) {
        const similarTriggerAndIntegrationBot = getSimilarTriggerAndIntegrationBot();

        if (similarTriggerAndIntegrationBot) {
          const modal = openAlreadyActivatedTelegramBotModal(similarTriggerAndIntegrationBot);

          return modal.result
            .then(() => save(activateStatus))
            .then(() => {
              similarTriggerAndIntegrationBot.active = !similarTriggerAndIntegrationBot.active;

              telegramBotStore.telegramBotAllList$.next(vm.telegramBotAllList);
            })
            .catch(() => {});
        }
      }

      save(activateStatus);
    }

    /**
     * Получение веток, которые изменились после последнего сохранения
     *
     * @return {Object}
     */
    function getChangedBotBranches() {
      const changedBot = angular.copy(vm.bot);
      changedBot.branches = [];

      for (let i = 0; i < vm.bot.branches.length; i++) {
        const branch = vm.bot.branches[i];

        const branchSnapshot = $filter('filter')(vm.chatBotSnapshot.branches, { id: branch.id }, true)[0]; // Ветка до сохранения

        //Если нет id => это новая ветка
        // или имя веток отличается
        // или количество действий изменилось
        // или изменились координаты положения
        // => ветка поменялась
        if (
          !branch.id ||
          branchSnapshot.name !== branch.name ||
          branch.actions.length !== branchSnapshot.actions.length ||
          isCoordinatesChanged(branch.coordinates, branchSnapshot.coordinates)
        ) {
          changedBot.branches.push({ ...branch });
          continue;
        }

        for (let k = 0; k < branch.actions.length; k++) {
          const action = branch.actions[k];
          const filterQuery = action.id ? { id: action.id } : { linkId: action.linkId };
          const actionBranchSnapshot = $filter('filter')(branchSnapshot.actions, filterQuery, true)[0]; // Действие ветки до сохранения

          //Если есть нет id => это новое действие
          // или body отличается
          // или key_name отличается
          // или у прикрепления нет ID
          // или отличается порядок
          // или изменлось свойство NextBranch
          // => ветка поменялась
          if (
            !action.id ||
            // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
            (action.body !== actionBranchSnapshot.body && action.body !== null && actionBranchSnapshot.body !== '') ||
            (!angular.equals(action.bodyJson, actionBranchSnapshot.bodyJson) && action.bodyJson !== null) ||
            (action.keyName !== actionBranchSnapshot.keyName &&
              // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
              action.keyName !== null &&
              actionBranchSnapshot.keyName !== '') ||
            ([CHAT_BOT_ACTIONS_TYPES.FILE, CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE].includes(action.type) &&
              !action.attachments[0]?.id) ||
            action.order !== actionBranchSnapshot.order ||
            action.nextBranchLinkId !== actionBranchSnapshot.nextBranchLinkId
          ) {
            changedBot.branches.push({ ...branch });
            break;
          }
        }
      }

      return changedBot;

      function isCoordinatesChanged(branchCoords, branchSnapshotCoords) {
        // Считам что что-то поменялось если
        // branchSnapshot не имеет координат, а branch их получила
        // или branchSnapshot и branch имеет координаты и они не совпадают
        return (
          (!branchSnapshotCoords && branchCoords) ||
          // костыль: "> 3" сделано потому, что там при отрисовке происходит небольшое смещение, которое я не хочу править)
          (branchSnapshotCoords && branchCoords && Math.abs(branchSnapshotCoords.x - branchCoords.x) > 3) ||
          Math.abs(branchSnapshotCoords.y - branchCoords.y) > 3
        );
      }
    }

    /**
     * Получение названия ограничения и причины показа баннера ограничений
     *
     * @returns {{reason: PLAN_CAPABILITIES_REASON, planCapability: PLAN_CAPABILITIES}}
     */
    function getPlanCapabilityForBanner() {
      let reason, planCapability;

      if (!planCapabilityModel.hasAccess(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL)) {
        planCapability = PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL;
        reason = PLAN_CAPABILITIES_REASON.NOT_AVAILABLE;
      }

      return {
        planCapability,
        reason,
      };
    }

    /** Получение ТГ-бота с такими же триггером и интеграцией */
    function getSimilarTriggerAndIntegrationBot() {
      return vm.telegramBotAllList.filter(
        (bot) =>
          bot.active &&
          bot.id !== vm.bot.id &&
          bot.triggerTypes[0] === vm.triggerTypes[0] &&
          bot.integration === vm.telegramIntegrationId,
      )[0];
    }

    /**
     * Получение ссылки на редактирование ТГ-бота
     *
     * @param {TelegramBot} telegramBot
     * @return {string}
     */
    function getTelegramBotUrl(telegramBot) {
      return `${PANEL_URL}/${vm.currentApp.id}/messages/telegram-bot/${telegramBot.id}/edit`;
    }

    /**
     * Проверка наличия Jinja-фильтров в содержимом ветках бота
     * @param {ChatBotBranch[]} branches Ветки бота
     */
    function isContainJinjaUserProps(branches) {
      return branches.some((branch) => {
        return branch.actions.some((action) => {
          if (action.type === CHAT_BOT_ACTIONS_TYPES.TEXT) {
            return JinjaService.isContainJinjaUserProps(action.body);
          }

          return false;
        });
      });
    }

    /**
     * Замена Jinja-фильтров на стилизованные бейджи со свойствами пользователя
     * @param {ChatBotBranch[]} branches Ветки бота
     * @param {UserProperty[]} userProps Свойства пользователя
     */
    function parseJinjaToProps(branches, userProps) {
      branches.forEach((branch) => {
        branch.actions.forEach((action) => {
          const isTextType = action.type === CHAT_BOT_ACTIONS_TYPES.TEXT;
          const isJinjaExist = JinjaService.isContainJinjaUserProps(action.body);

          if (isTextType && isJinjaExist) {
            action.body = JinjaService.parseJinjaToProps(action.body, userProps);
          }
        });
      });
    }

    /**
     * Нужно ли отменять работу accessToTelegramBots.hasAccess
     *
     * NOTE:
     *  Пользователь должен иметь возможность деактивировать telegram-бота,
     *  даже если у него нет доступа до фичи "Telegram-боты"
     *
     *  @return {boolean}
     */
    function isPreventHasAccessToTelegramBots() {
      if (vm.telegramBot?.id && vm.activeChatBotsAmounts.ids.includes(vm.telegramBot.id)) {
        return true;
      }

      return false;
    }

    /**
     * Инициализация функции по обновлению бота
     *
     * @param {Function} cb
     */
    function initUpdateBotFn(cb) {
      vm.updateBot = cb;
    }

    /**
     * Сливает бота полученного с сервера с текущим ботом
     *
     * @param {Object} chatBot - Чат-бот
     * @param {Object} mapIds - Сопоставление linkId и id
     */
    function mergeBotFromServer(chatBot, mapIds) {
      for (var linkId in mapIds) {
        var branch = $filter('filter')(vm.bot.branches, { linkId: linkId }, true)[0];

        branch.id = mapIds[linkId];

        branch.parentBranchIds.splice(-1, 1, branch.id);
      }

      for (var i = 0; i < vm.bot.branches.length; i++) {
        var branch = $filter('filter')(chatBot.branches, { id: vm.bot.branches[i].id }, true)[0];

        // Чтобы код ниже работал нормально, надо гарантировать, что в момент получения бота с созданными действиями
        // их количество и порядок был таким же как и в момент сохранения, т.е. пользователь не производил ни каких действий с действиями (сорян за тавтологию)
        // на текущий меомент это гарантируется лоадером
        // таким образом i-ое действие текущего бота и равно i-му действию бота, пришедшего с бекенда
        for (var g = 0; g < vm.bot.branches[i].actions.length; g++) {
          var action = vm.bot.branches[i].actions[g];
          action.id = branch.actions[g].id;

          if (ChatBotModel.isConnectionSourceAction(action.type) && action.nextBranchLinkId) {
            action.nextBranchId = branch.actions[g].nextBranchId;
          }
        }
      }
    }

    /**
     * Запустить и выходим к списку ботов
     */
    function launchAndLeave() {
      const activateStatus = true;

      const similarTriggerAndIntegrationBot = getSimilarTriggerAndIntegrationBot();

      if (similarTriggerAndIntegrationBot) {
        const modal = openAlreadyActivatedTelegramBotModal(similarTriggerAndIntegrationBot);

        return modal.result
          .then(() => save(activateStatus))
          .then(() => {
            similarTriggerAndIntegrationBot.active = !similarTriggerAndIntegrationBot.active;

            telegramBotStore.telegramBotAllList$.next(vm.telegramBotAllList);
          })
          .then(() => {
            vm.deletedBranches = [];
          })
          .catch(() => {});
      }

      save(activateStatus)
        .then(() => {
          vm.deletedBranches = [];
        })
        .catch(() => {});
    }

    /**
     * Обработчик клика по кнопке сохранения бота
     *
     * @param {boolean} statusToSave Статус активности, с которым нужно сохранить бота
     * @return {*}
     */
    function onClickSaveBotButton(statusToSave) {
      if (statusToSave) {
        const similarTriggerAndIntegrationBot = getSimilarTriggerAndIntegrationBot();

        if (similarTriggerAndIntegrationBot) {
          const modal = openAlreadyActivatedTelegramBotModal(similarTriggerAndIntegrationBot);

          return modal.result
            .then(() => save(statusToSave))
            .then(() => {
              similarTriggerAndIntegrationBot.active = !similarTriggerAndIntegrationBot.active;

              telegramBotStore.telegramBotAllList$.next(vm.telegramBotAllList);
            })
            .catch(() => {});
        }
      }

      save(statusToSave);
    }

    /**
     * Компонент с формой нового ангуляра по готовности отправляет callback, которым можно проверять валидность формы
     * @param cb { Function }
     */
    function onValidationCallbackReady(cb) {
      vm.firstStepOnExitCallback = cb;
    }

    /** Открытие confirm-модалки про уже активного ТГ-бота с такими же триггером и интеграцией */
    function openAlreadyActivatedTelegramBotModal(similarTriggerAndIntegrationBot) {
      return $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        size: 'md modal-dialog-centered',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('telegramBot.alreadyActiveTelegramBotModal.heading'),
              body: $translate.instant('telegramBot.alreadyActiveTelegramBotModal.body', {
                alreadyActiveTelegramBotName: similarTriggerAndIntegrationBot.name,
                alreadyActiveTelegramBotUrl: getTelegramBotUrl(similarTriggerAndIntegrationBot),
              }),
              confirmButtonText: $translate.instant('telegramBot.alreadyActiveTelegramBotModal.confirmButtonText'),
            };
          },
        },
      });
    }

    /** Подготовка TG-бота к копированию */
    function prepareTelegramBotCopy() {
      delete vm.telegramBot.id;
      vm.telegramBot.name = `${vm.telegramBot.name} (${$translate.instant('chatBot.edit.copy')})`;

      vm.telegramBot.startBranchLinkId = vm.telegramBot.startBranchId;
      vm.telegramBot.interruptBranchLinkId = vm.telegramBot.interruptBranchId;
      vm.telegramBot.startBranchId = null;
      vm.telegramBot.interruptBranchId = null;

      for (let i = 0; i < vm.telegramBot.branches.length; i++) {
        const branch = vm.telegramBot.branches[i];
        branch.linkId = branch.id;
        branch.id = null;

        for (let g = 0; g < vm.telegramBot.branches[i].actions.length; g++) {
          const action = vm.telegramBot.branches[i].actions[g];
          action.linkId = action.id;
          delete action.id;

          if (ChatBotModel.isConnectionSourceAction(action.type)) {
            action.nextBranchLinkId = action.nextBranchId;
            action.nextBranchId = null;
          }
        }
      }
    }

    /**
     * Функция сохранения бота
     * @param {boolean} activateStatus
     */
    function save(activateStatus) {
      // Надо ВСЕГДА выставлять allowUserReplies значение true
      vm.bot.allowUserReplies = true;

      const validators = [];

      validators.push(vm.firstStepOnExitCallback('save'));

      if (activateStatus) {
        const isIntegrationIdValid = vm.validateTgIntegrationIdFn().then((valid) => {
          return valid ? Promise.resolve() : Promise.reject();
        });

        const isTriggerTypeValid = vm.validateTriggerTypeFn().then((valid) => {
          return valid ? Promise.resolve() : Promise.reject();
        });

        validators.push(isIntegrationIdValid, isTriggerTypeValid);
      }

      return Promise.all(validators)
        .then(vm.isEditing ? saveTelegramBot : createTelegramBot)
        .then((response) => {
          return vm.isEditing ? saveSuccess(response) : createSuccess(response);
        })
        .catch(saveOrCreateError)
        .finally(saveOrCreateFinally);

      function saveTelegramBot() {
        trackSaveBot();
        vm.isRequestPerforming = true;
        const botChanges = getChangedBotBranches();
        return firstValueFrom(
          chatBotModel.saveTelegramBot(
            vm.currentApp.id,
            {
              ...botChanges,
              active: activateStatus,
              name: vm.botName,
              triggerTypes: vm.triggerTypes,
              integration: vm.telegramIntegrationId,
            },
            vm.deletedBranches,
          ),
        );
      }

      function createTelegramBot() {
        if (activateStatus) {
          trackCreateAndSetActive();
        } else {
          trackCreateAndSetPaused();
        }
        vm.isRequestPerforming = true;
        return firstValueFrom(
          chatBotModel.createTelegramBot(vm.currentApp.id, {
            ...vm.bot,
            active: activateStatus,
            name: vm.botName,
            triggerTypes: vm.triggerTypes,
            integration: vm.telegramIntegrationId,
          }),
        );
      }

      function saveSuccess(response) {
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        mergeBotFromServer(response.chatBot, response.mapIds);
        vm.telegramBot.active = response.chatBot.active;
        vm.chatBotSnapshot = angular.copy(vm.bot);
        vm.deletedBranches = [];
        vm.setBotSubject.next({ chatBot: vm.bot, mapIds: response.mapIds, doNotRedrawCanvas: true });
        return $q.resolve();
      }

      function createSuccess(response) {
        $state.go('app.content.messagesAjs.telegramBot.edit', {
          telegramBotId: response.chatBot.id,
        });
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        return $q.resolve();
      }

      function saveOrCreateError() {
        return $q.reject();
      }

      function saveOrCreateFinally() {
        vm.isRequestPerforming = false;
      }
    }

    /**
     * Трек открытия страницы создания
     */
    function trackOpenCreatePage() {
      carrotquestHelper.track('Telegram Welcome бот - перешел на страницу создания');
    }

    /**
     * Трек открытия страницы редактирования
     */
    function trackOpenEditPage() {
      carrotquestHelper.track('Telegram Welcome бот - зашел на страницу редактирования');
    }

    /**
     * Трек создания бота в статусе активен
     */
    function trackCreateAndSetActive() {
      carrotquestHelper.track('Telegram Welcome бот - создал и запустил');
    }

    /**
     * Трек создания бота в статусе приостановлен
     */
    function trackCreateAndSetPaused() {
      carrotquestHelper.track('Telegram Welcome бот - создал, но не запустил');
    }

    /**
     * Трек сохранения бота
     */
    function trackSaveBot() {
      carrotquestHelper.track('Telegram Welcome бот - сохранил изменения');
    }

    /**
     * Трек смены статуса бота на "Активен"
     */
    function trackSetActiveBot() {
      carrotquestHelper.track('Telegram Welcome бот - активировал');
    }

    /**
     * Трек смены статуса бота на "Приостановлен"
     */
    function trackSetPauseBot() {
      carrotquestHelper.track('Telegram Welcome бот - приостановил');
    }
  }
})();
