<ng-container *transloco="let t; read: 'contentBlocksComponent'">
  <ng-container *ngFor="let content of control.controls; index as i">
    <cq-block-header
      class="mb-5"
      (delete)="deleteContentBlock(i)"
      (moveUp)="moveBlock(i, i - 1)"
      (moveDown)="moveBlock(i, i + 1)"
      (duplicate)="copyBLock(i)"
      [isDuplicateDisabled]="!isAllowToAddContentBlock"
      [blockType]="content.value.type"
      [index]="i"
      [lastIndex]="control.controls.length - 1"
    ></cq-block-header>

    <ng-container [ngSwitch]="content.value.type">
      <cq-text-block-editor
        class="mb-20"
        *ngSwitchCase="'text'"
        [formControl]="content"
        (delete)="deleteContentBlock(i)"
      ></cq-text-block-editor>

      <cq-media-blocks-editor
        class="mb-20"
        *ngSwitchCase="'file'"
        [formControl]="content"
        (delete)="deleteContentBlock(i)"
      ></cq-media-blocks-editor>

      <cq-media-blocks-editor
        class="mb-20"
        *ngSwitchCase="'video_note'"
        [formControl]="content"
        (delete)="deleteContentBlock(i)"
      ></cq-media-blocks-editor>
    </ng-container>
  </ng-container>

  <!--Кнопки добавления блоков контента-->
  <div class="mb-20 ml-n5 mr-n5 row">
    <div
      class="col-4 px-5"
      *ngFor="let type of contentTypeList"
    >
      <button
        class="btn btn-secondary btn-block"
        type="button"
        (click)="addContentBlock(type)"
        [disabled]="!isAllowToAddContentBlock"
      >
        <i
          class="btn-icon-left cqi-sm"
          [ngClass]="contentIconsMap[type]"
        ></i>
        <span> {{ t('addContentButton.' + type) }} </span>
      </button>
    </div>
  </div>
</ng-container>
