<ng-container *transloco="let t; read: 'botsListComponent'">
  <h4>{{ t('heading') }}</h4>
  <ng-container *ngIf="chatBots.length !== 0; else zeroData">
    <div class="text-secondary mb-10">{{ t('text') }}</div>
    <div class="margin-between-rows-10 mb-15">
      <ng-container *ngFor="let bot of chatBots; trackBy: trackByFn">
        <ng-container *tuiLet="getChatBotBranches(bot) as chatBotBranches">
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-robot text-primary"></i>
            <div class="ml-5">
              <a
                class="font-weight-bold"
                uiSref="app.content.messagesAjs.chatBot.edit"
                [uiParams]="{ chatBotId: bot.id }"
                target="_blank"
                >{{ bot.name }}</a
              >,
              {{ t('blocks', { blocks: chatBotBranches }) }}
              {{ getFormattedBlocksNames(chatBotBranches) }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <a
      [href]="t('instructionLink.href')"
      target="_blank"
    >
      {{ t('instructionLink.text') }} <i class="cqi-sm cqi-external-link"></i>
    </a>
  </ng-container>
  <ng-template #zeroData>
    <div class="mb-15">
      <span>{{ t('zeroData.text') }}</span>
      <a
        [href]="t('zeroData.link.href')"
        target="_blank"
        >{{ t('zeroData.link.text') }} <i class="cqi-sm cqi-external-link"></i
      ></a>
    </div>
    <a
      class="btn btn-secondary"
      type="button"
      uiSref="app.content.messagesAjs.chatBot"
      target="_blank"
    >
      <i class="btn-icon-left cqi-sm cqi-external-link"></i>
      {{ t('zeroData.goToChatBotsButton') }}
    </a>
  </ng-template>
</ng-container>
