import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TuiLet } from '@taiga-ui/cdk';
import { SortablejsModule } from 'nxt-sortablejs';

import { EditSavedReplyModalModule } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/edit-saved-reply-modal/edit-saved-reply-modal.module';
import { SavedRepliesListComponent } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/list/saved-replies-list.component';
import { SharedModule } from '@panel/app/shared/shared.module';

import { SavedRepliesComponent } from './saved-replies.component';

@NgModule({
  declarations: [SavedRepliesComponent, SavedRepliesListComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    FormsModule,
    EditSavedReplyModalModule,
    ReactiveFormsModule,
    TuiLet,
    SortablejsModule,
    NgbTooltip,
  ],
  exports: [SavedRepliesComponent],
})
export class SavedRepliesModule {}
