<ng-container *transloco="let t; read: 'faqComponent'">
  <ng-container *tuiLet="subscriptionStore.billing$ | async as billing">
    <h4 class="mb-15 font-weight-bold">{{ t('title') }}</h4>

    <div
      class="accordion"
      ngbAccordion
    >
      <div
        ngbAccordionItem
        #ngbAccordionItem1="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem1.collapsed"
          (click)="ngbAccordionItem1.expand(); trackClickQuestion(1)"
        >
          <b>{{ t('items.item1.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>{{ t('items.item1.answer.part1.content') }}</div>
          <div>
            {{
              t('items.item1.answer.part2.content', {
                costOverspendingUniqueUserPerOne: costOverspendingUniqueUserPerOne | toMoney | toFormattedMoney,
              })
            }}
          </div>
        </cq-item-content>
      </div>

      <div
        class="card"
        ngbAccordionItem
        #ngbAccordionItem2="ngbAccordionItem"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem2.collapsed"
          (click)="ngbAccordionItem2.expand(); trackClickQuestion(2)"
        >
          <b>{{ t('items.item2.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>
            <div>{{ t('items.item2.answer.part1.title') }}</div>
            <ul class="pl-15 mb-0">
              <li>{{ t('items.item2.answer.part1.list.item1') }}</li>
              <li>{{ t('items.item2.answer.part1.list.item2') }}</li>
              <li>{{ t('items.item2.answer.part1.list.item3') }}</li>
              <li>{{ t('items.item2.answer.part1.list.item4') }}</li>
            </ul>
          </div>
          <div>
            {{
              t('items.item2.answer.part2.content', {
                costOverspendingEmailPerOne: costOverspendingEmailPerOne | toMoney | toFormattedMoney,
              })
            }}
          </div>
        </cq-item-content>
      </div>

      <div
        *ngIf="isRusCountry && billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem3="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem3.collapsed"
          (click)="ngbAccordionItem3.expand(); trackClickQuestion(3)"
        >
          <b>{{ t('items.item3.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>
            <div>
              <b>{{ t('items.item3.answer.part1.title') }}</b>
            </div>
            <div [innerHTML]="t('items.item3.answer.part1.content')"></div>
          </div>
          <div>
            <div>
              <b>{{ t('items.item3.answer.part2.title') }}</b>
            </div>
            <div [innerHTML]="t('items.item3.answer.part2.content', { emailSupport })"></div>
          </div>
          <div>
            <div>
              <b>{{ t('items.item3.answer.part3.title') }}</b>
            </div>
            <div [innerHTML]="t('items.item3.answer.part3.content', { emailSupport })"></div>
          </div>
          <div>
            <a
              [href]="t('items.item3.answer.part4.link.href')"
              target="_blank"
            >
              {{ t('items.item3.answer.part4.link.text') }}
            </a>
          </div>
        </cq-item-content>
      </div>

      <div
        ngbAccordionItem
        #ngbAccordionItem4="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem4.collapsed"
          (click)="ngbAccordionItem4.expand(); trackClickQuestion(4)"
        >
          <b>{{ t('items.item4.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div [innerHTML]="t('items.item4.answer.part1.content')"></div>
          <div [innerHTML]="t('items.item4.answer.part2.content', { nameProject })"></div>
          <div [innerHTML]="t('items.item4.answer.part3.content')"></div>

          <ng-container *ngIf="isRusCountry">
            <a
              [href]="t('items.item4.answer.part4.link.href')"
              target="_blank"
            >
              {{ t('items.item4.answer.part4.link.text') }}
            </a>
          </ng-container>
        </cq-item-content>
      </div>

      <div
        ngbAccordionItem
        #ngbAccordionItem5="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem5.collapsed"
          (click)="ngbAccordionItem5.expand(); trackClickQuestion(5)"
        >
          <b>{{ t('items.item5.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div [innerHTML]="t('items.item5.answer.part1.content')"></div>
          <div [innerHTML]="t('items.item5.answer.part2.content')"></div>
        </cq-item-content>
      </div>

      <div
        *ngIf="isRusCountry"
        ngbAccordionItem
        #ngbAccordionItem6="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem6.collapsed"
          (click)="ngbAccordionItem6.expand(); trackClickQuestion(6)"
        >
          <b>{{ t('items.item6.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>
            <div>{{ t('items.item6.answer.part1.title') }}</div>
            <ul class="pl-15 mb-0">
              <li>{{ t('items.item6.answer.part1.list.item1') }}</li>
              <li>{{ t('items.item6.answer.part1.list.item2') }}</li>
              <li>{{ t('items.item6.answer.part1.list.item3') }}</li>
            </ul>
          </div>
          <div>
            <div>{{ t('items.item6.answer.part2.title') }}</div>
            <ol class="pl-15 mb-0">
              <li>{{ t('items.item6.answer.part2.list.item1') }}</li>
              <li>{{ t('items.item6.answer.part2.list.item2', { nameProject }) }}</li>
              <li>{{ t('items.item6.answer.part2.list.item3') }}</li>
            </ol>
          </div>
          <a
            [href]="t('items.item6.answer.part3.link.href')"
            target="_blank"
          >
            {{ t('items.item6.answer.part3.link.text') }}
          </a>
        </cq-item-content>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem7="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem7.collapsed"
          (click)="ngbAccordionItem7.expand(); trackClickQuestion(7)"
        >
          <b>{{ t('items.item7.question') }}</b>
        </cq-item-header>
        <ng-template ngbAccordionCollapse>
          <cq-item-content>
            <div>{{ t('items.item7.answer.part1.content', { nameProject }) }}</div>
            <div>{{ t('items.item7.answer.part2.content', { nameProject }) }}</div>
          </cq-item-content>
        </ng-template>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem8="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem8.collapsed"
          (click)="ngbAccordionItem8.expand(); trackClickQuestion(8)"
        >
          <b>{{ t('items.item8.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>{{ t('items.item8.answer.part1.content', { nameProject }) }}</div>
          <div>{{ t('items.item8.answer.part2.content') }}</div>
          <div>{{ t('items.item8.answer.part3.content') }}</div>
        </cq-item-content>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem9="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem9.collapsed"
          (click)="ngbAccordionItem9.expand(); trackClickQuestion(9)"
        >
          <b>{{ t('items.item9.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>{{ t('items.item9.answer.part1.content') }}</div>
          <div [innerHTML]="t('items.item9.answer.part2.content')"></div>

          <ng-container *ngIf="isRusCountry">
            <a
              [href]="t('items.item9.answer.part3.link.href')"
              target="_blank"
            >
              {{ t('items.item9.answer.part3.link.text') }}
            </a>
          </ng-container>
        </cq-item-content>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem10="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem10.collapsed"
          (click)="ngbAccordionItem10.expand(); trackClickQuestion(10)"
        >
          <b>{{ t('items.item10.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>{{ t('items.item10.answer.part1.content') }}</div>

          <div>
            <div>
              <b>{{ t('items.item10.answer.part2.title') }}</b>
            </div>
            <div>{{ t('items.item10.answer.part2.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item10.answer.part3.title') }}</b>
            </div>
            <div>{{ t('items.item10.answer.part3.content') }}</div>
          </div>

          <ng-container *ngIf="isRusCountry">
            <div>{{ t('items.item10.answer.part4.content') }}</div>
            <a
              [href]="t('items.item10.answer.part5.link.href')"
              target="_blank"
            >
              {{ t('items.item10.answer.part5.link.text') }}
            </a>
          </ng-container>
        </cq-item-content>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem11="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem11.collapsed"
          (click)="ngbAccordionItem11.expand(); trackClickQuestion(11)"
        >
          <b>{{ t('items.item11.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>
            <div [innerHTML]="t('items.item11.answer.part1.content', { nameProject })"></div>
          </div>
        </cq-item-content>
      </div>

      <div
        *ngIf="billing && billing.subscription.isActive"
        ngbAccordionItem
        #ngbAccordionItem12="ngbAccordionItem"
        class="card"
      >
        <cq-item-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem12.collapsed"
          (click)="ngbAccordionItem12.expand(); trackClickQuestion(12)"
        >
          <b>{{ t('items.item12.question') }}</b>
        </cq-item-header>
        <cq-item-content ngbAccordionCollapse>
          <div>
            <div>
              <b>{{ t('items.item12.answer.part1.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part1.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part2.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part2.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part3.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part3.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part4.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part4.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part5.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part5.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part6.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part6.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part7.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part7.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part8.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part8.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part9.title') }}</b>
            </div>
            <div>{{ t('items.item12.answer.part9.content') }}</div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part10.title') }}</b>
            </div>
            <div [innerHTML]="t('items.item12.answer.part10.content', { emailSupport })"></div>
          </div>

          <div>
            <div>
              <b>{{ t('items.item12.answer.part11.title') }}</b>
              <div>{{ t('items.item12.answer.part11.list.item1') }}</div>
              <div>{{ t('items.item12.answer.part11.list.item2') }}</div>
              <div>{{ t('items.item12.answer.part11.list.item3') }}</div>
              <ng-container *ngIf="isRusCountry">
                <div>{{ t('items.item12.answer.part11.list.item4') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item5') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item6') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item7') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item8') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item9') }}</div>
              </ng-container>
            </div>
          </div>
        </cq-item-content>
      </div>
    </div>
  </ng-container>
</ng-container>
