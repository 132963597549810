import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AiDataModel } from '@http/ai-data/ai-data.model';
import { AI_DATA_CRAWLING_STATUS } from '@http/ai-data/internal-types';
import { AppService } from '@http/app/services/app.service';
import { ArticleCategory } from '@http/article-category/internal-types/article-category.internal.type';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { AiDataActionModalService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { DestroyService, OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { WebSocketService } from '@panel/app/services/web-socket/web-socket.service';

@Component({
  selector: 'cq-ai-data-source-list',
  templateUrl: './ai-data-source-list.component.html',
  styleUrls: ['./ai-data-source-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, WebSocketService],
})
export class AiDataSourceListComponent implements OnInit {
  @Input()
  knowledgeBaseCategories: ArticleCategory[] = [];

  hasAccessToWebCrawler: boolean = this.featureModel.hasAccess(FEATURES_ONLY.WEB_CRAWLER);

  isShowSettingsBanner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    !this.oneTimeFlagHelper.isSet(ONE_TIME_FLAGS.SHOW_AI_DATA_SETTINGS_BANNER),
  );

  constructor(
    private readonly aiDataModel: AiDataModel,
    private readonly aiDataActionModalService: AiDataActionModalService,
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly featureModel: FeatureModel,
    private readonly oneTimeFlagHelper: OneTimeFlagService,
    readonly state: AiDataSourcesListStateService,
    private readonly webSocketService: WebSocketService,
  ) {}

  ngOnInit() {
    this.state.knowledgeBaseCategories$.next(this.knowledgeBaseCategories);

    if (this.hasAccessToWebCrawler) {
      this.webSocketService
        .connect(`app_crawl_finished.${this.appService.currentAppId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.state.isShowCrawlingBanner$.next(true);
          this.state.crawlingStatus$.next(AI_DATA_CRAWLING_STATUS.FINISHED);
        });

      this.checkCrawlingStatus();
    }
  }

  /** Если в данный момент происходит краулинг, то нужно показать баннер об этом */
  checkCrawlingStatus() {
    this.aiDataModel
      .getCrawlingResult()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.state.isShowCrawlingBanner$.next(true);
          this.state.crawlingUrl$.next(response.result.startUrl);
          this.state.crawlingStatus$.next(response.result.status);
        },
        error: () => {
          this.state.isShowCrawlingBanner$.next(false);
          this.state.crawlingUrl$.next(null);
          this.state.crawlingStatus$.next(null);
        },
      });
  }

  closeSettingsBanner(): void {
    this.oneTimeFlagHelper.set(ONE_TIME_FLAGS.SHOW_AI_DATA_SETTINGS_BANNER);
    this.isShowSettingsBanner$.next(false);
  }

  onClickCloseBtn() {
    this.state.isShowCrawlingBanner$.next(false);
  }

  openStartCrawlingProcessModal() {
    this.aiDataActionModalService.openStartCrawlingProcessModal();
  }
}
