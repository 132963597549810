import { firstValueFrom } from 'rxjs';
import { FEATURES } from '../../../../app/http/feature/feature.constants';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { MESSAGE_TYPES } from '../../../../app/http/message/message.constants';
import {
  PLAN_FEATURE,
  PLAN_FEATURE_BY_MESSAGE_PART_TYPE,
} from '../../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.scheduledMessages')
    .controller('CqScheduledMessageEditorController', CqScheduledMessageEditorController);

  function CqScheduledMessageEditorController(
    $filter,
    $q,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    $uibModal,
    $window,
    moment,
    toastr,
    appModel,
    carrotquestHelper,
    caseStyleHelper,
    eventTypeModel,
    featureModel,
    filterAjsModel,
    l10nHelper,
    messageModel,
    messagePartModel,
    paywallService,
    utmMarkModel,
    planFeatureAccessService,
    wizardHelper,
  ) {
    let vm = this;

    let originalMessageParts = [];
    let isMessageSaved = false; // Было ли сохранено сообщение. Используется для показа модального окна об уходе в другой раздел
    const beforeunloadListener = (event) => {
      const returnValue = $translate.instant('autoMessages.editor.confirmExitModal.body');

      event.preventDefault();
      event.returnValue = returnValue;
      return returnValue;
    };

    vm.$onInit = init;

    function init() {
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;

      vm.step = 1; // текущий шаг создания/редактирования триггерного сообщения
      vm.contentForm = null;
      vm.filtersForm = null;
      vm.messagePart = vm.message.parts[0];
      vm.submitRequestPerforming = false;
      vm.wizard = null;
      vm.validators = {
        scheduleSettings: null,
        maxUsersCount: null,
        repeatDelay: null,
      };

      vm.eventMessage2 = !!vm.message.eventRead;
      vm.eventMessage3 = !!vm.message.eventReplied;
      vm.eventMessage4 = !!vm.message.eventClicked;
      vm.eventMessage5 = !!vm.message.eventUnsubscribed;

      vm.getDenialReasonsForCreateAndStart = getDenialReasonsForCreateAndStart;
      vm.hasDenialReasonsForCreateAndStart = hasDenialReasonsForCreateAndStart;
      vm.isPreventHasDenialReasonsForCreateAndStart = isPreventHasDenialReasonsForCreateAndStart;
      vm.processMessagePartForms = processMessagePartForms;
      vm.toggleAllEventsForChainsInDefaultState = toggleAllEventsForChainsInDefaultState;
      vm.isAllEventsForChainsInDefaultState = isAllEventsForChainsInDefaultState;
      vm.getJsCount = getJsCount;
      vm.getWebhookCount = getWebhookCount;
      vm.createEventCustomName = createEventCustomName;
      vm.getCssClassForCreateAndStartLaterButton = getCssClassForCreateAndStartLaterButton;
      vm.createScheduledMessage = createScheduledMessage;
      vm.updateScheduledMessage = updateScheduledMessage;
      vm.changeMessageStatus = changeMessageStatus;
      vm.createCopy = createCopy;
      vm.openDenialReasonBillingModal = openDenialReasonBillingModal;
      vm.hasDenialReasonsForActivatedAndPause = hasDenialReasonsForActivatedAndPause;
      vm.hasDenialReasonsForSave = hasDenialReasonsForSave;
      vm.onChangeMessagePartType = onChangeMessagePartType;

      vm.accessToAutoMessages = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
        vm.currentApp,
        vm.activeMessagesAmounts,
      );
      vm.accessToAutoMessagesType = planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][vm.message.type],
        vm.currentApp,
      );
      vm.accessToAutoMessagesEventsForChains = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_EVENTS_FOR_CHAINS,
        vm.currentApp,
      );
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.currentApp,
      );
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);
      vm.accesses = [
        vm.accessToAutoMessages,
        vm.accessToAutoMessagesType,
        vm.accessToAutoMessagesEventsForChains,
        vm.accessToEventsEventTypesCustom,
        vm.accessToUsersCustomProperties,
        vm.accessToUsersTags,
      ];

      wizardHelper.getWizard().then((wizard) => (vm.wizard = wizard));

      if ($state.is('app.content.scheduledMessages.edit.copy')) {
        preparingCreateCopy();
      }
    }

    /**
     * Нужно ли отменять работу hasDenialReasonsForCreateAndStart
     *
     * NOTE:
     *  Пользователь должен иметь возможность деактивировать триггерное сообщение,
     *  даже если у него нет доступа до фичи "Триггерные сообщения"
     *
     *  @return {boolean}
     */
    function isPreventHasDenialReasonsForCreateAndStart() {
      return vm.message.active;
    }

    /**
     * Есть ли причины отказа в доступе для создания и запуска триггерного сообщения
     *
     * @return {boolean}
     */
    function hasDenialReasonsForCreateAndStart() {
      const denialReasons = getDenialReasonsForCreateAndStart();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Получение причин отказа в доступе до создания и запуска триггерного сообщения
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForCreateAndStart() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [];
      }
    }

    /**
     * Проверка валидности формы
     */
    function isFormValid(form) {
      if (angular.isDefined(form)) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        } else {
          return $q.resolve();
        }
      } else {
        return $q.resolve();
      }
    }

    /**
     * Обработка форм всех вариантов сообщений.
     * Это сделано потому, что ng-form (в которую обёрнуты все варианты триггерного сообщения) нельзя сабмитить, поэтому это надо сделать искусственно на submit родительской формы
     
     */
    function processMessagePartForms(form, messageParts) {
      return isFormValid(form).catch(isFormValidError).then(toggleUtmMarks);

      function isFormValidError() {
        // если форма с вариантами сообщения оказалась невалидна - значит какой-то вариант(ы) невалиден
        // индекс messagePart совпадает с индексом формы для messagePart
        // поэтому можно легко свернуть валидные и развернуть невалидные варианты
        for (let i = 0; i < messageParts.length; i++) {
          messageParts[i].showContent = vm.contentForm.$getControls()[i].$invalid;
          messageParts[i].handleError && messageParts[i].handleError();
        }

        return $q.reject();
      }

      /**
       * Отключение UTM меток, если в них ничего не заполнено, и заполнение их нашими стандартными значениями
       */
      function toggleUtmMarks() {
        for (let i = 0; i < messageParts.length; i++) {
          if (
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled &&
            utmMarkModel.isEmpty(messageParts[i][MESSAGE_PART_TYPES.EMAIL].utmMarks)
          ) {
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = false;
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].utmMarks = utmMarkModel.getPredefined();
          }
        }
      }
    }

    /**
     * Callback при смене типа варианта сообщения
     *
     * @param {MESSAGE_PART_TYPES} messagePartType
     */
    function onChangeMessagePartType({ messageType }) {
      vm.accessToAutoMessagesType = planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][messageType],
        vm.currentApp,
      );
    }

    /**
     * Отдельная функция валидации для второго шага
     * Нужна из-за того, что есть случаи, когда второй шаг не валиден, но пользователю всё равно надо дать перейти на другие шаги
     */
    function validate(form) {
      const areNewComponentsValid = Promise.all(
        Object.values(vm.validators).map((touchAndValidate) => {
          return touchAndValidate();
        }),
      ).then(([...args]) => {
        return args.every((valid) => valid) ? Promise.resolve() : Promise.reject();
      });

      return Promise.all([areNewComponentsValid, isFormValid(vm.filtersForm)]);
    }

    /** Переключение всех событий для цепочек сообщений в состояние по умолчанию */
    function toggleAllEventsForChainsInDefaultState() {
      vm.message.eventMessage2 = false;
      vm.message.eventMessage3 = false;
      vm.message.eventMessage4 = false;
      vm.message.eventMessage5 = false;
    }

    /**
     * Все ли события для цепочек сообщения в состоянии по умолчанию
     *
     * @return {Boolean}
     */
    function isAllEventsForChainsInDefaultState() {
      return [
        vm.message.eventMessage2,
        vm.message.eventMessage3,
        vm.message.eventMessage4,
        vm.message.eventMessage5,
      ].every((value) => value === false);
    }

    /**
     * Получение количества JS-скриптов среди вариантов сообщения
     *
     * @param {Array.<Object>} messageParts Варианты сообщения
     */
    function getJsCount(messageParts) {
      return messagePartModel.filterByMessagePartType(messageParts, MESSAGE_PART_TYPES.JS).length;
    }

    /**
     * Получение количества веб хуков среди вариантов сообщения
     *
     * @param {Array.<Object>} messageParts Варианты сообщения
     */
    function getWebhookCount(messageParts) {
      return messagePartModel.filterByMessagePartType(messageParts, MESSAGE_PART_TYPES.WEBHOOK).length;
    }

    /**
     * Автоматическое создание кастомного имени события eventName для цепочек сообщений
     *
     * @param {String} eventName Название события
     */
    function createEventCustomName(eventName) {
      if (!vm.message[eventName]) {
        let name = $translate.instant('autoMessages.editor.eventCustomNames.' + eventName);
        let id = 0;
        switch (eventName) {
          case 'eventRead':
            id = 2;
            break;
          case 'eventReplied':
            id = 3;
            break;
          case 'eventClicked':
            id = 4;
            break;
          case 'eventUnsubscribed':
            id = 5;
            break;
        }

        if (!vm.message[eventName] && vm['eventMessage' + id]) {
          vm.message[eventName] = name + (vm.message.name ? ' - ' + vm.message.name : '');
        }
      }
    }

    /**
     * Получение css-класса для кнопки "Создать и запустить позже"
     *
     * @return {Object}
     */
    function getCssClassForCreateAndStartLaterButton() {
      let exceededMessageLimit = !planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
        vm.currentApp,
        vm.activeMessagesAmounts,
      ).hasAccess;
      let hasAccessToMessagePartType = !planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][vm.messagePart.type],
        vm.currentApp,
      ).hasAccess;
      return {
        'btn-outline-primary': !hasAccessToMessagePartType && !exceededMessageLimit,
        'btn-primary': hasAccessToMessagePartType || exceededMessageLimit,
      };
    }

    /**
     * Получение причин отказа в доступе до сохранения триггерного сообщения
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForSave() {
      switch (true) {
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [];
      }
    }

    /**
     * Получение причины отказа доступа к фиче для биллинговой модалки
     *
     * Сделан отдельный метод т.к. модалка showAutoMessageTotalPaywall не расчитана что кроме ограничений по количеству будут другие ограничения
     *
     * @return {null|ProductFeatureDenialReason}
     */
    function getDenialReasonForBillingModal() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        case !vm.accessToEventsEventTypesCustom.hasAccess &&
          $filter('filter')(vm.message.filters.filters.events, { eventType: { name: '!$' } }, false).length > 0:
          return vm.accessToEventsEventTypesCustom.denialReason;
        case !vm.accessToUsersCustomProperties.hasAccess &&
          $filter('filter')(vm.message.filters.filters.props, { userProp: { groupOrder: 5 } }, false).length > 0:
          return vm.accessToUsersCustomProperties.denialReason;
        case !vm.accessToUsersTags.hasAccess && vm.message.filters.filters.tags.length > 0:
          return vm.accessToUsersTags.denialReason;
        default:
          return null;
      }
    }

    /**
     * Получение причин отказа в доступе
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForActivateAndPause() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [vm.accessToEventsEventTypesCustom, vm.accessToUsersCustomProperties, vm.accessToUsersTags]
            .filter((access) => !access.hasAccess)
            .filter((access) => {
              switch (access) {
                case vm.accessToEventsEventTypesCustom:
                  return (
                    $filter('filter')(vm.message.filters.filters.events, { eventType: { name: '!$' } }, false).length >
                    0
                  );
                case vm.accessToUsersCustomProperties:
                  return (
                    $filter('filter')(vm.message.filters.filters.props, { userProp: { groupOrder: 5 } }, false).length >
                    0
                  );
                case vm.accessToUsersTags:
                  return vm.message.filters.filters.tags.length > 0;
                default:
                  return true;
              }
            })
            .map((access) => access.denialReason);
      }
    }

    /**
     * Открыть одно из биллинговых модальных сообщений
     */
    function openDenialReasonBillingModal() {
      const denialReason = getDenialReasonForBillingModal();

      if (denialReason.productFeature === PLAN_FEATURE.AUTO_MESSAGES_TOTAL) {
        return vm.paywallService.showAutoMessageTotalPaywall(vm.currentApp, denialReason, 1, vm.activeMessagesAmounts);
      }

      vm.paywallService.showPaywallForAccessDenial(vm.currentApp, denialReason);
    }

    /**
     * Есть ли причины отказа в доступе для активации и паузы триггерного сообщения
     *
     * @return {boolean}
     */
    function hasDenialReasonsForActivatedAndPause() {
      const denialReasons = getDenialReasonsForActivateAndPause();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Есть ли причины отказа в доступе для сохранения триггерного сообщения
     *
     * Идет проверка по типу сообщения.
     * Нельзя сохранять активное сообщение с недоступным типом
     *
     * @return {boolean}
     */
    function hasDenialReasonsForSave() {
      const denialReasons = getDenialReasonsForSave();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Установка статуса триггерному сообщению
     */
    function changeMessageStatus() {
      if (!vm.message.active) {
        validate()
          .then(() => {
            return firstValueFrom(messageModel.patchScheduledMessage(vm.message.id, { active: true }));
          })
          .then((_) => {
            $timeout(() => (vm.message.active = true));
          });
      } else {
        firstValueFrom(messageModel.patchScheduledMessage(vm.message.id, { active: false })).then((_) => {
          $timeout(() => (vm.message.active = false));
        });
      }
    }

    function createScheduledMessage(active) {
      validate().then(() => {
        vm.message.active = active;
        firstValueFrom(messageModel.createScheduledMessage(vm.message)).then(({ id }) =>
          $state.go('app.content.scheduledMessages.edit', { messageId: id }),
        );
      });
    }

    function updateScheduledMessage() {
      validate().then(() => {
        firstValueFrom(messageModel.updateScheduledMessage(vm.message));
      });
    }

    function createCopy() {
      $state.go(
        $state.current.name + '.copy',
        { messageId: vm.message.id },
        {
          reload: $state.current.name,
          location: 'replace',
        },
      );
    }

    /**
     * Подготавливает сообщение к созданию его копии(удаляет все id)
     */
    function preparingCreateCopy() {
      delete vm.message.id;

      vm.message.name = vm.message.name + ' (' + $translate.instant('autoMessages.editor.copy') + ')';
      for (let i = 0; i < vm.message.parts.length; i++) {
        delete vm.message.parts[i].id;
      }

      vm.message.eventMessage1 = false;
      vm.message.eventMessage2 = false;
      vm.message.eventMessage3 = false;
      vm.message.eventMessage4 = false;
      vm.message.eventMessage5 = false;

      delete vm.message.eventRead;
      delete vm.message.eventReplied;
      delete vm.message.eventSended;
      delete vm.message.eventUnsubscribed;
      delete vm.message.eventClicked;

      if (vm.message.controlGroup) {
        delete vm.message.controlGroup.id;
      }

      if (vm.message.active) {
        vm.message.active = false;
      }
    }
  }
})();
