<ng-container *transloco="let t; read: 'userTagActionComponent'">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control.controls.body"
  >
    <ng-select
      bindLabel="name"
      bindValue="name"
      [clearable]="false"
      [formControl]="control.controls.body"
      [items]="(triggerChainEditorStore.tags$ | async) ?? []"
      placeholder="{{ t('userTagSelect.placeholder') }}"
      [required]="true"
      [cqShowError]="control.controls.body"
      [classList]="['ng-touched']"
    ></ng-select>

    <cq-validation-messages [control]="control.controls.body">
      <cq-validation-message errorName="required">
        {{ t('userTagSelect.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>
