import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

const GPT_FUNCTIONS = {
  CALL_HUMAN_OPERATOR: 'call_human_operator',
  CLOSE_CONVERSATION: 'close_conversation',
  CREATE_EVENT: 'create_event',
  CREATE_PROPERTY: 'create_property',
};

@Component({
  selector: 'cq-gpt-function-selector',
  templateUrl: './gpt-function-selector.component.html',
  styleUrls: ['./gpt-function-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GptFunctionSelectorComponent extends AbsCVAFormControlBasedComponent<string[]> {
  readonly fb = inject(FormBuilder);

  readonly control: FormControl<string[]> = this.fb.control([], { nonNullable: true });

  GPT_FUNCTIONS: string[] = Object.values(GPT_FUNCTIONS);

  constructor() {
    super();
  }
}
