import { Assets, Graphics, Text, TextStyle, Texture } from 'pixi.js';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH, SimpleActionABS } from './abstract';

export class BotVideoNoteAction extends SimpleActionABS {
  private videLoaded$: Subject<string> = new Subject();

  protected style!: ActionStyle;

  readonly MAX_CIRCLE_VIDEO_WIDTH = 600;
  readonly CIRCLE_VIDEO_RADIUS = 90;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.subscribeToVideoLoad();
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 0,
        horizontal: 0,
      },
      border: {
        size: 0,
        color: HEX_COLOR.GRAY_800,
        radius: 10,
      },
      background: {
        color: HEX_COLOR.GRAY_800,
      },
    };
  }

  /**
   * Отрисовка действия
   * @private
   */
  render(): Graphics | null {
    const attachment = this.form.controls.attachments.value[0];
    if (!attachment) {
      return null;
    }
    return this.renderVideoAction();
  }

  /**
   * Отрисовка видео
   * @private
   */
  private renderVideoAction(): Graphics {
    const element = this.element ?? new Graphics();
    const attachment = this.form.controls.attachments.value[0];
    // @ts-ignore
    const url = attachment.url ?? URL.createObjectURL(attachment);
    this.renderVideo(element, url);
    element.zIndex = 1;
    return element;
  }

  private renderVideo(element: Graphics, url: string) {
    const renderTexture = (texture: Texture): void => {
      element.beginTextureFill({ texture });

      // Квадратные видео до 600 надо рендерить в круге
      if (
        texture.baseTexture.width === texture.baseTexture.height &&
        texture.baseTexture.width <= this.MAX_CIRCLE_VIDEO_WIDTH
      ) {
        texture.baseTexture.setSize(this.CIRCLE_VIDEO_RADIUS * 2, this.CIRCLE_VIDEO_RADIUS * 2);
        element.drawCircle(this.CIRCLE_VIDEO_RADIUS, this.CIRCLE_VIDEO_RADIUS, this.CIRCLE_VIDEO_RADIUS);
      } else {
        if (texture.baseTexture.width > MAX_ACTION_WIDTH) {
          const aspectRatio = texture.baseTexture.height / texture.baseTexture.width;
          texture.baseTexture.setSize(MAX_ACTION_WIDTH, MAX_ACTION_WIDTH * aspectRatio);
        }

        element.drawRoundedRect(0, 0, texture.baseTexture.width, texture.baseTexture.height, this.style.border.radius);
      }
      element.endFill();
    };

    Assets.load(url)
      .then((texture) => {
        texture.baseTexture.resource.source.muted = true;
        renderTexture(texture);
        this.videLoaded$.next(url);
      })
      .catch(() => this.renderVideoPlaceholder());
  }

  /**
   * Отрисовка плейсхолдера для видео
   * @private
   */
  private renderVideoPlaceholder(): Graphics {
    const element = this.element ?? new Graphics();

    const getRenderedIcon = (): Text => {
      const iconStyle = new TextStyle({
        align: 'center',
        fill: BOT_WHITE_COLOR,
        fontFamily: 'CQ-Icons-sm',
        fontSize: 35,
      });
      const play = new Text('\ue94f', iconStyle);
      return play;
    };

    const iconCircle = getRenderedIcon();

    element.beginFill(this.style.background.color);
    element.drawCircle(this.CIRCLE_VIDEO_RADIUS, this.CIRCLE_VIDEO_RADIUS, this.CIRCLE_VIDEO_RADIUS);
    element.endFill();
    element.zIndex = 1;
    element.addChild(iconCircle);

    const iconXPosition = this.CIRCLE_VIDEO_RADIUS - iconCircle.width / 2;
    const iconYPosition = this.CIRCLE_VIDEO_RADIUS - iconCircle.height / 2;

    iconCircle.position.set(iconXPosition, iconYPosition);

    return element;
  }

  /**
   * Подписка на загрузку видео
   * NOTE Загрузка изображений использовала хак ниже для пересчета высоты ветки, но из-за этого действия циклично перерисовывались. Подписка ниже позволяет перерисовывать действие только если изображение изменилось
   * @private
   */
  private subscribeToVideoLoad() {
    this.videLoaded$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.currentBranch.redraw();
      // Это не очень очевидный, но все таки хак. Позволяет триггернуть перерисовку, не меняя при этом никаких значений
      // this.form.controls.nextBranchLinkId.setValue(null, {emitEvent: true});
    });
  }
}
