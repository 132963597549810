<ng-container *transloco="let t; read: 'actionsListComponent'">
  <ng-container
    *ngFor="let control of control.controls; let $idx = index"
    [ngSwitch]="control.value.type"
  >
    <hr />
    <cq-action-header
      class="mb-10"
      [iconClass]="actionIconClasses[control.value.type]"
      [actionType]="control.value.type"
      (duplicate)="copyAction($idx)"
      (delete)="deleteAction($idx)"
    >
    </cq-action-header>

    <cq-event-type-action
      *ngSwitchCase="TRIGGER_CHAIN_ACTION_TYPE.EVENT_TYPE"
      [formControl]="control"
      class="mb-20"
    ></cq-event-type-action>
    <cq-property-action
      *ngSwitchCase="TRIGGER_CHAIN_ACTION_TYPE.PROPERTY"
      [formControl]="control"
      class="mb-20"
    ></cq-property-action>
    <cq-user-tag-action
      *ngSwitchCase="TRIGGER_CHAIN_ACTION_TYPE.USER_TAG"
      [formControl]="control"
      class="mb-20"
    ></cq-user-tag-action>
  </ng-container>

  <div
    ngbDropdown
    [autoClose]="true"
  >
    <button
      class="btn btn-block btn-secondary"
      ngbDropdownToggle
      type="button"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      {{ t('addActionButton') }}
    </button>

    <div ngbDropdownMenu>
      <button
        *ngFor="let type of TRIGGER_CHAIN_ACTION_TYPES"
        ngbDropdownItem
        (click)="addAction(type)"
        type="button"
      >
        <i
          class="dropdown-item-icon-left cqi-sm"
          [ngClass]="actionIconClasses[type]"
        ></i>
        {{ t('actionTypeDropdown.' + type) }}
      </button>
    </div>
  </div>
</ng-container>
