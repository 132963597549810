import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { SharedModule } from '@panel/app/shared/shared.module';

import { GetDemoModalComponent } from './get-demo-modal.component';

@NgModule({
  declarations: [GetDemoModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectComponent,
    NgFooterTemplateDirective,
  ],
})
export class GetDemoModalModule {}
