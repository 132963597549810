<ng-container *transloco="let t; read: 'addonComponent'">
  <div class="card">
    <div class="card-body py-15 margin-between-rows-5">
      <div class="font-weight-bolder">
        {{ addon.name }}
      </div>

      <div class="text-secondary">
        {{ addon.description }}
      </div>

      <ng-container *tuiLet="subscriptionStore.quotas$ | async as quotas">
        <ng-container *ngIf="quotas">
          <div
            class="d-flex justify-content-between align-items-center"
            *ngIf="
              addon.id === BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM ||
              addon.id === BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS
            "
          >
            <span>{{ t('messagesSent') }}</span>
            <span>{{ quotas.integrationMessagesTelegram }}</span>
          </div>
        </ng-container>
      </ng-container>

      <div class="d-flex justify-content-between align-items-center">
        <!--Цена отображается для ВСЕХ кроме ТГ и уже подключенных аддонов-->
        <div *ngIf="addon.id !== BILLING_ADD_ONS.TELEGRAM_BOT || addon.isConnected; else tgPrice">
          {{
            t('mrrForMonth', {
              mrrForMonth: addon.getPricePerMonth(numbersUniqueUsers / paymentPeriod) | toMoney | toFormattedMoney
            })
          }}
        </div>
        <ng-template #tgPrice>
          <div>{{ t('requestPrice') }}</div>
        </ng-template>

        <ng-container *ngIf="addon.isConnected; then status; else actions"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #status>
    <ng-container *ngIf="isSubscriptionActive">
      <button
        class="btn text-success cursor-default"
        type="button"
      >
        <i class="cqi-sm cqi-check mr-5"></i>
        <span>{{ addon.translatedStatus }}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="!isSubscriptionActive">
      <button
        class="btn text-muted cursor-default"
        type="button"
      >
        <i class="cqi-sm cqi-pause mr-5"></i>
        <span>{{ addon.translatedStatus }}</span>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #actions>
    <ng-container *ngIf="addon.id === BILLING_ADD_ONS.TELEGRAM_BOT && l10nHelper.isRusCountry(); else defaultAction">
      <a
        href="https://t.me/Carrot_quest_Request_bot"
        target="_blank"
      >
        <i class="cqi-sm cqi-plus mr-5"></i>{{ t('requestPriceButton') }}
      </a>
    </ng-container>
  </ng-template>
  <ng-template #defaultAction>
    <button
      *ngIf="isSubscriptionActive"
      class="btn btn-borderless-primary"
      (click)="onClickConnectButton(addon)"
      type="button"
    >
      <i class="cqi-sm cqi-plus mr-5"></i>
      <span>{{ t('connect') }}</span>
    </button>
  </ng-template>
</ng-container>
