import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgSelectComponent } from '@ng-select/ng-select';
import { generate } from 'short-uuid';

import { AppService } from '@http/app/services/app.service';
import { UserTag } from '@http/user/types/user.type';
import { ModalHelperService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

@Component({
  selector: 'cq-user-tag-control',
  templateUrl: './user-tag-control.component.html',
  styleUrls: ['./user-tag-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectComponent, ReactiveFormsModule, TranslocoModule, NgFooterTemplateDirective, AsyncPipe],
})
export class UserTagControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  @Input({ required: true })
  tags!: UserTag[];

  @Output()
  tagsChange: EventEmitter<UserTag[]> = new EventEmitter<UserTag[]>();

  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
  });

  constructor(
    private readonly appService: AppService,
    private readonly modalHelperService: ModalHelperService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  /**
   * Открытие модального окна для добавления нового тега
   */
  openAddTagModal() {
    const addTagModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        confirmButtonText: this.translocoService.translate('userTagControlComponent.addTagModal.confirmButtonText'),
        heading: this.translocoService.translate('userTagControlComponent.addTagModal.heading'),
        inputErrorText: this.translocoService.translate('userTagControlComponent.addTagModal.inputErrorText'),
        inputLabel: this.translocoService.translate('userTagControlComponent.addTagModal.inputLabel'),
        inputPlaceholder: this.translocoService.translate('userTagControlComponent.addTagModal.inputPlaceholder'),
      })
      .open(PromptModalComponent);

    addTagModal.result.then(this.addTag.bind(this)).catch(() => {});
  }

  /**
   * Добавление нового тега
   *
   * @param tagName - Имя тега
   */
  private addTag(tagName: UserTag['name']) {
    const newTag: UserTag = {
      id: generate(),
      name: tagName,
      removed: null,
      app: this.appService.currentAppId,
    };

    // при добавлении нового тега надо проверить не существует ли добавляемый тег
    const existingTagByName = this.tags.filter((tag) => tag.name === tagName)[0];

    if (existingTagByName) {
      this.control.setValue(existingTagByName.name);
    } else {
      this.control.setValue(newTag.name);
      this.tagsChange.emit([...this.tags, newTag]);
    }
  }
}
