import { Validators } from '@angular/forms';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import { AiContentMakerIntegration } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration';
import { AiContentMakerIntegrationSettingsExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { AiContentMakerIntegrationFormData } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

/** Класс для работы с формой представления AiContentMaker-интеграции */
export class AiContentMakerIntegrationForm extends GenericFormGroup<AiContentMakerIntegrationFormData> {
  constructor(integration: AiContentMakerIntegration) {
    let controls: Controls<AiContentMakerIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new GenericFormGroup<AiContentMakerIntegrationSettingsExternal>({
        gptModelName: new GenericFormControl(integration.settings.gptModelName, [Validators.required]),
        gptTemperature: new GenericFormControl(integration.settings.gptTemperature, [Validators.required]),
        openAiApiKey: new GenericFormControl(integration.settings.openAiApiKey, [Validators.required]),
        promptsJson: new AiContentMakerSettingsFormGroup(integration.settings.promptsJson),
      }),
    };

    super(controls, null, null);
  }
}

class AiContentMakerSettingsFormGroup extends GenericFormGroup<
  AiContentMakerIntegrationSettingsExternal['promptsJson']
> {
  constructor(settings: AiContentMakerIntegrationSettingsExternal['promptsJson']) {
    const controls: Controls<AiContentMakerIntegrationSettingsExternal['promptsJson']> = {
      companyInfo: new GenericFormControl(settings.companyInfo),
      aiPersonaAndTask: new GenericFormControl(settings.aiPersonaAndTask, [Validators.required]),
      faq: new GenericFormControl(settings.faq),
      rules: new GenericFormControl(settings.rules, [Validators.required]),
    };

    super(controls, null, null);
  }
}
