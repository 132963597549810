import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { environment } from '@environment';
import { AiDataModel } from '@http/ai-data/ai-data.model';
import { AI_DATA_CRAWLING_STATUS } from '@http/ai-data/internal-types';
import { UtilsModel } from '@http/utils/utils.model';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { getAsyncUrlValidatorFn } from '@panel/app/shared/validators/async/async-validator';

@Component({
  selector: 'cq-start-crawling-process-modal',
  templateUrl: './start-crawling-process-modal.component.html',
  styleUrls: ['./start-crawling-process-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartCrawlingProcessModalComponent {
  urlControl: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
    asyncValidators: [getAsyncUrlValidatorFn(this.utilsService)],
  });

  /** Флаг запуска процесса краулинга */
  isCrawlingStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  projectName = environment.projectName;

  constructor(
    readonly activeModal: NgbActiveModal,
    private readonly aiDataModel: AiDataModel,
    private readonly state: AiDataSourcesListStateService,
    private readonly utilsService: UtilsModel,
  ) {}

  /** Запуск процесса краулинга */
  startCrawling() {
    if (!this.urlControl.valid) {
      this.urlControl.markAsTouched();
      return;
    }

    this.isRequestPerformed$.next(true);

    this.aiDataModel
      .startCrawling(this.urlControl.value)
      .pipe(
        finalize(() => this.isRequestPerformed$.next(false)),
        take(1),
      )
      .subscribe({
        next: () => {
          this.isCrawlingStarted$.next(true);
          this.state.crawlingUrl$.next(this.urlControl.value);
          this.state.crawlingStatus$.next(AI_DATA_CRAWLING_STATUS.IN_PROGRESS);
          this.state.isShowCrawlingBanner$.next(true);
        },
        error: (error) => {
          if (error.error === 'UrlCantBeReached' || error.error === 'UrlCantBeProcessed') {
            this.urlControl.setErrors({ notAvailable: true });
          }
        },
      });
  }
}
