import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgLabelTemplateDirective, NgOptgroupTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { DirectoryFilterComponent } from './directory-filter.component';

@NgModule({
  declarations: [DirectoryFilterComponent],
  exports: [DirectoryFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptgroupTemplateDirective,
    NgOptionTemplateDirective,
    NgFooterTemplateDirective,
  ],
})
export class DirectoryFilterModule {}
