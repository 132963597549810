import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { AiContentKind, AiData } from '@http/ai-data/internal-types';
import { AppService } from '@http/app/services/app.service';
import { ArticleModel } from '@http/article/article.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { ConversationPartAiContentModalStore } from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.store';

/**
 * Компонент с информацией о контенте, который использовал AI-бот для ответа пользователю
 */
@Component({
  selector: 'cq-data-source[dataSource]',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceComponent implements OnInit {
  @Input()
  dataSource!: AiData;

  /** Текст для кнопки редактирования источника данных */
  editDataTooltipTranslate!: string;

  /** Является ли текущий пользователь оператором */
  isOperator!: boolean;

  /** Был ли удалён источник данных */
  isSourceDeleted!: boolean;

  /** Текст об отсутствии прав на редактирования источника данных */
  noAccessTooltipTranslate!: string;

  /** Текст для показа актуальности источника данных на момент показа модалки */
  sourceActualityTranslate!: string;

  /** CSS-класс иконки источника данных */
  sourceTypeIcon!: string;

  /** URL для просмотра источника данных */
  sourceUrl!: string;

  constructor(
    private readonly appService: AppService,
    private readonly articleModel: ArticleModel,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly store$: ConversationPartAiContentModalStore,
    private readonly translocoService: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    this.isOperator = this.isUserOperator();
    this.isSourceDeleted = !!this.dataSource.removed;

    this.editDataTooltipTranslate = this.getEditDataTooltipTranslate();
    this.noAccessTooltipTranslate = this.getNoAccessTooltipTranslate();
    this.sourceActualityTranslate = this.getSourceActualityTranslate();
    this.sourceTypeIcon = this.getSourceTypeIcon();
    this.sourceUrl = this.getSourceUrl();
  }

  onClickEditDataButton(): void {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        const appId = this.appService.currentAppId;

        // Чтобы перейти на редактирование статьи, нужно знать ID категории, к которой она относится
        this.articleModel.get(appId, this.dataSource.contentId, true).subscribe((article: any) => {
          const url = this.uiRouter.stateService.href(
            'app.content.knowledgeBase.articleEdit',
            {
              articleId: article.id,
              categoryId: article.category.id,
            },
            { absolute: true },
          );
          window.open(url, '_blank');
        });
    }
  }

  private getEditDataTooltipTranslate(): string {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        return this.translocoService.translate('dataSourceComponent.editDataTooltip.knowledgeBase');
      case AiContentKind.URL:
        return '';
    }
  }

  private getNoAccessTooltipTranslate(): string {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        return this.translocoService.translate('dataSourceComponent.noAccessTooltip.knowledgeBase', {
          projectName: environment.projectName,
        });
      case AiContentKind.URL:
        return '';
    }
  }

  private getSourceActualityTranslate(): string {
    const conversationPartCreatedDate = this.store$.modalData$.getValue()?.conversationPart.created!;
    let translate: string = '';

    if (
      conversationPartCreatedDate.isSame(this.dataSource.lastAiSync, 'minute') ||
      conversationPartCreatedDate.isAfter(this.dataSource.lastAiSync, 'minute')
    ) {
      translate = this.translocoService.translate('dataSourceComponent.actuality.currentVersion');
    }

    if (conversationPartCreatedDate.isBefore(this.dataSource.lastAiSync, 'minute')) {
      translate = this.translocoService.translate('dataSourceComponent.actuality.contentWasChanged');
    }

    if (this.isSourceDeleted) {
      translate = this.translocoService.translate('dataSourceComponent.actuality.contentDeleted');
    }

    return translate;
  }

  private getSourceTypeIcon(): string {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        return 'cqi-book';
      case AiContentKind.URL:
        return 'cqi-link';
    }
  }

  private getSourceUrl(): string {
    switch (this.dataSource.kind) {
      case AiContentKind.KNOWLEDGE_BASE_ARTICLE:
        const knowledgeBaseDomain = this.store$.modalData$.getValue()?.knowledgeBaseDomain;
        return `${knowledgeBaseDomain}/article/${this.dataSource.contentId}`;
      case AiContentKind.URL:
        return '';
    }
  }

  private isUserOperator(): boolean {
    const appId = this.appService.currentAppId;
    const djangoUser = this.store$.modalData$.getValue()?.djangoUser!;

    return this.djangoUserModel.isOperator(appId, djangoUser);
  }
}
