import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { IPromise } from 'angular';
import { firstValueFrom, Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { PropertyModel, UserProperty } from '@http/property/property.model';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

/**
 * Компонент для выбора свойства. С возможность добавлять новые свойства
 */
@Component({
  selector: 'cq-property-selector[currentApp][properties]',
  templateUrl: './property-selector.component.html',
  styleUrls: ['./property-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class PropertySelectorComponent extends AbstractCVAControl<string> implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input()
  properties: UserProperty[] = [];

  @Input()
  sortType: string[] = ['groupOrder', 'prettyName'];

  @Input()
  userPropsTypeFilter: string[] = [];

  @ContentChild('customOption', { read: TemplateRef })
  readonly customOptionTemplate: TemplateRef<any> | null = null;

  readonly control = new GenericFormControl<string>(null);

  ngOnInit() {
    super.ngOnInit();

    this.accessToUsersCustomProperties = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
      this.currentApp,
    );
  }

  /**
   * Гипотетический, может быть ситуация, когда свойство удалено и его не будет в списке свойств с бэка.
   * Поэтому добавляем его в список свойств, чтоб выбрать в селекторе.
   */
  writeValue(propName: string | null): void {
    const isExistProp = this.properties.some(
      (propItem) => propItem.name === propName || propItem.prettyName === propName,
    );
    if (propName && !isExistProp) {
      this.addProperty(propName).then(() => super.writeValue(propName));
      return;
    }
    super.writeValue(propName);
  }

  @Output()
  readonly selectedChange = new Subject<UserProperty>();
  @Output()
  readonly propertiesChange = new Subject<UserProperty[]>();
  /** Доступ до кастомных свойств пользователей */
  accessToUsersCustomProperties: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    private readonly destroy$: DestroyService,
    private readonly modalHelperService: ModalHelperService,
    private readonly propertyModel: PropertyModel,
    private readonly transloco: TranslocoService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  /**
   * Создает свойство и добавляет его в список свойств
   */
  addProperty(name: string): IPromise<UserProperty> {
    return firstValueFrom(this.propertyModel.createUserProperty(this.currentApp.id, name)).then((prop) => {
      //Такой ситуации не должно произойти, потому что массив props в данной ситуации не возможен.
      //Сделано для того ts не ругался
      if (Array.isArray(prop)) {
        prop = prop[0];
      }

      //Добавляет его в список свойств
      if (this.isPropertyUnique(prop)) {
        this.propertiesChange.next([...this.properties, prop]);
      }

      return prop;
    });
  }

  /**
   * Открывает модалку создания свойства
   */
  openAddPropertyModal(): void {
    const addPropertyModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        body: this.transloco.translate('propertySelectorComponent.addPropertyModal.body'),
        confirmButtonText: this.transloco.translate('propertySelectorComponent.addPropertyModal.confirmButtonText'),
        heading: this.transloco.translate('propertySelectorComponent.addPropertyModal.heading'),
        inputErrorText: this.transloco.translate('propertySelectorComponent.addPropertyModal.inputErrorText'),
        inputPlaceholder: this.transloco.translate('propertySelectorComponent.addPropertyModal.inputPlaceholder'),
        inputMaxLength: 255,
      })
      .open(PromptModalComponent, {
        windowClass: 'index-1070',
        backdropClass: 'index-1070',
      });

    addPropertyModal.result
      .then((propName: string) => {
        return this.addProperty(propName);
      })
      .then((newProp) => {
        this.control.setValue(newProp.name);
        this.selectedChange.next(newProp);
      })
      // Модалка кидает ошибку на закрытие, в консоль это не надо TODO: сделать catch callback для модалок
      .catch(() => {});
  }

  trackByFn(property: UserProperty): string {
    return property.name;
  }

  /**
   * Проверяет существует ли такое свойство в списке свойств
   */
  private isPropertyUnique(property: UserProperty): boolean {
    for (const prop of this.properties) {
      if (prop.name === property.name || prop.prettyName === property.prettyName) {
        return false;
      }
    }

    return true;
  }
}
