<ng-container *transloco="let t; read: 'chatBot'">
  <div
    class="position-relative card flex flex-row no-margin-bottom"
    [ngClass]="{
      'access-denial-mode': hasAccessDenial,
      'canvas-chat-bot-content': chatBot.type === 'lead',
      'canvas-routing-bot-content':
        chatBot.type === 'routing' ||
        chatBot.type === 'facebook' ||
        chatBot.type === 'telegram' ||
        chatBot.type === 'widget',
      fullscreen: fullscreen
    }"
  >
    <ng-container
      *ngIf="chatBotForm; then chatBotContent; else loader"
      class="overflow-hidden"
    ></ng-container>

    <ng-template #chatBotContent>
      <div class="flex-grow-1">
        <div class="flex flex-row justify-space-between padding-20 action-bar bordered-bottom">
          <div class="flex flex-row justify-start">
            <button
              class="mr-10 btn btn-outline-primary"
              type="button"
              (click)="viewportToStartBadge()"
              ngbTooltip="{{ t('branchContent.canvasViewToStartedBranchButton.tooltip') }}"
              [placement]="fullscreen ? 'bottom-left' : 'top-left'"
            >
              <i class="btn-icon cqi-sm cqi-home-o"></i>
            </button>
            <div
              ngbDropdown
              [autoClose]="true"
            >
              <button
                class="btn btn-outline-primary"
                ngbDropdownToggle
                type="button"
              >
                <i class="btn-icon-left cqi-sm cqi-plus"></i>
                {{ t('branchContent.newElementButton') }}
              </button>
              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  (click)="addBranchAndSelect()"
                  type="button"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-comment-out-filled message-block-color"></i>
                  <span>{{ t('branchContent.newElementDropdown.block') }}</span>
                </button>
                <button
                  ngbDropdownItem
                  (click)="addConditionAndSelect(); trackAddConditionClick()"
                  type="button"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-split condition-block-color"></i>
                  <span>{{ t('branchContent.newElementDropdown.condition') }}</span>
                </button>
                <button
                  ngbDropdownItem
                  (click)="addActionBlockAndSelect(); trackAddActionClick()"
                  type="button"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-flash-filled action-block-color"></i>
                  <span>{{ t('branchContent.newElementDropdown.action') }}</span>
                </button>
                <button
                  *ngIf="hasAccessToMeetingAction"
                  ngbDropdownItem
                  (click)="addMeetingAndSelect()"
                  type="button"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-calendar meeting-block-color"></i>
                  <span>{{ t('branchContent.newElementDropdown.meeting') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="onChangeZoomButtonClick('down')"
            >
              <i class="btn-icon cqi-sm cqi-minus-circle"></i>
            </button>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="canvasEditor.setZoom(100)"
            >
              {{ zoomValue }} %
            </button>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="onChangeZoomButtonClick('up')"
            >
              <i class="btn-icon cqi-sm cqi-plus-circle"></i>
            </button>
          </div>
          <div class="flex flex-row justify-end">
            <button
              *ngIf="featureModel.hasAccess(FEATURES.CANVAS_SCREENSHOT)"
              class="btn btn-outline-primary"
              type="button"
              (click)="onClickTakeScreenshot()"
            >
              <i class="btn-icon-left cqi-sm cqi-camera-viewfinder"></i>
              <span>{{ t('takeScreenshotButton') }}</span>
              <cq-beta-label
                class="badge badge-light-secondary"
                [featureLabel]="FEATURE_LABELS_ONLY.CANVAS_SCREENSHOT"
              ></cq-beta-label>
            </button>
            <button
              *ngIf="featureModel.hasAccess(FEATURES.CHAT_BOT_TEMPLATE)"
              class="btn btn-outline-primary margin-left-10"
              (click)="copyStructureForTemplate()"
            >
              <i class="btn-icon cqi-sm cqi-copy"></i>
            </button>
            <button
              class="btn btn-outline-primary margin-left-10"
              (click)="toggleFullscreen()"
            >
              <i
                class="btn-icon cqi-sm"
                [ngClass]="{ 'cqi-compress': fullscreen, 'cqi-expand': !fullscreen }"
              ></i>
            </button>
          </div>
        </div>
      </div>

      <div class="position-absolute bordered-right canvas-wrap">
        <cq-bot-canvas-editor
          class="flex-grow h-100"
          [chatBotForm]="chatBotForm"
          (branchCreated)="addCreatedBranch($event); subscribeToBranchesDestroy($event)"
          (branchSelect)="selectBlock($event)"
          (startBadgeClick)="startBadgeCanvasClick.next()"
          (zoomChange)="onZoomChange($event)"
        ></cq-bot-canvas-editor>
      </div>
      <div
        class="overflow-hidden"
        *ngIf="selectedBranch"
        @slideLeft
      >
        <cq-branch-editor
          class="full-height"
          [allowUserReplies]="chatBotForm.allowUserReplies"
          [branch]="selectedBranch!"
          [chatBot]="chatBot"
          [createBranchFn]="createNewBlock.bind(this)"
          [currentApp]="currentApp"
          [nextBranchOptions]="(nextBranchOptions$ | async) ?? []"
          [properties]="properties"
          (branchRename)="onBranchRename()"
          (toBranch)="selectBlock($event)"
          [fullscreen]="fullscreen"
        ></cq-branch-editor>
      </div>
    </ng-template>

    <ng-template #loader>
      <cq-loader></cq-loader>
    </ng-template>

    <cq-loader
      *ngIf="screenshotInProgress"
      @screenshotAnimation
      [background]="false"
      class="content-loader position-absolute"
    >
      {{ t('screenshotInProgress') }}
    </cq-loader>
  </div>
</ng-container>
