import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { EventSelectModule } from '@panel/app/partials/filters/components/event-select/event-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EventsAnalyticsComponent } from './events-analytics.component';

@NgModule({
  declarations: [EventsAnalyticsComponent],
  imports: [
    CommonModule,
    PermissionDeniedModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    EventTypeSelectModule,
    EventSelectModule,
    ReactiveFormsModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class EventsAnalyticsModule {}
