import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AiDataBotSettings } from '@http/ai-data/internal-types';
import { Channel } from '@http/channel/channel.model';
import { Properties } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';

@Injectable()
export class AiDataBotSettingsStateService {
  readonly aiDataBotSettings$: BehaviorSubject<AiDataBotSettings | null> =
    new BehaviorSubject<AiDataBotSettings | null>(null);
  readonly properties$: BehaviorSubject<Properties> = new BehaviorSubject<Properties>({
    eventTypeProps: [],
    eventTypes: [],
    userProps: [],
  });
  readonly tags$: BehaviorSubject<UserTag[]> = new BehaviorSubject<UserTag[]>([]);

  private readonly channels$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  get channels() {
    const channels = this.channels$.getValue();
    if (!channels) {
      throw new Error('Cannot access Channels: value is null');
    }
    return channels;
  }
  set channels(channels: Channel[]) {
    this.channels$.next(channels);
  }
}
