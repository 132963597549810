<ng-container *transloco="let t; read: 'integrationSelectComponent'">
  <div
    class="form-control-container"
    [cqShowError]="formControl"
  >
    <ng-select
      [clearable]="false"
      [cqShowError]="formControl"
      [formControl]="formControl"
      [items]="integrationList"
      bindLabel="name"
      bindValue="id"
      placeholder="{{ t('placeholder') }}"
    >
      <ng-template
        ng-option-tmp
        let-integration="item"
      >
        <div>{{ integration.name }}</div>
        <!-- показ имен для тг ботов -->
        <div
          *ngIf="integration?.settings?.botName"
          class="small text-muted"
        >
          {{'@' + integration.settings.botName }}
        </div>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="formControl">
      <cq-validation-message errorName="required">
        {{ t('errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>
