export const EDITOR_FORMAT_FILE_MAX_SIZE = 10 * 1024 * 1024;

export enum EDITOR_FORMAT_FILE_TYPE {
  MSWORD = 'application/msword',
  PDF = 'application/pdf',
  PGP_SIGNATURE = 'application/pgp-signature',
  MS_EXCEL = 'application/vnd.ms-excel',
  OPENXML_SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OPENXML_WORDPROCESSING = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  RAR = 'application/x-rar',
  RAR_COMPRESSED = 'application/x-rar-compressed',
  ZIP_COMPRESSED = 'application/x-zip-compressed',
  ZIP = 'application/zip',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  PJPEG = 'image/pjpeg',
  PNG = 'image/png',
  SVG_XML = 'image/svg+xml',
  HTML = 'text/html',
  PLAIN_TEXT = 'text/plain',
  CSV = 'text/csv',
}

export const EDITOR_FORMAT_FILE_TYPES_DEFAULT = [
  EDITOR_FORMAT_FILE_TYPE.MSWORD,
  EDITOR_FORMAT_FILE_TYPE.PDF,
  EDITOR_FORMAT_FILE_TYPE.PGP_SIGNATURE,
  EDITOR_FORMAT_FILE_TYPE.MS_EXCEL,
  EDITOR_FORMAT_FILE_TYPE.OPENXML_SPREADSHEET,
  EDITOR_FORMAT_FILE_TYPE.OPENXML_WORDPROCESSING,
  EDITOR_FORMAT_FILE_TYPE.RAR,
  EDITOR_FORMAT_FILE_TYPE.RAR_COMPRESSED,
  EDITOR_FORMAT_FILE_TYPE.ZIP_COMPRESSED,
  EDITOR_FORMAT_FILE_TYPE.ZIP,
  EDITOR_FORMAT_FILE_TYPE.GIF,
  EDITOR_FORMAT_FILE_TYPE.JPEG,
  EDITOR_FORMAT_FILE_TYPE.PJPEG,
  EDITOR_FORMAT_FILE_TYPE.PNG,
  EDITOR_FORMAT_FILE_TYPE.SVG_XML,
  EDITOR_FORMAT_FILE_TYPE.HTML,
  EDITOR_FORMAT_FILE_TYPE.PLAIN_TEXT,
  EDITOR_FORMAT_FILE_TYPE.CSV,
].join(',');
