<ng-container *transloco="let t; read: 'triggerChainFilterEditorComponent'">
  <ng-container
    *tuiLet="{
      app: triggerChainEditorStore.currentApp$ | async,
      properties: triggerChainEditorStore.properties$ | async,
      telegramIntegrations: triggerChainEditorStore.telegramIntegrations$ | async,
    } as params"
  >
    <div
      class="w-690"
      *ngIf="params.app && params.properties && params.telegramIntegrations"
    >
      <div class="p-20 bordered-bottom">
        <div class="d-flex align-items-center margin-between-cols-10">
          <div class="mr-auto">
            <cq-trigger-chain-step-name-editor [formControl]="form.controls.name"></cq-trigger-chain-step-name-editor>
          </div>

          <div ngbDropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              ngbDropdownToggle
            >
              <i class="btn-icon-left cqi-sm cqi-ellipsis"></i>
            </button>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                class="btn btn-borderless-primary"
                type="button"
                (click)="copyStepChain()"
              >
                <i class="btn-icon cqi-sm cqi-copy"></i>
                <span>{{ t('actions.copy') }}</span>
              </button>
              <button
                ngbDropdownItem
                class="btn btn-borderless-primary"
                type="button"
                (click)="deleteStepChain()"
              >
                <i class="btn-icon cqi-sm cqi-trash"></i>
                <span>{{ t('actions.remove') }}</span>
              </button>
            </div>
          </div>

          <button
            (click)="dismissModal()"
            class="btn btn-outline-primary"
          >
            {{ 'general.cancel' | transloco }}
          </button>

          <button
            (click)="saveAndClose()"
            class="btn btn-primary"
          >
            {{ t('actions.saveAndClose') }}
          </button>
        </div>
      </div>

      <div class="px-20 pt-20 pb-0 d-flex justify-content-between">
        <ng-select
          [(ngModel)]="selectedFilterType"
          [clearable]="false"
          [searchable]="false"
          (change)="onSelectedFilterTypeChange($event)"
        >
          <ng-template
            ng-label-tmp
            let-type="item"
          >
            <i
              *ngIf="type === 'default'"
              class="cqi-sm cqi-user-list"
            ></i>
            <i
              *ngIf="type === 'jinja'"
              class="cqi-sm cqi-braces"
            ></i>
            <span class="ml-5">{{ t('filterTypeSelector.' + type + '.label') }}</span>
          </ng-template>
          <ng-option [value]="'default'">
            <div>{{ t('filterTypeSelector.default.label') }}</div>
            <small class="text-secondary">{{ t('filterTypeSelector.default.description') }}</small>
          </ng-option>
          <ng-option [value]="'jinja'">
            <div>{{ t('filterTypeSelector.jinja.label') }}</div>
            <small class="text-secondary">{{ t('filterTypeSelector.jinja.description') }}</small>
          </ng-option>
        </ng-select>

        <button
          *ngIf="selectedFilterType === 'jinja'"
          class="btn btn-secondary"
          type="button"
          [disabled]="form.controls.jinjaFilter.value?.length === 0"
          (click)="runJinjaTest()"
        >
          <i class="btn-icon-left cqi-sm cqi-play-circle"></i>
          <span>{{ t('jinja.testButton') }}</span>
        </button>
      </div>

      <div
        *ngIf="selectedFilterType === 'default'"
        class="p-20"
      >
        <div class="d-flex justify-content-between align-items-center mb-15">
          <div class="text-secondary">{{ t('logicalOperationSwitcher.description') }}</div>
          <div class="btn-group btn-block btn-group-toggle w-initial">
            <label
              class="btn btn-outline-primary"
              [class.active]="form.controls.logicalOperation.value === 'and'"
            >
              <input
                [formControl]="form.controls.logicalOperation"
                type="radio"
                value="and"
              />
              <span>{{ t('logicalOperationSwitcher.and') }}</span>
            </label>
            <label
              class="btn btn-outline-primary"
              [class.active]="form.controls.logicalOperation.value === 'or'"
            >
              <input
                [formControl]="form.controls.logicalOperation"
                type="radio"
                value="or"
              />
              <span>{{ t('logicalOperationSwitcher.or') }}</span>
            </label>
          </div>
        </div>

        <div
          [cqShowError]="form"
          [showOnlyForErrors]="['filtersAreEmpty']"
        >
          <cq-property-filters
            [app]="params.app"
            [props]="params.properties.userProps"
            [telegramIntegrations]="params.telegramIntegrations"
            [formControl]="form.controls.propertyFilters"
          ></cq-property-filters>

          <cq-validation-messages [control]="form">
            <cq-validation-message errorName="filtersAreEmpty">{{ t('errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>

        <hr />
        <div
          [cqShowError]="form"
          [showOnlyForErrors]="['filtersAreEmpty']"
        >
          <cq-event-filters
            [app]="params.app"
            [events]="params.properties.eventTypes"
            [formControl]="form.controls.eventFilters"
          ></cq-event-filters>

          <cq-validation-messages [control]="form">
            <cq-validation-message errorName="filtersAreEmpty">{{ t('errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>

      <div
        *ngIf="selectedFilterType === 'jinja'"
        class="p-20"
      >
        <div [cqShowError]="form.controls.jinjaFilter">
          <cq-ace-editor
            class="mb-10"
            [formControl]="form.controls.jinjaFilter"
          ></cq-ace-editor>
          <cq-validation-messages
            class="jinja-validation-messages"
            [control]="form.controls.jinjaFilter"
          >
            <cq-validation-message errorName="required">
              {{ t('jinja.errors.required') }}
            </cq-validation-message>
            <cq-validation-message errorName="maxlength">
              {{ t('jinja.errors.maxlength', { maxLength: JINJA_MAX_LENGTH }) }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <div class="mb-10">
          <ngb-alert
            *ngIf="jinjaValidationResult === true"
            type="success"
            [dismissible]="false"
            class="d-flex justify-content-between align-items-center mb-10"
          >
            <div class="margin-between-cols-5">
              <i class="cqi-sm cqi-check-circle text-success"></i>
              <span>{{ t('jinja.status.true') }}</span>
            </div>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="openCheckJinjaFilterModal()"
            >
              <span>{{ t('jinja.testOnUserButton') }}</span>
            </button>
          </ngb-alert>

          <div
            *ngIf="jinjaValidationResult === false"
            class="d-flex align-items-center margin-between-cols-5"
          >
            <i class="cqi-sm cqi-ban text-danger"></i>
            <span>{{ t('jinja.status.false') }}</span>
          </div>

          <ngb-alert
            *ngIf="isJinjaError(jinjaValidationResult)"
            type="warning"
            [dismissible]="false"
            class="d-flex align-items-center margin-between-cols-5 mb-10"
          >
            <i class="cqi-sm cqi-triangle-exclamation text-warning"></i>
            <span>{{ jinjaValidationResult }}</span>
          </ngb-alert>
        </div>
        <span
          class="small text-secondary"
          [innerHTML]="t('jinja.description')"
        >
        </span>
      </div>
    </div>
  </ng-container>
</ng-container>
