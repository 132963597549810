import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AiContentMakerModule } from './ai-content-maker/ai-content-maker.module';

@NgModule({
  exports: [],
  declarations: [],
  imports: [CommonModule, AiContentMakerModule],
})
export class EmailPartEditorModule {}
