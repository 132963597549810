import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';

/**
 * Контроллер списка шаблонов
 */
(function () {
  'use strict';

  angular.module('myApp.messageTemplates').controller('CqMessageTemplatesController', CqMessageTemplatesController);

  function CqMessageTemplatesController($timeout, MESSAGE_TEMPLATES_TABS, carrotquestHelper, planFeatureAccessService) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);

      vm.currentTab = MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY; // Выбранная вкладка
      vm.isApiRequestPerformed = false;
      vm.MESSAGE_TEMPLATES_TABS = MESSAGE_TEMPLATES_TABS;
      vm.toggleApiRequestPerformedFlag = toggleApiRequestPerformedFlag;
      vm.trackClickCreateTemplate = trackClickCreateTemplate;
      vm.trackEnterOnPage = trackEnterOnPage;
    }

    function toggleApiRequestPerformedFlag() {
      $timeout(() => (vm.isApiRequestPerformed = !vm.isApiRequestPerformed));
    }

    /**
     * Трек перехода на вкладку с разделами шаблонов
     *
     * @param {String} tab - Название вкладки
     */
    function trackEnterOnPage(tab) {
      carrotquestHelper.track('Шаблоны - зашел', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Источник шаблона': tab,
      });
    }

    /**
     * Трек клика на кнопку "создать новый шаблон"
     */
    function trackClickCreateTemplate() {
      carrotquestHelper.track('Шаблоны - клик на создать шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }
  }
})();
