import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

@Component({
  selector: 'cq-branch-action-assistant[actionForm]',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionAssistantComponent {
  /** Форма с действием */
  private _actionForm!: BaseBotActionForm;
  @Input() set actionForm(actionForm: BaseBotActionForm) {
    this._actionForm = actionForm;
    // пока доступен только один ассистент, поэтому сразу добавляю его в форму
    !this._actionForm.controls.body.value &&
      this._actionForm.controls.body.setValue(CONVERSATION_ASSISTANT_TYPES.CHAT_GPT);
  }
  get actionForm() {
    return this._actionForm;
  }

  allowedAssistants = this.getAllowedAssistants();
  assistantIcon: Record<CONVERSATION_ASSISTANT_TYPES.YANDEX_AI | CONVERSATION_ASSISTANT_TYPES.CHAT_GPT | any, string> =
    {
      [CONVERSATION_ASSISTANT_TYPES.YANDEX_AI]: 'cqi-yandex',
      [CONVERSATION_ASSISTANT_TYPES.CHAT_GPT]: 'cqi-robot',
      [CONVERSATION_ASSISTANT_TYPES.AI_SALES]: 'cqi-robot',
      //[CONVERSATION_ASSISTANT_TYPES.DIALOGFLOW]: '',
    };

  constructor(
    public readonly state: StateService,
    private readonly featureModel: FeatureModel,
  ) {}

  getAllowedAssistants(): CONVERSATION_ASSISTANT_TYPES[] {
    const assistants = [CONVERSATION_ASSISTANT_TYPES.CHAT_GPT];

    if (this.featureModel.hasAccess(FEATURES.AI_SALES)) {
      assistants.push(CONVERSATION_ASSISTANT_TYPES.AI_SALES);
    }

    //if (this.featureModel.hasAccess(FEATURES.YANDEX_AI)) {
    //  assistants.push(CONVERSATION_ASSISTANT_TYPES.YANDEX_AI);
    //}
    //assistants.push(CONVERSATION_ASSISTANT_TYPES.DIALOGFLOW);
    return assistants;
  }
}
