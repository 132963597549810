<ng-container *transloco="let t; read: 'editorFormatButtonComponent'">
  <div
    #buttonTooltipInstance="ngbTooltip"
    [ngbTooltip]="t('tooltip.insertButton')"
    container="body"
    triggers="hover:blur"
  >
    <button
      #buttonPopoverInstance="ngbPopover"
      type="button"
      class="btn btn-borderless-primary rounded-0"
      [ngbPopover]="buttonPopover"
      popoverClass="button-popover"
      container="body"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
      (click)="onClickButtonPopoverButton()"
    >
      <i class="cqi-sm cqi-hand-pointer"></i>
    </button>
  </div>

  <ng-template #buttonPopover>
    <form (ngSubmit)="onSubmitForm($event)">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          [formControl]="buttonFormGroup.controls.href"
          [placeholder]="t('input.href')"
        />
      </div>

      <div class="form-group">
        <input
          class="form-control"
          type="text"
          [formControl]="buttonFormGroup.controls.text"
          [placeholder]="t('input.text')"
        />
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="buttonPopoverCheckbox"
          class="custom-control-input"
          type="checkbox"
          [formControl]="buttonFormGroup.controls.isBlank"
        />
        <label
          class="custom-control-label"
          for="buttonPopoverCheckbox"
          >{{ t('input.isBlank') }}
        </label>
      </div>

      <div class="d-flex justify-end">
        <button
          class="btn btn-primary mt-15"
          type="submit"
          [disabled]="buttonFormGroup.invalid"
        >
          {{ t('button.insert') }}
        </button>
      </div>
    </form>
  </ng-template>

  <div #selectionButtonPopoverContainer>
    <div
      #selectionButtonPopoverInstance="ngbPopover"
      [ngbPopover]="selectionButtonPopover"
      popoverClass="button-popover"
      placement="bottom-left"
      [autoClose]="'outside'"
      triggers="manual"
    ></div>
    <ng-template #selectionButtonPopover>
      <form (ngSubmit)="onSubmitForm($event)">
        <ul
          ngbNav
          #selectionButtonPopoverNav="ngbNav"
          [(activeId)]="activeTab"
          class="nav nav-tabs"
        >
          <li [ngbNavItem]="1">
            <button
              ngbNavLink
              type="button"
              (click)="activeTab = 1"
              [ngbTooltip]="t('tooltip.editButtonLink')"
            >
              <i class="cqi-sm cqi-pencil"></i>
            </button>
            <ng-template ngbNavContent>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="buttonFormGroup.controls.href"
                  [placeholder]="t('input.href')"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  [formControl]="buttonFormGroup.controls.text"
                  [placeholder]="t('input.text')"
                />
              </div>

              <div class="custom-control custom-checkbox">
                <input
                  id="selectionButtonPopoverCheckbox"
                  class="custom-control-input"
                  type="checkbox"
                  [formControl]="buttonFormGroup.controls.isBlank"
                />
                <label
                  class="custom-control-label"
                  for="selectionButtonPopoverCheckbox"
                  >{{ t('input.isBlank') }}
                </label>
              </div>

              <div class="d-flex justify-end">
                <button
                  class="btn btn-primary mt-15"
                  type="submit"
                  [disabled]="buttonFormGroup.invalid"
                >
                  {{ t('button.update') }}
                </button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button
              ngbNavLink
              type="button"
              (click)="onClickOpenButtonButton()"
              [ngbTooltip]="t('tooltip.openButtonLink')"
            >
              <i class="cqi-sm cqi-external-link"></i>
            </button>
          </li>
          <li [ngbNavItem]="3">
            <button
              ngbNavLink
              type="button"
              (click)="onClickDeleteButtonButton()"
              [ngbTooltip]="t('tooltip.unlink')"
            >
              <i class="cqi-sm cqi-unlink"></i>
            </button>
            <ng-template ngbNavContent></ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="selectionButtonPopoverNav"></div>
      </form>
    </ng-template>
  </div>
</ng-container>
