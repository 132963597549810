import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { DjangoUserModel } from '@http/django-user/django-user.model';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { SETTINGS_TABS } from '@panel/app-old/components/settings/settings.constants';

@Injectable({ providedIn: 'root' })
export class ServiceInstallationToastService {
  /**
   * Типы сообщений в тосте
   *
   * @type {Object}
   */
  BODY_TYPES = {
    AUTO_MESSAGES: 'autoMessages', // Триггерные сообщения, готовые сценарии, шаблоны
    CONVERSATIONS: 'conversations', // Диалоги, аналитика диалогов
    DEFAULT: 'default', // Все остальные разделы
    EVENTS: 'events', // События, мастер сбора данных
    FUNNELS: 'funnels', // Воронки, аналитика событий, статистика ручных рассылок
    USERS: 'users', // Лиды, импорт, live
  };

  /**
   * Информация о биллинге
   * Необходим для того, чтобы скрыть тост, если "Вечеринка закончилась"
   */
  billingInfo: any;

  /**
   * Текущее приложение
   * Будет храниться локально и присваиваться при вызове showToast, чтобы не использовать $rootScope
   */
  currentApp: any;

  /** Текущий тип сообщения в тосте */
  currentBodyType: any;

  /**
   * Текущий пользователь
   * Будет храниться локально и присваиваться при вызове showToast, чтобы не использовать $rootScope
   */
  djangoUser: any;

  /**
   * Сопоставления типов сообщений и состояний
   * В зависимости от состояния нужно показывать тост с различными текстами
   */
  STATES_FOR_BODY_TYPES = this.getStates();

  /** Инстанс тоста */
  toast: any;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly toastr: ToastService,
    private readonly djangoUserModel: DjangoUserModel,
  ) {}

  /**
   * Получение соответствия типов сообщений и состояний, на которых показываются эти сообщения
   */
  getStates(): any {
    var states: any = {};

    states[this.BODY_TYPES.AUTO_MESSAGES] = [
      'app.content.messagesAjs',
      'app.content.messagesAjs.manual',
      'app.content.messagesAjs.templates',
    ];

    states[this.BODY_TYPES.CONVERSATIONS] = ['app.content.conversations'];

    states[this.BODY_TYPES.EVENTS] = ['app.content.dataCollection', 'app.content.trackmaster'];

    states[this.BODY_TYPES.FUNNELS] = ['app.content.analytics', 'app.content.funnels'];

    states[this.BODY_TYPES.USERS] = ['app.content.live', 'app.content.users'];

    return states;
  }

  /**
   * Получение типа сообщения в зависимости от текущего состояния
   *
   * @returns {string}
   */
  getBodyTypeOfCurrentState(state: any) {
    var newBodyType = this.BODY_TYPES.DEFAULT;

    bodyTypeLoop: for (var bodyType in this.STATES_FOR_BODY_TYPES) {
      if (this.STATES_FOR_BODY_TYPES.hasOwnProperty(bodyType)) {
        var states = this.STATES_FOR_BODY_TYPES[bodyType];

        for (var i = 0; i < states.length; i++) {
          if (state.includes(states[i])) {
            newBodyType = bodyType;
            break bodyTypeLoop;
          }
        }
      }
    }

    return newBodyType;
  }

  /**
   * Скрывает тост
   */
  hideToast() {
    if (this.isToastShowed()) {
      this.toastr.remove(this.toast);
      this.toast = null;
      this.currentBodyType = null; // на всякий случай вместе с закрытием тоста очищаем и текущий тип сообщения
    }
  }

  /**
   * Показывается ли сейчас тост
   *
   * @returns {Boolean}
   */
  isToastShowed() {
    // !!! тут не просто так не используется toast.isOpened! Дело в том, что этот флаг устанавливается асинхронно, после полного закрытия тоста (смотри в исходниках angular-toastr)
    //  Из-за асинхронности был баг, при котором показывалось несколько тостов при переходе в воронки, т.к. там не простой роутинг. Поэтому было решено не использовать toast.isOpened, а проверять только наличие инстанса тоста
    return !!this.toast;
  }

  /** Перенаправление пользователя на таб с установкой сервиса в настройках */
  redirectToServiceInstallationSettingsTab(state: any) {
    state.go('app.content.settings.general', { tab: SETTINGS_TABS.SERVICE_INSTALLATION });
  }

  /**
   * Показывает новый тост или не делает ничего
   *
   * @param {Object=} app Приложение, исходя из данных которого принимается решение показывать тост или нет
   * @param {Object} user Джанго юзер
   * @param {Object} billing Информация о биллинге
   * @param {string} customText Кастомный тест для тоста
   * @param state
   */
  showToast(app: any, user: any, billing: any, state: any, customText: any) {
    var newBodyType;

    this.currentApp = app || this.currentApp;
    this.djangoUser = user;
    this.billingInfo = billing;

    // Операторам никогда не должны показывать тост
    if (this.djangoUserModel.isOperator(this.currentApp.id, this.djangoUser)) {
      return;
    }

    // получаем тип сообщения для текущего состояния
    newBodyType = this.getBodyTypeOfCurrentState(state);

    // если тип сообщения в новом тосте отличается от предыдущего - закрываем предыдущий тост
    if (this.currentBodyType !== newBodyType) {
      this.hideToast();
      this.currentBodyType = newBodyType;
    }

    if (!this.isToastShowed()) {
      const toastrText = customText
        ? customText
        : this.transloco.translate('services.serviceInstallationToast.' + this.currentBodyType);

      // создаём тост с пустым текстом, чтобы затем подставить скомпилированный контент
      this.toast = this.toastr.success(toastrText, undefined, {
        closeButton: true,
        delay: 0,
        onTap: () => this.redirectToServiceInstallationSettingsTab(state),
        classname: 'alert animate service-installation', // NOTE: я не знал где расположить css для этого кастомного алерта, поэтому положил его в app.css
        autohide: false,
      });
    }
  }
}
