import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFooterTemplateDirective, NgHeaderTemplateDirective, NgLabelTemplateDirective, NgMultiLabelTemplateDirective, NgOptgroupTemplateDirective, NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { CreateAutoEventModalModule } from '@panel/app/partials/modals/create-auto-event-modal/create-auto-event-modal.module';
import { CreateEventTypeModalModule } from '@panel/app/partials/modals/create-event-type-modal/create-event-type-modal.module';
import { AutoEventsDuplicatesHelperService } from '@panel/app/partials/track-master/services/duplicates-helper.service';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EventTypeSelectComponent } from './event-type-select.component';
import { FilterAndSortEventTypesPipe } from './filter-and-sort-event-types.pipe';

@NgModule({
  declarations: [EventTypeSelectComponent, FilterAndSortEventTypesPipe],
  imports: [
    AccessDenialPopoverModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    CreateAutoEventModalModule,
    CreateEventTypeModalModule,
    NgSelectComponent,
    NgHeaderTemplateDirective,
    NgMultiLabelTemplateDirective,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgOptgroupTemplateDirective,
    NgFooterTemplateDirective,
  ],
  exports: [EventTypeSelectComponent],
  providers: [AutoEventsDuplicatesHelperService],
})
export class EventTypeSelectModule {}
