import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionAssistantComponent } from './assistant.component';

@NgModule({
  declarations: [BranchActionAssistantComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
  exports: [BranchActionAssistantComponent],
})
export class BranchActionAssistantModule {}
