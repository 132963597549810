import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { EditorModule } from '@panel/app/partials/editor/editor.module';

import { EditorWrapperComponent } from './editor-wrapper.component';

@NgModule({
  declarations: [EditorWrapperComponent],
  exports: [EditorWrapperComponent],
  imports: [CommonModule, TranslocoDirective, FormsModule, EditorModule, ReactiveFormsModule],
})
export class EditorWrapperModule {}
