import { environment } from '@environment';
import {
  BILLING_ADD_ONS,
  BILLING_PLAN_IDS,
  UNIQUE_BILLING_PLAN_ID,
} from '@panel/app/services/billing-info/billing-info.constants';

/** Версии тарифных сеток */
export enum PAID_PLAN_VERSION {
  /** Доступ до фич осуществляется по наличию подключённых аддонов или подключённых капабилитис, но тариф бесплатный */
  FREEMIUM = 'freemium',
  /** Доступ до фич осуществляется по наличию подключённых аддонов или подключённых капабилитис, но количество фич ограничено */
  LTD = 'ltd',
  /** Доступ до фич осуществляется по наличию подключённых аддонов */
  V1 = 'v1',
  /** Доступ до фич осуществляется по наличию подключённых капабилитис */
  V2 = 'v2',
  /** Доступ до фич осуществляется по наличию подключённых аддонов или подключённых капабилитис */
  V3 = 'v3',
}

/** Даты обновления биллинга */
export enum BILLING_UPDATE_DATE {
  Y2021_M04_Dxx = '2021-10-xx', // Дата для тарифов V0421
  Y2021_M10_D26 = '2021-10-26', // Дата обновления цен на тарифы V2
  Y2022_M08_D15 = '2022-08-15', // Дата обновления цен на аддоны
  Y2022_M11_D02 = '2022-11-02', // Дата релиза тарифов V3
  Y2022_M02_D01 = '2023-02-01', // Дата обновления цен тарифов и создания аддонов с новыми ценами
  Y2024_M04_D17 = '2024-04-17', // Убрали 1000 и 5000 уников для CQ
}

/** Стоимость аддонов до их обновления */
export const BILLING_ADD_ON_PRICES_DEFAULT = {
  RU: {
    [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS]: 1000,
    [BILLING_ADD_ONS.CHAT_BOTS]: 2000,
    [BILLING_ADD_ONS.BEE_EDITOR]: 1000,
    [BILLING_ADD_ONS.BEE_EDITOR_V2]: 1200,
    [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE]: 2000,
    [BILLING_ADD_ONS.MAIL_DOMAIN]: 1000,
    [BILLING_ADD_ONS.MARKETING]: 140000,
    [BILLING_ADD_ONS.NO_BRANDING]: 1000,
    [BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING]: {
      '1000': 990,
      '5000': 1490,
      '10000': 2290,
      '30000': 2990,
      '60000': 3790,
      '100000': 4490,
      '150000': 5490,
      '200000': 6490,
      '300000': 7890,
      '400000': 8790,
      '500000': 9690,
      '700000': 10590,
    },
    [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING]: {
      '1000': 590,
      '5000': 890,
      '10000': 1590,
      '30000': 3190,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
      '1000': 700,
      '5000': 990,
      '10000': 1990,
      '30000': 3990,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING]: {
      '1000': 190,
      '5000': 290,
      '10000': 490,
      '30000': 890,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224]: {
      '1000': 250,
      '5000': 350,
      '10000': 590,
      '30000': 990,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING]: {
      '1000': 390,
      '5000': 590,
      '10000': 790,
      '30000': 1590,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224]: {
      '1000': 450,
      '5000': 690,
      '10000': 990,
      '30000': 1900,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.SECURITY]: 0,
    [BILLING_ADD_ONS.TELEGRAM_BOT]: 1390,
    [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING]: {
      '1000': 590,
      '5000': 890,
      '10000': 1590,
      '30000': 3190,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
      '1000': 700,
      '5000': 990,
      '10000': 1990,
      '30000': 3990,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING]: {
      '1000': 990,
      '5000': 1290,
      '10000': 2390,
      '30000': 4790,
      '60000': null,
      '150000': null,
      '300000': null,
    },
    [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING_V0224]: {
      '1000': 1200,
      '5000': 1590,
      '10000': 2790,
      '30000': 5450,
      '60000': null,
      '150000': null,
      '300000': null,
    },
  },
  US: {
    [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS]: 15,
    [BILLING_ADD_ONS.CHAT_BOTS]: 30,
    [BILLING_ADD_ONS.BEE_EDITOR]: 19,
    [BILLING_ADD_ONS.BEE_EDITOR_V2]: 20,
    [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE]: 15,
    [BILLING_ADD_ONS.MAIL_DOMAIN]: 15,
    [BILLING_ADD_ONS.MARKETING]: 1000,
    [BILLING_ADD_ONS.NO_BRANDING]: 15,
    [BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING]: {
      '1000': 15,
      '5000': 25,
      '10000': 35,
      '25000': 45,
      '50000': 59,
      '100000': 69,
      '150000': 79,
      '200000': 99,
    },
    [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING]: {
      '1000': 21,
      '5000': 33,
      '10000': 39,
      '25000': 69,
      '50000': 95,
      '100000': 119,
      '150000': 159,
      '200000': 199,
    },
    [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING]: {
      '1000': 5,
      '5000': 12,
      '10000': 15,
      '25000': 24,
      '50000': 33,
      '100000': 42,
      '150000': 53,
      '200000': 70,
    },
    [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING]: {
      '1000': 10,
      '5000': 19,
      '10000': 25,
      '25000': 39,
      '50000': 55,
      '100000': 59,
      '150000': 89,
      '200000': 119,
    },
    [BILLING_ADD_ONS.SECURITY]: 0,
    [BILLING_ADD_ONS.TELEGRAM_BOT]: 29,
    [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING]: {
      '1000': 21,
      '5000': 33,
      '10000': 39,
      '25000': 69,
      '50000': 95,
      '100000': 119,
      '150000': 159,
      '200000': 199,
    },
    [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING]: {
      '1000': 29,
      '5000': 49,
      '10000': 59,
      '25000': 99,
      '50000': 139,
      '100000': 179,
      '150000': 239,
      '200000': 299,
    },
  },
};

/** Стоимость аддонов до их обновления */
export const BILLING_ADD_ON_PRICES_UPDATED = {
  [BILLING_UPDATE_DATE.Y2024_M04_D17]: {
    RU: {
      [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS]: 1000,
      [BILLING_ADD_ONS.CHAT_BOTS]: 2000,
      [BILLING_ADD_ONS.BEE_EDITOR]: 1000,
      [BILLING_ADD_ONS.BEE_EDITOR_V2]: 1200,
      [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE]: 2000,
      [BILLING_ADD_ONS.MAIL_DOMAIN]: 1000,
      [BILLING_ADD_ONS.MARKETING]: 140000,
      [BILLING_ADD_ONS.NO_BRANDING]: 1000,
      [BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING]: {
        '10000': 2290,
        '30000': 2990,
        '60000': 3790,
        '100000': 4490,
        '150000': 5490,
        '200000': 6490,
        '300000': 7890,
        '400000': 8790,
        '500000': 9690,
        '700000': 10590,
      },
      [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING]: {
        '10000': 1590,
        '30000': 3190,
        '60000': 4990,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
        '10000': 1990,
        '30000': 3990,
        '60000': 4990,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING]: {
        '10000': 490,
        '30000': 890,
        '60000': 1590,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224]: {
        '10000': 590,
        '30000': 990,
        '60000': 1590,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING]: {
        '10000': 790,
        '30000': 1590,
        '60000': 2690,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224]: {
        '10000': 990,
        '30000': 1900,
        '60000': 2690,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.SECURITY]: 0,
      [BILLING_ADD_ONS.TELEGRAM_BOT]: 1390,
      [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING]: {
        '10000': 1590,
        '30000': 3190,
        '60000': 4990,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
        '10000': 1990,
        '30000': 3990,
        '60000': 4990,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING]: {
        '10000': 2390,
        '30000': 4790,
        '60000': 7990,
        '150000': null,
        '300000': null,
      },
      [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING_V0224]: {
        '10000': 2790,
        '30000': 5450,
        '60000': 7990,
        '150000': null,
        '300000': null,
      },
    },
    US: BILLING_ADD_ON_PRICES_DEFAULT.US,
  },
};

/** Ключ перевода для описания capability-тарифов до обновлений их конфигураций */
export const DESCRIPTION_TRANSLATE_KEY_DEFAULT = {
  RU: 'default',
  US: 'default',
};

/** Ключ перевода для описания capability-тарифов после обновлений их конфигураций */
export const DESCRIPTION_TRANSLATE_KEY_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: 'updated',
    US: DESCRIPTION_TRANSLATE_KEY_DEFAULT.US,
  },
};

/** Периоды оплат */
export enum PLANS_PERIODS {
  MONTHLY = 'monthly',
  QUARTER = 'quarter',
  SIX_MONTHS = 'six_months',
  ANNUALLY = 'annually',
}

/** Скидка в процентах при разных периодах оплаты на capability-тарифах до обновлений их конфигураций */
export const DISCOUNTS_BY_PERIOD_DEFAULT = {
  RU: {
    [PLANS_PERIODS.MONTHLY]: 0,
    [PLANS_PERIODS.QUARTER]: 0,
    [PLANS_PERIODS.SIX_MONTHS]: 0.15,
    [PLANS_PERIODS.ANNUALLY]: 0.2,
    //[PLANS_PERIODS.ANNUALLY]: 0.35, // Новогодняя акция
  },
  US: {
    [PLANS_PERIODS.ANNUALLY]: 0.2,
    //[PLANS_PERIODS.ANNUALLY]: 0.35, // Новогодняя акция
    [PLANS_PERIODS.MONTHLY]: 0,
  },
};

/** Скидка в процентах при разных периодах оплаты на capability-тарифах после обновлений их конфигураций */
export const DISCOUNTS_BY_PERIOD_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: {
      [PLANS_PERIODS.MONTHLY]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.MONTHLY],
      [PLANS_PERIODS.QUARTER]: 0.1,
      [PLANS_PERIODS.SIX_MONTHS]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.SIX_MONTHS],
      [PLANS_PERIODS.ANNUALLY]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.ANNUALLY],
    },
    US: DISCOUNTS_BY_PERIOD_DEFAULT.US,
  },
  [BILLING_UPDATE_DATE.Y2021_M04_Dxx]: {
    RU: {
      [PLANS_PERIODS.MONTHLY]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.MONTHLY],
      [PLANS_PERIODS.QUARTER]: 0.1,
      [PLANS_PERIODS.SIX_MONTHS]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.SIX_MONTHS],
      [PLANS_PERIODS.ANNUALLY]: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.ANNUALLY],
    },
    US: DISCOUNTS_BY_PERIOD_DEFAULT.US,
  },
};

/** Скидка в процентах для пользователей приложения, зарегистрированного по affiliate программе FirstPromoter */
export const DISCOUNTS_BY_PERIOD_AFFILIATE = {
  RU: {
    [PLANS_PERIODS.MONTHLY]: 0.1,
    [PLANS_PERIODS.QUARTER]: 0.2,
    [PLANS_PERIODS.SIX_MONTHS]: 0.25,
    [PLANS_PERIODS.ANNUALLY]: 0.3,
  },
  US: {
    [PLANS_PERIODS.ANNUALLY]: 0.3,
    [PLANS_PERIODS.MONTHLY]: 0.1,
  },
};

export const PRICING_DEFAULT_PAYMENT_PERIOD: { [K in typeof environment.country]: PLANS_PERIODS } = {
  RU: PLANS_PERIODS.QUARTER,
  //RU: PLANS_PERIODS.ANNUALLY, // Новогодняя акция
  US: PLANS_PERIODS.ANNUALLY,
};

/** Скидка выбранная за пользователя на capability-тарифах до обновлений их конфигураций */
export const CHOSEN_DISCOUNT_DEFAULT = {
  RU: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.QUARTER],
  //RU: DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.ANNUALLY], // Новогодняя акция
  US: DISCOUNTS_BY_PERIOD_DEFAULT.US[PLANS_PERIODS.ANNUALLY],
};

/** Скидка выбранная за пользователя на capability-тарифах после обновлений их конфигураций */
export const CHOSEN_DISCOUNT_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: DISCOUNTS_BY_PERIOD_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26].RU[PLANS_PERIODS.ANNUALLY],
    US: DISCOUNTS_BY_PERIOD_DEFAULT.US[PLANS_PERIODS.ANNUALLY],
  },
};

/** Скидка выбранная за пользователя в приложении, зарегистрированного по affiliate программе FirstPromoter */
export const CHOSEN_DISCOUNT_AFFILIATE = {
  RU: DISCOUNTS_BY_PERIOD_AFFILIATE.RU[PLANS_PERIODS.QUARTER],
  US: DISCOUNTS_BY_PERIOD_AFFILIATE.US[PLANS_PERIODS.ANNUALLY],
};

/** Количество месяцев в зависимости от размера скидки за период на capability-тарифах до обновлений их конфигурации */
export const MONTHS_IN_DISCOUNTS_BY_PERIOD_DEFAULT = {
  RU: {
    [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.ANNUALLY]]: 12,
    [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.SIX_MONTHS]]: 6,
    [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.QUARTER]]: 3,
    [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.MONTHLY]]: 1,
  },
  US: {
    [DISCOUNTS_BY_PERIOD_DEFAULT.US[PLANS_PERIODS.ANNUALLY]]: 12,
    [DISCOUNTS_BY_PERIOD_DEFAULT.US[PLANS_PERIODS.MONTHLY]]: 1,
  },
};

/** Количество месяцев в зависимости от размера скидки за период на capability-тарифах после обновлений их конфигураций */
export const MONTHS_IN_DISCOUNTS_BY_PERIOD_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: {
      [DISCOUNTS_BY_PERIOD_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26].RU[PLANS_PERIODS.ANNUALLY]]: 12,
      [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.SIX_MONTHS]]: 6,
      [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.QUARTER]]: 3,
      [DISCOUNTS_BY_PERIOD_DEFAULT.RU[PLANS_PERIODS.MONTHLY]]: 1,
    },
    US: MONTHS_IN_DISCOUNTS_BY_PERIOD_DEFAULT.US,
  },
};

/** Количество месяцев в периоде */
export const MONTHS_BY_PERIOD = {
  [PLANS_PERIODS.MONTHLY]: 1,
  [PLANS_PERIODS.QUARTER]: 3,
  [PLANS_PERIODS.SIX_MONTHS]: 6,
  [PLANS_PERIODS.ANNUALLY]: 12,
};

/**
 * Количество месяцев в зависимости от размера скидки за период на приложении,
 * зарегистрированного по affiliate программе FirstPromoter
 */
export const MONTHS_IN_DISCOUNTS_BY_PERIOD_AFFILIATE = {
  RU: {
    [DISCOUNTS_BY_PERIOD_AFFILIATE.RU[PLANS_PERIODS.ANNUALLY]]: 12,
    [DISCOUNTS_BY_PERIOD_AFFILIATE.RU[PLANS_PERIODS.SIX_MONTHS]]: 6,
    [DISCOUNTS_BY_PERIOD_AFFILIATE.RU[PLANS_PERIODS.QUARTER]]: 3,
    [DISCOUNTS_BY_PERIOD_AFFILIATE.RU[PLANS_PERIODS.MONTHLY]]: 1,
  },
  US: {
    [DISCOUNTS_BY_PERIOD_AFFILIATE.US[PLANS_PERIODS.ANNUALLY]]: 12,
    [DISCOUNTS_BY_PERIOD_AFFILIATE.US[PLANS_PERIODS.MONTHLY]]: 1,
  },
};

/** Лимиты уникальных пользователей на capability-тарифах до обновлений их конфигураций */
export const LIMIT_UNIQUE_USERS_DEFAULT = {
  RU: 700001,
  US: 200001,
};

/** Лимиты уникальных пользователей на capability-тарифах после обновлений их конфигураций */
export const LIMIT_UNIQUE_USERS_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: LIMIT_UNIQUE_USERS_DEFAULT.RU,
    US: LIMIT_UNIQUE_USERS_DEFAULT.US,
  },
  [BILLING_UPDATE_DATE.Y2022_M11_D02]: {
    RU: 300001,
    US: LIMIT_UNIQUE_USERS_DEFAULT.US,
  },
};

/** Конфигурация capability-тарифов до обновлений их конфигураций */
export const PLANS_SETTINGS_DEFAULT = {
  RU: {
    [BILLING_PLAN_IDS.BUSINESS_CHAT]: {
      '10000': {
        PRICE: 2100,
        EMAILS: 1250,
      },
      '30000': {
        PRICE: 4200,
        EMAILS: 5000,
      },
      '60000': {
        PRICE: 7000,
        EMAILS: 7500,
      },
      '100000': {
        PRICE: 10500,
        EMAILS: 10000,
      },
      '150000': {
        PRICE: 14000,
        EMAILS: 15000,
      },
      '200000': {
        PRICE: 18200,
        EMAILS: 20000,
      },
      '300000': {
        PRICE: 25200,
        EMAILS: 25000,
      },
      '400000': {
        PRICE: 33600,
        EMAILS: 30000,
      },
      '500000': {
        PRICE: 38500,
        EMAILS: 40000,
      },
      '700000': {
        PRICE: 49000,
        EMAILS: 50000,
      },
      '700001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.AUTOMATION]: {
      '10000': {
        PRICE: 4500,
        EMAILS: 2500,
      },
      '30000': {
        PRICE: 9000,
        EMAILS: 10000,
      },
      '60000': {
        PRICE: 15000,
        EMAILS: 15000,
      },
      '100000': {
        PRICE: 22500,
        EMAILS: 20000,
      },
      '150000': {
        PRICE: 30000,
        EMAILS: 30000,
      },
      '200000': {
        PRICE: 39000,
        EMAILS: 40000,
      },
      '300000': {
        PRICE: 54000,
        EMAILS: 50000,
      },
      '400000': {
        PRICE: 72000,
        EMAILS: 60000,
      },
      '500000': {
        PRICE: 82500,
        EMAILS: 80000,
      },
      '700000': {
        PRICE: 105000,
        EMAILS: 100000,
      },
      '700001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.CONVERSATION]: {
      '10000': {
        PRICE: 3990,
        EMAILS: 1250,
      },
      '30000': {
        PRICE: 8990,
        EMAILS: 5000,
      },
      '60000': {
        PRICE: null,
        EMAILS: null,
      },
      '150000': {
        PRICE: null,
        EMAILS: null,
      },
      '300000': {
        PRICE: null,
        EMAILS: null,
      },
      '300001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.SUPPORT]: {
      '10000': {
        PRICE: 6990,
        EMAILS: 1875,
      },
      '30000': {
        PRICE: 12990,
        EMAILS: 7500,
      },
      '60000': {
        PRICE: null,
        EMAILS: null,
      },
      '150000': {
        PRICE: null,
        EMAILS: null,
      },
      '300000': {
        PRICE: null,
        EMAILS: null,
      },
      '300001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.MARKETING]: {
      '10000': {
        PRICE: 9990,
        EMAILS: 2500,
      },
      '30000': {
        PRICE: 18990,
        EMAILS: 10000,
      },
      '60000': {
        PRICE: null,
        EMAILS: null,
      },
      '150000': {
        PRICE: null,
        EMAILS: null,
      },
      '300000': {
        PRICE: null,
        EMAILS: null,
      },
      '300001': {
        PRICE: null,
        EMAILS: null,
      },
    },
  },
  US: {
    [BILLING_PLAN_IDS.FREEMIUM]: {
      '1000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '5000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '10000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '25000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '50000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '100000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '150000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '200000': {
        PRICE: 0,
        EMAILS: 100,
      },
      '200001': {
        PRICE: 0,
        EMAILS: 100,
      },
    },
    [BILLING_PLAN_IDS.BUSINESS_CHAT]: {
      '1000': {
        PRICE: 39,
        EMAILS: 200,
      },
      '5000': {
        PRICE: 79,
        EMAILS: 600,
      },
      '10000': {
        PRICE: 99,
        EMAILS: 1000,
      },
      '25000': {
        PRICE: 159,
        EMAILS: 2000,
      },
      '50000': {
        PRICE: 219,
        EMAILS: 4000,
      },
      '100000': {
        PRICE: 279,
        EMAILS: 8000,
      },
      '150000': {
        PRICE: 349,
        EMAILS: 12000,
      },
      '200000': {
        PRICE: 469,
        EMAILS: 16000,
      },
      '200001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.AUTOMATION]: {
      '1000': {
        PRICE: 109,
        EMAILS: 500,
      },
      '5000': {
        PRICE: 169,
        EMAILS: 1500,
      },
      '10000': {
        PRICE: 199,
        EMAILS: 2500,
      },
      '25000': {
        PRICE: 329,
        EMAILS: 5000,
      },
      '50000': {
        PRICE: 479,
        EMAILS: 10000,
      },
      '100000': {
        PRICE: 599,
        EMAILS: 20000,
      },
      '150000': {
        PRICE: 799,
        EMAILS: 30000,
      },
      '200000': {
        PRICE: 999,
        EMAILS: 40000,
      },
      '200001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.CONVERSATION]: {
      '1000': {
        PRICE: 39,
        EMAILS: 200,
      },
      '5000': {
        PRICE: 79,
        EMAILS: 600,
      },
      '10000': {
        PRICE: 99,
        EMAILS: 1000,
      },
      '25000': {
        PRICE: 159,
        EMAILS: 2000,
      },
      '50000': {
        PRICE: 219,
        EMAILS: 4000,
      },
      '100000': {
        PRICE: 279,
        EMAILS: 8000,
      },
      '150000': {
        PRICE: 349,
        EMAILS: 12000,
      },
      '200000': {
        PRICE: 469,
        EMAILS: 16000,
      },
      '200001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.SUPPORT]: {
      '1000': {
        PRICE: 79,
        EMAILS: 350,
      },
      '5000': {
        PRICE: 119,
        EMAILS: 1550,
      },
      '10000': {
        PRICE: 149,
        EMAILS: 5500,
      },
      '25000': {
        PRICE: 239,
        EMAILS: 8500,
      },
      '50000': {
        PRICE: 349,
        EMAILS: 12000,
      },
      '100000': {
        PRICE: 439,
        EMAILS: 19000,
      },
      '150000': {
        PRICE: 569,
        EMAILS: 26000,
      },
      '200000': {
        PRICE: 729,
        EMAILS: 33000,
      },
      '200001': {
        PRICE: null,
        EMAILS: null,
      },
    },
    [BILLING_PLAN_IDS.MARKETING]: {
      '1000': {
        PRICE: 109,
        EMAILS: 500,
      },
      '5000': {
        PRICE: 169,
        EMAILS: 2500,
      },
      '10000': {
        PRICE: 199,
        EMAILS: 10000,
      },
      '25000': {
        PRICE: 329,
        EMAILS: 15000,
      },
      '50000': {
        PRICE: 479,
        EMAILS: 20000,
      },
      '100000': {
        PRICE: 599,
        EMAILS: 30000,
      },
      '150000': {
        PRICE: 799,
        EMAILS: 40000,
      },
      '200000': {
        PRICE: 999,
        EMAILS: 50000,
      },
      '200001': {
        PRICE: null,
        EMAILS: null,
      },
    },
  },
};

/** Конфиг рация capability-тарифов после обновлений их конфигураций */
export const PLANS_SETTINGS_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: {
      [UNIQUE_BILLING_PLAN_ID.BUSINESS_CHAT_V1021]: {
        '10000': {
          PRICE: 3090,
          EMAILS: 1250,
        },
        '30000': {
          PRICE: 6290,
          EMAILS: 5000,
        },
        '60000': {
          PRICE: 8990,
          EMAILS: 7500,
        },
        '100000': {
          PRICE: 10990,
          EMAILS: 10000,
        },
        '150000': {
          PRICE: 13990,
          EMAILS: 15000,
        },
        '200000': {
          PRICE: 17990,
          EMAILS: 20000,
        },
        '300000': {
          PRICE: 24990,
          EMAILS: 25000,
        },
        '400000': {
          PRICE: 33490,
          EMAILS: 30000,
        },
        '500000': {
          PRICE: 38490,
          EMAILS: 40000,
        },
        '700000': {
          PRICE: 48990,
          EMAILS: 50000,
        },
        '700001': {
          PRICE: null,
          EMAILS: null,
        },
      },
      [UNIQUE_BILLING_PLAN_ID.AUTOMATION_V1021]: {
        '10000': {
          PRICE: 7990,
          EMAILS: 2500,
        },
        '30000': {
          PRICE: 15990,
          EMAILS: 10000,
        },
        '60000': {
          PRICE: 22490,
          EMAILS: 15000,
        },
        '100000': {
          PRICE: 28990,
          EMAILS: 20000,
        },
        '150000': {
          PRICE: 38990,
          EMAILS: 30000,
        },
        '200000': {
          PRICE: 46790,
          EMAILS: 40000,
        },
        '300000': {
          PRICE: 58990,
          EMAILS: 50000,
        },
        '400000': {
          PRICE: 71990,
          EMAILS: 60000,
        },
        '500000': {
          PRICE: 82490,
          EMAILS: 80000,
        },
        '700000': {
          PRICE: 104990,
          EMAILS: 100000,
        },
        '700001': {
          PRICE: null,
          EMAILS: null,
        },
      },
    },
    US: PLANS_SETTINGS_DEFAULT.US,
  },
  [BILLING_UPDATE_DATE.Y2024_M04_D17]: {
    RU: {
      [BILLING_PLAN_IDS.CONVERSATION]: {
        '10000': {
          PRICE: 3990,
          EMAILS: 1250,
        },
        '30000': {
          PRICE: 8990,
          EMAILS: 5000,
        },
        '60000': {
          PRICE: 10990,
          EMAILS: 7500,
        },
        '150000': {
          PRICE: null,
          EMAILS: null,
        },
        '300000': {
          PRICE: null,
          EMAILS: null,
        },
        '300001': {
          PRICE: null,
          EMAILS: null,
        },
      },
      [BILLING_PLAN_IDS.SUPPORT]: {
        '10000': {
          PRICE: 6990,
          EMAILS: 1875,
        },
        '30000': {
          PRICE: 12990,
          EMAILS: 7500,
        },
        '60000': {
          PRICE: 18990,
          EMAILS: 11250,
        },
        '150000': {
          PRICE: null,
          EMAILS: null,
        },
        '300000': {
          PRICE: null,
          EMAILS: null,
        },
        '300001': {
          PRICE: null,
          EMAILS: null,
        },
      },
      [BILLING_PLAN_IDS.MARKETING]: {
        '10000': {
          PRICE: 9990,
          EMAILS: 2500,
        },
        '30000': {
          PRICE: 18990,
          EMAILS: 10000,
        },
        '60000': {
          PRICE: 26990,
          EMAILS: 15000,
        },
        '150000': {
          PRICE: null,
          EMAILS: null,
        },
        '300000': {
          PRICE: null,
          EMAILS: null,
        },
        '300001': {
          PRICE: null,
          EMAILS: null,
        },
      },
    },
    US: PLANS_SETTINGS_DEFAULT.US,
  },
};

/** Наборы capability-тарифов после обновлений их конфигураций */
export const PLANS_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: {
      AUTOMATION: UNIQUE_BILLING_PLAN_ID.AUTOMATION_V1021,
      BUSINESS_CHAT: UNIQUE_BILLING_PLAN_ID.BUSINESS_CHAT_V1021,
      FREEMIUM: '',
      FREE_TRIAL: UNIQUE_BILLING_PLAN_ID.FREE_TRIAL,
      FREE_FOREVER: UNIQUE_BILLING_PLAN_ID.FREE_FOREVER,
      LIFE_TIME_DEAL: '',
      LIFE_TIME_DEAL_PRO: '',
      LIFE_TIME_DEAL_BUSINESS: '',
      LIFE_TIME_DEAL_15K: '',
      LIFE_TIME_DEAL_20K: '',
    },
    US: BILLING_PLAN_IDS,
  },
};

/** Стоимости за перерасход уникальных пользователей на capability-тарифах до обновлений их конфигураций */
export const PRICES_AFTER_OVER_LIMIT_UNIQUE_USERS_DEFAULT = {
  RU: {
    [BILLING_PLAN_IDS.BUSINESS_CHAT]: {
      '1000': {
        userPrice: 1.2,
      },
      '10000': {
        userPrice: 0.27,
      },
      '30000': {
        userPrice: 0.18,
      },
      '60000': {
        userPrice: 0.15,
      },
      '100000': {
        userPrice: 0.14,
      },
      '150000': {
        userPrice: 0.12,
      },
      '200000': {
        userPrice: 0.12,
      },
      '300000': {
        userPrice: 0.11,
      },
      '400000': {
        userPrice: 0.11,
      },
      '500000': {
        userPrice: 0.1,
      },
      '700000': {
        userPrice: 0.09,
      },
    },
    [BILLING_PLAN_IDS.AUTOMATION]: {
      '1000': {
        userPrice: 1.85,
      },
      '10000': {
        userPrice: 0.55,
      },
      '30000': {
        userPrice: 0.37,
      },
      '60000': {
        userPrice: 0.31,
      },
      '100000': {
        userPrice: 0.28,
      },
      '150000': {
        userPrice: 0.25,
      },
      '200000': {
        userPrice: 0.24,
      },
      '300000': {
        userPrice: 0.22,
      },
      '400000': {
        userPrice: 0.22,
      },
      '500000': {
        userPrice: 0.2,
      },
      '700000': {
        userPrice: 0.18,
      },
    },
    [BILLING_PLAN_IDS.CONVERSATION]: {
      '1000': {
        userPrice: 1.6,
      },
      '5000': {
        userPrice: 0.6,
      },
      '10000': {
        userPrice: 0.42,
      },
      '30000': {
        userPrice: 0.29,
      },
      '60000': {
        userPrice: 0.17,
      },
      '100000': {
        userPrice: 0.14,
      },
      '150000': {
        userPrice: 0.12,
      },
      '200000': {
        userPrice: 0.12,
      },
      '300000': {
        userPrice: 0.11,
      },
      '400000': {
        userPrice: 0.11,
      },
      '500000': {
        userPrice: 0.1,
      },
      '700000': {
        userPrice: 0.09,
      },
      '900000': {
        userPrice: 0.09,
      },
      '1000000': {
        userPrice: 0.09,
      },
      '1500000': {
        userPrice: 0.09,
      },
      '2000000': {
        userPrice: 0.09,
      },
      '2500000': {
        userPrice: 0.09,
      },
    },
    [BILLING_PLAN_IDS.SUPPORT]: {
      '1000': {
        userPrice: 2.4,
      },
      '5000': {
        userPrice: 0.87,
      },
      '10000': {
        userPrice: 0.63,
      },
      '30000': {
        userPrice: 0.42,
      },
      '60000': {
        userPrice: 0.31,
      },
      '100000': {
        userPrice: 0.14,
      },
      '150000': {
        userPrice: 0.12,
      },
      '200000': {
        userPrice: 0.12,
      },
      '300000': {
        userPrice: 0.11,
      },
      '400000': {
        userPrice: 0.11,
      },
      '500000': {
        userPrice: 0.1,
      },
      '700000': {
        userPrice: 0.09,
      },
      '900000': {
        userPrice: 0.09,
      },
      '1000000': {
        userPrice: 0.09,
      },
      '1500000': {
        userPrice: 0.09,
      },
      '2000000': {
        userPrice: 0.09,
      },
      '2500000': {
        userPrice: 0.09,
      },
    },
    [BILLING_PLAN_IDS.MARKETING]: {
      '1000': {
        userPrice: 3.73,
      },
      '5000': {
        userPrice: 1.1,
      },
      '10000': {
        userPrice: 0.88,
      },
      '30000': {
        userPrice: 0.59,
      },
      '60000': {
        userPrice: 0.41,
      },
      '100000': {
        userPrice: 0.28,
      },
      '150000': {
        userPrice: 0.25,
      },
      '200000': {
        userPrice: 0.24,
      },
      '300000': {
        userPrice: 0.22,
      },
      '400000': {
        userPrice: 0.22,
      },
      '500000': {
        userPrice: 0.2,
      },
      '700000': {
        userPrice: 0.18,
      },
      '900000': {
        userPrice: 0.18,
      },
      '1000000': {
        userPrice: 0.18,
      },
      '1500000': {
        userPrice: 0.18,
      },
      '2000000': {
        userPrice: 0.18,
      },
      '2500000': {
        userPrice: 0.18,
      },
    },
  },
  US: {
    [BILLING_PLAN_IDS.BUSINESS_CHAT]: {
      '1000': {
        userPrice: 0.023,
      },
      '5000': {
        userPrice: 0.007,
      },
      '10000': {
        userPrice: 0.006,
      },
      '25000': {
        userPrice: 0.004,
      },
      '50000': {
        userPrice: 0.003,
      },
      '100000': {
        userPrice: 0.002,
      },
      '150000': {
        userPrice: 0.002,
      },
      '200000': {
        userPrice: 0.001,
      },
    },
    [BILLING_PLAN_IDS.AUTOMATION]: {
      '1000': {
        userPrice: 0.084,
      },
      '5000': {
        userPrice: 0.025,
      },
      '10000': {
        userPrice: 0.021,
      },
      '25000': {
        userPrice: 0.013,
      },
      '50000': {
        userPrice: 0.009,
      },
      '100000': {
        userPrice: 0.006,
      },
      '150000': {
        userPrice: 0.006,
      },
      '200000': {
        userPrice: 0.005,
      },
    },
    [BILLING_PLAN_IDS.CONVERSATION]: {
      '1000': {
        userPrice: 0.023,
      },
      '5000': {
        userPrice: 0.007,
      },
      '10000': {
        userPrice: 0.006,
      },
      '25000': {
        userPrice: 0.004,
      },
      '50000': {
        userPrice: 0.003,
      },
      '100000': {
        userPrice: 0.002,
      },
      '150000': {
        userPrice: 0.002,
      },
      '200000': {
        userPrice: 0.001,
      },
      '250000': {
        userPrice: 0.001,
      },
      '400000': {
        userPrice: 0.001,
      },
      '800000': {
        userPrice: 0.001,
      },
      '1300000': {
        userPrice: 0.001,
      },
    },
    [BILLING_PLAN_IDS.SUPPORT]: {
      '1000': {
        userPrice: 0.0909,
      },
      '5000': {
        userPrice: 0.0274,
      },
      '10000': {
        userPrice: 0.0171,
      },
      '25000': {
        userPrice: 0.011,
      },
      '50000': {
        userPrice: 0.008,
      },
      '100000': {
        userPrice: 0.005,
      },
      '150000': {
        userPrice: 0.0044,
      },
      '200000': {
        userPrice: 0.0042,
      },
      '250000': {
        userPrice: 0.0042,
      },
      '400000': {
        userPrice: 0.0034,
      },
      '800000': {
        userPrice: 0.0029,
      },
    },
    [BILLING_PLAN_IDS.MARKETING]: {
      '1000': {
        userPrice: 0.1199,
      },
      '5000': {
        userPrice: 0.0372,
      },
      '10000': {
        userPrice: 0.0219,
      },
      '25000': {
        userPrice: 0.0145,
      },
      '50000': {
        userPrice: 0.0105,
      },
      '100000': {
        userPrice: 0.0066,
      },
      '150000': {
        userPrice: 0.0059,
      },
      '200000': {
        userPrice: 0.0055,
      },
      '250000': {
        userPrice: 0.0053,
      },
      '400000': {
        userPrice: 0.0044,
      },
      '800000': {
        userPrice: 0.0038,
      },
      '1300000': {
        userPrice: 0.0038,
      },
    },
  },
};

/** Стоимости за перерасход уникальных пользователей на capability-тарифах после обновлений их конфигураций */
export const PRICES_AFTER_OVER_LIMIT_UNIQUE_USERS_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: {
      [UNIQUE_BILLING_PLAN_ID.BUSINESS_CHAT_V1021]: {
        '1000': {
          userPrice: 1.3,
        },
        '5000': {
          userPrice: 0.48,
        },
        '10000': {
          userPrice: 0.36,
        },
        '30000': {
          userPrice: 0.24,
        },
        '60000': {
          userPrice: 0.17,
        },
        '100000': {
          userPrice: 0.14,
        },
        '150000': {
          userPrice: 0.12,
        },
        '200000': {
          userPrice: 0.12,
        },
        '300000': {
          userPrice: 0.11,
        },
        '400000': {
          userPrice: 0.11,
        },
        '500000': {
          userPrice: 0.1,
        },
        '700000': {
          userPrice: 0.09,
        },
      },
      [UNIQUE_BILLING_PLAN_ID.AUTOMATION_V1021]: {
        '1000': {
          userPrice: 2.8,
        },
        '5000': {
          userPrice: 0.99,
        },
        '10000': {
          userPrice: 0.88,
        },
        '30000': {
          userPrice: 0.59,
        },
        '60000': {
          userPrice: 0.41,
        },
        '100000': {
          userPrice: 0.28,
        },
        '150000': {
          userPrice: 0.25,
        },
        '200000': {
          userPrice: 0.24,
        },
        '300000': {
          userPrice: 0.22,
        },
        '400000': {
          userPrice: 0.22,
        },
        '500000': {
          userPrice: 0.2,
        },
        '700000': {
          userPrice: 0.18,
        },
      },
    },
    US: PRICES_AFTER_OVER_LIMIT_UNIQUE_USERS_DEFAULT.US,
  },
};

/** Шкала уникальных пользователей на capability-тарифах до обновлений их конфигураций */
export const SCALE_UNIQUE_USERS_DEFAULT = {
  RU: [
    {
      value: 10000,
    },
    {
      value: 30000,
    },
    {
      value: 60000,
    },
    {
      value: 100000,
    },
    {
      value: 150000,
    },
    {
      value: 200000,
    },
    {
      value: 300000,
    },
    {
      value: 400000,
    },
    {
      value: 500000,
    },
    {
      value: 700000,
    },
  ],
  US: [
    {
      value: 1000,
    },
    {
      value: 5000,
    },
    {
      value: 10000,
    },
    {
      value: 25000,
    },
    {
      value: 50000,
    },
    {
      value: 100000,
    },
    {
      value: 150000,
    },
    {
      value: 200000,
    },
  ],
};

/** Шкала уникальных пользователей на capability-тарифах после обновлений их конфигураций */
export const SCALE_UNIQUE_USERS_UPDATED = {
  [BILLING_UPDATE_DATE.Y2021_M10_D26]: {
    RU: [
      {
        value: 10000,
      },
      {
        value: 30000,
      },
      {
        value: 60000,
      },
      {
        value: 100000,
      },
      {
        value: 150000,
      },
      {
        value: 200000,
      },
      {
        value: 300000,
      },
      {
        value: 400000,
      },
      {
        value: 500000,
      },
      {
        value: 700000,
      },
    ],
    US: SCALE_UNIQUE_USERS_DEFAULT.US,
  },
  [BILLING_UPDATE_DATE.Y2022_M11_D02]: {
    RU: [
      {
        value: 10000,
      },
      {
        value: 30000,
      },
      {
        value: 60000,
      },
      {
        value: 150000,
      },
      {
        value: 300000,
      },
    ],
    US: SCALE_UNIQUE_USERS_DEFAULT.US,
  },
  [BILLING_UPDATE_DATE.Y2024_M04_D17]: {
    RU: [
      {
        value: 10000,
      },
      {
        value: 30000,
      },
      {
        value: 60000,
      },
      {
        value: 150000,
      },
      {
        value: 300000,
      },
    ],
    US: SCALE_UNIQUE_USERS_DEFAULT.US,
  },
};
