<ng-container *transloco="let t; read: 'articleEditComponent'">
  <section id="content">
    <cq-articles-limitation-banner
      *ngIf="!accessToKnowledgeBasePro.hasAccess"
      [billingInfo]="billingInfo"
      [currentApp]="currentApp"
    ></cq-articles-limitation-banner>
    <div class="container-fluid limited-container">
      <div class="flex justify-space-between align-items-center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a
                href
                uiSref="app.content.knowledgeBase"
                >{{ 'knowledgeBase.header.breadcrumbs' | transloco }}</a
              >
            </li>
            <li class="breadcrumb-item text-break">
              <a
                uiSref="app.content.knowledgeBase.category"
                [uiParams]="{ categoryId: currentCategory.id }"
                data-test="cq-article-edit-breadcrumb-category-link"
                >{{ currentCategory.name | truncate: 30 }}</a
              >
            </li>
            <li
              *ngIf="article.id"
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ 'articleEdit.breadcrumbs.editing' | transloco }}
            </li>
            <li
              *ngIf="!article.id"
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ 'articleEdit.breadcrumbs.creating' | transloco }}
            </li>
          </ol>
        </nav>
        <a
          *ngIf="article.id"
          class="btn btn-text-primary"
          target="_blank"
          [href]="
            'https://' + settings.fullDomain + '/article' + (initialPublicStatus ? '/' : '-preview/') + article.id
          "
        >
          <i class="btn-icon-left cqi-sm cqi-external-link"></i>
          <span>{{ 'articleEdit.articlePreviewLink.text' | transloco }}</span>
        </a>
        <a
          *ngIf="!article.id"
          class="link-unstyled cursor-not-allowed text-muted"
          [ngbTooltip]="'articleEdit.articlePreviewLink.noPreviewTooltip' | transloco"
          placement="left"
        >
          <i class="cqi-sm cqi-external-link"></i>
          <span>{{ 'articleEdit.articlePreviewLink.text' | transloco }}</span>
        </a>
      </div>
      <div class="card no-margin-bottom no-border-bottom">
        <!--Статья-->
        <div class="card-body padding-top-40">
          <div class="article-edit-container margin-left-auto margin-right-auto">
            <form
              novalidate
              (submit)="save(articlePreviewForm.valid)"
            >
              <!--Название-->
              <div
                class="form-group margin-bottom-10"
                [ngbTooltip]="'articleEdit.nameInput.tooltip' | transloco"
                tooltipClass="seo-info-tooltip"
                [disableTooltip]="article.name.length === 0"
                placement="top-left"
                [autoClose]="'outside'"
              >
                <div class="form-control-container">
                  <!--quill-editor
                    class="d-block article-name-input-area"
                    format="text"
                    name="name"
                    [modules]="{ toolbar: false }"
                    [placeholder]="'articleEdit.nameInput.placeholder' | transloco"
                    [ngModel]="article.name"
                    (ngModelChange)="onNameChange($event)"
                    (onBlur)="articlePreviewForm.controls.name.markAsTouched()"
                  ></quill-editor-->
                  <cq-validation-messages [control]="articlePreviewForm.controls.name">
                    <cq-validation-message
                      errorName="required"
                      data-test="cq-article-edit-validation-message-name-required"
                    >
                      {{ 'articleEdit.nameInput.errors.required' | transloco }}
                    </cq-validation-message>
                    <cq-validation-message errorName="minlength">
                      {{ 'articleEdit.nameInput.errors.minlength' | transloco: { minLength: MIN_NAME_LENGTH } }}
                    </cq-validation-message>
                    <cq-validation-message errorName="maxlength">
                      {{ 'articleEdit.nameInput.errors.maxlength' | transloco: { maxLength: MAX_NAME_LENGTH } }}
                    </cq-validation-message>
                    <cq-validation-message errorName="apiMessageArticleAlreadyExistsError">
                      {{ 'articleEdit.nameInput.errors.apiMessageArticleAlreadyExistsError' | transloco }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
              </div>

              <!--Описание-->
              <div
                class="form-group margin-bottom-30"
                [ngbTooltip]="'articleEdit.descriptionTextarea.tooltip' | transloco"
                tooltipClass="seo-info-tooltip"
                [disableTooltip]="article.shortDescription.length === 0"
                placement="top-left"
                [autoClose]="'outside'"
              >
                <div class="form-control-container">
                  <!--quill-editor
                    class="d-block article-description-input-area"
                    format="text"
                    name="description"
                    [modules]="{ toolbar: false }"
                    [placeholder]="'articleEdit.descriptionTextarea.placeholder' | transloco"
                    [ngModel]="article.shortDescription"
                    (ngModelChange)="onDescriptionChange($event)"
                    (onBlur)="articlePreviewForm.controls.description.markAsTouched()"
                  ></quill-editor-->
                  <cq-validation-messages [control]="articlePreviewForm.controls.description">
                    <cq-validation-message
                      errorName="required"
                      data-test="cq-article-edit-validation-message-description-required"
                    >
                      {{ 'articleEdit.descriptionTextarea.errors.required' | transloco }}
                    </cq-validation-message>
                    <cq-validation-message errorName="maxlength">
                      {{
                        'articleEdit.descriptionTextarea.errors.maxlength'
                          | transloco: { maxLength: MAX_DESCRIPTION_LENGTH }
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
              </div>

              <!--Содержание-->
              <div class="form-group margin-bottom-40">
                <div class="form-control-container">
                  <textarea
                    #editor
                    class="form-control"
                    [formControl]="articlePreviewForm.controls.content"
                  >
                  </textarea>
                  <cq-validation-messages [control]="articlePreviewForm.controls.content">
                    <cq-validation-message
                      errorName="required"
                      data-test="cq-article-edit-validation-message-content-required"
                    >
                      {{ 'articleEdit.content.errors.required' | transloco }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--Сохранить-->
      <div
        class="bottom-buttons flex align-items-center justify-space-between position-sticky bordered-top bottom-0 padding-top-20 padding-bottom-20"
      >
        <div>
          <div class="custom-control custom-checkbox margin-bottom-5">
            <input
              class="custom-control-input"
              id="article-public"
              type="checkbox"
              (change)="onArticlePublicChange(article.public)"
              [(ngModel)]="article.public"
            />
            <label
              class="custom-control-label"
              for="article-public"
              [innerHTML]="'articleEdit.publicStatusCheckbox' | transloco"
            ></label>
          </div>
          <div
            *ngIf="accessToKnowledgeBasePro.hasAccess"
            class="custom-control custom-checkbox"
            [ngbTooltip]="'articleEdit.indexStatusCheckbox.tooltip' | transloco"
            [disableTooltip]="article.public === false"
            placement="right"
          >
            <input
              class="custom-control-input"
              id="article-robots-index"
              type="checkbox"
              [disabled]="!article.public"
              [(ngModel)]="article.robotsIndex"
            />
            <label
              class="custom-control-label"
              for="article-robots-index"
              [innerHTML]="'articleEdit.indexStatusCheckbox.text' | transloco"
            ></label>
          </div>
          <cq-access-denial-popover
            *ngIf="!accessToKnowledgeBasePro.hasAccess"
            [currentApp]="currentApp"
            [denialReason]="accessToKnowledgeBasePro.denialReason"
            [popoverPlacement]="'right'"
          >
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                id="article-robots-index-disabled"
                type="checkbox"
                [disabled]="true"
                [(ngModel)]="article.robotsIndex"
              />
              <label
                class="custom-control-label"
                for="article-robots-index-disabled"
                [innerHTML]="'articleEdit.indexStatusCheckbox.text' | transloco"
              ></label>
              <cq-plan-capability-coin
                [ngbTooltip]="'articleEdit.indexStatusCheckbox.capability.tooltip' | transloco"
                container="body"
                placement="top-left"
              ></cq-plan-capability-coin>
            </div>
          </cq-access-denial-popover>
        </div>

        <button
          class="btn btn-lg btn-primary"
          type="button"
          (click)="save(articlePreviewForm.valid); trackClickSaveArticle()"
          [ngClass]="{
            disabled: !accessToKnowledgeBasePro.hasAccess && articlesLeftOnStandart <= 0 && !article.id
          }"
          [innerHTML]="'general.save' | transloco"
          data-test="cq-article-edit-save-button"
        ></button>
      </div>
    </div>
  </section>
</ng-container>
