import { AbstractControl, ValidatorFn } from '@angular/forms';
import { startWith } from 'rxjs/operators';

import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

export function attachmentsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value.length === 0 ? { required: { value: control.value } } : null;
  };
}
const fileAttachmentsValidators: ValidatorFn[] = [attachmentsValidator()];

/**
 * Общая форма для действий работающих с файлами
 */
export class BotMediaActionsForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return extractTouchedChanges(this.controls.attachments).pipe(startWith(this.controls.attachments.touched));
  }

  getCustomControls(action: ChatBotAction): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({}),
      attachments: new GenericFormControl(action.attachments ?? [], fileAttachmentsValidators),
    };
  }
}
