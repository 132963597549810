import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';

import { AiContentMakerBodyJsonConstants } from '@http/message-part/message-part.types';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-acm-add-constant',
  templateUrl: './acm-add-constant.component.html',
  styleUrls: ['./acm-add-constant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcmAddConstantComponent
  extends AbsCVAFormArrayBasedComponent<AiContentMakerBodyJsonConstants, FormControl>
  implements OnInit
{
  isEditorCollapsed: boolean = true;

  control: FormArray<FormControl<AiContentMakerBodyJsonConstants>> = this.fb.array<
    FormControl<AiContentMakerBodyJsonConstants>
  >([]);

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get defaultConstant(): AiContentMakerBodyJsonConstants {
    return {
      value: '',
      context: '',
    };
  }

  addConstant() {
    let control: FormControl<AiContentMakerBodyJsonConstants> = this.getNewControl();

    this.control.push(control);
  }

  deleteConstant(index: number) {
    this.control.removeAt(index);
  }

  getNewControl(constant?: AiContentMakerBodyJsonConstants): FormControl<AiContentMakerBodyJsonConstants> {
    if (!constant) {
      constant = this.defaultConstant;
    }

    return this.fb.control<AiContentMakerBodyJsonConstants>(constant, { nonNullable: true });
  }
}
