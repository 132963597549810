<ng-container *transloco="let t; read: 'actionHeaderWrapperComponent'">
  <div
    class="d-flex align-items-center justify-content-between mb-10"
    ngbDropdown
  >
    <div>
      <i
        class="cqi-sm mr-5"
        [ngClass]="iconClass"
      ></i>
      {{ t('actionType.' + actionType) }}
    </div>

    <button
      class="btn btn-borderless-primary"
      ngbDropdownToggle
    >
      <i class="btn-icon cqi-sm cqi-ellipsis"></i>
    </button>
    <div ngbDropdownMenu>
      <button
        ngbDropdownItem
        (click)="duplicate.next()"
        type="button"
      >
        <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
        {{ t('actionActionsDropdown.copy') }}
      </button>
      <button
        ngbDropdownItem
        (click)="delete.next()"
        type="button"
      >
        <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
        {{ t('actionActionsDropdown.delete') }}
      </button>
    </div>
  </div>
</ng-container>
