import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { TagDefaultModule } from '@panel/app/partials/tag-default/tag-default.module';
import { ModalHelperService } from '@panel/app/services';
import { PromptModalModule } from '@panel/app/shared/modals/prompt/prompt-modal.module';

import { ConversationTagsComponent } from './conversation-tags.component';

@NgModule({
  declarations: [ConversationTagsComponent],
  exports: [ConversationTagsComponent],
  imports: [
    CommonModule,
    FormsModule,
    TagDefaultModule,
    TranslocoModule,
    PromptModalModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgFooterTemplateDirective,
  ],
  providers: [ModalHelperService],
})
export class ConversationTagsModule {}
