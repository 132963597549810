import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { APP_TASKS, STARTER_GUIDE_SKIPPED } from '@http/app/app.constants';
import { App } from '@http/app/app.model';
import { ChatBotTemplate } from '@http/chat-bot-template/types/chat-bot-template-internal.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/** Компонент для работы со стартергайдом для маркетологов */
@Component({
  selector: 'cq-starter-guide-for-marketers[app][templates]',
  templateUrl: './starter-guide-for-marketers.component.html',
  styleUrls: ['./starter-guide-for-marketers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideForMarketersComponent {
  /** Приложение */
  @Input()
  app!: App;

  /** Временные данные django-пользователя */
  @Input()
  djangoUserTempData!: DjangoUserTempData;

  /** Шаблоны чат-ботов */
  @Input()
  templates!: ChatBotTemplate[];

  /** Отображаемые интеграции */
  displayedIntegration: INTEGRATION_TYPES[] = [];

  /** Отображаемые шаги */
  displayedSteps: STARTER_GUIDE_STEPS[] = [];

  /** Название проекта */
  projectName = environment.projectName;

  /** Все шаги стартергайда */
  STARTER_GUIDE_STEPS = STARTER_GUIDE_STEPS;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly l10nHelperService: L10nHelperService,
    private readonly starterGuideModel: StarterGuideModel,
    private readonly translocoService: TranslocoService,
    private readonly uibModalService: UibModalService,
    private readonly uiRouter: UIRouter,
  ) {
    this.initDisplayedSteps();
    this.initDisplayedIntegration();
    this.initCallbackOnMadeStep();
  }

  /** Возвращает переведённое описание шага стартергайда */
  getDescriptionForStep(step: STARTER_GUIDE_STEPS): string {
    return this.translocoService.translate(`starterGuideForMarketersComponent.steps.${step}.description`);
  }

  /**
   * Возвращает переведённый заголовок шага
   *
   * @param step - Шаг
   */
  getHeadingForStep(step: STARTER_GUIDE_STEPS): string {
    return this.translocoService.translate(`starterGuideForMarketersComponent.steps.${step}.heading`, {
      projectName: this.projectName,
    });
  }

  /**
   * Получает "человеческий" номера шага
   *
   * @param index - Индекс шага
   */
  getHumanizedHumber(index: number): string {
    return `${index + 1}`;
  }

  /** Инициализирует callback на прохождение шага */
  initCallbackOnMadeStep(): void {
    this.starterGuideModel.addPassedStepCallback(() => this.changeDetectorRef.detectChanges());
  }

  /** Инициализирует отображаемые интеграции */
  initDisplayedIntegration(): void {
    if (this.l10nHelperService.isUsCountry()) {
      this.displayedIntegration = [INTEGRATION_TYPES.EMAIL_NOTIFICATION];
    } else {
      this.displayedIntegration = [INTEGRATION_TYPES.EMAIL_NOTIFICATION, INTEGRATION_TYPES.AMOCRM];
    }
  }

  /** Инициализирует отображаемые шаги */
  initDisplayedSteps(): void {
    this.displayedSteps = [
      STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION,
      STARTER_GUIDE_STEPS.LEADS_PASSING,
      STARTER_GUIDE_STEPS.CHAT_SETTINGS,
      STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
    ];
  }

  /** Определяет, пройден ли стартергайд */
  isAllStepsMade(): boolean {
    return this.displayedSteps.every((step) => this.starterGuideModel.isStepIsMade(step));
  }

  /**
   * Проверяет, пройден ли шаг
   *
   * @param step - Шаг
   */
  isMadeStep(step: STARTER_GUIDE_STEPS): boolean {
    return this.starterGuideModel.isStepIsMade(step);
  }

  /** Выбрана ли во время регистрации задача по увеличению конверсии на сайте */
  isMarketerTaskChosen(): boolean {
    return this.djangoUserTempData.task === APP_TASKS.RAISE_LEAD_CONVERSION;
  }

  /** Определяет, показывать ли кнопку пропуска стартергайда */
  isNeedToShowStarterGuideSkipButton(): boolean {
    if (this.isAllStepsMade()) {
      return false;
    }

    if (this.isStarterGuideSkipped()) {
      return false;
    }

    if (!this.isMarketerTaskChosen()) {
      return false;
    }

    return true;
  }

  /** Определяет, пропущен ли стартергайд */
  isStarterGuideSkipped(): boolean {
    return !!localStorage.getItem(STARTER_GUIDE_SKIPPED);
  }

  /** Обрабатывает клик на кнопку пропуска стартергайда */
  onClickStarterGuideSkipButton(): void {
    localStorage.setItem(STARTER_GUIDE_SKIPPED, 'true');
    this.trackSkipStarterGuideButton();

    this.uiRouter.stateService.reload();
  }

  /** Обрабатывает намеренье создать лид-бота */
  onIntentToCreateLeadbot(): void {
    this.trackClickCreateLeadbotButton();

    this.redirectToLeadBotCreationPage();
  }

  /** Обрабатывает намеренье создать лид-бота с шаблона */
  onIntentToCreateLeadbotFormTemplate(): void {
    this.trackOpenChatBotTemplatesModal();

    this.openChatBotTemplatesModal();
  }

  /** Открывает модальное окно с выбором шаблонов лид-ботов */
  openChatBotTemplatesModal(): void {
    const templateModal = this.uibModalService.open({
      component: 'cqChatBotTemplateWrapperModal',
      resolve: {
        modalWindowParams: () => {
          return {
            templates: this.templates,
            botType: 'lead',
            isNewBot: true,
          };
        },
      },
      size: 'lg modal-dialog-centered',
    });

    templateModal.result
      .then(
        (template) => {
          this.redirectToLeadBotCreationPageFromTemplate(template);
        },
        () => {
          this.trackCloseChatBotTemplatesModal();
        },
      )
      .catch(() => {});
  }

  /** Перенаправляет пользователя на страницу создания лид-бота */
  redirectToLeadBotCreationPage(): void {
    this.uiRouter.stateService.go('app.content.messagesAjs.chatBot.create.new', {
      fromStarterGuideStep: STARTER_GUIDE_STEPS.LEADS_COLLECTION,
    });
  }

  /**
   * Перенаправляет пользователя на страницу создания лид-бота с шаблона
   *
   * @param template - Выбранный шаблон
   */
  redirectToLeadBotCreationPageFromTemplate(template?: ChatBotTemplate): void {
    this.uiRouter.stateService.go('app.content.messagesAjs.chatBot.create', {
      chatBotTemplateId: template?.id,
      hideTemplateModal: !template,
    });
  }

  /** Трекает клик по кнопке пропуска стартергайда */
  trackSkipStarterGuideButton(): void {
    const eventName: string = 'Стартергайд по лидогенерации - пропустил';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает клик по кнопке создания лид-бота */
  trackClickCreateLeadbotButton(): void {
    const eventName: string = 'Стартергайд - нажал кнопку создания лид-бота';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает закрытие модального окна с шаблонами лид-ботов */
  trackCloseChatBotTemplatesModal() {
    const eventName: string = 'Стартергайд - закрыл модалку с шаблонами';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает закрытие модального окна с шаблонами лид-ботов */
  trackOpenChatBotTemplatesModal() {
    const eventName: string = 'Стартергайд - открыл модалку с шаблонами';

    this.carrotquestHelper.track(eventName);
  }
}
