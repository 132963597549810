import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgLabelTemplateDirective, NgOptionComponent, NgSelectComponent } from "@ng-select/ng-select";

import { UrlFilterConfiguratorModule } from '@panel/app/partials/url-filter-configurator/url-filter-configurator.module';

import { VisibilitySettingsComponent } from './visibility-settings.component';

@NgModule({
  declarations: [VisibilitySettingsComponent],
  exports: [VisibilitySettingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    UrlFilterConfiguratorModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionComponent,
  ],
})
export class VisibilitySettingsModule {}
