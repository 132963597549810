import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from "@ng-select/ng-select";
import { UIRouterModule } from '@uirouter/angular';

import { VkFormsApiTokenComponent } from '@panel/app/pages/integrations/content/vk-forms/content/api-token/vk-forms-api-token.component';
import { VkFormsConfirmCodeComponent } from '@panel/app/pages/integrations/content/vk-forms/content/confirm-code/vk-forms-confirm-code.component';
import { VkFormsCustomEventComponent } from '@panel/app/pages/integrations/content/vk-forms/content/custom-event/vk-forms-custom-event.component';
import { VkFormsFormComponent } from '@panel/app/pages/integrations/content/vk-forms/content/form/form.component';
import { VkFormsFormLinkComponent } from '@panel/app/pages/integrations/content/vk-forms/content/form-link/form-link.component';
import { VkFormsManualComponent } from '@panel/app/pages/integrations/content/vk-forms/content/manual/vk-forms-manual.component';
import { VkFormsQuestionNameComponent } from '@panel/app/pages/integrations/content/vk-forms/content/question-name/vk-forms-question-name.component';
import { VkFormsQuestionPropertyComponent } from '@panel/app/pages/integrations/content/vk-forms/content/question-property/vk-forms-question-property.component';
import { VkFormsQuestionsComponent } from '@panel/app/pages/integrations/content/vk-forms/content/questions/vk-forms-questions.component';
import { VkFormsStandardQuestionsComponent } from '@panel/app/pages/integrations/content/vk-forms/content/standard-questions/vk-forms-standard-questions.component';
import { VkFormsStandardQuestionsPropertyComponent } from '@panel/app/pages/integrations/content/vk-forms/content/standard-questions-property/vk-forms-standard-questions-property.component';
import { VkFormsWebhookUrlComponent } from '@panel/app/pages/integrations/content/vk-forms/content/webhook-url/vk-forms-webhook-url.component';
import { VkFormsComponent } from '@panel/app/pages/integrations/content/vk-forms/vk-forms.component';
import { VkFormsWrapperComponent } from '@panel/app/pages/integrations/content/vk-forms/wrapper/vk-forms-wrapper.component';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { IntegrationsPartialsModule } from '@panel/app/pages/integrations/partials/integrations-partials.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [
    VkFormsApiTokenComponent,
    VkFormsComponent,
    VkFormsConfirmCodeComponent,
    VkFormsCustomEventComponent,
    VkFormsFormComponent,
    VkFormsFormLinkComponent,
    VkFormsManualComponent,
    VkFormsQuestionNameComponent,
    VkFormsQuestionPropertyComponent,
    VkFormsQuestionsComponent,
    VkFormsStandardQuestionsComponent,
    VkFormsStandardQuestionsPropertyComponent,
    VkFormsWebhookUrlComponent,
    VkFormsWrapperComponent,
  ],
  exports: [VkFormsApiTokenComponent, VkFormsConfirmCodeComponent, VkFormsWebhookUrlComponent],
  imports: [
    CommonModule,
    IntegrationIconModule,
    IntegrationNameControlModule,
    IntegrationsPartialsModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    UIRouterModule,
    NgSelectComponent,
  ],
})
export class VkFormsModule {}
