import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppModel } from '@http/app/app.model';
import { CHANNEL_TYPES, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel, ChannelModel } from '@http/channel/channel.model';
import { DestroyService } from '@panel/app/services';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-hotkeys-modal[closeFn][djangoUser]',
  templateUrl: './hotkeys-modal.component.html',
  styleUrls: ['./hotkeys-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class HotkeysModalComponent implements OnInit {
  @Input() closeFn!: () => Promise<string>;
  @Input() djangoUser!: any; // TODO ANGULAR_TS сделать описание djangoUser
  allChannelsChannel!: Channel;
  isRequestExecuted: boolean = false; // Флаг выполнения запроса
  messengerSendByEnter!: boolean;
  NOT_ASSIGNED_COUNT_PREVIEW_VALUE: number = 17; // Демо-значение количества неразобранных диалогов
  NOT_READ_COUNT_PREVIEW_VALUE: number = 1; // Демо-значение количества неотвеченных диалогов
  vkOrFacebookChannel!: Channel; // Канал ВК или фейсбук

  sendByKeyControl: UntypedFormControl = this.fb.control('byCtrEnter');

  constructor(
    public readonly appModel: AppModel,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly channelModel: ChannelModel,
    private readonly l10nHelper: L10nHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private fb: UntypedFormBuilder,
    private destroy: DestroyService,
  ) {}

  ngOnInit(): void {
    this.allChannelsChannel = this.channelModel.getDefaultChannel(PSEUDO_CHANNEL_TYPES.ALL_CHANNELS);
    this.messengerSendByEnter = this.djangoUser.messenger_send_by_enter;

    if (this.djangoUser.messenger_send_by_enter) {
      this.sendByKeyControl.setValue('byEnter');
    }

    this.sendByKeyControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((key) => {
      const byEnter = key === 'byEnter';
      this.changeMessengerSendByEnterSetting(byEnter);
    });

    this.vkOrFacebookChannel = this.channelModel.getDefaultChannel(
      this.l10nHelper.isUsCountry() ? CHANNEL_TYPES.FACEBOOK : CHANNEL_TYPES.VK,
    );
  }

  /**
   * Изменение настройки отправки по Enter или Ctrl+Enter
   */
  public changeMessengerSendByEnterSetting(byEnter = false) {
    this.isRequestExecuted = true;

    firstValueFrom(this.appModel.setMessengerSendByEnter(byEnter))
      .then(() => {
        this.djangoUser.messenger_send_by_enter = byEnter;
        this.toastService.success(this.translocoService.translate('hotkeysModal.toasts.sendMessageHotkeysSuccess'));
      })
      .catch(() => {
        this.djangoUser.messenger_send_by_enter = !byEnter;
        this.sendByKeyControl.setValue(byEnter ? 'byCtrEnter' : 'byEnter', { emitEvent: false });
      })
      .finally(() => {
        this.isRequestExecuted = false;
        this.changeDetectorRef.detectChanges();
      });
  }
}
