import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { TelegramOperationSelectModule } from '@panel/app/partials/filters/components/telegram-operation-select/telegram-operation-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EmailOperationSelectComponent } from './email-operation-select.component';
import { EmailOperationSelectWrapComponent } from './email-operation-select-wrap/email-operation-select-wrap.component';

@NgModule({
  declarations: [EmailOperationSelectComponent, EmailOperationSelectWrapComponent, EmailOperationSelectWrapComponent],
  exports: [EmailOperationSelectComponent, EmailOperationSelectWrapComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTooltip,
    SharedModule,
    TelegramOperationSelectModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
  ],
})
export class EmailOperationSelectModule {}
