import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@ng-web-apis/common';
import { find, orderBy } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { REMOVE_TEAM_MEMBER_ERRORS } from '@http/team-member/team-member.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { AdminParamsModalComponent } from '@panel/app/pages/account/shared/admin-params-modal/admin-params-modal.component';
import { AssignNewSuperAdminModalComponent } from '@panel/app/pages/account/shared/assign-new-super-admin-modal/assign-new-super-admin-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { UtilsService } from '@panel/app/services/utils/utils.service';
import { SafeRemoveComponent } from '@panel/app/shared/modals/safe-remove/safe-remove.component';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ProfileComponent implements OnInit {
  /** список приложений текущего пользователя */
  @Input() appList!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;

  protected selectedLanguage: any = environment.language;
  protected FEATURES = FEATURES;
  protected availableLanguages: any = environment.panelLanguages;
  protected LANGUAGE = environment.language;

  protected profileForm = this.fb.group({
    telephone: this.fb.control('', [Validators.maxLength(50)]),
    skype: this.fb.control('', [Validators.maxLength(80)]),
    language: this.fb.control('', [Validators.required]),
  });

  get sortedApps(): any[] {
    return orderBy(
      this.appList,
      [
        (app) => /^\d/.test(app.name), // Сначала сортируем по признаку начала с цифры
        (app) => app.name.toLowerCase(), // Затем сортируем по имени
      ],
      ['desc', 'asc'], // Порядок сортировки: сначала цифры (по убыванию, чтобы они были в начале), затем буквы (по возрастанию)
    );
  }

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly djangoUserModel: DjangoUserModel,
    protected readonly l10nHelper: L10nHelperService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    @Inject(WINDOW) private readonly window: Window,
    protected readonly teamMemberModel: TeamMemberModel,
    protected readonly utilsService: UtilsService,
    protected readonly cookieService: CookieService,
    private fb: FormBuilder,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly featureModel: FeatureModel,
    protected readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.profileForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
      this.djangoUser.telephone = values.telephone;
      this.djangoUser.skype = values.skype;
      this.selectedLanguage = values.language;
    });
  }

  ngOnInit(): void {
    this.profileForm.setValue({
      telephone: this.djangoUser.telephone,
      skype: this.djangoUser.skype,
      language: this.selectedLanguage,
    });
  }

  /**
   * Обновление личных данных
   */
  updateAccount(): void {
    const params: any = {
      locale: this.l10nHelper.getFourLetterLang(this.selectedLanguage),
      name: this.djangoUser.name,
      nameInternal: this.djangoUser.name_internal,
      telephone: this.djangoUser.telephone,
      skype: this.djangoUser.skype,
    };

    firstValueFrom(this.djangoUserModel.save(params)).then((response: any) => {
      this.toastr.success(this.translocoService.translate('account.profile.profileForm.toasts.profileDataSaved'));
      this.carrotquestHelper.identify({ $name: this.djangoUser.name });

      if (this.selectedLanguage !== environment.language) {
        localStorage.setItem('carrotquest_language', this.selectedLanguage);
        this.window.location.reload();
      }
    });
  }

  /**
   * Трек клика на 'Изменить' (в том числе и на всю строку целиком) в данных сайтов
   */
  trackClickChangeAppSettings(): void {
    this.carrotquestHelper.track('Аккаунт - Личные данные - клик на изменить');
  }

  /**
   * Открытие модалки редактирования себя как члена команды в аппе
   *
   * @param app Приложение, в котором производится редактировани��
   */
  openChangeAppSettingsModal(app: any): void {
    let modal = this.modalHelperService.open(AdminParamsModalComponent);

    modal.componentInstance.modalWindowParams = {
      avatar: this.djangoUser.prefs[app.id].avatar,
      name: this.djangoUser.prefs[app.id].name,
      internalName: this.djangoUser.prefs[app.id].name_internal,
      app: app,
    };

    modal.result
      .then(() => firstValueFrom(this.djangoUserModel.get(true)))
      .then((djangoUser: any) => {
        this.djangoUser = djangoUser;
        this.cdr.detectChanges();
      });
  }

  /**
   * Удаление себя из аппа
   *
   * @param app - Апп из которого надо удалить себя
   * @param force - Принудительное удаление без проверки
   */
  removeSelfFromApp(app: any, force: any): void {
    firstValueFrom(this.teamMemberModel.getList(app.id, null, false, false)).then((teamMembers: any) => {
      const teamMember = find(teamMembers, { id: this.djangoUser.id });
      firstValueFrom(this.teamMemberModel.remove(app.id, teamMember, null, teamMembers, this.djangoUser, force))
        .catch((error: any) => this.removeError(app, teamMember, teamMembers, error))
        .then(() => this.removeSuccess(app));
    });
  }

  removeSuccess(app: any): void {
    this.trackSelfRemoved(app);
    const index = this.appList.indexOf(app);
    if (index > -1) {
      this.appList.splice(index, 1);
    }
    this.toastr.success(
      this.translocoService.translate('account.toasts.selfRemoved', { originCurrentApp: app.origin }),
    );

    this.cookieService.delete('carrotquest_auth_token_panel', '/');

    this.cookieService.delete('carrotquest_django_user_id', '/');

    this.window.location.href = this.utilsService.getExternalPagesAbsoluteUrl('login');
  }

  removeError(app: any, teamMember: any, teamMembers: any, error: any): any {
    var heading = '';
    var body = '';
    var confirmButtonText = '';
    var confirmText = '';

    if (error === REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_SUPER_ADMINS) {
      let assignNewSuperAdminModal = this.modalHelperService.open(AssignNewSuperAdminModalComponent);

      assignNewSuperAdminModal.componentInstance.modalWindowParams = {
        body:
          '<div class="margin-bottom-20">' +
          this.translocoService.translate('account.removeSelfAsLastSuperAdminModal.body.removeSelf', {
            originCurrentApp: app.origin,
          }) +
          '</div> <div class="margin-bottom-20">' +
          this.translocoService.translate('account.removeSelfAsLastSuperAdminModal.body.chooseNewSuperAdmin') +
          '</div>',
        appId: app.id,
        currentApp: null,
        teamMember: teamMember,
        teamMembers: teamMembers,
        currentAppName: app.name,
      };

      assignNewSuperAdminModal.result.then(() => this.removeSelfFromApp(app, true));
    } else if (error === REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_TEAM_MEMBERS) {
      heading = this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.heading');
      body =
        '<div class="margin-bottom-20">' +
        this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.body.removeSelf', {
          originCurrentApp: app.origin,
        }) +
        '</div> <div class="margin-bottom-20">' +
        this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.body.noOneCanAccess', {
          projectName: environment.projectName,
        }) +
        '</div> <div>' +
        this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.body.areYouSure') +
        '</div>';
      confirmButtonText = this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.confirmButtonText');
      confirmText = this.translocoService.translate('account.removeSelfAsLastTeamMemberModal.confirmText', {
        appName: app.name,
      });
    } else if (error === REMOVE_TEAM_MEMBER_ERRORS.REMOVE_SELF && !this.teamMemberModel.isOperator(teamMember)) {
      //Удаление себя будучи админом/супер админом
      heading = this.translocoService.translate('account.removeSelfAsAdminModal.heading');
      body =
        '<div>' +
        this.translocoService.translate('account.removeSelfAsAdminModal.body.removeSelf', {
          originCurrentApp: app.origin,
          projectName: environment.projectName,
        }) +
        '</div><div>' +
        this.translocoService.translate('account.removeSelfAsAdminModal.body.areYouSure') +
        '</div>';
      confirmButtonText = this.translocoService.translate('account.removeSelfAsAdminModal.confirmButtonText');
      confirmText = this.translocoService.translate('account.removeSelfAsAdminModal.confirmText', {
        appName: app.name,
      });
    } else if (error === REMOVE_TEAM_MEMBER_ERRORS.REMOVE_SELF && this.teamMemberModel.isOperator(teamMember)) {
      heading = this.translocoService.translate('account.removeSelfAsOperatorModal.heading');
      body =
        '<div>' +
        this.translocoService.translate('account.removeSelfAsOperatorModal.body.removeSelf', {
          originCurrentApp: app.origin,
          projectName: environment.projectName,
        }) +
        '</div> <div>' +
        this.translocoService.translate('account.removeSelfAsOperatorModal.body.areYouSure') +
        '</div>';
      confirmButtonText = this.translocoService.translate('account.removeSelfAsOperatorModal.confirmButtonText');
      confirmText = this.translocoService.translate('account.removeSelfAsOperatorModal.confirmText', {
        appName: app.name,
      });
    }

    if (error !== REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_SUPER_ADMINS) {
      let safeRemoveModal = this.modalHelperService.open(SafeRemoveComponent);

      safeRemoveModal.componentInstance.modalWindowParams = {
        heading: heading,
        body: body,
        confirmButtonText: confirmButtonText,
        confirmText: confirmText,
        confirmedItemName: app.name,
      };

      safeRemoveModal.result.then(() => this.removeSelfFromApp(app, true));
    }

    return Promise.reject();
  }

  /**
   * Трек удаления себя
   */
  trackSelfRemoved(app: any): void {
    this.carrotquestHelper.track('Аккаунт - удалил себя', { app: app.name });
  }

  trackById(index: number, app: App) {
    return app.id;
  }
}
