import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cq-saved-replies-page',
  templateUrl: './saved-replies-page.component.html',
  styleUrls: ['./saved-replies-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedRepliesPageComponent {
  constructor() {}
}
