import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { PropertySelectComponent } from './property-select.component';

@NgModule({
  declarations: [PropertySelectComponent],
  exports: [PropertySelectComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgOptionComponent,
    ReactiveFormsModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
  ],
})
export class PropertySelectModule {}
