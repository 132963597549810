import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import {
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLinkButton,
  NgbNavOutlet,
  NgbPopover,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { EditorFormatFileComponent } from './editor-format-file.component';

@NgModule({
  declarations: [EditorFormatFileComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    NgbPopover,
    NgbTooltip,
    FormsModule,
    ReactiveFormsModule,
    NgbNav,
    NgbNavItem,
    NgbNavLinkButton,
    NgbNavContent,
    NgbNavOutlet,
    NgxDropzoneModule,
  ],
  exports: [EditorFormatFileComponent],
})
export class EditorFormatFileModule {}
