import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AiContentMakerIntegrationSettingsExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-ai-content-maker-gpt-version',
  templateUrl: './ai-content-maker-gpt-version.component.html',
  styleUrls: ['./ai-content-maker-gpt-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiContentMakerGptVersionComponent implements OnInit {
  @Input({ required: true })
  control!: GenericFormControl<AiContentMakerIntegrationSettingsExternal['gptModelName']>;

  ngOnInit() {
    this.control.valueChanges.subscribe((value) => this.control.setValue(value.toLowerCase(), { emitEvent: false }));
  }
}
