import { NgModule } from '@angular/core';

import { EmailPartEditorModule } from '@panel/app/pages/trigger-messages/email-part-editor/email-part-editor.module.';
import { LegacyWebhookPartEditorModule } from '@panel/app/pages/trigger-messages/legacy-webhook-part-editor/legacy-webhook-part-editor.module';
import { TelegramPartEditorModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.module';
import { WebhookPartEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/webhook-part-editor.module';

@NgModule({
  declarations: [],
  imports: [EmailPartEditorModule, LegacyWebhookPartEditorModule, TelegramPartEditorModule, WebhookPartEditorModule],
})
export class TriggerMessagesModule {}
