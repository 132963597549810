<ng-container *transloco="let t; read: 'starterGuideForMarketersComponent'">
  <div class="margin-between-rows-20">
    <h2>{{ t('heading') }}</h2>

    <div
      ngbAccordion
      class="accordion"
      [closeOthers]="true"
    >
      <div
        *ngFor="let step of displayedSteps; let i = index"
        ngbAccordionItem
        [id]="displayedSteps[i]"
        #ngbAccordionItem="ngbAccordionItem"
        class="card"
      >
        <cq-step-header
          ngbAccordionHeader
          [isOpened]="!ngbAccordionItem.collapsed"
          (click)="ngbAccordionItem.expand()"
        >
          <cq-step-number
            [isMadeStep]="isMadeStep(displayedSteps[i])"
            [number]="getHumanizedHumber(i)"
          ></cq-step-number>

          <cq-step-title [title]="getHeadingForStep(displayedSteps[i])"></cq-step-title>
        </cq-step-header>

        <cq-step-content ngbAccordionCollapse>
          <ng-container *ngIf="displayedSteps[i] === STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION">
            <cq-starter-guide-creating-leadbot
              [isStepMade]="isMadeStep(displayedSteps[i])"
              (intentToCreateLeadbot)="onIntentToCreateLeadbot()"
              (intentToCreateLeadbotFormTemplate)="onIntentToCreateLeadbotFormTemplate()"
            ></cq-starter-guide-creating-leadbot>
          </ng-container>

          <ng-container *ngIf="displayedSteps[i] === STARTER_GUIDE_STEPS.LEADS_PASSING">
            <cq-starter-guide-leads-passing
              [description]="getDescriptionForStep(displayedSteps[i])"
              [displayedIntegrations]="displayedIntegration"
              [currentApp]="app"
              [isStepMade]="isMadeStep(displayedSteps[i])"
            ></cq-starter-guide-leads-passing>
          </ng-container>

          <ng-container *ngIf="displayedSteps[i] === STARTER_GUIDE_STEPS.CHAT_SETTINGS">
            <ng-content select="[starterGuideChatSettings]"></ng-content>
          </ng-container>

          <ng-container *ngIf="displayedSteps[i] === STARTER_GUIDE_STEPS.INSTALLATION_SERVICE">
            <ng-content select="[starterGuideInstallationService]"></ng-content>
          </ng-container>
        </cq-step-content>
      </div>
    </div>

    <button
      *ngIf="isNeedToShowStarterGuideSkipButton()"
      type="button"
      class="btn btn-secondary"
      (click)="onClickStarterGuideSkipButton()"
    >
      {{ t('button.text') }}
    </button>
  </div>
</ng-container>
