<ng-container *transloco="let t; read: 'aiContentMakerComponent'">
  <ng-container *tuiLet="(messageEditorTriggerState.userProps$ | async) ?? [] as properties">
    <div class="mb-15">
      <label for="integration">{{ t('integration.label') }}</label>
      <cq-integration-select
        id="integration"
        [integrationId]="aiContentMakerIntegrationId"
        (integrationIdChange)="onIntegrationIdChange($event)"
        [integrationList]="aiContentMakerIntegrations"
        (touchAndValidateCallback)="setValidationCallback($event)"
      ></cq-integration-select>
    </div>

    <cq-acm-promt [formControl]="form.controls.messageContext"></cq-acm-promt>

    <hr class="my-30" />

    <cq-acm-add-props
      [formControl]="form.controls.variables"
      [properties]="properties"
      [currentApp]="currentApp"
    ></cq-acm-add-props>

    <hr class="my-30" />

    <cq-acm-add-constant [formControl]="form.controls.constants"></cq-acm-add-constant>

    <hr class="mt-30 mb-30" />

    <div class="row mx-n10">
      <div
        class="col-9 px-10"
        [cqShowError]="form.controls.testLead"
      >
        <input
          class="form-control"
          [formControl]="form.controls.testLead"
          [placeholder]="t('testLead.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.testLead">
          <cq-validation-message errorName="required">
            {{ t('testLead.errors.required') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <div class="col-3 px-10 text-right">
        <button
          class="btn btn-primary"
          type="button"
          [disabled]="isGenerateDisabled"
          (click)="generateContent()"
        >
          {{ t('generateTestEmail') }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
