<ng-container *transloco="let t; read: 'triggerChainsEditorComponent'">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center py-20">
      <cq-trigger-chain-breadcrumbs [(chainName)]="chain.name"></cq-trigger-chain-breadcrumbs>

      <cq-trigger-chain-actions
        [isEdit]="!!chain.id"
        [isActive]="chain.active"
        [accessToAutoMessagesTotal]="this.accessToAutoMessagesTotal"
        [accessToTelegramAutoMessages]="this.accessToTelegramAutoMessages"
        (clickOnRunButton)="onClickOnRunButton()"
        (clickOnSaveButton)="onClickOnSaveButton()"
        (clickOnStatusChange)="onClickOnStatusChange($event)"
        (clickOnShowPaywall)="onClickOnShowPaywall()"
        (clickStopAndSave)="onClickOnStatusChange(false)"
        (clickOnCopyButton)="onClickOnCopyButton()"
      ></cq-trigger-chain-actions>
    </div>

    <div
      class="cq-canvas position-relative d-flex flex-row overflow-hidden"
      data-stepEditorParent
      [ngClass]="{ fullscreen: canvasBaseService.isFullscreen }"
    >
      <cq-trigger-chain-canvas
        class="flex-grow-1"
        [chain]="chain"
        (clickOnAddChainStepButton)="onClickOnAddChainStepButton($event)"
      ></cq-trigger-chain-canvas>

      <ng-container *ngIf="selectedStep$ | async as selectedStep">
        <cq-trigger-chain-step-editor
          class="flex-shrink-0"
          @slideLeft
          [@.disabled]="isDisabledOpenAnimationForStepEditor(selectedStep)"
          [isValidationStrict]="isValidationStrict"
          [step]="selectedStep"
          (stepChange)="onStepChange($event)"
          (closeEditorClick)="manualSelectStepSubj.next(null)"
          (deleteClick)="deleteStep($event)"
          (copyClick)="copyStep($event)"
        ></cq-trigger-chain-step-editor>
      </ng-container>
    </div>
  </div>
</ng-container>
