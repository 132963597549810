import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_STATUSES, SENDING_STATUSES } from '../../../../app/http/message/message.constants';
import {
  MESSAGE_PART_TYPE_ICON_CLASSES,
  MESSAGE_PART_TYPES,
  MESSAGE_PART_TYPES_ARRAY,
} from '../../../../app/http/message-part/message-part.constants';

/**
 * Контроллер для таблицы ручных сообщений
 */

(function () {
  'use strict';

  angular.module('myApp.manualMessages').controller('CqManualMessagesTableController', CqManualMessagesTableController);

  function CqManualMessagesTableController(
    $scope,
    $rootScope,
    $filter,
    $stateParams,
    carrotquestHelper,
    messageModel,
    l10nHelper,
    planFeatureAccessService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToManualMessagesStats = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_STATS,
        vm.currentApp,
      );
      vm.isScheduledMessage = !!$stateParams.scheduledMessageId;

      trackEnterOnPage();

      vm.currentType = MESSAGE_PART_TYPES.ALL; // фильтр автосообщений по типу
      vm.FILTERED_MESSAGE_PART_TYPES_ARRAY = filterMessagePartTypesArray(); // используется для фильтрации сообщений по типу;
      vm.forceReload = false; // флаг принудительной перезагрузки таблицы сообщений
      vm.getMessages = getMessages;
      vm.isMessagesRequestPerformed = false; // флаг выполнения запроса на получение сообщений в текущий момент времени
      vm.MESSAGE_PART_TYPE_ICON_CLASSES = MESSAGE_PART_TYPE_ICON_CLASSES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MESSAGE_STATUSES = MESSAGE_STATUSES;
      vm.messages = []; // список загруженных сообщений
      vm.messagesPaginator = null; // пагинация сообщений
      vm.refreshMessages = refreshMessages;
      vm.SENDING_STATUSES = SENDING_STATUSES;
      vm.trackChangeMessagePartType = trackChangeMessagePartType;
      vm.trackClickOnStatistics = trackClickOnStatistics;

      $scope.$watch('vm.currentType', function (newValue, oldValue) {
        // изменение любого параметра влечёт за собой полную перезагрузку страницы пользователей
        vm.forceReload = true;

        getMessages(newValue);
      });
    }

    /**
     * Фильтрация типов сообщений (контрольная группа не нужна)
     *
     * @returns {MESSAGE_PART_TYPES_ARRAY}
     */
    function filterMessagePartTypesArray() {
      var filteredMessagePartTypesArray = angular.copy(MESSAGE_PART_TYPES_ARRAY);

      for (var i = filteredMessagePartTypesArray.length - 1; i >= 0; i--) {
        var messagePartType = filteredMessagePartTypesArray[i];

        if (
          ~[MESSAGE_PART_TYPES.CONTROL_GROUP, MESSAGE_PART_TYPES.WEBHOOK, MESSAGE_PART_TYPES.JS].indexOf(
            messagePartType,
          )
        ) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        const userActivatedInSdk =
          Boolean(vm.currentApp.activation.installed_sdk_ios) ||
          Boolean(vm.currentApp.activation.installed_sdk_android);
        // NOTE: Доступ до SDK-типов только по фичагалке
        if (
          ~[
            MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
            MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
            MESSAGE_PART_TYPES.SDK_PUSH,
          ].indexOf(messagePartType) &&
          !userActivatedInSdk
        ) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        // NOTE: пуши отключены для США
        if (~[MESSAGE_PART_TYPES.PUSH].indexOf(messagePartType) && l10nHelper.isUsCountry()) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        // Телеграм пока не доступен для ручных рассылок
        if (messagePartType === MESSAGE_PART_TYPES.TELEGRAM) {
          filteredMessagePartTypesArray.splice(i, 1);
        }
      }

      return filteredMessagePartTypesArray;
    }

    /**
     * Получение списка автосообщений
     *
     * @param {MESSAGE_PART_TYPES} messagePartsType Тип вариантов сообщения
     * @returns {Promise}
     */
    function getMessages(messagePartsType) {
      vm.isMessagesRequestPerformed = true;

      // если идёт перезагрукзка таблицы сообщений - всё обнуляем
      if (vm.forceReload) {
        vm.messages = [];
        vm.messagesPaginator = null;
        vm.forceReload = false;
      }

      return firstValueFrom(
        messageModel.getManualMessages(
          messagePartsType,
          MESSAGE_STATUSES.ALL,
          vm.messagesPaginator,
          undefined,
          undefined,
          $stateParams.scheduledMessageId,
        ),
      )
        .then(getManualMessagesSuccess)
        .finally(getManualMessagesFinally);

      function getManualMessagesSuccess(data) {
        vm.messages.push.apply(vm.messages, data.messages);
        vm.messagesPaginator = data.paginatorParams;

        return data;
      }

      function getManualMessagesFinally() {
        vm.isMessagesRequestPerformed = false;
        $scope.$digest();
      }
    }

    /**
     * Обновление таблицы сообщений
     *
     * @param {MESSAGE_PART_TYPES} messagePartsType Тип вариантов сообщения
     */
    function refreshMessages(messagePartsType) {
      trackClickOnRefresh();

      vm.forceReload = true;

      getMessages(messagePartsType);
    }

    /**
     * Трек изменения фильтрации ручных сообщений
     */
    function trackChangeMessagePartType() {
      carrotquestHelper.track('Статистика рассылок - клик на фильтр');
    }

    /**
     * Трек клика на кнопку "Обновить"
     */
    function trackClickOnRefresh() {
      carrotquestHelper.track('Статистика рассылок - клик на "Обновить"');
    }

    /**
     * Трек клика на кнопку "Статистика" у любого из ручных сообщений
     */
    function trackClickOnStatistics() {
      carrotquestHelper.track('Статистика рассылок - клик на "Статистика"');
    }

    /**
     * Трек входа на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Статистика рассылок - зашёл на страницу');
    }
  }
})();
