import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageScheduleRepeatDelayComponent } from './message-schedule-repeat-delay.component';

@NgModule({
  declarations: [MessageScheduleRepeatDelayComponent],
  imports: [CommonModule, TranslocoDirective, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [MessageScheduleRepeatDelayComponent],
})
export class MessageScheduleRepeatDelayModule {}
