import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageEditorOpenedSdkPageTriggerTypeComponent } from './message-editor-opened-sdk-page-trigger-type/message-editor-opened-sdk-page-trigger-type.component';
import { MessageEditorOpenedSdkPageTriggerTypesComponent } from './message-editor-opened-sdk-page-trigger-types.component';

@NgModule({
  declarations: [MessageEditorOpenedSdkPageTriggerTypesComponent, MessageEditorOpenedSdkPageTriggerTypeComponent],
  exports: [MessageEditorOpenedSdkPageTriggerTypesComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    SharedModule,
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
  ],
})
export class MessageEditorOpenedSdkPageTriggerTypesModule {}
