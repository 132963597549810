import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { copy } from 'angular';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { DJANGO_USER_PRESENCE_TYPES, PERMISSIONS_FOR_ACTIONS } from '@http/django-user/django-user.constants';
import { DjangoUser, DjangoUserAutoAssignStatus } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import {
  EXTENDED_RESPONSE,
  IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS,
  IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS,
} from '@panel/app/shared/constants/http.constants';
import { UtilsService } from '@panel/app/services/utils/utils.service';

/***
 * TODO:
 *  - Описать тип DjangoUser;
 *  - changePassword: указать тип возвращаемого Promise;
 *  - checkPermissionsOnApp: указать тип djangoUser (когда он будет полностью описан);
 *  - hasAccess: указать тип djangoUser (когда он будет полностью описан);
 *  - isAdmin: указать тип djangoUser (когда он будет полностью описан);
 *  - isOperator: указать тип djangoUser (когда он будет полностью описан);
 *  - isSuperAdmin: указать тип djangoUser (когда он будет полностью описан);
 *  - parse: указать тип djangoUser (когда он будет полностью описан) и тип возвращаемого объекта;
 *  - save: указать тип settings (когда он будет полностью описан);
 *  - sendPresence: указать тип возвращаемого Promise;
 *  - uploadAvatar: указать тип возвращаемого Promise;
 */

/**
 * Сервис для работы с пользователем django
 */
@Injectable({ providedIn: 'root' })
export class DjangoUserModel {
  public djangoUser: any = {};

  /**
   * Нужен, чтоб при обновлении DjangoUser или его внутреностей
   * можно было сообщить об этом всем компонентам в новом ангуляре и они подтянули изменения
   *
   * Использование: Если что-то обновил, вызови appModel.refresh$.next();
   */
  readonly refresh$ = new Subject();

  constructor(
    private utilsService: UtilsService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Смена пароля django-пользователя
   *
   * @param oldPassword - Старый пароль
   * @param newPassword - Новый пароль
   */
  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    let params = {
      old_password: oldPassword,
      new_password: newPassword,
      ignoreErrors: true,
    };

    return this.httpClient.post('/djangousers/$self_django_user/changepassword', params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Проверка прав djangoUser на приложение
   *
   * @param permissions - Проверяемое разрешение
   * @param appId - ID приложения, на которое проверяются права
   * @param djangoUser - Пользователь, чьи права проверяются
   */
  checkPermissionsOnApp(permissions: TEAM_MEMBER_PERMISSIONS, appId: string, djangoUser: any): boolean {
    return djangoUser.prefs[appId].permissions === permissions;
  }

  /**
   * Получение данных о django-пользователе
   *
   * @param ignoreLoadingBar - Игнорировать или нет loading bar
   */
  get(ignoreLoadingBar: boolean = false): Observable<DjangoUser> {
    // HACK: IE кеширует этот запрос на странице с сайтами, от чего у пользователей не получается добавить новый сайт.
    //  Это из-за того, что djangoUser не обновляется после добавления сайта и там нет этого сайта.
    //  Самое простое решение этой проблемы — добавить рандомный GET параметр, в данном случае это nonce со значением текущего timestamp
    let currentTimestamp = new Date().getTime();

    return this.httpClient
      .get('/djangousers/$self_django_user?nonce=' + currentTimestamp, {
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((response: any) => {
          //Добавить разрешение на поиск по диалогам, если такого разрешения еще нет
          for (let pref in response.data.prefs) {
            if (
              typeof response.data.prefs[pref] === 'object' &&
              response.data.prefs[pref].permissions_conversation_user_list === undefined
            ) {
              response.data.prefs[pref].permissions_conversation_user_list = true;
            }
          }

          this.parse(response.data);

          copy(response.data, this.djangoUser);

          return this.parse(response.data);
        }),
      );
  }

  /**
   * Получение временных данных django-пользователя
   *
   * @param appId - Id приложения
   * @param djangoUserId - Id django-пользователя
   */
  getTempData(appId: string, djangoUserId: string): Observable<any> {
    let params = {
      app: appId,
    };

    return this.httpClient
      .get('/django_users/' + djangoUserId + '/settings', {
        params,
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((res: any) => res.data.temp_data));
  }

  /**
   * Проверка на доступ к действиям
   *
   * @param appId - Id app'а
   * @param djangoUser - Пользователь
   * @param permissionForAction - Тип разрешения на действие
   */
  hasAccess(appId: string, djangoUser: any, permissionForAction: PERMISSIONS_FOR_ACTIONS): boolean {
    const isHasPermission = djangoUser.prefs[appId][permissionForAction];
    const isSuperAdmin = this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, appId, djangoUser);
    const isAdmin = this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.ADMIN, appId, djangoUser);

    return isSuperAdmin || (isAdmin && isHasPermission);
  }

  /**
   * Является ли пользователь админом
   *
   * @param appId - ID приложения
   * @param djangoUser - Django-пользователь
   */
  isAdmin(appId: string, djangoUser: any): boolean {
    return this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.ADMIN, appId, djangoUser);
  }

  /**
   * Проверка валидности JSON
   *
   * @param jsonString - JSON-строка
   */
  isJsonValid(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
    } catch (e) {
      return false;
    }

    return true;
  }

  /**
   * Является ли пользователь оператором
   *
   * @param appId - ID приложения
   * @param djangoUser - Django-пользователь
   */
  isOperator(appId: string, djangoUser: any): boolean {
    return this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.OPERATOR, appId, djangoUser);
  }

  /**
   * Находится ли оператор в состоянии конкуренции за диалоги с другими операторами
   *
   * @param djangoUser - Django-пользователь
   * @param currentApp - Текущее приложение
   */
  isOperatorWithConcurrency(djangoUser: any, currentApp: App): boolean {
    return (
      this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.OPERATOR, currentApp.id, djangoUser) &&
      !!currentApp.settings.messenger_operator_concurrency
    );
  }

  /**
   * Является ли пользователь супер-админом
   *
   * @param appId - ID приложения
   * @param djangoUser - Django-пользователь
   */
  isSuperAdmin(appId: string, djangoUser: any): boolean {
    return this.checkPermissionsOnApp(TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, appId, djangoUser);
  }

  /**
   * Парсинг django-пользователя
   *
   * @param djangoUser - Django-пользователь
   */
  parse(djangoUser: any): DjangoUser {
    for (const appId of Object.keys(djangoUser.prefs)) {
      // обязательно надо проверить, что ключ - это appId, т.к. prefs - гетерогенный объект
      if (this.utilsService.isStringNumber(appId)) {
        let appPrefs = djangoUser.prefs[appId];

        if (!('presence' in appPrefs)) {
          appPrefs.presence = DJANGO_USER_PRESENCE_TYPES.ONLINE;
        }
      }
    }

    return djangoUser;
  }

  /**
   * Сохранение джанго юзера
   *
   * @param settings - Данные джанго юзера
   * @param ignoreLoadingBar - Игнорировать или нет loading bar
   */
  save(settings: any, ignoreLoadingBar: boolean = false): Observable<any> {
    return this.httpClient.patch('/djangousers/$self_django_user', settings, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /** Временная функция, которая конвертирует presence из localStorage в формат бэкенда и отсылает их в него */
  savePresenceFromStorageToPrefs(): Observable<any> {
    let jsonPresence = localStorage.getItem('carrotquest_djangousers_presence');
    let prefs = {};

    if (!jsonPresence) {
      return of();
    }

    if (!this.isJsonValid(jsonPresence)) {
      return of();
    }

    jsonPresence = JSON.parse(jsonPresence);

    //@ts-ignore
    for (const appId of Object.keys(jsonPresence)) {
      // если статус у аппа был online - не посылаем его на бэкенд, т.к. он онлайн у всех аппов, в которых числится пользователь, и он станет онлайн во всех своих аппах,
      // и останется онлайн, пока на бэкенде не истечёт таймаут перехода в оффлайн из-за отсутствия запросов set_presence
      //@ts-ignore
      if (jsonPresence !== null && jsonPresence[appId] === DJANGO_USER_PRESENCE_TYPES.ONLINE) {
        continue;
      }

      // раньше был ещё статус "отошёл" (idle), но было решено от него отказаться, т.к. никто из пользователей им не пользовался и не задавал вопросы по поводу него, хотя он работал очень плохо и неправильно
      //@ts-ignore
      if (jsonPresence !== null && jsonPresence[appId] === 'idle') {
        //@ts-ignore
        jsonPresence[appId] = DJANGO_USER_PRESENCE_TYPES.OFFLINE;
      }

      // в итоге в prefs попадут только статусы offline, которые пользователь выставлял себе сам, принудительно
      //@ts-ignore
      prefs['presence__' + appId] = jsonPresence[appId];
    }

    return this.save({ prefs: JSON.stringify(prefs) }, true).pipe(
      tap(() => localStorage.removeItem('carrotquest_djangousers_presence')),
    );
  }

  /**
   * Установка статуса в prefs
   * Не путать с sendPresence! Эта функция сохраняет статус, который выбрал пользователь при помощи переключателя, на бэкенде, для синхронизации между устройствами
   *
   * @param presence - Статус в prefs
   * @param appId - ID приложения
   */
  savePresence(presence: DJANGO_USER_PRESENCE_TYPES, appId: string): Observable<any> {
    let prefs = {};

    //@ts-ignore
    prefs['presence__' + appId] = presence;

    let params = {
      prefs: JSON.stringify(prefs),
    };

    return this.save(params, true);
  }

  /**
   * Сохранение временных данных django-пользователя
   *
   * @param djangoUserId - Id django-пользователя
   * @param tempData - Временные данные
   */
  saveTempData(djangoUserId: string, tempData: DjangoUserTempData): Observable<void> {
    const params = {
      temp_data: tempData,
    };

    return this.httpClient.patch<void>('/django_users/' + djangoUserId + '/settings', params, {
      context: new HttpContext().set(IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Получение текущего статуса настройки автоназначения диалогов на оператора
   *
   * @param djangoUserId Id django-пользователя
   */
  getAutoAssignStatus(djangoUserId: string): Observable<DjangoUserAutoAssignStatus> {
    return this.httpClient.get<DjangoUserAutoAssignStatus>(`/djangousers/${djangoUserId}/conversations_auto_assign`);
  }

  /**
   * Включение автоназначения на оператора
   *
   * @param djangoUserId Id django-пользователя
   */
  enableAutoAssign(djangoUserId: string): Observable<any> {
    return this.httpClient.post(`/djangousers/${djangoUserId}/conversations_auto_assign`, {});
  }

  /**
   * Массовое включение автоназначения у операторов
   *
   * @param djangoUserIds Id django-пользователей, у которых нужно включить автоназначение
   */
  enableAutoAssignBulk(djangoUserIds: string[]): Observable<void> {
    const params = {
      django_users: djangoUserIds,
    };

    return this.httpClient.post<void>(`/djangousers/conversations_auto_assign_bulk`, params);
  }

  /**
   * Отключение автоназначения на оператора
   *
   * @param djangoUserId Id django-пользователя
   */
  disableAutoAssign(djangoUserId: string): Observable<any> {
    return this.httpClient.delete(`/djangousers/${djangoUserId}/conversations_auto_assign`, {});
  }

  /**
   * Массовое отключение автоназначения у операторов
   *
   * @param djangoUserIds Id django-пользователей, у которых нужно отключить автоназначение
   */
  disableAutoAssignBulk(djangoUserIds: string[]): Observable<void> {
    const params = {
      django_users: djangoUserIds,
    };

    return this.httpClient.delete<void>(`/djangousers/conversations_auto_assign_bulk`, { body: params });
  }

  /**
   * Установка статуса в Offline
   *
   * @param appId - ID приложения
   */
  setOffline(appId: string): Observable<any> {
    return this.savePresence(DJANGO_USER_PRESENCE_TYPES.OFFLINE, appId);
  }

  /**
   * Установка статуса в Offline
   *
   * @param appId - ID приложения
   */
  setOnline(appId: string): Observable<any> {
    return this.savePresence(DJANGO_USER_PRESENCE_TYPES.ONLINE, appId);
  }

  /**
   * Установка статуса
   *
   * @param {string} appId ID приложения
   * @param {string} presence Статус
   */
  sendPresence(appId: string, presence: string): Observable<any> {
    let params = {
      presence: presence,
      app: appId,
      ignoreErrors: true,
    };

    return this.httpClient.post('/djangousers/$self_django_user/setpresence', params, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /** Получение значения настройки переназначения диалогов */
  getReassignConversationsValue(): Observable<boolean> {
    return this.httpClient
      .get(`/django_users/$self_django_user/settings`, {
        params: {},
      })
      .pipe(map((res: any) => res.unassignConversations));
  }

  /** Установка значения настройки переназначения диалогов */
  setUnassignConversationsValue(value: boolean): Observable<any> {
    const params = {
      unassign_conversations: value,
    };

    return this.httpClient.patch(`/django_users/$self_django_user/settings`, params);
  }

  /**
   * Загрузка аватара django-пользователя в аппе
   *
   * @param appId - ID приложения
   * @param avatarFile - Файл аватара
   */
  uploadAvatar(appId: string, avatarFile: File): Observable<any> {
    let body: any = {
      app: appId,
      file: avatarFile,
    };

    return this.httpClient.post('/djangousers/$self_django_user/uploadappavatar', body, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }
}
