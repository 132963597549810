import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ActionHeaderModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/action-header/action-header.module';
import { EventTypeActionModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/event-type-action/event-type-action.module';
import { PropertyActionModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/property-action/property-action.module';
import { UserTagActionModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-action-editor/user-tag-action/user-tag-action.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ActionsListComponent } from './actions-list.component';

@NgModule({
  declarations: [ActionsListComponent],
  exports: [ActionsListComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ActionHeaderModule,
    EventTypeActionModule,
    PropertyActionModule,
    UserTagActionModule,
  ],
})
export class ActionsListModule {}
