import { some } from 'lodash-es';
import { create, each, enforce, group, omitWhen, test } from 'vest';

import { TriggerChainStepFilter } from '@http/trigger-chain/internal-types';

export const triggerChainFilterStepValidator = create((step: TriggerChainStepFilter) => {
  group(step.uuid, () => {
    test('name', () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotEmpty();
    });

    test('meta.nextStepOnFail', () => {
      enforce(step.meta.nextStepOnFail).isNotEmpty();
    });

    omitWhen(
      some(step.meta.filters.filters.props, (filter) => filter.propertyName !== null),
      () => {
        each(step.meta.filters.filters.events, (event) => {
          test('meta.filters.filters.events', () => {
            enforce(event.eventId).isNotNull();
          });
        });
      },
    );

    omitWhen(
      some(step.meta.filters.filters.events, (filter) => filter.eventId !== null),
      () => {
        each(step.meta.filters.filters.props, (event) => {
          test('meta.filters.filters.props', () => {
            enforce(event.propertyName).isNotNull();
          });
        });
      },
    );

    omitWhen(step.meta.jinjaFilterTemplate !== null, () => {
      if (step.meta.jinjaFilterTemplate) {
        enforce(step.meta.jinjaFilterTemplate!.length).lessThan(8000);
      }
    });
  });
});
