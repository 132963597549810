<ng-container *transloco="let t; read: 'userEventControlComponent'">
  <div class="mb-10">{{ t('label') }}</div>
  <ng-select
    [formControl]="control"
    [items]="userEvents"
    bindLabel="name"
    [placeholder]="t('select.placeholder')"
  >
    <ng-container *ng-footer-tmp>
      <button
        (click)="openAddEventModal()"
        class="btn btn-secondary btn-block"
      >
        {{ t('select.addEventButton') }}
      </button>
    </ng-container>
  </ng-select>
</ng-container>
