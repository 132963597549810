import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { findIndex } from 'lodash-es';

import { environment } from '@environment';
import { EmailNotificationIntegrationSettingsExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { Properties, UserProperty } from '@http/property/property.model';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';

/** Емейл, с которого будет приходить оповещение */
const NOTIFICATION_EMAIL = {
  RU: 'notifications@carrotquest.io',
  EN: 'notifications@dashly.io',
};

/* eslint-disable change-detection-strategy/on-push */
@Component({
  selector:
    'cq-email-notification-email-preview[appName][comment][properties][selectedEventTypesIds][selectedUserProps][subject]',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
})
export class EmailNotificationEmailPreviewComponent implements OnInit {
  /** Название приложения */
  @Input() appName!: string;
  /** Текст комментария для получателей уведомления */
  @Input() comment!: EmailNotificationIntegrationSettingsExternal['comment'];
  /** Список свойств и событий, которые есть в App'е */
  @Input() properties!: Properties;
  /** ID выбранных событий-триггеров, по которым отправляется оповещение */
  @Input() selectedEventTypesIds!: EmailNotificationIntegrationSettingsExternal['eventTypeIds'];
  /** Выбранные свойства лида, которые придут в уведомлении */
  @Input() selectedUserProps!: EmailNotificationIntegrationSettingsExternal['props'];
  /** Тема письма-оповещения */
  @Input() subject!: EmailNotificationIntegrationSettingsExternal['subject'];
  /** Список кастомных свойств */
  customPropertyNames: UserProperty['name'][] = [];
  /** Емейл, с которого будут приходить оповещения */
  notificationsEmail: string;
  /** Текущее имя проекта */
  projectName = environment.projectName;

  constructor(private readonly l10nHelper: L10nHelperService, private readonly translocoService: TranslocoService) {
    // TODO Возможно стоит вынести в переменные окружения или как-то по-другому формировать эту переменную
    if (l10nHelper.isUsCountry()) {
      this.notificationsEmail = NOTIFICATION_EMAIL.EN;
    } else {
      this.notificationsEmail = NOTIFICATION_EMAIL.RU;
    }
  }

  ngOnInit(): void {
    this.customPropertyNames = this.getCustomPropertyNames();
  }

  /** Получение списка имён кастомных свойств */
  getCustomPropertyNames(): string[] {
    let propertyNames: string[] = [];

    for (let i = 0; i < this.properties.userProps.length; i++) {
      const prop = this.properties.userProps[i];

      const isCustomProperty = prop.groupOrder === 5;
      if (isCustomProperty) {
        propertyNames.push(prop.name);
      }
    }

    return propertyNames;
  }

  /** Получение первого выбранного события-триггера. Если такого нет, то возвращаем текст-заглушку */
  getFirstSelectedEventTypePrettyName(): string {
    if (this.selectedEventTypesIds.length === 0) {
      return this.translocoService.translate('emailNotificationEmailPreview.eventTypePlaceholder');
    }

    const firstSelectedEventTypeIndex = findIndex(
      this.properties.eventTypes,
      (eventType) => eventType.id === this.selectedEventTypesIds[0],
    );

    return this.properties.eventTypes[firstSelectedEventTypeIndex].prettyName;
  }

  /**
   * Получение перевода для названия свойства пользователя в preview уведомления
   *
   * @param propName - Название свойства пользователя
   */
  getTranslatedNameUserProperty(propName: string): string {
    let translatePropertyName;

    if (this.isPropertyCustom(propName)) {
      translatePropertyName = propName;
    } else {
      translatePropertyName = this.translocoService.translate(`models.property.userSystemProperties.${propName}`);
    }

    return translatePropertyName;
  }

  /**
   * Получение перевода для значения свойства пользователя в preview уведомления
   *
   * @param propName - Название свойства пользователя
   */
  getTranslatedValueUserProperty(propName: string): string {
    return this.translocoService.translate('emailNotificationEmailPreview.userInformation.propertyNameValueText', {
      propertyName: this.getTranslatedNameUserProperty(propName),
    });
  }

  /**
   * Является ли свойство кастомным
   *
   * @param propName - Название свойства пользователя
   */
  isPropertyCustom(propName: string): boolean {
    return this.customPropertyNames.includes(propName);
  }
}
