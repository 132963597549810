import { APP_TASKS } from '@http/app/app.constants';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

/**
 * Получение списка времен, после которых фичи станут не доступны для app'ов, у которого дата регистраци меньше или больше даты окончания фичи
 *
 * @return {Object}
 */
export enum END_TIME_FEATURES {
  WEB_PUSH = '2020-11-16',
}

/**
 * Получение списка фичей
 *
 * @return {Object}
 */
export enum FEATURES_ONLY {
  ACCESS_WITHOUT_CODE = 'access_without_code', // Доступ к админке без кода
  AI_SALES = 'ai_sales', // доступ к AI-sales
  AMOCRM_DOMAINS = 'amocrm_domains', // Возможность выбрать amocrm домен при OAuth авторизации
  AMOCRM_NOTIFICATION = 'amocrm_notification', // Интеграция AmoCRM
  AUTOTRACK_PHONE_COLLECT = 'autotrack_phone_collect', // Автоматический сбор телефонов в автотрекинге
  CANVAS_SCREENSHOT = 'canvas_screenshot',
  CHANGE_LANGUAGE = 'change_language', // Возможность смены языка
  CHAT_BOT_BUTTON_LINK = 'chat_bot_button_link', // Ссылки в кнопках бота
  CHAT_BOT_QUIZ = 'chat_bot_quiz', // Показ квиза при заходе в раздел чат-бот, если ни один ещё не был создан
  CHAT_BOT_TEMPLATE = 'chat_bot_template', // Копирование структуры бота для шаблона !!! и триггерных сообщений тоже, решили не пилить под них отдельную фичегалку
  LEGACY_WEBHOOK = 'legacy_webhook', // Возможность переключится на старый вебхук (должна быть возможность только для внедрения)
  CONVERSATIONS_AUTO_ASSIGNMENT = 'conversations_auto_assignment', // Автоназначение диалогов
  CONVERSATIONS_STATS = 'conversations_stats', // Включение сбора статистики по производительности раздела Диалоги
  CUSTOM_UNSUBSCRIBE_LINK = 'custom_unsubscribe_link', // Кастомная ссылка отписки от рассылок
  EMAIL_CONTENT_MAKER = 'email_content_maker', // генерация email через ai
  FACEBOOK_BOT = 'facebook_bot', // Facebook бот
  FARFOR_FIX = 'farfor_fix', // Включает обработку batch'ей из RTS через очередь RxJS
  GENERAL_FUNNEL_CHART = 'general_funnel_chart', // Показ основной воронки в общей статистике
  HIDE_ONE_MONTH_PAYMENT_PERIOD = 'hide_one_month_payment_period', // Скрытие возможности выбора 1 месяца в качестве периода оплаты
  LEAD_BOT_SDK = 'lead_bot_sdk', // Создание лид-бота для SDK
  LEADS_STATISTIC_CHART = 'leads_statistic_chart', // График лидов в общей статистике
  MANUAL_MESSAGE_EXTENDED_SIZE = 'manual_message_extended_size', // Расширенная квота ручной отправки ручных сообщений
  MANUAL_MESSAGE_EXTRA_EXTENDED_SIZE = 'manual_message_extra_extended_size', // Расширенная квота ручной отправки ручных сообщений
  MESSAGE_PLANNING = 'message_planning', // Запланированные рассылки // NOTE: эту фичагалку удалять нельзя, т.к. иногда при падениях бэка нужно всем отключить ручные рассылки
  NEW_EDNA_API = 'new_edna_api', // Новое API WhatsApp edna
  OLD_ADDONS_LIST = 'old_addons_list', // Старый список модулей (если фича включена - у апа отсутствует информация о модуле No Branding, его нельзя подключить)
  OUTDATED_BROWSER_BANNER = 'outdated_browser_banner', // Баннер для устаревших браузеров // NOTE: эту фичагалку лучше не удалять, т.к. она пригодится при следующем отказе от старых браузеров
  QUIZ_FOR_TASK_TALK_TO_CUSTOMERS = 'quiz_for_task_talk_to_customers', // Показ квиза для пользователей, которые выбрали задачу talkToCustomers при регистрации
  QUIZ_FOR_TASK_OTHER = 'quiz_for_task_other', // Показ квиза для пользователей, которые выбрали задачу other при регистрации
  QUIZ_FOR_TASK_RAISE_LEAD_CONVERSION = 'quiz_for_task_raise_lead_conversion', // Показ квиза для пользователей, которые выбрали задачу raiseLeadConversion при регистрации
  QUIZ_FOR_TASK_RAISE_PURCHASE_CONVERSION = 'quiz_for_task_raise_purchase_conversion', // Показ квиза для пользователей, которые выбрали задачу raisePurchaseConversion при регистрации
  QUIZ_FOR_TASK_SUPPORT_CUSTOMERS = 'quiz_for_task_support_customers', // Показ квиза для пользователей, которые выбрали задачу supportCustomers при регистрации
  SEND_MANUAL_MESSAGES = 'send_manual_messages', // Отправка ручных сообщений // NOTE: эту фичагалку удалять нельзя, т.к. иногда при падениях бэка нужно всем отключить ручные рассылки
  TAGS_OPTIMIZED = 'tags_optimized', //Оптимизированная версия тегов

  // !!! Фичегалка с WIDGET_BOT также указывается в cqstatic, это сделано чтобы сократить количество постоянных запросов
  WIDGET_BOT = 'widget_bot', // Widget бот
  TRIGGER_CHAIN = 'trigger_chain', // Триггерные цепочки
  USERGUIDING_BOTS_ONBOARDING = 'userguiding_bots_onboarding', // показ кнопки запуска онбординга
  USERGUIDING_MESSAGES_ONBOARDING = 'userguiding_messages_onboarding', // показ кнопки запуска онбординга
  WEB_PUSH = 'web_push', // Возможность использовать web-push,
  YANDEX_AI = 'yandex_ai', // NOTE По идее, это должно быть типом интеграции, но пока интеграция не настраивается на фронте нужно это оставить здесь
  CHAT_GPT = 'chat_gpt', // ChatGPT в качестве AI ассистента
  NEW_YEAR = 'new_year', // Новогоднее настроение, для включения нг фона и лого
  SERVICE_BILLING_INFO = 'service_billing_info', // Доступ до сервисной страницы с информацией о биллинге NOTE ТОЛЬКО для врутреннего пользования
  SCHEDULED_MESSAGES = 'scheduled_messages', // сообщения по расписанию
  WEB_CRAWLER = 'web_crawler', // Краулер веб-страниц
  CUSTOM_EMAIL_DOMAINS = 'custom_email_domains',
}

export const FEATURES = {
  ...FEATURES_ONLY,
  ...INTEGRATION_TYPES,
};

export type FEATURES = FEATURES_ONLY | INTEGRATION_TYPES;

/**
 * Во время регистрации пользователь выбирает задачу, которую он хочет решить. Для некоторых таких задачи после регистрации
 * мы показываем квиз. Чтобы была возможность добавлять показ квиза для других задач без участия разработичка, была
 * заведена эта константа: достаточно включить фичегалку для соответствующей задачи в джанго-админке.
 */
export const FEATURE_BY_REGISTRATION_TASK = {
  [APP_TASKS.TALK_TO_CUSTOMERS]: FEATURES.QUIZ_FOR_TASK_TALK_TO_CUSTOMERS,
  [APP_TASKS.RAISE_PURCHASE_CONVERSION]: FEATURES.QUIZ_FOR_TASK_RAISE_PURCHASE_CONVERSION,
  [APP_TASKS.RAISE_LEAD_CONVERSION]: FEATURES.QUIZ_FOR_TASK_RAISE_LEAD_CONVERSION,
  [APP_TASKS.SUPPORT_CUSTOMERS]: FEATURES.QUIZ_FOR_TASK_SUPPORT_CUSTOMERS,
  [APP_TASKS.OTHER]: FEATURES.QUIZ_FOR_TASK_OTHER,
};
