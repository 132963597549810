import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { Viewport } from 'pixi-viewport';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { TriggerChain, TriggerChainStep } from '@http/trigger-chain/internal-types';
import { TriggerChainUtils } from '@http/trigger-chain/trigger-chain.utils';
import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { CanvasOverlayHandlerService } from '@panel/app/pages/trigger-chains/editor/components/canvas/services/canvas-overlay-handler.service';
import { TriggerChainCullingService } from '@panel/app/pages/trigger-chains/editor/components/canvas/services/trigger-chain-culling.service';
import { ViewportPointerFollowService } from '@panel/app/pages/trigger-chains/editor/components/canvas/services/viewport-pointer-follow.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { DestroyService } from '@panel/app/services';
import {
  CANVAS_ZOOM_DIRECTION,
  CANVAS_ZOOM_SETTINGS,
} from '@panel/app/services/canvas/common/base/canvas-base.constants';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { CanvasScreenshotService } from '@panel/app/services/canvas/common/screenshot/canvas-screenshot.service';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewService } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.service';
import { AtlasBuilderService } from '@panel/app/services/canvas/tirgger-chain/builders/atlas-builder/atlas-builder.service';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { screenshotAnimation } from '@panel/app/shared/animations/screenshot';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

/**
 * Компонент для работы с canvas цепочки
 */
@Component({
  selector: 'cq-trigger-chain-canvas',
  templateUrl: './trigger-chain-canvas.component.html',
  styleUrls: ['./trigger-chain-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CanvasScreenshotService, DestroyService, ViewportPointerFollowService, TriggerChainCullingService],
  animations: [screenshotAnimation()],
})
export class TriggerChainCanvasComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input({ required: true })
  chain!: TriggerChain;

  @Output()
  clickOnAddChainStepButton: Subject<TRIGGER_CHAIN_BLOCK_TYPE> = new Subject<TRIGGER_CHAIN_BLOCK_TYPE>();

  /** Контейнер в который будет инициализирован canvas */
  @ViewChild('canvasContainer', { static: true })
  canvasContainer!: ElementRef<HTMLElement>;

  /** Доступ до возможности делать скриншот канваса */
  hasAccessToCanvasScreenshotFeature!: boolean;

  /** Флаг выполнения скриншота канваса */
  screenshotInProgress!: boolean;

  constructor(
    protected readonly canvasBaseService: CanvasBaseService,
    public readonly canvasScreenshotService: CanvasScreenshotService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly clipboard: ClipboardService,
    private readonly connectionService: ConnectionService,
    private readonly blockViewService: BlockViewService,
    private readonly destroy$: DestroyService,
    private readonly viewportPointerFollowService: ViewportPointerFollowService,
    private readonly canvasOverlayHandler: CanvasOverlayHandlerService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly triggerChainUtils: TriggerChainUtils,
    private readonly featureModel: FeatureModel,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    private readonly triggerChainCullingService: TriggerChainCullingService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
  ) {
    this.hasAccessToCanvasScreenshotFeature = this.featureModel.hasAccess(FEATURES_ONLY.CANVAS_SCREENSHOT);
  }

  ngOnInit() {
    if (this.hasAccessToCanvasScreenshotFeature) {
      this.canvasScreenshotService.screenshotInProgress.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.screenshotInProgress = value;
        this.changeDetectorRef.detectChanges();
      });
    }
    this.initClipboardSubscription();
  }

  ngAfterViewInit(): void {
    this.canvasBaseService.clear();
    this.canvasBaseService.mount(this.canvasContainer);
    this.canvasBaseService.resize();
    const baseTexture = AtlasBuilderService.buildTexture(this.pixiApp.renderer);

    this.connectionService.initObservers();

    this.triggerChainCullingService.initCulling();
    this.createViewsOnCanvas();
    this.createConnectionsOnCanvas();
    this.moveViewportToSendingConditions();
    this.viewportPointerFollowService.init(this.canvasContainer);
    this.canvasOverlayHandler.initOverlays(this.chain);
  }

  ngOnDestroy() {
    AtlasBuilderService.destroyBaseTexture();
  }

  get hasAccessToCopyTemplate(): boolean {
    return this.featureModel.hasAccess(FEATURES_ONLY.CHAT_BOT_TEMPLATE);
  }

  /** Создаёт связи между шагами на canvas */
  createConnectionsOnCanvas(): void {
    this.chain.steps.forEach((step: TriggerChainStep) => {
      let sourceView = this.blockViewService.getViewByUUID(step.uuid);

      let successStep: TriggerChainStep | null;
      let successView: IBlockView;
      let failStep: TriggerChainStep | null;
      let failView: IBlockView;

      switch (step.type) {
        case 'action':
        case 'autoMessage':
        case 'sendingConditions':
          successStep = this.chain.steps.find((s) => s.uuid === step.meta.nextStep) ?? null;
          if (successStep) {
            successView = this.blockViewService.getViewByUUID(successStep.uuid);
          }
          break;
        case 'delay':
        case 'filter':
        case 'reaction':
          successStep = this.chain.steps.find((s) => s.uuid === step.meta.nextStep) ?? null;
          if (successStep) {
            successView = this.blockViewService.getViewByUUID(successStep.uuid);
          }

          failStep = this.chain.steps.find((s) => s.uuid === step.meta.nextStepOnFail) ?? null;
          if (failStep) {
            failView = this.blockViewService.getViewByUUID(failStep.uuid);
          }
          break;
        case 'exit':
          break;
        default:
          throw new Error('Case is not handled');
      }

      sourceView.interactiveBlockParts.forEach((action) => {
        switch (action.type) {
          case INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT:
          case INTERACTIVE_BLOCK_PART.DELAY:
          case INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS:
          case INTERACTIVE_BLOCK_PART.FILTER_MATCH:
          case INTERACTIVE_BLOCK_PART.REACTION:
          case INTERACTIVE_BLOCK_PART.ACTION:
            if (successView) {
              this.connectionService.addConnectionBySteps(action, successView);
            }
            break;
          case INTERACTIVE_BLOCK_PART.DELAY_PASSED:
          case INTERACTIVE_BLOCK_PART.FILTER_NOT_MATCH:
          case INTERACTIVE_BLOCK_PART.NO_REACTION:
            if (failView) {
              this.connectionService.addConnectionBySteps(action, failView);
            }
            break;
          default:
            throw new Error('Case is not handled');
        }
      });
    });
  }

  /** Создаёт представления звеньев цепочки на canvas */
  createViewsOnCanvas(): void {
    this.chain.steps.forEach((step: TriggerChainStep) => this.blockViewService.addViewByStep(step));
  }

  /** Обрабатывает клик по кнопке копирования структуры цепочки */
  onClickCopyStructureForTemplate(): void {
    this.clipboard.copy(
      JSON.stringify(
        this.triggerChainUtils.copyStructure(
          this.chain,
          this.triggerChainEditorStore.properties$.getValue().eventTypes,
        ),
      ),
    );
  }

  /**
   * Инициализация слушателя копирования
   * @private
   */
  private initClipboardSubscription() {
    this.clipboard.copyResponse$.pipe(takeUntil(this.destroy$)).subscribe((response: IClipboardResponse) => {
      if (response.isSuccess) {
        this.toastService.success(this.translocoService.translate('chatBot.branchContent.toasts.copied'));
      }
    });
  }

  /**
   * Обрабатывает клик по кнопке добавления нового звена в цепочку
   *
   * @param stepType - Тип шага
   */
  onClickOnAddChainStepButton(stepType: TRIGGER_CHAIN_BLOCK_TYPE): void {
    this.clickOnAddChainStepButton.next(stepType);
  }

  /** Обрабатывает клик по кнопке возврата в начало цепочки */
  moveViewportToSendingConditions(): void {
    const sendingConditionsBlock = this.blockViewService.store.find(
      (block) => block.type === TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION,
    );
    if (!sendingConditionsBlock) {
      throw new Error('Could not find a sending conditions block');
    }
    this.canvasBaseService.moveViewportTo(sendingConditionsBlock.graphicContainer);
  }

  /** Обрабатывает клик по кнопке изменения zoom в canvas */
  onClickOnChangeZoomButton(value: CANVAS_ZOOM_DIRECTION | CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE): void {
    switch (value) {
      case CANVAS_ZOOM_DIRECTION.UP:
        return this.canvasBaseService.zoomUp();
      case CANVAS_ZOOM_DIRECTION.DOWN:
        return this.canvasBaseService.zoomDown();
      default:
        this.canvasBaseService.zoomSet(+value);
    }
  }

  /** Обрабатывает клик по кнопке переключения fullscreen режима */
  onClickOnToggleFullscreenModeButton(): void {
    this.canvasBaseService.toggleFullscreen();

    // Canvas перед resize должен увидеть, что размеры container изменились
    this.changeDetectorRef.detectChanges();

    this.canvasBaseService.resize();
  }

  /**
   * Обрабатывает движение колеса мышки
   *
   * @param event - Событие движения колеса мышки
   */
  onWheel(event: MouseEvent) {
    event.preventDefault();
  }

  /** Создание скриншота цепочки */
  takeScreenshot() {
    this.canvasScreenshotService.take();
  }
}
