import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiFor, TuiKeysPipe, TuiLet } from '@taiga-ui/cdk';
import { GroupByPipe, NgArrayPipesModule } from 'ngx-pipes';

import { ConversationOperatorModule } from '@panel/app/pages/conversations/conversation-operator/conversation-operator.module';
import { NotAssignedModule } from '@panel/app/pages/conversations/not-assigned/not-assigned.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';

import { AssigneeFilterComponent } from './assignee-filter.component';

@NgModule({
  declarations: [AssigneeFilterComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    NotAssignedModule,
    TuiLet,
    NgArrayPipesModule,
    ConversationOperatorModule,
    TuiKeysPipe,
    TuiFor,
    DirectivesModule,
  ],
  providers: [GroupByPipe],
})
export class AssigneeFilterModule {}
