import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgFooterTemplateDirective, NgMultiLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { NotificationChannelSettingsComponent } from '@panel/app/pages/account/shared/notification-settings/notification-channel-settings/notification-channel-settings.component';
import { ConversationChannelModule } from '@panel/app/pages/conversations/conversation-channel/conversation-channel.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [NotificationChannelSettingsComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ConversationChannelModule,
    NgSelectComponent,
    NgMultiLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgFooterTemplateDirective,
  ],
  exports: [NotificationChannelSettingsComponent],
  providers: [CarrotquestHelperProvider],
})
export class NotificationChannelSettingsModule {}
