import { NavigationState } from '@panel/app/services/block-panel/block-panel.types';

/**
 * Разделы навигаций
 */
export enum NAVIGATION_SECTION {
  DASHBOARD = 'dashboard',
  LEADS = 'leads',
  AUTO_MESSAGES = 'auto_messages',
  CONVERSATIONS = 'conversations',
  CHAT_BOTS = 'chat_bots',
  TRIGGER_CHAIN = 'chains',
  KNOWLEDGE_BASE = 'knowledge_base',
  REPORTS = 'reports',
  INTEGRATIONS = 'integrations',
  SETTINGS = 'settings',
  SUBSCRIPTION = 'subscription',
}

/**
 * Получение списка состояний, которые соответствуют каждому разделу в навигации
 */
export const NAVIGATIONS_STATES: NavigationState = {
  [NAVIGATION_SECTION.DASHBOARD]: ['app.content.dashboard'],
  [NAVIGATION_SECTION.LEADS]: ['app.content.live', 'app.content.users'],
  [NAVIGATION_SECTION.AUTO_MESSAGES]: [
    'app.content.messages.auto',
    'app.content.messagesAjs.create',
    'app.content.messagesAjs.edit',
    'app.content.messagesAjs.templates',
    'app.content.messagesAjs.createOnTemplate',
    'app.content.messagesAjs.createOnReadyTemplate',
  ],
  [NAVIGATION_SECTION.CONVERSATIONS]: ['app.content.conversations.general', 'app.content.conversations.detail'],
  [NAVIGATION_SECTION.TRIGGER_CHAIN]: [
    'app.content.triggerChains',
    'app.content.triggerChains.list',
    'app.content.triggerChains.editor.edit',
    'app.content.triggerChains.editor.create',
  ],
  [NAVIGATION_SECTION.CHAT_BOTS]: [
    'app.content.messagesAjs.chatBot',
    'app.content.messagesAjs.routingBot',
    'app.content.messagesAjs.facebookBot',
    'app.content.messagesAjs.widgetBot',
  ],
  [NAVIGATION_SECTION.KNOWLEDGE_BASE]: ['app.content.knowledgeBase'],
  [NAVIGATION_SECTION.REPORTS]: [
    'app.content.funnels',
    'app.content.analytics',
    'app.content.messagesAjs.manual',
    'app.content.conversations.statistics',
  ],
  [NAVIGATION_SECTION.INTEGRATIONS]: ['app.content.integrations'],
  [NAVIGATION_SECTION.SETTINGS]: [
    'app.content.settings',
    'app.content.trackmaster',
    'app.content.dataCollection',
    'app.content.teamMembers',
    'app.content.settings.developers',
    'app.content.channels',
    'app.content.conversationsSettings',
  ],
  [NAVIGATION_SECTION.SUBSCRIPTION]: ['app.content.subscription'],
};
