import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { AiSalesListComponent } from './ai-sales-list.component';

@NgModule({
  declarations: [AiSalesListComponent],
  exports: [AiSalesListComponent],
  imports: [CommonModule, UIRouterModule, TranslocoModule],
})
export class AiSalesListModule {}
