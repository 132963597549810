import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import sortBy from 'lodash-es/sortBy';
import { GroupByPipe } from 'ngx-pipes';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';
import { DjangoUser } from '@http/django-user/django-user.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { TeamMemberGroup } from '@http/team-member-group/team-member-group.types';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Псевдоназначения, вдобавок к членам команды
 */
enum PSEUDO_ASSIGNEES {
  ALL = 'all', // без фильтра
  NOT_ASSIGNED = 'notAssigned', // неназначенные
  MINE_AND_NOT_ASSIGNED = 'mineAndNotAssigned', // мои и неназначенные
}

@Component({
  selector: 'cq-assignee-filter',
  templateUrl: './assignee-filter.component.html',
  styleUrls: ['./assignee-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AppService],
})
export class AssigneeFilterComponent {
  readonly control: FormControl<PSEUDO_ASSIGNEES | CONVERSATION_ASSISTANT_TYPES | TeamMember> = new FormControl<
    PSEUDO_ASSIGNEES | TeamMember
  >(PSEUDO_ASSIGNEES.ALL, {
    nonNullable: true,
  });

  availableAssistants: CONVERSATION_ASSISTANT_TYPES[] = this.conversationsStoreService.availableAssistants$.getValue();
  currentApp: App = this.conversationsStoreService.currentApp$.getValue()!;
  djangoUser: DjangoUser = this.conversationsStoreService.djangoUser$.getValue();
  teamMembers: TeamMember[] = this.conversationsStoreService.teamMembers$.getValue();

  /**
   * Список назначения для фильтра по членам команды
   */
  @Input()
  assignees: (PSEUDO_ASSIGNEES | TeamMember)[] = [];

  /** TODO Скорее всего, эту штуку лучше вынести в стор */
  @Input()
  selectedAssistantType!: CONVERSATION_ASSISTANT_TYPES;

  @Input()
  disabled: boolean = false;

  @Input()
  set value(value: PSEUDO_ASSIGNEES | TeamMember) {
    this.control.setValue(value, { emitEvent: false });
  }

  @Output()
  valueChanges = this.control.valueChanges.pipe(distinctUntilChanged());

  PSEUDO_ASSIGNEES = PSEUDO_ASSIGNEES;
  PSEUDO_ASSIGNEES_ARRAY = Object.values(PSEUDO_ASSIGNEES);

  searchPhrase: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  /** Отфильтрованные по имени члены команды */
  filteredTeamMembers$: Observable<TeamMember[]> = this.searchPhrase.valueChanges.pipe(
    debounceTime(400),
    startWith(''),
    distinctUntilChanged(),
    map((nameFilter) => {
      return this.filterByName(this.sortedTeamMembers, nameFilter);
    }),
    map((teamMembers) => {
      return teamMembers.filter((teamMember) => teamMember.id !== this.me.id);
    }),
  );

  /** Группировка отфильтрованных членов команды по уникальным группам */
  groupedTeamMembers$: Observable<Map<TeamMemberGroup, TeamMember[]>> = this.filteredTeamMembers$.pipe(
    map((teamMembers) => {
      return this.groupByPipe.transform(teamMembers, 'group.name');
    }),
    map((groupedTeamMembers: { [key: string]: TeamMember[] }) => {
      const map = new Map<TeamMemberGroup, TeamMember[]>();

      this.getUniqueGroups(this.teamMembers).forEach((teamMemberGroup) => {
        if (groupedTeamMembers[teamMemberGroup.name]) {
          map.set(teamMemberGroup, groupedTeamMembers[teamMemberGroup.name]);
        }
      });

      return map;
    }),
  );

  constructor(
    public readonly appService: AppService,
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly conversationsStoreService: ConversationsStoreService,
    private readonly groupByPipe: GroupByPipe,
    private readonly translocoService: TranslocoService,
  ) {}

  get me(): TeamMember {
    return this.getMe(this.teamMembers, this.djangoUser.id);
  }

  get sortedTeamMembers(): TeamMember[] {
    return this.sortByName(this.teamMembers);
  }

  /**
   * Case insensitive фильтрация списка членов команды по имени
   *
   * @param teamMembers Список членов команды
   * @param filter Значение фильтра
   */
  @tuiPure
  filterByName(teamMembers: TeamMember[], filter: string): TeamMember[] {
    return teamMembers.filter((teamMember) => teamMember.name.toLowerCase().includes(filter.toLowerCase()));
  }

  /**
   * Получение себя
   *
   * @param teamMembers Список членов команды
   * @param myId ID текущего django-пользователя
   */
  @tuiPure
  getMe(teamMembers: TeamMember[], myId: string): TeamMember {
    return teamMembers.find((teamMember) => teamMember.id === myId)!;
  }

  /**
   * Получение отсортированного по имени списка уникальных групп членов команды
   * Сортировка - case insensitive
   * Системные группы идут в конце списка
   *
   * @param teamMembers Список членов команды
   */
  @tuiPure
  getUniqueGroups(teamMembers: TeamMember[]): TeamMemberGroup[] {
    return sortBy(
      [...new Map(teamMembers.map((teamMember) => [teamMember.group.id, teamMember.group])).values()],
      ['isSystem', (group) => group.name.toLowerCase()],
    );
  }

  /**
   * Case insensitive сортировка членов команды
   *
   * @param teamMembers Список членов команды
   */
  @tuiPure
  sortByName(teamMembers: TeamMember[]): TeamMember[] {
    return sortBy(teamMembers, (teamMember) => teamMember.name.toLowerCase());
  }

  isTeamMember(value: PSEUDO_ASSIGNEES | CONVERSATION_ASSISTANT_TYPES | TeamMember): value is TeamMember {
    return value.hasOwnProperty('id');
  }

  /** Трек по ID канала */
  trackByTeamMemberId(index: number, teamMember: TeamMember): string {
    return teamMember.id;
  }

  /** Трек по названию группы */
  trackByGroupName(index: number, groupedTeamMembers: { key: TeamMemberGroup; value: TeamMember[] }): string {
    return groupedTeamMembers.key.id;
  }

  /**
   * Получение иконки для ассистента
   *
   * @param assistantType
   */
  getAssistantIcon(assistantType: CONVERSATION_ASSISTANT_TYPES) {
    switch (assistantType) {
      case CONVERSATION_ASSISTANT_TYPES.AI_SALES:
        return 'cqi-robot';
      case CONVERSATION_ASSISTANT_TYPES.YANDEX_AI:
        return 'cqi-yandex';
      case CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT:
        return 'cqi-robot';
      case CONVERSATION_ASSISTANT_TYPES.FACEBOOK_BOT:
        return 'cqi-facebook';
      case CONVERSATION_ASSISTANT_TYPES.TELEGRAM_BOT:
        return 'cqi-telegram';
      case CONVERSATION_ASSISTANT_TYPES.CHAT_GPT:
        return 'cqi-robot';
      default:
        return '';
    }
  }

  trackClickOnTheFilterByOperatorButton(filteredBy: any) {
    const eventName = 'Диалоги - Применил  фильтр по операторам';
    const eventParams: Record<string, any> = {
      App: this.currentApp.name,
      'Права пользователя': this.djangoUser.prefs[this.appService.currentAppId].permissions,
    };

    if (filteredBy === PSEUDO_ASSIGNEES.ALL) {
      eventParams['Отфильтровал по'] = 'Без фильтра по операторам';
    } else if (filteredBy === PSEUDO_ASSIGNEES.NOT_ASSIGNED) {
      eventParams['Отфильтровал по'] = 'Неразобранные';
    } else if (filteredBy === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED) {
      eventParams['Отфильтровал по'] = 'Мои диалоги и неразобранные';
    } else if (filteredBy === this.me) {
      eventParams['Отфильтровал по'] = 'Мои диалоги';
    } else {
      eventParams['Отфильтровал по'] = filteredBy;
    }

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackClickOnTheFilterByAssistantButton(assistant: CONVERSATION_ASSISTANT_TYPES) {
    const eventName = 'Диалоги - Применил фильтр по чат-боту';
    const eventParams = {
      App: this.currentApp.name,
      'Чат-бот': this.translocoService.translate(`assigneeFilterComponent.assistants.${assistant}`),
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }
}
