import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import Quill, { Range } from 'quill';
import { delay } from 'rxjs';

import { EditorService } from '../../service/editor.service';

@Component({
  selector: 'cq-editor-format-link',
  templateUrl: './editor-format-link.component.html',
  styleUrls: ['./editor-format-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFormatLinkComponent implements OnInit {
  @ViewChild('linkTooltipInstance', { static: false })
  linkTooltipInstance!: NgbTooltip;

  @ViewChild('linkPopoverInstance', { static: false })
  linkPopoverInstance!: NgbPopover;

  @ViewChild('selectionLinkPopoverContainer', { static: false })
  selectionLinkPopoverContainer!: ElementRef;

  @ViewChild('selectionLinkPopoverInstance', { static: false })
  selectionLinkPopoverInstance!: NgbPopover;

  @Input({ required: true })
  public editor!: Quill;

  @Input({ required: true })
  public editorComponentRef!: ElementRef;

  protected activeTab: number = 1;
  protected linkFormGroup = this.formBuilder.group({
    href: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    text: this.formBuilder.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isBlank: this.formBuilder.control(true, {
      validators: [],
      nonNullable: true,
    }),
  });

  private selection: Range | null = null;

  constructor(private formBuilder: FormBuilder, private editorService: EditorService) {}

  ngOnInit(): void {
    this.editorService.clickOnEditor$.pipe(delay(200)).subscribe((target) => {
      if (target && target.classList.contains('cq-editor-link')) {
        let formatNode = target as HTMLAnchorElement;

        this.saveSelection();
        this.setSelectionLinkPopoverPosition(formatNode);

        this.linkFormGroup.setValue({
          href: formatNode.getAttribute('href') ?? '',
          text: formatNode.innerText,
          isBlank: formatNode.getAttribute('target') === '_blank',
        });

        this.openSelectionFilePopover();
      }
    });
  }

  protected deleteLink(): void {
    this.editorService.formatLinkDelete(this.editor, this.selection);
    this.selectionLinkPopoverInstance.close();
  }

  protected onClickLinkPopoverButton(): void {
    this.saveSelection();
    this.closeTooltip();

    let href = '';
    let text = '';
    let blank = '_blank';

    if (this.selection) {
      text = this.editor.getText(this.selection.index, this.selection.length);
    }

    this.linkFormGroup.setValue({
      href,
      text,
      isBlank: blank === '_blank',
    });

    this.linkPopoverInstance.open();
  }

  protected openLink(): void {
    let { href } = this.linkFormGroup.getRawValue();

    this.editorService.formatLinkOpen(href);

    this.resetActiveTab();
  }

  protected insertLink(): void {
    let { href, text, isBlank } = this.linkFormGroup.getRawValue();
    let target = isBlank ? '_blank' : '_target';

    this.editorService.formatLinkInsert(this.editor, this.selection, {
      text,
      href,
      target,
    });

    this.linkFormGroup.setValue({
      href: '',
      text: '',
      isBlank: true,
    });

    this.closeLinkPopover();
    this.closeSelectionLinkPopover();
    this.resetActiveTab();
  }

  private closeLinkPopover(): void {
    this.linkPopoverInstance.close();
  }

  private closeSelectionLinkPopover(): void {
    this.selectionLinkPopoverInstance.close();
  }

  private closeTooltip(): void {
    this.linkTooltipInstance.close();
  }

  protected onClickDeleteLinkButton(): void {
    this.saveSelection();
    this.deleteLink();
    this.closeSelectionLinkPopover();
  }

  protected onClickOpenLinkButton(): void {
    this.openLink();
    this.closeSelectionLinkPopover();
  }

  protected onSubmitForm(event: SubmitEvent): void {
    event.stopPropagation();

    this.insertLink();
    this.closeSelectionLinkPopover();
  }

  protected openSelectionFilePopover(): void {
    this.selectionLinkPopoverInstance.open();
  }

  private saveSelection(): void {
    this.selection = this.editor.getSelection(true);
  }

  private setSelectionLinkPopoverPosition(formatNode: HTMLElement): void {
    let width = formatNode.offsetWidth;
    let height = formatNode.offsetHeight;

    let rect = formatNode.getBoundingClientRect();
    let containerRect = this.editorComponentRef.nativeElement.getBoundingClientRect();

    let top = rect.top - containerRect.top + this.editorComponentRef.nativeElement.scrollTop;
    let left = rect.left - containerRect.left + this.editorComponentRef.nativeElement.scrollLeft;

    this.selectionLinkPopoverContainer.nativeElement.style.position = 'absolute';
    this.selectionLinkPopoverContainer.nativeElement.style.top = `${top + height}px`;
    this.selectionLinkPopoverContainer.nativeElement.style.left = `${left + width / 2}px`;
  }

  private resetActiveTab(): void {
    this.activeTab = 1;
  }
}
