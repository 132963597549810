import { MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { POPUP_BLOCK_TYPES } from '../../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupInputWithBigButtonBlockSettingsController', CqPopupInputWithBigButtonBlockSettingsController);

  function CqPopupInputWithBigButtonBlockSettingsController(
    $filter,
    $scope,
    $translate,
    $uibModal,
    PROJECT_NAME,
    carrotquestHelper,
    eventTypeModel,
    l10nHelper,
    planFeatureAccessService,
    popupBlockModel,
    propertyModel,
    shortUuid,
    validationHelper,
  ) {
    var vm = this;

    /**
     * Вкладки
     *
     * @type {{CONTENT: String, STYLE: String}}
     */
    var TABS = {
      CONTENT: 'content', // содержимое
      STYLE: 'style', // стиль
    };

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.currentApp,
      );
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );

      vm.addInput = addInput;
      vm.contentForm = null; // форма вкладки "Содержимое"
      vm.currentTab = TABS.CONTENT; // текущая вкладка
      vm.eventsEventTypesCustomCapabilityFilter = eventsEventTypesCustomCapabilityFilter;
      vm.isConfirmSubscriptionWithCheckbox = vm.currentApp.settings.messenger_confirm_subscription_with_checkbox;
      vm.isExtraSettingsOpened = !!(vm.popupBlockParams.eventType || vm.popupBlockParams.showDataCollectionConsent); // флаг раскрытия дополнительных настроек
      vm.isOldSettingsForPrivacyPolicy =
        !vm.currentApp.settings.terms_of_email_storage &&
        !vm.currentApp.settings.terms_of_phone_storage &&
        vm.currentApp.settings.messenger_confirm_subscription_policy;
      vm.isRusCountry = l10nHelper.isRusCountry();
      vm.openCreateEventTypeModal = openCreateEventTypeModal;
      vm.openCreateUserPropertyModal = openCreateUserPropertyModal;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.removeInput = removeInput;
      vm.styleForm = null; // форма вкладки "Стиль"
      vm.swapInputWithNext = angular.bind(null, swapInputs, 1);
      vm.swapInputWithPrevious = angular.bind(null, swapInputs, -1);
      vm.TABS = TABS;
      vm.trackClickOnContent = trackClickOnContent;
      vm.trackClickOnStyle = trackClickOnStyle;
      vm.userCustomPropertiesCapabilityFilter = userCustomPropertiesCapabilityFilter;
      vm.UUID = UUID;
      vm.validationHelper = validationHelper;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });

      // HACK: у старых поп-апов в блоках не существует цветов для чекбокса, текста и ссылки, поэтому их нужно заполнить актуальными цветами при включении чекбокса согласия на сбор данных
      $scope.$watch('vm.popupBlockParams.showDataCollectionConsent', watchShowDataCollectionConsent);

      function watchShowDataCollectionConsent(newValue) {
        if (newValue) {
          vm.popupBlockParams.dataCollectionCheckboxColor =
            vm.popupBlockParams.dataCollectionCheckboxColor || vm.popupBlockParams.buttonBackgroundColor;
          vm.popupBlockParams.dataCollectionConsentColor =
            vm.popupBlockParams.dataCollectionConsentColor ||
            popupBlockModel.getDefault(POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON).params.dataCollectionConsentColor;
          vm.popupBlockParams.privacyPolicyUrlColor =
            vm.popupBlockParams.privacyPolicyUrlColor || vm.popupBlockParams.buttonBackgroundColor;
        }
      }
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (!vm.popupBlockParams.inputs[0].userProperty) {
        vm.popupBlockParams.inputs[0].userProperty = $filter('filter')(vm.userProperties, { name: '$email' }, true)[0];
      }
    }

    /**
     * Добавление нового поля ввода в блок
     */
    function addInput() {
      vm.popupBlockParams.inputs.push(popupBlockModel.getDefaultInputParams());
    }

    /**
     * Фильтрация событий с учетом ограничений тарифа
     *
     * @param {Object} eventType - Объект с информацией о событием
     *
     * @return {Boolean}
     */
    function eventsEventTypesCustomCapabilityFilter(eventType) {
      return (
        vm.accessToEventsEventTypesCustom.hasAccess ||
        (vm.popupBlockParams.eventType && eventType.name === vm.popupBlockParams.eventType.name) ||
        (typeof eventType.name === 'string' && eventType.name.includes('$'))
      );
    }

    /**
     * Функция открытия вкладки с ошибкой при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.contentForm.$setSubmitted();
      vm.styleForm.$setSubmitted();

      // если открыта невалидная вкладка - переключаться на другую вкладку не нужно
      if (vm.contentForm.$invalid && vm.styleForm.$valid) {
        vm.currentTab = TABS.CONTENT;
      } else if (vm.styleForm.$invalid && vm.contentForm.$valid) {
        vm.currentTab = TABS.STYLE;
      }
    }

    /**
     * Открытие модалки создания нового события
     */
    function openCreateEventTypeModal() {
      var createEventTypeModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('popupInputWithBigButtonBlockSettings.createEventTypeModal.heading'),
              body: $translate.instant('popupInputWithBigButtonBlockSettings.createEventTypeModal.body'),
              inputPlaceholder: $translate.instant(
                'popupInputWithBigButtonBlockSettings.createEventTypeModal.inputPlaceholder',
              ),
              inputErrorText: $translate.instant(
                'popupInputWithBigButtonBlockSettings.createEventTypeModal.inputErrorText',
              ),
              confirmButtonText: $translate.instant(
                'popupInputWithBigButtonBlockSettings.createEventTypeModal.confirmButtonText',
              ),
            };
          },
        },
      });

      createEventTypeModal.result.then(addEventType);

      function addEventType(eventTypeName) {
        var newEventType = eventTypeModel.getDefault(eventTypeName);

        // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        var existingEventTypeByName = $filter('filter')(vm.eventTypes, { name: newEventType.name }, true)[0];
        var existingEventTypeByPrettyName = $filter('filter')(
          vm.eventTypes,
          { prettyName: newEventType.prettyName },
          true,
        )[0];

        vm.popupBlockParams.eventType =
          existingEventTypeByName ||
          existingEventTypeByPrettyName ||
          (vm.eventTypes.push(newEventType) && newEventType);
      }
    }

    /**
     * Открытие модалки создания нового события
     */
    function openCreateUserPropertyModal(input) {
      var createUserPropertyModal = $uibModal.open({
        component: 'cqCreateUserProperty',
      });

      createUserPropertyModal.result.then(addUserProperty);

      function addUserProperty(userPropertyName) {
        var newUserProperty = propertyModel.getDefaultUserProperty(userPropertyName);

        // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        var existingUserPropertyByName = $filter('filter')(vm.userProperties, { name: newUserProperty.name }, true)[0];
        var existingUserPropertyByPrettyName = $filter('filter')(
          vm.userProperties,
          { prettyName: newUserProperty.prettyName },
          true,
        )[0];

        input.userProperty =
          existingUserPropertyByName ||
          existingUserPropertyByPrettyName ||
          (vm.userProperties.push(newUserProperty) && newUserProperty);
      }
    }

    /**
     * Удаление поля ввода
     *
     * @param {Object} input Поле ввода
     */
    function removeInput(input) {
      vm.popupBlockParams.inputs.splice(vm.popupBlockParams.inputs.indexOf(input), 1);
    }

    /**
     * Смена полей ввода местами
     *
     * @param {Number} targetInputOffset Смещение поля ввода, с которым будет меняться местами input, относительно input
     * @param {Object} input Поле ввода
     */
    function swapInputs(targetInputOffset, input) {
      var inputIndex = vm.popupBlockParams.inputs.indexOf(input);
      var targetInput = vm.popupBlockParams.inputs[inputIndex + targetInputOffset];

      if (targetInput) {
        vm.popupBlockParams.inputs[inputIndex + targetInputOffset] = input;
        vm.popupBlockParams.inputs[inputIndex] = targetInput;
      }
    }

    /**
     * Трек клика на вкладку 'Содержимое'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnContent(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на содержимое блока', {
        Название: POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
      });
    }

    /**
     * Трек клика на вкладку 'Стиль'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnStyle(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на стиль блока', {
        Название: POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
      });
    }

    /**
     * Фильтрация пользовательских свойств с учетом ограничений тарифа
     *
     * @param {Object} userProp - Объект с информацией о свойстве
     *
     * @return {Boolean}
     */
    function userCustomPropertiesCapabilityFilter(userProp) {
      return (
        vm.accessToUsersCustomProperties.hasAccess ||
        (vm.popupBlockParams.userProperty && userProp.prettyName === vm.popupBlockParams.userProperty.prettyName) ||
        userProp.groupOrder !== 5
      );
    }
  }
})();
