import { ChangeDetectionStrategy, Component, NgZone, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import toArray from 'lodash-es/toArray';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AMOCRM_ACCOUNT_DOMAIN } from '@panel/app/pages/integrations/content/amocrm/domain-selection/amocrm-domain-selection.constants';
import { L10nHelperService } from '@panel/app/services/l10n-helper/l10n-helper.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/**
 * Компонент для выбора домена, с которого будет происходить авторизация в amoCRM
 */
@Component({
  selector: 'cq-amocrm-domain-selection',
  templateUrl: './amocrm-domain-selection.component.html',
  styleUrls: ['./amocrm-domain-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmDomainSelectionComponent extends AbstractCVAControl<AMOCRM_ACCOUNT_DOMAIN> {
  /** Отсортированный список доступных доменов */
  amocrmAccountDomainsList: AMOCRM_ACCOUNT_DOMAIN[] = this.getAmocrmAccountDomainsList();

  readonly control = new GenericFormControl<AMOCRM_ACCOUNT_DOMAIN>(null);

  constructor(
    private readonly integrationService: IntegrationService,
    private readonly l10nHelper: L10nHelperService,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  /** Получение отсортированного списка доменов. Для каждой страны свой порядок */
  getAmocrmAccountDomainsList(): AMOCRM_ACCOUNT_DOMAIN[] {
    const domains = toArray(AMOCRM_ACCOUNT_DOMAIN);

    return this.l10nHelper.isUsCountry() ? domains.reverse() : domains;
  }
}
