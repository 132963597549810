import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AiContentMakerIntegrationSettingsExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-ai-content-maker-faq',
  templateUrl: './ai-content-maker-faq.component.html',
  styleUrls: ['./ai-content-maker-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiContentMakerFaqComponent {
  @Input({ required: true })
  control!: GenericFormControl<AiContentMakerIntegrationSettingsExternal['promptsJson']['faq']>;
}
