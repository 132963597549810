import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFooterTemplateDirective, NgHeaderTemplateDirective, NgLabelTemplateDirective, NgOptgroupTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";

import { SelectPreviewComponent } from '@panel/app/pages/components-preview/select-preview/select-preview.component';
import { SwitchPreviewComponent } from '@panel/app/pages/components-preview/switch-preview/switch-preview.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [SelectPreviewComponent, SwitchPreviewComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectComponent,
    NgOptgroupTemplateDirective,
    NgHeaderTemplateDirective,
    NgFooterTemplateDirective,
    NgLabelTemplateDirective,
  ],
  exports: [SelectPreviewComponent, SwitchPreviewComponent],
})
export class ComponentsPreviewModule {}
