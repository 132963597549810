import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { NgLabelTemplateDirective, NgOptgroupTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";
import { DestroyService } from '@panel/app/services';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmPipelineComponent } from './amocrm-pipeline.component';

@NgModule({
  declarations: [AmocrmPipelineComponent],
  exports: [AmocrmPipelineComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptgroupTemplateDirective,
    NgOptionTemplateDirective,
  ],
  providers: [AmocrmService, DestroyService, IntegrationService],
})
export class AmocrmPipelineModule {}
