import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { CreateAutoEventModalHelperService } from '@panel/app/partials/modals/create-auto-event-modal/services/create-auto-event-modal-helper.service';
import { CssSelectorInputModule } from '@panel/app/partials/track-master/css-selector-input/css-selector-input.module';
import { AutoEventsDuplicatesErrorsModule } from '@panel/app/partials/track-master/duplicates-errors/duplicates-errors.module';
import { EventNameInputModule } from '@panel/app/partials/track-master/event-name-input/event-name-input.module';
import { EventTypeSelectorModule } from '@panel/app/partials/track-master/event-type-selector/event-type-selector.module';
import { PageUrlModule } from '@panel/app/partials/track-master/page-url/page-url.module';
import { SelectorsPropertiesModule } from '@panel/app/partials/track-master/selectors-properties/selectors-properties.module';
import { TextPropertiesModule } from '@panel/app/partials/track-master/text-properties/text-properties.module';
import { WorksOnPageModule } from '@panel/app/partials/track-master/works-on-page/works-on-page.module';
import { WritePropertySelectorModule } from '@panel/app/partials/track-master/write-property-selector/write-property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { CreateAutoEventModalComponent } from './create-auto-event-modal.component';

@NgModule({
  declarations: [CreateAutoEventModalComponent],
  imports: [
    CommonModule,
    AutoEventsDuplicatesErrorsModule,
    TranslocoModule,
    EventNameInputModule,
    ReactiveFormsModule,
    CssSelectorInputModule,
    WorksOnPageModule,
    PageUrlModule,
    WritePropertySelectorModule,
    NgbCollapseModule,
    TextPropertiesModule,
    SelectorsPropertiesModule,
    SharedModule,
    EventTypeSelectorModule,
  ],
  exports: [CreateAutoEventModalComponent],
  providers: [CreateAutoEventModalHelperService],
})
export class CreateAutoEventModalModule {}
