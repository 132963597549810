import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

import { AiContentMakerIntegrationSettingsExternal } from '@http/integration/integrations/ai-content-maker/ai-content-maker-integration.interface';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-ai-content-maker-creativity-level',
  templateUrl: './ai-content-maker-creativity-level.component.html',
  styleUrls: ['./ai-content-maker-creativity-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiContentMakerCreativityLevelComponent {
  @Input({ required: true })
  control!: GenericFormControl<AiContentMakerIntegrationSettingsExternal['gptTemperature']>;

  protected sliderOptions: Options = {
    floor: 0,
    ceil: 1,
    step: 0.05,
    showSelectionBar: true,
    hideLimitLabels: true,
  };
}
