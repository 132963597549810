/**
 * Группы экшенов. Существуют только на фронте
 */
export enum ACTIONS_GROUPS {
  CONTENT = 'content', // Основные действия блока
  TARGET_SHOW = 'target-show', // Целевые действия
  TARGET_SELECT = 'target-select', // Целевые действия для выбора в селекторе
}

/**
 * Список группы действий
 */
export const ACTIONS_GROUPS_LIST: ACTIONS_GROUPS[] = [ACTIONS_GROUPS.CONTENT, ACTIONS_GROUPS.TARGET_SHOW];

/**
 * Подгруппы экшенов. Существуют только на фронте
 */
export enum ACTIONS_SUB_GROUPS {
  TEXT = 'text', // Текст
  FILE = 'file', // Прикрепление
  RECORD = 'record', // Запись данных о пользователе
  TRANSFER = 'transfer', // Передача данных о пользователе
  FINISH = 'finish', // Действия окончания диалога
  FORWARD = 'forward', // Действия продолжения диалога
}

/**
 * Типы действий ветки бота
 */
export enum CHAT_BOT_ACTIONS_TYPES {
  /** Передача данных о пользователе в AmoCRM */
  AMOCRM_NOTIFICATION = 'amocrm_notification',
  /** Ответ пользователя */
  BUTTON = 'button',
  /** Ответ пользователя */
  BUTTONS_PROPERTY = 'buttons_property',
  /** Добавление в канал */
  CHANNEL = 'channel',
  /** Запись события */
  EVENT = 'event',
  /** Передача данных о пользователе на Email */
  EMAIL_NOTIFICATION = 'email_notification',
  /** Прикрепление */
  FILE = 'file',
  /** Видео-кружок */
  VIDEO_NOTE = 'video_note',
  /** Сделать диалог видимым в диалогах панельки */
  MARK_CONVERSATION_VISIBLE = 'mark_conversation_visible',
  /** Назначение встречи */
  MEET = 'meet',
  /** Назначение на AI ассистента */
  ASSISTANT = 'set_assistant_type',
  /** Назначение на оператора */
  OPERATOR = 'assign',
  /** Запись свойства */
  PROPERTY = 'property',
  /** ПВвод ответа */
  PROPERTY_FIELD = 'property_field',
  /** Текст */
  TEXT = 'text',
  /** Добавление тега на пользователя */
  USER_TAG = 'user_tag',
  /** Действие не должно отправляться на backend, нужно для псевдозакрытия диалога бота на фронте */
  CLOSE = 'close',
  /** Действие перехода на следующий блок NOTE используется в блоках с типом 'action' */
  NEXT = 'move_to_next_branch',

  // Экшены по условию
  /** Апп онлайн, то есть когда рабочее время + если операторы онлайн (только при включенной настройке "Включать офлайн-режим, если в рабочее время все операторы в офлайне" */
  APP_ONLINE_CONDITION = 'app_online_condition',
  /** Условие по умолчанию */
  DEFAULT_CONDITION = 'default_condition',
}

/**
 * Ограничение на количество действий в ветке бота
 * NOTE Максимальное количество действий в ветке — 20
 */
export const CHAT_BOT_ACTIONS_LIMIT_COUNT: Record<CHAT_BOT_ACTIONS_TYPES, number> = {
  [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION]: 1,
  [CHAT_BOT_ACTIONS_TYPES.BUTTON]: 10,
  [CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY]: 1,
  [CHAT_BOT_ACTIONS_TYPES.CHANNEL]: 1,
  [CHAT_BOT_ACTIONS_TYPES.EVENT]: 20,
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION]: 1,
  [CHAT_BOT_ACTIONS_TYPES.FILE]: 20,
  [CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE]: 20,
  [CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE]: 1,
  [CHAT_BOT_ACTIONS_TYPES.MEET]: 1,
  [CHAT_BOT_ACTIONS_TYPES.ASSISTANT]: 1,
  [CHAT_BOT_ACTIONS_TYPES.OPERATOR]: 1,
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY]: 20,
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD]: 1,
  [CHAT_BOT_ACTIONS_TYPES.TEXT]: 20,
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG]: 5,
  [CHAT_BOT_ACTIONS_TYPES.CLOSE]: 1,
  [CHAT_BOT_ACTIONS_TYPES.NEXT]: 1,
  // Экшены по условию
  [CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION]: 1,
  [CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION]: 1,
};

/**
 * Группировка действий по возможности отправки
 *
 * @deprecated
 */
export const CHAT_BOT_ACTIONS_TYPES_BY_IS_REPLY: Record<CHAT_BOT_ACTIONS_TYPES, boolean> = {
  [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION]: false,
  [CHAT_BOT_ACTIONS_TYPES.BUTTON]: true,
  [CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY]: false,
  [CHAT_BOT_ACTIONS_TYPES.CHANNEL]: true,
  [CHAT_BOT_ACTIONS_TYPES.CLOSE]: true,
  [CHAT_BOT_ACTIONS_TYPES.EVENT]: false,
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION]: false,
  [CHAT_BOT_ACTIONS_TYPES.FILE]: false,
  [CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE]: false,
  [CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE]: true,
  [CHAT_BOT_ACTIONS_TYPES.MEET]: true,
  [CHAT_BOT_ACTIONS_TYPES.ASSISTANT]: true,
  [CHAT_BOT_ACTIONS_TYPES.OPERATOR]: true,
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY]: false,
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD]: true,
  [CHAT_BOT_ACTIONS_TYPES.TEXT]: false,
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG]: false,
  [CHAT_BOT_ACTIONS_TYPES.NEXT]: true,
  // Экшены по условию
  [CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION]: false,
  [CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION]: false,
};

/**
 * Получение иконок для каждого типа действия
 *
 * Any тут пришлось добавить чтоб не возиться с подключением флага noImplicitAny
 */
export const CHAT_BOT_ACTIONS_TYPES_ICONS: Record<CHAT_BOT_ACTIONS_TYPES | any, string> = {
  [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION]: 'cqi-amocrm',
  [CHAT_BOT_ACTIONS_TYPES.BUTTON]: 'cqi-list-ul',
  [CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY]: 'cqi-list-ul',
  [CHAT_BOT_ACTIONS_TYPES.CHANNEL]: 'cqi-move',
  [CHAT_BOT_ACTIONS_TYPES.EVENT]: 'cqi-cursor-arrow',
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION]: 'cqi-envelope-o',
  [CHAT_BOT_ACTIONS_TYPES.FILE]: 'cqi-paperclip',
  [CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE]: 'cqi-play-circle',
  [CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE]: 'cqi-comment-in-unread',
  [CHAT_BOT_ACTIONS_TYPES.MEET]: 'cqi-calendly',
  [CHAT_BOT_ACTIONS_TYPES.ASSISTANT]: 'cqi-robot',
  [CHAT_BOT_ACTIONS_TYPES.OPERATOR]: 'cqi-circle-user',
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY]: 'cqi-user-o',
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD]: 'cqi-keyboard',
  [CHAT_BOT_ACTIONS_TYPES.TEXT]: 'cqi-align-left',
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG]: 'cqi-tag-o',
  [CHAT_BOT_ACTIONS_TYPES.CLOSE]: 'cqi-check-circle-o',
};

/**
 * Список типов действий ветки бота
 */
export const CHAT_BOT_ACTIONS_TYPES_LIST: CHAT_BOT_ACTIONS_TYPES[][] = [
  [CHAT_BOT_ACTIONS_TYPES.TEXT],
  [CHAT_BOT_ACTIONS_TYPES.FILE],
  [CHAT_BOT_ACTIONS_TYPES.BUTTON],
  [CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY],
  [CHAT_BOT_ACTIONS_TYPES.CHANNEL],
  [CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE],
  [CHAT_BOT_ACTIONS_TYPES.ASSISTANT],
  [CHAT_BOT_ACTIONS_TYPES.OPERATOR],
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG],
  [CHAT_BOT_ACTIONS_TYPES.EVENT],
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION],
  [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION],
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY],
  [CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD],
];

/**
 * Список типов действий ветки бота, объединенные в группы
 */
export const CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP: Record<ACTIONS_GROUPS, CHAT_BOT_ACTIONS_TYPES[]> = {
  [ACTIONS_GROUPS.CONTENT]: [
    CHAT_BOT_ACTIONS_TYPES.TEXT,
    CHAT_BOT_ACTIONS_TYPES.FILE,
    CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE,
    CHAT_BOT_ACTIONS_TYPES.MEET,
    CHAT_BOT_ACTIONS_TYPES.USER_TAG,
    CHAT_BOT_ACTIONS_TYPES.EVENT,
    CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION,
    CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION,
    CHAT_BOT_ACTIONS_TYPES.PROPERTY,
  ],
  [ACTIONS_GROUPS.TARGET_SELECT]: [
    CHAT_BOT_ACTIONS_TYPES.BUTTON,
    CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
    CHAT_BOT_ACTIONS_TYPES.CHANNEL,
    CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    CHAT_BOT_ACTIONS_TYPES.ASSISTANT,
    CHAT_BOT_ACTIONS_TYPES.OPERATOR,
    CHAT_BOT_ACTIONS_TYPES.CLOSE,
  ],
  [ACTIONS_GROUPS.TARGET_SHOW]: [
    CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY,
    CHAT_BOT_ACTIONS_TYPES.BUTTON,
    CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
    CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    CHAT_BOT_ACTIONS_TYPES.CHANNEL,
    CHAT_BOT_ACTIONS_TYPES.ASSISTANT,
    CHAT_BOT_ACTIONS_TYPES.OPERATOR,
    CHAT_BOT_ACTIONS_TYPES.CLOSE,
  ],
};

/**
 * Тип с целевыми действиями бота
 */
export type CHAT_BOT_TARGET_ACTION =
  | CHAT_BOT_ACTIONS_TYPES.BUTTON
  | CHAT_BOT_ACTIONS_TYPES.CHANNEL
  | CHAT_BOT_ACTIONS_TYPES.ASSISTANT
  | CHAT_BOT_ACTIONS_TYPES.OPERATOR
  | CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD
  | CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE;

/**
 * Список типов действий ветки бота, объединенные в подгруппы
 */
export const CHAT_BOT_ACTIONS_TYPES_LIST_BY_SUB_GROUP: Record<ACTIONS_SUB_GROUPS, CHAT_BOT_ACTIONS_TYPES[]> = {
  [ACTIONS_SUB_GROUPS.TEXT]: [CHAT_BOT_ACTIONS_TYPES.TEXT],
  [ACTIONS_SUB_GROUPS.FILE]: [CHAT_BOT_ACTIONS_TYPES.FILE, CHAT_BOT_ACTIONS_TYPES.VIDEO_NOTE],
  [ACTIONS_SUB_GROUPS.RECORD]: [
    CHAT_BOT_ACTIONS_TYPES.USER_TAG,
    CHAT_BOT_ACTIONS_TYPES.EVENT,
    CHAT_BOT_ACTIONS_TYPES.PROPERTY,
  ],
  [ACTIONS_SUB_GROUPS.TRANSFER]: [
    CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION,
    CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION,
  ],
  [ACTIONS_SUB_GROUPS.FORWARD]: [CHAT_BOT_ACTIONS_TYPES.BUTTON, CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD],
  [ACTIONS_SUB_GROUPS.FINISH]: [
    CHAT_BOT_ACTIONS_TYPES.CHANNEL,
    CHAT_BOT_ACTIONS_TYPES.OPERATOR,
    CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    CHAT_BOT_ACTIONS_TYPES.ASSISTANT,
  ],
};
