import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { ApiLeadBotResponse } from '@http/chat-bot/types/chat-bot-external.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationAutoMessage } from '@http/integration/types/integration.types';
import { EventType } from '@http/property/property.model';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { EmailNotificationSelectModalWindowParams } from '@panel/app/pages/integrations/content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-email-notification-select-modal-wrapper',
  templateUrl: './email-notification-select-modal-wrapper.component.html',
  styleUrls: ['./email-notification-select-modal-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationSelectModalWrapperComponent implements OnInit {
  @Input() modalWindowParams!: EmailNotificationSelectModalWindowParams;

  /** TODO: Удалить как переделаются автосообщения, нужна чтоб закрывтаь ajs модалку и прокидывать созданную интеграцию */
  @Input() ajsModalInstance?: any;

  /** Список автосообщений, в которых используется данная интеграция */
  autoMessagesList: Observable<IntegrationAutoMessage[]> = of([]);
  /** Список чат-ботов, в которых используется данная интеграция */
  chatBotsList: Observable<ApiLeadBotResponse[]> = of([]);
  /** Список получателей оповещений для аппа */
  emailRecipients: Observable<EmailRecipient[]> = of([]);
  /** Список названий событий, которые являются триггером для данной интеграции */
  selectedEventTriggersPrettyNames!: EventType['prettyName'][];

  // @formatter:off
  constructor(
    public activeModal: NgbActiveModal,
    public readonly appService: AppService,
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly integrationService: IntegrationService,
    public readonly stateService: StateService,
  ) {}
  // @formatter:on

  ngOnInit(): void {
    this.autoMessagesList = this.integrationService.emailNotificationGetAutoMessages(
      this.modalWindowParams.integrationExternal.id!,
      this.modalWindowParams.currentApp.id,
    );
    this.chatBotsList = this.integrationService.emailNotificationGetChatBots(
      this.modalWindowParams.integrationExternal.id!,
    );
    this.emailRecipients = this.appService.getEmailRecipients(this.modalWindowParams.currentApp.id);
    this.selectedEventTriggersPrettyNames = this.getSelectedEventTypesPrettyNames();

    this.trackOpenModal();
  }

  closeModal() {
    this.ajsModalInstance ? this.ajsModalInstance.close() : this.activeModal.close();
  }

  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  /** Получение выбранных свойств */
  getFilteredEventTypeBySelected(): EventType[] {
    return this.modalWindowParams.properties.eventTypes.filter((eventType) => this.isSelectedEventType(eventType));
  }

  /** Получение коротких имён выбранных типов свойств */
  getSelectedEventTypesPrettyNames(): string[] {
    return this.getFilteredEventTypeBySelected().map((evenType) => evenType.prettyName);
  }

  /** Является ли событие выбранным */
  isSelectedEventType(eventType: EventType): boolean {
    return this.modalWindowParams.integrationExternal.settings.eventTypeIds.includes(eventType.id);
  }

  /**
   * Трек открытия модалки
   */
  trackOpenModal(): void {
    this.carrotquestHelper.track(`Интеграции - ${INTEGRATION_TYPES.EMAIL_NOTIFICATION} - переход на интеграцию`);
  }
}
