import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiLabelTemplateDirective, NgOptionComponent, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";
import { UIRouterModule } from '@uirouter/angular';

import { ConversationChannelModule } from '@panel/app/pages/conversations/conversation-channel/conversation-channel.module';
import { AccessDenialBannerModule } from '@panel/app/partials/access-denial/banner/access-denial-banner.module';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { EventFiltersModule } from '@panel/app/partials/filters/filters/event-filters/event-filters.module';
import { PropertyFiltersModule } from '@panel/app/partials/filters/filters/property-filters/property-filters.module';
import { TagFiltersModule } from '@panel/app/partials/filters/filters/tag-filters/tag-filters.module';
import { UrlFilterConfiguratorModule } from '@panel/app/partials/url-filter-configurator/url-filter-configurator.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ChannelEditorComponent } from './channel-editor.component';

@NgModule({
  declarations: [ChannelEditorComponent],
  exports: [ChannelEditorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    AccessDenialBannerModule,
    SharedModule,
    ReactiveFormsModule,
    UIRouterModule,
    ChannelIconModule,
    FormsModule,
    NgbDropdownModule,
    ConversationChannelModule,
    AccessDenialPopoverModule,
    UrlFilterConfiguratorModule,
    TagFiltersModule,
    PropertyFiltersModule,
    EventFiltersModule,
    NgOptimizedImage,
    NgSelectComponent,
    NgOptionComponent,
    NgOptionTemplateDirective,
    NgMultiLabelTemplateDirective,
  ],
})
export class ChannelEditorModule {}
